/*
*
* Search Component
*
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { cloneDeep, debounce, isEqual, } from 'lodash';
import Fuse from 'fuse.js';

import { 
  MenuItem
} from '@mui/material';

import {
  Search
} from '@frontend/common';

import styles from './styles.module.scss';

export class ComposableSearch extends Component {

  static propTypes = {
    idKey: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    dataColumns: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    updateData: PropTypes.func.isRequired,
    hasSearchMenu: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      ComposableSearch: PropTypes.shape({
        text_search_suggestion: PropTypes.func,
      }),
    }),
  };

  state = {
    showSearchSuggestions: false,
    filterTerm: '',
    selectedSearchMenuIndex: -1,
  };

  searchMenuHide = () => {
    this.setState({
      showSearchSuggestions: false,
      selectedSearchMenuIndex: -1,
    });
  };
  
  keyPressHandle = e => {
    const { showSearchSuggestions, selectedSearchMenuIndex, } = this.state;
    const columns = this.props.dataColumns;
    if (e.keyCode === 27) { // ESC Key
      this.searchMenuHide();
    }
    if (showSearchSuggestions) {
      switch (e.keyCode) {
        case 13: { // Enter Key
          if (selectedSearchMenuIndex > -1) {
            this.onSearchSuggestionClick(selectedSearchMenuIndex);
          }
          else {
            this.searchMenuHide();
          }
          break;
        }
        case 40: { // Arrow Down Key
          if (selectedSearchMenuIndex < columns.length - 1) {
            this.setState({
              selectedSearchMenuIndex: selectedSearchMenuIndex + 1,
            });
          }
          break;
        }
        case 38: { // Arrow Up Key
          if (selectedSearchMenuIndex > -1) {
            this.setState({
              selectedSearchMenuIndex: selectedSearchMenuIndex - 1,
            });
          }
          break;
        }
        default: break;
      }
    }
  };

  onSearchSuggestionClick = (index) => {
    const column = this.props.dataColumns[index];
    this.setState({ filterTerm: `${column.title}: ${this.state.filterTerm}`, showSearchSuggestions: false, }, this.onFilter);
  };

  onFilter = () => {
    const { filterTerm } = this.state;
    const searchIsActive = Boolean(filterTerm);
    const splitTerm = filterTerm.split(':').map(val => val.trim());
    

    let sortColumn = '';
    let searchTerm = '';
    
    if (splitTerm.length > 1) {
      const column = this.props.dataColumns.find(column => column.title.toLowerCase() === splitTerm[0].toLowerCase());
      sortColumn = [column.key];
      searchTerm = splitTerm[splitTerm.length - 1];
    }
    else {
      sortColumn = this.props.dataColumns.map(column => column.key);
      searchTerm = filterTerm;
    }

    const updatedResults = this.filterData(sortColumn, searchTerm);
    this.props.updateData(updatedResults, searchIsActive);
  }

  filterData = (sortColumn, searchTerm) => {
    const fuseConfig = {
      tokenize: true,
      findAllMatches: true,
      shouldSort: true,
      maxPatternLength: 100,
      minMatchCharLength: 1,
      threshold: 0.2,
      location: 0,
      distance: 50,
      keys: sortColumn
    };
    const clonedData = cloneDeep(this.props.data);
    if (searchTerm.length > 0) {

      const fuse = new Fuse(clonedData, fuseConfig);
      const results = fuse.search(searchTerm);
      const matchedRows = [];
      
      results.forEach(result => {
        const matchingRow = clonedData.filter(row => row[this.props.idKey] === result.item[this.props.idKey])[0];
        if (matchingRow) matchedRows.push(matchingRow);
      });
      
      return matchedRows;
    }
    else {
      return clonedData;
    }
  };

  componentDidMount() {
    document.addEventListener('keyup', this.keyPressHandle);
  }
  
  componentDidUpdate(prevProps) {
    // if data is updated from parent, run through search again to give back searched results
    if (!isEqual(prevProps.data, this.props.data)) { 
      this.onFilter();
    }
  }
  
  componentWillUnmount() {
    document.removeEventListener('keyup', this.keyPressHandle);
  }

  render() {
    const { filterTerm, showSearchSuggestions, selectedSearchMenuIndex, } = this.state;
    
    return (
      <div
        key='tableSearchAction'
        className={styles.searchContainer}
        onBlur={this.searchMenuHide}
        onFocus={() => this.setState({ showSearchSuggestions: Boolean(filterTerm) && this.props.hasSearchMenu })} // if a filterTerm exists and the hasSearchMenu is true, can show searchSuggestions
      >
        <Search
          direction='right'
          onChange={filterTerm => this.setState({ filterTerm, showSearchSuggestions: Boolean(filterTerm) && this.props.hasSearchMenu }, debounce(this.onFilter, 300))}
          value={filterTerm}
          disabled={this.props.disabled}
        />
        <div
          className={`${styles.searchMenu} ${showSearchSuggestions ? styles.show : ''}`}
          id='searchMenu'
        >
          {this.props.dataColumns.map((column, index) => (
            <MenuItem
              selected={selectedSearchMenuIndex === index}
              key={column.key}
              onClick={() => {		
                this.onSearchSuggestionClick(index);	
              }}
            >
              {this.props.text.ComposableSearch.text_search_suggestion(filterTerm, column.title)}
            </MenuItem>
          ))}
        </div>
      </div>
    );
  }

}

export default (LanguageHOC(ComposableSearch));