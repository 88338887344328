/*
*
* FundAllocationsAndFees Component
*
*/
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  TextField,
} from '@mui/material';

import {
  InfoIcon,
  Dropdown,
} from '@frontend/common';

import styles from '../styles.module.scss';

const tippyProps = {
  hideOnClick: true,
  interactive: true,
};

export class FundAllocationsAndFees extends Component {
  static propTypes = {
    applicationInfo: PropTypes.object.isRequired,
    programDescriptionLink: PropTypes.string.isRequired,
    title1: PropTypes.string.isRequired,
    title2: PropTypes.string.isRequired,
    selectedAgeBand: PropTypes.number.isRequired,
    isAgeBased: PropTypes.bool.isRequired,
    onAgeBandSelection: PropTypes.func.isRequired,
    text: PropTypes.shape({
      LpoaAccount: PropTypes.object.isRequired,
    }).isRequired,
  }

  render() {
    const { selectedAgeBand, isAgeBased } = this.props;

    const {
      applicationInfo,
      title1,
      title2,
      onAgeBandSelection,
      programDescriptionLink,
      text: {
        LpoaAccount: { fund_allocations_and_fees }
      },
    } = this.props;

    return (
      <Fragment>
        {isAgeBased && <div>
          <div className={styles.sectionTitle}>{fund_allocations_and_fees.select_age_band_title}</div>
          <div className={styles.sectionSubTitle}><div className={styles.emptySubtitle} /></div>
          <Dropdown
            FormControlProps={{ variant: 'filled' }}
            label={fund_allocations_and_fees.age_band_dropdown_label}
            onChange={onAgeBandSelection}
            options={applicationInfo.ageBands.map((ageBand, index) => ({ display: ageBand.AgeBandName, value: index }))}
            value={selectedAgeBand}
          />
        </div>}

        <div className={styles.sectionTitle}>{title1}</div>
        <div className={styles.sectionSubTitle}><div className={styles.emptySubtitle} /></div>

        {applicationInfo.lpoaFirmName !== '' && applicationInfo.ageBands[selectedAgeBand].FundInformation.map((fund, index) => {
          return (
            <TextField
              key={`${fund.Name}_${index}`}
              disabled={true}
              variant='filled'
              fullWidth
              label={fund.Name}
              value={`${fund.Ticker} ${fund.Percentage}`}
            />
          );
        })}

        <div className={styles.sectionTitle}>
          {title2}
          <span className={styles.infoIconByTitle}>
            <InfoIcon
              message={fund_allocations_and_fees.fees_info_icon_content(programDescriptionLink)}
              tippyProps={tippyProps}
            />
          </span>
        </div>
        <div className={styles.sectionSubTitle}><div className={styles.emptySubtitle} /></div>

        <div className={styles.detailsRow}>
          <TextField
            disabled={true}
            variant='filled'
            fullWidth
            label={fund_allocations_and_fees.est_expense_ratio_label}
            value={applicationInfo.ageBands[selectedAgeBand].FeeInformation.ExpenseRatio}
          />
        </div>

        <div className={styles.detailsRow}>
          <TextField
            disabled={true}
            variant='filled'
            fullWidth
            label={fund_allocations_and_fees.my529_admin_asset_fee_label}
            value={applicationInfo.ageBands[selectedAgeBand].FeeInformation.My529Fee}
          />
        </div>

        <div className={styles.detailsRow}>
          <TextField
            disabled={true}
            variant='filled'
            fullWidth
            label={fund_allocations_and_fees.ext_total_annual_asset_based_fee_label}
            value={applicationInfo.ageBands[selectedAgeBand].FeeInformation.TotalFee}
          />
        </div>
      </Fragment>
    );
  }
}


export default LanguageHOC(FundAllocationsAndFees);
