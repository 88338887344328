/*
*
* Lpoa Component
*
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';

import LearnMore from './LearnMore';
import BeneTable from './BeneTable';
import EditLpoa from './EditLpoa';

import {
  CardAligned,
  LoadingOverlay,
} from '@frontend/common';

import {
  Button,
  TextField,
} from '@mui/material';

import {
  checkAuthCode,
  getLpoa,
  deleteLpoa,
} from './actions';

import { get2FAPrecondition, getRSAPrecondition } from 'components/AppRoot/Navigation/actions';
import { protectAction } from 'utils/helpers/multifactor_handler';
import { MULTIFACTOR_TYPE } from 'components/AppRoot/Navigation/constants';

import {
  INVALID_AUTH_CODE,
} from './constants';


import styles from './styles.module.scss';

const select = (state) => ({
  claims: state.session.claims
});

export class Lpoa extends React.Component {
  static propTypes = {
    checkAuthCode: PropTypes.func.isRequired,
    getLpoa: PropTypes.func.isRequired,
    deleteLpoa: PropTypes.func.isRequired,
    get2FAPrecondition: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    text: PropTypes.shape({
      Lpoa: PropTypes.shape({
        btn_learn_more: PropTypes.string.isRequired,
        error_auth_code_empty: PropTypes.string.isRequired,
        authorization_code_label: PropTypes.string.isRequired,
        authorization_code_title: PropTypes.string.isRequired,
        btn_back: PropTypes.string.isRequired,
        btn_cancel: PropTypes.string.isRequired,
        btn_confirm: PropTypes.string.isRequired,
        btn_next: PropTypes.string.isRequired,
        confirm_correct_lpoa_txt: PropTypes.string.isRequired,
        enter_auth_code_txt: PropTypes.string.isRequired,
      }).isRequired,

    }).isRequired,
  };

  state = {
    loading: false,
    learnMoreOpen: false,
    lpoaIsNoLinked: false,
    errorAuthCode: '',
    lpoa: {
      PoaCode: null,
      PoaName: null,
      BeneficiaryInfo: [],
    },
    selectedBeneIds: [],
    show: {
      authCodeEntryComponent: true,
      lpoaListComponent: false,
      editLpoaComponent: false,
      confirmLpoaNameComponent: false,
    },
    openConfirmModalRevokeBene: false,
    openConfirmModalUnlinkLpoa: false,

    hasPrecondition: false,
    post2FAAuthFunction: '',
    rememberedEvent: {}
  }

  validateForm = () => {
    const { text: { Lpoa } } = this.props;
    const { lpoa: { PoaCode } } = this.state;

    let errorAuthCode = '';
    if (PoaCode === '' || PoaCode === null)
      errorAuthCode = Lpoa.error_auth_code_empty;

    this.setState({ errorAuthCode });

    const isErrored = Boolean(errorAuthCode);
    return !isErrored;
  }

  onInputChange = (e) => {
    this.setState({
      lpoa: { ...this.state.lpoa, PoaCode: e.target.value },
      errorAuthCode: '',
    });
  }

  selectAllClickHandler = (e) => {
    if (e.target.checked) {
      const newSelectedBeneIds = this.state.lpoa.BeneficiaryInfo.map(bene => bene.BeneficiaryId);
      this.setState({ selectedBeneIds: newSelectedBeneIds });
      return;
    }
    this.setState({ selectedBeneIds: [] });
  };

  onCheckboxClickHandler = (e, beneId) => {
    const selectedBeneIds = this.state.selectedBeneIds;
    const selectedIndex = selectedBeneIds.indexOf(beneId);
    let newSelectedBeneIds = [];

    if (selectedIndex === -1) {
      newSelectedBeneIds = newSelectedBeneIds.concat(selectedBeneIds, beneId);
    }
    else if (selectedIndex === 0) {
      newSelectedBeneIds = newSelectedBeneIds.concat(selectedBeneIds.slice(1));
    }
    else if (selectedIndex === selectedBeneIds.length - 1) {
      newSelectedBeneIds = newSelectedBeneIds.concat(selectedBeneIds.slice(0, -1));
    }
    else if (selectedIndex > 0) {
      newSelectedBeneIds = newSelectedBeneIds.concat(
        selectedBeneIds.slice(0, selectedIndex),
        selectedBeneIds.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedBeneIds: newSelectedBeneIds });
  };

  setStateShowHandler = (componentName) => {
    // first reset all
    const updatedShow = {
      authCodeEntryComponent: false,
      lpoaListComponent: false,
      editLpoaComponent: false,
      confirmLpoaNameComponent: false,
    };
    // update only one
    updatedShow[componentName] = true;
    this.setState({ show: updatedShow });
  }

  goBackToLpoaTableHandler = (refreshLpoa = true) => {
    this.setStateShowHandler('lpoaListComponent');
    // also reset all selected benes to none
    this.setState({ selectedBeneIds: [] });
    // refresh lpoa
    if (refreshLpoa) {
      this.setState({ loading: true });
      this.getLpoaHandler()
        .finally(() => this.setState({ loading: false }));
    }

    window.scrollTo(0, 0);
  }

  onAuthCodeCheckHandler = e => {
    e.preventDefault();
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        { 
          hasPrecondition: true,
          post2FAAuthFunction: 'onAuthCodeCheckHandler',
          rememberedEvent: e
        },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        { 
          hasPrecondition: true,
          post2FAAuthFunction: 'onAuthCodeCheckHandler',
          rememberedEvent: e
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      if (this.validateForm()) {
        this.setState({ 
          loading: true,
          hasPrecondition: false,
          rememberedEvent: {}
        });
        this.props.checkAuthCode(this.state.lpoa.PoaCode)
          .then((response) => {
            const PoaName = response.payload.data;
            // check if invalid code
            if (PoaName === INVALID_AUTH_CODE) {
              this.setStateShowHandler('authCodeEntryComponent');
              this.setState({
                errorAuthCode: INVALID_AUTH_CODE
              });
            }
            else {
              this.setStateShowHandler('confirmLpoaNameComponent');
              this.setState({ lpoa: { ...this.state.lpoa, PoaName, } });
            }
          })
          .finally(() => this.setState({ loading: false }));
      }
    }
  }

  onBackToCodeEntryHandler = () => {
    this.setStateShowHandler('authCodeEntryComponent');
    this.setState({
      errorAuthCode: '',
      poa: { ...this.state.lpoa, PoaName: '' }
    });
  }

  onLPOACancelHandler = () => {
    this.props.history.goBack();
  }

  onLPOAConfirmHandler = () => {
    this.setStateShowHandler('lpoaListComponent');
  }

  getLpoaHandler = () => {
    return this.props.getLpoa()
      .then((response) => {
        const lpoa = response.payload.data;
        this.setState({ lpoa }, () => {
          if (lpoa.PoaCode === null) {
            this.setState({ lpoaIsNoLinked: true });
            this.setStateShowHandler('authCodeEntryComponent');
          }
          else {
            this.setState({ lpoaIsNoLinked: false });
            this.setStateShowHandler('lpoaListComponent');
          }
        });
      });
  }

  setOpenConfirmModalRevokeBene = functionName => {
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: functionName
        },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: functionName
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      this.setState({ 
        hasPrecondition: false,
        openConfirmModalRevokeBene: true,
        post2FAAuthFunction: ''
      });
    }
  }

  setOpenConfirmModalUnlinkLpoa = functionName => {
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        { 
          hasPrecondition: true,
          post2FAAuthFunction: functionName
        },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: functionName,
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      this.setState({ 
        hasPrecondition: false,
        openConfirmModalUnlinkLpoa: true,
        post2FAAuthFunction: ''
      });
    }
  }

  revokeAllHandler = () => {
    const allBeneIds = this.state.lpoa.BeneficiaryInfo.map(bene => bene.BeneficiaryId);
    this.setState({
      loading: true,
      openConfirmModalUnlinkLpoa: true,
    });
    this.props.deleteLpoa(allBeneIds)
      .then(() => {
        this.setState({ selectedBeneIds: [] });
        return this.getLpoaHandler();
      })
      .finally(() => this.setState({
        loading: false,
        openConfirmModalUnlinkLpoa: false,
      }));
  };

  revokeSelectedBenesHandler = () => {
    this.setState({
      loading: true,
      openConfirmModalRevokeBene: true,
    });
    this.props.deleteLpoa(this.state.selectedBeneIds)
      .then(() => {
        this.setState({ selectedBeneIds: [] });
        return this.getLpoaHandler();
      })
      .finally(() => this.setState({
        loading: false,
        openConfirmModalRevokeBene: false,
      }));
  };

  editSelectedBenesHandler = functionName => {
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: functionName
        },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: functionName
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      this.setStateShowHandler('editLpoaComponent', true);
      this.setState({ hasPrecondition: false });
    }
  };

  // Methods to render components
  renderNavigationButton = (label, onClick, variant, disabled = false, color = 'primary') => {
    return (
      <div className={styles.button}>
        <Button
          key={`button_${label}`}
          disabled={this.state.loading || disabled}
          onClick={onClick}
          variant={variant}
          color={color}
        >
          {label}
        </Button>
      </div>
    );
  }

  authCodeEntryComponent = () => {
    const { text: { Lpoa } } = this.props;
    const { errorAuthCode, lpoa: { PoaCode }, learnMoreOpen } = this.state;

    return (
      <div>
        <CardAligned title={Lpoa.authorization_code_title} onSubmit={this.onAuthCodeCheckHandler}>
          <div className={styles.authCodeEntryComponent}>
            <LoadingOverlay show={this.state.loading} width='100%'>
              {Lpoa.enter_auth_code_txt}
              <div className={styles.codeInput}>
                <TextField
                  defaultValue={PoaCode}
                  error={Boolean(errorAuthCode)}
                  helperText={errorAuthCode}
                  fullWidth
                  label={Lpoa.authorization_code_label}
                  name='authorizeAccess'
                  onChange={this.onInputChange}
                  variant='filled'
                />
              </div>
              <div className={styles.navButtons}>
                {this.renderNavigationButton(Lpoa.btn_cancel, this.onLPOACancelHandler, 'text')}
                {this.renderNavigationButton(Lpoa.btn_next, this.onAuthCodeCheckHandler, 'contained')}
              </div>
            </LoadingOverlay>
          </div>
        </CardAligned>
        <div className={styles.learnMore}>
          {this.renderNavigationButton(Lpoa.btn_learn_more, () => this.setState({ learnMoreOpen: true }), 'outlined')}
        </div>
        <LearnMore
          onClose={() => this.setState({ learnMoreOpen: false })}
          open={learnMoreOpen}
        />
      </div>

    );
  }

  confirmLpoaNameComponent = () => {
    const { text: { Lpoa } } = this.props;
    const { lpoa: { PoaName } } = this.state;
    return (
      <CardAligned title={'Confirm LPOA'} onSubmit={this.onAuthCodeCheckHandler}>
        <div className={styles.authCodeEntryComponent}>
          {Lpoa.confirm_correct_lpoa_txt}
          <div className={styles.poaName}>
            {PoaName}
          </div>
          <div className={styles.navButtons}>
            {this.renderNavigationButton(Lpoa.btn_back, this.onBackToCodeEntryHandler, 'text')}
            {this.renderNavigationButton(Lpoa.btn_cancel, this.onLPOACancelHandler, 'text')}
            {this.renderNavigationButton(Lpoa.btn_confirm, this.onLPOAConfirmHandler, 'contained')}
          </div>
        </div>
      </CardAligned>
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    // check if there are any linked benes if yes go to table otherwise to code entry
    this.setState({ loading: true });
    this.getLpoaHandler()
      .finally(() => this.setState({ loading: false }));
  }

  componentDidUpdate() {
    const multiStatus = protectAction(this.props.claims);
    if (this.state.hasPrecondition && multiStatus === MULTIFACTOR_TYPE.AUTH) {
      const functionName = this.state.post2FAAuthFunction;
      this[functionName](this.state.rememberedEvent);
    }
  }

  render() {
    const {
      loading,
      show,
      lpoa,
      selectedBeneIds,
      lpoaIsNoLinked,
      openConfirmModalRevokeBene,
      openConfirmModalUnlinkLpoa,
    } = this.state;

    return (
      <div className={styles.container}>
        {show.authCodeEntryComponent && this.authCodeEntryComponent()}
        {show.lpoaListComponent &&
          <BeneTable
            loading={loading}
            lpoa={lpoa}
            selectedBeneIds={selectedBeneIds}
            onCheckboxClickHandler={this.onCheckboxClickHandler}
            renderNavigationButton={this.renderNavigationButton}
            selectAllClickHandler={this.selectAllClickHandler}
            unlinkLpoa={this.revokeAllHandler}
            revokeSelectedBenesHandler={this.revokeSelectedBenesHandler}
            editSelectedBenesHandler={() => this.editSelectedBenesHandler('editSelectedBenesHandler')}
            disableRevoke={lpoaIsNoLinked}
            openConfirmModalRevokeBene={openConfirmModalRevokeBene}
            openConfirmModalUnlinkLpoa={openConfirmModalUnlinkLpoa}
            setOpenConfirmModalRevokeBene={() => this.setOpenConfirmModalRevokeBene('setOpenConfirmModalRevokeBene')}
            setOpenConfirmModalUnlinkLpoa={() => this.setOpenConfirmModalUnlinkLpoa('setOpenConfirmModalUnlinkLpoa')}
            cancelConfirmModalRevokeBene={() => this.setState({ openConfirmModalRevokeBene: false })}
            cancelConfirmModalUnlinkLpoa={() => this.setState({ openConfirmModalUnlinkLpoa: false })}
          />
        }
        {show.editLpoaComponent &&
          <EditLpoa
            lpoa={lpoa}
            selectedBeneIds={selectedBeneIds}
            goBackToLpoaTableAndRefreshLpoa={this.goBackToLpoaTableHandler}
          />
        }
        {show.confirmLpoaNameComponent && this.confirmLpoaNameComponent()}
      </div>

    );
  }
}

export default withRouter(connect(select, {
  checkAuthCode,
  getLpoa,
  deleteLpoa,
  get2FAPrecondition,
  getRSAPrecondition
})(LanguageHOC(Lpoa)));
