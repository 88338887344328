/*
*
* AccountOwnerStep Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import { GETaddress } from 'utils/helpers/api_handler';
import * as validator from 'utils/helpers/form_validation';
import { saveAccountOwner } from '../actions';
import { getNotifications, } from 'components/AppRoot/Navigation/actions';
import {
  Button,
  Paper,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import {
  AddressTextFields,
  Checkbox,
  DatePicker,
  IconBtnTooltip,
  InfoIcon,
  notificationShow,
  PhoneNumberInput,
  SsnInput,
} from '@frontend/common';

import styles from './styles.module.scss';

const errorTabStyles = {
  backgroundColor: 'rgba(255, 0, 0, 0.25)',
  color: 'rgb(255, 0, 0)',
};

const select = (state) => ({
  accountOwnerInfo: state.signup.accountOwnerInfo,
  ADDRESS_TYPES: state.static.environmentVars.AddressTypes,
  claims: state.session.claims,
  isAgent_AccessRole: state.session.isAgent_AccessRole,
  MaximumAge: state.static.environmentVars.MaximumAge,
  MinimumAccountOwnerAge: state.static.environmentVars.MinimumAccountOwnerAge,
  usStates: state.static.usStates,
});


export class AccountOwnerStep extends React.Component {

  static propTypes = {
    getNotifications: PropTypes.func.isRequired,
    accountOwnerInfo: PropTypes.shape({
      encryptedDataExists: PropTypes.bool,
      Mailing: PropTypes.shape({
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
      }),
      OwnerBirthdate: PropTypes.string,
      OwnerFirstName: PropTypes.string,
      OwnerHomePhone: PropTypes.string,
      OwnerIsResident: PropTypes.bool,
      OwnerLastName: PropTypes.string,
      OwnerMiddleName: PropTypes.string,
      OwnerSSN1: PropTypes.string,
      OwnerSSN2: PropTypes.string,
      Physical: PropTypes.shape({
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
      })
    }).isRequired,
    ADDRESS_TYPES: PropTypes.shape({
      Physical: PropTypes.string,
      Mailing: PropTypes.string,
    }).isRequired,
    claims: PropTypes.object.isRequired,
    getSignupApplication: PropTypes.func.isRequired,
    isAgent_AccessRole: PropTypes.bool.isRequired,
    MaximumAge: PropTypes.number.isRequired,
    MinimumAccountOwnerAge: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    saveAccountOwner: PropTypes.func.isRequired,
    setLoadingOff: PropTypes.func.isRequired,
    setLoadingOn: PropTypes.func.isRequired,
    text: PropTypes.shape({
      AccountOwnerStep: PropTypes.shape({
        btn_continue: PropTypes.string,
        err_copy_ssn: PropTypes.string,
        err_smarty_streets: PropTypes.string,
        err_ssns_must_match: PropTypes.string,
        head_account_owner: PropTypes.string,
        lbl_birthdate: PropTypes.string,
        lbl_clear_state: PropTypes.string,
        lbl_firstName: PropTypes.string,
        lbl_homePhone: PropTypes.string,
        lbl_lastName: PropTypes.string,
        lbl_middleName: PropTypes.string,
        lbl_ssn_tin: PropTypes.string,
        lbl_ssn_tin_confirm: PropTypes.string,
        lbl_utah_tax_payer: PropTypes.string,
        msg_ao_info_saved: PropTypes.string,
        text_what_is_ao: PropTypes.array,
      }),
      AddressCard: PropTypes.object,
    }).isRequired,
    usStates: PropTypes.array.isRequired,
  }

  state = {
    accountOwnerInfo: this.props.accountOwnerInfo,
    addressSuggestions: [],
    addressType: this.props.ADDRESS_TYPES.Mailing,
    apiErrors: [],
    autocompleteText: {
      Mailing: this.props.accountOwnerInfo.Mailing.StreetAddress1,
      Physical: this.props.accountOwnerInfo.Physical.StreetAddress1,
    },
    editSsn: true,
    formErrors: {
      Mailing: {},
      Physical: {}
    },
  };

  addressTabChange = (e, value) => {
    this.setState({ addressType: value });
  };

  autocompleteAddressSelect = (event, selection) => {
    const { accountOwnerInfo, addressType, autocompleteText, formErrors } = this.state;

    if (!selection) { // if user clears input
      this.setState({
        autocompleteText: {
          ...accountOwnerInfo[addressType],
          [addressType]: '',
        },
        accountOwnerInfo: {
          ...accountOwnerInfo,
          [addressType]: {
            ...accountOwnerInfo[addressType],
            StreetAddress1: ''
          }
        },
        addressSuggestions: [],
      });
    }
    else if (!Object.prototype.hasOwnProperty.call(selection, 'street_line')) { // if user sends too many requests in a short period, autocomplete stops sending suggestions back, so just set input to state
      this.setState({
        autocompleteText: {
          ...autocompleteText,
          [addressType]: selection
        },
        accountOwnerInfo: {
          ...accountOwnerInfo,
          [addressType]: {
            ...accountOwnerInfo[addressType],
            StreetAddress1: selection,
          }
        }
      });
    }
    else { // if all is working as expected and selection is valid SmartyStreets object
      this.setState({
        autocompleteText: {
          ...autocompleteText,
          [addressType]: selection.street_line.toUpperCase()
        },
        accountOwnerInfo: {
          ...accountOwnerInfo,
          [addressType]: {
            ...accountOwnerInfo[addressType],
            City: selection.city.toUpperCase(),
            PostalCode: selection.zipcode.toUpperCase(),
            State: selection.state.toUpperCase(),
            StreetAddress1: selection.street_line.toUpperCase(),
            StreetAddress2: selection.secondary.toUpperCase()
          }
        },
        apiErrors: [],
        formErrors: {
          ...formErrors,
          [addressType]: {}
        }
      });
    }
  }

  autocompleteSuggestionsGet = event => {
    const { accountOwnerInfo, addressType, apiErrors, autocompleteText, formErrors } = this.state;

    this.setState({
      apiErrors: {
        ...apiErrors,
        [addressType]: []
      },
      autocompleteText: {
        ...autocompleteText,
        [addressType]: event.target.value
      },
      accountOwnerInfo: {
        ...accountOwnerInfo,
        [addressType]: {
          ...accountOwnerInfo[addressType],
          StreetAddress1: event.target.value
        }
      },
      formErrors: {
        ...formErrors,
        [addressType]: {
          ...formErrors[addressType],
          StreetAddress1: ''
        }
      }
    });

    if (event.target.value.trim()) { // Only make a request if there is text. Trims out leading spaces to avoid a Smarty Streets freakout
      return GETaddress(event.target.value)
        .then(res => {
          if (res.data.suggestions !== null) {
            this.setState({ addressSuggestions: res.data.suggestions });
          }
        })
        .catch(res => {
          if (res !== 'short-criteria') {
            this.props.notificationShow(this.props.text.AddressCard.err_smarty_streets, 'error');
          }
          this.setState({ addressSuggestions: [] });
        });
    }
  }

  formValidate = () => {
    const { accountOwnerInfo, apiErrors, editSsn } = this.state;
    const { ADDRESS_TYPES, MinimumAccountOwnerAge, MaximumAge, text: { AccountOwnerStep } } = this.props;
    const formErrors = { ...this.state.formErrors };

    // If editing, validate SSN. Encrypted SSN will not run through the validator.
    formErrors.OwnerSSN1 = editSsn ? validator.socialSecurityNumValidator(accountOwnerInfo.OwnerSSN1) : '';
    formErrors.OwnerSSN2 = editSsn ? validator.socialSecurityNumValidator(accountOwnerInfo.OwnerSSN2) : '';

    if (!formErrors.OwnerSSN2 && editSsn && accountOwnerInfo.OwnerSSN1 !== accountOwnerInfo.OwnerSSN2) { // checks if there is SSN2 error and gives that priority
      formErrors.OwnerSSN2 = AccountOwnerStep.err_ssns_must_match;
    }

    formErrors.OwnerBirthdate = validator.accountOwnerBirthdateValidator(accountOwnerInfo.OwnerBirthdate, MinimumAccountOwnerAge, MaximumAge);
    formErrors.OwnerFirstName = validator.firstNameValidator(accountOwnerInfo.OwnerFirstName);
    formErrors.OwnerLastName = validator.lastNameValidator(accountOwnerInfo.OwnerLastName);
    formErrors.OwnerHomePhone = validator.phoneNumValidator(accountOwnerInfo.OwnerHomePhone);
    formErrors.Mailing.StreetAddress1 = validator.street1Validator(accountOwnerInfo.Mailing.StreetAddress1);
    accountOwnerInfo.Mailing.StreetAddress2 && (formErrors.Mailing.StreetAddress2 = validator.street1Validator(accountOwnerInfo.Mailing.StreetAddress2));
    formErrors.Mailing.City = validator.cityValidator(accountOwnerInfo.Mailing.City);
    formErrors.Mailing.State = validator.stateValidator(accountOwnerInfo.Mailing.State);
    formErrors.Mailing.PostalCode = validator.postalCodeValidator(accountOwnerInfo.Mailing.PostalCode);
    formErrors.OwnerMiddleName = validator.middleNameValidator(accountOwnerInfo.OwnerMiddleName);

    if (Object.keys(accountOwnerInfo.Physical).some(field => accountOwnerInfo.Physical[field] !== '')) { // if Physical address has anything in it, validation will run
      formErrors.Physical.StreetAddress1 = validator.street1Validator(accountOwnerInfo.Physical.StreetAddress1);
      accountOwnerInfo.Physical.StreetAddress2 && (formErrors.Physical.StreetAddress2 = validator.street1Validator(accountOwnerInfo.Physical.StreetAddress2));
      formErrors.Physical.City = validator.cityValidator(accountOwnerInfo.Physical.City);
      formErrors.Physical.State = validator.stateValidator(accountOwnerInfo.Physical.State);
      formErrors.Physical.PostalCode = validator.postalCodeValidator(accountOwnerInfo.Physical.PostalCode);
    }
    else {
      formErrors.Physical.StreetAddress1 = '';
      formErrors.Physical.City = '';
      formErrors.Physical.State = '';
      formErrors.Physical.PostalCode = '';
    }

    if (apiErrors.length > 0) {
      apiErrors.forEach(error => {
        if (error.Field.includes(ADDRESS_TYPES.Mailing)) {
          switch (error.Field) {
            case 'OwnerMailingStreetAddress1': formErrors.Mailing.StreetAddress1 = error.Message; break;
            case 'OwnerMailingStreetAddress2': formErrors.Mailing.StreetAddress2 = error.Message; break;
            case 'OwnerMailingCity': formErrors.Mailing.City = error.Message; break;
            case 'OwnerMailingPostalCode': formErrors.Mailing.PostalCode = error.Message; break;
            case 'OwnerMailingState': formErrors.Mailing.State = error.Message; break;
            default: break;
          }
        }
        else if (error.Field.includes(ADDRESS_TYPES.Physical)) {
          switch (error.Field) {
            case 'OwnerPhysicalStreetAddress1': formErrors.Physical.StreetAddress1 = error.Message; break;
            case 'OwnerPhysicalStreetAddress2': formErrors.Physical.StreetAddress2 = error.Message; break;
            case 'OwnerPhysicalCity': formErrors.Physical.City = error.Message; break;
            case 'OwnerPhysicalPostalCode': formErrors.Physical.PostalCode = error.Message; break;
            case 'OwnerPhysicalState': formErrors.Physical.State = error.Message; break;
            default: break;
          }
        }
        else {
          formErrors[error.Field] = error.Message;
        }
      });
    }

    this.setState({ formErrors });

    const hasMailingErrors = Object.keys(formErrors.Mailing).some(mailingError => formErrors.Mailing[mailingError] !== '');
    const hasPhysicalErrors = Object.keys(formErrors.Physical).some(physicalError => formErrors.Physical[physicalError] !== '');
    const formWithoutAddresses = Object.keys(formErrors).filter(field => field !== ADDRESS_TYPES.Mailing && field !== ADDRESS_TYPES.Physical);
    const hasOtherErrors = formWithoutAddresses.some(error => formErrors[error] !== '');

    return !hasMailingErrors && !hasPhysicalErrors && !hasOtherErrors;
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    const isValid = this.formValidate();
    const { accountOwnerInfo } = this.state;
    const { claims: { AgentId, InterestedPartyId }, text: { AccountOwnerStep: { msg_ao_info_saved } } } = this.props;


    if (isValid) {
      this.props.setLoadingOn();

      const mappedAccountOwner = {
        ...accountOwnerInfo,
        AgentId,
        InterestedPartyId,
        OwnerBirthdate: dayjs(accountOwnerInfo.OwnerBirthdate).format('L'),
        OwnerSSN1: accountOwnerInfo.encryptedDataExists && accountOwnerInfo.OwnerSSN1.length === 4 ? `xxx-xx-${accountOwnerInfo.OwnerSSN1}` : accountOwnerInfo.OwnerSSN1, // if encrypted ssn, sends back expected format, otherwise passes value
        OwnerSSN2: accountOwnerInfo.encryptedDataExists && accountOwnerInfo.OwnerSSN2.length === 4 ? `xxx-xx-${accountOwnerInfo.OwnerSSN2}` : accountOwnerInfo.OwnerSSN2, // if encrypted ssn, sends back expected format, otherwise passes value
        OwnerMailingStreetAddress1: accountOwnerInfo.Mailing.StreetAddress1,
        OwnerMailingStreetAddress2: accountOwnerInfo.Mailing.StreetAddress2,
        OwnerMailingCity: accountOwnerInfo.Mailing.City,
        OwnerMailingState: accountOwnerInfo.Mailing.State,
        OwnerMailingPostalCode: accountOwnerInfo.Mailing.PostalCode,
        OwnerPhysicalStreetAddress1: accountOwnerInfo.Physical.StreetAddress1,
        OwnerPhysicalStreetAddress2: accountOwnerInfo.Physical.StreetAddress2,
        OwnerPhysicalCity: accountOwnerInfo.Physical.City,
        OwnerPhysicalState: accountOwnerInfo.Physical.State,
        OwnerPhysicalPostalCode: accountOwnerInfo.Physical.PostalCode,
      };

      this.props.saveAccountOwner(mappedAccountOwner)
        .then(() => {
          this.props.notificationShow(msg_ao_info_saved, 'success');
          this.props.getNotifications(); // refreshes app notifications
          this.props.getSignupApplication()
            .then(() => {
              this.props.setLoadingOff();
              this.props.onNextClick();
            })
            .catch(() => this.props.setLoadingOff);
        })
        .catch(errors => {
          this.props.setLoadingOff();
          this.setState({
            apiErrors: errors.payload.data,
          }, () => this.formValidate());
        });
    }
  }

  onAddressChange = (name, e) => {
    const { addressType } = this.state;

    this.setState({
      accountOwnerInfo: {
        ...this.state.accountOwnerInfo,
        [addressType]: {
          ...this.state.accountOwnerInfo[addressType],
          [name]: e.target.value
        }
      },
      apiErrors: [],
      formErrors: {
        ...this.state.formErrors,
        [addressType]: {
          ...this.state.formErrors[addressType],
          [name]: ''
        }
      }
    });
  }

  onEditSsn = () => {
    this.setState({
      accountOwnerInfo: {
        ...this.state.accountOwnerInfo,
        OwnerSSN1: '',
        OwnerSSN2: ''
      },
      editSsn: true,
    });
  }

  onInputChange = (e) => {
    const { apiErrors } = this.state;
    if (apiErrors.length > 0) { // removes API error object for related input being changed
      const apiErrorsModified = [...this.state.apiErrors];
      const errorIndex = apiErrorsModified.findIndex(error => error.Field === e.target.name); // gets index of error that relates to input being changed
      apiErrorsModified.splice(errorIndex, 1);

      this.setState({ apiErrors: apiErrorsModified });
    }

    this.setState({
      accountOwnerInfo: {
        ...this.state.accountOwnerInfo,
        [e.target.name]: e.target.value
      },
      apiErrors: [],
      formErrors: {
        ...this.state.formErrors,
        [e.target.name]: ''
      }
    });
  }

  preventCopy = (e) => {
    const formErrors = cloneDeep(this.state.formErrors);
    const confirmSsnInput = e.target;

    confirmSsnInput.onpaste = e => {
      e.preventDefault();
      formErrors.OwnerSSN2 = this.props.text.AccountOwnerStep.err_copy_ssn;
      this.setState({ formErrors });
    };
  }

  ssnCompose = () => { // Handles encrypted SSN inputs
    const { accountOwnerInfo: { encryptedDataExists }, isAgent_AccessRole, isLoading, text: { AccountOwnerStep } } = this.props;
    const { accountOwnerInfo: { OwnerSSN1, OwnerSSN2 }, editSsn, formErrors } = this.state;

    if (encryptedDataExists && !editSsn) { // if the birthdate is encrypted, and user is NOT editing
      return (
        <div className={styles.ssnContainer}>
          <div className={styles.ssnInputs}>
            <TextField
              disabled
              fullWidth
              label={AccountOwnerStep.lbl_ssn_tin}
              name='OwnerSSN1'
              value={OwnerSSN1}
              variant='filled'
            />
            {!isAgent_AccessRole && // hides the edit icon if AO is agent
              <IconBtnTooltip
                buttonProps={{ style: { marginLeft: '3px' } }}
                icon='create'
                onClick={this.onEditSsn}
              />
            }
          </div>
          <div className={styles.ssnInputs}>
            <TextField
              disabled
              fullWidth
              label={AccountOwnerStep.lbl_ssn_tin_confirm}
              name='OwnerSSN2'
              value={OwnerSSN2}
              variant='filled'
            />
            {!isAgent_AccessRole && // hides the edit icon if AO is agent
              <IconBtnTooltip
                buttonProps={{ style: { marginLeft: '3px' } }}
                icon='create'
                onClick={this.onEditSsn}
              />
            }
          </div>

        </div>
      );
    }
    else if (!encryptedDataExists || editSsn) { // if the user does not have an encrypted SSN, or if they do but are editing
      return (
        <div>
          <SsnInput
            disabled={isLoading || isAgent_AccessRole}
            error={Boolean(formErrors.OwnerSSN1)}
            helperText={formErrors.OwnerSSN1}
            fullWidth
            label={AccountOwnerStep.lbl_ssn_tin}
            onChange={({ value }) => this.onInputChange({ target: { name: 'OwnerSSN1', value } })}
            value={OwnerSSN1}
            variant='filled'
          />
          <SsnInput
            disabled={isLoading || isAgent_AccessRole}
            error={Boolean(formErrors.OwnerSSN2)}
            helperText={formErrors.OwnerSSN2}
            fullWidth
            label={AccountOwnerStep.lbl_ssn_tin_confirm}
            onChange={({ value }) => this.onInputChange({ target: { name: 'OwnerSSN2', value } })}
            onFocus={e => this.preventCopy(e)}
            value={OwnerSSN2}
            variant='filled'
          />

        </div>
      );
    }
  }

  utahTaxpayerToggle = e => {
    this.setState({
      accountOwnerInfo: {
        ...this.state.accountOwnerInfo,
        OwnerIsResident: e.target.checked,
      }
    });
  }

  componentDidMount() {
    if (this.props.accountOwnerInfo.encryptedDataExists) {
      this.setState({
        editSsn: false,
      });
    }
  }

  render() {
    const { accountOwnerInfo, addressSuggestions, addressType, autocompleteText, formErrors } = this.state;
    const { ADDRESS_TYPES, isAgent_AccessRole, isLoading, MaximumAge, MinimumAccountOwnerAge, text: { AccountOwnerStep }, usStates } = this.props;

    return (
      <Paper>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h1>{AccountOwnerStep.head_account_owner}</h1>
            <InfoIcon message={<ul>{AccountOwnerStep.text_what_is_ao.map(line => <li key={line}>{line}</li>)}</ul>} />
          </div>
          <form onSubmit={this.onFormSubmit} className={styles.form}>
            <div className={styles.infoContainer}>
              <TextField
                autoComplete='given-name'
                disabled={isLoading || isAgent_AccessRole}
                error={Boolean(formErrors.OwnerFirstName)}
                variant='filled'
                fullWidth
                helperText={formErrors.OwnerFirstName}
                label={AccountOwnerStep.lbl_firstName}
                name='OwnerFirstName'
                onChange={this.onInputChange}
                value={accountOwnerInfo.OwnerFirstName}
              />
              <TextField
                autoComplete='additional-name'
                disabled={isLoading || isAgent_AccessRole}
                error={Boolean(formErrors.OwnerMiddleName)}
                variant='filled'
                fullWidth
                helperText={formErrors.OwnerMiddleName}
                label={AccountOwnerStep.lbl_middleName}
                name='OwnerMiddleName'
                onChange={this.onInputChange}
                value={accountOwnerInfo.OwnerMiddleName}
              />
              <TextField
                autoComplete='family-name'
                disabled={isLoading || isAgent_AccessRole}
                error={Boolean(formErrors.OwnerLastName)}
                fullWidth
                helperText={formErrors.OwnerLastName}
                label={AccountOwnerStep.lbl_lastName}
                name='OwnerLastName'
                onChange={this.onInputChange}
                value={accountOwnerInfo.OwnerLastName}
                variant='filled'
              />
              <DatePicker // The label formatting (labelFunc), value and date passed to onChange need to be specific to get the date to clear
                error={Boolean(formErrors.OwnerBirthdate)}
                disabled={isLoading || isAgent_AccessRole}
                fullWidth
                helperText={formErrors.OwnerBirthdate}
                variant='filled'
                key='birthDate'
                label={AccountOwnerStep.lbl_birthdate}
                maxDate={dayjs().subtract(MinimumAccountOwnerAge, 'year')}
                minDate={dayjs().subtract(MaximumAge, 'year')}
                onChange={value => this.onInputChange({ target: { name: 'OwnerBirthdate', value, } })}
                openTo='year'
                value={accountOwnerInfo.OwnerBirthdate}
              />
              {this.ssnCompose()}
              <PhoneNumberInput
                error={Boolean(formErrors.OwnerHomePhone)}
                errorText={formErrors.OwnerHomePhone}
                disabled={isLoading || isAgent_AccessRole}
                fullWidth
                label={AccountOwnerStep.lbl_homePhone}
                name='OwnerHomePhone'
                onChange={({ value }) => this.onInputChange({ target: { name: 'OwnerHomePhone', value } })}
                value={accountOwnerInfo.OwnerHomePhone}
                variant='filled'
              />
            </div>
            <div className={styles.addressesContainer}>
              <Tabs
                centered
                onChange={this.addressTabChange}
                value={addressType}
              >
                <Tab
                  label={ADDRESS_TYPES.Mailing}
                  style={Object.keys(formErrors.Mailing).some(error => formErrors.Mailing[error] !== '') ? errorTabStyles : {}}
                  value={ADDRESS_TYPES.Mailing}
                />
                <Tab
                  label={ADDRESS_TYPES.Physical}
                  style={Object.keys(formErrors.Physical).some(error => formErrors.Physical[error] !== '') ? errorTabStyles : {}}
                  value={ADDRESS_TYPES.Physical}
                />
              </Tabs>
              <AddressTextFields
                address={accountOwnerInfo[addressType]}
                addressSuggestions={addressSuggestions}
                autocompleteProps={{ disabled: isLoading || isAgent_AccessRole }}
                autocompleteSuggestionsGet={this.autocompleteSuggestionsGet}
                autocompleteText={autocompleteText[addressType]}
                errorGet={inputName => this.state.formErrors[this.state.addressType][inputName]}
                inputProps={{ disabled: isLoading || isAgent_AccessRole }}
                isReadOnly={false}
                onAutocompleteAddressSelect={this.autocompleteAddressSelect}
                onInputChange={this.onAddressChange}
                stateFormControlProps={{ variant: 'filled' }}
                textFieldProps={{ disabled: isLoading || isAgent_AccessRole, variant: 'filled' }}
                usStates={usStates}
              />
            </div>
            <div className={styles.residentContainer}>
              <Checkbox
                checked={accountOwnerInfo.OwnerIsResident}
                disabled={isLoading || isAgent_AccessRole}
                label={AccountOwnerStep.lbl_utah_tax_payer}
                onChange={this.utahTaxpayerToggle}
                color='primary'
              />
            </div>
            <div className={styles.stepperButtons}>
              <Button
                color='primary'
                onClick={(e) => isAgent_AccessRole ? this.props.onNextClick() : this.onFormSubmit(e)} // existing AOs cannot edit their info, so continue to Bene without saving
                variant='contained'
                fullWidth
                type='submit'
              >
                {AccountOwnerStep.btn_continue}
              </Button>
            </div>
          </form>
        </div>
      </Paper>
    );
  }
}


export default connect(select, {
  getNotifications,
  notificationShow,
  saveAccountOwner,
})(LanguageHOC(AccountOwnerStep));
