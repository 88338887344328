import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { TextField } from '@mui/material';
import { Modal } from '@frontend/common';

import { editNickName, getBankAccounts } from '../actions';

// const select = (state) => ({
//   allowedBankHolders: state.payments.allowedBankHolders,
// });

export class EditBankModal extends React.Component {

  static propTypes = {
    editNickName: PropTypes.func.isRequired,
    getBankAccounts: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    isMobileView: PropTypes.bool.isRequired,
    bankAccount: PropTypes.object.isRequired,
    text: PropTypes.shape({
      EditBankModal: PropTypes.shape({
        btn_save_nickname: PropTypes.string,
        err_allowable_chars: PropTypes.string,
        lbl_nickname: PropTypes.string,
        title_modal: PropTypes.string,
      }),
    })
  }

  state = {
    formErrors: {},
    nickname: '',
    isSaving: false
  }

  onInputChange = e => {
    const val = e.target.value;
    this.setState({ nickname: val });
  }

  formValidate = () => {
    const { err_allowable_chars } = this.props.text.EditBankModal;
    const { nickname } = this.state;
    const formErrors = {};

    const regex = /^[A-Za-z0-9.\s.,':#/-]+$/; // case insensitive, space, period, comma, single quote and hyphen.
    if (nickname && !regex.test(nickname)) {
      formErrors.nickname = err_allowable_chars;
    }

    this.setState({ formErrors });
    return Object.keys(formErrors).length === 0; // returns True or False whether form is valid
  }

  onFormSubmit() {
    if (this.formValidate()) {
      const { BankAccountId } = this.props.bankAccount;
      const { nickname } = this.state;
      this.setState({ isSaving: true });
      this.props.editNickName(BankAccountId, nickname)
        // refresh bank accounts so it has the latest bank nicknames
        .then(() => this.props.getBankAccounts())
        .then(() => this.setState({ isSaving: false }))
        .then(() => this.props.close())
        .catch(() => this.setState({ isSaving: false }));
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.bankAccount, this.props.bankAccount)
      && this.props.bankAccount.Nickname && this.props.bankAccount.Nickname.length > 0) {
      this.setState({ nickname: this.props.bankAccount.Nickname });
    }
  }

  render() {
    const { formErrors, isSaving, nickname } = this.state;
    const { open, close, isMobileView, text: { EditBankModal } } = this.props;
    return (
      <Modal
        show={open}
        maxWidth='md'
        fullWidth
        title={EditBankModal.title_modal}
        onCloseModal={close}
        DialogProps={{ 'aria-labelledby': EditBankModal.title_modal, 'fullScreen': isMobileView }}
        includeLeftCancelBtn
        actionButtons={[
          {
            action: () => this.onFormSubmit(),
            label: EditBankModal.btn_save_nickname,
            disabled: isSaving,
            loading: isSaving,
          },
        ]}
      >
        <div>
          <TextField
            disabled={isSaving}
            error={Boolean(formErrors.nickname)}
            variant='filled'
            fullWidth
            helperText={formErrors.nickname}
            label={EditBankModal.lbl_nickname}
            name='Nickname'
            onChange={this.onInputChange}
            value={nickname}
            style={{ height: '99px' }}
            inputProps={{ maxLength: '40' }}
          />
        </div>
      </Modal>
    );
  }
}

export default connect(null, { editNickName, getBankAccounts })(LanguageHOC(EditBankModal));