import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { IconBtnTooltip } from '@frontend/common';
import {
  Drawer,
} from '@mui/material';

import styles from './styles.module.scss';

export function LearnMoreDrawer({ open, onClose, text: { Lpoa } }) {

  return (
    <Drawer anchor='bottom' open={open} onClose={onClose}>
      <div className={styles.learnMoreContainer}>
        <div className={styles.closeIcon}>
          <IconBtnTooltip
            icon='close'
            onClick={onClose}
          />
        </div>
        <hr className={styles.divider} />
        <h3 className={styles.learnMoreInfoHeader}>{Lpoa.learn_more_title}</h3>
        <div className={styles.learnMoreInfo}>
          {Lpoa.text_learn_more}
        </div>
      </div>
    </Drawer>
  );
}

LearnMoreDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.shape({
    Lpoa: PropTypes.shape({
      learn_more_title: PropTypes.string,
      text_learn_more: PropTypes.string,
    }),
  }),
};

export default LanguageHOC(LearnMoreDrawer);