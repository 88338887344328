/*
*
* LearnMore Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Drawer,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
} from '@mui/material';
import { IconBtnTooltip } from '@frontend/common';

import styles from './styles.module.scss';


export function LearnMore({ text: { InterestedParties }, onClose, open }) {
  return (
    <Drawer anchor='bottom' onClose={onClose} open={open}>
      <div className={styles.container}>
        <IconBtnTooltip
          icon='close'
          onClick={onClose}
        />
        <h3>{InterestedParties.head_interested_parties}</h3>
        <p>{InterestedParties.text_learn_more()}</p>
      </div>
      {InterestedParties.text_faq_questions.map((question, idx) => (
        <Accordion key={question}>
          <AccordionSummary
            aria-controls='faq1-content'
            expandIcon={<Icon>expand_less</Icon>}
            id='faq1-header'
          >
            {question}
          </AccordionSummary>
          <AccordionDetails>{InterestedParties.text_faq_answers()[idx]}</AccordionDetails>
        </Accordion>
      ))}
    </Drawer>
  );
}

LearnMore.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.shape({
    InterestedParties: PropTypes.shape({
      btn_add_ip: PropTypes.string,
      head_interested_parties: PropTypes.string,
      text_faq_answers: PropTypes.func,
      text_faq_questions: PropTypes.array,
      text_learn_more: PropTypes.func,
    }),
  }).isRequired,
};

export default LanguageHOC(LearnMore);