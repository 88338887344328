/* eslint-disable newline-per-chained-call */
/*
*
* OneTime Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import { isEqual, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import * as validator from 'utils/helpers/form_validation';
import {
  Card,
  notificationShow,
} from '@frontend/common';
import {
  ONE_TIME
} from '../constants';
import TransactionStepper from 'components/Features/protected/Accounts/Transactions/TransactionStepper';
import { Step0Amount, Step1DateAndBank, Step2Terms, Step3PrintReview, } from 'components/Features/protected/Accounts/Transactions/Contributions/OneTime/StepComponents/';


import styles from './styles.module.scss';

const select = (state) => ({
  contributionEnvironmentVars: state.static.environmentVars,
  contributionDetails: state.transactions.contributionDetails,
  agentBankAccounts: state.payments.agentBankAccounts,
});

export class OneTime extends React.Component {
  static propTypes = {
    agentBankAccounts: PropTypes.array.isRequired,
    accountId: PropTypes.number.isRequired,
    bankAccountsLoading: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    contributionCreate: PropTypes.func.isRequired,
    contributionEdit: PropTypes.func.isRequired,
    toggleShowAddBank: PropTypes.func.isRequired,
    renderDuplicateConfirmModal: PropTypes.func.isRequired,
    duplicateContributionModalOpen: PropTypes.bool.isRequired,
    createdDate: PropTypes.string.isRequired,
    contributionEnvironmentVars: PropTypes.shape({
      FaxNumber: PropTypes.string,
      CurrentTradeDate: PropTypes.string,
      ValidOneTimeContributionDates: PropTypes.array,
      MaximumOneTimeOnlineContribution: PropTypes.number,
      MinimumOneTimeOnlineContribution: PropTypes.number,
      MaximumOnlineContributionAmount: PropTypes.number,
      MaximumOnlineContributionDays: PropTypes.number,
      SupportPhoneNumber: PropTypes.string,
    }).isRequired,
    contributionDetails: PropTypes.shape({
      canContributeAccountList: PropTypes.array.isRequired,
      contribution: PropTypes.object.isRequired,
    }),
    isEditing: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      ContributionOneTime: PropTypes.shape({
        err_account_select: PropTypes.string,
        err_bankAccount_select: PropTypes.string,
        err_step_does_not_exist: PropTypes.string,
        err_valid_amount: PropTypes.string,
        lbl_step_labels: PropTypes.array,
        msg_unsaved_changes: PropTypes.string,
      })
    }),
  }

  state = {
    contribution: {
      bankAccount: {
        BankAccountId: '',
        BankName: '',
        MaskedBankAccountNumber: '',
      },
      day1: this.props.contributionEnvironmentVars.CurrentTradeDate,
      selectedAccounts: [],
      type: ONE_TIME
    },
    contributionScreenshot: {
      bankAccount: {
        BankAccountId: '',
        BankName: '',
        MaskedBankAccountNumber: '',
      },
      day1: this.props.contributionEnvironmentVars.CurrentTradeDate,
      selectedAccounts: [],
      type: ONE_TIME
    },
    errors: [],
    step0ValidationHasRun: false,
    step1ValidationHasRun: false,
    termsChecked: false,
    stepHasError: false,
  }

  displayUnsavedChangesPrompt = () => {
    return !this.state.bankAccountsLoading && !isEqual(this.state.contribution, this.state.contributionScreenshot);
  }

  clearStepErrors = () => {
    this.setState({
      errors: [],
      stepHasError: false,
    });
  }

  formValidate = (step) => {
    const { ContributionOneTime } = this.props.text;
    const { MaximumOneTimeOnlineContribution, MinimumOneTimeOnlineContribution, ValidOneTimeContributionDates, } = this.props.contributionEnvironmentVars;
    const { selectedAccounts, bankAccount, day1 } = this.state.contribution;
    const errors = [];

    switch (step) {
      case 0: {
        selectedAccounts.forEach(({ accountId, contributionAmount }, index) => {
          const message = validator.amountValidator(contributionAmount, MinimumOneTimeOnlineContribution, MaximumOneTimeOnlineContribution); // validates amounts against min and max parameters
          if (accountId && message) {
            errors.push({
              key: 'amount',
              message,
              row: index
            });
          }
          if (!accountId && contributionAmount) { // validates all accounts that also have amounts entered have a beneficiary selected
            errors.push({
              key: 'account',
              message: ContributionOneTime.err_account_select,
              row: index
            });
          }
        });
        this.setState({ errors, step0ValidationHasRun: true, stepHasError: errors.length > 0 });
        break;
      }

      case 1: {
        if (!bankAccount.BankAccountId) {
          errors.push({
            key: 'bankAccount',
            message: ContributionOneTime.err_bankAccount_select,
          });
        }
        const message = validator.calendarValidator(dayjs(day1), dayjs(ValidOneTimeContributionDates[0]),
          dayjs(ValidOneTimeContributionDates[ValidOneTimeContributionDates.length - 1]));
        if (message) {
          errors.push({
            key: 'day1',
            message,
          });
        }
        this.setState({ errors, step1ValidationHasRun: true, stepHasError: errors.length > 0 });
        break;
      }
      default: break;
    }
    return errors.length === 0;
  }

  errorGet = (key, row) => {
    const error = this.state.errors.find(error => error.key === key && error.row === row);
    return error ? error.message : '';
  }

  contributionSet = (step, key, value) => {
    this.setState(({ contribution }) => {
      return { contribution: { ...contribution, [key]: value } };
    }, () => {
      if (
        (step === 0 && this.state.step0ValidationHasRun) ||
        (step === 1 && this.state.step1ValidationHasRun)
      ) {
        this.formValidate(step);
      }
    });
  }

  accountSelect = (value, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    const selectedAccount = this.props.contributionDetails.canContributeAccountList.find(account => account.accountId === value);
    const accountUpdatedWithAmount = { ...selectedAccount, contributionAmount: selectedAccounts[rowIndex].contributionAmount };

    selectedAccounts.splice(rowIndex, 1, accountUpdatedWithAmount);
    this.contributionSet(0, 'selectedAccounts', selectedAccounts);
  }

  amountHandle = ({ floatValue = 0 }, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    selectedAccounts[rowIndex].contributionAmount = floatValue;
    this.contributionSet(0, 'selectedAccounts', selectedAccounts);
  }

  addAccount = () => {
    const { contribution: { selectedAccounts } } = this.state;
    const newSelectedAccounts = [...selectedAccounts, { accountId: '', contributionAmount: 0 }];
    this.contributionSet(0, 'selectedAccounts', newSelectedAccounts);
  }

  removeAccount = (rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    selectedAccounts.splice(rowIndex, 1);
    this.contributionSet(0, 'selectedAccounts', selectedAccounts);
  }

  contributionDateHandle = (e) => {
    this.contributionSet(1, 'day1', e);
  }

  contributionDatesDisable = (date) => {
    return this.props.contributionEnvironmentVars.ValidOneTimeContributionDates.every(validContributionDate => {
      return !dayjs(validContributionDate).isSame(dayjs(date), 'day') ||
        dayjs(this.props.contributionEnvironmentVars.CurrentTradeDate).isAfter(dayjs(date), 'day');
    });
  }

  bankAccountSelectHandle = (value) => {
    this.contributionSet(1, 'bankAccount', this.props.agentBankAccounts.find(bankAccount => bankAccount.BankAccountId === value));
  }

  contributionCreateHandler = () => {
    return this.props.contributionCreate(this.state.contribution)
      .then(() => this.setState({ contributionScreenshot: cloneDeep(this.state.contribution) }));
  }

  contributionEditHandler = () => {
    return this.props.contributionEdit(this.state.contribution)
      .then(() => this.setState({ contributionScreenshot: cloneDeep(this.state.contribution) }));
  }

  renderStepComponent = step => {
    const { agentBankAccounts, bankAccountsLoading, contributionDetails, contributionEnvironmentVars, isEditing, toggleShowAddBank, createdDate, text: { ContributionOneTime } } = this.props;
    const { contribution, errors, termsChecked, } = this.state;
    let stepComponent;

    switch (step) {
      case 0: {
        stepComponent = (
          <div className={styles.step0Container}>
            <Step0Amount
              isEditing={isEditing}
              selectedAccounts={contribution.selectedAccounts}
              errors={errors}
              contributionDetails={contributionDetails}
              contributionEnvironmentVars={contributionEnvironmentVars}
              errorGet={this.errorGet}
              accountSelect={(value, rowIndex) => this.accountSelect(value, rowIndex)}
              amountHandle={(vals, rowIndex) => this.amountHandle(vals, rowIndex)}
              removeAccount={(rowIndex) => this.removeAccount(rowIndex)}
              addAccount={this.addAccount}
            />
          </div>
        );
        break;
      }

      case 1: {
        stepComponent = (
          <div className={styles.step1Container}>
            <Step1DateAndBank
              isEditing={isEditing}
              contribution={contribution}
              agentBankAccounts={agentBankAccounts}
              bankAccountsLoading={bankAccountsLoading}
              errors={errors}
              contributionDetails={contributionDetails}
              bankAccountSelectHandle={(value) => this.bankAccountSelectHandle(value)}
              toggleShowAddBank={() => toggleShowAddBank()}
              contributionDateHandle={this.contributionDateHandle}
              contributionDatesDisable={this.contributionDatesDisable}
              errorGet={(key) => this.errorGet(key)}
            />
          </div>
        );
        break;
      }

      case 2: {
        stepComponent = (
          <Step2Terms
            contribution={contribution}
            termsChecked={termsChecked}
            phoneEnvironmentVars={{ SupportPhoneNumber: contributionEnvironmentVars.SupportPhoneNumber, FaxNumber: contributionEnvironmentVars.FaxNumber }}
            toggleTermsChecked={() => this.setState({ termsChecked: !termsChecked })}
          />
        );
        break;
      }

      case 3: {
        stepComponent = (
          <Step3PrintReview
            selectedAccounts={contribution.selectedAccounts}
            contribution={contribution}
            createdDate={createdDate}
          />
        );
        break;
      }

      default:
        stepComponent = <div>{ContributionOneTime.err_step_does_not_exist}</div>;
    }
    return stepComponent;
  }

  componentDidMount() {
    const { canContributeAccountList, contribution, } = this.props.contributionDetails;
    if (canContributeAccountList.length > 0) {
      if (this.props.isEditing) {
        this.setState({ contribution, contributionScreenshot: cloneDeep(contribution) });
      }
      else {
        const defaultAccount = canContributeAccountList.find(account => account.accountId === this.props.accountId);
        this.setState(({ contribution }) => {
          const contributionUpdated = {
            ...contribution,
            selectedAccounts: [defaultAccount],
          };

          return ({
            contribution: contributionUpdated,
            contributionScreenshot: cloneDeep(contributionUpdated)
          });
        });
      }
    }

  }

  render() {
    const { isEditing, text, renderDuplicateConfirmModal, } = this.props;
    return (
      <div className={styles.container}>
        <Card>
          <TransactionStepper
            stepHasError={this.state.stepHasError}
            clearStepErrors={this.clearStepErrors}
            verifyStep={(step) => this.formValidate(step)}
            renderStepComponent={(step) => this.renderStepComponent(step)}
            handleSubmit={() => isEditing ? this.contributionEditHandler(this.state.contribution) : this.contributionCreateHandler(this.state.contribution)}
            stepLabels={text.ContributionOneTime.lbl_step_labels}
            submitStep={2}
            termsChecked={this.state.termsChecked}
            renderConfirmModal={renderDuplicateConfirmModal}
          />
        </Card>

        <Prompt
          message={text.ContributionOneTime.msg_unsaved_changes}
          when={this.displayUnsavedChangesPrompt()}
        />
      </div>
    );
  }
}


export default withRouter(connect(select, {
  notificationShow,
})(LanguageHOC(OneTime)));
