/*
*
* Lpoa Reducers
*
*/

// import { cloneDeep } from 'lodash';

// import {
//   CHECK_AUTH_CODE
// } from './constants';

const initialState = {
  authCodeResponse: ''
};

function LpoaReducer(state = initialState, action) {
  
  // const newState = cloneDeep(state);

  switch (action.type) {
    
    // case CHECK_AUTH_CODE: {
    //   newState.authCodeResponse = action.payload.data;
    //   return newState;
    // }

    default:
      return state;
  }

}

export default LpoaReducer;