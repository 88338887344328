/*
*
* Signup Reducers
*
*/
import { cloneDeep } from 'lodash';
import {
  BLANK_BENE_INFO,
  CREATE_ACCOUNT,
  DELETE_APPLICATION,
  GET_EXISTING_BENEFICIARIES,
  GET_EXISTING_BENEFICIARY,
  GET_SIGNUP_APPLICATION,
  RESET_ACCOUNT_APPLICATION,
  SAVE_ACCOUNT_OWNER,
  SAVE_BENEFICIARY,
  SAVE_CUSTOMIZED_OPTION,
  SAVE_PREDEFINED_INVESTMENT_OPTION,
  SAVE_PROMO_CODE,
} from './constants';
import { BLANK_ADDRESS } from 'components/AppRoot/StaticResources/constants';
import { convert2DCalculatorSchemaTo3D } from '@frontend/common';

const initialState = {
  AccountNumber: 0,
  accountOwnerInfo: {
    encryptedDataExists: false,
    Mailing: BLANK_ADDRESS,
    OwnerBirthdate: '',
    OwnerEmail: '',
    OwnerFirstName: '',
    OwnerHomePhone: '',
    OwnerIsResident: false,
    OwnerLastName: '',
    OwnerMiddleName: '',
    OwnerSSN1: '',
    OwnerSSN2: '',
    Physical: BLANK_ADDRESS,
  },
  activeStep: -1,
  beneficiaryInfo: BLANK_BENE_INFO,
  existingBenesDetails: {},
  existingBenesList: [],
  investmentInfo: {
    InvestmentOptionId: 0,
    Template: {
      TemplateName: '',
      FundCategories: [],
    },
  },
  PromotionalCode: '',
  selectedExistingBene: {},
};

function SignupReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case CREATE_ACCOUNT: {
      newState.AccountNumber = action.payload.data.AccountNumber;
      newState.activeStep = 5; // if the user refreshes the page after creating an account, they will remain on the confirmation step

      return newState;
    }

    case DELETE_APPLICATION:
    case GET_SIGNUP_APPLICATION: {
      const { AccountData, Beneficiary, Owner, Template } = action.payload.data;
      newState.accountOwnerInfo = mapAccountOwner(Owner);
      newState.beneficiaryInfo = mapBeneficiary(Beneficiary);
      newState.investmentInfo = { ...AccountData };
      Template && Template.FundCategories.length > 0 && (newState.investmentInfo.Template = convert2DCalculatorSchemaTo3D(Template));
      newState.PromotionalCode = AccountData.PromotionalCode || '';

      newState.activeStep = 0; // Defaults to AO page

      if (newState.investmentInfo.InvestmentOptionId || newState.investmentInfo.Template) {
        newState.activeStep = 3; // Goes to Review page
      }
      else if (newState.beneficiaryInfo.Mailing.StreetAddress1) { // if Mailing StreetAddress1 is populated, info has already been saved
        newState.activeStep = 2; // Goes to Investment Option
      }
      else if (newState.accountOwnerInfo.Mailing.StreetAddress1) { // if Mailing StreetAddress1 is populated, info has already been saved
        newState.activeStep = 1; // Goes to Beneficiary
      }

      return newState;
    }

    case GET_EXISTING_BENEFICIARIES: {
      const benesWithFilteredOpts = [];

      // Removes options that are not associated with an Individual account type because no two options can be the same for shared Individual accounts.
      // However, if there are two different account types for the same AO/bene pair, those shared accounts can also share an investment option.
      action.payload.data.forEach(acct => {
        const validOptions = acct.Options.filter(opt => opt.AccountType === 'I');
        acct.Options = validOptions;
        benesWithFilteredOpts.push(acct);
      });

      newState.existingBenesList = [
        { Name: 'New Beneficiary', BeneficiaryId: 0 }, // TODO we'll need to figure out translations in reducers eventually
        ...benesWithFilteredOpts
      ];

      return newState;
    }

    case GET_EXISTING_BENEFICIARY: {
      const bene = action.payload.data.Beneficiary;
      newState.existingBenesDetails[bene.BeneficiaryId] = { // Beneficiaries fetched from API are saved to existingBenesDetails object by their BeneficiaryId. Then they can be easily retrieved from the store by BeneficiaryId without making an add'l API call
        ...mapBeneficiary(bene),
      };

      return newState;
    }

    case RESET_ACCOUNT_APPLICATION: {
      newState.activeStep = 1;
      newState.beneficiaryInfo = initialState.beneficiaryInfo;
      newState.investmentInfo = initialState.investmentInfo;

      return newState;
    }

    case SAVE_ACCOUNT_OWNER: {
      newState.accountOwnerInfo = mapAccountOwner(action.payload.data);
      newState.activeStep = 1; // Goes to Beneficiary Step

      return newState;
    }

    case SAVE_BENEFICIARY: {
      newState.beneficiaryInfo = mapBeneficiary(action.payload.data);
      newState.activeStep = 2; // Goes to Investment Option

      return newState;
    }

    case SAVE_CUSTOMIZED_OPTION: {
      newState.investmentInfo = {
        InvestmentOptionId: 0,
        Template: convert2DCalculatorSchemaTo3D(action.payload.data),
      };
      newState.activeStep = 3; // Goes to Review page

      return newState;
    }

    case SAVE_PREDEFINED_INVESTMENT_OPTION: {
      newState.investmentInfo = {
        InvestmentOptionId: action.payload.data.InvestmentOptionId,
        Template: null,
      };
      newState.activeStep = 3; // Goes to Review page

      return newState;
    }

    case SAVE_PROMO_CODE: {
      newState.PromotionalCode = action.payload.data.PromotionalCode;

      return newState;
    }

    default:
      return state;
  }
}

export function mapAccountOwner(data) {
  return {
    // if data has null value, it will be set to a default value - empty string or boolean
    encryptedDataExists: Boolean(data.OwnerMailingStreetAddress1),
    Mailing: {
      StreetAddress1: data.OwnerMailingStreetAddress1 || '',
      StreetAddress2: data.OwnerMailingStreetAddress2 || '',
      City: data.OwnerMailingCity || '',
      PostalCode: data.OwnerMailingPostalCode || '',
      State: data.OwnerMailingState || '',
    },
    OwnerBirthdate: data.OwnerBirthdate || '',
    OwnerFirstName: data.OwnerFirstName || '',
    OwnerHomePhone: data.OwnerHomePhone || '',
    OwnerIsResident: data.OwnerIsResident ? data.OwnerIsResident : false, // ensures property is always saved as boolean
    OwnerLastName: data.OwnerLastName || '',
    OwnerMiddleName: data.OwnerMiddleName || '',
    OwnerSSN1: data.OwnerSSN1 || '',
    OwnerSSN2: data.OwnerSSN2 || '',
    Physical: {
      StreetAddress1: data.OwnerPhysicalStreetAddress1 || '',
      StreetAddress2: data.OwnerPhysicalStreetAddress2 || '',
      City: data.OwnerPhysicalCity || '',
      PostalCode: data.OwnerPhysicalPostalCode || '',
      State: data.OwnerPhysicalState || '',
    }
  };
}

export function mapBeneficiary(data) {
  return {
    // if data has null value, it will be set to empty string
    encryptedDataExists: Boolean(data.BeneficiaryMailingStreetAddress1),
    Mailing: {
      StreetAddress1: data.BeneficiaryMailingStreetAddress1 || '',
      StreetAddress2: data.BeneficiaryMailingStreetAddress2 || '',
      City: data.BeneficiaryMailingCity || '',
      PostalCode: data.BeneficiaryMailingPostalCode || '',
      State: data.BeneficiaryMailingState || '',
    },
    BeneficiaryBirthdate: data.BeneficiaryBirthdate || '',
    BeneficiaryFirstName: data.BeneficiaryFirstName || '',
    BeneficiaryId: data.BeneficiaryId || 0,
    BeneficiaryIsResident: data.BeneficiaryIsResident ? data.BeneficiaryIsResident : false,
    BeneficiaryLastName: data.BeneficiaryLastName || '',
    BeneficiaryMailingAddressSameAsOwner: data.BeneficiaryMailingAddressSameAsOwner || false,
    BeneficiaryMiddleName: data.BeneficiaryMiddleName || '',
    BeneficiaryPhysicalAddressSameAsOwner: data.BeneficiaryPhysicalAddressSameAsOwner || false,
    BeneficiarySSN1: data.BeneficiarySSN1 || '',
    BeneficiarySSN2: data.BeneficiarySSN2 || '',
    Physical: {
      StreetAddress1: data.BeneficiaryPhysicalStreetAddress1 || '',
      StreetAddress2: data.BeneficiaryPhysicalStreetAddress2 || '',
      City: data.BeneficiaryPhysicalCity || '',
      PostalCode: data.BeneficiaryPhysicalPostalCode || '',
      State: data.BeneficiaryPhysicalState || '',
    },
    RelationshipToAccountOwner: data.RelationshipToAccountOwner || '',
  };
}

export default SignupReducer;