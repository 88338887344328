/*
*
* InterestedParties Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';
import AddIp from './AddIp';
import DeleteIp from './DeleteIp';
import EditIp from './EditIp';
import LearnMore from './LearnMore';
import {
  getInterestedParties,
  resendIpEmail,
} from './actions';
import { get2FAPrecondition, getRSAPrecondition } from 'components/AppRoot/Navigation/actions';
import { protectAction } from 'utils/helpers/multifactor_handler';
import { MULTIFACTOR_TYPE } from 'components/AppRoot/Navigation/constants';
import {
  Button,
  FormHelperText,
  Paper,
} from '@mui/material';
import {
  Checkbox,
  IconBtnTooltip,
  LoadingOverlay,
  Modal,
  notificationShow,
  
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
} from '@frontend/common';

import styles from './styles.module.scss';

const select = (state) => ({
  accountList: state.accounts.accountList.filter(acct => !acct.interestedPartyId), // only includes accounts where user is AO of account
  interestedParties: state.interestedParties.interestedParties,
  isAgent_AccessRole: state.session.isAgent_AccessRole,
  claims: state.session.claims,
});

export class InterestedParties extends React.Component {

  static propTypes = {
    accountList: PropTypes.array.isRequired,
    getInterestedParties: PropTypes.func.isRequired,
    get2FAPrecondition: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    interestedParties: PropTypes.arrayOf(PropTypes.shape({
      accountsDetail: PropTypes.arrayOf(PropTypes.shape({
        accountNumber: PropTypes.number
      })),
      emailAddress1: PropTypes.string,
      firstName: PropTypes.string,
      interestedPartyId: PropTypes.number,
      lastName: PropTypes.string,
      pin: PropTypes.string,
      status: PropTypes.string,
    })).isRequired,
    isAgent_AccessRole: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    resendIpEmail: PropTypes.func.isRequired,
    text: PropTypes.shape({
      InterestedParties: PropTypes.shape({
        btn_add_ip: PropTypes.string,
        btn_delete_ip: PropTypes.string,
        btn_edit_ip: PropTypes.string,
        btn_learn_more: PropTypes.string,
        btn_ok: PropTypes.string,
        btn_resend_email: PropTypes.string,
        err_select_account: PropTypes.string,
        head_account: PropTypes.string,
        head_accounts: PropTypes.string,
        head_bene_name: PropTypes.string,
        head_email: PropTypes.string,
        head_email_sent: PropTypes.string,
        head_interested_parties: PropTypes.string,
        head_name: PropTypes.string,
        head_opt_name: PropTypes.string,
        head_pin: PropTypes.string,
        head_read_only: PropTypes.string,
        head_status: PropTypes.string,
        text_setup_ip_access: PropTypes.string,
        text_success: PropTypes.array,
      }),
    }).isRequired,
  };

  state = {
    addModalOpen: false,
    deleteModalOpen: false,
    editModalOpen: false,
    errors: {},
    learnMoreOpen: false,
    loading: false,
    selectableAccounts: [],
    selectedAccounts: [],
    selectedIp: {},
    successModalOpen: false,

    hasPrecondition: false,
    preIpId: {},
    preModalName: ''
  };

  handleIpActions = (ipId, modalName) => {
    this.setState({ preIpId: ipId, preModalName: modalName });
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        { hasPrecondition: true },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        { hasPrecondition: true },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      const selectedIp = this.props.interestedParties.find(ip => ip.interestedPartyId === ipId) || {};
      this.setState({
        hasPrecondition: false,
        [modalName]: true,
        selectedIp,
      }, () => this.setSelectedAccounts());
    }
  }

  onAccountSelect = accountId => {
    const idx = this.state.selectableAccounts.findIndex(acct => acct.accountId === accountId);
    const updatedAccountSelections = cloneDeep(this.state.selectableAccounts);
    updatedAccountSelections[idx].selected = !updatedAccountSelections[idx].selected;

    this.setState({
      errors: {},
      selectableAccounts: updatedAccountSelections,
      selectedAccounts: updatedAccountSelections.filter(acct => acct.selected)
    });
  }

  onModalClose = () => {
    this.setState({
      addModalOpen: false,
      deleteModalOpen: false,
      editModalOpen: false,
      errors: {},
      selectedAccounts: [],
      successModalOpen: false,
    });
  }

  renderAccountSelection = () => {
    const { errors, selectableAccounts } = this.state;
    const { InterestedParties } = this.props.text;
    
    return selectableAccounts.length > 0 && (
      <div className={styles.accountsTableContainer}>

        <FormHelperText
          error={Boolean(errors.accountSelection)}
          style={{ textAlign: 'center' }}
        >
          {errors.accountSelection}
        </FormHelperText>

        <SmartTable
          columns={[
            {
              key: 'accountNumber',
              title: InterestedParties.head_account,
              type: 'number',
            },
            {
              key: 'beneficiaryName',
              title: InterestedParties.head_bene_name,
              type: 'string',
            },
            {
              key: 'optionName',
              title: InterestedParties.head_opt_name,
              type: 'string',
              hideOn: ['tablet', 'phone']
            },
            {
              key: 'selected',
              title: InterestedParties.head_read_only,
              type: 'boolean',
              format: (selected, row) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Checkbox
                    checked={selected}
                    onChange={() => this.onAccountSelect(row.accountId)}
                  />
                </div>
              )
            },
          ]}
          rows={selectableAccounts}
          idKey='accountId'
          loading={false}
        >
          <TableContainer minWidth='100%' maxHeight='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>
      </div>
    );
  }

  renderAddIpBtn = () => {
    return <Button onClick={() => this.handleIpActions({}, 'addModalOpen')} variant='contained'>{this.props.text.InterestedParties.btn_add_ip}</Button>;
  }

  renderInfoButtons = () => {
    const { isAgent_AccessRole, interestedParties } = this.props;
    return (
      <div className={styles.infoButtons}>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => this.setState({ learnMoreOpen: true })}
        >
          {this.props.text.InterestedParties.btn_learn_more}
        </Button>
        {isAgent_AccessRole && interestedParties.length === 0 && this.renderAddIpBtn()}
      </div>
    );
  }

  resendIpEmail = ipId => {
    this.setState({
      loading: true,
      selectedIp: this.props.interestedParties.find(ip => ip.interestedPartyId === ipId),
    });

    this.props.resendIpEmail(ipId)
      .then(() => {
        this.setState({
          loading: false,
          selectedIp: this.props.interestedParties.find(ip => ip.interestedPartyId === ipId),
          successModalOpen: true,
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  setSelectedAccounts = () => {
    const { selectedIp } = this.state;
    const selectableAccounts = [];
    this.props.accountList.forEach(acct => {
      if (acct.status === 'Open') {
        selectableAccounts.push({
          accountId: acct.accountId,
          accountNumber: acct.accountNumber,
          beneficiaryName: acct.beneficiary.name,
          optionName: acct.option.name,
          selected: selectedIp.accountIds ? selectedIp.accountIds.includes(acct.accountId) : false,
        });
      }
    });

    this.setState({
      selectableAccounts,
      selectedAccounts: selectableAccounts.filter(acct => acct.selected),
    });
  }

  validateSelectedAccounts = () => {
    const errors = {};

    if (this.state.selectedAccounts.length === 0) {
      errors.accountSelection = this.props.text.InterestedParties.err_select_account;
    }
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  }

  componentDidMount() {
    if (this.props.interestedParties.length === 0) {
      this.setState({ loading: true });
      this.props.getInterestedParties()
        .finally(() => this.setState({ loading: false }));
    }
  }

  componentDidUpdate() {
    const multiStatus = protectAction(this.props.claims);
    if (this.state.hasPrecondition && multiStatus === MULTIFACTOR_TYPE.AUTH) {
      this.handleIpActions(this.state.preIpId, this.state.preModalName);
    }
  }

  render() {
    const { interestedParties, text: { InterestedParties } } = this.props;
    const { addModalOpen, deleteModalOpen, editModalOpen, learnMoreOpen, loading, successModalOpen, selectableAccounts, selectedAccounts, selectedIp } = this.state;

    return (
      <React.Fragment>
        {loading ?
          <div>
            <LoadingOverlay show={loading} indicatorHeight='15px' width='100%' />
          </div>
          :
          <div className={styles.container}>
            {interestedParties.length > 0 ?
              <React.Fragment>
                <div className={styles.addIpButton}>
                  {this.renderAddIpBtn()}
                </div>
                <Paper>
                  <div className={styles.header}>{InterestedParties.head_interested_parties}</div>
                  {this.props.interestedParties.map((ip, idx) => (
                    <div key={ip.interestedPartyId}>
                      <div className={styles.row} key={ip.interestedPartyId}>
                        <div className={styles.name}>
                          <label className={styles.label}>{InterestedParties.head_name}</label>
                          <p className={styles.data}>{ip.firstName} {ip.lastName}</p>
                        </div>
                        <div className={styles.emailAddress}>
                          <label className={styles.label}>{InterestedParties.head_email}</label>
                          <p className={styles.data}>{ip.emailAddress1}</p>
                        </div>
                        <div className={styles.pin}>
                          <label className={styles.label}>{InterestedParties.head_pin}</label>
                          <p className={styles.data}>{ip.pin}</p>
                        </div>
                        <div className={styles.status}>
                          <label className={styles.label}>{InterestedParties.head_status}</label>
                          <p className={styles.data}>{ip.status}</p>
                        </div>
                        <div className={styles.accounts}>
                          <label className={styles.label}>{InterestedParties.head_accounts}</label>
                          <p className={styles.data}>
                            {ip.accountsDetail.map((acct, idx) => idx === ip.accountsDetail.length - 1 ? `${acct.accountNumber}` : `${acct.accountNumber}, `)} {/* do not add comma and space for last account num */}
                          </p>
                        </div>
                        <div className={styles.actions}>
                          {ip.status !== 'Activated' && (
                            <IconBtnTooltip
                              icon='email'
                              onClick={() => this.resendIpEmail(ip.interestedPartyId)}
                              title={InterestedParties.btn_resend_email}
                            />
                          )}
                          <IconBtnTooltip
                            icon='create'
                            onClick={() => this.handleIpActions(ip.interestedPartyId, 'editModalOpen')}
                            title={InterestedParties.btn_edit_ip}
                          />
                          <IconBtnTooltip
                            icon='delete'
                            onClick={() => this.handleIpActions(ip.interestedPartyId, 'deleteModalOpen')}
                            title={InterestedParties.btn_delete_ip}
                          />
                        </div>
                      </div>
                      {idx !== this.props.interestedParties.length - 1 && <hr />} {/* do not add divider after last account */}
                    </div>
                  ))}
                </Paper>
                {this.renderInfoButtons()}
              </React.Fragment>
              :
              <div className={styles.newIp}>
                <h1>{InterestedParties.text_setup_ip_access}</h1>
                {this.renderInfoButtons()}
              </div>
            }
          </div>
        }

        <LearnMore
          onClose={() => this.setState({ learnMoreOpen: false })}
          open={learnMoreOpen}
        />
        
        {/* ADD INTERESTED PARTY MODAL */}
        <AddIp
          handleIpActions={this.handleIpActions}
          onModalClose={this.onModalClose}
          open={addModalOpen}
          renderAccountSelection={() => this.renderAccountSelection()}
          selectedAccounts={selectedAccounts}
          validateSelectedAccounts={this.validateSelectedAccounts}
        />
        
        {/* DELETE INTERESTED PARTY MODAL */}
        <DeleteIp
          onModalClose={this.onModalClose}
          open={deleteModalOpen}
          selectedIp={selectedIp}
        />

        {/* EDIT INTERESTED PARTY MODAL */}
        <EditIp
          onModalClose={this.onModalClose}
          open={editModalOpen}
          renderAccountSelection={() => this.renderAccountSelection()}
          selectableAccounts={selectableAccounts}
          selectedAccounts={selectedAccounts}
          selectedIp={selectedIp}
          validateSelectedAccounts={this.validateSelectedAccounts}
        />

        {/* RESEND EMAIL / SUCCESS ON ADDING NEW IP MODAL */}
        <Modal
          actionButtons={[
            {
              label: InterestedParties.btn_ok,
              action: this.onModalClose,
            },
          ]}
          onCloseModal={this.onModalClose}
          show={successModalOpen}
          title={InterestedParties.head_email_sent}
        >
          <p>{InterestedParties.text_success[0]}</p>
          <p>{InterestedParties.text_success[1]}</p>
          <p style={{ textAlign: 'center' }}><strong>{selectedIp.pin}</strong></p>
          <p>{InterestedParties.text_success[2]}</p>
        </Modal>

      </React.Fragment>
    );
  }
}


export default connect(select, {
  getInterestedParties,
  get2FAPrecondition,
  getRSAPrecondition,
  notificationShow,
  resendIpEmail,
})(LanguageHOC(InterestedParties));
