// import { store } from 'src/index'; TRANSLATIONS uncomment line to activate Spanish titles
import { default as English } from 'utils/translations/en.js';
// import { default as Spanish } from 'utils/translations/es.js'; TRANSLATIONS uncomment line to activate Spanish titles
import { ROUTE_ACCESS } from 'components/AppRoot/Navigation/RequiredAccess.js';


export function getTitle(path, userAccess) {
  // const text = store.getState().session.language === 'es' ? Spanish : English; TRANSLATIONS uncomment line and remove below line to display Spanish titles
  const text = English;
  const {
    AccountDetails,
    Accounts,
    AddBirthdate,
    Payments,
    Documents,
    Home,
    ForgotPassword,
    ForgotUsername,
    Lpoa,
    Gifting,
    InterestedParties,
    InterestedPartiesActivation,
    Login,
    MyInfo,
    Multifactor,
    PayrollContribution,
    Register,
    ResendEmail,
    ResetPassword,
    Signup,
    SetupUser,
    VerifyEmail,
    PageNotFound,
    LpoaAccount,
    ValidateEmail,
    TransactionsHub,
  } = text;

  // Priority is placed to titles placed LOWER on this list
  const routes = [
    { matcher: Home.nav_path, title: Home.nav_title, visible: ROUTE_ACCESS.Home.includes(userAccess) },
    { matcher: ValidateEmail.nav_path, title: ValidateEmail.nav_title, visible: true },
    { matcher: AddBirthdate.nav_path, title: AddBirthdate.nav_title, visible: ROUTE_ACCESS.AddBirthdate.includes(userAccess) },
    { matcher: Accounts.nav_path, title: Accounts.nav_title, visible: ROUTE_ACCESS.Accounts.includes(userAccess) },
    { matcher: AccountDetails.nav_path_regex, title: AccountDetails.nav_title, visible: ROUTE_ACCESS.Accounts.includes(userAccess) },
    { matcher: Payments.nav_path, title: Payments.nav_title, visible: ROUTE_ACCESS.Payments.includes(userAccess) },
    { matcher: Documents.nav_path, title: Documents.nav_title, visible: ROUTE_ACCESS.Documents.includes(userAccess) },
    { matcher: ForgotPassword.nav_path, title: ForgotPassword.nav_title, visible: true },
    { matcher: ForgotUsername.nav_path, title: ForgotUsername.nav_title, visible: true },
    { matcher: InterestedPartiesActivation.nav_path, title: InterestedPartiesActivation.nav_title, visible: true },
    { matcher: InterestedParties.nav_path, title: InterestedParties.nav_title, visible: ROUTE_ACCESS.InterestedParties.includes(userAccess) },
    { matcher: Gifting.nav_path, title: Gifting.nav_title, visible: ROUTE_ACCESS.Gifting.includes(userAccess) },
    { matcher: `${Gifting.nav_path}/[0-9]`, title: Gifting.manage_title, visible: ROUTE_ACCESS.Gifting.includes(userAccess) },
    { matcher: Lpoa.nav_path, title: Lpoa.nav_title, visible: ROUTE_ACCESS.Lpoa.includes(userAccess) },
    { matcher: Login.nav_path, title: Login.nav_title, visible: true },
    { matcher: MyInfo.nav_path, title: MyInfo.nav_title, visible: ROUTE_ACCESS.MyInfo.includes(userAccess) },
    { matcher: Multifactor.nav_path, title: Multifactor.nav_title, visible: ROUTE_ACCESS.Multifactor.includes(userAccess) },
    { matcher: PayrollContribution.nav_path, title: PayrollContribution.nav_title, visible: ROUTE_ACCESS.PayrollContribution.includes(userAccess) },
    { matcher: ResendEmail.nav_path, title: ResendEmail.nav_title, visible: true },
    { matcher: Register.nav_path, title: Register.nav_title, visible: true },
    { matcher: ResetPassword.nav_path, title: ResetPassword.nav_title, visible: true },
    { matcher: Signup.nav_path, title: Signup.nav_title, visible: ROUTE_ACCESS.Signup.includes(userAccess) },
    { matcher: SetupUser.nav_path, title: SetupUser.nav_title, visible: true },
    { matcher: VerifyEmail.nav_path, title: VerifyEmail.nav_title, visible: ROUTE_ACCESS.VerifyEmail.includes(userAccess) },
    { matcher: LpoaAccount.nav_path, title: LpoaAccount.nav_title, visible: true },
    { matcher: TransactionsHub.nav_path, title: TransactionsHub.nav_title, visible: true },
  ];

  const matchingRoute = routes.reverse().find(route => path.match(new RegExp(route.matcher, 'i'))); // make match case insensitive
  let title = '';

  if (matchingRoute && matchingRoute.visible) {
    title = matchingRoute.title;
  }
  else {
    title = PageNotFound.nav_title;
  }

  document.title = title;
  return title;
}