/*
*
* SchoolSearch Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Slide
} from '@mui/material';
import {
  Modal,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';


export function SchoolSearch({ loading, onClose, open, schools, schoolSelect, text: { Withdrawals } }) {
  return (
    <Modal
      maxWidth='lg'
      onCloseModal={onClose}
      show={open}
      title='Eligible Educational Institutions'
      DialogProps={{ 'TransitionComponent': Transition, fullScreen: true }}
    >
      <SmartTable
        idKey='id'
        rows={schools}
        loading={loading}
        emptyMessage='No Matching Schools'
        columns={[
          {
            key: 'name',
            title: 'School',
            type: 'string',
            customStyle: { width: '2fr' },
          },
          {
            key: 'city',
            title: 'City',
            type: 'string',
            hideOn: ['phone', 'tablet'],
          },
          {
            key: 'state',
            title: 'State',
            type: 'string',
            hideOn: ['phone'],
            customStyle: { width: '.5fr' },
          },
          {
            key: 'fafsaId',
            title: 'FAFSA Code',
            type: 'string',
            hideOn: ['phone', 'tablet'],
            customStyle: { width: '.75fr' },
          },
        ]}
        actions={[
          {
            type: 'icon',
            onSelect: row => schoolSelect(row.schoolId, row.ElectronicSchoolPaymentSchoolId),
            icon: 'add',
            iconTitle: Withdrawals.btn_add_school,
          }
        ]}
      >
        <TableToolbar 
          searchOpen={true}
          searchPlaceholder='Search school...'
        />
        <TableContainer minWidth='100%'>
          <TableHeader />
          <TableRows />
        </TableContainer>
        <TablePagination />
      </SmartTable>
    </Modal>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction='up' ref={ref} {...props} />;
});

SchoolSearch.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  schoolSelect: PropTypes.func.isRequired,
  schools: PropTypes.array.isRequired,
  text: PropTypes.shape({
    Withdrawals: PropTypes.shape({
      btn_add_school: PropTypes.string,
    }),
  }).isRequired,
};

export default LanguageHOC(SchoolSearch);