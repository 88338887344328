/*
*
* Register Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  registerUser,
  verifyRecaptcha,
} from 'components/AppRoot/Navigation/actions';
import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';
import { GETRecaptchaToken } from 'utils/helpers/api_handler';
import FormWrapper from '../FormWrapper';

import { REGISTRATION_TYPES } from '../constants';

import {
  allNotificationsHide,
  Checkbox,
  LoadingOverlay,
  Modal,
  notificationShow,
  PasswordInput,
  PasswordRequirements,
} from '@frontend/common';

import { emailValidator } from 'utils/helpers/form_validation';

import {
  Button,
  Accordion,
  FormHelperText,
  TextField,
} from '@mui/material';

import styles from './styles.module.scss';

// When the user clicks on the button, scroll to the top of the document
const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

const select = (state) => ({
  registrationType: state.registrationtype.registrationType,
  passwordRequirements: state.static.passwordRequirements,
  recaptchaToken: state.session.userDetails.recaptchaToken,
  recaptchaVerified: state.session.userDetails.recaptchaVerified,
  supportNumber: state.static.environmentVars.SupportPhoneNumber,
});

export class Register extends React.Component {
  static propTypes = {
    registrationType: PropTypes.string.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    passwordRequirements: PropTypes.array.isRequired,
    passwordRequirementsGet: PropTypes.func.isRequired,
    recaptchaToken: PropTypes.string.isRequired,
    recaptchaVerified: PropTypes.bool.isRequired,
    registerUser: PropTypes.func.isRequired,
    supportNumber: PropTypes.string.isRequired,
    text: PropTypes.shape({
      Login: PropTypes.shape({
        nav_path: PropTypes.string
      }),
      Register: PropTypes.shape({
        btn_register: PropTypes.string,
        btn_start_registration: PropTypes.string,
        err_max_length_20: PropTypes.string,
        err_max_length_40: PropTypes.string,
        err_password_criteria: PropTypes.string,
        err_password_match: PropTypes.string,
        err_required_field: PropTypes.string,
        err_terms_agree: PropTypes.string,
        err_username_cannot_match_pass: PropTypes.string,
        err_username_max_length: PropTypes.string,
        err_username_min_length: PropTypes.string,
        err_username_spaces: PropTypes.string,
        err_username_with_numbers: PropTypes.string,
        err_valid_email: PropTypes.string,
        head_individual_account: PropTypes.string,
        head_terms: PropTypes.string,
        lbl_email: PropTypes.string,
        lbl_firstName: PropTypes.string,
        lbl_lastName: PropTypes.string,
        lbl_password1: PropTypes.string,
        lbl_password2: PropTypes.string,
        lbl_username: PropTypes.string,
        nav_path: PropTypes.string,
        text_about_individual_account: PropTypes.string,
        text_about_institutional_account: PropTypes.string,
        text_already_have_account: PropTypes.string,
        text_i_agree: PropTypes.string,
        text_read_terms: PropTypes.string,
        text_setup_online_access: PropTypes.string,
        text_setup_user: PropTypes.string,
        text_terms_bullets: PropTypes.array,
        text_menu_1: PropTypes.string,
        text_menu_2: PropTypes.string,
        text_never_opened_account: PropTypes.string,
      }),
      ResendEmail: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
      SetupUser: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
    }).isRequired,
    verifyRecaptcha: PropTypes.func.isRequired,
  };

  state = {
    apiErrors: [],
    formErrors: {},
    loading: false,
    passwordIsValid: false,
    passwordReqsVisible: false,
    registrationInfo: {
      email: '',
      firstName: '',
      lastName: '',
      password1: '',
      password2: '',
      username: '',
    },
    termsChecked: false,
    termsModalOpen: false,
  };

  formValidate = () => {
    const formErrors = {};
    const { apiErrors, passwordIsValid, registrationInfo: { email, firstName, lastName, password1, password2, username }, termsChecked } = this.state;
    const { Register } = this.props.text;

    // Username validation
    if (username.match(/^\s+|\s+$/)) {
      formErrors.username = Register.err_username_spaces;
    }
    if (username.match(/\d\d\d\d/)) {
      formErrors.username = Register.err_username_with_numbers;
    }
    if (username === password1) {
      formErrors.username = Register.err_username_cannot_match_pass;
    }
    if (!username) {
      formErrors.username = Register.err_required_field;
    }
    if (username.length > 40) {
      formErrors.username = Register.err_max_length_40;
    }
    if (username.length < 6) {
      formErrors.username = Register.err_username_min_length;
    }
    // First and last name validation
    if (!firstName) {
      formErrors.firstName = Register.err_required_field;
    }
    if (firstName.length >= 20) {
      formErrors.firstName = Register.err_max_length_20;
    }
    if (!lastName) {
      formErrors.lastName = Register.err_required_field;
    }
    if (lastName.length >= 40) {
      formErrors.lastName = Register.err_max_length_40;
    }
    // Email validation
    if (!email) {
      formErrors.email = Register.err_required_field;
    }
    const emailError = emailValidator(email);
    if (email && emailError) {
      formErrors.email = emailError;
    }
    // Password validation
    if (!passwordIsValid) {
      formErrors.password1 = Register.err_password_criteria;
    }
    if (!password1) {
      formErrors.password1 = Register.err_required_field;
    }
    if (!password2) {
      formErrors.password2 = Register.err_required_field;
    }
    if (password1 !== password2) {
      formErrors.password2 = Register.err_password_match;
    }
    // Terms are checked
    if (!termsChecked) {
      formErrors.terms = Register.err_terms_agree;
    }
    // API errors
    if (apiErrors.length > 0) {
      apiErrors.forEach(error => {
        if (error.Field === 'EmailAddress') {
          formErrors.email = error.Message;
        }
        if (error.Field === 'FirstName') {
          formErrors.firstName = error.Message;
        }
        if (error.Field === 'LastName') {
          formErrors.lastName = error.Message;
        }
        if (error.Field === 'PasswordOne') {
          formErrors.password1 = error.Message;
        }
        if (error.Field === 'PasswordTwo') {
          formErrors.password2 = error.Message;
        }
        if (error.Field === 'UserName' || error.Field === 'Username') {
          formErrors.username = error.Message;
        }
      });
    }

    this.setState({
      apiErrors: [],
      formErrors
    });

    return Object.keys(formErrors).length === 0; // returns True or False whether form is valid
  }

  onFormSubmit = e => {
    e.preventDefault();

    if (this.formValidate()) {
      this.setState({ loading: true });
      const { registrationInfo } = this.state;

      const apiRegistrationInfo = {
        EmailAddress: registrationInfo.email,
        FirstName: registrationInfo.firstName,
        LastName: registrationInfo.lastName,
        PasswordOne: registrationInfo.password1,
        PasswordTwo: registrationInfo.password2,
        UserName: registrationInfo.username,
      };

      this.props.registerUser(apiRegistrationInfo)
        .then(() => {
          this.setState({ loading: false });
          this.props.history.push(this.props.text.ResendEmail.nav_path);
        })
        .catch(errors => {
          this.setState({
            apiErrors: errors.payload.data,
            loading: false
          }, () => this.formValidate());
        });
    }
  }

  onInputChange = e => {
    this.setState({
      formErrors: {
        ...this.state.formErrors,
        [e.target.name]: ''
      },
      registrationInfo: {
        ...this.state.registrationInfo,
        [e.target.name]: e.target.value,
      }
    });
  }

  onTermsCheck = () => {
    this.setState({
      formErrors: {
        ...this.state.formErrors,
        terms: !this.state.termsChecked ? '' : this.state.formErrors.terms // termsChecked isn't set to true yet, so we need to check that termsChecked is false to clear the error.
      },
      termsChecked: !this.state.termsChecked
    });
  }

  recaptchaVerify = () => {
    GETRecaptchaToken('register')
      .then(token => {
        if (token !== this.props.recaptchaToken) {
          this.props.verifyRecaptcha(token)
            .then((response) => {
              if (!this.props.recaptchaVerified) {
                const warningMessage = response.payload.data['error-codes'][0];
                this.props.notificationShow(warningMessage, 'warning');
              }
            });
        }
      });
  }

  handleMenuClick = (event) => {
    this.setState({ menuOpen: event.currentTarget });
  };

  componentDidMount() {
    scrollToTop();
    if (this.props.passwordRequirements.length === 0) {
      this.props.passwordRequirementsGet();
    }
    this.props.allNotificationsHide();
    this.recaptchaVerify();
  }

  render() {
    const { 
      passwordRequirements, 
      recaptchaVerified, 
      registrationType,
      text: { Register } 
    } = this.props;

    const { 
      formErrors, 
      loading, 
      passwordReqsVisible, 
      registrationInfo,
      termsChecked, 
      termsModalOpen, 
    } = this.state;

    return (
      <>
        {loading ?
          <LoadingOverlay show={loading} width='100%' />
          :
          <FormWrapper>
            {registrationType === REGISTRATION_TYPES.I_AM_NEW_TO_MY529 &&
              <>
                <div className={styles.newAccountSubLanguage}>
                  {Register.text_never_opened_account}
                </div>
                <form onSubmit={this.onFormSubmit}>
                  <TextField
                    autoComplete='given-name'
                    disabled={loading}
                    error={Boolean(formErrors.firstName)}
                    variant='filled'
                    fullWidth
                    helperText={formErrors.firstName}
                    label={Register.lbl_firstName}
                    name='firstName'
                    onChange={this.onInputChange}
                    value={registrationInfo.firstName}
                  />
                  <TextField
                    autoComplete='family-name'
                    disabled={loading}
                    error={Boolean(formErrors.lastName)}
                    variant='filled'
                    fullWidth
                    helperText={formErrors.lastName}
                    label={Register.lbl_lastName}
                    name='lastName'
                    onChange={this.onInputChange}
                    value={registrationInfo.lastName}
                  />
                  <TextField
                    autoComplete='email'
                    disabled={loading}
                    error={Boolean(formErrors.email)}
                    variant='filled'
                    fullWidth
                    helperText={formErrors.email}
                    label={Register.lbl_email}
                    name='email'
                    onChange={this.onInputChange}
                    value={registrationInfo.email}
                  />
                  <TextField
                    autoComplete='username'
                    disabled={loading}
                    error={Boolean(formErrors.username)}
                    variant='filled'
                    fullWidth
                    helperText={formErrors.username}
                    label={Register.lbl_username}
                    name='username'
                    onChange={this.onInputChange}
                    value={registrationInfo.username}
                  />

                  <PasswordInput
                    autoComplete='new-password'
                    disabled={loading}
                    variant='filled'
                    fullWidth
                    errorText={formErrors.password1}
                    label={Register.lbl_password1}
                    name='password1'
                    onBlur={() => this.setState({ passwordReqsVisible: false })}
                    onChange={this.onInputChange}
                    onFocus={() => this.setState({ passwordReqsVisible: true })}
                    value={registrationInfo.password1}
                  />

                  <div style={{ width: '100%' }}>
                    <Accordion
                      sx={{
                        background: 'none',
                        boxShadow: 'none',
                      }}
                      expanded={passwordReqsVisible}
                    >
                      <PasswordInput
                        autoComplete='new-password'
                        disabled={loading}
                        variant='filled'
                        fullWidth
                        errorText={formErrors.password2}
                        label={Register.lbl_password2}
                        name='password2'
                        onChange={this.onInputChange}
                        value={registrationInfo.password2}
                      />
                      <div className={styles.passwordRequirements}>
                        <PasswordRequirements
                          password={registrationInfo.password1}
                          passwordRequirements={passwordRequirements}
                          onPasswordCheck={passwordIsValid => this.setState({ passwordIsValid })}
                        />
                      </div>
                    </Accordion>
                  </div>

                  <div className={styles.termsAndConditionsContainer}>
                    <Button
                      variant='text'
                      color='primary'
                      onClick={() => this.setState({ termsModalOpen: true })}
                    >
                      {Register.text_read_terms}
                    </Button>
                    <Checkbox
                      checked={termsChecked}
                      error={formErrors.terms}
                      label={Register.text_i_agree}
                      onChange={this.onTermsCheck}
                      color='primary'
                    />
                    {formErrors.terms && <FormHelperText error={Boolean(formErrors.terms)}>{formErrors.terms}</FormHelperText>}
                  </div>

                  <div className={styles.buttons}>
                    <div>
                      <Button
                        disabled={loading || !recaptchaVerified}
                        fullWidth
                        type='submit'
                        variant='contained'
                      >
                        {Register.btn_register}
                      </Button>
                    </div>
                  </div>
                </form>
              </>
            }
          </FormWrapper>
        }

        <Modal
          onCloseModal={() => this.setState({ termsModalOpen: false })}
          show={termsModalOpen}
          title={Register.head_terms}
        >
          <ul>{Register.text_terms_bullets.map(bull => <li key={bull}>{bull}</li>)}</ul> {/** TODO hook up the email once it is returned as env var */}
        </Modal>
      </>
    );
  }
}


export default withRouter((connect(select, {
  allNotificationsHide,
  notificationShow,
  passwordRequirementsGet,
  registerUser,
  verifyRecaptcha,
})(LanguageHOC(Register))));
