/*
*
* TransactionDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
} from '@mui/material';
// import {
//   withWidth,
// } from '@mui/styles';
import {
  CardTitle,
  currencyFormatter,
  IconBtnTooltip,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';

import styles from './styles.module.scss';

export class TransactionDetails extends React.Component {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    transaction: PropTypes.shape({
      id: PropTypes.number.isRequired,
      date: PropTypes.string,
      type: PropTypes.string.isRequired,
      source: PropTypes.string,
      payeeName: PropTypes.string,
      total: PropTypes.number.isRequired,
      basis: PropTypes.number.isRequired,
      bankName: PropTypes.string.isRequired,
      bankAccountNumber: PropTypes.string.isRequired,
      bankAccountType: PropTypes.string.isRequired,
      accessCardNumber: PropTypes.string.isRequired,
      ElectronicSchoolPaymentPaymentId: PropTypes.string,
      transactionDetails: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          fundName: PropTypes.string,
          units: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
          amount: PropTypes.number.isRequired,
        })
      )
    }).isRequired,
    text: PropTypes.shape({
      TransactionDetails: PropTypes.shape({
        head_title: PropTypes.string,
        text_portfolio: PropTypes.string,
        text_units: PropTypes.string,
        text_nav: PropTypes.string,
        text_amount: PropTypes.string,
        text_date: PropTypes.string,
        text_total: PropTypes.string,
        text_transaction_type: PropTypes.string,
        text_type: PropTypes.string,
        text_payee_name: PropTypes.string,
        text_bank_name: PropTypes.string,
        text_bank_account_number: PropTypes.string,
        text_bank_account_type: PropTypes.string,
        text_card_number: PropTypes.string,
        text_payment_confirmation: PropTypes.string,
      }),
      n_a: PropTypes.string,
    }).isRequired,
  };

  render() {
    const { open, onClose, transaction, text: { TransactionDetails, n_a } } = this.props;
    return (
      <Drawer
        anchor='right'
        open={open}
        onClose={onClose}
      >
        <div
          tabIndex={0}
          style={{ width: 'auto' }}
          className={styles.Transaction_container}
        >
          <div className={styles.transactionNavigation}>
            <div style={{ visibility: 'hidden' }}>
              <IconBtnTooltip
                icon='close'
                onClick={onClose}
              />
            </div>
            <div className={styles.transactionTitle}>
              <CardTitle>
                {TransactionDetails.head_title}
              </CardTitle>
            </div>
            <div>
              <IconBtnTooltip
                icon='close'
                onClick={onClose}
              />
            </div>
          </div>
          <div className={styles.transactionTableWrapper}>
            <table className={styles.transactionTable}>
              <tbody>
                <tr>
                  <th>{TransactionDetails.text_date}:</th>
                  <td>{dayjs(transaction.date).format('MM/DD/YYYY')}</td>
                </tr>
                <tr>
                  <th>{TransactionDetails.text_total}:</th>
                  <td>{currencyFormatter(transaction.total)}</td>
                </tr>
                <tr>
                  <th>{TransactionDetails.text_transaction_type}:</th>
                  <td>{transaction.type}</td>
                </tr>
                <tr>
                  <th>{TransactionDetails.text_type}:</th>
                  <td>{transaction.source ? transaction.source : n_a}</td>
                </tr>
                {transaction.accessCardNumber ?
                  <tr>
                    <th>{TransactionDetails.text_card_number}:</th>
                    <td>{transaction.accessCardNumber ? transaction.accessCardNumber : n_a}</td>
                  </tr>
                  : null}
                {transaction.bankName ?
                  <tr>
                    <th>{TransactionDetails.text_bank_name}:</th>
                    <td>{transaction.bankName ? transaction.bankName : n_a}</td>
                  </tr>
                  : null}
                {transaction.bankName ?
                  <tr>
                    <th>{TransactionDetails.text_bank_account_number}:</th>
                    <td>{transaction.bankAccountNumber ? transaction.bankAccountNumber : n_a}</td>
                  </tr>
                  : null}
                {transaction.bankName ?
                  <tr>
                    <th>{TransactionDetails.text_bank_account_type}:</th>
                    <td>{transaction.bankAccountType ? transaction.bankAccountType : n_a}</td>
                  </tr>
                  : null}
                {transaction.payeeName && !transaction.accessCardNumber ? // only hide on Access Card transactions
                  <tr>
                    <th>{TransactionDetails.text_payee_name}:</th>
                    <td>{transaction.payeeName ? transaction.payeeName : n_a}</td>
                  </tr>
                  : null}
                {transaction.source && transaction.source.includes('Electronic School') ?
                  <tr>
                    <th>{TransactionDetails.text_payment_confirmation}:</th>
                    <td>{transaction.ElectronicSchoolPaymentPaymentId}</td>
                  </tr>
                  : null}
              </tbody>
            </table>
          </div>
          <SmartTable
            idKey='id'
            rows={transaction.transactionDetails}
            columns={[
              {
                key: 'fundName',
                title: TransactionDetails.text_portfolio,
                type: 'string',
              },
              {
                key: 'price',
                title: TransactionDetails.text_nav,
                type: 'string',
                hideOn: ['phone', 'tablet'],
                customStyle: { width: '100px', textAlign: 'right' },
              },
              {
                key: 'units',
                title: TransactionDetails.text_units,
                type: 'string',
                hideOn: ['phone', 'tablet'],
                customStyle: { width: '125px', textAlign: 'right' },
              },
              {
                key: 'amount',
                title: TransactionDetails.text_amount,
                type: 'currency',
                customStyle: { width: '125px' },
              },
            ]}
          >
            <TableToolbar />
            <TableContainer minWidth='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        </div>
      </Drawer >
    );
  }
}

export default LanguageHOC(TransactionDetails);