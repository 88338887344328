/*
*
* Step2CreateCustomizedOption Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  CalculatorForm,
} from '@frontend/common';

import styles from '../styles.module.scss';

const select = (state) => ({
  ServerDateTime: state.static.ServerDateTime,
});

export class Step2CreateCustomizedOption extends React.Component {

  static propTypes = {
    onAllocationChange: PropTypes.func.isRequired,
    currentSchema: PropTypes.object.isRequired,
    selectedCategory: PropTypes.string.isRequired,
    ServerDateTime: PropTypes.string,
  };

  render() {
    const {
      currentSchema,
      selectedCategory,
      onAllocationChange,
      ServerDateTime
    } = this.props;

    return (
      <div style={{ textAlign: 'center' }}>
        <div className={styles.selectedOption}>Selected Category</div>
        <div className={styles.selectedOptionValue}>{selectedCategory}</div>
        <CalculatorForm
          stickyHeaderTopOffset={60}
          schema={currentSchema}
          onAllocationChange={onAllocationChange}
          currentDate={ServerDateTime}
          hideTemplateName={true}
        />
      </div>
    );
  }
}

export default connect(select, {}, null)(Step2CreateCustomizedOption);