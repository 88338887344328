/*
*
* Summary Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  TextField,
} from '@mui/material';
import { IconBtnTooltip } from '@frontend/common';

import styles from './styles.module.scss';

const select = (state) => ({
  accountOwnerInfo: state.signup.accountOwnerInfo,
  beneficiaryInfo: state.signup.beneficiaryInfo,
  investmentInfo: state.signup.investmentInfo,
  predefinedOptions: state.optionChanges.predefinedOptions,
});


export class Summary extends React.Component {
  static propTypes = {
    accountOwnerInfo: PropTypes.shape({
      encryptedDataExists: PropTypes.bool,
      Mailing: PropTypes.shape({
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
      }),
      OwnerBirthdate: PropTypes.string,
      OwnerFirstName: PropTypes.string,
      OwnerHomePhone: PropTypes.string,
      OwnerIsResident: PropTypes.bool,
      OwnerLastName: PropTypes.string,
      OwnerMiddleName: PropTypes.string,
      OwnerSSN1: PropTypes.string,
      OwnerSSN2: PropTypes.string,
      Physical: PropTypes.shape({
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
      })
    }).isRequired,
    beneficiaryInfo: PropTypes.shape({
      BeneficiaryIsResident: PropTypes.bool,
      encryptedDataExists: PropTypes.bool,
      Mailing: PropTypes.shape({
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
      }),
      BeneficiaryBirthdate: PropTypes.string,
      BeneficiaryFirstName: PropTypes.string,
      BeneficiaryLastName: PropTypes.string,
      BeneficiaryMiddleName: PropTypes.string,
      BeneficiarySSN1: PropTypes.string,
      BeneficiarySSN2: PropTypes.string,
      Physical: PropTypes.shape({
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
      }),
      RelationshipToAccountOwner: PropTypes.string,
    }).isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool,
    investmentInfo: PropTypes.shape({
      InvestmentOptionId: PropTypes.number,
      Template: PropTypes.object,
    }).isRequired,
    onPromoCodeChange: PropTypes.func,
    onStepClick: PropTypes.func,
    predefinedOptions: PropTypes.array.isRequired,
    promoError: PropTypes.string,
    PromotionalCode: PropTypes.string.isRequired,
    showPromoCode: PropTypes.bool,
    text: PropTypes.shape({
      Summary: PropTypes.shape({
        head_account_owner: PropTypes.string,
        head_beneficiary: PropTypes.string,
        head_investment_option: PropTypes.string,
        head_mailing_address: PropTypes.string,
        head_physical_address: PropTypes.string,
        lbl_bene_utah_tax_payer: PropTypes.string,
        lbl_birthdate: PropTypes.string,
        lbl_cancel: PropTypes.string,
        lbl_city: PropTypes.string,
        lbl_clear_state: PropTypes.string,
        lbl_firstName: PropTypes.string,
        lbl_homePhone: PropTypes.string,
        lbl_lastName: PropTypes.string,
        lbl_middleName: PropTypes.string,
        lbl_postalCode: PropTypes.string,
        lbl_promo_code: PropTypes.string,
        lbl_relationship: PropTypes.string,
        lbl_ssn_tin: PropTypes.string,
        lbl_state: PropTypes.string,
        lbl_street1: PropTypes.string,
        lbl_street2: PropTypes.string,
        lbl_utah_tax_payer: PropTypes.string,
        msg_promo_applied: PropTypes.string,
        text_customized_ageBased: PropTypes.string,
        text_customized_static: PropTypes.string,
        text_no: PropTypes.string,
        text_yes: PropTypes.string,
      })
    }).isRequired,
  };

  static defaultProps = {
    isEditable: true,
    showPromoCode: true,
  };

  investmentOptionCompose = () => {
    const { investmentInfo: { InvestmentOptionId, Template }, predefinedOptions, text: { Summary } } = this.props;
    if (InvestmentOptionId) {
      return predefinedOptions.find(opt => opt.OptionId === InvestmentOptionId).Name;
    }
    return Template && Template.TemplateType === 'S' ? Summary.text_customized_static : Summary.text_customized_ageBased;
  }

  render() {
    const { accountOwnerInfo, beneficiaryInfo, isDisabled, isEditable, onPromoCodeChange, onStepClick, promoError, PromotionalCode, showPromoCode, text: { Summary } } = this.props;
    const phoneNumbersOnly = accountOwnerInfo.OwnerHomePhone.replace(/[^0-9.]+/g, ''); // removes formatting from API to ensure phone number is always formatted the same way, no matter what environment

    return (
      <div className={styles.container}>
        {/* PROMO CODE */}
        {showPromoCode && (
          <TextField
            className={styles.promoCode}
            disabled={isDisabled}
            error={Boolean(promoError)}
            fullWidth
            helperText={promoError}
            label={Summary.lbl_promo_code}
            name='promoCode'
            onChange={onPromoCodeChange}
            style={{ maxWidth: '350px', marginTop: '18px' }}
            value={PromotionalCode}
            variant='filled'
          />)}

        {/* INVESTMENT OPTION INFORMATION */}
        <div className={styles.investmentOptionContainer}>
          <div className={styles.investmentOptionHeader}>
            <h2>{Summary.head_investment_option}</h2>
            {isEditable && (
              <IconBtnTooltip
                buttonProps={{ style: { marginLeft: '3px' } }}
                icon='create'
                onClick={() => onStepClick(2)}
              />
            )}
          </div>
          <div className={styles.investmentOption}>
            <p>{this.investmentOptionCompose()}</p>
          </div>
        </div>

        {/* ACCOUNT OWNER INFORMATION */}
        <div className={styles.accountOwnerContainer}>
          <div className={styles.accountOwnerHeader}>
            <h2>{Summary.head_account_owner}</h2>
            {isEditable && (
              <IconBtnTooltip
                buttonProps={{ style: { marginLeft: '3px' } }}
                icon='create'
                onClick={() => onStepClick(0)}
              />
            )}
          </div>
          <div className={styles.accountOwnerInfo}>
            <div className={styles.aoNameContainer}>
              <div className={styles.fieldContainer}>
                <h4>{Summary.lbl_firstName}</h4>
                <p>{accountOwnerInfo.OwnerFirstName}</p>
              </div>
              {accountOwnerInfo.OwnerMiddleName && ( // if Middlename is populated
                <div className={styles.fieldContainer}>
                  <h4>{Summary.lbl_middleName}</h4>
                  <p>{accountOwnerInfo.OwnerMiddleName}</p>
                </div>
              )}
              <div className={styles.fieldContainer}>
                <h4>{Summary.lbl_lastName}</h4>
                <p>{accountOwnerInfo.OwnerLastName}</p>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <h4>{Summary.lbl_birthdate}</h4>
              <p>{accountOwnerInfo.OwnerBirthdate}</p>
            </div>
            <div className={styles.fieldContainer}>
              <h4>{Summary.lbl_ssn_tin}</h4>
              <p>{accountOwnerInfo.OwnerSSN1}</p>
            </div>
            {accountOwnerInfo.OwnerHomePhone && // some existing AOs do not have a home phone, so this will show nothing instead of an empty number
              <div className={styles.fieldContainer}>
                <h4>{Summary.lbl_homePhone}</h4>
                <p>({phoneNumbersOnly.slice(0, 3)}) {phoneNumbersOnly.slice(3, 6)}-{phoneNumbersOnly.slice(6)}</p>
              </div>
            }
          </div>

          <div className={styles.accountOwnerAddress}>
            <div className={styles.fieldContainer}>
              <h3>{Summary.head_mailing_address}</h3>
              <h4>{Summary.lbl_street1}</h4>
              <p>{accountOwnerInfo.Mailing.StreetAddress1}</p>
            </div>
            {accountOwnerInfo.Mailing.StreetAddress2 && ( // if StreetAddress2 is populated
              <div className={styles.fieldContainer}>
                <h4>{Summary.lbl_street2}</h4>
                <p>{accountOwnerInfo.Mailing.StreetAddress2}</p>
              </div>
            )}
            <div className={styles.fieldContainer}>
              <h4>{Summary.lbl_city}, {Summary.lbl_state}, {Summary.lbl_postalCode}</h4>
              <p>{accountOwnerInfo.Mailing.City}, {accountOwnerInfo.Mailing.State} {accountOwnerInfo.Mailing.PostalCode}</p>
            </div>
            {accountOwnerInfo.Physical.StreetAddress1 && ( // if Physical address exists
              <div>
                <div className={styles.fieldContainer}>
                  <h3>{Summary.head_physical_address}</h3>
                  <h4>{Summary.lbl_street1}</h4>
                  <p>{accountOwnerInfo.Physical.StreetAddress1}</p>
                </div>
                {accountOwnerInfo.Physical.StreetAddress2 && ( // if StreetAddress2 is populated
                  <div className={styles.fieldContainer}>
                    <h4>{Summary.lbl_street2}</h4>
                    <p>{accountOwnerInfo.Physical.StreetAddress2}</p>
                  </div>
                )}
                <div className={styles.fieldContainer}>
                  <h4>{Summary.lbl_city}, {Summary.lbl_state}, {Summary.lbl_postalCode}</h4>
                  <p>{accountOwnerInfo.Physical.City}, {accountOwnerInfo.Physical.State} {accountOwnerInfo.Physical.PostalCode}</p>
                </div>
              </div>
            )}
            <div className={styles.fieldContainer}>
              <h4>{Summary.lbl_utah_tax_payer}</h4>
              <p>{accountOwnerInfo.OwnerIsResident ? Summary.text_yes : Summary.text_no}</p>
            </div>
          </div>
        </div>

        {/* BENEFICIARY INFORMATION */}
        <div className={styles.beneficiaryContainer}>
          <div className={styles.beneficiaryHeader}>
            <h2>{Summary.head_beneficiary}</h2>
            {isEditable && (
              <IconBtnTooltip
                buttonProps={{ style: { marginLeft: '3px' } }}
                icon='create'
                onClick={() => onStepClick(1)}
              />
            )}
          </div>
          <div className={styles.beneficiaryInfo}>
            <div className={styles.beneNameContainer}>
              <div className={styles.fieldContainer}>
                <h4>{Summary.lbl_firstName}</h4>
                <p>{beneficiaryInfo.BeneficiaryFirstName}</p>
              </div>
              {beneficiaryInfo.BeneficiaryMiddleName && ( // if Middlename is populated
                <div className={styles.fieldContainer}>
                  <h4>{Summary.lbl_middleName}</h4>
                  <p>{beneficiaryInfo.BeneficiaryMiddleName}</p>
                </div>
              )}
              <div className={styles.fieldContainer}>
                <h4>{Summary.lbl_lastName}</h4>
                <p>{beneficiaryInfo.BeneficiaryLastName}</p>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <h4>{Summary.lbl_birthdate}</h4>
              <p>{beneficiaryInfo.BeneficiaryBirthdate}</p>
            </div>
            <div className={styles.fieldContainer}>
              <h4>{Summary.lbl_ssn_tin}</h4>
              <p>{beneficiaryInfo.BeneficiarySSN1}</p>
            </div>
          </div>
          <div className={styles.beneficiaryAddress}>
            <div className={styles.fieldContainer}>
              <h3>{Summary.head_mailing_address}</h3>
              <h4>{Summary.lbl_street1}</h4>
              <p>{beneficiaryInfo.Mailing.StreetAddress1}</p>
            </div>
            {beneficiaryInfo.Mailing.StreetAddress2 && ( // if StreetAddress2 is populated
              <div className={styles.fieldContainer}>
                <h4>{Summary.lbl_street2}</h4>
                <p>{beneficiaryInfo.Mailing.StreetAddress2}</p>
              </div>
            )}
            <div className={styles.fieldContainer}>
              <h4>{Summary.lbl_city}, {Summary.lbl_state}, {Summary.lbl_postalCode}</h4>
              <p>{beneficiaryInfo.Mailing.City}, {beneficiaryInfo.Mailing.State} {beneficiaryInfo.Mailing.PostalCode}</p>
            </div>
            {beneficiaryInfo.Physical.StreetAddress1 && ( // if Physical address exists
              <div>
                <div className={styles.fieldContainer}>
                  <h3>{Summary.head_physical_address}</h3>
                  <h4>{Summary.lbl_street1}</h4>
                  <p>{beneficiaryInfo.Physical.StreetAddress1}</p>
                </div>
                {beneficiaryInfo.Physical.StreetAddress2 && ( // if StreetAddress2 is populated
                  <div className={styles.fieldContainer}>
                    <h4>{Summary.lbl_street2}</h4>
                    <p>{beneficiaryInfo.Physical.StreetAddress2}</p>
                  </div>
                )}
                <div className={styles.fieldContainer}>
                  <h4>{Summary.lbl_city}, {Summary.lbl_state}, {Summary.lbl_postalCode}</h4>
                  <p>{beneficiaryInfo.Physical.City}, {beneficiaryInfo.Physical.State} {beneficiaryInfo.Physical.PostalCode}</p>
                </div>
              </div>
            )}
            <div className={styles.fieldContainer}>
              <h4>{Summary.lbl_bene_utah_tax_payer}</h4>
              <p>{beneficiaryInfo.BeneficiaryIsResident ? Summary.text_yes : Summary.text_no}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default connect(select, {})(LanguageHOC(Summary));
