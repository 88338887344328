export const AGENT_SEARCH = 'AppRoot/Features/public/RegistrationType/AGENT_SEARCH';
export const AGENT_UPDATE = 'AppRoot/Features/public/RegistrationType/AGENT_UPDATE';
export const REGISTRATION_TYPE = 'AppRoot/Features/public/RegistrationType/REGISTRATION_TYPE';

export const INIT_FORM_ERRORS = {
  SocialSecurityNumber: '',
  BirthDate: '',
  UserName: '',
  EmailAddress: '',
  PasswordOne: '',
  PasswordTwo: '',
};

export const REGISTRATION_TYPES = {
  I_AM_NEW_TO_MY529: 'I am new to my529',
  I_HAVE_ACCOUNTS_WITH_MY529: 'I have accounts with my529'
};