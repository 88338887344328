/*
*
* LpoaAccount Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
} from '@mui/material';

import {
  My529Logo,
  LoadingOverlay,
  ConfirmModal,
  notificationShow,
  allNotificationsHide,
} from '@frontend/common';

import {
  getRSA,
  postRSA,
  rejectLpoaAccount,
  getLpoaAccount,
  approveLpoaAccount,
} from './actions';

import {
  RSA_STATUS,
} from './constants';

import AccountEdit from './AccountEdit';
import RSAQuestions from './RSAQuestions';
import FirstTermsAndConditions from './FirstTermsAndConditions';
import SecondTermsAndConditions from './SecondTermsAndConditions';

import styles from './styles.module.scss';

// there is only showing one component at a time
const COMPONENT_NAMES = {
  ACCOUNT_EDIT: 'AccountEdit',
  RSA_QUESTIONS: 'RSAQuestions',
  RSA_FAILED: 'RSAFailed',
  FIRST_T_AND_C: 'FirstTermsAndConditions',
  SECOND_T_AND_C: 'SecondTermsAndConditions',
  ACCOUNT_CREATED: 'AccountCreated',
};

const select = (state) => ({
  applicationInfo: state.lpoaAccount.applicationInfo,
  loginAlreadyExists: state.lpoaAccount.loginAlreadyExists,
  newAccountNumber: state.lpoaAccount.newAccountNumber,
  supportNumber: state.static.environmentVars.SupportPhoneNumber,
});

export class LpoaAccount extends React.Component {
  static propTypes = {
    getRSA: PropTypes.func.isRequired,
    postRSA: PropTypes.func.isRequired,
    applicationInfo: PropTypes.object.isRequired,
    rejectLpoaAccount: PropTypes.func.isRequired,
    getLpoaAccount: PropTypes.func.isRequired,
    approveLpoaAccount: PropTypes.func.isRequired,
    loginAlreadyExists: PropTypes.bool.isRequired,
    newAccountNumber: PropTypes.number.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    supportNumber: PropTypes.string.isRequired,
    text: PropTypes.shape({
      LpoaAccount: PropTypes.object.isRequired,
    }).isRequired,
  };

  state = {
    getRSALoading: false,
    postRSALoading: false,
    validateAccountLoading: false,
    approveAccountLoading: false,
    firstAttemptFailed: false,
    rejectLoading: false,
    showRejectModal: false,
    show: {
      [COMPONENT_NAMES.ACCOUNT_EDIT]: false,
      [COMPONENT_NAMES.RSA_QUESTIONS]: false,
      [COMPONENT_NAMES.RSA_FAILED]: false,
      [COMPONENT_NAMES.FIRST_T_AND_C]: false,
      [COMPONENT_NAMES.SECOND_T_AND_C]: false,
      [COMPONENT_NAMES.ACCOUNT_CREATED]: false,
    },
    failedRSAMessage: '',
    guid: '',
    RSA: {},
  };

  setStateShowHandler = (componentName) => {
    // first reset all
    const updatedShow = {
      [COMPONENT_NAMES.ACCOUNT_EDIT]: false,
      [COMPONENT_NAMES.RSA_QUESTIONS]: false,
      [COMPONENT_NAMES.RSA_FAILED]: false,
      [COMPONENT_NAMES.FIRST_T_AND_C]: false,
      [COMPONENT_NAMES.SECOND_T_AND_C]: false,
      [COMPONENT_NAMES.ACCOUNT_CREATED]: false,
    };
    // update only one
    updatedShow[componentName] = true;
    this.setState({ show: updatedShow });
  }

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handlePostRSA = (answerSet) => {
    this.setState({ postRSALoading: true });
    this.props.postRSA(this.state.guid, answerSet)
      .then((response) => {
        const RSA = response.payload.data;
        this.setState({ RSA });
        switch (RSA.Status) {
          case RSA_STATUS.RSA_NOT_PASSED_YET:
            this.setStateShowHandler(COMPONENT_NAMES.RSA_QUESTIONS);
            this.setState({ firstAttemptFailed: true }); // assuming that calling POST is the 2nd attempt
            break;
          case RSA_STATUS.RSA_FAILED:
            this.setStateShowHandler(COMPONENT_NAMES.RSA_FAILED);
            this.setState({
              firstAttemptFailed: false,
              failedRSAMessage: RSA.Message
            });
            break;
          case RSA_STATUS.RSA_PASSED:
            document.cookie = `LPOA_RSA_Passed=${RSA.Message}`;
            this.setStateShowHandler(COMPONENT_NAMES.ACCOUNT_EDIT);
            this.setState({
              firstAttemptFailed: false,
            });
            break;
          default: // this should be caught by the middleware
        }
      })
      .finally(() => {
        this.scrollToTop();
        this.setState({ postRSALoading: false });
      });
  }

  handleGetRSA = (guid) => {
    this.setState({ getRSALoading: true });
    this.props.getRSA(guid)
      .then((response) => {
        const RSA = response.payload.data;
        this.setState({ RSA });
        switch (RSA.Status) {
          case RSA_STATUS.RSA_NOT_PASSED_YET:
            this.setStateShowHandler(COMPONENT_NAMES.RSA_QUESTIONS);
            break;
          case RSA_STATUS.RSA_FAILED:
            this.setStateShowHandler(COMPONENT_NAMES.RSA_FAILED);
            this.setState({ failedRSAMessage: RSA.Message });
            break;
          case RSA_STATUS.RSA_PASSED:
            this.setStateShowHandler(COMPONENT_NAMES.ACCOUNT_EDIT);
            break;
          default: // this should be caught by the middleware
        }
      })
      .finally(() => {
        this.setState({ getRSALoading: false });
      });
  }

  onPOARejectHandler = (e) => {
    e.preventDefault();
    const { guid } = this.state;
    const { text: { LpoaAccount }, notificationShow } = this.props;

    this.setState({ rejectLoading: true });
    this.props.rejectLpoaAccount(guid)
      .then(() => {
        return this.props.getLpoaAccount(guid)
          .then(() => {
            notificationShow(LpoaAccount.success_lpoa_account_rejected, 'success');
            this.setState({
              applicationInfo: this.props.applicationInfo,
            });
          });
      })
      .finally(() => {
        this.scrollToTop();
        this.setStateShowHandler('');
        this.setState({
          showRejectModal: false,
          rejectLoading: false,
        });
      });
  }

  showRejectModalHandler = () => {
    this.setState({
      showRejectModal: true,
    });
  }

  onRejectModalClose = () => {
    this.setState({
      showRejectModal: false,
    });
  }

  onNextHandlerAccountEdit = () => {
    this.setStateShowHandler(COMPONENT_NAMES.FIRST_T_AND_C);
  }

  onNextHandlerFirstTermsAndConditions = () => {
    this.setStateShowHandler(COMPONENT_NAMES.SECOND_T_AND_C);
  }

  onBackHandlerFirstTermsAndConditions = () => {
    this.setStateShowHandler(COMPONENT_NAMES.ACCOUNT_EDIT);
  }

  onBackHandlerSecondTermsAndConditions = () => {
    this.setStateShowHandler(COMPONENT_NAMES.FIRST_T_AND_C);
  }

  onSubmitHandler = () => {
    const { 
      approveLpoaAccount, 
      notificationShow, 
      text: { LpoaAccount },
    } = this.props;
    this.setState({ approveAccountLoading: true });
    approveLpoaAccount(this.props.applicationInfo, this.state.guid)
      .then(() => {
        notificationShow(LpoaAccount.success_lpoa_account_approved, 'success');
        this.setStateShowHandler(COMPONENT_NAMES.ACCOUNT_CREATED);
      })
      .finally(() => {
        this.setState({ approveAccountLoading: false });
      });
  }

  // Methods to render components
  renderNavigationButton = (label, onClick, variant, disabled = false, loading = false, color = 'primary') => {
    return (
      <div className={`${styles.button} hideOnPrint`}>
        <LoadingOverlay show={loading}>
          <Button
            key={`button_${label}`}
            disabled={disabled}
            onClick={onClick}
            variant={variant}
            color={color}
          >
            {label}
          </Button>
        </LoadingOverlay>
      </div>
    );
  }

  renderAccountCreated = () => {
    const { 
      newAccountNumber, 
      loginAlreadyExists, 
      supportNumber,
      text: { 
        LpoaAccount: {
          btn_setup_login,
          btn_login,
          confirmation_page,
          btn_done,
          btn_print,
        } 
      } 
    } = this.props;

    const onDoneClick = () => (window.location = 'https://my529.org/');
    const onSetupLoginClick = () => this.props.history.push('/setup-user');
    const onLoginClick = () => this.props.history.push('/login');
    let confirmationSecondParagraph = confirmation_page.second_paragraph_setup(btn_setup_login, supportNumber);
    let logInOrSetUpButton = this.renderNavigationButton(btn_setup_login, onSetupLoginClick, 'text');
    if (loginAlreadyExists) { 
      logInOrSetUpButton = this.renderNavigationButton(btn_login, onLoginClick, 'text');
      confirmationSecondParagraph = confirmation_page.second_paragraph_login(btn_login, supportNumber);
    }

    return (
      <div className={styles.accountCreatedContainer}>
        <h2 className={styles.accountCreatedTitle}>Account Created</h2>
        <h3>{confirmation_page.your_account_number}</h3>
        <h2 className={styles.newAccountNumber}>{newAccountNumber}</h2>
        <p>{confirmation_page.first_paragraph}</p>
        <p>{confirmationSecondParagraph}</p>
        <div className={styles.navButtons}>
          {logInOrSetUpButton}
          {this.renderNavigationButton(btn_print, () => window.print(), 'text')}
          {this.renderNavigationButton(btn_done, onDoneClick, 'contained')}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.props.allNotificationsHide();
    const guid = new URLSearchParams(window.location.search.toLowerCase()).get('key');
    this.setState({ guid });
    this.handleGetRSA(guid);
  }

  render() {
    const {
      show,
      failedRSAMessage,
      getRSALoading,
      postRSALoading,
      approveAccountLoading,
      firstAttemptFailed,
      RSA,
      rejectLoading,
      showRejectModal,
    } = this.state;

    const {
      applicationInfo,
      text: { LpoaAccount }
    } = this.props;

    return (
      <div>
        {getRSALoading ? <LoadingOverlay show={getRSALoading} width='100%' /> :
          <div style={{ minHeight: '30px' }}>

            <div className={styles.container}>
              <div className={`${styles.logo} hideOnPrint`}><My529Logo /></div>
              {firstAttemptFailed && <div className={styles.failedRSA}>{LpoaAccount.try_again_rsa_txt}</div>}
              {show[COMPONENT_NAMES.ACCOUNT_EDIT] &&
                <AccountEdit
                  onNextHandler={this.onNextHandlerAccountEdit}
                  renderNavigationButton={this.renderNavigationButton}
                  rejectLoading={rejectLoading}
                  showRejectModal={showRejectModal}
                  showRejectModalHandler={this.showRejectModalHandler}
                />
              }
              {show[COMPONENT_NAMES.RSA_QUESTIONS] &&
                <RSAQuestions
                  handlePostRSA={this.handlePostRSA}
                  loading={postRSALoading}
                  RSA={RSA}
                />
              }
              {show[COMPONENT_NAMES.RSA_FAILED] &&
                <div className={styles.failedRSA}>
                  {<div>{failedRSAMessage}</div>}
                </div>
              }
              {show[COMPONENT_NAMES.FIRST_T_AND_C] &&
                <FirstTermsAndConditions
                  scrollToTop={this.scrollToTop}
                  renderNavigationButton={this.renderNavigationButton}
                  onBackHandler={this.onBackHandlerFirstTermsAndConditions}
                  onNextHandler={this.onNextHandlerFirstTermsAndConditions}
                  showRejectModalHandler={this.showRejectModalHandler}
                  rejectLoading={rejectLoading}
                />
              }
              {show[COMPONENT_NAMES.SECOND_T_AND_C] &&
                <SecondTermsAndConditions
                  scrollToTop={this.scrollToTop}
                  renderNavigationButton={this.renderNavigationButton}
                  onBackHandler={this.onBackHandlerSecondTermsAndConditions}
                  onSubmitHandler={this.onSubmitHandler}
                  showRejectModalHandler={this.showRejectModalHandler}
                  lpoaFirmName={applicationInfo.lpoaFirmName}
                  rejectLoading={rejectLoading}
                  approveAccountLoading={approveAccountLoading}
                />
              }
              {show[COMPONENT_NAMES.ACCOUNT_CREATED] &&
                this.renderAccountCreated()
              }
              <ConfirmModal
                body={LpoaAccount.reject_modal_confirmation}
                isLoading={rejectLoading}
                key={'POA_Reject_Modal'}
                onConfirm={this.onPOARejectHandler}
                onModalClose={this.onRejectModalClose}
                show={showRejectModal}
                title={LpoaAccount.reject_modal_title}
              />
            </div>
          </div>
        }
      </div>
    );
  }
}


export default withRouter(connect(select, {
  getRSA,
  postRSA,
  rejectLpoaAccount,
  getLpoaAccount,
  approveLpoaAccount,
  notificationShow,
  allNotificationsHide,
})(LanguageHOC(LpoaAccount)));
