/*
*
* AddIp Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { addIp } from '../actions';
import * as validator from 'utils/helpers/form_validation';
import {
  Button,
  FormHelperText,
  TextField
} from '@mui/material';
import {
  Checkbox,
  Modal,
  notificationShow,
} from '@frontend/common';

import styles from './styles.module.scss';

const select = state => ({
  interestedParties: state.interestedParties.interestedParties,
});

export class AddIp extends React.Component {

  static propTypes = {
    addIp: PropTypes.func.isRequired,
    handleIpActions: PropTypes.func.isRequired,
    interestedParties: PropTypes.arrayOf(PropTypes.shape({
      interestedPartyId: PropTypes.number,
    })).isRequired,
    notificationShow: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    renderAccountSelection: PropTypes.func.isRequired,
    selectedAccounts: PropTypes.array.isRequired,
    text: PropTypes.shape({
      InterestedParties: PropTypes.shape({
        btn_add_ip: PropTypes.string,
        btn_add_ip_read_terms: PropTypes.string,
        btn_agree_terms: PropTypes.string,
        btn_close: PropTypes.string,
        btn_save: PropTypes.string,
        err_add_ip_terms_agree: PropTypes.string,
        err_copy_email: PropTypes.string,
        err_emails_must_match: PropTypes.string,
        lbl_email: PropTypes.string,
        lbl_email_confirm: PropTypes.string,
        msg_ip_added: PropTypes.string,
        text_add_ip_terms: PropTypes.func,
      }),
    }).isRequired,
    validateSelectedAccounts: PropTypes.func.isRequired,
  };

  state = {
    apiErrors: [],
    loading: false,
    errors: {},
    newIp: {
      emailAddress1: '',
      emailAddress2: '',
    },
    showTerms: false,
    termsChecked: false,
  };

  addIp = () => {
    const { newIp: { emailAddress1, emailAddress2 } } = this.state;
    const isValid = this.formValidate();

    if (isValid) {
      const newIpInfo = {
        accountIds: this.props.selectedAccounts.map(acct => acct.accountId),
        emailAddress1,
        emailAddress2,
      };
      this.setState({ loading: true });
      this.props.addIp(newIpInfo)
        .then(() => {
          const newlyAddedIp = this.props.interestedParties[this.props.interestedParties.length - 1];
          this.closeModal();
          this.props.handleIpActions(newlyAddedIp.interestedPartyId, 'successModalOpen');
          this.props.notificationShow(this.props.text.InterestedParties.msg_ip_added, 'success');
        })
        .catch(res => {
          this.setState({
            apiErrors: res.payload.data,
            loading: false
          }, () => this.formValidate());
        });
    }
  }

  closeModal = () => {
    this.setState({
      errors: {},
      loading: false,
      newIp: {
        emailAddress1: '',
        emailAddress2: '',
      },
    });
    this.props.onModalClose();
  }

  formValidate = () => {
    const { apiErrors, newIp: { emailAddress1, emailAddress2 }, termsChecked, } = this.state;
    const errors = {};
    const accountIsSelected = this.props.validateSelectedAccounts();

    errors.emailAddress1 = validator.emailValidator(emailAddress1);
    errors.emailAddress2 = validator.emailValidator(emailAddress2);
    errors.terms = termsChecked ? '' : this.props.text.InterestedParties.err_add_ip_terms_agree;

    if (emailAddress1 !== emailAddress2) {
      errors.emailAddress2 = this.props.text.InterestedParties.err_emails_must_match;
    }
    if (errors.terms) { // scroll to bottom of modal if user has not agreed to terms
      const el = document.getElementById('bottomOfModal');
      el.scrollIntoView();
    }
    if (!accountIsSelected || errors.emailAddress1 || errors.emailAddress2) { // scroll to top of modal if there are errors there
      const modalEl = document.getElementById('topOfModal');
      modalEl.scrollIntoView();
    }
    if (apiErrors.length > 0) {
      apiErrors.map(err => errors[err.Field] = err.Message);
    }


    this.setState({ errors });
    return accountIsSelected && Object.keys(errors).every(err => errors[err] === '');
  }

  onEmailChange = e => {
    this.setState({
      apiErrors: [],
      errors: {
        ...this.state.errors,
        [e.target.name]: ''
      },
      newIp: {
        ...this.state.newIp,
        [e.target.name]: e.target.value
      }
    });
  }

  preventCopy = (e) => {
    const errors = cloneDeep(this.state.errors);
    const confirmSsnInput = e.target;

    confirmSsnInput.onpaste = e => {
      e.preventDefault();
      errors.emailAddress2 = this.props.text.InterestedParties.err_copy_email;
      this.setState({ errors });
    };
  }

  render() {
    const { open, renderAccountSelection, text: { InterestedParties } } = this.props;
    const { loading, errors, newIp, showTerms, termsChecked, } = this.state;

    return (
      <Modal
        actionButtons={[
          {
            action: () => this.addIp(),
            buttonType: 'contained',
            disabled: loading,
            label: InterestedParties.btn_save,
            loading,
          },
        ]}
        maxWidth='md'
        onCloseModal={this.closeModal}
        show={open}
        title={InterestedParties.btn_add_ip}
        includeLeftCancelBtn
      >
        <TextField
          fullWidth
          error={Boolean(errors.emailAddress1)}
          helperText={errors.emailAddress1}
          id='topOfModal'
          label={InterestedParties.lbl_email}
          name='emailAddress1'
          onChange={this.onEmailChange}
          value={newIp.emailAddress1}
        />
        <TextField
          fullWidth
          error={Boolean(errors.emailAddress2)}
          helperText={errors.emailAddress2}
          label={InterestedParties.lbl_email_confirm}
          name='emailAddress2'
          onChange={this.onEmailChange}
          onFocus={this.preventCopy}
          value={newIp.emailAddress2}
        />
        {renderAccountSelection()}

        <div className={styles.termsContainer} id='bottomOfModal'>
          <Button
            onClick={() => this.setState({ showTerms: !showTerms })}
            variant='text'
            color='primary'
          >
            {InterestedParties.btn_add_ip_read_terms}
          </Button>
          <div className={showTerms ? styles.showTerms : styles.hideTerms}>
            {InterestedParties.text_add_ip_terms()}
          </div>
          <Checkbox
            checked={termsChecked}
            color='primary'
            disabled={loading}
            error={errors.terms}
            label={InterestedParties.btn_agree_terms}
            onChange={() => this.setState({ errors: { ...errors, terms: '' }, termsChecked: !termsChecked })}
          />
          {errors.terms && <FormHelperText error={Boolean(errors.terms)}>{errors.terms}</FormHelperText>}
        </div>
      </Modal>
    );
  }
}


export default connect(select, {
  addIp,
  notificationShow,
})(LanguageHOC(AddIp));
