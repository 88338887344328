/* eslint-disable brace-style */
import React, { Fragment } from 'react';
import parse from 'html-react-parser';
import {
  buttons,
  errors,
  formLabels,
  intl_address,
  recaptchaLanguage,
  current_password,
  unsaved_changes,
  NA,
  fullBalanceWithdrawalNote,
  mountain_time_zone,
  msg_available_balance_contribution
} from './common';
import { currencyFormatter } from '@frontend/common';


/* eslint-disable quotes */
const English = {
  AccountDetails: {
    nav_path(accountId) { return `/accounts/${accountId}`; }, // Will need some clarification on what needs to be translated
    nav_path_regex: /(accounts)\/\d+/g,
    nav_title: 'Account Details',
    investment_path: 'investment-path',
    action_path: 'actions',
  },
  AccountOwnerCard: {
    head_title: 'Account Owner',
    text_name_change_form: 'To change the account owner name, please submit form 500, Account Information Change.',
  },
  AccountOwnerStep: {
    btn_continue: buttons.continue,
    err_copy_ssn: errors.copy_ssn_tin,
    err_phone_number_too_short: errors.phone_number_too_short,
    err_smarty_streets: errors.smarty_streets_generic,
    err_ssns_must_match: errors.ssns_tins_must_match,
    head_account_owner: 'Account Owner',
    lbl_birthdate: formLabels.birthdate,
    lbl_clear_state: formLabels.clear_state,
    lbl_firstName: formLabels.firstName,
    lbl_homePhone: formLabels.homePhone,
    lbl_lastName: formLabels.lastName,
    lbl_middleName: formLabels.middleName,
    lbl_ssn_tin: `${formLabels.ssn}  /  ${formLabels.tin}`,
    lbl_ssn_tin_confirm: `Confirm ${formLabels.ssn}  /  ${formLabels.tin}`,
    lbl_utah_tax_payer: formLabels.utahTaxPayer,
    msg_ao_info_saved: 'Account owner information saved.',
    text_what_is_ao: [
      'The account owner must be age 18 or older.',
      'This person must have a valid U.S.Social Security or Taxpayer Identification Number.',
      'The account owner is the person who controls this account(designates the beneficiary, directs all investments, receives all statements, etc).',
      'There can only be one account owner on each my529 account.',
    ],
  },
  Accounts: {
    head_title: 'Summary',
    nav_path: '/accounts',
    accounts_path: 'accounts',
    nav_title: 'Accounts',
    text_account_closed: 'Account closed on',
  },
  TransactionsHub: {
    head_title: 'Transactions',
    nav_title: 'Transactions',
    nav_path: '/transactions-hub',
  },
  AccountsSummary: {
    btn_add_account: 'Add Account',
    btn_csa_account: 'Account',
    btn_my529_account: 'my529 Account',
    head_account_type: 'Account Type:',
    head_beneficiary: 'Beneficiary',
    head_date_opened: 'Date Opened',
    head_interested_party: 'Interested Party',
    head_market_date: 'Market Date',
    head_total_accts: 'Grand Total as of',
    head_total_value: 'Total Value',
    head_ytd_return: 'YTD Return',
    nav_contribution: 'Contribution',
    nav_option_change: 'Option Change',
    nav_transfer: 'Transfer',
    nav_withdrawal: 'Withdrawal',
  },
  AccessCardsLearnMore: {
    text_faq: [
      {
        question: 'What is my529 Access Discover® Prepaid Card?',
        answer: 'The my529 Access Card is a convenient way for account owners and beneficiaries to use money withdrawn from their my529 account to pay for qualified education expenses. my529 Access Card is a prepaid card issued by Central Bank of Kansas City.',
      },
      {
        question: 'Why use my529 Access Card?',
        answer: 'The my529 Access Card creates convenience and control for the account owner. Each transaction where the my529 Access Card is used will appear on the Card Services monthly statement, making the my529 Access Card a central place to track qualified education expenses. The my529 Access Card is designed to limit merchants who offer products and services such as alcohol, gambling, or adult entertainment to protect against nonqualified purchases.'
      },
      {
        question: 'What can my529 Access Card be used for?',
        answer: [
          'The card can be used to pay qualified education expenses, which include, but are not limited to, the following:',
          [
            'Mandatory fees, books, supplies and equipment required for the beneficiary to enroll and attend an eligible higher educational institution.',
            'Computers, peripheral equipment, software and internet access while enrolled in an eligible higher educational institution.',
            'Expenses for services for a special needs beneficiary to enroll and attend an eligible educational institution.'
          ]
        ]
      },
      {
        question: 'How does the card work?',
        answer: [
          'my529 Access Card can be used at select merchants for in-store and online purchases where Discover is accepted.',
          'my529 Access Card is funded from the my529 account directly. Once approved and set up, the account owner can add money to the my529 Access Card to pay for qualified education expenses. The account owner has sole control of these accounts and is the only person able to request a withdrawal from their my529 account.',
          'Account owners have the ability to withdraw funds from their my529 accounts and add those funds to their my529 Access Card. Once funds are transferred to the card, they are considered withdrawn from my529 and are no longer invested in the account owners’ my529 accounts. Per IRS rules, funds must be spent within the same period as the expense was incurred to be considered a qualified withdrawal.',
        ],
      },
      {
        question: 'Can I use my529 Access Card to pay tuition?',
        answer: 'Yes. However, please be aware that your college will likely charge a fee that is a percentage of the transaction amount for using the card to pay tuition. Contact Card Services to explore other options—such as bill pay or e-check via electronic funds transfer direct debit—to pay tuition using your my529 Access Card. You can reach Card Services at 1.844.545.0805.',
      },
      {
        question: 'How do I transfer money from my my529 account to my529 Access Card?',
        answer: 'Once you’re approved, your my529 Access Card will be one of the available online withdrawal options you can choose from in your my529 account.',
      },
      {
        question: 'What if I transfer more money than I need to my my529 Access Card? Can I have it reinvested in my my529 account?',
        answer: 'No. Once funds are transferred to the my529 Card, they cannot be reversed or transferred back to my529.',
      },
      {
        question: 'Who can apply for a my529 Access Card?',
        answer: 'Applicants must have a beneficiary who is 17 years or older, a physical domestic (U.S.) address, and an active my529 individual or institutional account that has been open for 6 months. Applicants must also register with an email address that is not already in use within the Card Services system.',
      },
      {
        question: 'Once I have been approved, how do I activate my my529 Access Card? ',
        answer: [
          'Once an account owner has applied and been approved for the my529 Access Card, they will receive a virtual card. However, it may take up to two days before the card is linked to their my529 account and ready for use. The card requires no activation. Account owners are able to withdraw and transfer funds to their card as soon as it is linked to their my529 account.',
          'If a secondary card is requested for a beneficiary, the beneficiary will automatically receive both a virtual and a physical card. An account owner has the option to request a physical card. Physical my529 Access Cards will require activation by calling Card Services at 1.844.545.0805, or by logging in to Card Services at http://my529accesscard.com.'
        ]
      },
      {
        question: 'What if I have my529 accounts for other beneficiaries?',
        answer: [
          'A person who owns my529 accounts for multiple beneficiaries only needs a single my529 Access Card account. The account owner would receive a primary my529 Access Card and has the option to request a secondary card for each my529 beneficiary. Account owners may have one card with a maximum of 10 beneficiaries.',
          'All withdrawals from the owner’s my529 accounts will go to the primary my529 Access Card. Funds could then be transferred to the secondary card accounts by logging in to Card Services. Each card will have the cardholder’s (either account owner or beneficiary) name on it. Only the my529 account owner can fund the beneficiary cards. Beneficiaries cannot move funds between cards.',
        ],
      },
      {
        question: 'What is the status of funds moved to my my529 Access Card?',
        answer: 'When funds are transferred from the my529 account to your my529 Access Card, it is the same as making a withdrawal from your my529 account. Money that is transferred to your my529 Access Card is no longer invested and is considered withdrawn from my529.',
      },
      {
        question: 'Does the beneficiary build credit by having a my529 Access Card? ',
        answer: 'No, the my529 Access Card is a prepaid card and does not build credit for the beneficiary or the account holder.',
      },
      {
        question: 'Are there any fees beyond the established my529 program fees for this card?',
        answer: [
          'Card Services charges a $1 Monthly Maintenance Fee after a nine (9) month grace period. The fee is assessed on the last day of the month after the grace period.',
          'A $1 PIN Purchase Fee also applies. The PIN Purchase Fee of $1 occurs when the card is used to make a purchase and the PIN is entered. Consult the  Fee Schedule for more information and complete details.',
          'my529 Card accounts with a balance of $0 will not be charged a fee.'
        ]
      },
      {
        question: 'Are there any merchant restrictions?',
        answer: 'my529 Access Card restricts usage at certain businesses, such as those that offer alcohol, gambling or adult entertainment.',
      },
      {
        question: 'What if my card is lost or stolen?',
        answer: 'Always keep your PIN in a safe place and never write it on the card. If your card or PIN is lost or stolen, contact Card Services immediately at 1.844.545.0805. Your card is protected by Discover’s Zero Liability Policy. Conditions and exceptions apply, see the Cardholder Agreement for complete details.',
      },
      {
        question: 'What if my my529 Access Card account is closed?',
        answer: 'If a my529 Access Card account is closed for any reason, you will be notified and any debit card withdrawal schedules from the my529 account will be canceled. This includes both one-time and recurring withdrawals.',
      },
      {
        question: 'If my my529 account is closed, what happens to funds remaining on the card?',
        answer: 'All funds added to a my529 Access Card are available for use to pay for qualified education expenses until they have been exhausted. Per IRS rules, funds must be spent within the same period as the expense was incurred to be considered a qualified withdrawal.',
      },
      {
        question: 'Are there limits for how much can be transferred to a my529 Access Card? ',
        answer: 'Yes, the my529 Access Card has a maximum balance limit of $75,000.'
      },
      {
        question: 'Does my529 Access Card come with overdraft protection?',
        answer: 'No, the card does not include overdraft protection.'
      },
      {
        question: 'Will my funds be ready to use immediately?',
        answer: 'No. It takes 1-3 business days for funds to be available after a withdrawal request is submitted online at my529.org.',
      },
      {
        question: 'Can I set up recurring withdrawals from my my529 account to my Access Card? How does that work?',
        answer: 'Yes, you may set up recurring monthly withdrawals. This option will be available during the online withdrawal process from your my529 account.',
      },
      {
        question: 'Can a my529 Access Card be used to withdraw funds from an ATM?',
        answer: 'No. The card cannot be used for ATM withdrawals.',
      },
      {
        question: 'Where do I find my my529 Access Card statement?',
        answer: 'Log in to Card Services at http://my529accesscard.com to view or download your monthly statements.',
      },
      {
        question: 'Can I use my529 Access Card to pay for nonqualified expenses? ',
        answer: 'Yes, but if you take a withdrawal to pay for nonqualified expenses with a my529 Access Card, you will be subject to taxes and penalties as outlined by the Internal Revenue Service regarding the use of 529 plan funds.',
      },
      {
        question: 'Whom should I notify if I need to update my mailing address?',
        answer: [
          'It is important to remember that you need to update two different accounts: my529 and my529 Access Card.',
          [
            'Contact my529 at 1.800.418.2551 or log in to your account at my529.org.',
            'Log in to your my529 Card through Card Services at http://my529accesscard.com or call 1.844.545.0805.'
          ],
          'Please note: updating your information in one location doesn’t automatically update your information in the other.'
        ],
      },
      {
        question: 'Who receives the 1099-Q form?',
        answer: 'Transferring money to a my529 Access Card is a withdrawal from your my529 account. The account owner will receive a 1099-Q form for all withdrawals from a my529 account within a tax year.',
      },
      {
        question: 'Whom do I call if I have a question about my529 Access Card?',
        answer: 'Contact Card Services at 1.844.545.0805 with all questions regarding the my529 Access Card.',
      },
      {
        question: 'I see this is a Discover Card. Where can I use it?',
        answer: 'The my529 Access Card is accepted at select merchants where Discover is accepted.',
      },
      {
        question: 'Am I able to log in to my account at Discover.com to check my my529 Access Card balance? ',
        answer: 'No. Log in to Card Services at http://my529accesscard.com or call 1.844.545.0805 to check your my529 Access Card balance.',
      },
      {
        question: 'Will Card Services be able to view my my529 account balance?',
        answer: 'No. Card Services will not have any information regarding the account owner’s my529 account balances or transactions.',
      },
      {
        question: 'Is there a Discover rewards program associated with the my529 Access Card?',
        answer: 'No. There are no Discover rewards programs associated with the my529 Access Card.',
      },
    ],
    title_learn_more: 'Access Cards',
    learn_more_footer: 'The my529 Access Discover® Prepaid Card is issued by Central Bank of Kansas City, Member FDIC. Discover and the Discover acceptance mark are service marks used by Central Bank of Kansas City under license from Discover Financial Services. Certain fees, terms, and conditions are associated with the approval, maintenance, and use of the Card. You should consult your Cardholder Agreement and Fee Schedule at http://my529accesscard.com. If you have any questions regarding the Card or such fees, terms, and conditions, you can contact us 24/7/365 toll-free at 1-844-545-0805.',
  },
  AddBankModal: {
    btn_cancel: buttons.cancel,
    btn_agree: buttons.agree,
    err_invalid_routing: 'Enter a valid routing number.',
    err_copy: 'Please type bank account number to confirm.',
    err_required: errors.required,
    err_routing_length: 'Routing number must be 9 digits.',
    err_accounts_match: 'Bank Account Numbers do not match.',
    err_allowable_chars: "Allowable characters: alphanumeric and .,':#/-",
    head_modal_title: 'Add Bank Account',
    lbl_account_holder: 'Account Holder',
    lbl_account_number: 'Account Number',
    lbl_confirm_account_number: 'Confirm Account Number',
    lbl_checking: 'Checking',
    lbl_nickname: 'Account Nickname',
    lbl_routing: 'Routing Number',
    lbl_savings: 'Savings',
    msg_account_added: 'Account added.',
    text_required_language_1: 'The linked account must be a checking or savings account at a bank or credit union located in the United States.',
    text_required_language_2: 'You must own the linked account and your name must be in the account title.',
    text_required_language_3: 'my529 will verify the information you provide with the appropriate financial institution.',
    text_required_language_4: 'Please note that my529 does not accept every bank or financial institution. If you receive a "Not Accepted" message, please try an account from a different institution.',
    text_required_language_5: 'I authorize my529 to initiate, and my financial institution to honor, electronic payments from my linked account. This authority pertains to the linked bank account listed above.  The information I have provided is accurate.  I understand that rejected transactions or insufficient funds could cause fees to be assessed by my529 and/or my financial institution.',
    text_permission_bank_account_holder: 'This account cannot fund online contributions.',
  },
  AddBirthdate: {
    btn_logout: 'Log Out',
    head_incomplete_records: 'Incomplete Records',
    lbl_birthdate: formLabels.birthdate,
    lbl_submit: buttons.submit,
    msg_birthdate_updated: 'Birth date updated.',
    nav_path: '/add-birthdate',
    nav_title: 'Birth Date Required',
    text_incomplete_records: 'Please enter the date of birth for the account owner:',
    text_support(supportNumber) { return parse(`If you are experiencing difficulties, call my529 at <a href='tel:${supportNumber}'>${supportNumber}</a>.`); },
  },
  AddMissingEmailWhileBlocked: {
    text_missing_email1: 'In an ongoing effort to provide the best possible service, please add an email address. After submitting the information, my529 will send an email to the address you provided that contains a validation link. If you do not receive the email, please check your spam folder.',
    text_missing_email2: 'After you receive the message, click on the link provided in the email and follow the prompts to log in.',
    text_missing_email3: 'For the security of your account, you will be logged out while you verify your new email address.',
  },
  AddressCard: {
    btn_delete(addressType) { return `${buttons.delete} ${addressType}`; },
    btn_update(addressType) { return `${buttons.change} ${addressType}`; },
    err_postal_code_length: errors.postal_code_reqs,
    err_required: errors.required,
    err_smarty_streets: errors.smarty_streets_generic,
    err_street1_max_length: errors.field_max_length_40,
    head_title: 'Addresses',
    msg_address_deleted: 'Address deleted.',
    msg_address_updated: 'Address updated.',
    text_intl_address: intl_address,
  },
  AppTour: {
    btn_back: buttons.back,
    btn_finish: buttons.finish,
    btn_next: buttons.next,
    btn_skip: buttons.skip,
    head_more_info: 'More Information',
    head_nav: 'Navigation',
    head_notifications: 'Notifications',
    head_table_menu: 'Tables - Row Menu',
    head_table_search: 'Tables - Search & Filter',
    head_transactions: 'Transactions',
    head_welcome: 'Welcome',
    text_dont_show: 'Do not show again',
    text_more_info: "When additional information is available about a term or feature, you'll see an icon like the one below. Simply hover over the icon to reveal more information.",
    text_nav: 'Left hand main navigation can be expanded to find all the familiar pieces of Login at my529.',
    text_notifications: 'Right hand notifications can be expanded to see that you are always up to date.',
    text_table_menu: "You'll see a lot of data tables throughout the application. Often times, each row will have a menu that can be used to view more details or take an action.",
    text_table_search: 'Data tables will often have the ability to search/filter the data. That is done by clicking on the Search icon and typing a search term.',
    text_transactions1: 'Contributions',
    text_transactions2: 'Click a button to perform a transaction',
    text_transactions3: 'Click the Transactions menu to see all options',
    text_transactions4: "Close this website tour, and click an account number to view that account's detail. In the bottom right corner of the page, notice this action icon.",
    text_transactions: 'Transactions can be accessed multiple ways. After closing this website tour, scroll down to see one of these options:',
    text_welcome2: 'Complete this tour to see where to find your information and how to perform a transaction.',
    text_welcome: 'The my529 account you created to support a journey toward a higher education has a new smile.',
  },
  BalanceByDate: {
    btn_filter: 'Filter Accounts',
    lbl_date: 'Date',
    text_NA: NA,
    title_account_numbers: 'Account Numbers',
    title_bene_names: 'Beneficiary Names',
    title_investment_options: 'Investment Options',
  },
  BankAccountsLearnMore: {
    head_title: 'Bank Accounts',
    text_info_1: 'Electronic contributions can be submitted without completing the bank verification process on this page.',
    text_info_2: 'The account owner bank accounts are linked to your my529 accounts and may be used for contributions immediately. The bank accounts may require verification before they can be used to receive electronic withdrawals from your my529 accounts.',
    text_info_3: 'The following types of withdrawals must be done by submitting a completed Withdrawal Request form (form 300):',
    text_info_4: [
      'Rollover to another 529 plan or to an ABLE account',
      'Withdrawals to international educational institutions and account owner/agents and beneficiaries with foreign addresses',
      'Withdrawals requiring a signature guarantee',
    ],
    text_info_5: 'A signature guarantee is required on your withdrawal request for',
    text_info_6(amount, days) {
      return [
        'an account ownership change in the previous 10 calendar days',
        `withdrawal request of ${currencyFormatter(amount)} or more`,
        `multiple withdrawals equaling ${currencyFormatter(amount)} or more within a rolling ${days}-day period`,
      ];
    },
    text_info_7: 'UGMA/UTMA accounts are not eligible for electronic withdrawals because withdrawal checks need to be noted when sourced from UGMA/UTMA funds.',
    text_info_8: 'Qualified withdrawals of K-12 tuition expenses will be distributed only to the account owner.',
  },
  BeneContact: {
    btn_add_address: 'Click here to add address',
    title_update_bene_address: 'Update Beneficiary Address',
    text_no_address(addressType) { return `No ${addressType} address has been provided.`; },
  },
  BeneContactForm: {
    btn_change: buttons.change,
    btn_delete: buttons.delete,
    err_field_required: errors.required,
    err_postal_code: errors.postal_code_reqs,
    err_smarty_streets: errors.smarty_streets_generic,
    err_street1_max_length: errors.field_max_length_40,
    head_title: 'Beneficiary Contact',
    head_title_beneficiary_contact: 'Beneficiary Contact',
    msg_address_changed: 'Address changed.',
    msg_address_deleted: 'Address deleted.',
    msg_unsaved_changes: unsaved_changes,
    text_intl_address: intl_address,
  },
  BeneficiaryStep: {
    btn_back: buttons.back,
    btn_continue: buttons.continue,
    err_copy_ssn: errors.copy_ssn_tin,
    err_phone_number_too_short: errors.phone_number_too_short,
    err_smarty_streets: errors.smarty_streets_generic,
    err_ssns_must_match: errors.ssns_tins_must_match,
    head_beneficiary: 'Beneficiary',
    lbl_birthdate: formLabels.birthdate,
    lbl_cancel: formLabels.cancel,
    lbl_clear_state: formLabels.clear_state,
    lbl_existing_bene: 'New Account For Existing Beneficiary',
    lbl_firstName: formLabels.firstName,
    lbl_lastName: formLabels.lastName,
    lbl_mailing_is_same_as_ao: 'Mailing address is same as Account Owner',
    lbl_middleName: formLabels.middleName,
    lbl_physical_is_same_as_ao: 'Physical address is same as Account Owner',
    lbl_relationship: 'Relationship to Account Owner',
    lbl_ssn_tin: `${formLabels.ssn}  /  ${formLabels.tin}`,
    lbl_ssn_tin_confirm: `Confirm ${formLabels.ssn}  /  ${formLabels.tin}`,
    lbl_utah_tax_payer: formLabels.beneUtahTaxPayer,
    msg_bene_info_saved: 'Beneficiary information saved.',
    text_what_is_bene: [
      'The beneficiary is the person whose qualified education expenses are expected to be paid from the account.',
      'You may name only one beneficiary per account.',
      'The beneficiary must have a valid U.S. Social Security or Taxpayer Identification Number.',
      'You may name yourself as the beneficiary.',
      'The person designated as a beneficiary has no independent claim, right, or access to any funds in an account solely by virtue of such designation.',
    ],
  },
  ComposableSearch: {
    text_search_suggestion(filterTerm, columnTitle) { return `Find "${filterTerm}" in ${columnTitle}`; },
  },
  ConfirmationStep: {
    btn_to_home: 'Home',
    head_congratulations: 'Congratulations!',
    text_account_created: 'You have successfully completed creating your account.',
    text_account_number: 'Account Number',
    text_add_another_account: 'Add Another Account',
  },
  Contributions: {
    head_title_account_owner: 'Account Owner',
    lbl_one_time: 'One-Time',
    lbl_recurring: 'Recurring',
    lbl_special_occasion: 'Special Occasion',
    msg_contribution_scheduled: 'Contribution scheduled.',
    msg_contribution_updated: 'Contribution updated.',
    nav_title_edit: 'Edit Contributions',
    nav_title_new: 'New Contributions',
    title_duplicate_contribution: 'Create Duplicate Contribution?',
  },
  ContributionOneTime: {
    msg_unsaved_changes: unsaved_changes,
    err_account_select: 'Select an account or remove the row.',
    err_bankAccount_select: 'Select an existing bank account or add a new one.',
    err_step_does_not_exist: 'Step does not exist!',
    lbl_step_labels: ['Amount', 'Date & Bank Account', 'Terms & Conditions', 'Print Review'], //TODO: Removed 'Contribution' from date step. Confirm this is ok. Better on mobile
  },
  ContributionOneTimeStep0: {
    lbl_add_account: 'Add account',
    lbl_amount: 'Amount',
    lbl_remove_account: 'Remove account',
    lbl_selected_account: 'Selected Account',
    err_amount_min_max(min, max) { return `Enter an amount between ${min} and ${max}.`; },
    text_total: 'Total:',
  },
  ContributionOneTimeStep1: {
    btn_add_bank: 'Add Bank Account',
    lbl_contribution_date: 'Contribution Date',
    lbl_bank_account: 'Bank Account',
    lbl_add_bank: 'Add new bank account',
  },
  ContributionOneTimeStep2: {
    lbl_read_terms: 'I have read and understand the above terms and conditions.',
  },
  ContributionOneTimeStep3: {
    text_print_copy: 'Please print a copy of this page for your records.',
    text_timezone: mountain_time_zone,
  },
  ContributionOneTimeReview: {
    title_account_number: 'Account Number',
    title_amount: 'Amount',
    title_bank_account: 'Bank Account',
    title_bene_name: 'Beneficiary Name',
    title_date: 'Date',
    title_total: 'Total:',
  },
  ContributionRecurring: {
    msg_unsaved_changes: unsaved_changes,
    err_bankAccount_select: 'Select an existing bank account or add a new one.',
    err_end_date_after_start: 'End date must occur after start date.',
    err_end_date_after_both: 'End date must occur after both start dates.',
    err_second_date_required: 'Second date is required for twice monthly contributions.',
    err_second_date_within_month: 'Date must occur within 1 month after first start date.',
    err_step_does_not_exist: 'Step does not exists.',
    lbl_step_labels: ['Amount & Bank Account', 'Contribution Frequency', 'Terms & Conditions', 'Print Review'],
    text_end_before_next_run: 'Recurring contribution is scheduled to end before next run date.',
    text_next_run: 'Next run date:',
  },
  ContributionRecurringStep0: {
    btn_add_bank: 'Add Bank Account',
    err_amount_between(minAmount, maxAmount, twiceMonthlyMaxAmount) { return `Enter an amount between ${minAmount} and ${maxAmount} for monthly contributions, or between ${minAmount} and ${twiceMonthlyMaxAmount} for twice monthly contributions.`; },
    lbl_amount: 'Amount',
    lbl_bank_account: 'Bank Account',
    lbl_selected_account: 'Selected Account',
    text_add_new_bank: 'Add a new bank account',
  },
  ContributionRecurringStep1: {
    err_amount_between(minAmount, maxAmount, twiceMonthlyMaxAmount) { return `Go back and enter an amount between ${minAmount} and ${maxAmount} for monthly contributions, or between ${minAmount} and ${twiceMonthlyMaxAmount} for twice monthly contributions.`; },
    err_max_date: 'Date must occur within 1 month after first start date.',
    lbl_first_date(isEditing, monthly) { return isEditing ? 'Next Scheduled Day' : monthly ? 'Contribution Start Date' : 'First Start Date'; }, // eslint-disable-line
    lbl_monthly: 'Monthly',
    lbl_option_end_date: 'Optional End Date',
    lbl_second_date(isEditing) { return isEditing ? 'Following Scheduled Day' : 'Second Start Date'; },
    lbl_twice_monthly: 'Twice Monthly',
  },
  ContributionRecurringStep2: {
    lbl_read_terms: 'I have read and understand the above terms and conditions.',
  },
  ContributionRecurringStep3: {
    text_print_copy: 'Please print a copy of this page for your records.',
    text_timezone: mountain_time_zone,
  },
  ContributionRecurringReview: {
    text_scheduled_days(monthly, day1, day2) { return monthly ? `On the ${day1} of every month` : `On the ${day1} and ${day2} of every month`; },
    title_account_number: 'Account Number:',
    title_amount: 'Amount',
    title_bank_account: 'Bank Account:',
    title_bene_name: 'Beneficiary Name:',
    title_end_date: 'End Date:',
    title_next_date: 'Next Contribution Date:',
    title_scheduled_day(monthly) { return monthly ? 'Scheduled Day:' : 'Scheduled Days:'; }
  },
  ContributionSpecial: {
    msg_unsaved_changes: unsaved_changes,
    err_account_select: 'Select an account or remove the row.',
    err_bankAccount_select: 'Select an existing bank account or add a new one.',
    err_end_date_after_start: 'End date must occur after start date.',
    err_step_does_not_exist: 'Step does not exists.',
    lbl_step_labels: ['Amount & Date', 'Bank Account', 'Terms & Conditions', 'Print Review'],
  },
  ContributionSpecialStep0: {
    err_amount_min_max(min, max) { return `Enter an amount between ${min} and ${max}.`; },
    lbl_add_account: 'Add account',
    lbl_amount: 'Amount',
    lbl_birthday: 'Birthday',
    lbl_occasion: 'Occasion Type',
    lbl_optional_end_date: 'Optional End Date',
    lbl_remove_account: 'Remove account',
    lbl_start_date: 'Start Date',
    lbl_selected_account: 'Selected Account',
    text_multiple_annual_events: 'To create multiple annual event contributions, complete this contribution and repeat the process.',
    text_next_run: 'Next run date:',
    text_total: 'Total:'
  },
  ContributionSpecialStep1: {
    btn_add_bank: 'Add Bank Account',
    lbl_bank_account: 'Bank Account',
    lbl_add_bank: 'Add new bank account',
  },
  ContributionSpecialStep2: {
    lbl_read_terms: 'I have read and understand the above terms and conditions.',
  },
  ContributionSpecialStep3: {
    text_print_copy: 'Please print a copy of this page for your records.',
    text_timezone: mountain_time_zone,
  },
  ContributionSpecialReview: {
    title_account_number: 'Account Number:',
    title_amount: 'Amount',
    title_bank_account: 'Bank Account:',
    title_bene_name: 'Beneficiary Name:',
    title_end_date: 'End Date:',
    title_next_run_date: 'Next Run Date:',
    title_total: 'Total:',
    text_none: 'None',
  },
  ContributionTermsConditions: {
    head_title: 'Terms and Conditions',
    head_review_terms: 'Please review the following Terms and Conditions regarding contributions',
    text_about_special_occasion: 'About Special Occasion Contributions:',
    text_about_special: [
      'You may initiate annual contributions for birthdays, holidays, or other special occasions by entering a dollar amount and date (when applicable) when you want the transaction to occur.',
      'Contributions will occur every year on the date selected (or the next business day if the date falls on a holiday or weekend).',
      'One week prior to the scheduled contribution day, my529 will send you an email reminder of the upcoming contributions.',
    ],
    text_bad_accounts_warning: 'Warning: The following types of accounts cannot be used to fund online contributions. Requests to do so will be rejected and may incur reject fees and/or market losses:',
    text_bad_accounts: [
      'Brokerage and mutual fund accounts (such as Vanguard, Charles Schwab, etc.)',
      'Accounts drawn on credit unions that do not clear their own checks (they use third-party routing numbers)',
      'Savings accounts with restricted access privileges',
      'Bank accounts with UGMA/UTMA designations',
    ],
    text_check: 'Although online contributions may not be made from the accounts listed above, checks drawn from those accounts may be submitted, as long as they are:',
    /* eslint-disable quotes */
    text_check_requirements: [
      'Made payable to my529',
      'Sent to my529, PO Box 145100, Salt Lake City, UT 84114-5100',
      "Marked with the beneficiary's name and my529 account number",
      "Checks drawn from bank accounts with UGMA/UTMA designations must be contributed to a my529 UGMA/UTMA account in the beneficiary's name.",
    ],
    text_notes: 'Notes:',
    text_notes_list1: [
      'A separate transaction will appear on your bank statement for each my529 account receiving a contribution.',
      'Your my529 quarterly statement will reflect the contribution(s). This is your contribution receipt.',
      'The funds will normally be debited from the bank account within three business days after the contribution appears in the my529 account.',
      'my529 UGMA/UTMA accounts may not be funded with an online contribution schedule. Instead, to set up or change automated contributions to an UGMA/UTMA account, please submit the Scheduled Electronic Contributions Authorization/Change form (form 200).',
      'You will receive an email confirming the setup, change, or cancellation of their contribution schedule.',
      'Please ensure that funds are available on the agreed-upon contribution day(s). Returned transactions or insufficient funds could cause fees to be assessed by my529 and/or the financial institution.',
    ],
    text_notes_list2: 'Cancellation Instructions: ',
    text_notes_list3: 'This authorization is to remain in full effect until the end date (if applicable) or until the following:',
    text_notes_list4_sublists_special({ SupportPhoneNumber }) {
      return [
        'The bank account(s) specified above are deleted; or',
        'my529 is notified of the revocation of this authorization by doing one of the following:',
        [
          'logging into Account Access at my529.org and using the online account feature to manage Contributions; or',
          `contacting my529 by telephone at ${SupportPhoneNumber}.`,
        ]
      ];
    },
    text_notes_list4_sublists_non_special({ SupportPhoneNumber, FaxNumber }) {
      return [
        'The bank account(s) specified above are deleted; or',
        'my529 is notified of the revocation of this authorization by doing one of the following:',
        [
          'logging into Account Access at my529.org and using the online account feature to manage Contributions;',
          `contacting my529 by telephone at ${SupportPhoneNumber}; or`,
          `submitting Form 200 via fax to ${FaxNumber} or via U.S. Mail to PO Box 145100, Salt Lake City, UT 84114-5100.`
        ]
      ];
    },
    text_notes_list5: 'To cancel or make changes to this contribution, your online request must be received before the close of market trading with the New York Stock Exchange (NYSE) (normally 2:00 p.m. MT), on the business day of the scheduled contribution. This contribution may not be cancelled or changed after that deadline. Any other revocation will not be effective until my529 has had a reasonable opportunity to act on the termination notification.',
    text_authorize: "I authorize my529 to initiate, and my financial institution to honor, an electronic payment in the amount(s) specified above from the account owner's bank account. This authority pertains to the account(s) listed above. The information I have provided is accurate. I understand that rejected transactions or insufficient funds could cause fees to be assessed by my529 and/or the account owner's financial institution.",
  },
  default_title: 'my529 Account Access Portal',
  n_a: NA,
  ChangeEmailWhileBlocked: {
    text_verify_email1: 'If the email address shown here is incorrect, correct it now.',
    text_verify_email2: 'An email message will be sent to the new email address that contains a link that you are required to click on for verification purposes.',
    text_verify_email3: 'For the security of your account, you will be logged out while you verify your new email address.',
  },
  CurrentAllocation: {
    btn_chart_toggle: 'View Chart',
    btn_list_toggle: 'View List',
    head_account_closed: 'Account Closed',
    head_no_info: 'No information to display',
    text_actual_percentage: 'Actual',
    text_make_contribution: 'Please make a contribution into the account to view more information.',
    text_target_percentage: 'Target',
    text_value: 'Value',
    title_actual: 'Actual',
    title_category: 'Category',
    title_value: 'Value',
  },
  Details: {
    btn_option_change: 'Create Option Change',
    head_account_number: 'Account Number',
    head_account_type: 'Account Type',
    head_beneficiary: 'Beneficiary',
    head_current_tax_year_contributions: 'Current Tax Year Contributions',
    head_date_opened: 'Date Opened',
    head_earnings: 'Earnings',
    head_investment_option: 'Investment Option',
    head_mailing_address: 'Mailing',
    head_netPrincipal: 'Net Principal',
    head_other_address: 'Other',
    head_ppr: 'Personal Rate of Return (YTD)',
    head_physical_address: 'Physical',
    head_prior_tax_year_contributions: 'Prior Tax Year Contributions',
    head_remaining_option_changes: 'Option Changes',
    head_total_value(marketDate) { return `Total as of ${marketDate}`; },
    title_see_PPR: 'See Personal Rate of Return',
  },
  Documents: {
    btn_cancel: buttons.cancel,
    btn_open_pdf: 'Open PDF',
    btn_save: buttons.save,
    err_required: errors.required,
    lbl_from_name: 'From Name',
    msg_certificate_updated: 'Account Certificate Updated.',
    msg_interested_party: 'Interested Party',
    nav_path: '/documents',
    nav_path_for_certificate: '?document=Account%20Certificate',
    nav_title: 'Documents',
    text_edit_certificate: 'Your beneficiary\'s certificate will include your name as the person who established their account. If you would like to customize the name to something other than your full name to appear (e.g., "Grandma and Grandpa"), enter it here.',
    text_no_documents: 'No documents to display',
    title_account_number: 'Account Number',
    title_account_type: 'Account Type',
    title_bene_name: 'Beneficiary Name',
    title_description: 'Description',
    title_download: 'Download',
    title_document_type: 'Document Type',
    title_quarter: 'Quarter',
    title_view_additional: 'View Additional Pages',
    title_view_document: 'Statement',
    title_year: 'Year',
    title_edit_certificate: 'Edit Account Certificate',
  },
  EmailCard: {
    btn_cancel_change_email: buttons.cancel,
    btn_change_email: 'Change Email',
    btn_confirm_change_email: 'Change email and log out',
    btn_confirm_submit_email: 'Submit email and log out',
    btn_resend_email: 'Resend Verification Email',
    err_different_email: 'Email address is same as current',
    err_emails_must_match: errors.emails_must_match,
    err_required: errors.required,
    err_valid_email: errors.email_invalid,
    head_title: 'Change Email',
    head_verify_email: 'Change Email Address',
    head_missing_email: 'Add Email Address',
    lbl_current_email: 'Current Email',
    lbl_new_email: 'New Email',
    lbl_new_email_confirm: 'Confirm Email',
    msg_check_email_to_verify(supportNumber) { return `Please check your email for a message to verify your changed email address. Contact my529 support at ${supportNumber} for help.`; },
    msg_unsaved_changes: unsaved_changes,
    text_ip_email_visible_to_ao: 'Any changes of the email address will be viewable by the account owner.',
    text_verify_email1: 'Are you sure you want to change your email address to: ',
    text_verify_email2: 'If you confirm, an email message will be sent to the above address that contains a link that you are required to click on to verify the new email address.',
    text_verify_email3: 'For the security of your account, you will be logged out while you verify your new email address.',
  },
  EditBankModal: {
    btn_save_nickname: buttons.save,
    err_allowable_chars: "Allowable characters: alphanumeric and .,':#/-",
    err_required: errors.required,
    title_modal: 'Rename',
    lbl_nickname: 'Nickname'
  },
  ForgotPassword: {
    btn_cancel: buttons.cancel,
    btn_submit: buttons.submit,
    err_password: errors.required,
    err_username: errors.required,
    msg_success: 'Request submitted. Check your email.',
    nav_path: '/forgot-password',
    nav_title: 'Forgot Password',
    text_instructions(supportNumber) { return `If the username is found, password reset instructions will be sent to the associated email address. If you have any questions, please call my529 at ${supportNumber}`; },
    text_username: 'Username',
    msg_recaptcha_language: recaptchaLanguage
  },
  ForgotUsername: {
    nav_path: '/forgot-username',
    nav_title: 'Forgot Username',
    btn_cancel: buttons.cancel,
    btn_ok: buttons.ok,
    btn_submit: buttons.submit,
    err_valid_email: errors.email_invalid,
    err_email_required: 'You must provide an Email',
    lbl_email: 'Email',
    msg_general(supportNumber) { return `If the email is in the system, an email with your username will be sent to you. If you are experiencing difficulties call my529 at ${supportNumber}`; },
    msg_request_submitted: 'Request submitted. Check your email.',
    msg_recaptcha_language: recaptchaLanguage
  },
  GiactRejectionModal: {
    btn_close: buttons.close,
    head_title: 'Authentication Failed',
    text_not_authenticated: 'Our verification process was not able to authenticate your financial institution account.',
    text_rejection_language_1: 'Please ensure that the name at the financial institution matches your name exactly and that you are not attempting to use a bank account associated with a third party.',
    text_rejection_language_2: 'Account verification services are provided by GIACT Systems, LLC and are based on information provided by financial institutions. my529 does not have information regarding the specific reasons for a decline.',
    text_rejection_language_3: 'How Do I Correct Information GIACT Has About Me?',
    text_rejection_language_4() {
      return (
        <p>If information reflected within your bank or credit union Disclosure of Consumer Report Information is inaccurate, you may initiate a dispute at no cost by calling GIACT Systems toll-free at 833.802.8092 from 8:30 a.m. to 5 p.m. CT, emailing <a href='mailto:support@giact.com' target='_blank' rel='noopener noreferrer'>support@giact.com</a>, or writing to GIACT Systems at:</p>
      );
    },
    text_giact_address_1: 'GIACT Systems, LLC.',
    text_giact_address_2: 'Attention: Consumer Resolutions',
    text_giact_address_3: 'P.O. Box 1116',
    text_giact_address_4: 'Allen, TX 75013',
    text_rejection_language_5: 'For more information, visit ',
    text_rejection_language_6: 'Additional Questions?',
    text_rejection_language_7: 'You may contact my529 by emailing ',
    text_rejection_language_8: ' or by calling our office toll-free at 800.418.2551 on business days from 7 a.m. to 5 p.m. MT.',
  },
  Gifting: {
    btn_add: buttons.add,
    btn_cancel: 'cancel',
    btn_done: 'save',
    btn_email: 'email',
    btn_facebook: 'facebook',
    btn_learn_more: buttons.learn_more,
    btn_manage: 'manage',
    btn_ok: buttons.ok,
    btn_remove: buttons.remove,
    btn_twitter: 'twitter',
    lbl_account: 'account',
    lbl_account_owner: 'account owner',
    lbl_beneficiary: 'beneficiary',
    lbl_code: 'code',
    lbl_enabled: 'Enabled',
    lbl_disabled: 'Disabled',
    lbl_hide_last_name: 'Hide Last Name',
    lbl_hidden: 'Hidden',
    lbl_inactive: 'Inactive',
    lbl_no_history: 'No Gifts Received Yet',
    lbl_history: 'History of Gifts Received',
    lbl_page_views: 'page views',
    lbl_manage_gift: 'manage gift',
    lbl_message: 'message',
    lbl_shown: 'Shown',
    lbl_social: 'social',
    lbl_status: 'status',
    lbl_url: 'url',
    msg_no_beneficiary: 'Beneficiary\'s have not been configured yet.',
    msg_email_subject(name) { return `Family and friends: Looking for a meaningful BIRTHDAY/HOLIDAY gift for ${name}?`; },
    msg_save: 'Your changes were saved.',
    head_title: 'Beneficiary Gifts',
    learn_more_title: 'Gifting Program Dashboard',
    manage_title: 'Manage Gift',
    nav_path: '/gifting',
    nav_title: 'Gifting',
    text_copy_link: 'Gifting link was copied to the clipboard',
    text_enable_gifting: 'Enable gifting to generate URL.',
    text_hide_last_name: 'This option hides the account owner and beneficiary middle & last name(s) from the public gifting website.',
    text_learn_more: 'You can have my529 set up Gift Codes for you to allow family and friends to contribute to your account(s).',
    text_max_aggregate(maxAmount) { return `The maximum aggregated balance for this beneficiary of ${maxAmount} has been met; no additional contributions can be accepted at this time for this beneficiary.`; },
    text_default_message(name) { return `Hi, thank you for your interest in ${name}'s education. Your contribution will help make dreams come true.`; },
    text_default_email(name, link) { return `I save for ${name}'s future qualified college expenses with my529, a 529 college savings program. Because many of you give gifts for birthdays and holidays, I'd like to pass along this link, ${link} which allows you to contribute to ${name}'s college savings account. Thank you for considering a gift for the future! ${link}`; },
    text_faq(currentYear, annualGiftExclusionAmount, maximumBeneficiaryDeposit, maximumBenficiaryAccountValueForGift) {
      return [
        {
          question: 'What is the my529 Gift Program?',
          answer: 'The my529 Gift Program is a simple and convenient service that allows account owners to invite family and friends to contribute to their my529 college savings account.'
        },
        {
          question: 'How do I invite family and friends to participate in my529\'s Gift Program?',
          answer: 'To give family and friends the opportunity to contribute to your my529 account, please enroll in my529\'s Gift Program through Account Access and select Gift Program. When you enroll, you will receive a unique gift code and a URL that links to your personal gift page. When shared with family and friends, the gift code and link ensure the proper deposit of any gift contributions, without disclosing your personal my529 account information. Anyone with your gift code will be able to access your gift page. As the account owner, you decide with whom you\'d like to share the link. You may share this gift code or link by email, in person, by phone, or by mail. my529 also provides links and default messages to share your gift page using Twitter and Facebook. You may copy and use the URL on your other social media sites as well. You may also turn off access to your page in your my529 Gift Program dashboard.'
        },
        {
          question: 'Do friends and family need to have a my529 account to contribute to my account?',
          answer: 'No. Anyone may contribute to your my529 account as long as he or she has your my529 gift code or link to your gift page.'
        },
        {
          question: 'What if I don\'t have someone\'s email address?',
          answer: 'You may also pass along your gift code in person, by mail, or by phone.'
        },
        {
          question: 'How will my friends and family give the gift?',
          answer: 'They may give a gift in three ways: by using a debit card; by making an electronic funds transfer from their bank or credit union; or by mailing a check. When making a debit card contribution, they will provide a debit card number and will be assessed a service fee. When contributing via electronic funds transfer, they will provide a routing number and account number through the secure gift page. When making a payment by mail, they will print a deposit slip found on the gift page and mail it with a check to my529.'
        },
        {
          question: 'When people give gifts, do they have access to my my529 account information?',
          answer: 'No. When someone gives a gift, the gift code or gift link ensures that the funds will be directed to your my529 account without sharing your account number in the process.'
        },
        {
          question: 'How will I know if I\'ve received a gift?',
          answer: 'my529 will notify you by email when a gift posts to your account. You will be able to view the gift online in Account Access usually within three days after it has posted to your account, and it will be included in your account balance.'
        },
        {
          question: 'May I specify my unborn baby as a beneficiary on my account to receive gifts before the baby is born?',
          answer: 'Because a U.S. Social Security Number is required for all beneficiaries, your unborn baby cannot yet be named as the beneficiary on the account. You may, however, name yourself as the beneficiary until the baby is born. When your baby is born, simply change the beneficiary on your account to your baby\'s name using a Beneficiary Change/Correction form (form 510). The new beneficiary must be a "member of the family" of the current beneficiary, as defined in the my529 Program Description. Please note that contributions made to accounts before the baby\'s birth, to a beneficiary age 19 or older, do not qualify for the Utah state income tax credit.'
        },
        {
          question: 'May I return a gift made to my my529 account?',
          answer: 'No. Gifts made to a my529 account are considered \'completed\' gifts. Once gift funds are invested in your my529 account, they blend with other contributions. Any withdrawal is treated as a distribution from your account. Returning a gift would be considered a "nonqualified withdrawal," subject to taxes and a 10 percent federal penalty tax on any earnings. To return a gift, you may want to withdraw money from a different type of account, your checking or savings account, for example, to avoid taxes.'
        },
        {
          question: 'What if I have a question about a gift made to my account?',
          answer: 'You may contact my529 toll-free at 800.418.2551 or by email at info@my529.org. Representatives are available to answer questions on regular business days from 7 a.m. to 5 p.m. MT.'
        },
        {
          question: 'Do I owe taxes on gifts made to my my529 account?',
          answer: `You should not owe taxes on gifts made to your my529 account, but people who make large gifts to your account may owe gift taxes. A gift tax or generation skipping transfer (GST) tax may occur for someone making gifts more than ${annualGiftExclusionAmount} per year to any one person. Anyone giving large monetary gifts should consult a tax advisor before sending the contributions to my529. my529 does not provide tax advice to the person giving the gift.`
        },
        {
          question: 'Does the gift count toward my my529 account balance limit?',
          answer: `Yes. For ${currentYear}, my529 will accept contributions until all my529 account balances for the same beneficiary total ${maximumBeneficiaryDeposit}, above which additional contributions are not allowed. However, there is a separate limit for gifts. When the account balance has reached ${maximumBenficiaryAccountValueForGift}, any gift contribution will be returned to the person who made it. Please see the my529 Program Description, downloadable at my529.org, for more information about contribution limits.`
        },
        {
          question: 'When are gift contributions invested?',
          answer: 'If the contribution is received in good order, it will be posted to your my529 account within three business days.'
        },
        {
          question: 'How are gift contributions allocated?',
          answer: 'Gift contributions will be allocated according to the investment option and/or allocations that you, as the account owner, have selected for your my529 account.'
        },
        {
          question: 'Are there any fees associated with giving a gift through the my529 Gift Program?',
          answer: 'There is a service fee charged per debit card transaction by J.P. Morgan as a third-party debit card processor. The fee covers the cost of securely processing debit card contributions. There are no fees associated with online bank account contributions or contributions made by sending a check.'
        },
        {
          question: 'Will the unique gift code and URL that links to my gift page ever expire?',
          answer: 'No. Both the unique gift code and URL will be active as long as you participate in the my529 Gift Program.'
        },
        {
          question: 'Are gift contributions available for immediate withdrawal?',
          answer: 'If your account ownership with my529 is less than six months old, gift contributions made online using a bank account or using a check by mail are held for 20 business days. If your account ownership has been established for at least six months, online gift contributions are held for at least seven business days. Gift Program contributions made by debit card cannot be withdrawn until 45 business days after deposit.'
        }
      ];
    },
    error_message_empty: 'Message is required',
    error_message_allowed_chars: "Allowable characters: alphanumeric and !?()$.,:;-='\\",
    text_input_placeholder: 'Type your custom message here'
  },
  Header: {
    btn_main_menu: 'Main menu',
    text_browser_warning: 'You are currently using a browser that is not supported. For the best experience, please use Chrome or Firefox.',
    text_notifications: 'Notifications',
  },
  Home: {
    btn_list_view: 'List view',
    btn_tile_view: 'Tile view',
    head_accounts: 'Accounts',
    head_overview: 'Overview',
    head_greeting_morning: 'Good Morning',
    head_greeting_afternoon: 'Good Afternoon',
    head_greeting_evening: 'Good Evening',
    nav_path: '/home',
    nav_title: 'Home',
  },
  InterestedParties: {
    btn_add_ip: 'Add Interested Party',
    btn_add_ip_read_terms: buttons.read_terms,
    btn_agree_terms: buttons.agree_terms,
    btn_close: buttons.close,
    btn_delete_ip: 'Delete Interested Party',
    btn_edit_ip: 'Edit Interested Party',
    btn_learn_more: 'Learn More',
    btn_ok: buttons.ok,
    btn_resend_email: 'Resend email',
    btn_save: buttons.save,
    err_add_ip_terms_agree: errors.terms_agree,
    err_copy_email: errors.copy_email,
    err_emails_must_match: errors.emails_must_match,
    err_select_account: 'You must select at least one account.',
    head_account: 'Account',
    head_accounts: 'Accounts',
    head_bene_name: 'Beneficiary Name',
    head_delete_ip: 'Delete this Interested Party?',
    head_email: 'Email',
    head_email_sent: 'Email Sent',
    head_interested_parties: 'Interested Parties',
    head_name: 'Name',
    head_opt_name: 'Option Name',
    head_pin: 'Pin',
    head_read_only: 'Read-Only Access',
    head_status: 'Status',
    lbl_email: formLabels.email,
    lbl_email_confirm: formLabels.email_confirm,
    msg_ip_added: 'Interested Party added.',
    msg_ip_deleted: 'Interested Party deleted.',
    msg_ip_edited: 'Interested Party edited.',
    nav_path: '/interested-parties',
    nav_title: 'Interested Parties',
    text_add_ip_terms() { return `By clicking ${this.btn_save}, I understand that I am allowing my interested party to view, download, transmit to their third-party agent(s), and/or instruct my529 to transmit to my interested party's third - party agent the following information related to the my529 account(s) designated above: (1) the account number, (2) account owner and beneficiary names and contact information, (3) primary and secondary successor names, (4) transaction history, (5) investment options, (6) account balances, and(7) quarterly statements.I also understand that this does not give my interested party permission to make changes to the account.`; },
    text_confirm_delete_ip(emailAddress) { return `Are you sure you want to remove Interested Party access for ${emailAddress}?`; },
    text_faq_answers() {
      return [
        `Once you add an interested party, a request will be sent to them using the email address you provide. The interested party will be prompted to enter a PIN. The PIN will be provided to you during the setup process and will be shown next to the interested party's email address and name. You will need to communicate the PIN to the interested party before they will be able to set up access to your accounts. The email request to the interested party will be valid for 10 days from the time you set it up. Should that time expire before the interested party signs up, you may click the "${this.btn_resend_email}" button and a new email will be sent to them. At that time, a new PIN will also be generated and the previous email and PIN will be invalid. As before, you will need to communicate the new PIN to the interested party.`,
        `If the email address you entered is incorrect, click on the "${this.btn_delete_ip}" button that appears on the row associated with the interested party. After you delete the interested party, the information associated with the account(s) will no longer be available for the interested party to view. The interested party may still log on but will be unable to view any of your accounts.`,
        'To change the email address associated with the interested party, delete the interested party and add them again with the new email address, following the same process as before.',
        `For security purposes, if the interested party forgets their password, they will need you to resend the email request by clicking the "${this.btn_resend_email}" button. A new email will be sent to them, and a new PIN will be generated. You will need to communicate the new PIN to them, and they will be able to re-establish their interested party access by responding to the request and entering a new user name, password, and PIN.`,
        `Click on the "${this.btn_edit_ip}" button on the row associated with the interested party. Check or uncheck the boxes to add or remove the accounts from their view and then click "Save." The change will take place immediately without notifying the interested party. If you want to remove all access, click the "Delete" link on the row associated with the interested party.`,
      ];
    },
    text_faq_questions: [
      'How does it work?',
      'Wrong email address?',
      'Need to change the email address of the interested party?',
      'Did the interested party forget their password?',
      'Need to add or remove accounts from an interested party?',
    ],
    text_learn_more() { return `You may grant online read-only access to interested parties. An interested party can be a family member, or anyone to whom you would like to grant read-only access to one or more of your my529 accounts. You are in control of this feature and can turn it on or off to selected individuals at your discretion. Interested party access does not give permission to modify the account but will allow the interested party, for the account(s) you designate, to view, download, transmit to their third-party agent(s), and/or instruct my529 to transmit to their third-party agent: (1) the account number, (2) account owner and beneficiary names and contact information, (3) primary and secondary successor names, (4) transaction history, (5) investment options, (6) account balances, and (7) quarterly statements. To add an interested party, click the ${this.btn_add_ip} button. You may only set up one interested party at a time, but may set up multiple interested parties by repeating the process.`; },
    text_setup_ip_access: 'Set up Interested Party access',
    text_success: [
      'The interested party has been sent an email requesting their participation.',
      'Upon accepting your request, the interested party will need to enter the PIN provided below to validate his/her setup. You, the account owner, are responsible for providing the interested party the following PIN:',
      'The email request to the interested party will be valid for the next 10 days.',
    ],
  },
  InterestedPartiesActivation: {
    btn_create: buttons.create,
    btn_i_agree: buttons.agree_terms,
    btn_learn_more: buttons.learn_more,
    btn_read_terms: buttons.read_terms,
    err_password_criteria: errors.password_criteria,
    err_password_match: errors.passwords_match,
    err_required_field: errors.required,
    err_terms_agree: errors.terms_agree,
    head_already_have_account: 'I already have an account.',
    head_create_account: 'Create an account.',
    head_login: 'Log in.',
    head_terms: 'Terms and Conditions',
    lbl_email: formLabels.email,
    lbl_firstname: formLabels.firstName,
    lbl_lastname: formLabels.lastName,
    lbl_password1: formLabels.password,
    lbl_password2: formLabels.password_confirm,
    lbl_pin: formLabels.pin,
    lbl_username: formLabels.username,
    msg_change_email_when_logged_in: 'You can change your email once logged in.',
    msg_ip_activated: 'Your Interested Party access is activated. Please log in.',
    nav_path: '/activate-interestedparty',
    nav_title: 'Interested Party Activation',
    text_learn_more() {
      return [
        'If you are a my529 Account Owner, log in to also become an Interested Party on an account.',
        `If you do not have a my529 account and would like to gain Interested Party access to an account, click ${this.btn_create} to sign up.`,
        'An Interested Party is a person who is granted permission by an Account Owner to have online, read-only access to view certain account information. The Interested Party cannot make changes to the account or initiate transactions.'
      ];
    },
    text_terms: 'By establishing interested party account access, I understand I am being allowed access to information that account owners may consider sensitive. As an interested party I can view, download, transmit to my third-party agent(s), and/or instruct my529 to transmit to my third-party agent: (1) the account number, (2) account owner and beneficiary names and contact information, (3) primary and secondary successor names, (4) transaction history, (5) investment options, (6) account balances, and (7) quarterly statements. I understand that this does not give me permission to make changes to the account. I understand that I can only access this information electronically. I understand that the account owner controls my access rights. I understand I do not have permission to make changes to the account. I also understand that if an account owner/agent is changed on an account that the interested party access to that account will be terminated.',
    text_toggle_password_vis: buttons.toggle_password,
  },
  InvestmentOptionStep: {
    btn_back: buttons.back,
    btn_cancel: buttons.cancel,
    btn_continue: buttons.continue,
    btn_create_ageBased: 'Create Age-Based',
    btn_create_static: 'Create Static',
    btn_details: 'Details',
    btn_edit_ageBased: 'Edit Age-Based',
    btn_edit_static: 'Edit Static',
    btn_ok: buttons.ok,
    btn_save: buttons.save,
    err_must_select_opt: 'Investment Option is required. Click each investment type to read about the options.',
    head_enrollmentdate_opts: 'Enrollment Date Option',
    head_customized_opts: 'Customized Options',
    head_investment_opt_disclosures: 'Investment Option Selection',
    head_investment_option: 'Investment Option',
    head_static_opts: 'Static Options',
    msg_cannot_select_opt: 'Investment plan is already in use and cannot be selected.',
    msg_investment_opt_saved: 'Investment option saved.',
    msg_total_allocations_ageBased: 'The Total Allocations for each age bracket must equal 100%.',
    msg_total_allocations_static: 'The Total Allocation must equal 100%.',
    text_about_investment_opts: 'my529 offers Target Enrollment Date, Static, and Customized investment options that use underlying funds managed by The Vanguard Group, Dimensional Fund Advisors, Pacific Investment Management Company (PIMCO), and FDIC-insured accounts held at Sallie Mae Bank and U.S. Bank.',
    text_customized_details1: 'Customized options can be age-based or static. Age-based option allocations change based on the age of the beneficiary. Rebalances and reallocations occur on the beneficiary’s birthday.',
    text_customized_details2: 'The Internal Revenue Service allows you to make two option changes in a calendar year without triggering tax consequences.',
    text_customized_review_performance: 'Review performance returns for customized funds.',
    text_customized_summary: 'You can build your own investment option by selecting from a menu of available funds offered by my529.',
    text_enrollmentdate_details1: 'Allocations in the portfolios automatically adjust over time based on a preset quarterly allocation schedule.',
    text_enrollmentdate_details2: 'The Internal Revenue Service allows you to make two option changes in a calendar year without triggering tax consequences.',
    text_enrollmentdate_details3: 'Review performance returns for the enrollment date option.',
    text_enrollmentdate_summary: 'Target Enrollment Date portfolios take into consideration the target year your beneficiary would enroll in school and begin taking withdrawals to pay for qualified education expenses. The portfolios gradually shift over time to a more conservative investment allocation as the target enrollment date draws near. Depending on your investment risk tolerance, a more aggressive or more conservative portfolio can be selected regardless of the beneficiary’s anticipated enrollment year.',
    text_options_on_mobile: 'Customized options on a mobile is not available. Please use a desktop instead.',
    text_static_details1: 'It is a good idea to review your static option periodically, especially if your investment objective changes over time.',
    text_static_details2: 'The Internal Revenue Service allows you to make two option changes in a calendar year without triggering tax consequences.',
    text_static_details3: 'Review performance returns for static options.',
    text_static_summary: 'In the Static options, money remains in the same investment allocation without changing over time. The target allocation remains the same until you decide to change to a different investment option.',
    text_disclosures(programDescription) {
      return [
        parse(`For complete details about the investment options, please see the <a target='_blank' rel='noopener noreferrer' href=${programDescription}>my529 Program Description</a>. You may choose only one investment option for each my529 account.`),
        'Please select your investment option carefully. The Internal Revenue Service (IRS) limits the number of investment option changes to two per calendar year for a beneficiary.',
        "All of your future contributions are invested in the investment option you select for your account. To change the investment of contributions in the future, you must instruct my529 to change your account's investment option.",
        'The Target Enrollment Date investment option has 12 portfolios with asset allocations that consider the target year the account beneficiary will begin taking withdrawals to pay for qualified education expenses. Over time, the portfolios gradually shift to a more conservative investment allocation as the target enrollment year draws closer. The Enrolled portfolio is the most conservative. The other portfolios are listed by year, with the latest Target Enrollment Date portfolio being the most aggressive. The account owner has the flexibility to select any portfolio based on their investment preference (more aggressive or more conservative), regardless of the beneficiary’s anticipated year of enrollment.',
        parse("Static options are available for a wide range of investment objectives. Please see <a target='_blank' rel='noopener noreferrer' href='https://my529.org/investment-options/'>Static Investment options</a> for specifics. Investments do not change unless you submit an investment option change."),
        "If you select a Customized Age-Based or Customized Static investment option, you can build your own option from a menu of investments offered by my529. Each investment you select for your customized investment option must have an allocation of at least 1 percent, using only whole percentages. If you have questions regarding how your particular financial situation and investment goals fit with your customized allocation, you should consult with your own financial advisor before choosing to invest in a customized investment option. Customized options will rebalance annually on the beneficiary's birthday (or next available business day) or advance the option to the next age bracket based on the beneficiary's age.",
        "Changing the investment allocation of a Customized Age-Based or Customized Static investment option is considered an investment option change.",
        "Some investment options include an allocation to the FDIC-insured accounts held in trust by my529 at Sallie Mae Bank and U.S. Bank. Money in the FDIC-insured accounts is insured by the FDIC on a pass-through basis to each account owner up to $250,000 at each bank. The amount of FDIC insurance provided to an account owner is based on the total of (1) the value of an account owner's investment in the FDIC-insured accounts at each bank plus (2) the value of other accounts (if any) owned by the account owner and held at each bank, as determined by the banks and by FDIC regulations.",
      ];
    },
    title_options_on_mobile: 'Customized Options on Mobile',
  },
  Login: {
    btn_create_account: 'Create Account',
    btn_fa_login_link: 'Log In As Financial Advisor',
    btn_login: 'Log In',
    err_password: errors.required,
    password_length: 'Password can have a maximum length of 40 characters',
    err_username: errors.required,
    username_length: 'Username can have a maximum length of 128 characters',
    lbl_password: 'Password',
    lbl_username: formLabels.username,
    msg_recaptcha_language: recaptchaLanguage,
    msg_you_have_logged_out: 'You have logged out.',
    nav_password: 'password',
    nav_username: 'username',
    nav_forgot_password: 'Forgot Password?',
    nav_forgot_username: 'Forgot Username?',
    nav_no_login: "Don't have a login?",
    nav_path: '/login',
    nav_setup_user: 'Setup username/password',
    nav_title: 'Login',
    btn_toggle_password: buttons.toggle_password,
  },
  Lpoa: {
    btn_learn_more: buttons.learn_more,
    btn_confirm: buttons.confirm,
    btn_cancel: buttons.cancel,
    btn_next: buttons.next,
    btn_agree: buttons.agree,
    btn_back: buttons.back,
    btn_edit: buttons.edit,
    btn_add_edit: `${buttons.add}/${buttons.edit}`,
    btn_add_edit_help_txt: "In order to add a financial advisor to you account(s), please select which account(s) you wish the grant limited power of attorney access to and then click 'Add/Edit'.",
    btn_add: buttons.add,
    btn_revoke: buttons.revoke,
    btn_unlink: buttons.unlink,
    checkbox_select_bene_alt_text: 'Select Beneficiary',
    text_yes: 'Yes',
    text_no: 'No',
    confirm_correct_lpoa_txt: 'Please confirm if this is the correct LPOA:',
    enter_auth_code_txt: "Grant your financial advisor limited power of attorney for any of your my529 accounts. Please contact your financial advisor to obtain your advisor's my529 LPOA Authorization Code and enter it.",
    terms_and_conditions_agree_button_label: 'I have read and understand these terms and conditions.',
    text_disabled: 'Inactive',
    checkbox_all_alt_text: 'Select all Beneficiaries',
    table_title: 'Beneficiaries',
    error_auth_code_empty: 'Please enter the authorization code',
    nav_path: '/lpoa',
    nav_title: 'Power of Attorney',
    column_labels: ['BENEFICIARY', 'POA LEVEL', '1099-Q', 'TC-675H'],
    learn_more_title: 'Authorization Code Entry',
    text_learn_more: "Complete the following steps to grant your financial advisor limited power of attorney for one or more of your my529 accounts. You must obtain your financial advisor's my529 LPOA Authorization Code in order to complete this request. Financial advisors may contact the my529 Professional Services Team at 888.529.1886 for information about obtaining limited power of attorney.",
    modal_title_revoke_benes: 'Please Confirm',
    modal_body_revoke_benes: 'Once revoked, the power of attorney cannot be reestablished without submitting a new Limited Power of Attorney Authorization request. Are you sure you want to revoke this power of attorney?',
    modal_title_unlink_lpoa: 'Please Confirm',
    authorization_code_title: 'Authorize Access',
    authorization_code_label: 'Authorization Code',
    errors: {
      missing_lpoa_level: 'Please select the authorization level.',
      missing_terms_checked: 'Please check the terms before you submit.',
    },
    success_lpoa_updated: 'Power of Attorney options saved.',
    edit_lpoa_step_labels: [
      'Level',
      'Review',
    ],
    edit_level_table: {
      levels: [
        'Level 1',
        'Level 2',
        'Level 3',
      ],
      btn_agree_label: 'Agree to Terms',
      title: 'Level Selection',
      lpoa_name_label: 'Authorized Representative',
      span_column_3_4_5_6_title: 'Intitate Transactions Online or with Applicable Form',
      column_titles: [
        'Select Authorization Level',
        'Obtain Account Information',
        'Automated Contributions',
        'Investment Option Changes',
        'Transfers Between Accounts',
        'Withdrawals',
      ],
      online_phone_text: 'Online/Phone',
    },
    edit_tax_docs: {
      label: 'Click to Disallow Federal or State Tax Forms',
    },
    level_3_info_bubble: 'Withdrawal checks may be payable only to the account owner, the beneficiary, or an eligible educational institution. my529 will not issue checks to other parties. Account owners can link a bank account to receive electronic deposits from my529 account withdrawals intitiated online by financial advisors.',
    fed_tax_docs_label: '1099-Q',
    state_tax_docs_label: 'TC-675H',
    fed_tax_docs_body() {
      return (
        <div>Check the box at left if you do <b>NOT</b> grant the LPOA entity/individual access to copies of your 1099-Q tax forms, which are generated and issued by my529, for the accounts you have granted the LPOA entity/individual authority over. This selection will remain in effect until this Limited Power of Attorney Authorization is either (1) revoked or (2) my529 receives, for the designated accounts, a Limited Power of Attorney Authorization form with a different selection in this box.</div>
      );
    },
    state_tax_docs_body() {
      return (
        <div><b>For Utah residents/taxpayers only:</b> Check the box at left if you do <b>NOT</b> grant the LPOA entity/individual access to copies of your TC-675H tax forms, which are generated and issued by my529. Please note that form TC-675H contains aggregated information about contributions, withdrawals, and transfers for ALL accounts you own (not just the accounts you have granted the LPOA entity/individual authority over). This selection will remain in effect until this Limited Power of Attorney Authorization is either (1) revoked or (2) my529 receives, for any account you own, a Limited Power of Attorney Authorization form with a different selection in this box.</div>
      );
    },
    review_title: 'Review',
    review_details(lpoaName, level, beneListFormatted, allowFedTax, allowStateTax) {
      return (
        <div>
          <p>You are about to grant a limited power of attorney (LPOA) to <b>{lpoaName}</b>.</p>
          <b>{lpoaName}</b> will have <b>Level {level}</b> authority over the following beneficiaries:
          <ul>{beneListFormatted}</ul>
          <p>You have chosen to <b>{allowFedTax}</b> <b>{lpoaName}</b> access to you federal tax documents regarding your my529 account.</p>
          <p>You have chosen to <b>{allowStateTax}</b> <b>{lpoaName}</b> access to you state tax documents regarding your my529 account.</p>
          <p>Granting limited power of attorney will set your quarterly statement and official communication delivery method to online for all beneficiary accounts included in this authorization.</p>
        </div>
      );
    },
    terms_and_conditions_title: 'Terms and Conditions',
    terms_and_conditions_body(lpoaName) {
      return (
        <div>
          <p>By granting this limited power of attorney, you are designating an entity/individual to act as your attorney-in-fact, agent, and authorized representative to act in your name and on your behalf for the limited purposes that are specified therein. However, you will continue to control the account(s) and may perform any of the actions you grant the entity/individual permission to perform.</p>
          <p>You may also revoke any limited power of attorney on file with my529 and grant a new entity a limited power of attorney for the same accounts through this online process.</p>
          <p>Your limited power of attorney will continue in effect until it is revoked or terminated as specified below.</p>
          <p>The authority granted by you under this limited power of attorney may not be assigned or delegated by the authorized entity/individual to another entity/individual, but an entity may designate the person(s) within the entity to assist in the performance of this authority.</p>
          <p>When a limited power of attorney is granted to an entity, the entity can transmit to its third-party agent(s), and/or instruct my529 to transmit to the entity&#39;s third-party agent(s), your financial data, including the following: (1) the account number; (2) account owner and beneficiary names and contact information; (3) primary and secondary successor names; (4) transaction history; (5) investment options; (6) account balances; and (7) quarterly statements.</p>

          The LPOA entity/individual may not change, add, or delete the following account information:
          <ul>
            <li>Account owner/agent</li>
            <li>Beneficiary</li>
            <li>Primary and/or secondary successors</li>
            <li>Addresses of the owner, beneficiary, or successors</li>
          </ul>

          The LPOA entity/individual may not take any of the following actions:
          <ul>
            <li>Open a new my529 account on your behalf</li>
            <li>Transfer funds to my529 accounts for which you are not the account owner</li>
            <li>Roll over account funds to another 529 plan</li>
          </ul>

          <p>Withdrawals may only be sent to the account owner, the beneficiary, or an eligible educational institution. my529 will not issue withdrawals to an LPOA entity or other parties.</p>

          By executing this limited power of attorney, I understand, agree, and certify that:
          <ul>
            <li>I have the power and authority to execute this limited power of attorney.</li>
            <li>I am appointing <b>{lpoaName}</b> to act as my attorney-in-fact for my my529 accounts selected above and under the terms specified herein.</li>
            <li>By granting this limited power of attorney I expressly revoke any earlier limited power of attorney that I have on file with my529 for the same my529 accounts covered by this authorization.</li>
            <li>This limited power of attorney does not impose a duty on the entity/individual to exercise the granted authority. However, when such authority is exercised, the entity/individual will be responsible for any resulting consequences of such actions and will be responsible for maintaining records relating to such actions.</li>
            <li>If I grant Level 2 or Level 3 limited power of attorney authorization, I understand that the entity will be able to add appropriate bank account information and initiate transactions as authorized above between my account(s) at my529 and the bank. I authorize the bank to accept any such credit or debit to my account without responsibility for its correctness.</li>
            <li>I will indemnify and hold harmless my529, the State of Utah, the Utah State Board of Regents, the Utah Higher Education Assistance Authority, and their officials, employees, and agents, from and against any and all liability, claims, suits, losses, costs, and legal fees caused by, arising out of, or resulting from acting upon instructions believed by any of them to have originated from the entity to whom I grant my limited power of attorney, and from all acts of the entity/individual covered by this limited power of attorney authorization.</li>
          </ul>

          This limited power of attorney will remain in effect for my selected my529 beneficiaries until it is revoked or terminated by any of the following actions:
          <ul>
            <li>my529 receives a new Limited Power of Attorney Authorization form (form 710/810) for the selected account(s).</li>
            <li>my529 receives an Entity/Individual Limited Power of Attorney Revocation form (form 720/820) or the limited power of attorney authorization is revoked online at my529.org.</li>
            <li>The account owner/agent is changed.</li>
            <li>The account type is changed.</li>
            <li>my529 receives written notification of my death.</li>
            <li>All accounts for the same account owner/agent and beneficiary combination become closed.</li>
          </ul>

          <p>In addition, this limited power of attorney will also remain in effect for my selected my529 beneficiaries in the event of a merger or sale of my designated LPOA entity. When a merger or sale of my designated LPOA entity occurs, I must affirmatively notify my529 of my desire to revoke or terminate this limited power of attorney on behalf of the new entity.</p>
          <p>If I become disabled, incapacitated, or incompetent after signing this form, this limited power of attorney will continue in effect until it is revoked or terminated.</p>
          <p>Revocation or termination of the limited power of attorney due to my death, court determination, or any other reason will not take effect until my529 receives written notification of the revocation or termination and has had a reasonable amount of time to act on such notice.</p>
          <p>my529 cannot and does not provide legal, financial, or tax advice. Accordingly, you should consult your legal advisor if you have any questions concerning the consequences of granting this limited power of attorney authorization.</p>
        </div>
      );
    },
  },
  LpoaAccount: {
    nav_path: '/poaaccountapplication',
    nav_title: 'POA Account Application',
    try_again_rsa_txt: 'Authentication failure. Please try again.',
    reject_modal_confirmation: 'Are you sure you wish to reject this account application? Rejecting the account application will end this account application session and this account will not be created at my529.',
    reject_modal_title: 'Reject Account Application',
    btn_next: buttons.next,
    btn_reject: buttons.reject,
    btn_print: buttons.print,
    btn_back: buttons.back,
    btn_submit: buttons.submit,
    btn_done: buttons.done,
    btn_learn_more: buttons.learn_more,
    btn_login: 'Login',
    btn_setup_login: 'Setup Login',
    success_lpoa_account_rejected: 'POA account successfully rejected.',
    success_lpoa_account_approved: 'New account successfully created.',
    confirmation_page: {
      your_account_number: 'Your account number',
      first_paragraph: 'Please keep this account number for your records. You and your financial advisor will receive a confirmation email confirming your new account has been established.',
      second_paragraph_setup(setupButtonLabel, supportNumber) {
        return `By clicking ${setupButtonLabel}, you will be prompted to establish your online access. You will need your social security number and date of birth. Should you have any questions, please contact my529 by calling ${supportNumber} or emailing info@my529.org.`;
      },
      second_paragraph_login(loginButtonlabel, supportNumber) {
        return `By clicking ${loginButtonlabel}, you will be prompted to establish your online access. Should you have any questions, please contact my529 by calling ${supportNumber} or emailing info@my529.org.`;
      }
    },
    account_edit: {
      lpoa_review_title: 'Account Application Review',
      error_lpoa_account_validate: 'There are some form errors. Please scroll up to fix them.',
      warning_text_above_buttons: 'By clicking NEXT, you certify that you have reviewed all information above and that it is correct.  Should you find an error, please update the information, or click REJECT and contact your financial advisor.',
      account_owner: 'Account Owner',
      mailing_adddress: 'Mailing Address',
      physical_address: 'Physical Address',
      contact: 'Contact',
      beneficiary: 'Beneficiary',
      succesor: 'Successor Account Owners',
      primary: 'Primary',
      secondary: 'Secondary',
      account_summary: 'Account Summary',
      fund_allocations_title1: 'Fund Allocations',
      fund_allocations_title2: 'Fees',
    },
    fund_allocations_and_fees: {
      select_age_band_title: 'Select Age Band to see Funds and Fees',
      age_band_dropdown_label: 'Select Age Band',
      est_expense_ratio_label: 'Est. Underlying Fund Expense',
      my529_admin_asset_fee_label: 'my529 Adminstrative Asset Fee',
      ext_total_annual_asset_based_fee_label: 'Est. Total Annual Asset-Based Fee',
      fees_info_icon_content(programDescriptionLink) {
        return (
          <div>
            The fees at my529 vary depending on your specific investment option. Please note that the underlying fund expense for Enrollment Date options will vary slightly as allocations shift over time. For more information on my529’s fees, read the <a href={programDescriptionLink} target='_blank' rel='noopener noreferrer'>Program Description</a>. {/* eslint-disable-line */}
          </div>
        );
      },
    },
    account_summary: {
      application_date_label: 'Application Date',
      type_label: 'Type',
      option_label: 'Option',
      poa_level_label: 'POA Level',
      poa_levels: [
        'Level 1',
        'Level 2',
        'Level 3',
      ],
      fed_tax_docs_label: 'Federal Tax Docs',
      state_tax_docs_label: 'State Tax Docs',
      status_label: 'Status',
      fed_tax_docs_info_icon_checked: 'Your designated limited power of attorney (LPOA) advisor will have access to copies of your 1099-Q tax forms, which are generated and issued by my529, for the accounts you have granted the LPOA entity authority over. This selection will remain in effect until this Limited Power of Attorney Authorization is either (1) revoked or (2) my529 receives for the designated account a Limited Power of Attorney Authorization form with a different selection.',
      fed_tax_docs_info_icon_unchecked: 'Your designated LPOA advisor will not have access to copies of your 1099-Q tax forms.',
      state_tax_docs_info_icon_checked: 'For Utah residents/taxpayers only: Your designated LPOA advisor will have access to copies of your TC-675H tax forms, which are generated and issued by my529. Please note that form TC-675H contains aggregated information about contributions, withdrawals, and transfers for ALL accounts you own (not just the accounts you have granted the LPOA authority over). This selection will remain in effect until this Limited Power of Attorney Authorization is either (1) revoked or (2) my529 receives for the designated account a Limited Power of Attorney Authorization form with a different selection.',
      state_tax_docs_info_icon_unchecked: 'For Utah residents/taxpayers only: Your designated LPOA advisor will not have access to copies of your TC-675H tax forms.',
      promo_code_label: 'Promotion Code',
    },
    ao_contact: {
      relationship_label: 'Relationship to Beneficiary',
      email_label: 'Email',
      home_phone_label: 'Home Phone',
      work_phone_label: 'Work Phone',
      other_phone_label: 'Other Phone',
    },
    first_terms_and_conditions: {
      title: 'TERMS AND CONDITIONS OF my529 ACCOUNT AGREEMENT',
      content() {
        return (
          <div>
            <p>
              This Account Agreement is entered into by and between my529 and the Account Owner/Agent effective the date all application materials and information have been completed and submitted to my529.org. and the Account Owner/Agent clicks the appropriate button, indicating his or her agreement with the terms herein. All capitalized terms used herein shall have the meanings used in the Program Description.
            </p>
            <ol>
              <li>
                This Account Agreement is subject to, and incorporates by reference, the policies* adopted by the Utah Board of Higher Education acting in its capacity as the Utah Education Savings Board of Trustees and statutes governing my529 codified as UCA 53B-8a. Any amendments to statutes and regulations shall automatically amend this Agreement, and any changes to the policies, including investment allocations and fees, shall amend this Agreement after adoption by the Utah Board of Higher Education.
              </li>
              <li>
                This Account Agreement, the Program Description, future updates to the Program Description, and the policies adopted by the Utah Board of Higher Education constitute the entire Agreement between you and my529. No person is authorized to make oral or written modifications to this Agreement.
              </li>
              <li>
                This Account Agreement shall be governed by and construed in accordance with the laws of the state of Utah without regard to any conflict of law principles. Any action or proceeding arising from or relating to this Account Agreement shall be brought in state or federal court in the state of Utah, venue shall be in Salt Lake County.
              </li>
              <li>
                There may be both federal and state tax consequences to opening or contributing to a my529 account. Advice should be sought from a qualified tax advisor. my529 does not provide legal, financial, or tax advice. Accordingly, you should consult your own tax or financial advisor concerning your particular tax or financial situation or if you have any questions concerning opening an account.
              </li>
              <li>
                If you are not a Utah taxpayer or resident, you should consider whether the state in which you or the beneficiary resides or pays taxes offers a 529 program. That program may offer state tax or other benefits to its taxpayers or residents that may not be available through my529.
              </li>
              <li>
                If my529 determines that there has been any material misrepresentation related to this Account Agreement or an account, the account may be cancelled and the account money returned to you, less any applicable fees and penalties.
              </li>
            </ol>

            <p>
              *For copies of the relevant Utah Board of Higher Education policies, call my529 toll-free at 800.418.2551 or email info@my529.org.
            </p>

            <p>
              By signing below, I hereby apply for an individual account with my529. I certify that:
            </p>
            <ol>
              <li>
                I am opening the individual account to provide for the qualified higher education expenses of the beneficiary identified in section 3. Each contribution to my account will be for that purpose, and I will not make any contributions in excess of limitations established by my529.
              </li>
              <li>
                I am at least age 18, and have a valid U.S. Social Security or Taxpayer Identification Number.
              </li>
              <li>
                I understand that my529 may collect nonpublic personal information about me from third parties to verify my identity or prevent fraud. This personal information may include, among other things, my name and address, U.S. Social Security or Taxpayer Identification Number, and date of birth.
              </li>
              <li>
                I have the power and authority to sign and open an account with my529.
              </li>
              <li>
                I have received, read, understand, and agree to all the terms and conditions in the Program Description and this Account Agreement and will retain a copy of this Account Agreement for my records.
              </li>
              <li>
                The information I have provided on this form—and all future information I will provide with respect to my account—is true, complete, and correct.
              </li>
              <li>
                I understand the account will be opened based on the information I provide.
              </li>
              <li>
                I understand the value of my account may vary depending on market conditions and the performance of the investment option I select. I understand that the account balance may be more or less than the total amount contributed to the account. I understand opening an account involves investment risk, including the possible loss of principal.
              </li>
              <li>
                I understand my investments are not insured or guaranteed by the State of Utah, my529, the Utah Board of Higher Education, the Utah Education Savings Board of Trustees, or any other state or federal government agency (except to the extent noted below regarding FDIC insurance), or any of the employees or directors of any such entities. I understand investments in the FDIC-insured accounts are insured by the FDIC up to the maximum amount set by federal law. Insurance coverage is based on the total of my investments in the FDIC-insured accounts at each of Sallie Mae Bank and U.S. Bank (the Banks) plus other accounts I hold at each of the Banks. It is my responsibility to determine how my my529 investments would be aggregated with other accounts at each Bank for purposes of the FDIC insurance.
              </li>
              <li>
                I have looked into the 529 plan(s) in the state in which I pay taxes, as well as the state in which my beneficiary pays taxes, to see if they offer me tax or other benefits not offered by my529.
              </li>
            </ol>

            I have read, understand, and agree to the Terms and Conditions of the my529 Account Agreement. By clicking, Accept Terms, I hereby confirm my intent to enter into a contract with my529 to establish an account. I have read, understand, and retained a copy of the Program Description, and will retain a copy of this document for my records.
          </div>
        );
      },
    },
    second_terms_and_conditions: {
      title: 'TERMS AND CONDITIONS FOR LIMITED POWER OF ATTORNEY AUTHORIZATION',
      content() {
        return (
          <div>
            <p>
              By granting this limited power of attorney, you are designating an entity to act as your attorney-in-fact, agent, and authorized representative to act in your name and on your behalf for the limited purposes that are specified therein. However, you will continue to control the account(s) and may perform any of the actions you grant the entity/individuals permission to perform.
            </p>

            <p>
              You may also revoke any limited power of attorney authority on file with my529 and grant a new entity limited power of attorney authority for the same accounts through this online process.
            </p>

            <p>
              The authority granted by you under this limited power of attorney agreement may not be assigned or delegated by the authorized entity to another entity, but an entity may designate the person(s) within the entity to assist in the performance of this authority.
            </p>

            <p>
              When a limited power of attorney authority is granted to an entity, the entity can transmit to its third-party agents(s), and/or instruct my529 to transmit to the entity’s third-party agent(s), your financial data including the following: (1) the account number, (2)account owner and beneficiary names and contact information, (3) primary and secondary successor names, (4) transaction history, (5) investment options, (6) account balances, (7) quarterly statement, (8) Federal Tax Form 1099-Q and (9) Utah State Tax Form TC-675H.
            </p>

            <p>
              The limited power of attorney entity/individual may not change, add, or delete the following account information:
            </p>
            <ul>
              <li>
                Account owner/agent
              </li>
              <li>
                Beneficiary
              </li>
              <li>
                Primary and/or secondary successors
              </li>
              <li>
                Addresses of the owner, beneficiary, or successors
              </li>
            </ul>

            <p>
              The limited power of attorney entity/individual may not take any of the following actions:
            </p>
            <ul>
              <li>
                Transfer funds to my529 accounts for which you are not the account owner
              </li>
              <li>
                Roll over account funds to another 529 plan
              </li>
              <li>
                Open a new my529 account on your behalf
              </li>
            </ul>

            <p>
              Disbursements may be sent only to account owner, beneficiary, or an eligible educational institution, my529 will not issue disbursements to a limited power of attorney entity or other parties.
            </p>

            <p>
              By executing this limited power of attorney authorization, I understand, agree, and certify that:
            </p>
            <ul>
              <li>
                I have the power and authority to execute this limited power of attorney authorization.
              </li>
              <li>
                I am allowing the entity named in section 2 limited power of attorney authority for my my529 accounts selected in section 3, as described in section 4.
              </li>
              <li>
                The filing of this limited power of attorney automatically revokes any earlier limited power of attorney authorization on file with my529 for the same my529 accounts covered by this form.
              </li>
              <li>
                This limited power of attorney authorization does not impose a duty on the entity to exercise the granted authority; however, when such authority is exercised, the entity will be responsible for any resulting consequences of such actions and will be responsible for maintaining records relating to such actions.
              </li>
              <li>
                If I grant Level 2 or Level 3 limited power of attorney authorization, I understand that the entity will be able to add appropriate bank account information and initiate transactions as authorized above between my account(s) at my529 and the bank. I authorize the bank to accept any such credit or debit to my account without responsibility for its correctness.
              </li>
              <li>
                I will indemnify and hold harmless my529, the State of Utah, the Utah Board of Higher Education, and the Utah Education Savings Board of Trustees and their officials, employees, and agents from and against any and all liability, claims, suits, losses, costs, and legal fees caused by, arising out of, or resulting from acting upon instructions believed by any of them to have originated from the entity to whom I grant limited power of attorney authority, and from all acts of the entity covered by this limited power of attorney authorization.
              </li>
              <li>
                I understand that if I have listed the address of the entity to whom I am granting limited power of attorney authorization as my mailing and/or physical address, I may no longer receive certain account information directly from my529 because communications from my529 may be sent to that address.
              </li>
              <li>
                This limited power of attorney authorization will remain in effect for my selected my529 account(s) until it is revoked or term initiated by any of the following actions:
                <ul>
                  <li>
                    The account group becomes closed (i.e., there are no open accounts for the same owner/beneficiary combination for whom the LPOA has been established)
                  </li>
                  <li>
                    my529 receives a new Limited Power of Attorney Authorization form (Form 710 or 810) for account(s) selected in section 3 of this form
                  </li>
                  <li>
                    my529 receives an Entity Limited Power of Attorney Revocation form (Form 720) or the limited power of attorney authorization is revoked online at my529.org
                  </li>
                  <li>
                    The account owner/agent is changed
                  </li>
                  <li>
                    The account type is changed
                  </li>
                  <li>
                    my529 receives written notification of my death
                  </li>
                  <li>
                    In addition, this limited power of attorney will also remain in effect for my selected my529 beneficiaries in the event of a name change, merger, or sale of my designated LPOA entity. When a merger or sale of my designated LPOA entity occurs, I must affirmatively notify my529 of my desire to revoke or terminate this limited power of attorney on behalf of the new entity.
                  </li>
                  <li>
                    If I become disabled, incapacitated, or incompetent after signing this form, this limited power of attorney authorization will continue in effect until it is revoked or terminated.
                  </li>
                  <li>
                    Revocation or termination of the power of attorney due to my death, court determination, or any other reason is not effective as to my529 until my529 receives written notification of the revocation or termination and has had a reasonable amount of time to act on such notice.
                  </li>
                </ul>
              </li>
            </ul>

            <p>
              I have read and understand these terms and conditions.
            </p>

            <p>
              By clicking Accept Terms, I hereby confirm my intent to enter into a contract with my529 to grant limited power of attorney authorization. I have read and understood the Terms and Conditions, and I have retained a copy of the Program Description for my records.
            </p>
          </div>
        );
      },
      accept_terms_checkbox_label: 'Accept Terms',
    },
  },
  MyInfo: {
    head_title: 'Settings',
    msg_unsaved_changes: unsaved_changes,
    nav_path: '/settings',
    nav_title: 'Settings',
    label_paperless: 'Go Paperless',
    msg_paperless: 'Adjust your settings to go paperless today'
  },
  Multifactor: {
    btn_another_method: 'use another method',
    btn_calling: 'calling...',
    btn_call_again: 'call me again',
    btn_change_dob: 'change date of birth',
    btn_cancel: buttons.cancel,
    btn_confirm_dob: 'confirm date of birth',
    btn_continue_aa: 'continue to account access',
    btn_done: buttons.done,
    btn_edit: buttons.edit,
    btn_enter_token: 'enter a security code',
    btn_get_started: 'get started',
    btn_learn_more: buttons.learn_more,
    btn_logout: 'log out',
    btn_ok: buttons.ok,
    btn_register: buttons.register,
    btn_request_code: 'request code',
    btn_resend_code: 'resend code',
    btn_save: buttons.save,
    btn_sec_code: 'Security Code',
    btn_sending: 'sending...',
    btn_send_newpush: 'send new push',
    btn_submit_answer: 'submit answer',
    btn_submit_code: 'submit code',
    btn_submit_questions: 'submit questions',
    btn_submit_token: 'submit security code',
    btn_try_again: 'try again later',
    btn_use_token: 'use authenticator',
    btn_use_phone: 'use text or phone call',
    btn_verify_dob: 'verify date of birth',
    btn_verify_identity: 'verify identity',
    err_code_required: 'Security code is required',
    err_required: errors.required,
    err_options: errors.select_option,
    err_incorrect_answer: 'Answer is incorrect',
    err_invalid_token: 'Security code is invalid',
    err_token_required: 'Security code is required',
    err_phone_number_too_short: errors.phone_number_too_short,
    err_verification_code_required: 'Security code is required',
    lbl_ao_dob: 'Account Owner/Agent Birthdate',
    lbl_ao_name: 'Account Owner/Agent Name',
    lbl_call: 'Call Me',
    lbl_invalid_code: 'Security code is invalid',
    lbl_other: 'Authenticator',
    lbl_question: 'Question',
    lbl_my529_token: 'Security Code',
    lbl_other_options: 'Authenticator Option',
    lbl_present_2FA: 'Present 2-factor at login',
    lbl_phone_number: 'Phone Number',
    lbl_remember_device: 'Remember this device',
    lbl_registered: 'Registered',
    lbl_step1: 'Step 1:',
    lbl_step2: 'Step 2:',
    lbl_step3: 'Step 3:',
    lbl_text: 'Text Me',
    lbl_token: 'Security Code',
    lbl_verification_code: 'Security Code',
    msg_apology: 'We apologize for the inconvenience, but there is a discrepancy.',
    msg_authenticator_download: 'To receive a security code (token), install an authenticator app such as Google Authenticator or Microsoft Authenticator on your phone. Download an app from your phone\'s app store.',
    msg_attempts_remaining: 'Warning: You have one more attempt to validate your security code before you will be logged out of the website. Security code is invalid',
    msg_token_attempts_remaining: 'Warning: You have one more attempt to validate your security code before you will be logged out of the website. Security code is invalid',
    msg_2FA: 'my529 requires two factor authentication',
    msg_2FA_explanation: 'Two Factor Authentication (2FA) adds a layer of protection to your account. It combines something you know, such as a username and password, with something you have, such as a device through which you can access a security code.',
    msg_2FA_phone: 'Please enter the 10 digit US phone number you would like to use to register for 2FA.',
    msg_2FA_reg_method: 'Choose a registration method you would like to use. You have the option to change this method in your account settings after logging in.',
    msg_2FA_unavailable: 'Two factor authentication is currently unavailable',
    msg_2FA_down_apology: 'We apologize for any inconvenience. Please try again later.',
    msg_congratulations: 'Congratulations!',
    msg_discrepancy: 'We apologize for the inconvenience, but there is a discrepancy',
    msg_enter_authenticator: 'Enter a security code from your authenticator app',
    msg_enter_token: 'Enter security code',
    msg_get_codes: 'Get code from an authenticator app',
    msg_download_authenticator: 'Download autheticator app',
    msg_maintain_security: 'To maintain the security of your account, we need to verify your identity.',
    msg_protection: 'For your protection, you must answer questions to verify your identity and prevent fraudulent activity on your my529 account.',
    msg_not_verified: 'The computer you are using is not registered with the my529. Verify your identity by answering the following question.',
    msg_dob_select: 'Please select your birth date below. my529 may use a third party to verify your identity.',
    msg_enter_seccode: 'Enter the my529 security code you see in your authenticator app',
    msg_later_prompt: 'When you are later prompted to use 2FA you will be required to enter a security code, or an authenticator app generated security code.',
    msg_locked_out: 'For security purposes, you have been locked out of your account for 30 minutes.',
    msg_scan_qrcode: 'Scan the QR image or enter the code below the image into your authenticator app',
    msg_successful: 'You have successfully registered for two factor authentication',
    msg_resend_successful: 'Resent successfully!',
    msg_rsa_select: 'Please select an answer for each question and click Submit Answers. You must answer all the questions.',
    msg_security_code(fourDigits) { return `We sent a security code to (***) ***-${fourDigits}`; },
    msg_we_sent(fourDigits) { return `We sent a security code to (***) ***-${fourDigits}`; },
    msg_we_called(fourDigits) { return `We called (***) ***-${fourDigits} with a verification code`; },
    msg_send_security_code(fourDigits) { return `Send a security code to (***) ***-${fourDigits}`; },
    msg_unable_verify: 'You are unable to verify your identity at this time. Please try again after 24 hours.',
    authenticator_path: '/multifactor/register/authenticator-method',
    confirmation_path: '/multifactor/confirmation',
    nav_path: '/multifactor',
    nav_title: 'Two Factor Authentication',
    phone_path: '/multifactor/phone-method',
    preferred_path: '/multifactor/preferred-method',
    register_path: '/multifactor/register',
    register_title: 'Register for Two Factor Authentication',
    register_phonenumber_label: 'Enter Phone Number',
    verify_identity_path: '/multifactor/verify-identity',
    verify_identity_title: 'Verify Your Identity',
    learn_more_url: 'https://my529.org/faq-two-factor-authentication/',
  },
  Navigation: {
    menu_english: 'English',
    menu_spanish: 'Español',
    menu_log_out: 'Log out',
    menu_profile: 'Profile',
    text_version: 'Version',
  },
  Notifications: {
    text_no_notifications: 'You currently have no notifications',
  },
  OptionChanges: {
    NewOptions: {
      msg_unsaved_changes: unsaved_changes,
      step_labels: ['Option Category', 'Select Option', 'Terms & Conditions', 'Print Review'],
      customized_agebased_option_selection_name: 'Customized Age-Based Option Change',
      customized_static_option_selection_name: 'Customized Static Option Change',
      errors: {
        empty_category_selection: 'Option category should not be empty.',
        empty_option_selection: 'Selected Option should not be empty.',
        duplicate_option_selection: 'No change has been made.',
        agree_to_terms: 'You have to agree to terms.',
        total_allocations_for_each_bracket_100_percent: 'The Total Allocations for each age bracket must equal 100%.',
        total_allocations_100_percent: 'The Total Allocation must equal 100%.',
      },
      success_message: 'Option changed successfully',
      btn_ok: buttons.ok,
      title_options_on_mobile: 'Customized Options on Mobile',
      text_options_on_mobile: 'Customized Option change on a mobile is not available. Please use a desktop instead.',

    },
  },
  OptionDetails: {
    head_investment_option(type) { return <React.Fragment>Investment Option: <a href='https://my529.org/investment-options/' target='_blank' rel='noopener noreferrer'>{type}</a></React.Fragment>; },
    head_remaining_option_changes: 'Remaining Option Changes',
    nav_path_investment: 'investment-path',
    text_custom: 'Custom',
    text_enrollment_date: 'Enrollment Date',
    text_static: 'Static',
    title_portfolio: 'Portfolio',
    title_nav: 'NAV',
    title_see_more_details: 'See More Details',
    title_symbol: 'Symbol',
    title_total: 'Total',
    title_units: 'Units',
  },
  Overview: {
    err_date_past_today: 'Date cannot be past today',
    err_start_after_end: 'Start Date must be before End Date',
    err_end_before_start: 'End Date must be after Start Date',
    lbl_bene_account(multipleSelected) { return multipleSelected ? 'Beneficiary Accounts' : 'Beneficiary Account'; },
    lbl_end_date: 'End Date',
    lbl_filter_1month: '1 Month',
    lbl_filter_3month: '3 Month',
    lbl_filter_6month: '6 Month',
    lbl_filter_YTD: 'YTD',
    lbl_filter_1year: '1 Year',
    lbl_filter_all: 'All',
    lbl_filter_custom: 'Custom',
    lbl_start_date: 'Start Date',
    lbl_savings_calculator: 'Savings Calculator',
    lbl_total: 'Total:',
    text_all_selected: 'All Accounts Selected',
    text_NA: NA,
    text_no_open_accounts: 'There are no open accounts. Please open an account to view this section.',
    text_oops: 'Oops!',
    text_see_more: 'To see more data, please make a contribution to your account.',
    text_select_all: 'Select All Accounts',
    text_something_wrong: 'Something went wrong. Please try again later.',
    title_close_fullscreen: 'Close Fullscreen View',
    title_open_fullscreen: 'Open Graph to Fullscreen'
  },
  ProtectedRoute: {
    msg_logged_out_inactivity: 'Your session has expired. You have been automatically logged out.',
    msg_logged_out_inactivity_warning(time) { return `Your session will expire in ${time}. If you wish to continue click ${buttons.close}`; },
    title_logged_out_inactivity_warning: 'Inactivity Warning',
    btn_close: buttons.close,
  },
  PrrCalculator: {
    learn_more_title: 'Personal Rate of Return Footer/Disclosure:',
    prr_not_available: 'Not Available',
    text_learn_more() {
      return (
        <div>
          <p><b>Performance returns for time periods longer than 365 days have been annualized.</b></p>

          <p>The Personal Rate of Return produced by this calculator (Personal Rate of Return) is a dollar-weighted calculation methodology (also known as Internal Rate of Return) used to measure the actual performance of an account, including the impact of the timing and dollar amount of deposits and withdrawals (i.e., cash flows). The formula used by my529 considers any activity in the account, including cash flows, dividends, interest and fees. It can be used to answer the fundamental question of how an investment is performing.</p>

          <p>The Personal Rate of Return should not be confused with performance returns posted by my529 for its investment options or returns for underlying funds. The performance returns posted for my529&apos;s investment options and for the underlying funds use a time-weighted calculation that normalizes returns by not considering cash flows. Performance reported for my529 investment options may differ significantly from the Personal Rate of Return of an account due to the unique transaction activity that occurred within the account.</p>

          <p>Year-to-date (YTD) performance returns are calculated from either the beginning of the calendar year or the opening date of the account, if the account was opened after the beginning of the year. The earliest date that may be selected is the account opening date, or January 1, 2005, whichever is later. If an account was not funded on the opening date, the calculation will use the funding date as the beginning date. Performance may be calculated up to the previous trading date.</p>

          <p>The Personal Rate of Return should not be used in making investment decisions. It is provided by my529 as a courtesy for informational purposes only with respect to the unique transaction activity that occurred within your account. You should consider other factors like your time horizon, risk tolerance, investment objectives, personal financial situation, and tax implications before making changes to your investment option.</p>

          <p><b>Past performance is not a guarantee of future performance.</b></p>
        </div>
      );
    },
    text_faq: [
      {
        question: 'How was my Personal Rate of Return calculated?',
        answer: 'Your Personal Rate of Return was determined using a dollar-weighted rate of return calculation. This calculation is based on a number of factors, including changes in the value of the my529 units you own, dividends and interest earned, fees paid and the dollar amount and timing of your contribution(s) and/or withdrawal(s). This type of calculation is also sometimes referred to as an Internal Rate of Return.',
      },
      {
        question: 'What is the difference between dollar-weighted and time-weighted rates of return?',
        answer: (
          <Fragment>
            A dollar-weighted rate of return is used to evaluate the combined investment decisions of an investment manager and an individual investor. The dollar-weighted Personal Rate of Return measures the performance of the underlying investments, including dividends, interest and fees, and also considers the impact of the relative dollar amount and timing of the contribution(s) and withdrawal(s) that an account owner makes.
            <br /><br />
            In contrast, a time-weighted rate of return is commonly used to evaluate the performance of a fund or an investment manager. This rate of return measures the performance of the underlying investments, including dividends, interest and fees, but seeks to exclude or minimize the impact of the dollar amount and timing of contributions and withdrawals. The exclusion of cash flows normalizes the returns to allow for easier comparison of the investment manager&apos;s performance and other investment options.
            <br /><br />
            Investment Option Performance published on my529.org is calculated using a time-weighted rate of return, whereas the account specific Personal Rates of Return within Account Access are calculated using a personal (dollar-weighted) rate of return.
          </Fragment>
        ),
      },
      {
        question: 'How will withdrawals affect my performance?',
        answer: 'Withdrawals are not treated as a decline in your return, but will decrease the investment option value used to calculate your return. The dollar-weighted methodology, or Personal Rate of Return, takes into account the timing and size of contributions and withdrawals.',
      },
      {
        question: 'What time frame can I select for the Personal Rate of Return?',
        answer: 'The Personal Rate of Return can be calculated from any time period beginning as far back as January 1, 2005, or account opening (whichever is later) and ending as recently as the previous trading day. Account performance data is calculated nightly.',
      },
      {
        question: 'What does it mean when the Personal Rate of Return for time periods longer than 365 days has been annualized?',
        answer: 'A Personal Rate of Return for any time period longer than 365 days has been annualized, or calculated as the equivalent annual return an investment received over the selected period. Returns for a time period less than 365 days, on the other hand, have not been annualized to prevent any projection of performance.',
      },
    ],
    title: 'Personal Rate of Return',
    subtitle: 'Calculate your personal rate of return.',
    rate_of_return_txt: 'Rate of return',
    start_date_label: 'Start Date',
    end_date_label: 'End Date',
    btn_calculate: 'Calculate',
    btn_learnmore: 'Learn More',
  },
  PageNotFound: {
    btn_back_to_home: 'Back to Home',
    nav_title: 'Page Not Found',
    text_oops: "Oops! Looks like you reached a page that doesn't exist.", // eslint-disable-line
  },
  PasswordCard: {
    btn_change_password: 'Change Password',
    btn_toggle_password: buttons.toggle_password,
    err_confirm_password: 'Confirmed password does not match above password.',
    err_password_criteria: errors.password_criteria,
    err_required: errors.required,
    head_title: 'Change Password',
    lbl_confirm_password: 'Confirm New Password',
    lbl_current_password: current_password,
    lbl_new_password: 'New Password',
    msg_password_updated: 'Password changed.',
  },
  Payments: {
    access_card_url_text: 'my529AccessCard.com',
    btn_add_bank: 'Add Bank',
    btn_apply_now: 'Apply Now',
    btn_deposits_received: 'Deposits Received',
    btn_learn_more: 'Learn More',
    btn_ok: buttons.ok,
    btn_remove: 'Remove',
    btn_renew: 'Renew',
    btn_validate: 'Validate',
    head_pending_ach: 'Pending Electronic Funds Transfer Transactions',
    head_remove_account: 'Remove Bank Account',
    head_renew_account: 'Renew Bank Account',
    msg_removed: 'Account removed.',
    msg_renewed: 'Account renewed.',
    msg_poa_approved: 'LPOA is now approved.',
    msg_poa_not_approved: 'LPOA is now not approved.',
    nav_path: '/payments',
    nav_title: 'Payments',
    text_account_validated: 'Validated',
    text_account_not_validated: 'Not Validated',
    text_access_card_reqs: [
      'An open my529 Individual or Institutional account',
      'An account beneficiary who is at least 17 years old',
      'A domestic (U.S.) address for the account owner',
    ],
    text_attempts_exceeded: 'Validation Attempts Exceeded',
    text_confirm_remove(maskedBankAccountNumber) { return `Are you sure you want to remove bank account ${maskedBankAccountNumber}?`; },
    text_confirm_renew(maskedBankAccountNumber) { return `You have selected to keep bank account ${maskedBankAccountNumber} active for future use with your my529 account(s). Is this correct?`; },
    text_expiration_warning(expirationDate) { return `Please click the Renew link to confirm that you want to keep the bank account active, otherwise it will be removed on ${expirationDate} and will no longer be available for use with your my529 account(s).`; },
    text_has_pending_transactions: 'This bank account is being used for scheduled electronic funds transfer contributions or withdrawals. Please cancel all contribution or withdrawal schedules that use this bank account number and try again.',
    text_lpoa_switch: 'LPOA Withdrawal',
    text_my529_access_card: 'my529 Access Discover® Prepaid Card',
    text_poa_approved: 'Approved',
    text_poa_not_approved: 'Not Approved',
    text_requirements: 'Requirements',
    text_set_up_accounts: 'Set up bank accounts',
    title_access_cards: 'My Access Cards',
    txt_third_party_warning_access_cards: 'By clicking the blue button, you will be directed to the Open-CP portal, a third-party website where you can apply for the my529 Access Discover® Prepaid Card (see image below). The website, www.my529-ch.open-cp.com/signup, is operated by Central Payments. my529 is not responsible for and has no control over the content of third-party websites.',
    txt_third_party_disclaimer: 'The my529 Access Discover® Prepaid Card is issued by Central Bank of Kansas City, Member FDIC. Discover and the Discover acceptance mark are service marks used by Central Bank of Kansas City under license from Discover Financial Services. Certain fees, terms, and conditions are associated with the approval, maintenance, and use of the Card. You should consult your Cardholder Agreement and Fee Schedule. If you have any questions regarding the Card or such fees, terms, and conditions, you can contact us 24/7/365 toll-free at 1-844-545-0805.',
    txt_third_party_warning_access_cards_footer(openCPLink) {
      return `The my529 Access Discover® Prepaid Card is issued by Central Bank of Kansas City, Member FDIC. Discover and the Discover acceptance mark are service marks used by Central Bank of Kansas City under license from Discover Financial Services. Certain fees, terms, and conditions are associated with the approval, maintenance, and use of the Card. You should consult your Cardholder Agreement and Fee Schedule at ${openCPLink}. If you have any questions regarding the Card or such fees, terms, and conditions, you can contact us 24/7/365 toll-free at 1-844-545-0805.`;
    },
    txt_third_party_warning_for_info: 'By clicking the blue button, you will be directed to a third-party website. The content of the third-party website is for information purposes only. my529 is not responsible for and has no control over the content of the third-party website.',
  },
  PayrollContribution: {
    btn_add: buttons.add,
    btn_add_account: 'Contribute to Different Account',
    btn_agree: buttons.agree,
    btn_cancel: buttons.cancel,
    btn_edit: buttons.edit,
    btn_finish: buttons.finish,
    btn_instructions: 'Learn More',
    btn_next: buttons.next,
    btn_print: buttons.print,
    btn_terms: 'Terms and Conditions',
    err_date_past_today: 'Date cannot be past today',
    err_invalid_account: 'Invalid account number or beneficiary birthdate',
    err_print: errors.print,
    err_required: errors.required,
    err_total_amount: 'Amount must be greater than 0',
    err_total_percent: 'Must total 100%',
    head_account_number: 'my529 Account Number',
    head_beneficiary: 'Beneficiary',
    head_other_accounts: 'Accounts Others Own',
    head_own_accounts: 'My Own Accounts',
    lbl_birth_date: formLabels.birthdate,
    lbl_employee: 'Employee',
    lbl_employer: 'Employer',
    lbl_my529_account: 'my529 Account Number',
    lbl_pay_period_contribution: 'Pay Period Contribution',
    lbl_state: 'Employer State',
    lbl_terms_agree: 'I agree to the terms and conditions',
    msg_account_added: 'Account has been added.',
    msg_payroll_saved: 'Payroll direct deposit saved.',
    msg_payroll_updated: 'Payroll direct deposit updated.',
    nav_path: '/payroll',
    nav_title: 'Payroll Direct Deposit',
    text_add_account: 'You may add a my529 account to receive your regular payroll direct deposit if you know the my529 account number and the beneficiary\'s date of birth.',
    text_current_allocations: 'The account allocation(s) of your current payroll direct deposit:',
    text_important_bank_info: '<strong>Important:</strong> The bank account information provided above is strictly to fund the above-named employee\'s my529 account(s) through the means of payroll direct deposit. Any other use of this information is prohibited. The user of this information is liable for losses incurred by its fraudulent use or dissemination.',
    text_total: 'Total:',
    title_add_account: 'Add Payroll Direct Deposit to Another Account',
    title_amount: 'Amount',
    title_bene_name: 'Beneficiary Name',
    title_employer: 'Employer',
    title_payroll_account: 'Payroll Bank Account Number',
    title_payroll_account_type: 'Payroll Bank Account Type',
    title_payroll_routing: 'Payroll Routing Number',
  },
  PayrollInstructions: {
    head_instructions: 'Learn More',
    title_how: 'How can I sign up?',
    text_how1: 'To sign up for after-tax payroll direct deposit with my529, you\'ll need to provide the following information:',
    text_how2: [
      'Your employer\'s name',
      'The total amount to be deducted from your paycheck each pay period',
      'The dollar amount of percentage of your contribution to go to each my529 account',
    ],
    text_how3: 'Once you agree to the terms, you will be provided with a <strong>routing number and bank account number</strong> to give to your employer.',
    text_how4: 'How can I make changes?',
    text_how5: 'You may make any needed changes to your employer name and allocations. Changes to your payroll direct deposit amount or cancellation of your contribution will not take place until you contact your employer with the new information.',
    title_provide_to_employer: 'Provide payroll information to your employer',
    text_provide1: 'Don\'t forget this step! To activate your contribution, you or your employer will need to set up my529 payroll direct deposit as an additional direct deposit from your paycheck as allowed by your employer. The confirmation you print contains the required direct deposit information.',
    text_provide2: 'Changes or cancellation of my529 payroll direct deposit must be done through your employer.',
  },
  PayrollTerms: {
    head_terms: 'Terms and Conditions',
    text_terms: [
      'my529 UGMA/UTMA accounts may not be funded through payroll direct deposit.',
      'The above amount will be deducted from my paycheck each pay period.',
      'If I no longer wish to participate in payroll direct deposit or I no longer own any accounts with my529, I must stop payroll direct deposit with my employer.',
      'This request revokes and replaces any previous my529 payroll direct deposit request that I have submitted. It will remain in effect until cancelled or replaced.',
      'my529 will apply the funds received from my employer according to the current percentages on record with my529. I also acknowledge that any changes I make that affect the ownership or status of my my529 account(s) will change my current percentages.',
      'Each pay period my employer will reduce my after-tax compensation by the total amount shown above and remit such amount to my529 for deposit into my my529 account(s).',
      'This payroll direct deposit amount will not be invested with my529 until my529 receives the funds from my employer.',
      'I have read the my529 Program Description and considered all investment objectives, risks, charges, and expenses.',
      'Investments are not guaranteed by my529, the State Board of Regents, the Utah Higher Education Assistance Authority (UHEAA), or any other state or federal agency. However, FDIC insurance is provided for the FDIC-insured accounts. I understand that my investment could lose value.',
      '<strong>Non-account Owner Contributors: As a reminder, when you authorize payroll direct deposit, you agree to the following:</strong> Only the my529 account owner may receive Utah income tax benefits for money contributed to a my529 account. If you are not the my529 account owner of the account to which you are contributing, you will not receive the Utah income tax benefit and you will not have any control over the money contributed to the my529 account.',
    ],
    text_terms_authorize: 'I understand and authorize the following:',
  },
  PhoneNumbersCard: {
    btn_change_phone: 'Change Phone Numbers',
    head_title: 'Phone Numbers',
    lbl_cell: 'Cell Phone',
    lbl_fax: 'Fax',
    lbl_home: 'Home Phone',
    lbl_other: 'Other Phone',
    lbl_work: 'Work Phone',
    text_intl_phone: 'Do you have an international phone number? If so, please call us at 800.418.2551 or submit form 500, Account Information Change',
    msg_phone_numbers_updated: 'Phone numbers updated.',
    msg_phone_number_too_short: errors.phone_number_too_short,
    msg_warning_changing_phone: 'Warning: Changing a phone number here will not change the phone number you have registered for two factor authentication.',
  },
  Preferences: {
    btn_change_delivery_prefs: 'Change Delivery Preferences',
    btn_close: buttons.close,
    btn_view_change_statement_delivery: 'View/Change Statement Delivery',
    head_utah_resident: 'Utah Resident',
    head_account: 'Account',
    head_balance: 'Balance',
    head_beneficiary: 'Beneficiary',
    head_birthday: 'Birthday Notifications',
    head_delivery_method: 'Delivery Method',
    head_newsletter: 'Newsletter',
    head_notification_preferences: 'Notification Preferences',
    head_official_communications: ' Official Communications',
    head_promotional: 'Promotional',
    head_title_change_statement_delivery_prefs: 'Change Statement Delivery Preferences',
    head_title_preferences: 'Preferences',
    head_title_statement_delivery: 'Quarterly Statements',
    head_tax_forms: 'Tax Forms',
    lbl_birthday_notifications: 'Birthday Notifications',
    lbl_official_communications: 'Includes Program Description releases, important my529 announcements, or plan changes',
    lbl_promotional_info: 'my529 will not send you promotional materials for other companies or products.',
    lbl_promotional_information: 'Promotional Information',
    lbl_options: 'Options',
    lbl_tax_documents_online: 'Tax Documents online only?',
    lbl_tax_forms: 'Tax documents designated for the beneficiary will be mailed to their address on file',
    lbl_utah_resident: 'Do you require a Utah tax form?',
    text_residency_mismatch: 'The selected state does not match your declared Utah residency status. Please check that your mailing address and/or the checkbox for Utah Resident are correct.',
    msg_preferences_changed: 'Notification delivery successfully changed.',
    msg_statement_delivery_changed: 'Quarterly Statement Delivery changed.',
    text_no_accounts: 'No accounts on file',
    text_will_not_send_material_for_other: 'my529 will not send you promotional material for other companies or products.',
    text_tax_document_online_only: "If a tax document is designated to go to the beneficiary of the account, the tax document will still be mailed to the beneficiary's address on file.",
    text_utah_tax_form_changes: 'This option is required if you are a Utah Resident or plan to file for a Utah state tax credit.',
  },
  PromoCode: {
    btn_enter_code: 'Enter promotion code',
    btn_submit: buttons.submit,
    err_required_field: errors.required,
    lbl_promo_code: 'Promo Code',
    text_success: 'Promo code successful',
  },
  Register: {
    dropdown_label: 'Select Registration Type',
    btn_register: 'Create',
    btn_toggle_password: buttons.toggle_password,
    err_max_length_20: errors.field_max_length_20,
    err_max_length_40: errors.field_max_length_40,
    err_password_criteria: errors.password_criteria,
    err_password_match: errors.passwords_match,
    err_required_field: errors.required,
    err_terms_agree: errors.terms_agree,
    err_username_cannot_match_pass: 'Username and password cannot be the same.',
    err_username_min_length: errors.username_min_length,
    err_username_spaces: errors.no_trailing_leading_spaces,
    err_username_with_numbers: errors.username_with_numbers,
    err_valid_email: errors.email_invalid,
    head_individual_account: 'Never opened a my529 account? Start here and open your first one!',
    head_other_account: 'Registration Type',
    head_other_account_by_form: 'Accounts Created by Form',
    head_terms: 'terms and conditions',
    lbl_email: 'Email',
    lbl_firstName: formLabels.firstName,
    lbl_lastName: formLabels.lastName,
    lbl_password1: formLabels.password,
    lbl_password2: formLabels.password_confirm,
    lbl_username: formLabels.username,
    msg_recaptcha_language: recaptchaLanguage,
    nav_path: '/register',
    nav_title: 'New Registration',
    text_i_am_new_to_my529: 'I am new to my529',
    text_i_have_accounts_with_529: 'I have accounts with my529',
    text_account_types_requirement: 'These account types require that the account be created by filling out a form and sending it to my529. Once received you will be able to setup online access here.',
    text_about_form104: 'UGMA/UTMA Account Agreement (Form 104)',
    text_about_individual_account: 'If you are an adult (age 18+), register below to begin opening an Individual account. This is the most common my529 account type that people open.',
    text_about_institutional_account: 'Owned by an institution such as a trust, corporation or other organization',
    text_about_intl_number: 'Do you have an international address or phone number?',
    text_about_other_account: <span>Never opened a my529 account? Select <b>I am new to my529</b> to create a new account with my529. Already have an account or sent forms and just want online access? Select <b>I have accounts with my529</b> to setup your online access.</span>,
    text_never_opened_account: 'If you are an adult (age 18+), register below to begin opening an Individual account. This is the most common my529 account type that people open.',
    text_about_ugma_utma_account: 'In a Uniformed Gift to Minors Act/Uniformed Transfers to Minors Act (UGMA/UTMA) account, the minor beneficiary is also the account owner, but an agent is appointed for the account. Contributions are an irrevocable, permanent gift to the minor beneficiary.',
    text_already_have_account: 'Already have an account?',
    text_form104: 'Institutional Account Agreement (Form 102)',
    text_form500: 'Account Information Change (Form 500)',
    text_i_agree: buttons.agree_terms,
    text_institutional_account: 'Institutional 529 Account',
    text_intl_number: 'International address or phone number?',
    text_read_terms: buttons.read_terms,
    text_setup_online_access: 'Set up online access',
    text_setup_user: 'Already have an account or sent forms and just want online access?',
    text_terms_bullets: [
      'my529 will use and store information you provide us to contact you or market to you about my529.',
      'my529 will not sell your information to third parties.',
    ],
    text_ugma_utma_account: 'UGMA/UTMA 529 Account',
  },
  ResendEmail: {
    btn_resend_email: 'Resend Email',
    head_confirm_email: 'Please confirm your email address',
    msg_email_resent: 'Email resent.',
    nav_path: '/resend-email',
    nav_title: 'Welcome To my529!',
    text_check_inbox: 'Check your inbox for a confirmation email. Click the link in your email to confirm the email address.',
    text_support_phone(supportNumber) { return `Didn't receive an email from us? Contact support at ${supportNumber}.`; }
  },
  ResetPassword: {
    btn_submit: 'Submit',
    btn_toggle_password: buttons.toggle_password,
    err_confirm_password: errors.required,
    err_password_criteria: errors.password_criteria,
    err_password_match: errors.passwords_match,
    err_password_required: errors.required,
    msg_password_changed: 'Password changed.',
    nav_path: '/reset-password',
    nav_title: 'Reset Password',
    text_confirm_password: 'Confirm new password',
    text_new_password: 'New Password',
  },
  ReviewStep: {
    btn_back: buttons.back,
    btn_continue: buttons.continue,
    head_review: 'Review',
    text_review: [
      'Please validate your information is correct before creating your account.',
      'my529 will attempt to verify your identity using an identity verification company. In the event that my529 needs more information to verify your ID, we will contact you.',
    ],
  },
  SetupUser: {
    nav_path: '/setup-user',
    nav_title: 'New Registration',
    err_required: errors.required,
    err_password_match: errors.passwords_match,
    lbl_ssn: formLabels.ssn,
    lbl_dob: 'Date of Birth',
    lbl_email: formLabels.email,
    lbl_password: formLabels.password,
    lbl_repeat_password: 'Repeat Password',
    lbl_username: formLabels.username,
    btn_continue: buttons.continue,
    btn_create: 'create',
    msg_agent_not_found: 'The account information you provided was not found in our system. Please re-enter the requested information.',
    msg_recaptcha_language: recaptchaLanguage,
  },
  Sidenav: {
    head_account_owner: 'Account Owner',
    head_need_help: 'Need help? Contact my529 at:',
    text_fax: 'f: ',
    text_loading: 'Loading...',
    text_phone: 'p: ',
    text_previous_login: 'Previous Login',
    btn_cancel: buttons.cancel,
    resources: {
      btn_proceed: 'Click here to proceed',
      title: 'Resources',
      lbl_program_description: 'Program Description',
      lbl_forms: 'Forms',
      lbl_performance: 'Performance',
      lbl_faqs: 'FAQs',
      title_third_party_warning: 'You are about to leave the my529 website',
      txt_third_party_warning_for_info: 'By clicking the blue button, you will be directed to a third-party website. The content of the third-party website is for information purposes only. my529 is not responsible for and has no control over the content of the third-party website.',
    },
    text_timezone: mountain_time_zone,
  },
  Signup: {
    btn_close: buttons.close,
    btn_delete_application: 'Delete',
    head_delete_account_application: 'Delete Account Application?',
    lbl_ao_step: 'Account Owner',
    lbl_bene_step: 'Beneficiary',
    lbl_confirmation_step: 'Confirmation',
    lbl_investment_step: 'Investment',
    lbl_review_step: 'Review',
    lbl_terms_step: 'Terms & Conditions',
    msg_application_deleted: 'Application deleted.',
    nav_path: '/add-account',
    nav_title: 'Add Account',
    text_delete_application_data: 'Are you sure you want to delete your account application, including beneficiary information and investment option information? This cannot be undone.',
  },
  SignupWelcome: {
    btn_continue: buttons.continue,
    btn_get_started: 'Get Started',
    head_welcome: 'Welcome',
    head_welcome_back: 'Welcome back',
    text_child_reading: 'Child reading',
    text_new_welcome: 'You are ready to set up your new account and start saving.',
    text_steps_remaining: 'Steps remaining for account setup:',
    text_steps_to_complete: 'Steps to complete',
    text_welcome_back: "Let's finish getting your account set up and get you on the road of saving for the future.",
  },
  Successors: {
    btn_add_successor(type) { return `Add ${type} Successor`; },
    btn_change_successor(type) { return `Change ${type} Successor`; },
    btn_close: buttons.close,
    err_age_limits(minAge, maxAge) { return errors.age_limits(minAge, maxAge); },
    err_missing_dob: 'We apologize for the inconvenience, but there is a discrepancy. Please contact my529 at 800.418.2551.',
    err_required: errors.required,
    head_remove_successor(type) { return `Remove ${type} Successor`; },
    head_title_primary_successor: 'Primary Successor',
    head_title_secondary_successor: 'Secondary Successor',
    lbl_birth_date: 'Successor Date of Birth',
    lbl_first_name: formLabels.firstName,
    lbl_last_name: formLabels.lastName,
    lbl_middle_name: 'Middle Name',
    lbl_ssn: formLabels.ssn,
    lbl_successor: 'Successor',
    menu_new_successor: 'New Successor',
    msg_successor_added: 'Successor added.',
    msg_successor_changed: 'Successor changed.',
    msg_successor_removed: 'Successor removed.',
    text_confirm_remove_successor(first, mid, last, type) { return `Do you want to remove ${first} ${mid} ${last} as ${type} successor?`; },
    text_none: 'None',
    text_successor_info_hide: 'In an effort to protect data confidentiality, the SSN and Birth Year are not displayed.',
    text_successor_to_trust: 'To designate or change the successor to a trust or institution, you must complete and submit Form 515 to my529.',
  },
  Summary: {
    head_account_owner: 'Account Owner',
    head_beneficiary: 'Beneficiary',
    head_investment_option: 'Investment Option',
    head_mailing_address: 'Mailing Address',
    head_physical_address: 'Physical Address',
    lbl_bene_utah_tax_payer: formLabels.beneUtahTaxPayer,
    lbl_birthdate: formLabels.birthdate,
    lbl_cancel: formLabels.cancel,
    lbl_city: formLabels.address_city,
    lbl_clear_state: formLabels.clear_state,
    lbl_firstName: formLabels.firstName,
    lbl_homePhone: formLabels.homePhone,
    lbl_lastName: formLabels.lastName,
    lbl_middleName: formLabels.middleName,
    lbl_postalCode: formLabels.address_postalCode,
    lbl_promo_code: 'Promotional Code (optional)',
    lbl_relationship: 'Relationship to Account Owner',
    lbl_ssn_tin: `${formLabels.ssn}  /  ${formLabels.tin}`,
    lbl_state: formLabels.address_state,
    lbl_street1: formLabels.address_street1,
    lbl_street2: formLabels.address_street2,
    lbl_utah_tax_payer: formLabels.utahTaxPayer,
    text_customized_ageBased: 'Customized Age-Based',
    text_customized_static: 'Customized Static',
    text_no: 'No',
    text_yes: 'Yes',
  },
  TermsConditionsStep: {
    btn_back: buttons.back,
    btn_i_agree: buttons.agree,
    head_terms: 'Terms and Conditions of my529 Account Agreement',
    msg_account_created: 'Account created.',
    text_terms_intro: 'This Account Agreement is entered into by and between my529 and the Account Owner / Agent effective the date all application materials and information have been completed and submitted to the Web site and the Account Owner / Agent clicks the appropriate button hereon to indicate its agreement with the terms herein. All capitalized terms used herein shall have the meanings used in the Terms of Use.',
    text_read_understand_agree(programDescription) { return `<div>I have read, understand, and agree to the Terms and Conditions of my529 Account Agreement. By clicking "I Agree" below where indicated, I hereby confirm my intent to enter into a contract with my529 to establish an account. I have read, understand, and retained a copy of the <a href=${programDescription} target='_blank' rel='noopener noreferrer'>Program Description</a> and will retain a copy of this document for my records.</div>`; },
    text_terms1: [
      'This Account Agreement is subject to, and incorporates by reference, the operating procedures and policies adopted by the Utah State Board of Regents, acting in its capacity as the Utah Higher Education Assistance Authority, and statutes governing the Utah Educational Savings Plan Trust codified as UCA 53B-8a. Any amendments to statutes and regulations shall automatically amend this Agreement and any changes to operating procedures and policies, including investment allocations and fees, shall amend this Agreement after adoption by the Utah State Board of Regents.',
      'This Account Agreement, the Program Description, and the program rules adopted by the Utah State Board of Regents constitute the entire agreement between the account owner and my529. No person is authorized to make oral or written modifications to this Agreement.',
      'This Agreement shall be deemed to be performed in Salt Lake City, Utah, and the laws of the State of Utah shall govern the terms and conditions of the Agreement. Accounts shall be governed in accordance with the laws of the State of Utah and applicable federal law, including 26 U.S.C. Section 529, as amended. The venue for any action arising from or relating to an account shall be in state or federal court located in Salt Lake City, Utah.',
      'There may be both federal and state tax consequences to opening or contributing to a my529 account. Advice should be sought from a qualified tax advisor. my529 cannot and does not provide legal, financial, or tax advice. Accordingly, you should consult your own tax or financial advisor concerning your particular tax or financial situation or if you have any questions concerning opening an account.',
      'If my529 determines that there has been any material misrepresentation related to this Account Agreement or an account, the account may be cancelled and the account money returned to the account owner, less any applicable fees and penalties.',
      'As the account owner, I warrant and represent to my529 the following as a condition to establishing the account:',
    ],
    text_terms2: [
      'I am opening the account to provide for the qualified higher education expenses of the beneficiary I have identified.',
      'I am at least 18 years of age and have a valid U.S. Social Security or Taxpayer Identification number.',
      'I understand that my529 may collect non-public personal information about me from third parties to verify my identity or prevent fraud. This personal information may include, among other things, my name and address, Social Security or Taxpayer Identification number, and date of birth.',
      'I have the power and authority to sign and open an account with my529.',
      'The information I have provided to complete the Account Agreement -- and all future information I will provide with respect to my account is true, complete, and correct.',
      'I understand that the account will be opened based on the information I provide.',
      'I understand that the value of my account may vary depending on market conditions and the performance of the investment option I select. I understand that the account balance may be more or less than the total amount contributed to the account. I understand that opening an account involves investment risk, including the possible loss of principal.',
      "I understand that my investments are not insured or guaranteed by the State of Utah, my529, the Utah State Board of Regents, the Utah Higher Education Assistance Authority, or any other state or federal government agency (except to the extent noted below regarding FDIC insurance), or any of the employees or directors of any such entities. I understand that investments in my529's FDIC-insured accounts are insured by the FDIC up to the maximum amount set by federal law. Insurance coverage is subject to the total of my investments in my529's FDIC-insured accounts plus other accounts I hold at Sallie Mae Bank and U.S. Bank (Banks). It is my responsibility to determine how my my529 investments would be aggregated with other accounts at the Bank for purposes of the FDIC insurance.",
      'I have looked into the 529 plan in the state in which I pay taxes, as well as the state in which my beneficiary pays taxes, to see if it offers tax or other benefits to me that are not offered by my529.',
    ],
    text_terms3(faxNumber, phoneNumber) { return `I hereby authorize my529 to debit a scheduled contribution from my checking or savings account. This authorization is to remain in full effect until the above end date (if applicable) or until the following: (1) I delete the bank account(s) specified above or (2) I notify my529 of the revocation of this authorization by doing one of the following: (i) logging into Account Access at my529.org and use the online account feature Manage Contributions; (ii) contacting my529 by telephone at ${phoneNumber}; or (iii) submitting Form 200 via fax to ${faxNumber} or via U.S. Mail to PO Box 145100, Salt Lake City, UT 84114-5100. I understand that any revocation will not be effective until my529 has had a reasonable opportunity to act on the termination notification. If the bank account is owned jointly, I certify that I am authorized to initiate this transaction. I understand that rejected transactions or insufficient funds could cause fees to be assessed by my529 and/or my financial institution. (Applicable if I have signed up for online contribution(s).)`; },
    text_terms4: 'I understand that my account notification preferences have been set to online delivery. These settings can be changed at any time in my online login on the Settings page.',
  },
  TemplateTable: {
    msg_exceeded_max_percentage: 'The total percentage invested cannot exceed 100%.',
    msg_exceeds_max_allocation(fundName, maximumAllocation) { return `The percentage invested for ${fundName} cannot exceed ${maximumAllocation}%.`; },
    msg_only_whole_numbers: 'Only whole numbers can be entered.',
    text_notes(agreementYear) {
      return [
        '* An investment allocation to this fund may not exceed 25 percent in the account. Therefore, the total underlying fund expense will reflect the weighted allocation to the underlying investment.',
        `† The total underlying fund expense reflects a fee waiver pursuant to a Fee Waiver Agreement in effect through February 28, ${agreementYear}. The total underlying fund expense may increase if the Fee Waiver Agreement is not extended beyond February 28, ${agreementYear}.`,
        'Note: All numbers are rounded to the third decimal place.'
      ];
    },
  },
  TransactionHistory: {
    btn_export: 'Export',
    btn_open: 'Open',
    err_date_past_today: 'Date cannot be past today',
    err_from_after_to: 'From Date must be before To Date',
    err_to_before_from: 'To Date must be after From Date',
    head_title: 'Transactions',
    lbl_from_date: 'From Date',
    lbl_to_date: 'To Date',
    text_acct_number: 'Acct #',
    text_bene_name: 'Beneficiary',
    text_option: 'Option',
    text_basis: 'Basis',
    text_amount: 'Amount',
    text_date: 'Date',
    text_no_transactions: 'No transactions to display',
    text_tax_year: 'Tax Year',
    text_transaction_type: 'Transaction Type',
    text_view_details: 'View Details',
    text_milestone: 'Milestone',
  },
  TransactionDetails: {
    head_title: 'Transaction Details',
    text_acct_number: 'Acct #',
    text_bene_name: 'Beneficiary',
    text_option: 'Option',
    text_portfolio: 'Portfolio',
    text_units: 'Units',
    text_nav: 'NAV',
    text_total: 'Total',
    text_amount: 'Amount',
    text_date: 'Date',
    text_transaction_type: 'Transaction Type',
    text_type: 'Type',
    text_bank_name: 'Bank Name',
    text_bank_account_number: 'Number',
    text_bank_account_type: 'Bank Account Type',
    text_card_number: 'Card Number',
    text_payee_name: 'Payee Name',
    text_payment_confirmation: 'Confirmation #',
  },
  Transactions: {
    btn_transactions: 'Transactions',
    btn_cancel: buttons.cancel,
    preflight_in_progress_message: 'Preparing Transaction...',
    transactions_path: 'transactions',
    edit_path: 'edit',
    new_path: 'new',
    withdrawal_path: 'withdrawals',
    cancel_transaction_modal_message(transactionType, frequency) {
      let message = `Are you sure you want to cancel this ${transactionType}?`;

      if (transactionType === this.TRANSACTION_TYPES.CONTRIBUTION.type) {
        if (frequency === this.FREQUENCY.MONTHLY || frequency === this.FREQUENCY.TWICE_MONTHLY) {
          message = 'Are you sure you want to cancel these recurring monthly contributions?';
        }
        else if (frequency === this.FREQUENCY.SPECIAL_OCCASION) {
          message = 'Are you sure you want to cancel these recurring yearly contributions?';
        }
      }
      else if (transactionType === this.TRANSACTION_TYPES.WITHDRAWAL.type) {
        if (frequency === this.FREQUENCY.MONTHLY) {
          message = 'Are you sure you want to cancel these recurring monthly withdrawals?';
        }
      }

      return message;
    },
    transaction_title(transactionType) {
      switch (transactionType) {
        case this.TRANSACTION_TYPES.CONTRIBUTION.type:
          return this.TRANSACTION_TYPES.CONTRIBUTION.title;
        case this.TRANSACTION_TYPES.OPTION_CHANGE.type:
          return this.TRANSACTION_TYPES.OPTION_CHANGE.title;
        case this.TRANSACTION_TYPES.TRANSFER.type:
          return this.TRANSACTION_TYPES.TRANSFER.title;
        case this.TRANSACTION_TYPES.WITHDRAWAL.type:
          return this.TRANSACTION_TYPES.WITHDRAWAL.title;
        case this.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type:
          return this.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.title;
        default: // do nothing;
      }
    },
    transactionhub_account_list_subtitle(transactionType) {
      switch (transactionType) {
        case this.TRANSACTION_TYPES.CONTRIBUTION.type:
          return 'Who is this for?';
        case this.TRANSACTION_TYPES.OPTION_CHANGE.type:
          return 'Which account would you like to change?';
        case this.TRANSACTION_TYPES.TRANSFER.type:
          return 'Move funds from.';
        case this.TRANSACTION_TYPES.WITHDRAWAL.type:
          return 'Who is this for?';
        default: // do nothing;
      }
    },
    preflight_title_suffix(transactionType) {
      let preflightTitleSuffix = ' cannot be performed on this account because:';
      switch (transactionType) {
        case this.TRANSACTION_TYPES.TRANSFER.type:
          preflightTitleSuffix = ' cannot be made from this account because:';
          break;
        default: // do nothing
      }
      return preflightTitleSuffix;
    },
    nav_path(accountId, transactionType, editType, transactionId, groupId) {
      switch (transactionType) {
        case this.TRANSACTION_TYPES.TRANSFER.type: {
          if (editType === 'new') {
            return `/accounts/${accountId}/transactions/${this.TRANSACTION_TYPES.TRANSFER.path_new}`;
          }
          return `/accounts/${accountId}/transactions/${transactionId}/${this.TRANSACTION_TYPES.TRANSFER.path_edit}/edit`;
        }
        case this.TRANSACTION_TYPES.OPTION_CHANGE.type: {
          if (editType === 'new') {
            return `/accounts/${accountId}/transactions/${this.TRANSACTION_TYPES.OPTION_CHANGE.path_new}`;
          }
          else if (editType === 'edit') {
            return `/accounts/${accountId}/transactions/${this.TRANSACTION_TYPES.OPTION_CHANGE.path_edit}`;
          }
          return `/accounts/${accountId}/transactions/${this.TRANSACTION_TYPES.OPTION_CHANGE.path_view}`;
        }
        case this.TRANSACTION_TYPES.CONTRIBUTION.type: {
          if (editType === 'new') {
            return `/accounts/${accountId}/transactions/${this.TRANSACTION_TYPES.CONTRIBUTION.path_new}`;
          }
          return `/accounts/${accountId}/transactions/${transactionId}/${this.TRANSACTION_TYPES.CONTRIBUTION.path_edit}/edit`;
        }
        case this.TRANSACTION_TYPES.WITHDRAWAL.type: {
          if (editType === 'new') {
            return `/accounts/${accountId}/transactions/${this.TRANSACTION_TYPES.WITHDRAWAL.path}/groups/${groupId}/new`;
          }
          return `/accounts/${accountId}/transactions/${this.TRANSACTION_TYPES.WITHDRAWAL.path}/groups/${groupId}/edit`;
        }
        case this.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type:
          return `/accounts/${accountId}/transactions/${this.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.path}/groups/${groupId}/new`;
        default: return `/accounts/${accountId}/transactions`; // returns base route of Transactions component
      }
    },
    FREQUENCY: {
      MONTHLY: 'Monthly',
      TWICE_MONTHLY: 'Twice-Monthly',
      ONE_TIME: 'One-Time',
      SPECIAL_OCCASION: 'Special Occasion',
    },
    TRANSACTION_TYPES: {
      WITHDRAWAL: {
        path: 'withdrawals',
        title: 'Withdrawals',
        type: 'Withdrawal',
      },
      TRANSFER: {
        path_edit: 'transfers',
        path_new: 'transfers/new',
        title: 'Transfers',
        type: 'TransferOut',
      },
      OPTION_CHANGE: {
        path_edit: 'option-changes/edit',
        path_new: 'option-changes/new',
        path_view: 'option-changes/view',
        title: 'Option Changes',
        type: 'OptionChange',
      },
      CONTRIBUTION: {
        path_edit: 'contributions',
        path_new: 'contributions/new',
        title: 'Contributions',
        type: 'Contribution',
      },
      WITHDRAWAL_REQUEST: {
        path: 'withdrawal-requests',
        title: 'Request Withdrawal',
        type: 'WithdrawalRequest',
      }
    },
  },
  TransactionStepper: {
    btn_agree: buttons.agree,
    btn_back: buttons.back,
    btn_cancel: buttons.cancel,
    btn_finish: buttons.finish,
    btn_next: buttons.next,
    btn_print: buttons.print,
  },
  Transfers: {
    msg_unsaved_changes: unsaved_changes,
    btn_back: buttons.back,
    btn_cancel: buttons.cancel,
    btn_close: buttons.close,
    btn_ok: buttons.ok,
    btn_next: buttons.next,
    btn_submit: buttons.submit,
    btn_print: buttons.print,
    btn_finish: buttons.finish,
    lbl_add_account: 'Add account',
    err_accountOwnerSingleAccount: 'There is only one account under this Account Owner.',
    err_custodialFromOtherType: (typeName) => `${typeName} Accounts cannot be transferred from other account types.`,
    err_custodialToOtherBeneficiary: (typeName) => `${typeName} Accounts cannot be transferred to other Beneficiaries.`,
    err_custodialToOtherType: (typeName) => `${typeName} Accounts cannot be transferred to other account types.`,
    err_deferredEarningsNotConfirmed: 'You must confirm deferred earnings to do a full transfer balance.',
    err_destinationAccountMissing: 'Destination account is missing.',
    err_fullBalanceNotAllocated: 'Full Balance Transfers must be 100% allocated.',
    err_invalidAmount: 'Enter a valid amount.',
    err_invalidPercentage: 'Enter a valid percentage.',
    err_maxGroupBalanceExceeded: (beneficiaryName, amount) => `The maximum amount allowed for transfer to accounts for which ${beneficiaryName} is the beneficiary is ${amount}.`,
    err_noOptionChangesRemaining: (beneficiaryName) => `No Option Changes Remaining for Beneficiary ${beneficiaryName}.`,
    err_noRemainingOptionChanges: 'The maximum number of option changes allowed per calendar year has already been reached for this beneficiary.',
    err_partialBalancePercentUnderMinimum: 'Total must be greater than 0%.',
    err_partialBalancePercentOverMax: 'Total must be less than 100%.',
    err_sourceAccountZeroBalance: 'Source Account has no funds to transfer.',
    err_termsNotChecked: 'I have read and understand these terms and conditions.',
    err_totalGreaterThanAvailable: 'Total must be less than Available Balance.',
    err_totalUnderMinimum: 'Total must be at least $1.00.',
    head_account_number: 'Account Number',
    head_account_hash: 'Account #',
    head_account_owner: 'Account Owner',
    head_account_option: 'Account Option',
    head_account_type: 'Account Type',
    head_available_balance: 'Available Balance',
    head_bene: 'Beneficiary',
    head_bene_name: 'Beneficiary Name',
    head_option_change: 'Option Change',
    head_opt_change: 'Opt. Change',
    head_total_value: 'Total Value',
    head_source_status: 'Source Account Status:',
    lbl_destination_account: 'Destination Account',
    lbl_transfer_type: 'Transfer Type Toggle',
    lbl_partial_transfer: 'Partial Transfer',
    lbl_source_account: 'Source Account',
    lbl_leave_open: 'Leave this account open',
    lbl_transfer_types: 'Amount/Percentage',
    lbl_radio_amount: 'Amount ($)',
    lbl_radio_percentage: 'Percentage (%)',
    lbl_total: 'Total',
    lbl_step_select: 'Select Transfer Amount',
    lbl_step_terms: 'Terms & Conditions',
    lbl_step_confirm: 'Transfer Confirmation',
    msg_balance_info() {
      return (
        <div>
          The available balance is the current total value of the account minus pending transactions out of the account - such as withdrawals, transfers, contribution reversals, contributions that have not cleared the bank, or fees.

          {msg_available_balance_contribution}
        </div>
      );
    },
    msg_transfer_created: 'Transfer created.',
    msg_transfer_updated: 'Transfer updated.',
    nav_path_accounts: '/accounts',
    nav_path_edit: 'edit',
    nav_path_new: 'new',
    nav_transfer_title: (isNew) => `${isNew ? 'New' : 'Edit Scheduled'} Transfer`,
    title_transfer_type: (isEditing) => isEditing ? 'Edit Scheduled Transfer' : 'Create Transfer',
    title_deferred_earning_confirm: 'Deferred Earnings Confirmation',
    title_full_balance: 'Full Balance Transfers',
    title_option_change_info: 'Investment Option Change Information',
    title_destination_account: 'Transfer to Destination Account',
    title_source_account: 'Transfer from Source Account',
    text_deferred_earning: 'A full balance transfer from this account will result in a loss of deferred earnings from this account. Are you sure you wish to proceed?',
    text_print_copy: 'Please print a copy of this page for your records.',
    text_terms_read: 'I have read and understand these Terms and Conditions.',
    text_close_status: (willClose) => willClose ? 'Will Be Closed' : 'Will Remain Open',
    text_timezone: mountain_time_zone,
    text_full_balance_info() {
      return (
        <div>
          <p>
            You have selected a full-balance transfer of the my529 account shown above. The following will result:
          </p>
          <ul>
            <li>
              The entire available balance will be transferred to the account(s) selected. The available balance includes all contributions posted to your account after at least seven business days.
            </li>
            <li>
              Funds not in the available balance include pending transactions out of the account such as:
              <ul>
                <li>Withdrawals</li>
                <li>Transfers</li>
                <li>Fees</li>
                <li>Contribution reversals</li>
                <li>Contributions that have not cleared the bank</li>
                <li>Gift program contributions</li>
              </ul>
            </li>

            <li>
              All Gift Program contributions to my529 accounts are subject to a hold and may not be available at the time of a full balance transfer, whether they are contributed directly from a bank account, from a debit card or by check.
              <ul>
                <li>
                  <b>Gift Program contributions, made from a bank account or check, to my529 accounts that were established by a first-time account owner less than six months ago</b> will be held for 20 business days after the deposit.
                </li>
                <li>
                  <b>Gift Program contributions, made from a bank account or check, to my529 accounts that have been established longer than six months — or from an account owner who has been with my529 over six months —</b> will be held for at least seven business days after the deposit.
                </li>
                <li>
                  <b>Gift Program contributions made by debit card</b> cannot be withdrawn until 45 business days after deposit.
                </li>
              </ul>
            </li>
            <li>
              Your account will be closed unless you check the box below which says, “Leave this account open”. If you are a Utah taxpayer, closing your account may have Utah state income tax implications. Please see Part 9 of the Program Description for more information.
            </li>
            <li>
              Any scheduled electronic contributions will be cancelled unless you check the box below which says, “Leave this account open”.
            </li>
          </ul>
          <p>
            If the account owner/agent contributes to this account through payroll direct deposit and is closing the account, they must notify their employer to discontinue contributions.
          </p>
        </div>
      );
    },
    text_learn_more(form400, form300) {
      return (
        <div>
          <p>
            Only transfers between accounts where you are the owner/agent can be done online. To make a transfer to an account owned by another person, please complete the <a href={form400} target='_blank' rel='noopener noreferrer'>Internal Transfer form (form 400)</a>.
          </p>
          <p>
            UGMA/UTMA funds cannot be transferred to an account of another beneficiary or to a non-UGMA/UTMA account.
          </p>
          <p>
            <strong>
              Transferring funds between two or more my529 accounts where the account owner/agent, beneficiary, and account type are the same is considered an investment option change. You may change your investment option only twice per calendar year per beneficiary.
            </strong>
          </p>
          <p>
            <strong>
              If after making a transfer, you want to change the investment option for those same accounts, you may do so without incurring a second option change as long as you make the change before the close of market trading on the New York Stock Exchange (NYSE), normally 2:00 p.m. MT, on that day. You also must make any changes or cancellations before the close of market trading.
            </strong>
          </p>
          <p>
            <strong>
              If you make your transfer after the close of market trading, or on a weekend or holiday, any additional changes or cancellations must be received by 2:00 p.m., MT, the following business day.
            </strong>
          </p>
          <p>
            <strong>
              If you have questions about investment option changes, please review the Program Description before proceeding with this transfer.
            </strong>
          </p>
          <p>
            Transferring funds to a beneficiary who is not a member of the family of the current beneficiary is not an allowable transfer and is viewed as a nonqualified withdrawal by the IRS, with the earnings subject to both federal and state income taxes and a 10 percent federal penalty tax. If you wish to transfer between accounts where the beneficiaries are not family members, please complete a <a href={form300} target='_blank' rel='noopener noreferrer'>Withdrawal Request form (form 300)</a> indicating a nonqualified withdrawal.
          </p>
          <p>
            For Utah taxpayers, transfers from an account where the beneficiary was younger than age 19 at the time the account was established to an account where the beneficiary was age 19 or older when the account was opened will be subject to recapture of previous years&apos; Utah state income tax benefits and/or disallow Utah state income tax benefits for the current year.
          </p>
        </div>
      );
    },
    text_option_change_info() {
      return (
        <div>
          <p>
            This transfer will count as one of the two total investment option changes allowed per beneficiary for this calendar year.
          </p>
          <p>
            If, after making this transfer, you want to change the investment option for the same beneficiary&apos;s accounts of the same account type,
            you may do so without incurring a second option change as long as you make the change before the close of market trading on the New York Stock Exchange (NYSE),
            normally 2:00 p.m. MT. You also must make any changes or cancellations before the close of market trading.
          </p>
          <p>
            If you make a transfer request after the close of market trading, or on a weekend or holiday, any additional changes or cancellations must be received by 2:00 p.m. MT, the following business day.
          </p>
        </div>
      );
    },
    text_terms_conditions() {
      return (
        <div>
          <h2>Terms and Conditions</h2>
          <h3>
            Please review the following Terms and Conditions regarding transfers
          </h3>
          <p>
            I understand that a transfer between my529 accounts with the same account owner, beneficiary, and account type is considered an investment option change, which may only be done twice each calendar year per beneficiary.
          </p>
          <p>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            I understand that if I want to change the investment option for the same beneficiary's accounts of the same account type, I may do so without incurring a second option change as long as I make the change before the close of market trading on the New York Stock Exchange (NYSE), normally 2:00 p.m. MT, on the same day as the transfer.
          </p>
          <p>
            I understand that if I make my transfer request after the close of market trading, or on a weekend or holiday, any additional changes or cancellations must be received by 2:00 p.m. MT, the following business day. I may not cancel or change my transfer request after that deadline without it being counted as one of my option changes.
          </p>
          <p>
            I certify that the beneficiaries on the source and destination accounts involved in this transfer are family members, as defined by Section 529 of the Internal Revenue Code.
          </p>
          <p>
            I understand if I am a Utah taxpayer that if I am transferring money from an account held by a beneficiary who was younger than age 19 at the time the beneficiary was designated on the account, to an account where the beneficiary was age 19 or older when designated on the account:
          </p>
          <ul>
            <li>
              my529 will report this transfer on the Utah state tax form TC-675H and to the Utah State Tax Commission.
            </li>
            <li>
              Any Utah state income tax credit or deduction claimed in a prior tax year must be recaptured in the year of the transfer.
            </li>
            <li>
              If this transfer occurs in the same tax year, no Utah state income tax benefits are allowed.
            </li>
          </ul>
        </div>
      );
    },
  },
  WithdrawalRequests: {
    msg_unsaved_changes: unsaved_changes,
    btn_back: buttons.back,
    btn_cancel: buttons.cancel,
    btn_finish: buttons.finish,
    btn_next: buttons.next,
    btn_print: buttons.print,
    err_age_limits(minAge, maxAge) { return errors.age_limits(minAge, maxAge); },
    err_field_required: errors.required,
    err_max_amount(maxAmount) { return `Maximum withdrawal is ${maxAmount}`; },
    err_min_amount(minAmount) { return `Minimum withdrawal is ${minAmount}`; },
    err_required_field: errors.required,
    err_school_required: errors.school_required,
    err_selection_required: 'Selection is required.',
    head_account_details: 'Account Details',
    head_amount: 'Amount',
    head_confirmation: 'Withdrawal Confirmation and Summary',
    head_recipient: 'Recipient',
    head_terms: 'Terms and Conditions',
    lbl_agree_terms: buttons.agree_terms,
    lbl_birthdate: 'Beneficiary Birth Date',
    lbl_confirmation: 'Confirmation',
    lbl_fbo: 'FBO',
    lbl_full_balance: 'Full Balance',
    lbl_partial_balance: 'Partial Balance',
    lbl_student_id: 'Student ID',
    msg_withdrawal_requested: 'Withdrawal requested.',
    nav_title: 'Withdrawal Requests',
    text_3rd_party: 'my529 may use a third party to verify your identity.',
    text_account_num: 'Account Number',
    text_available_balance_info: [
      'Withdrawals can be made only from contributed funds that have posted to your account(s) for at least 7 business days.',
      'The Available Balance is the current total value of the account minus pending transactions.',
    ],
    text_available_for_withdrawal: 'Available For Withdrawal',
    text_bene: 'Beneficiary',
    text_current_opt: 'Current Option',
    text_full_balance_info: 'Your withdrawal request will result in a zero balance, so my529 will close your account(s) and cancel any automated contributions. Please contact your employer to cancel automated payroll direct deposit. If you would like your zero-balance account(s) to remain open, please check the box below.',
    text_full_balance_note: fullBalanceWithdrawalNote,
    text_print_copy: 'Please print a copy of this page for your records.',
    text_submitted: 'Congratulations! Your online withdrawal request has been submitted. my529 will send you an email confirmation shortly.',
    text_terms_p1: [
      'Use of Funds',
      'I understand my529 is not responsible for tracking the use of funds withdrawn from my529 accounts or determining their eligibility as qualified higher education expenses (including K-12 tuition expenses). I understand that I am responsible for maintaining adequate records, such as receipts and other documentation, for qualified higher education expenses (including K-12 tuition expenses).',
      'Federal law mandates that total withdrawals from all qualified tuition plans, such as my529, to pay for K-12 tuition expenses cannot exceed $10,000 annually per beneficiary from all 529 accounts. I understand that I am responsible for adhering to the federal limit.',
    ],
    text_terms_p2: [
      'Nonqualified Withdrawals',
      "I understand that any amount of this withdrawal used for nonqualified higher education expenses will make that amount a nonqualified withdrawal. I understand that the earnings portion of nonqualified withdrawals will be subject to federal and applicable state income taxes (including any recapture of Utah state income tax credit or deduction previously claimed), as well as an additional 10 percent federal penalty tax. Withdrawals due to the beneficiary's death, disability, scholarship(up to the amount of the scholarship), or U.S.Service academy attendance are exempt from the penalty tax(but are still subject to income tax on the earnings portion of that withdrawal).",
      'If any part or all of this withdrawal becomes nonqualified, I understand that either I or the beneficiary, as applicable, will be responsible for reporting the withdrawal on an income tax return for the tax year of the withdrawal and for paying any related taxes or penalties.',
    ],
    text_terms_p3: [
      'Tax Forms',
      'I understand that my529 will send IRS Form 1099-Q in January each year after a withdrawal is issued from an account. This form states the total amount, principal, and earnings portion of all withdrawals in a calendar year, for each payee, as applicable.',
      'In addition, I understand that Utah taxpayer/resident account owners will receive a TC-675H tax form each year. This form states the following information for an account owner: total qualifying contributions to count toward a Utah state income tax credit, or deduction, total withdrawals made, and total amount transferred to an nonqualified beneficiary.',
    ],
    text_terms_p4: [
      'Request Submission',
      'I certify that the information entered for this withdrawal request is true and accurate.',
      'I authorize my529 to make my requested withdrawal.',
      'If the account is an UGMA/UTMA account, I certify that I am the custodian of the account and the withdrawal request is necessary for the welfare and benefit of the beneficiary. If the account is owned by a trust, corporation, or other entity, I certify that I am authorized to act on its behalf.',
    ],
    text_timezone: mountain_time_zone,
  },
  Withdrawals: {
    total_amount_full_balance: 'Total Amount of Full Balance',
    total_amount_full_balance_electronic: 'Total Amount of Full Balance (less $3.50 Electronic School Fee)',
    msg_unsaved_changes: unsaved_changes,
    msg_available_balance() {
      return (
        <div>
          Withdrawals can be made only from contributed funds that have posted to your account(s) for at least 7 days.

          <p>The Available Balance is the current total value of the account minus pending transactions.</p>

          {msg_available_balance_contribution}
        </div>
      );
    },
    btn_change_agent_address: 'Change Agent Address',
    btn_change_bene_address: 'Change Beneficiary Address',
    btn_add_school: 'Add School',
    title_edit_withdrawal: 'Edit Withdrawal',
    title_new_withdrawal: 'New Withdrawal',
    text_full_balance_info: 'Your withdrawal request will result in a zero balance, so my529 will close your account(s) and cancel any automated contributions. Please contact your employer to cancel automated payroll direct deposit. If you would like your zero-balance account(s) to remain open, please check the box below.',
    text_ElectronicSchoolPayment: 'Electronic Payment',
    ElectronicSchoolPayment_fee_msg: 'Electronic Payment ($3.50 fee for expedited payment to the school.)',
    ElectronicSchoolPayment_electronic_info: [
      'Secure electronic payments arrive at the school within approximately 2-3 business days. The expedited service costs $3.50 per transaction, and my529 adds the fee to the requested withdrawal amount. Track your payment status on the Transactions page.',
      'my529 will send the tax forms in January of the following year.'
    ],
    ElectronicSchoolPayment_mail_info: [
      'my529 sends checks directly to the school. Please allow 2-3 weeks for processing and delivery. Withdrawal checks are made payable to the eligible educational institution. The check stub includes the beneficiary’s name and student ID (if provided by the account owner).',
      'my529 will send the tax forms in January of the following year.'
    ],
    ElectronicSchoolPayment_mail_check: 'Mail Check',
    tab_labels: [
      'One Time',
      'Recurring Monthly',
    ],
    step_labels: [
      'Withdrawal Type',
      'Recipient',
      {
        onetime: 'Amount, Sell Date & Pay Method',
        recurring: 'Amount, Start/End Date & Pay Method',
      },
      'Terms & Conditions',
    ],
    errors: {
      amnt_with_fee: 'Withdrawal + Electronic Payment fee exceeds individual available balance.',
      min_withdrawal(minWithdrawal) {
        return `Minimum withdrawal is ${minWithdrawal}.`;
      },
      exceeds_access_card_capcity: 'Withdrawal exceeds my529 Access Card max balance.',
      exceeds_total_available: 'Withdrawal exceeds total available balance.',
      exceeds_individual_available: 'Withdrawal exceeds individual available balance.',
      amount_type: 'Amount type is required.',
      withdrawal_type: 'Withdrawal type is required.',
      max_withdrawal(maxWithdrawal) {
        return `Withdrawals of ${maxWithdrawal} or more require a Signature Guarantee. Visit https://my529.org to complete and submit Withdrawal Request Form 300, which includes a section for a Signature Guarantee.`;
      },
      sell_date_required: 'Sell Date is required.',
      sell_date_valid: 'Sell Date is not valid.',
      max_withdrawal_recurring(maxWithdrawalRecurring) {
        return `Recurring withdrawals cannot exceed ${maxWithdrawalRecurring} or more.`;
      },
      start_date_required: 'Start Date is required.',
      start_date_valid: 'Start Date is not valid.',
      start_date_enabled(numberOfRollingDays) {
        return `Start Date can be only between the 1st and 28th or ${numberOfRollingDays} days from now.`;
      },
      end_date_required: 'End Date is not valid.',
      end_date_valid: 'End Date should be after the start date.',
      recipient_required: 'Recipient is required.',
      school_required: errors.school_required,
      state_required: 'State is required.',
      postal_code_required: 'Postal code is required.',
      payment_method_required: 'Payment method is required.',
      payment_type_required: 'Payment type is required.',
    },
    cutoff_warning(date) {
      return `The withdrawal request must be submitted by ${date} MT to be eligible for today's trading session.`;
    },
    poa_bank_modal_body: 'The selected bank account was set up by a user with Power of Attorney on your my529 accounts. Please verify that this is the desired destination bank account for your withdrawal.',
    poa_bank_modal_title: 'Bank Account created by Power of Attorney',
    step0_amount_date: {
      sellDateText(isRecurring) {
        const allow23DaysText = isRecurring ?
          'Please allow 2-3 days for the trade to settle when considering which day to schedule the withdrawal.' :
          'Please allow 2-3 days for the trade to settle – plus mailing time for check requests of up to 2 weeks – when considering which day to schedule the withdrawal.';
        const dateInfo = isRecurring ?
          'The Start Date is the day on which my529 will submit an order to sell units of your investment for your withdrawal. Withdrawn funds will not be available on the Sell Date.' :
          'The Sell Date is the day on which my529 will submit an order to sell units of your investment for your withdrawal. Withdrawn funds will not be available on the Sell Date.';

        return [
          dateInfo,
          allow23DaysText,
          'If the markets are closed to trading on the selected day due to a holiday, weekend or other event, the sell order will be submitted the next business day. my529 offers no guarantee regarding the timing of transactions.'
        ];
      },
      partial_balance: {
        title: 'Only a partial balance withdrawal can be performed at this time.',
        more_than_max_p_long: 'A full balance withdrawal is not allowed for one or more of the following reasons:',
        more_than_max_p_short: 'A full balance withdrawal is not allowed because:',
        listItem1: 'Another transaction is already scheduled.',
        html_listItem2_more_than_max(maxWithdrawal) {
          return `Withdrawals of ${maxWithdrawal} or more require a Signature Guarantee. Visit <a href='https://my529.org' target='_blank' rel='noopener noreferrer'>https://my529.org</a> to complete and submit Withdrawal Request Form 300, which includes a section for a Signature Guarantee.`;
        },
        uncollected_funds_text: 'Withdrawals can be made only from contributed funds that have posted to your account(s) for at least 7 business days.',
      },
      continue_btn_label: 'Continue',
      currency_input_label: 'Amount to withdraw...',
      proportional_amounts(accountNumber, amount) { return `Account ${accountNumber}: ${amount}`; },
      sell_date_label: 'Sell Date',
    },
    step3_terms_and_conditions: {
      title: 'Terms and Conditions',
      title_help: 'Please review the following Terms and Conditions regarding withdrawals',
      p1: {
        title: 'Use of Funds',
        text1: 'I understand my529 is not responsible for tracking the use of funds withdrawn from my529 accounts or determining their eligibility as qualified higher education expenses (including K-12 tuition expenses). I understand that I am responsible for maintaining adequate records, such as receipts and other documentation, for qualified higher education expenses (including K-12 tuition expenses).',
        text2: 'Federal law mandates that total withdrawals from all qualified tuition plans, such as my529, to pay for K-12 tuition expenses cannot exceed $10,000 annually per beneficiary from all 529 accounts. I understand that I am responsible for adhering to the federal limit.',
      },
      p2: {
        title: 'Nonqualified Withdrawals',
        text1: "I understand that any amount of this withdrawal used for nonqualified higher education expenses will make that amount a nonqualified withdrawal. I understand that the earnings portion of nonqualified withdrawals will be subject to federal and applicable state income taxes (including any recapture of Utah state income tax credit or deduction previously claimed), as well as an additional 10 percent federal penalty tax. Withdrawals due to the beneficiary's death, disability, scholarship (up to the amount of the scholarship), or U.S. Service academy attendance are exempt from the penalty tax (but are still subject to income tax on the earnings portion of that withdrawal).",
        text2: 'If any part or all of this withdrawal becomes nonqualified, I understand that either I or the beneficiary, as applicable, will be responsible for reporting the withdrawal on an income tax return for the tax year of the withdrawal and for paying any related taxes or penalties.',
      },
      p3: {
        title: 'Tax Forms',
        text1: 'I understand that my529 will send IRS Form 1099-Q in January each year after a withdrawal is issued from an account. This form states the total amount, principal, and earnings portion of all withdrawals in a calendar year, for each payee, as applicable.',
        text2: 'In addition, I understand that Utah taxpayer/resident account owners will receive a TC-675H tax form each year. This form states the following information for an account owner: total qualifying contributions to count toward a Utah state income tax credit, or deduction, total withdrawals made, and total amount transferred to an nonqualified beneficiary.',
      },
      p4: {
        title: 'Request Submission',
        text1: 'I certify that the information entered for this withdrawal request is true and accurate.',
        text2: 'I authorize my529 to make my requested withdrawal.',
        text3: 'If the account is an UGMA/UTMA account, I certify that I am the custodian of the account and the withdrawal request is necessary for the welfare and benefit of the beneficiary. If the account is owned by a trust, corporation, or other entity, I certify that I am authorized to act on its behalf.',
      },
      agree_btn_label: 'I have read and understand these Terms and Conditions.',
    },
    messages: [
      {
        id: 'HigherEd',
        messages: [
          "All withdrawn money will be used to pay for the beneficiary's qualified higher education expenses." // eslint-disable-line quotes
        ]
      },
      {
        id: 'K-12Tuition',
        messages: [
          "All withdrawn money will be used to pay for the beneficiary’s qualified K-12 tuition expenses, up to $10,000 per year." // eslint-disable-line quotes
        ]
      },
      {
        id: 'NonQualified',
        messages: [
          'The money will not be used to pay for qualified higher education expenses or K-12 tuition expenses.'
        ]
      },
      {
        id: 'P',
        messages: [
          'The account owner/agent will receive IRS Form 1099-Q. If the account owner is also a Utah taxpayer/resident, they will receive Form TC-675H.',
          'my529 will send the tax forms in January of the following year.'
        ]
      },
      {
        id: 'B',
        messages: [
          'The beneficiary will receive IRS Form 1099-Q. If the account owner is a Utah taxpayer/resident, they will receive Form TC-675H.',
          'my529 will send the tax forms in January of the following year.'
        ]
      },
      {
        id: 'I',
        messages: [
          "my529 sends checks directly to the school. Please allow 2-3 weeks for processing and delivery. Withdrawal checks are made payable to the eligible educational institution. The check stub includes the beneficiary’s name and student ID (if provided by the account owner).", // eslint-disable-line quotes
          'my529 will send the tax forms in January of the following year.'
        ]
      }
    ],
    ao_school_address_modal_title: 'Update Account Owner Address',
    bene_school_address_modal_title: 'Update Beneficiary Address',
    retained_school_address_txt: 'Important: The information below was entered by you in a previous withdrawal submitted to this school. Please update the information as needed.',
    ugmautma_check_only_txt: 'UGMA/UTMA accounts are not eligible for withdrawals by Electronic Withdrawal, because withdrawal checks need to be noted when sourced from UGMA/UTMA funds.',
    withdraw_funds_modal: {
      title: 'Withdrawing Funds from Multiple Accounts',
      modal_title: 'Withdraw Funds',
      p1: 'There are two ways to withdraw funds if you own multiple accounts for the same beneficiary:',
      listItem1: 'You may select one account and the amount you wish to withdraw or',
      listItem2: 'You may withdraw funds proportionally from all displayed accounts for the same beneficiary',
      p_all_accts_disabled: 'The FROM ALL DISPLAYED ACCOUNTS button is disabled since there is at least one account with $0 available balance.',
      table_column1_title: 'Account Number',
      table_column2_title: 'Current option',
      table_column3_title: 'Available',
      btn_one_acct_label: 'From one account',
      btn_all_accts_label_short: 'From all accounts',
      btn_all_accts_label_long: 'From all displayed accounts',
    },
    check_mailing_text: 'A check will be mailed in 3-5 business days. Please allow up to two weeks for delivery.',
    electronic_request_text: 'Most requests can be completed within 3 business days.',
    step3_print_and_summary: {
      title: 'Withdrawal Confirmation and Summary',
      comment1: 'Congratulations! Your online withdrawal request has been submitted. my529 will send you an email confirmation shortly.',
      comment2: 'Your withdrawal will be reflected on your account activity within 2-3 business days.',
      subtitle_payee_bank: 'Payee Bank',
      subtitle_payee_address: 'Payee Address',
      subtitle_schedule: 'Schedule',
      frequency_onetime(sellDate) {
        return `One Time on ${sellDate}`;
      },
      frequency_recurring(dayOfMonth) {
        return `Recurring monthly on the ${dayOfMonth}`;
      },
      end_date_text(endDate) {
        return `Ending on ${endDate}`;
      },
      table_title: 'Withdrawal Amount',
      table_note: 'Funds will be withdrawn proportionally from all accounts listed below:',
      table_column1_title: 'Account',
      table_column2_title: 'Investment option',
      table_column3_title: 'Full Market',
      table_column4_title: 'Leave Open',
      table_column5_title: 'Withdrawal Amount*',
      total_amount(totalAmountOf) { return `Total Amount of ${totalAmountOf}`; },
      star_note: fullBalanceWithdrawalNote,
      print_note: 'Please print a copy of this page for your records.',
    },
    no_payment_accounts_text(onClick) {
      return <p>There are other electronic methods that can be added for withdrawals. To add other electronic methods, or to verify an existing bank account for electronic withdrawals, visit the <a onClick={onClick} src='#'>Payments</a> page.</p>; // eslint-disable-line
    },
    lbl_select_method: 'Select Method',
  },
  UpcomingTransactions: {
    head_title: 'Upcoming Transactions',
    menu_cancel: buttons.cancel,
    menu_edit: buttons.edit,
    menu_view: buttons.view,
    msg_error_retrieving_transactions: 'An error occurred while attempting to retrieve Upcoming Transactions. Please try refreshing the page, or contact my529 if you continue to experience this issue.',
    text_acct_number: 'Acct #',
    text_bene_name: 'Beneficiary',
    text_option: 'Option',
    text_amount: 'Amount',
    text_end_date: 'End Date',
    text_date: 'Date',
    text_frequency: 'Frequency',
    text_no_upcoming_transactions: 'No upcoming transactions to display',
    text_scheduled_date: 'Scheduled Date',
    text_status: 'Status',
    text_transaction_type: 'Transaction Type',
    drawer_title_view_pending_option: 'Pending Option Change Details',
  },
  UsernameCard: {
    btn_change_username: 'Change Username',
    err_different_username: 'Username is same as current',
    err_required: errors.required,
    err_username_length: errors.field_max_length_40,
    err_username_min_length: errors.username_min_length,
    err_username_spaces: errors.no_trailing_leading_spaces,
    err_username_with_numbers: errors.username_with_numbers,
    head_title: 'Username',
    lbl_current_password: current_password,
    lbl_current_username: 'Current Username',
    lbl_new_username: 'New Username',
    msg_username_changed: 'Username changed.',
  },
  ValidateEmail: {
    nav_path: '/validate-email',
    nav_title: 'Validating Email',
    msg_email_validated: 'Email validated. Please log in.',
  },
  ValidationFinish: {
    btn_back: buttons.back,
    btn_cancel: buttons.cancel,
    btn_validate: buttons.validate,
    err_required: errors.required,
    err_full_amount: errors.full_amount,
    head_validate(maskedBankAccountNumber) { return `Validate ${maskedBankAccountNumber} For Withdrawal`; },
    lbl_amount1: 'First Deposit',
    lbl_amount2: 'Second Deposit',
    msg_validated: 'Account validated',
    text_exceeded_attempts_error: 'The number of attempts to enter the correct verification amounts has been exceeded. Please go back to the Payments page and initiate another verification deposit.',
    text_match_error(attemptsRemaining, pluralize) {
      return pluralize ?
        `The amounts entered did not match the amounts deposited in your bank account. Please confirm the amounts by checking with your banking establishment then try again. You have ${attemptsRemaining} attempts remaining.` :
        `The amounts entered did not match the amounts deposited in your bank account. Please confirm the amounts by checking with your banking establishment then try again. You have ${attemptsRemaining} attempt remaining.`;
    },
    text_validation_instructions: 'To activate a bank account to receive electronic withdrawals from your my529 account(s), you must first verify your bank account by entering the amounts of two deposits placed by my529 into your bank account. If the amounts do not appear in your bank account\'s transaction history, please make sure you have allowed at least three business days for the amounts to post. Look for two small deposits originating from my529.',
  },
  ValidationStart: {
    btn_begin: buttons.begin,
    btn_cancel: buttons.cancel,
    btn_done: buttons.done,
    head_validate(maskedBankAccountNumber) { return `Validate ${maskedBankAccountNumber} For Withdrawal`; },
    text_complete_tp_verify: 'Complete the following process to verify your bank account for receipt of electronic withdrawals from your my529 account(s).',
    text_step_1: 'my529 will deposit two small, random amounts into your bank account.',
    text_step_2: 'After three business days:',
    text_step_3: 'Check your bank accounts transaction history and note the two amounts that were deposited by my529.',
    text_step_4: 'Return to this web page and enter the two amounts in the fields provided. This action verifies your bank account for receipt of withdrawals from your my529 account(s).',
    text_step_5: 'To authorize my529 to deposit the two verification amounts into your bank account, please click \'Begin\'',
    text_step_done: 'We received your request to verify this bank account for receipt of electronic withdrawals from your my529 accounts(s). my529 will deposit two small, random amounts into your bank account. Please allow three business days for the amounts, originating from my529, to post to your bank account. Note the amounts and return to this web page to input them in the fields provided, thus completing the verification process.',
  },
  VerifyEmail: {
    btn_change_email: 'Change Email Address',
    btn_resend_email: 'Resend Verification Email',
    head_change_email_address: 'Change Email Address',
    head_validating_email_while_logged_in: 'Validating Your Email',
    msg_email_resent: 'Verification Email Resent.',
    msg_validating_while_logged_in: 'It looks like you are trying to validate your email in a window you are already logged into. To validate your email, copy and paste the validation link into a new window or tab.',
    nav_path: '/verify-email',
    nav_title: 'Verify Email',
    text_verify_email1: 'Please verify your email address. A message containing a confirmation link was sent to the above email address. Click on the link to continue.',
    text_verify_email2(supportNumber) { return `If you did not receive the confirmation message, please check your spam folders. You can also resend the email by clicking the button below. If you continue to experience issues, contact the my529 support team at ${supportNumber}.`; },
    text_verify_email3: 'If the email address displayed above is incorrect, click the button above to change it.',
  },
};

export default English;
