/*
*
* AccountDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';

import BeneContact from './BeneContact';
import CurrentAllocation from './CurrentAllocation';
import Details from './Details';
import PromoCode from './PromoCode';
import Successors from './Successors';
import UpcomingTransactions from '../UpcomingTransactions';
import TransactionHistory from './TransactionHistory';
import ValueDetails from './ValueDetails';
import OptionDetails from './OptionDetails';
import TransactionButtons from '../TransactionButtons';

import {
  Divider,
  Paper,
} from '@mui/material';
import {
  sizify,
} from '@frontend/common';
import { submitPromoCode, getAccounts, } from '../actions';


import styles from './styles.module.scss';


const select = (state, props) => ({
  account: state.accounts.accountList.find(data => data.accountId === parseInt(props.match.params.accountId)) || state.accounts.emptyAccount,
  lastMarketDate: state.static.environmentVars.LastMarketDate,
  predefinedOptions: state.optionChanges.predefinedOptions,
});

export class AccountDetails extends React.Component {

  static propTypes = {
    account: PropTypes.shape({
      accountCloseDate: PropTypes.string,
      accountId: PropTypes.number,
      accountIsClosed: PropTypes.bool,
      accountNumber: PropTypes.number,
      accountType: PropTypes.string,
      beneficiary: PropTypes.object,
      hasAvailablePromoCodes: PropTypes.bool,
      interestedPartyId: PropTypes.number,
      moneyAllocations: PropTypes.array,
      option: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        isCustom: PropTypes.bool,
      }),
      permissions: PropTypes.shape({
        Contribute: PropTypes.bool,
        EditAccount: PropTypes.bool,
        OptionChange: PropTypes.bool,
        Transfer: PropTypes.bool,
        Withdraw: PropTypes.bool,
        WithdrawalRequest: PropTypes.bool,
      }),
      transactions: PropTypes.array,
    }).isRequired,
    lastMarketDate: PropTypes.string.isRequired,
    predefinedOptions: PropTypes.array.isRequired,
    getAccounts: PropTypes.func.isRequired,
    submitPromoCode: PropTypes.func.isRequired,
    size: PropTypes.object.isRequired,
    text: PropTypes.shape({
      Accounts: PropTypes.shape({
        text_account_closed: PropTypes.string,
      }),
      AccountsSummary: PropTypes.shape({
        head_account_type: PropTypes.string,
        head_interested_party: PropTypes.string,
      }),
      Documents: PropTypes.shape({
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
      }),
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    lastClicked: null,
  }

  render() {
    const { account, size, text, location, lastMarketDate, predefinedOptions, submitPromoCode, } = this.props;
    const { lastClicked } = this.state;
    const isInterestedParty = Boolean(account.interestedPartyId);

    return (
      <div className={styles.container} key={account.accountId}>
        
        {/* displays warning banner if account is closed */}
        {account.accountIsClosed && <div className={styles.closedAccountBanner}>{text.Accounts.text_account_closed} {account.accountCloseDate}</div>}

        <div className={styles.accountDetailsCard}>
          <Paper>
            {isInterestedParty ? // displays different header for interested party account
              <div className={styles.accountTypeInterestedParty}>{text.AccountsSummary.head_account_type} {account.accountType} - {text.AccountsSummary.head_interested_party}</div>
              :
              <div className={styles.accountType}>{text.AccountsSummary.head_account_type} {account.accountType}</div>
            }
            <div className={styles.accountDetailsContainer}>
              <div className={styles.accountDetailsLeft}>
                <Details
                  account={account}
                  lastMarketDate={lastMarketDate}
                  size={size}
                />

                <PromoCode
                  account={account}
                  submitPromoCode={submitPromoCode}
                  getAccounts={getAccounts}
                />

                <BeneContact 
                  key={account.beneficiary.beneficiaryId}
                  addresses={account.beneficiary.addresses}
                  canEditAddress={account.permissions.EditAccount}
                  setLastClicked={() => this.setState({ lastClicked: 'BeneContact' })}
                  isLastClicked={lastClicked === 'BeneContact'}
                />

                <Divider style={{ marginBottom: '20px' }} />

                <div className={styles.transactionBtns}>
                  <TransactionButtons
                    account={account}
                    size={size}
                    setLastClicked={() => this.setState({ lastClicked: 'Transactions' })}
                  />
                </div>

                <Divider style={{ margin: '20px 0' }} />

                <OptionDetails 
                  account={account}
                  isCustomOption={account.option.isCustom}
                  path={location.pathname}
                  predefinedOptions={predefinedOptions}
                />
              </div>

              <div className={styles.accountDetailsRight}>
                <CurrentAllocation
                  account={account}
                  size={size}
                  isCustomOption={account.option.isCustom}
                />

                <ValueDetails
                  account={account}
                  path={location.pathname}
                  lastMarketDate={lastMarketDate}
                  size={size}
                  isCustomOption={account.option.isCustom}
                />

                <Divider style={{ margin: '20px 0' }} />

                <Successors
                  account={account}
                  setLastClicked={() => this.setState({ lastClicked: 'Successors' })}
                  isLastClicked={lastClicked === 'Successors'}
                />
              </div>
            </div>
          </Paper>
        </div>

        {(account.permissions.EditAccount || account.permissions.WithdrawalRequest) && ( // hidden for Interested Parties (but not CSAs that can request withdrawals), and when no transactions exist
          <UpcomingTransactions
            account={account}
          />
        )}

        <TransactionHistory
          accountId={account.accountId}
          isScholarshipAccount={account.accountType === 'Scholarship'}
          transactions={account.transactions}
        />
      </div>
    );
  }
}

export default connect(select, { submitPromoCode, getAccounts, })(sizify(LanguageHOC(AccountDetails)));
