/*
*
* LpoaAccount Constants
*
*/
export const GET_RSA = 'src/components/Features/public/LpoaAccount/GET_RSA';
export const POST_RSA = 'src/components/Features/public/LpoaAccount/POST_RSA';
export const GET_LPOA_ACCOUNT = 'src/components/Features/public/LpoaAccount/GET_LPOA_ACCOUNT';
export const APPROVE_LPOA_ACCOUNT = 'src/components/Features/public/LpoaAccount/APPROVE_LPOA_ACCOUNT';
export const VALIDATE_LPOA_ACCOUNT = 'src/components/Features/public/LpoaAccount/VALIDATE_LPOA_ACCOUNT';
export const REJECT_LPOA_ACCOUNT = 'src/components/Features/public/LpoaAccount/REJECT_LPOA_ACCOUNT';
export const SAVE_TO_REDUX_LPOA_ACCOUNT = 'src/components/Features/public/LpoaAccount/SAVE_TO_REDUX_LPOA_ACCOUNT';

export const RSA_STATUS = {
  RSA_FAILED: 'RSA_FAILED',
  RSA_PASSED: 'RSA_PASSED',
  RSA_NOT_PASSED_YET: 'RSA_NOT_PASSED_YET',
};

export const PERSON_TYPES = {
  AO: 'ao',
  BENE: 'bene',
  SUCCESSOR_PRIMARY: 'successor',
  SUCCESSOR_SECONDARY: 'ssuccessor',
};

export const ADDRESS_TYPES = {
  MAILING: 'mailingAddress',
  PHYSICAL: 'physicalAddress',
};

export const RELATIONSHIPS = [
  { display: 'Child', value: 'C' },
  { display: 'Grandchild', value: 'G' },
  { display: 'Niece/Nephew', value: 'N' },
  { display: 'Spouse', value: 'SP' },
  { display: 'Self', value: 'S' },
  { display: 'Other', value: 'O' },
];