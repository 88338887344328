/*
*
* Payments Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import {
  Button,
  FormHelperText,
  Icon,
  Menu,
  MenuItem,
  Paper,
  Switch,
} from '@mui/material';
import {
  notificationShow,
  ConfirmModal,
  Modal,
  sizify,
  IconBtnTooltip,
} from '@frontend/common';
import SkeletonLoader from 'src/utils/components/SkeletonLoader';
import ThirdPartyWarningModal from 'components/AppRoot/Navigation/Sidenav/ThirtPartyWarningModal';
import {
  getBankAccounts,
  getAccessCards,
  getGroupedBankAccounts,
  getAllowedBankHolders,
  deleteBankAccount,
  startMicroDeposits,
  renewBankAccount,
  poaApproval,
  getAccessCardURL
} from 'components/Features/protected/Payments/actions';
import { agentGet } from 'components/Features/protected/MyInfo/actions';
import { get2FAPrecondition, getRSAPrecondition } from 'components/AppRoot/Navigation/actions';
import { MULTIFACTOR_TYPE } from 'components/AppRoot/Navigation/constants';
import { protectAction } from 'utils/helpers/multifactor_handler';

import AddBankModal from './AddBankModal';
import EditBankModal from 'components/Features/protected/Payments/EditBankModal';
import BankAccountsLearnMore from './BankAccountsLearnMore';
import AccessCardsLearnMore from './AccessCardsLearnMore';
import ValidationStartModal from './ValidationStartModal';
import ValidationFinishModal from './ValidationFinishModal';

import { tabletWidth, mobileWidth } from 'utils/config/_sassconfig.scss';
import styles from './styles.module.scss';

import OpenCPScreenshot from './OpenCPScreenshot.png';
const OPENCP_URL = 'https://my529accesscard.com';

const select = (state) => ({
  accountList: state.accounts.accountList,
  bankAccounts: state.payments.bankAccounts,
  accessCards: state.payments.accessCards,
  groupedBankAccounts: state.payments.groupedBankAccounts,
  claims: state.session.claims,
  agent: state.myInfo.agent,
});

///////////// CardWithTitle Common parts /////////////////
export function CardWithTitle({ children, paperProps, ...props }) {
  return (
    <div {...props}>
      <Paper {...paperProps} style={{ height: '100%' }}>
        {children}
      </Paper>
    </div>
  );
}

export function PaperTitle({ children }) {
  return (<div className={styles.paperTitle}>{children}</div>);
}

export function PaperBodyContainer({ children, padding }) {
  return (<div style={{ padding: padding || '20px' }}>{children}</div>);
}

export function PaperBodySection({ children }) {
  return (<div className={styles.paperBodySection}>{children}</div>);
}

export function PaperButtonsContainer({ children }) {
  return (<div className={styles.paperButtons}>{children}</div>);
}

export function PaperBodyDivider() {
  return (<hr className={styles.paperDivider} />);
}

export function PaperBodySectionLabels({ topLabel, bottomLabel }) {
  return (
    <div>
      <p className={styles.paperTopLabel}>{topLabel}</p>
      <p className={styles.paperBottomLabel}>{bottomLabel}</p>
    </div>
  );
}

CardWithTitle.propTypes = {
  paperProps: PropTypes.object,
};

PaperBodyContainer.propTypes = {
  padding: PropTypes.string,
};

PaperBodySectionLabels.propTypes = {
  topLabel: PropTypes.string,
  bottomLabel: PropTypes.string.isRequired,
};
///////////// END CardWithTitle Common parts /////////////////

export class Payments extends React.Component {
  static propTypes = {
    accountList: PropTypes.array.isRequired,
    bankAccounts: PropTypes.array.isRequired,
    accessCards: PropTypes.arrayOf(
      PropTypes.shape({
        AccessCardId: PropTypes.number,
        CardNumber: PropTypes.string,
        Title: PropTypes.string,
      })
    ).isRequired,
    groupedBankAccounts: PropTypes.object.isRequired,
    get2FAPrecondition: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    getBankAccounts: PropTypes.func.isRequired,
    getAccessCards: PropTypes.func.isRequired,
    getGroupedBankAccounts: PropTypes.func.isRequired,
    getAllowedBankHolders: PropTypes.func.isRequired,
    deleteBankAccount: PropTypes.func.isRequired,
    startMicroDeposits: PropTypes.func.isRequired,
    renewBankAccount: PropTypes.func.isRequired,
    poaApproval: PropTypes.func.isRequired,
    getAccessCardURL: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    agentGet: PropTypes.func.isRequired,
    agent: PropTypes.shape({
      AgentId: PropTypes.number,
      FirstName: PropTypes.string,
      MiddleName: PropTypes.string,
      LastName: PropTypes.string,
    }).isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number,
    }).isRequired,
    text: PropTypes.shape({
      Payments: PropTypes.shape({
        access_card_url_text: PropTypes.string,
        btn_add_bank: PropTypes.string,
        btn_apply_now: PropTypes.string,
        btn_learn_more: PropTypes.string,
        btn_validate: PropTypes.string,
        btn_deposits_received: PropTypes.string,
        btn_renew: PropTypes.string,
        btn_remove: PropTypes.string,
        btn_ok: PropTypes.string,
        head_pending_ach: PropTypes.string,
        head_remove_account: PropTypes.string,
        head_renew_account: PropTypes.string,
        msg_poa_approved: PropTypes.string,
        msg_poa_not_approved: PropTypes.string,
        msg_removed: PropTypes.string,
        msg_renewed: PropTypes.string,
        text_account_not_validated: PropTypes.string,
        text_account_validated: PropTypes.string,
        text_access_card_reqs: PropTypes.array,
        text_attempts_exceeded: PropTypes.string,
        text_confirm_remove: PropTypes.func,
        text_confirm_renew: PropTypes.func,
        text_expiration_warning: PropTypes.func,
        text_lpoa_switch: PropTypes.string,
        text_has_pending_transactions: PropTypes.string,
        text_my529_access_card: PropTypes.string,
        text_poa_not_approved: PropTypes.string,
        text_poa_approved: PropTypes.string,
        text_requirements: PropTypes.string,
        text_set_up_accounts: PropTypes.string,
        title_access_cards: PropTypes.string,
        txt_third_party_disclaimer: PropTypes.string,
        txt_third_party_warning_access_cards: PropTypes.string,
        txt_third_party_warning_access_cards_footer: PropTypes.func,
        txt_third_party_warning_for_info: PropTypes.string,
      })
    }).isRequired,
    environmentVars: PropTypes.shape({
      DisbursementSignatureGuaranteeAmount: PropTypes.number,
      DisbursementSignatureGuaranteeRollingDays: PropTypes.number,
    }).isRequired,
  }

  state = {
    isMenuView: false,
    isMobileView: false,
    isLoading: false,
    isLoadingURL: false,
    buttonIsLoading: false,
    hasBankAccounts: false,
    hasAccessCards: false,
    showBankAccountsLearnMore: false,
    showAccessCardsLearnMore: false,
    showAddBankModal: false,
    showEditBankModal: false,
    showGiactRejectionModal: false,
    showCancelConfirmModal: false,
    showRenewConfirmModal: false,
    showHasPendingTransactionsModal: false,
    showNicknameModal: false,
    showValidationStartModal: false,
    validationStarted: false,
    showValidationFinishModal: false,
    selectedBankAccount: {},
    menuAnchorPoints: {},
    addAccountMenuOpen: false,
    showAccessCardApplicationThirdPartyWarning: false,
    showGeneralThirdPartyWarning: false,
    accessCardEligible: false,
    accountBeingEdited: {},
    accessCardURL: '',

    hasPrecondition: false,
    post2FAAuthFunction: '',
    post2FAAction: '',
    past2FABankAccount: {}
  }

  handleGetBankAccounts = () => {
    const { getBankAccounts, getAccessCards, getGroupedBankAccounts, } = this.props;
    this.setState({ isLoading: true });
    Promise.all([
      getBankAccounts(),
      getAccessCards(),
    ])
      .then(() => {
        const { bankAccounts, accessCards, } = this.props;
        getGroupedBankAccounts();
        this.setState({ isLoading: false, hasBankAccounts: bankAccounts.length > 0, hasAccessCards: accessCards.length > 0 });
      })
      .catch(() => {
        const { bankAccounts, accessCards, } = this.props;
        this.setState({ isLoading: false, hasBankAccounts: bankAccounts.length > 0, hasAccessCards: accessCards.length > 0 });
      });
  }

  menuClose = (accountId) => {
    this.setState({ menuAnchorPoints: { ...this.state.menuAnchorPoints, [accountId]: null } });
  }

  menuOpen = (event, accountId) => {
    this.setState({ menuAnchorPoints: { ...this.state.menuAnchorPoints, [accountId]: event.currentTarget } });
  }

  toggleShowBankAccountsLearnMore = () => {
    this.setState({ showBankAccountsLearnMore: !this.state.showBankAccountsLearnMore });
  }

  toggleShowAccessCardsLearnMore = () => {
    this.setState({ showAccessCardsLearnMore: !this.state.showAccessCardsLearnMore });
  }

  toggleShowAddBank = () => {
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: 'toggleShowAddBank'
        },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: 'toggleShowAddBank'
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      this.setState({
        hasPrecondition: false,
        showAddBankModal: !this.state.showAddBankModal,
        post2FAAuthFunction: ''
      });
    }
  }

  handleShowRemoveBankConfirm = (bankAccount) => {
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: 'handleShowRemoveBankConfirm',
          post2FABankAccount: bankAccount
        },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: 'handleShowRemoveBankConfirm',
          post2FABankAccount: bankAccount
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      if (bankAccount.HasPendingACHTransactions) {
        this.setState({
          hasPrecondition: false,
          showHasPendingTransactionsModal: true,
          post2FAAuthFunction: '',
          post2FABankAccount: {}
        });
      }
      else {
        this.setState({
          hasPrecondition: false,
          selectedBankAccount: bankAccount,
          showCancelConfirmModal: true,
          post2FAAuthFunction: '',
          post2FABankAccount: {}
        });
      }
    }
  }

  handleDeleteBankAccount = () => {
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: 'handleDeleteBankAccount'
        },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: 'handleDeleteBankAccount',
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      this.setState({ buttonIsLoading: true });
      this.props.deleteBankAccount(this.state.selectedBankAccount.BankAccountId)
        .then(() => {
          this.props.notificationShow(this.props.text.Payments.msg_removed, 'success');
          this.handleGetBankAccounts();
          this.setState({
            hasPrecondition: false,
            buttonIsLoading: false,
            showCancelConfirmModal: false,
            post2FAAuthFunction: ''
          });
        })
        .catch(() => this.setState({ buttonIsLoading: false, showCancelConfirmModal: false }));
    }
  }

  handleRenewBankAccount = () => {
    this.setState({ buttonIsLoading: true });
    this.props.renewBankAccount(this.state.selectedBankAccount.BankAccountId)
      .then(() => {
        this.props.notificationShow(this.props.text.Payments.msg_renewed, 'success');
        this.handleGetBankAccounts();
        this.setState({ buttonIsLoading: false, showRenewConfirmModal: false });
      })
      .catch(() => this.setState({ buttonIsLoading: false, showRenewConfirmModal: false }));
  }

  handleApproveLPOA = bankAccount => {
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: 'handleApproveLPOA',
          post2FABankAccount: bankAccount
        },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: 'handleApproveLPOA',
          post2FABankAccount: bankAccount
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      const { msg_poa_approved, msg_poa_not_approved, } = this.props.text.Payments;
      const notificationMessage = !bankAccount.IsPoaApproved ? msg_poa_approved : msg_poa_not_approved;
      const approvalApiEndpoint = !bankAccount.IsPoaApproved ? 'Approve' : 'Unapprove';

      this.setState({ isLoading: true, hasPrecondition: false });
      this.props.poaApproval(approvalApiEndpoint, bankAccount.BankAccountId)
        .then(() => {
          this.props.notificationShow(notificationMessage, 'success');
          this.handleGetBankAccounts();
        })
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  handleAddAccountMenuClose = (event) => {
    if (this.addAccountMenuAnchor.current && this.addAccountMenuAnchor.current.contains(event.target)) {
      return;
    }

    this.setState({ addAccountMenuOpen: false });
  };

  renderLearnMoreButton = (forAccessCards = false) => {
    return (
      <Button
        onClick={forAccessCards ? this.toggleShowAccessCardsLearnMore : this.toggleShowBankAccountsLearnMore}
        variant='outlined'
        color='primary'
      >
        {this.props.text.Payments.btn_learn_more}
      </Button>
    );
  }

  renderNoBankAccounts = () => {
    return (
      <div className={styles.noAccounts_container}>
        <p className={styles.noAccounts_header}>{this.props.text.Payments.text_set_up_accounts}</p>
        <div className={styles.buttons_noAccounts}>
          {this.renderLearnMoreButton()}
          <Button
            variant='contained'
            onClick={this.toggleShowAddBank}
          >
            {this.props.text.Payments.btn_add_bank}
          </Button>
        </div>
      </div>
    );
  }

  renderNoAccessCards = () => {
    return (
      <div className={styles.noAccounts_container}>
        <p className={styles.noAccounts_header}>{this.props.text.Payments.text_my529_access_card}</p>
        <p className={styles.noAccounts_disclaimer}>{this.props.text.Payments.txt_third_party_disclaimer}</p>
        <div className={styles.buttons_noAccounts}>
          {this.renderLearnMoreButton(true)}
          <Button
            variant='contained'
            onClick={this.handleApplyForAccessCard}
            disabled={this.state.isLoadingURL}
          >
            {this.props.text.Payments.btn_apply_now}
          </Button>
        </div>
      </div>
    );
  }

  renderHasPendingTransactionsModal = () => {
    const { head_pending_ach, text_has_pending_transactions, btn_ok } = this.props.text.Payments;
    return (
      <Modal
        title={head_pending_ach}
        show={this.state.showHasPendingTransactionsModal}
        onCloseModal={() => this.setState({ showHasPendingTransactionsModal: false })}
        modal={false}
        actionButtons={[
          {
            label: btn_ok,
            action: () => this.setState({ showHasPendingTransactionsModal: false }),
            buttonType: 'contained',
          }
        ]}
      >
        <p>
          {text_has_pending_transactions}
        </p>
      </Modal>
    );
  }

  handleApplyForAccessCard = () => {
    this.setState({ showAccessCardApplicationThirdPartyWarning: true, isLoadingURL: true });
    this.props.getAccessCardURL()
      .then((response) => {
        const accessCardURL = response.payload.data;
        this.setState({ isLoadingURL: false, accessCardURL });
      })
      .catch(() => {
        this.setState({ showAccessCardApplicationThirdPartyWarning: false, isLoadingURL: false });
      });
  }

  handleAccountButtons(bankAccount, actionType) {
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: 'handleAccountButtons',
          post2FABankAccount: bankAccount,
          post2FAAction: actionType,
        },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        {
          hasPrecondition: true,
          post2FAAuthFunction: 'handleAccountButtons',
          post2FABankAccount: bankAccount,
          post2FAAction: actionType,
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      if (actionType === 'validate') {
        this.setState({
          hasPrecondition: false,
          showValidationStartModal: true,
          validationStarted: false,
          selectedBankAccount: bankAccount,
          post2FAAuthFunction: '',
          post2FAAction: '',
          post2FABankAccount: {}
        });
      }
      else { // deposits recieved
        this.setState({
          hasPrecondition: false,
          showValidationFinishModal: true,
          selectedBankAccount: bankAccount,
          post2FAAuthFunction: '',
          post2FAAction: '',
          post2FABankAccount: {}
        });
      }
    }
  }

  renderAccessCardButtons = () => {
    const { Payments } = this.props.text;

    return (
      <PaperButtonsContainer>
        <div className={styles.accessCardLink} onClick={() => this.setState({ showGeneralThirdPartyWarning: true })}>
          {Payments.access_card_url_text}
        </div>
      </PaperButtonsContainer>
    );
  }

  renderAccountButtons = (bankAccount, isExpiring, lpoaApprovalStatus) => {
    const { btn_validate, btn_deposits_received, btn_renew, btn_remove, text_lpoa_switch, } = this.props.text.Payments;
    const { BankAccountId, AllowWithdrawals, IsPendingWithdrawalVerification, HasExceededFullVerificationAttempts, IsEligibleForPoa, IsPoaApproved, BeneficiaryName, Nickname } = bankAccount;
    const accountBeingEdited = {
      BankAccountId,
      Nickname,
    };
    return (
      <PaperButtonsContainer>

        <div style={{ margin: 'auto' }}>
          <IconBtnTooltip
            buttonProps={{
              'aria-controls': 'edit bank name',
            }}
            icon='edit'
            title='Edit Bank Name'
            onClick={() => this.setState(
              { accountBeingEdited },
              () => this.setState({ showEditBankModal: true })
            )}
          />
        </div>
        {IsEligibleForPoa && AllowWithdrawals && !BeneficiaryName &&
          <div className={styles.lpoaSwitch} style={{ textAlign: 'center' }}>
            <p className={styles.labelTILE}>{text_lpoa_switch}</p>
            <Switch
              checked={IsPoaApproved}
              onChange={() => this.handleApproveLPOA(bankAccount)}
              value={IsPoaApproved}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <p style={{ fontSize: '10px', marginTop: '3px' }}>{lpoaApprovalStatus}</p>
          </div>
        }
        {!AllowWithdrawals && !IsPendingWithdrawalVerification && !HasExceededFullVerificationAttempts &&
          <Button onClick={() => this.handleAccountButtons(bankAccount, 'validate')}>{btn_validate}</Button>}
        {IsPendingWithdrawalVerification &&
          <Button onClick={() => this.handleAccountButtons(bankAccount, 'deposits')}>{btn_deposits_received}</Button>}
        {isExpiring &&
          <Button onClick={() => this.setState({ showRenewConfirmModal: true, selectedBankAccount: bankAccount })} >{btn_renew}</Button>}
        <Button
          onClick={() => this.handleShowRemoveBankConfirm(bankAccount)}
        >
          {btn_remove}
        </Button>
      </PaperButtonsContainer>
    );
  }

  renderAccessCardMenu = (accessCard) => {
    const { Payments } = this.props.text;
    const { CardNumber } = accessCard;

    return (
      <PaperButtonsContainer>
        <IconBtnTooltip
          buttonProps={{
            ariaControls: 'transaction-menu',
            ariaHaspopup: 'true',
            style: { paddingTop: '9px' },
          }}
          icon='more_vert'
          onClick={event => this.menuOpen(event, CardNumber)}
        />
        <Menu
          anchorEl={this.state.menuAnchorPoints[CardNumber]}
          id='transaction-menu'
          onClose={() => this.menuClose(CardNumber)}
          open={Boolean(this.state.menuAnchorPoints[CardNumber])}
        >
          <MenuItem
            onClick={() => {
              this.setState({ showGeneralThirdPartyWarning: true });
              this.menuClose(CardNumber);
            }}
          >
            {Payments.access_card_url_text}
          </MenuItem>
        </Menu>
      </PaperButtonsContainer>
    );
  }

  renderAccountMenu = (bankAccount, isExpiring, lpoaApprovalStatus) => {
    const { btn_validate, btn_deposits_received, btn_renew, btn_remove, text_lpoa_switch, } = this.props.text.Payments;
    const { BankAccountId, AllowWithdrawals, IsPendingWithdrawalVerification, HasExceededFullVerificationAttempts, IsEligibleForPoa, IsPoaApproved, BeneficiaryName, } = bankAccount;

    return (
      <PaperButtonsContainer>
        {IsEligibleForPoa && AllowWithdrawals && !BeneficiaryName &&
          <div className={styles.lpoaSwitch}>
            <p className={styles.labelTILE} style={{ textAlign: 'center' }}>{text_lpoa_switch}</p>
            <Switch
              checked={IsPoaApproved}
              onChange={() => this.handleApproveLPOA(bankAccount)}
              value={IsPoaApproved}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <p style={{ fontSize: '10px', marginTop: '3px', textAlign: 'center' }}>{lpoaApprovalStatus}</p>
          </div>
        }
        <IconBtnTooltip
          buttonProps={{
            style: { paddingTop: '9px' }
          }}
          icon='more_vert'
          onClick={event => this.menuOpen(event, BankAccountId)}
        />
        <Menu
          anchorEl={this.state.menuAnchorPoints[BankAccountId]}
          id='transaction-menu'
          onClose={() => this.menuClose(BankAccountId)}
          open={Boolean(this.state.menuAnchorPoints[BankAccountId])}
        >
          {!AllowWithdrawals && !IsPendingWithdrawalVerification && !HasExceededFullVerificationAttempts &&
            <MenuItem
              onClick={() => {
                this.setState({ showValidationStartModal: true, validationStarted: false, selectedBankAccount: bankAccount });
                this.menuClose(BankAccountId);
              }}
            >
              {btn_validate}
            </MenuItem>
          }
          {IsPendingWithdrawalVerification &&
            <MenuItem
              onClick={() => {
                this.setState({ showValidationFinishModal: true, selectedBankAccount: bankAccount });
                this.menuClose(BankAccountId);
              }}
            >
              {btn_deposits_received}
            </MenuItem>
          }
          {isExpiring &&
            <MenuItem
              onClick={() => {
                this.setState({ showRenewConfirmModal: true, selectedBankAccount: bankAccount });
                this.menuClose(BankAccountId);
              }}
            >
              {btn_renew}
            </MenuItem>
          }
          <MenuItem
            onClick={() => {
              this.handleShowRemoveBankConfirm(bankAccount);
              this.menuClose(BankAccountId);
            }}
          >
            {btn_remove}
          </MenuItem>
        </Menu>
      </PaperButtonsContainer>
    );
  }

  renderBankAccountDetailsAndButtons(bankAccount) {
    const {
      text_expiration_warning,
      text_poa_approved, text_poa_not_approved,
      text_account_validated,
      text_account_not_validated,
      text_attempts_exceeded,
    } = this.props.text.Payments;
    const {
      BankName,
      MaskedBankAccountNumber,
      BankAccountType,
      AllowWithdrawals,
      HasExceededFullVerificationAttempts,
      ExpirationWarningDate,
      ExpirationDate,
      IsPoaApproved,
      Nickname
    } = bankAccount;
    const isExpiring = dayjs().isSameOrAfter(dayjs(ExpirationWarningDate), 'day');
    const expirationDate = dayjs(ExpirationDate).format('MM/DD/YYYY');
    const lpoaApprovalStatus = IsPoaApproved ? text_poa_approved : text_poa_not_approved;
    const renderButtons = this.state.isMenuView ?
      this.renderAccountMenu(bankAccount, isExpiring, lpoaApprovalStatus) :
      this.renderAccountButtons(bankAccount, isExpiring, lpoaApprovalStatus);
    let validationStatus = AllowWithdrawals ?
      <p className={styles.validatedStatus}>{text_account_validated}</p> :
      <p className={styles.notValidatedStatus}>{text_account_not_validated}</p>;

    if (HasExceededFullVerificationAttempts) {
      validationStatus = <p className={styles.notValidatedStatus}>{text_attempts_exceeded}</p>;
    }

    return (
      <PaperBodySection>
        <div className={styles.bankDetailsContainer}>
          <div className={styles.icon}>
            <Icon fontSize='inherit'>account_balance</Icon>
          </div>
          <div>
            <div className={styles.bankDetails}>
              <p className={styles.bankName}>{BankName}</p>
              <p className={styles.bankAccountType}>{BankAccountType}</p>
              {validationStatus}
            </div>
            <p className={styles.paperBottomLabel}>{MaskedBankAccountNumber}</p>
            <div className={styles.nickName}>{Nickname}</div>
          </div>
          {isExpiring &&
            <FormHelperText error={isExpiring}>
              {text_expiration_warning(expirationDate)}
            </FormHelperText>}
        </div>
        {renderButtons}
      </PaperBodySection>
    );
  }

  renderAccessCardDetailsAndButtons(accessCard) {
    const {
      Title,
      CardNumber,
    } = accessCard;

    const renderButtons = this.state.isMenuView ?
      this.renderAccessCardMenu(accessCard) :
      this.renderAccessCardButtons();

    return (
      <PaperBodySection>
        <div className={styles.bankDetailsContainer}>
          <div className={styles.icon}>
            <Icon fontSize='inherit'>credit_card</Icon>
          </div>
          <div>
            <div className={styles.bankDetails}>
              <p className={styles.bankName}>{Title}</p>
            </div>
            <p className={styles.paperBottomLabel}>{CardNumber}</p>
          </div>
        </div>
        {renderButtons}
      </PaperBodySection>
    );
  }

  renderBlankAccountCard = () => {
    return (
      <CardWithTitle className={styles.accountsOwnerSummary}>
        <div className={styles.paperContainer}>
          <PaperTitle><SkeletonLoader width={250} height={20} /></PaperTitle>
          <PaperBodyContainer padding='10px 20px'>
            <PaperBodyDivider />
            <PaperBodySection>
              <div className={styles.bankDetailsContainer}>
                <div className={styles.icon}>
                  <SkeletonLoader width={48} height={48} variant='rect' />
                </div>
                <div>
                  <div className={styles.bankDetails}>
                    <SkeletonLoader width={175} height={20} textVariant='body1' />
                  </div>
                  <div style={{ margin: '3px 0 0 3px' }}>
                    <SkeletonLoader width={200} height={35} textVariant='body1' />
                  </div>
                </div>
              </div>
            </PaperBodySection>
            <PaperBodyDivider />
          </PaperBodyContainer>
        </div>
      </CardWithTitle>
    );
  }

  renderPaymentAccounts = () => {
    const { groupedBankAccounts, accessCards, text: { Payments } } = this.props;
    const { isLoading, hasAccessCards, hasBankAccounts, accessCardEligible, } = this.state;

    return (
      <>
        <div className={styles.bankAccountsSummaryContainer}>
          {(hasBankAccounts) &&
            <div className={styles.addBankButtonContainer_hasAccounts}>
              <Button variant='contained' onClick={this.toggleShowAddBank}>{this.props.text.Payments.btn_add_bank}</Button>
            </div>
          }

          {/* BANK ACCOUNT SECTION */}
          {isLoading
            ? this.renderBlankAccountCard()
            : Object.keys(groupedBankAccounts).map(accountOwner => {
              const bankAccountGroup = groupedBankAccounts[accountOwner];
              return (
                <CardWithTitle key={bankAccountGroup[0].BeneficiaryId} className={styles.accountsOwnerSummary}>
                  <div className={styles.paperContainer}>
                    <PaperTitle>{accountOwner}</PaperTitle>
                    <PaperBodyContainer padding='10px 20px'>
                      {bankAccountGroup.map((bankAccount) => {
                        return (
                          <React.Fragment key={bankAccount.BankAccountId}>
                            <PaperBodyDivider />
                            {this.renderBankAccountDetailsAndButtons(bankAccount)}
                          </React.Fragment>
                        );
                      })}
                      <PaperBodyDivider />
                    </PaperBodyContainer>
                  </div>
                </CardWithTitle>
              );
            })}

          {hasBankAccounts &&
            <div className={styles.learnMoreButton_hasAccounts}>
              {this.renderLearnMoreButton()}
            </div>}

          {/* ACCESS CARD SECTION */}
          {hasAccessCards &&
            <CardWithTitle className={styles.accountsOwnerSummary}>
              <div className={styles.paperContainer}>
                <PaperTitle>{Payments.title_access_cards}</PaperTitle>
                <PaperBodyContainer padding='10px 20px'>
                  {accessCards.map(accessCard => {
                    return (
                      <React.Fragment key={accessCard.AccessCardId}>
                        <PaperBodyDivider />
                        {this.renderAccessCardDetailsAndButtons(accessCard)}
                      </React.Fragment>
                    );
                  })}
                  <PaperBodyDivider />
                  <div className={styles.accessCardReqsContainer}>
                    <strong>{Payments.text_requirements}</strong>
                    <ul className={styles.accessCardReqs}>
                      {Payments.text_access_card_reqs.map((req, index) => (
                        <li key={index}>{req}</li>
                      ))}
                    </ul>
                  </div>
                </PaperBodyContainer>
              </div>
            </CardWithTitle>}

          {hasAccessCards &&
            <div className={styles.learnMoreButton_hasAccounts}>
              <p>{Payments.txt_third_party_disclaimer}</p>
              {this.renderLearnMoreButton(true)}
            </div>}

          {!hasBankAccounts && !isLoading && this.renderNoBankAccounts()}
          {!hasAccessCards && !isLoading && accessCardEligible && this.renderNoAccessCards()}
        </div>
      </>
    );
  }

  initBankAccounts = (agent) => {
    const userDetails = {
      agentId: agent.AgentId,
      name: `${agent.FirstName} ${agent.MiddleName} ${agent.LastName}`
    };
    this.props.getAllowedBankHolders(userDetails, this.props.accountList);
    this.handleGetBankAccounts();

    const menuAnchorPoints = {};
    // Creates menu anchor point for every account, since each menu includes links to the individual bank account
    Object.keys(this.props.groupedBankAccounts).forEach(owner => {
      this.props.groupedBankAccounts[owner].forEach(bankAccount => {
        menuAnchorPoints[bankAccount.BankAccountId] = null;
      });
    });

    this.setState({ menuAnchorPoints });
  }

  componentDidMount() {
    const { agent, claims, } = this.props;
    const accessCardEligible = claims.AccessCardEligible === 'True';

    this.setState({ accessCardEligible });
    if (!agent.AgentId) {
      this.setState({ isLoading: true });
      this.props.agentGet()
        .then(() => {
          this.initBankAccounts(this.props.agent);
        })
        .finally(() => this.setState({ isLoading: false }));
    }
    else {
      this.initBankAccounts(agent);
    }
  }

  componentDidUpdate({ size }) {
    const { size: { windowWidth } } = this.props;
    if (size.windowWidth !== windowWidth) {
      this.setState({
        isMenuView: windowWidth < parseInt(tabletWidth),
        isMobileView: windowWidth < parseInt(mobileWidth),
      });
    }

    const multiStatus = protectAction(this.props.claims);
    if (this.state.hasPrecondition && multiStatus === MULTIFACTOR_TYPE.AUTH) {
      const funcName = this.state.post2FAAuthFunction;
      this[funcName](this.state.post2FABankAccount, this.state.post2FAAction);
    }
  }

  render() {
    const {
      environmentVars,
      text: { Payments, },
    } = this.props;
    const {
      showAddBankModal,
      showEditBankModal,
      showBankAccountsLearnMore,
      showAccessCardsLearnMore,
      showValidationStartModal,
      validationStarted,
      showValidationFinishModal,
      showAccessCardApplicationThirdPartyWarning,
      showGeneralThirdPartyWarning,
      selectedBankAccount,
      buttonIsLoading,
      accessCardURL,
    } = this.state;

    return (
      <div className={styles.manageBankAccountsContainer}>
        {this.renderPaymentAccounts()}

        <AddBankModal
          open={showAddBankModal}
          close={() => this.toggleShowAddBank()}
          isMobileView={this.state.isMobileView}
          handleGetBankAccounts={this.handleGetBankAccounts}
          handleShowGiactRejectionModal={() => this.setState({ showGiactRejectionModal: true })}
          handleShowValidationStart={(newBankAccount) => this.setState({ showValidationStartModal: true, validationStarted: false, selectedBankAccount: newBankAccount })}
        />
        <EditBankModal
          open={showEditBankModal}
          close={() => this.setState({ showEditBankModal: false, accountBeingEdited: {} })}
          isMobileView={this.state.isMobileView}
          bankAccount={this.state.accountBeingEdited}
        />
        <BankAccountsLearnMore
          environmentVars={environmentVars}
          onClose={this.toggleShowBankAccountsLearnMore}
          open={showBankAccountsLearnMore}
        />
        <AccessCardsLearnMore
          environmentVars={environmentVars}
          onClose={this.toggleShowAccessCardsLearnMore}
          open={showAccessCardsLearnMore}
        />
        <ConfirmModal
          modal={false}
          show={this.state.showCancelConfirmModal}
          title={Payments.head_remove_account}
          body={Payments.text_confirm_remove(selectedBankAccount.MaskedBankAccountNumber)}
          onModalClose={() => this.setState({ showCancelConfirmModal: false })}
          onConfirm={() => this.handleDeleteBankAccount()}
          isLoading={buttonIsLoading}
        />
        <ConfirmModal
          modal={false}
          show={this.state.showRenewConfirmModal}
          title={Payments.head_renew_account}
          body={Payments.text_confirm_renew(selectedBankAccount.MaskedBankAccountNumber)}
          onModalClose={() => this.setState({ showRenewConfirmModal: false })}
          onConfirm={() => this.handleRenewBankAccount()}
          isLoading={buttonIsLoading}
        />
        {this.renderHasPendingTransactionsModal()}
        <ValidationStartModal
          open={showValidationStartModal}
          selectedBankAccount={selectedBankAccount}
          isLoading={buttonIsLoading}
          validationStarted={validationStarted}
          startMicroDeposits={this.props.startMicroDeposits}
          handleGetBankAccounts={this.handleGetBankAccounts}
          handleShowValidationStart={() => this.setState({ showValidationStartModal: !this.state.showValidationStartModal })}
          handleValidationStarted={() => this.setState({ validationStarted: !this.state.validationStarted })}
          handleButtonIsLoading={() => this.setState({ buttonIsLoading: !this.state.buttonIsLoading })}
        />
        <ValidationFinishModal
          key={`${selectedBankAccount.BankAccountId}_${selectedBankAccount.VerificationAttemptsRemaining}`}
          open={showValidationFinishModal}
          handleShowValidationFinish={() => this.setState({ showValidationFinishModal: !showValidationFinishModal })}
          selectedBankAccount={selectedBankAccount}
          handleGetBankAccounts={this.handleGetBankAccounts}
        />
        <ThirdPartyWarningModal
          open={showAccessCardApplicationThirdPartyWarning}
          onClose={() => this.setState({ showAccessCardApplicationThirdPartyWarning: false })}
          eventLabel='Discover Access Cards' // for Google analytics
          thirdPartyURL={accessCardURL}
          imageSrc={OpenCPScreenshot}
          warningLanguage={Payments.txt_third_party_warning_access_cards}
          footer={Payments.txt_third_party_warning_access_cards_footer(OPENCP_URL)}
          maxWidth='lg'
        />
        <ThirdPartyWarningModal
          open={showGeneralThirdPartyWarning}
          onClose={() => this.setState({ showGeneralThirdPartyWarning: false })}
          eventLabel='Access Cards Homepage'
          thirdPartyURL={OPENCP_URL}
          warningLanguage={Payments.txt_third_party_warning_for_info}
        />
      </div>
    );
  }
}


export default connect(
  select,
  {
    getBankAccounts,
    getAccessCards,
    getGroupedBankAccounts,
    getAllowedBankHolders,
    deleteBankAccount,
    startMicroDeposits,
    renewBankAccount,
    poaApproval,
    getAccessCardURL,
    get2FAPrecondition,
    getRSAPrecondition,
    notificationShow,
    agentGet,
  }
)(sizify(LanguageHOC(Payments)));
