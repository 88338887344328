/*
*
* Documents Actions
*
*/
import {
  GETrequest,
  POSTrequest,
} from 'utils/helpers/api_handler';
import {
  DOCUMENTS_GET,
  ACCOUNT_DOCUMENT_DOWNLOAD_DATA,
  ACCOUNT_CERTIFICATE_NAME,
  COUNTRIES_LIST_GET,
} from './constants';

export function documentsGet() {
  const payload = GETrequest('/accounts/documents');

  return {
    type: DOCUMENTS_GET,
    payload,
  };
}

export function downloadAccountDocumentData(url) {
  const payload = GETrequest(url);

  return {
    type: ACCOUNT_DOCUMENT_DOWNLOAD_DATA,
    payload,
  };
}

export function updateAccountCertificateName(certificateFromName, accountId) {
  const payload = POSTrequest(`/accounts/${accountId}/documents/certificate?customName=${certificateFromName}`);

  return {
    type: ACCOUNT_CERTIFICATE_NAME,
    payload,
  };
}

export function getCountries() {
  const response = GETrequest('/Parameters/Countries');
  return {
    type: COUNTRIES_LIST_GET,
    payload: response,
  };
}