import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  IconBtnTooltip,
  notificationShow,
  LoadingOverlay
} from '@frontend/common';
import {
  Button,
  TextField,
  InputAdornment,
  Switch,
  Dialog,
  Slide
} from '@mui/material';

import { savePreferredMethod } from 'components/Features/protected/Multifactor/actions';
import { twoFaRouteChange } from 'components/AppRoot/Navigation/actions';

import Register from 'components/Features/protected/Multifactor/Registration/Register';
import RegisterAuthenticator from 'components/Features/protected/Multifactor/Registration/RegisterAuthenticator';

import { TWO_FACTOR_TYPES } from 'components/AppRoot/Navigation/constants';

import styles from './styles.module.scss';

const select = (state) => ({
  userDetails: state.session.userDetails,
  claims: state.session.claims,
});

export class MultifactorCard extends React.Component {
  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    savePreferredMethod: PropTypes.func.isRequired,
    twoFaRouteChange: PropTypes.func.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    claims: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_done: PropTypes.string,
        btn_edit: PropTypes.string,
        err_required: PropTypes.string,
        lbl_other: PropTypes.string,
        lbl_other_options: PropTypes.string,
        lbl_phone_number: PropTypes.string,
        lbl_present_2FA: PropTypes.string,
        nav_title: PropTypes.string,
      })
    }).isRequired,
  };

  static defaultProps = {
    hasTitle: true,
  };

  state = {
    isLoading: false,
    isAlwaysRequired: false,
    isPhoneDisabled: true,
    hasPhoneNumberChanged: false,
    originalPhoneNumber: '',
    phoneNumber: '',
    phoneNumberError: '',

    isRegisterOpen: false,
    isRegisterAuthenticatorOpen: false,

    hasPrecondition: false,
    savedFunctionName: ''
  };

  toggleRegisteredDevice() {
    this.setState({ isLoading: true });
    const params = {
      preferredMethod: this.props.preferredInfo.PreferredMethod,
      alwaysRequired: !this.state.isAlwaysRequired
    };
    this.props.savePreferredMethod(params)
      .then(() => {
        this.setState({
          isLoading: false,
          isAlwaysRequired: !this.state.isAlwaysRequired
        });
      });
  }

  componentDidMount() {
    const { PhoneNumber, AlwaysRequired } = this.props.preferredInfo;
    this.setState({
      originalPhoneNumber: PhoneNumber,
      phoneNumber: PhoneNumber,
      isAlwaysRequired: AlwaysRequired
    });
    this.props.twoFaRouteChange(window.location.pathname); // sets user's current route so they can return once 2fa is complete - important for SSup

  }

  render() {
    const {
      isAlwaysRequired, isPhoneDisabled, phoneNumber, isLoading,
      isRegisterOpen, isRegisterAuthenticatorOpen
    } = this.state;
    const { text: { Multifactor } } = this.props;
    const phone = `(***) ***-${phoneNumber.substr(phoneNumber.length - 4, phoneNumber.length)}`;

    return (
      <React.Fragment>
        <LoadingOverlay show={isLoading}>
          <div className={styles.container}>

            <div className={styles.phone}>
              <TextField
                disabled={isPhoneDisabled}
                label={Multifactor.lbl_phone_number}
                value={phone}
                fullWidth
                style={{ paddingBottom: '15px' }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>
                    <IconBtnTooltip
                      icon='edit'
                      onClick={() => this.setState({ isRegisterOpen: true })}
                      title={Multifactor.btn_edit}
                    />
                  </InputAdornment>,
                }}
              />
            </div>

            <div className={styles.login}>{Multifactor.lbl_present_2FA}</div>
            <div className={styles.toggle}>
              <Switch
                checked={isAlwaysRequired}
                onChange={() => this.toggleRegisteredDevice()}
                value={isAlwaysRequired}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                style={{ marginBottom: '15px' }}
              />
            </div>
            <div className={styles.OtherOptions}>{Multifactor.lbl_other_options}</div>
            <div className={styles.authenticator}>
              <Button
                color='primary'
                variant='contained'
                onClick={() => this.setState({ isRegisterAuthenticatorOpen: true })}
              >
                {Multifactor.lbl_other}
              </Button>
            </div>
          </div>
        </LoadingOverlay>

        <Dialog open={isRegisterOpen} onClose={null} TransitionComponent={Transition}>
          <Register
            registerType={TWO_FACTOR_TYPES.UPDATE}
            closeWindow={() => this.setState({ isRegisterOpen: false })}
            hideAuthenticatorButton={true}
          />
        </Dialog>

        <Dialog open={isRegisterAuthenticatorOpen} onClose={null} TransitionComponent={Transition}>
          <RegisterAuthenticator
            registerType={TWO_FACTOR_TYPES.UPDATE}
            closeWindow={() => this.setState({ isRegisterAuthenticatorOpen: false })}
            hideAnotherMethodButton={true}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction='up' ref={ref} {...props} />;
});

export default withRouter(connect(select, {
  notificationShow,
  savePreferredMethod,
  twoFaRouteChange,
})(LanguageHOC(MultifactorCard)));