/*
*
* AddBankModal Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
} from '@mui/material';
import {
  Modal,
  notificationShow,
} from '@frontend/common';
import {
  checkRoutingNumber,
  createAgentBankAccount,
  createBeneficiaryBankAccount,
} from 'components/Features/protected/Payments/actions';

import GiactRejectionModal from '../GiactRejectionModal';
import checkImage from './image/BankCheck.svg';

import styles from './styles.module.scss';

const select = (state) => ({
  allowedBankHolders: state.payments.allowedBankHolders,
  routingNumberBank: state.payments.routingNumberBank,
  GiactResultTypes: state.static.environmentVars.GiactResultTypes,
});

export class AddBankModal extends React.Component {
  static propTypes = {
    agentOnly: PropTypes.bool, //used for transactions only
    routingNumberBank: PropTypes.string.isRequired,
    allowedBankHolders: PropTypes.array.isRequired,
    GiactResultTypes: PropTypes.object.isRequired,
    notificationShow: PropTypes.func.isRequired,
    checkRoutingNumber: PropTypes.func.isRequired,
    createAgentBankAccount: PropTypes.func.isRequired,
    createBeneficiaryBankAccount: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    isMobileView: PropTypes.bool.isRequired,
    handleGetBankAccounts: PropTypes.func.isRequired,
    handleShowValidationStart: PropTypes.func,
    text: PropTypes.shape({
      AddBankModal: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_agree: PropTypes.string,
        err_invalid_routing: PropTypes.string,
        err_copy: PropTypes.string,
        err_required: PropTypes.string,
        err_routing_length: PropTypes.string,
        err_accounts_match: PropTypes.string,
        err_allowable_chars: PropTypes.string,
        head_modal_title: PropTypes.string,
        lbl_account_holder: PropTypes.string,
        lbl_account_number: PropTypes.string,
        lbl_nickname: PropTypes.string,
        lbl_confirm_account_number: PropTypes.string,
        lbl_checking: PropTypes.string,
        lbl_routing: PropTypes.string,
        lbl_savings: PropTypes.string,
        msg_account_added: PropTypes.string,
        text_required_language_1: PropTypes.string,
        text_required_language_2: PropTypes.string,
        text_required_language_3: PropTypes.string,
        text_required_language_4: PropTypes.string,
        text_required_language_5: PropTypes.string,
        text_permission_bank_account_holder: PropTypes.string,
      })
    })
  }

  state = {
    newBankAccount: {
      accountHolder: '',
      accountType: '',
      routingNumber: '',
      accountNumber1: '',
      accountNumber2: '',
      nickname: '',
    },
    routingNumberBank: '',
    formErrors: {},
    apiErrors: [],
    isSaving: false,
    showGiactRejectionModal: false,
    permissionBankAccountHolderText: ''
  }

  inputFormat = (value) => {
    return value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '');
  }

  onInputChange = e => {
    const { name, value } = e.target;

    this.setState({
      formErrors: {
        ...this.state.formErrors,
        [name]: ''
      },
      newBankAccount: {
        ...this.state.newBankAccount,
        [name]: value,
      },
      apiErrors: []
    });

    // checking routing number is valid
    if (name === 'routingNumber') {
      if (value.length !== 9 && this.props.routingNumberBank) {
        this.setState({ routingNumberBank: '' });
      }
      if (value.length === 9) {
        this.props.checkRoutingNumber(value)
          .then(() => this.setState({ routingNumberBank: this.props.routingNumberBank, apiErrors: [] }))
          .catch((errors) => this.setState({
            routingNumberBank: '',
            apiErrors: errors.payload.data,
            formErrors: { ...this.state.formErrors, routingNumber: errors.payload.data[0].Message }
          }));
      }
    }

    // getting language after acct holder selection
    if (name === 'accountHolder') {
      const isBeneficiary = this.props.allowedBankHolders.find(bene => bene.id === value).isBeneficiary;
      this.setState({ permissionBankAccountHolderText: isBeneficiary ? this.props.text.AddBankModal.text_permission_bank_account_holder : '' });
    }
  }

  copyPrevent = (e) => {
    const confirmField = e.target;

    confirmField.onpaste = e => {
      const { formErrors } = this.state;
      e.preventDefault();
      formErrors.accountNumber2 = this.props.text.AddBankModal.err_copy;
      this.setState({ formErrors });
    };
  }

  formValidate = () => {
    const { err_required, err_routing_length, err_accounts_match, err_allowable_chars } = this.props.text.AddBankModal;
    const { newBankAccount: { accountHolder, accountType, routingNumber, accountNumber1, accountNumber2, nickname }, apiErrors } = this.state;
    const formErrors = {};

    // Account holder validation
    if (!accountHolder) {
      formErrors.accountHolder = err_required;
    }
    // Account type validation
    if (!accountType) {
      formErrors.accountType = err_required;
    }
    // Routing number validation
    if (!routingNumber) {
      formErrors.routingNumber = err_required;
    }
    if (routingNumber.length !== 9 && routingNumber.length > 0) {
      formErrors.routingNumber = err_routing_length;
    }
    // Account number validation
    if (!accountNumber1) {
      formErrors.accountNumber1 = err_required;
    }
    if (!accountNumber2) {
      formErrors.accountNumber2 = err_required;
    }
    if (accountNumber1 !== accountNumber2) {
      formErrors.accountNumber2 = err_accounts_match;
    }

    const regex = /^[A-Za-z0-9.\s.,':#/-]+$/; // case insensitive, space, period, comma, single quote and hyphen.
    if (nickname && !regex.test(nickname)) {
      formErrors.nickname = err_allowable_chars;
    }

    // API errors
    if (apiErrors.length > 0) {
      apiErrors.forEach(error => {
        if (error.Field === 'BankAccountNumber') {
          formErrors.accountNumber1 = error.Message;
        }

        if (error.Field === 'routingNumber') {
          formErrors.routingNumber = error.Message;
        }
      });
    }

    this.setState({ formErrors });

    return Object.keys(formErrors).length === 0; // returns True or False whether form is valid
  }

  onFormSubmit = e => {
    e.preventDefault();

    if (this.formValidate()) {
      this.setState({ isSaving: true, apiErrors: [] });
      const { allowedBankHolders, GiactResultTypes, text: { AddBankModal } } = this.props;
      const { newBankAccount } = this.state;

      const apiNewBankAccountInfo = {
        RoutingNumber: newBankAccount.routingNumber,
        BankAccountNumber: newBankAccount.accountNumber1,
        BankAccountType: newBankAccount.accountType,
        Nickname: newBankAccount.nickname,
      };

      // Check if accountHolder (id) matches the first id of the allowedBankHolders to see if it is the Agent or not.
      const isAgent = newBankAccount.accountHolder === allowedBankHolders[0].id;
      const createNewBankAccount = isAgent ?
        this.props.createAgentBankAccount :
        this.props.createBeneficiaryBankAccount;

      createNewBankAccount(apiNewBankAccountInfo, newBankAccount.accountHolder)
        .then(response => {
          const newBankAccount = response.payload.data;
          const GiactResult = newBankAccount.GiactResult;
          this.props.handleGetBankAccounts();
          this.closeModal();
          if (GiactResult === GiactResultTypes.Fail) {
            this.setState({ showGiactRejectionModal: true });
            return;
          }
          else if (GiactResult === GiactResultTypes.NA && this.props.handleShowValidationStart) { // check for ValidationStart function, since this is only handled on Payments page.
            this.props.handleShowValidationStart(newBankAccount);
          }
          this.props.notificationShow(AddBankModal.msg_account_added, 'success');
        })
        .catch(errors => {
          this.setState({
            apiErrors: errors.payload.data,
            isSaving: false
          }, () => this.formValidate());
        });
    }
  }

  closeModal = () => {
    const accountHolder = this.props.agentOnly ? this.props.allowedBankHolders[0].id : '';
    this.props.close();
    this.setState({
      newBankAccount: {
        accountHolder,
        accountType: '',
        routingNumber: '',
        accountNumber1: '',
        accountNumber2: '',
      },
      routingNumberBank: '',
      formErrors: {},
      isSaving: false,
    });
  }

  renderRequiredLanguage = () => {
    const { AddBankModal } = this.props.text;

    return (
      <div className={styles.AddBank_requiredLanguage}>
        <ul>
          <li>{AddBankModal.text_required_language_1}</li>
          <li>{AddBankModal.text_required_language_2}</li>
          <li>{AddBankModal.text_required_language_3}</li>
          <li>{AddBankModal.text_required_language_4}</li>
        </ul>
        <p>{AddBankModal.text_required_language_5}</p>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.agentOnly) {
      this.setState({ newBankAccount: { ...this.state.newBankAccount, accountHolder: this.props.allowedBankHolders[0].id } });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      this.setState({ permissionBankAccountHolderText: '' });
    }
  }

  render() {
    const { newBankAccount, formErrors, isSaving, permissionBankAccountHolderText } = this.state;
    const { agentOnly, open, isMobileView, allowedBankHolders, text: { AddBankModal } } = this.props;

    return (
      <React.Fragment>
        <Modal
          show={open}
          maxWidth='lg'
          fullWidth
          title={AddBankModal.head_modal_title}
          onCloseModal={this.closeModal}
          DialogProps={{ 'aria-labelledby': AddBankModal.head_modal_title, 'fullScreen': isMobileView }}
          includeLeftCancelBtn
          actionButtons={[
            {
              action: this.onFormSubmit,
              label: AddBankModal.btn_agree,
              disabled: isSaving,
              loading: isSaving,
            },
          ]}
        >
          <div className={styles.addBankModalContainer}>
            <div className={styles.AddBank_formAndCheckContainer}>
              <div className={styles.AddBank_accountForm}>
                <TextField
                  disabled={isSaving || agentOnly}
                  error={Boolean(formErrors.accountHolder)}
                  helperText={formErrors.accountHolder}
                  variant='filled'
                  fullWidth
                  label={AddBankModal.lbl_account_holder}
                  name='accountHolder'
                  onChange={this.onInputChange}
                  value={newBankAccount.accountHolder}
                  select
                >
                  {allowedBankHolders.map(accountHolder => (
                    <MenuItem key={accountHolder.id} value={accountHolder.id}>
                      {accountHolder.name}
                    </MenuItem>
                  ))}
                </TextField>
                {Boolean(permissionBankAccountHolderText) &&
                  <div style={{ fontSize: '0.75rem', minHeight: '1em', textAlign: 'left', fontFamily: 'Roboto, PT Sans, Arial, sans-serif', fontWeight: '400', lineHeight: '1em', color: 'grey' }}>
                    {permissionBankAccountHolderText}
                  </div>
                }

                <FormControl component='fieldset'>
                  <RadioGroup
                    aria-label='position'
                    name='accountType'
                    value={newBankAccount.accountType}
                    onChange={this.onInputChange}
                    row
                  >
                    <FormControlLabel
                      value='Checking'
                      control={<Radio color='primary' />}
                      label={AddBankModal.lbl_checking}
                      labelPlacement='end'
                      disabled={isSaving}
                    />
                    <FormControlLabel
                      value='Savings'
                      control={<Radio color='primary' />}
                      label={AddBankModal.lbl_savings}
                      labelPlacement='end'
                      disabled={isSaving}
                    />
                  </RadioGroup>
                  {formErrors.accountType && <FormHelperText error={Boolean(formErrors.accountType)}>{formErrors.accountType}</FormHelperText>}
                </FormControl>
                <TextField
                  disabled={isSaving}
                  error={Boolean(formErrors.routingNumber)}
                  variant='filled'
                  fullWidth
                  helperText={formErrors.routingNumber || this.state.routingNumberBank}
                  label={AddBankModal.lbl_routing}
                  name='routingNumber'
                  onInput={e => e.target.value = this.inputFormat(e.target.value)}
                  onChange={this.onInputChange}
                  value={newBankAccount.routingNumber}
                  inputProps={{ maxLength: '9' }}
                />
                <TextField
                  disabled={isSaving}
                  error={Boolean(formErrors.accountNumber1)}
                  variant='filled'
                  fullWidth
                  helperText={formErrors.accountNumber1}
                  label={AddBankModal.lbl_account_number}
                  name='accountNumber1'
                  onInput={e => e.target.value = this.inputFormat(e.target.value)}
                  onChange={this.onInputChange}
                  value={newBankAccount.accountNumber1}
                  inputProps={{ maxLength: '17' }}
                />
                <TextField
                  htmlFor='confirmedBankAccountNum'
                  disabled={isSaving}
                  error={Boolean(formErrors.accountNumber2)}
                  variant='filled'
                  fullWidth
                  helperText={formErrors.accountNumber2}
                  label={AddBankModal.lbl_confirm_account_number}
                  name='accountNumber2'
                  onInput={e => e.target.value = this.inputFormat(e.target.value)}
                  onChange={this.onInputChange}
                  onFocus={(e) => this.copyPrevent(e)}
                  value={newBankAccount.accountNumber2}
                  inputProps={{ maxLength: '17' }}
                />
                <TextField
                  disabled={isSaving}
                  error={Boolean(formErrors.nickname)}
                  variant='filled'
                  fullWidth
                  helperText={formErrors.nickname}
                  label={AddBankModal.lbl_nickname}
                  name='nickname'
                  onChange={this.onInputChange}
                  value={newBankAccount.nickname}
                  inputProps={{ maxLength: '40' }}
                />
              </div>

              <img src={checkImage} className={styles.AddBank_checkImage} alt='check' />
            </div>

            {this.renderRequiredLanguage()}

          </div>
        </Modal>

        <GiactRejectionModal
          open={this.state.showGiactRejectionModal}
          handleCloseGiactRejectionModal={() => this.setState({ showGiactRejectionModal: false })}
        />
      </React.Fragment>
    );
  }
}

export default connect(select, {
  checkRoutingNumber,
  createAgentBankAccount,
  createBeneficiaryBankAccount,
  notificationShow,
})(LanguageHOC(AddBankModal));