/*
*
* InterestedParties Constants
*
*/
export const ADD_INTERESTED_PARTY = 'src/components/Features/protected/InterestedParties/ADD_INTERESTED_PARTY';
export const CREATE_INTERESTED_PARTY = 'src/components/Features/protected/InterestedParties/CREATE_INTERESTED_PARTY';
export const DELETE_INTERESTED_PARTY = 'src/components/Features/protected/InterestedParties/DELETE_INTERESTED_PARTY';
export const EDIT_INTERESTED_PARTY = 'src/components/Features/protected/InterestedParties/EDIT_INTERESTED_PARTY';
export const GET_INTERESTED_PARTIES = 'src/components/Features/protected/InterestedParties/GET_INTERESTED_PARTIES';
export const GET_IP_ACTIVATION_INFO = 'src/components/Features/protected/InterestedParties/GET_IP_ACTIVATION_INFO';
export const RESEND_INTERESTED_PARTY_EMAIL = 'src/components/Features/protected/InterestedParties/RESEND_INTERESTED_PARTY_EMAIL';