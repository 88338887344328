import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { Modal } from '@frontend/common';

export const FullBalanceDialog = ({ show, onClose, text: { Transfers } }) => (
  <Modal
    title={Transfers.title_full_balance}
    show={show}
    onCloseModal={onClose}
    modal={false}
  >
    {Transfers.text_full_balance_info()}
  </Modal>
);

FullBalanceDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.shape({
    Transfers: PropTypes.shape({
      btn_close: PropTypes.string,
      title_full_balance: PropTypes.string,
      text_full_balance_info: PropTypes.func,
    })
  })
};

export default LanguageHOC(FullBalanceDialog);
