/*
*
* DualPushButtonSwitch Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';

import styles from './styles.module.scss';

// eslint-disable-next-line react/prop-types
function OptionButton({ label, value, active, disabled, onChange, style }) {
  return (
    <Button
      variant='contained'
      style={{
        color: active ? '#FFFFFF' : 'var(--accent)',
        backgroundColor: active ? 'var(--accent)' : '#FFFFFF',
        ...style,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      }}
      disabled={disabled}
      className={active ? 'active' : undefined}
      onClick={() => onChange(value)}
    >
      {label}
    </Button>
  );
}

DualPushButtonSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  groupLabel: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    radioLabel: PropTypes.string,
  })).isRequired,
};

export function DualPushButtonSwitch({ onChange, options = {} }) {
  return (
    <div className={styles.DualPushButtonSwitch_container}>
      <div className='pushButton_container 1'>
        <OptionButton
          {...options[0]}
          onChange={onChange}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
      </div>
      <div className='pushButton_container 2'>
        <OptionButton
          {...options[1]}
          onChange={onChange}
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
      </div>
    </div>
  );
}

export default DualPushButtonSwitch;
