/*
*
* AccessCardBanner Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import { IconBtnTooltip, } from '@frontend/common';

import accessCardBannerImage from './AccessCardBanner.png';
import mobileAccessCardBannerImage from './MobileAccessCardBanner.png';

import { tabletWidth } from 'utils/config/_sassconfig.scss';
import styles from './styles.module.scss';

export class AccessCardBanner extends React.Component {

  static propTypes = {
    show: PropTypes.bool,
    onCloseBanner: PropTypes.func.isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number,
    }).isRequired,
    text: PropTypes.shape({
      Payments: PropTypes.shape({
        nav_path: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  state = {
    isMobileView: false,
  }

  componentDidMount() {
    this.setState({ isMobileView: this.props.size.windowWidth < parseInt(tabletWidth) });
  }

  componentDidUpdate({ size }) {
    const { size: { windowWidth }, } = this.props;

    if (size.windowWidth !== windowWidth) {
      this.setState({
        isMobileView: windowWidth < parseInt(tabletWidth),
      });
    }
  }

  render() {
    const { onCloseBanner, history, text: { Payments }, show } = this.props;
    const { isMobileView } = this.state;

    return (
      show &&
      <div className={styles.bannerContainer}>
        <div className={styles.imageContainer}>
          <img
            className={styles.bannerImage}
            src={isMobileView ? mobileAccessCardBannerImage : accessCardBannerImage}
            alt='banner'
            onClick={() => history.push(Payments.nav_path)}
          />
          <div className={styles.closeButton}>
            <IconBtnTooltip
              icon={'close'}
              onClick={(event) => {
                event.stopPropagation();
                onCloseBanner();
              }}
              onFocus={event => event.stopPropagation()}
            />
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(LanguageHOC(AccessCardBanner));