
/*
*
* Sidenav
*
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { greetingGenerator } from 'utils/helpers/greeting_generator';

import {
  notificationShow,
  sizify,
} from '@frontend/common';

import {
  Divider,
  Drawer,
  Hidden,
  SwipeableDrawer,
} from '@mui/material';

import { withStyles, } from '@mui/styles';

import {
  changeLanguage,
  clearStore,
  toggleSidenav,
  userLogout,
} from '../actions';

import SidenavMenuOption from './SidenavMenuOption';

import { ROUTE_ACCESS } from 'components/AppRoot/Navigation/RequiredAccess.js';
import { desktopWidth } from 'utils/config/_sassconfig.scss';

import styles from './styles.module.scss';

const select = (state) => ({
  accountBlocked: state.session.accountBlocked,
  uiPermissions: state.accounts.uiPermissions,
  faxNumber: state.static.environmentVars.FaxNumber,
  isValid: state.session.isValid,
  language: state.session.language,
  phoneNumber: state.static.environmentVars.SupportPhoneNumber,
  supportEmail: state.static.environmentVars.SupportEmail,
  sidenavOpen: state.session.sidenavOpen,
  userAccess: state.session.userAccess,
  userDetails: state.session.userDetails,
  documents: state.static.documents ? state.static.documents : {},
  disableLeftNavigationMenu: state.session.disableLeftNavigationMenu,
});

const muiStyles = {
  sidenavOpen: {
    overflowX: 'visible',
    transition: 'all .5s ease',
    width: '250px',
  },
  sidenavMini: {
    overflowX: 'hidden',
    transition: 'width .5s ease',
  },
};

export class Sidenav extends Component {

  static propTypes = {
    accountBlocked: PropTypes.bool.isRequired,
    changeLanguage: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    clearStore: PropTypes.func.isRequired,
    faxNumber: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    notificationShow: PropTypes.func.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    supportEmail: PropTypes.string.isRequired,
    sidenavOpen: PropTypes.bool.isRequired,
    size: PropTypes.shape({
      windowHeight: PropTypes.number,
    }).isRequired,
    documents: PropTypes.object,
    text: PropTypes.shape({
      Accounts: PropTypes.shape({ nav_title: PropTypes.string, nav_path: PropTypes.string }),
      Documents: PropTypes.shape({ nav_title: PropTypes.string, nav_path: PropTypes.string }),
      Gifting: PropTypes.shape({ nav_title: PropTypes.string, nav_path: PropTypes.string }),
      Home: PropTypes.shape({ nav_title: PropTypes.string, nav_path: PropTypes.string }),
      Login: PropTypes.shape({ msg_you_have_logged_out: PropTypes.string }),
      MyInfo: PropTypes.shape({ nav_title: PropTypes.string, nav_path: PropTypes.string }),
      Navigation: PropTypes.shape({
        menu_english: PropTypes.string,
        menu_log_out: PropTypes.string,
        menu_profile: PropTypes.string,
        menu_spanish: PropTypes.string,
        text_previous_login: PropTypes.string,
        text_version: PropTypes.string,
      }),
      Payments: PropTypes.shape({ nav_title: PropTypes.string, nav_path: PropTypes.string }),
      PayrollContribution: PropTypes.shape({ nav_title: PropTypes.string, nav_path: PropTypes.string }),
      Sidenav: PropTypes.shape({
        head_account_owner: PropTypes.string,
        head_need_help: PropTypes.string,
        text_fax: PropTypes.string,
        text_loading: PropTypes.string,
        text_phone: PropTypes.string,
        text_previous_login: PropTypes.string,
        btn_cancel: PropTypes.string,
        resources: PropTypes.shape({
          title: PropTypes.string,
          lbl_program_description: PropTypes.string,
          lbl_forms: PropTypes.string,
          lbl_performance: PropTypes.string,
          lbl_college_planning_center: PropTypes.string,
          lbl_faqs: PropTypes.string,
          title_third_party_warning: PropTypes.string,
        }),
        text_timezone: PropTypes.string,
      }),
      Lpoa: PropTypes.shape({
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
      }),
      InterestedParties: PropTypes.shape({
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
      }),
    }).isRequired,
    toggleSidenav: PropTypes.func.isRequired,
    uiPermissions: PropTypes.shape({
      canAddInterestedParties: PropTypes.bool,
      canManageBankAccounts: PropTypes.bool,
      giftingEnabled: PropTypes.bool,
      noBeneAccounts: PropTypes.bool,
    }).isRequired,
    userAccess: PropTypes.string.isRequired,
    userLogout: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    disableLeftNavigationMenu: PropTypes.bool.isRequired,
  };

  state = {
    hasScrollBar: false,
    nestedMenuOpen: false,
    scrollBarWidth: 0, // needed when browser scale or screen resolution changed also changes scrollbar width
    languageOptionsShow: false,
  };

  languageChangeHandle = (language) => {
    this.props.changeLanguage(language);
    this.props.history.push(this.props.text.Home.nav_path);
    this.setState({ languageOptionsShow: false });
    window.innerWidth < parseInt(desktopWidth) && this.props.toggleSidenav(false); // only close menu on tablet and smaller
  }

  logOut = () => {
    const { text } = this.props;
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .finally(() => this.props.notificationShow(text.Login.msg_you_have_logged_out, 'success'));
    this.props.clearStore();
    this.props.toggleSidenav(false);
  };

  menuSelectHandle = path => {
    window.innerWidth < parseInt(desktopWidth) && this.props.toggleSidenav(false); // only close menu on tablet and smaller
    this.props.history.push(path);
  }

  renderSidenavContents = () => {
    const {
      faxNumber,
      phoneNumber,
      supportEmail,
      sidenavOpen,
      uiPermissions: { canAddInterestedParties, canManageBankAccounts, giftingEnabled, noBeneAccounts },
      text: {
        Navigation,
        Sidenav,
        MyInfo,
        Accounts,
        Payments,
        Documents,
        Gifting,
        Home,
        InterestedParties,
        Lpoa,
        PayrollContribution,
      },
      userDetails,
      documents,
      userAccess,
      toggleSidenav,
      disableLeftNavigationMenu,
    } = this.props;

    return (
      <React.Fragment>

        <div className={styles.sideNavContainer}>
          <div className={styles.topMenu} id='topMenu'>
            <div className={styles.hamburger}>
              <SidenavMenuOption
                name={''} // there was an issue the tooltip would freeze and stay
                onClick={() => toggleSidenav(!sidenavOpen)}
                rootMenuOptionIconName='menu'
                toggleSidenav={toggleSidenav}
                sidenavOpen={sidenavOpen}
              />
            </div>

            <div className={styles.greetings}>
              <div className={sidenavOpen ? styles.aoName : styles.hide}>
                <h3>{`${greetingGenerator()},`}</h3>
                <p>{userDetails.name}</p>
              </div>
            </div>

            <SidenavMenuOption
              name={Home.nav_title}
              link={Home.nav_path}
              rootMenuOptionIconName='home'
              visible={ROUTE_ACCESS.Home.includes(userAccess)}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              disabled={disableLeftNavigationMenu}
            />

            <SidenavMenuOption
              name={Accounts.nav_title}
              link={Accounts.nav_path}
              rootMenuOptionIconName='people'
              visible={ROUTE_ACCESS.Accounts.includes(userAccess)}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              disabled={disableLeftNavigationMenu}
            />

            <SidenavMenuOption
              name={'Transactions'}
              link={'/transactions-hub'}
              rootMenuOptionIconName='attach_money'
              visible={ROUTE_ACCESS.TransactionsHub.includes(userAccess) && !noBeneAccounts}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              disabled={disableLeftNavigationMenu}
            />

            <SidenavMenuOption
              name={Documents.nav_title}
              link={Documents.nav_path}
              rootMenuOptionIconName='description'
              visible={ROUTE_ACCESS.Documents.includes(userAccess)}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              disabled={disableLeftNavigationMenu}
            />

            <SidenavMenuOption
              name={Payments.nav_title}
              link={Payments.nav_path}
              rootMenuOptionIconName='account_balance'
              visible={ROUTE_ACCESS.Payments.includes(userAccess) && canManageBankAccounts}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              disabled={disableLeftNavigationMenu}
            />

            <SidenavMenuOption
              name={Gifting.nav_title}
              link={Gifting.nav_path}
              rootMenuOptionIconName='card_giftcard'
              visible={ROUTE_ACCESS.Gifting.includes(userAccess) && giftingEnabled}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              disabled={disableLeftNavigationMenu}
            />

            <SidenavMenuOption
              name={PayrollContribution.nav_title}
              link={PayrollContribution.nav_path}
              rootMenuOptionIconName='account_balance_wallet'
              visible={ROUTE_ACCESS.PayrollContribution.includes(userAccess)}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              disabled={disableLeftNavigationMenu}
            />

            <SidenavMenuOption
              name={Lpoa.nav_title}
              link={Lpoa.nav_path}
              rootMenuOptionIconName='gavel'
              visible={ROUTE_ACCESS.Lpoa.includes(userAccess)}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              disabled={disableLeftNavigationMenu}
            />

            <SidenavMenuOption
              name={InterestedParties.nav_title}
              link={InterestedParties.nav_path}
              rootMenuOptionIconName='supervised_user_circle'
              visible={ROUTE_ACCESS.InterestedParties.includes(userAccess) && canAddInterestedParties}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              disabled={disableLeftNavigationMenu}
            />
          </div>

          <div className={styles.bottomMenu}>
            <div className={styles.divider}>
              <Divider />
            </div>

            <SidenavMenuOption
              name={Navigation.menu_log_out}
              onClick={this.logOut}
              rootMenuOptionIconName='power_settings_new'
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
            />

            <SidenavMenuOption
              name={MyInfo.nav_title}
              link={MyInfo.nav_path}
              rootMenuOptionIconName='settings'
              visible={ROUTE_ACCESS.Home.includes(userAccess)}
              toggleSidenav={toggleSidenav}
              sidenavOpen={sidenavOpen}
              disabled={disableLeftNavigationMenu}
            />

            {/* TRANSLATIONS uncomment below menu items to activate language selection in Sidenav */}
            {/* <SidenavMenuOption
              name={'Language'}
              rootMenuOptionIconName='language'
              sidenavOpen={sidenavOpen}
              setNestedMenuOpen={() => this.setState({ languageOptionsShow: !this.state.languageOptionsShow })}
              toggleSidenav={toggleSidenav}
            >
              <SidenavMenuOption
                name={Navigation.menu_english}
                rootMenuOptionIconName='flag'
                onClick={() => this.languageChangeHandle('en')}
                sidenavOpen={sidenavOpen}
              />
              <SidenavMenuOption
                name={Navigation.menu_spanish}
                rootMenuOptionIconName='outlined_flag'
                onClick={() => this.languageChangeHandle('es')}
                sidenavOpen={sidenavOpen}
              />
            </SidenavMenuOption> */}

            <SidenavMenuOption
              name={Sidenav.resources.title}
              rootMenuOptionIconName='link'
              sidenavOpen={sidenavOpen}
              setNestedMenuOpen={(nestedMenuOpen) => this.setState({ nestedMenuOpen })}
              toggleSidenav={toggleSidenav}
            >
              <SidenavMenuOption
                name={Sidenav.resources.lbl_program_description}
                externalLink={documents.programDescription ? documents.programDescription : ''}
                rootMenuOptionIconName='article'
                sidenavOpen={sidenavOpen}
                toggleSidenav={toggleSidenav}
              />
              <SidenavMenuOption
                name={Sidenav.resources.lbl_forms}
                externalLink='https://my529.org/how-to-save/forms-documents/'
                rootMenuOptionIconName='assignment'
                sidenavOpen={sidenavOpen}
                toggleSidenav={toggleSidenav}
              />
              <SidenavMenuOption
                name={Sidenav.resources.lbl_performance}
                externalLink='http://www.my529.org/performance-returns/'
                rootMenuOptionIconName='show_chart'
                sidenavOpen={sidenavOpen}
                toggleSidenav={toggleSidenav}
              />
              <SidenavMenuOption
                name={Sidenav.resources.lbl_faqs}
                externalLink='https://my529.org/faq/'
                rootMenuOptionIconName='question_answer'
                sidenavOpen={sidenavOpen}
                toggleSidenav={toggleSidenav}
              />
            </SidenavMenuOption>

            <div className={sidenavOpen ? styles.bottom : styles.hide}>
              <div className={styles.helpContainer}>
                <div className={styles.version}>
                  <h5>{Navigation.text_version} {window.appVersion}</h5>
                </div>
                <h4>{Sidenav.head_need_help}</h4>
                <div className={styles.bottom}>
                  <p><a href={`mailto:${supportEmail}`}>{supportEmail}</a></p>
                  <p>{Sidenav.text_phone}&nbsp;{phoneNumber ? <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> : Sidenav.text_loading}</p>
                  <p>{Sidenav.text_fax}&nbsp;{faxNumber ? faxNumber : Sidenav.text_loading}</p>
                </div>
                {userDetails.previousLogin &&
                  <div className={styles.previousLoginContainer}>
                    <h5>{Sidenav.text_previous_login} </h5>
                    <p>{userDetails.previousLogin} {Sidenav.text_timezone}</p>
                  </div>
                }
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { sidenavOpen, size: { windowHeight } } = this.props;
    const drawerEl = document.getElementById('drawer');
    const topMenuEl = document.getElementById('topMenu');
    const hasDrawerScrollBar = Boolean(drawerEl) && drawerEl.firstChild.scrollHeight > drawerEl.firstChild.clientHeight;
    const hasTopMenuScrollBar = Boolean(topMenuEl) && topMenuEl.scrollHeight > topMenuEl.clientHeight; // determines presence of a vertical scroll bar
    const hasScrollBar = hasDrawerScrollBar || hasTopMenuScrollBar;

    if (
      windowHeight !== prevProps.size.windowHeight
      || sidenavOpen !== prevProps.sidenavOpen
      || hasScrollBar !== this.state.hasScrollBar
      || prevState.nestedMenuOpen !== this.state.nestedMenuOpen // this would not catch when nested menu is open/closed so introduced new state for nested menu open
    ) {
      const drawerScrollBarWidth = drawerEl ? drawerEl.firstChild.offsetWidth - drawerEl.firstChild.clientWidth : 0;
      const topMenuScrollBarWidth = topMenuEl ? topMenuEl.offsetWidth - topMenuEl.clientWidth : 0;
      const sumOfBarWidths = drawerScrollBarWidth + topMenuScrollBarWidth;
      const scrollBarWidth = sumOfBarWidths > 0 ? sumOfBarWidths : this.state.scrollBarWidth;
      this.setState({ hasScrollBar, scrollBarWidth });
    }
  }

  render() {
    const { accountBlocked, isValid, sidenavOpen } = this.props;
    const sidenavIsVisible = !accountBlocked && isValid && sidenavOpen; // hide sidenav if account is blocked

    return (
      !accountBlocked && isValid &&
      <React.Fragment>
        <Hidden mdDown>
          <Drawer
            PaperProps={{ style: sidenavIsVisible ? muiStyles.sidenavOpen : { ...muiStyles.sidenavMini, width: this.state.hasScrollBar ? `${55 + this.state.scrollBarWidth}px` : '55px' } }}
            id='drawer'
            variant={isValid ? 'permanent' : 'persistent'} // ensures drawer closes when logged out
          >
            {this.renderSidenavContents()}
          </Drawer>
        </Hidden>

        <Hidden lgUp>
          <SwipeableDrawer
            open={sidenavOpen}
            onClose={() => this.props.toggleSidenav(false)}
            onOpen={() => this.props.toggleSidenav(true)}
          >
            {this.renderSidenavContents()}
          </SwipeableDrawer>
        </Hidden>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(muiStyles)(connect(select, {
  changeLanguage,
  clearStore,
  notificationShow,
  toggleSidenav,
  userLogout,
})(sizify(LanguageHOC(Sidenav)))));