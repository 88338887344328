import { POSTrequest } from 'utils/helpers/api_handler';
import {
  AGENT_SEARCH,
  AGENT_UPDATE,
  REGISTRATION_TYPE,
} from './constants';


export function agentSearch(params) {
  const payload = POSTrequest('/UserRegistration/AgentExists', params);
  return {
    type: AGENT_SEARCH,
    payload,
  };
}

export function agentUpdate(params) {
  const payload = POSTrequest('/UserRegistration/Register', params);
  return {
    type: AGENT_UPDATE,
    payload,
  };
}

export function setGetRegistrationType(registrationType) {
  return {
    type: REGISTRATION_TYPE,
    payload: registrationType,
  };
}