/*
*
* PayrollContribution Actions
*
*/
import {
  GETrequest,
  POSTrequest,
} from 'utils/helpers/api_handler';

import {
  PAYROLL_GET,
  PAYROLL_UPDATE,
  NON_AGENT_ACCOUNT_VERIFY,
} from './constants';

export function getPayroll() {
  const payload = GETrequest('/agents/Payroll');
  return {
    type: PAYROLL_GET,
    payload,
  };
}

export function updatePayroll(updatedPayroll) {
  const payload = POSTrequest('/agents/Payroll', updatedPayroll);
  return {
    type: PAYROLL_UPDATE,
    payload,
  };
}

export function verifyNonAgentAccount(nonAgentAccount) {
  // user can add a non-owned account to their payroll account list after verifying using an account number and bene birthday.
  // this is done so they can contribute to an account for a beneficiary, even if they are not the agent on the account.
  const payload = POSTrequest('/agents/Payroll/Verify', nonAgentAccount);
  return {
    type: NON_AGENT_ACCOUNT_VERIFY,
    payload,
  };
}