/*
*
* PromoCode Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Button,
  Icon,
  TextField,
} from '@mui/material';
import {
  LoadingOverlay,
} from '@frontend/common';

import styles from './styles.module.scss';

export class PromoCode extends React.Component {
  static propTypes = {
    account: PropTypes.shape({
      accountId: PropTypes.number,
      hasAvailablePromoCodes: PropTypes.bool,
    }).isRequired,
    getAccounts: PropTypes.func.isRequired,
    submitPromoCode: PropTypes.func.isRequired,
    text: PropTypes.shape({
      PromoCode: PropTypes.shape({
        btn_enter_code: PropTypes.string,
        btn_submit: PropTypes.string,
        err_required_field: PropTypes.string,
        lbl_promo_code: PropTypes.string,
        text_success: PropTypes.string,
      }),
    }),
  };

  state = {
    showPromoInput: false,
    showSuccessfullyApplied: false,
    promoCode: '',
    isLoading: false,
    formErrors: {},
  }

  handleSubmitPromoCode = () => {
    const { promoCode, } = this.state;
    const { account: { accountId, }, text: { PromoCode }, submitPromoCode, getAccounts, } = this.props;
    const formErrors = { ...this.state.formErrors };

    if (promoCode) { // check that promoCode is not empty
      this.setState({ isLoading: true });
      submitPromoCode(accountId, promoCode)
        .then(() => {
          this.setState({ isLoading: true, showPromoInput: false, showSuccessfullyApplied: true, });
          getAccounts(); // refresh accounts list
        })
        .catch(response => {
          if (!(response instanceof TypeError) && response.payload.data.length > 0) {
            response.payload.data.forEach(error => {
              formErrors[error.Field] = error.Message;
            });
          }
          this.setState({ isLoading: false, formErrors });
        });
    }
    else { // if empty, show required error text
      formErrors.PromoCode = PromoCode.err_required_field;
      this.setState({ formErrors });
    }
  }

  render() {
    const { showPromoInput, showSuccessfullyApplied, promoCode, isLoading, formErrors, } = this.state;
    const { account: { hasAvailablePromoCodes }, text: { PromoCode }, } = this.props;

    return (
      (hasAvailablePromoCodes || showSuccessfullyApplied) && // only display if account has available promos or the success is showing after just submitting
      <div className={styles.promoCodeContainer}>
        {!showPromoInput && !showSuccessfullyApplied &&
        <Button 
          variant='text'
          color='primary'
          onClick={() => this.setState({ showPromoInput: true })}
        >
          {PromoCode.btn_enter_code}
        </Button>}
        
        {showPromoInput &&
        <div className={styles.promoCodeInput}>
          <TextField
            variant='filled'
            value={promoCode}
            disabled={isLoading}
            label={PromoCode.lbl_promo_code}
            onChange={(e) => this.setState({ promoCode: e.target.value, formErrors: { ...formErrors, PromoCode: '' } })}
            error={Boolean(formErrors.PromoCode)}
            helperText={formErrors.PromoCode}
            inputProps={{
              maxLength: 15,
            }}
            style={{ width: '200px' }}
          />
          <LoadingOverlay show={isLoading}>
            <Button 
              variant='contained'
              onClick={this.handleSubmitPromoCode}
              style={{ marginLeft: '10px' }}
            >
              {PromoCode.btn_submit}
            </Button>
          </LoadingOverlay>
        </div>}

        {showSuccessfullyApplied &&
        <div className={styles.successMessage}>
          {PromoCode.text_success} <Icon style={{ marginLeft: '5px' }}>check</Icon>
        </div>}
      </div>
    );
  }

}

export default LanguageHOC(PromoCode);