/*
*
* Details Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Icon,
} from '@mui/material';
import {
  IconBtnTooltip,
} from '@frontend/common';

import PrrCalculator from './PrrCalculator';

import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';

export class Details extends React.Component {
  static propTypes = {
    account: PropTypes.shape({
      accountTypeEnum: PropTypes.string,
      accountNumber: PropTypes.number,
      agreementDate: PropTypes.string,
      beneficiary: PropTypes.shape({
        name: PropTypes.string,
      }),
      option: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      }),
      ownerEntity: PropTypes.shape({
        Name: PropTypes.string,
      }),
      optionChangesRemaining: PropTypes.number,
      totalValue: PropTypes.string,
      netPrincipal: PropTypes.string,
      earnings: PropTypes.string,
      ytdContributions: PropTypes.string,
      priorContributions: PropTypes.string,
      ytdPRR: PropTypes.string,
    }),
    lastMarketDate: PropTypes.string.isRequired,
    text: PropTypes.shape({
      Details: PropTypes.shape({
        head_account_number: PropTypes.string,
        head_beneficiary: PropTypes.string,
        head_date_opened: PropTypes.string,
        head_ppr: PropTypes.string,
        head_total_value: PropTypes.func,
        nav_path_investment: PropTypes.string,
        title_see_PPR: PropTypes.string,
      }),
    }),
  };

  state = {
    prrCalculatorShow: false,
    prrButtonDisabled: false,
  }

  onClosePrrCalculatorHandler = () => {
    this.setState({ prrCalculatorShow: false });
  }

  render() {
    const { prrCalculatorShow, prrButtonDisabled } = this.state;
    const { account, text: { Details }, lastMarketDate } = this.props;

    return (
      <div className={`${parentStyles.combinedDetails} ${styles.combinedDetails}`}>
        <div className={styles.accountDetails}>
          <div className={styles.beneficiaryNameSection}>
            <div className={parentStyles.topLabel}>{Details.head_beneficiary}</div>
            <div className={parentStyles.beneficiaryName}>{account.beneficiary.name}</div>
            {account.ownerEntity && account.accountTypeEnum === 'D' && (
              <div className={styles.scholarshipEntity}>
                <Icon>school</Icon>
                <p>{account.ownerEntity.Name}</p>
              </div>
            )}
          </div>

          <div className={styles.accountNumber}>
            <div className={parentStyles.topLabel}>
              {Details.head_account_number}
            </div>
            <div className={parentStyles.accountNumber}>{account.accountNumber}</div>
            <div className={parentStyles.openedDate}>{Details.head_date_opened}: {account.agreementDate}</div>
          </div>

          <div className={styles.totalBalance}>
            <div className={parentStyles.topLabel}>{Details.head_total_value(lastMarketDate)}</div>
            <div className={parentStyles.balance}>{account.totalValue}</div>
          </div>

          <div className={styles.pprContainer}>
            <div className={parentStyles.topLabel}>{Details.head_ppr}</div>
            <div className={styles.pprPercentageContainer}>
              <div
                className={`${parentStyles.pprPercentage} ${styles.ppr} ${!account.ytdPRR.includes('%') ? styles.pprNotAvailable : account.ytdPRR.includes('-') ? styles.negativeReturn : styles.positiveReturn}`}
              >
                {account.ytdPRR.includes('%') && (account.ytdPRR.includes('-') ? <Icon>arrow_drop_down</Icon> : <Icon>arrow_drop_up</Icon>)}
                {account.ytdPRR.includes('%') ? account.ytdPRR.replace(/\s/g, '') : account.ytdPRR}
              </div>
              <div className={styles.buttonIcons}>
                <IconBtnTooltip
                  buttonProps={{ disabled: prrButtonDisabled }}
                  icon='launch'
                  onClick={() => this.setState({ prrCalculatorShow: true })}
                  title={Details.title_see_PPR}
                />
              </div>
            </div>
          </div>
        </div>

        <PrrCalculator
          open={prrCalculatorShow}
          onCloseHandler={this.onClosePrrCalculatorHandler}
          account={account}
          disablePRRButtonInDetails={() => this.setState({ prrButtonDisabled: true })}
        />
      </div>
    );
  }

}

export default LanguageHOC(Details);