/*
*
* Contributions Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import {
  Tabs,
  Tab,
} from '@mui/material';
import {
  Breadcrumbs,
  LoadingOverlay,
  notificationShow,
  ConfirmModal,
  Card,
  sizify,
} from '@frontend/common';
import {
  MONTHLY,
  ONE_TIME,
  TWICE_MONTHLY,
  YEARLY
} from './constants';
import {
  getContributionDetails,
  getScheduledTransaction,
  createContribution,
  editContribution,
} from 'components/Features/protected/Accounts/Transactions/actions';
import { getAllowedBankHolders, getAgentBankAccounts, } from 'components/Features/protected/Payments/actions';
import { getNotifications, } from 'components/AppRoot/Navigation/actions';

import OneTime from './OneTime';
import Recurring from './Recurring';
import Special from './Special';
import AddBankModal from 'components/Features/protected/Payments/AddBankModal';

import { tabletWidth, mobileWidth } from 'utils/config/_sassconfig.scss';
import styles from './styles.module.scss';


const select = (state) => ({
  accountList: state.accounts.accountList,
  agentBankAccounts: state.payments.agentBankAccounts,
  contributionDetails: state.transactions.contributionDetails,
  contributionEnvironmentVars: state.static.environmentVars,
  userDetails: state.session.userDetails,
});

export class Contributions extends React.Component {

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    accountList: PropTypes.array.isRequired,
    agentBankAccounts: PropTypes.array.isRequired,
    contributionEnvironmentVars: PropTypes.shape({
      MaximumBeneficiaryDeposit: PropTypes.number.isRequired,
      CurrentTradeDate: PropTypes.string.isRequired,
      TradeCutOffTime: PropTypes.string.isRequired,
    }),
    getContributionDetails: PropTypes.func.isRequired,
    contributionDetails: PropTypes.shape({
      contribution: PropTypes.object.isRequired,
    }),
    createContribution: PropTypes.func.isRequired,
    editContribution: PropTypes.func.isRequired,
    getAgentBankAccounts: PropTypes.func.isRequired,
    getAllowedBankHolders: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number,
    }).isRequired,
    text: PropTypes.shape({
      Accounts: PropTypes.shape({
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
      }),
      AccountDetails: PropTypes.shape({
        nav_path: PropTypes.func,
        nav_title: PropTypes.string,
      }),
      Contributions: PropTypes.shape({
        head_title_account_owner: PropTypes.string,
        lbl_one_time: PropTypes.string,
        lbl_recurring: PropTypes.string,
        lbl_special_occasion: PropTypes.string,
        msg_contribution_scheduled: PropTypes.string,
        msg_contribution_updated: PropTypes.string,
        nav_title_edit: PropTypes.string,
        nav_title_new: PropTypes.string,
        title_duplicate_contribution: PropTypes.string,
      })
    }).isRequired,
  }

  state = {
    accountId: parseInt(this.props.match.params.accountId),
    isEditing: window.location.href.search('edit') > -1,
    tabIndex: 0,
    isTabletView: this.props.size.windowWidth < parseInt(tabletWidth),
    isMobileView: this.props.size.windowWidth < parseInt(mobileWidth),
    initialLoading: false,
    isLoading: false,
    showAddBankModal: false,
    bankAccountsLoading: false,
    duplicateContributionMessage: '',
    duplicateContributionModalOpen: false,
    ignoreDuplicates: false,
    createdDate: '',
  }


  contributionCreate = (contribution) => {
    this.setState({ isLoading: true });

    const formattedContribution = {
      AccountAmounts: contribution.selectedAccounts.filter(account => account.accountId).map(account => ({
        AccountId: account.accountId,
        Amount: account.contributionAmount,
        EndDate: account.endDate ? dayjs(account.endDate).format('MM/DD/YYYY') : null,
      })),
      BankAccountId: contribution.bankAccount.BankAccountId,
      IgnoreDuplicates: this.state.ignoreDuplicates,
      Occasion: contribution.occasion || null,
      ScheduleType: contribution.type.replace(/ /g, ''),
      TradeDate: contribution.day1 ? dayjs(contribution.day1).format('MM/DD/YYYY') : null,
      TradeDate2: contribution.day2 ? dayjs(contribution.day2).format('MM/DD/YYYY') : null,
    };

    return this.props.createContribution(formattedContribution)
      .then((response, error) => {
        this.setState({ isLoading: false });

        // handle duplicate contribution message here
        const messages = response.payload.data;
        const dupeMsgs = Array.isArray(messages) ? messages.filter(message => message.MessageType === 3) : [];

        if (dupeMsgs.length > 0) {
          const duplicateContributionMessage = dupeMsgs.map(message => message.Message).join('<\br>');
          this.setState({
            duplicateContributionMessage,
            duplicateContributionModalOpen: true,
          });
          throw error; // stops TransactionStepper from progressing but brings up DuplicateConfirmModal
        }
        else {
          const createdDate = response.payload.data; // gets createdDate from server for timestamp display on print screen
          this.setState({ duplicateContribution: {}, createdDate });
          this.props.notificationShow(this.props.text.Contributions.msg_contribution_scheduled, 'success');
          this.props.getNotifications(); // refreshes app notifications
        }

      })
      .catch(error => {
        this.setState({ isLoading: false });
        throw error; // stops TransactionStepper from progressing
      });
  }

  contributionEdit = (contribution) => {
    this.setState({ isLoading: true });

    const formattedContribution = {
      Amount: contribution.selectedAccounts[0].contributionAmount,
      BankAccountId: contribution.bankAccount.BankAccountId,
      EndDate: contribution.selectedAccounts[0].endDate ? dayjs(contribution.selectedAccounts[0].endDate).format('MM/DD/YYYY') : null,
      Occasion: contribution.occasion || null,
      Frequency: contribution.type.replace(/ /g, ''),
      TradeDate: contribution.day1 ? dayjs(contribution.day1).format('MM/DD/YYYY') : null,
      TradeDate2: contribution.day2 ? dayjs(contribution.day2).format('MM/DD/YYYY') : null,
    };
    return this.props.editContribution(this.props.match.params.transactionId, formattedContribution)
      .then(response => {
        const createdDate = response.payload.data; // gets createdDate from server for timestamp display on print screen
        this.setState({ isLoading: false, createdDate });
        this.props.notificationShow(this.props.text.Contributions.msg_contribution_updated, 'success');
        this.props.getNotifications(); // refreshes app notifications
      })
      .catch(error => {
        this.setState({ isLoading: false });
        throw error; // stops TransactionStepper from progressing
      });
  }

  defaultTabGet = () => {
    if (this.state.isEditing) {
      switch (this.props.contributionDetails.contribution.type) {
        case ONE_TIME: this.setState({ tabIndex: 0 });
          break;
        case MONTHLY:
        case TWICE_MONTHLY: this.setState({ tabIndex: 1 });
          break;
        case YEARLY: this.setState({ tabIndex: 2 });
          break;
        default: this.setState({ tabIndex: 0 });
      }
    }
    else {
      this.setState({ tabIndex: 0 });
    }
  }

  onTabChange = (e, val) => {
    this.setState({ tabIndex: val });
  }

  updateContributionDetails = () => {
    this.props.getContributionDetails(this.state.accountId, this.props.accountList, this.props.contributionEnvironmentVars.MaximumBeneficiaryDeposit);
  }

  toggleShowAddBank = () => {
    this.setState({ showAddBankModal: !this.state.showAddBankModal });
  }

  handleGetBankAccounts = () => {
    this.setState({ bankAccountsLoading: true });
    this.props.getAgentBankAccounts()
      .then(() => {
        this.setState({ bankAccountsLoading: false });
      })
      .catch(() => {
        this.setState({ bankAccountsLoading: false });
      });
  }

  renderDuplicateConfirmModal = (onConfirm) => {
    // this modal gets passed into TransactionStepper, brings in onConfirm as arguement function so it can run handleSubmit in Stepper component.
    const { duplicateContributionModalOpen, duplicateContributionMessage, isLoading } = this.state;

    return (
      <ConfirmModal
        show={duplicateContributionModalOpen}
        title={this.props.text.Contributions.title_duplicate_contribution}
        body={duplicateContributionMessage}
        onModalClose={() => this.setState({ duplicateContributionModalOpen: false })}
        onConfirm={() => this.setState({ duplicateContributionModalOpen: false, ignoreDuplicates: true }, () => onConfirm())}
        isLoading={isLoading}
      />
    );
  }

  componentDidMount() {
    this.setState({ initialLoading: true });
    this.props.getAllowedBankHolders(this.props.userDetails, this.props.accountList);
    this.props.getAgentBankAccounts()
      .then(() => {
        this.props.getContributionDetails(this.state.accountId, this.props.accountList, this.props.agentBankAccounts, this.state.isEditing, this.props.contributionEnvironmentVars.MaximumBeneficiaryDeposit);
        this.setState({ initialLoading: false }, () => this.defaultTabGet());
      })
      .catch(() => {
        this.setState({ initialLoading: false });
      });
  }

  componentDidUpdate({ size }) {
    const { size: { windowWidth } } = this.props;
    if (size.windowWidth !== windowWidth) {
      this.setState({
        isTabletView: windowWidth < parseInt(tabletWidth),
        isMobileView: windowWidth < parseInt(mobileWidth),
      });
    }
  }

  render() {
    const {
      userDetails,
      text: { Accounts, AccountDetails, Contributions, },
      contributionDetails: { contribution, }
    } = this.props;
    const {
      accountId,
      isEditing,
      tabIndex,
      isMobileView,
      initialLoading,
      isLoading,
      bankAccountsLoading,
      duplicateContributionModalOpen,
      createdDate,
    } = this.state;
    const mobileTabStyle = isMobileView ? { fontSize: '12px', padding: '6px' } : {};

    return (
      <div className={styles.container}>
        <LoadingOverlay
          show={this.props.loading || isLoading}
          width='100%'
        >
          <div className={styles.contributions}>
            <div className={styles.breadcrumbs}>
              <Breadcrumbs
                crumbs={[
                  {
                    title: Accounts.nav_title,
                    link: Accounts.nav_path,
                  },
                  {
                    title: AccountDetails.nav_title,
                    link: AccountDetails.nav_path(accountId),
                  },
                  {
                    title: isEditing ? Contributions.nav_title_edit : Contributions.nav_title_new,
                  },
                ]}
              />
            </div>


            <div className={styles.accountDetails}>
              <Card title={Contributions.head_title_account_owner}>
                {userDetails.name}
              </Card>
            </div>

            <div className={styles.contributionsTabs}>
              <Tabs
                onChange={(e, val) => this.onTabChange(e, val)}
                value={tabIndex}
                centered
              >
                <Tab label={Contributions.lbl_one_time} disabled={isEditing && contribution.type !== ONE_TIME} sx={mobileTabStyle} />
                <Tab label={Contributions.lbl_recurring} disabled={isEditing && !(contribution.type === MONTHLY || contribution.type === TWICE_MONTHLY)} sx={mobileTabStyle} />
                <Tab label={Contributions.lbl_special_occasion} disabled={isEditing && contribution.type !== YEARLY} sx={mobileTabStyle} />
              </Tabs>

              {tabIndex === 0 && !this.props.loading && !initialLoading &&
                <OneTime
                  key={this.props.contributionEnvironmentVars.CurrentTradeDate}
                  accountId={accountId}
                  isEditing={isEditing}
                  bankAccountsLoading={bankAccountsLoading}
                  contributionCreate={this.contributionCreate}
                  contributionEdit={this.contributionEdit}
                  toggleShowAddBank={this.toggleShowAddBank}
                  renderDuplicateConfirmModal={this.renderDuplicateConfirmModal}
                  duplicateContributionModalOpen={duplicateContributionModalOpen} // this is needed for component to rerender when modal does onClose.
                  createdDate={createdDate}
                />
              }

              {tabIndex === 1 &&
                <Recurring
                  key={this.props.contributionEnvironmentVars.CurrentTradeDate}
                  accountId={accountId}
                  isEditing={isEditing}
                  bankAccountsLoading={bankAccountsLoading}
                  contributionCreate={this.contributionCreate}
                  contributionEdit={this.contributionEdit}
                  toggleShowAddBank={this.toggleShowAddBank}
                  renderDuplicateConfirmModal={this.renderDuplicateConfirmModal}
                  duplicateContributionModalOpen={duplicateContributionModalOpen} // this is needed for component to rerender when modal does onClose.
                  createdDate={createdDate}
                />
              }

              {tabIndex === 2 &&
                <Special
                  key={this.props.contributionEnvironmentVars.CurrentTradeDate}
                  accountId={accountId}
                  isEditing={isEditing}
                  bankAccountsLoading={bankAccountsLoading}
                  contributionCreate={this.contributionCreate}
                  contributionEdit={this.contributionEdit}
                  toggleShowAddBank={this.toggleShowAddBank}
                  renderDuplicateConfirmModal={this.renderDuplicateConfirmModal}
                  duplicateContributionModalOpen={duplicateContributionModalOpen} // this is needed for component to rerender when modal does onClose.
                  createdDate={createdDate}
                />
              }
            </div>

          </div>

          <AddBankModal
            agentOnly={true}
            open={this.state.showAddBankModal}
            close={() => this.toggleShowAddBank()}
            isMobileView={isMobileView}
            handleGetBankAccounts={this.handleGetBankAccounts}
          />

        </LoadingOverlay>
      </div>
    );
  }
}


export default connect(select, {
  getContributionDetails,
  getScheduledTransaction,
  getAgentBankAccounts,
  getAllowedBankHolders,
  createContribution,
  editContribution,
  notificationShow,
  getNotifications,
})(sizify(LanguageHOC(Contributions)));
