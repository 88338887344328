/*
*
* ConfirmationStep Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  getExistingBeneficiaries,
  resetAccountApplication
} from '../actions';
import { refreshClaims } from 'components/AppRoot/Navigation/actions.js';
import { documentsGet } from 'components/Features/protected/Documents/actions';
import Summary from '../Summary';
import {
  Button,
  Paper,
} from '@mui/material';
import { IconBtnTooltip } from '@frontend/common';
import { signupStepper } from 'utils/ga/events';

import styles from './styles.module.scss';

const select = state => ({
  AccountNumber: state.signup.AccountNumber,
  documents: state.documents.documents,
  personId: state.session.claims.PersonId,
  PromotionalCode: state.signup.PromotionalCode,
});

const LOCATIONS = { HOME: 'home', SIGNUP: 'signup' };


export class ConfirmationStep extends React.Component {
  static propTypes = {
    AccountNumber: PropTypes.number.isRequired,
    documents: PropTypes.array.isRequired,
    documentsGet: PropTypes.func.isRequired,
    getExistingBeneficiaries: PropTypes.func.isRequired,
    onStepClick: PropTypes.func.isRequired,
    personId: PropTypes.string.isRequired,
    PromotionalCode: PropTypes.string.isRequired,
    refreshClaims: PropTypes.func.isRequired,
    resetAccountApplication: PropTypes.func.isRequired,
    setLoadingOff: PropTypes.func.isRequired,
    setLoadingOn: PropTypes.func.isRequired,
    text: PropTypes.shape({
      ConfirmationStep: PropTypes.shape({
        btn_to_home: PropTypes.string,
        head_congratulations: PropTypes.string,
        text_account_created: PropTypes.string,
        text_account_number: PropTypes.string,
        text_add_another_account: PropTypes.string,
      }),
      Home: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    nextLocation: '',
  };

  goToLocation = () => {
    window.scrollTo(0, 0); // this will scroll to the top if user chooses to add another account
    switch (this.state.nextLocation) {
      case LOCATIONS.HOME: this.props.history.push(this.props.text.Home.nav_path); break;
      case LOCATIONS.SIGNUP: this.props.onStepClick(1); break;
      default: break;
    }
  }

  setLocation = nextLocation => {
    this.setState({ nextLocation }, () => this.updateAccount());
  }

  updateAccount = () => {
    this.props.setLoadingOn();

    const apiCalls = [];

    if (!this.props.personId) { // only runs for brand new users
      apiCalls.push(this.props.refreshClaims());
    }

    if (this.props.documents.length > 0) { // if docs are already in store, re-fetch them to get new account certificates. Otherwise, the new certs will be fetched when Documents is loaded for the first time
      apiCalls.push(this.props.documentsGet());
    }
    
    Promise.all(apiCalls)
      .then(() => {
        this.goToLocation(); // goes to next location if user clicked button
      })
      .catch(() => this.props.setLoadingOff());
  }

  componentWillUnmount() { // this will run when user leaves page either by clicking a SSup button or left nav (existing users have access to left nav).
    this.props.getExistingBeneficiaries(); // re-fetches existing beneficiaries so new account is added to existingBenesList. Needs to occur AFTER refreshing claims.
    this.props.resetAccountApplication(); // clears application so a new bene can be added right away
    this.props.setLoadingOff();
    signupStepper('finish'); // Google Analytics url for goal tracking
  }

  render() {
    const { AccountNumber, PromotionalCode, text: { ConfirmationStep } } = this.props;

    return (
      <Paper>
        <div className={styles.container}>
          <div className={styles.printButton}>
            <IconBtnTooltip
              icon='print'
              onClick={() => window.print()}
            />
          </div>
          <div className={styles.headerContainer}>
            <h1>{ConfirmationStep.head_congratulations}</h1>
            <h3>{ConfirmationStep.text_account_number}: {AccountNumber}</h3>
            <p>{ConfirmationStep.text_account_created}</p>
          </div>

          <Summary
            isDisabled={true}
            isEditable={false}
            PromotionalCode={PromotionalCode}
            showPromoCode={Boolean(PromotionalCode)}
          />

          <div className={styles.stepperButtons}>
            <Button
              color='primary'
              onClick={() => this.setLocation(LOCATIONS.SIGNUP)}
            >
              {ConfirmationStep.text_add_another_account}
            </Button>
            <Button
              color='primary'
              onClick={() => this.setLocation(LOCATIONS.HOME)}
              variant='contained'
            >
              {ConfirmationStep.btn_to_home}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}


export default withRouter(connect(select, {
  documentsGet,
  getExistingBeneficiaries,
  refreshClaims,
  resetAccountApplication,
})(LanguageHOC(ConfirmationStep)));
