/*
*
* FormWrapper Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import { My529Logo } from '@frontend/common';

import styles from './styles.module.scss';


export function FormWrapper({ children }) {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.body}>
          <a 
            className={styles.logo}
            href='https://www.my529.org/'
            target='_self'
            rel='noopener noreferrer'
          >
            <My529Logo height='100%' />
          </a>
          {children}
        </div>
      </div>
    </div>
  );
}

FormWrapper.propTypes = {
  children: PropTypes.node,
};

export default LanguageHOC(FormWrapper);