/*
*
* Transfer Component
*
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import { Card, LoadingOverlay } from '@frontend/common';
import {
  Step,
  StepButton,
  Stepper,
  Button,
} from '@mui/material';
import TransferSetup from './TransferStepOneContent';
import {
  TransferFromReview,
  TransferToReview,
  TransferTermsAndConditions,
} from './TransferTermsAndConditions';

import styles from './styles.module.scss';

export class Transfer extends React.Component {
  static propTypes = {
    cardTitle: PropTypes.string.isRequired,
    sourceAccount: PropTypes.object,
    destinationAccounts: PropTypes.array,
    save: PropTypes.func.isRequired,
    transfer: PropTypes.object.isRequired,
    canContinue: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired,
    onTransferChange: PropTypes.func.isRequired,
    onTargetChange: PropTypes.func.isRequired,
    addTargetAccount: PropTypes.func.isRequired,
    removeTargetAccount: PropTypes.func.isRequired,
    createdDate: PropTypes.string.isRequired,
    text: PropTypes.shape({
      Transfers: PropTypes.shape({
        btn_back: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_finish: PropTypes.string,
        btn_next: PropTypes.string,
        btn_print: PropTypes.string,
        btn_submit: PropTypes.string,
        lbl_step_select: PropTypes.string,
        lbl_step_terms: PropTypes.string,
        lbl_step_confirm: PropTypes.string,
        text_print_copy: PropTypes.string,
        text_timezone: PropTypes.string,
      })
    })
  };

  static defaultProps = {
    sourceAccount: {},
    destinationAccounts: [],
  };

  state = {
    stepIndex: 0,
    termsChecked: false,
    isLoadingSubmit: false,
    transferSubmitted: false,
  };

  isSmallWindow = () => window.innerWidth < 600;

  onTermsToggled = () => {
    this.setState({
      termsChecked: !this.state.termsChecked
    });
  };

  getErrorForInput = (inputKey) => {
    const { errors } = this.props;
    const error = errors.find(err => err.key === inputKey);
    return error !== undefined ? error.message : '';
  };

  handlePrevStep = () => {
    this.setState({
      stepIndex: this.state.stepIndex - 1,
    });

    window.scrollTo(0, 0);
  };

  handleNextStep = () => {
    const { canContinue, transfer } = this.props;
    const { stepIndex, termsChecked, } = this.state;

    switch (stepIndex) {
      case 0: {
        canContinue(() => {
          this.setState({
            stepIndex: stepIndex + 1,
          });
          window.scrollTo(0, 0);
        });
        break;
      }
      case 1: {
        if (!termsChecked) {
          return;
        }
        canContinue(() => {
          this.setState({ isLoadingSubmit: true });
          this.props.save()
            .then(() => {
              this.setState({
                isLoadingSubmit: false,
                stepIndex: stepIndex + 1,
                transferSubmitted: true,
              });

              // Qualtrics Intercept for full balance transfer / account closure. Launches survey 
              if (transfer.CloseAccount) {
                try {
                  document.cookie = 'closeAcct=true';
                  window.QSI.API.run(); // stimulate Targeting.php
                  window.QSI.API.unload();
                  window.QSI.API.load();
                  window.QSI.API.run(); // stimulate Targeting.php
                }
                catch (error) {
                  // return null;
                }
              }

              window.scrollTo(0, 0);
            })
            .catch(() => {
              this.setState({
                isLoadingSubmit: false,
                stepIndex: this.state.stepIndex - 1
              }, this.goToPreviousPage());
              window.scrollTo(0, 0);
            });
        });
        break;
      }
      case 2: {
        if (!termsChecked) {
          return;
        }
        canContinue(() => {
          this.setState({ isLoadingSubmit: true });
          this.props.save()
            .catch(() => {
              this.setState({
                isLoadingSubmit: false,
                stepIndex: this.state.stepIndex - 1
              });
            });
        });
        break;
      }
      default: break;
    }
  };

  stepperCompose = () => {
    const { Transfers } = this.props.text;
    const { transferSubmitted } = this.state;
    const stepperStyle = {
      width: this.isSmallWindow() ? '' : '650px'
    };
    return (
      <Stepper
        activeStep={this.state.stepIndex}
        orientation={this.isSmallWindow() ? 'vertical' : 'horizontal'}
        style={stepperStyle}
      >
        <Step>
          <StepButton onClick={() => this.setState({ stepIndex: 0 })} disabled={transferSubmitted}>{Transfers.lbl_step_select}</StepButton>
        </Step>
        <Step>
          <StepButton onClick={() => this.setState({ stepIndex: 1 })} disabled={transferSubmitted}>{Transfers.lbl_step_terms}</StepButton>
        </Step>
        <Step>
          <StepButton onClick={() => this.setState({ stepIndex: 2 })}>{Transfers.lbl_step_confirm}</StepButton>
        </Step>
      </Stepper>
    );
  };

  stepperContentCompose = () => {
    const {
      stepIndex,
      termsChecked,
    } = this.state;

    switch (stepIndex) {
      case 0:
        return (
          <TransferSetup
            {...this.props}
            getErrorForInput={this.getErrorForInput}
          />
        );
      case 1:
        return [
          <TransferFromReview
            key='transferFromReview'
            transfer={this.props.transfer}
            destinationAccounts={this.props.destinationAccounts}
            sourceAccount={this.props.sourceAccount}
            Transfers={this.props.text.Transfers}
          />,
          <TransferToReview
            key='transferToReview'
            transfer={this.props.transfer}
            destinationAccounts={this.props.destinationAccounts}
            sourceAccount={this.props.sourceAccount}
            Transfers={this.props.text.Transfers}
          />,
          <TransferTermsAndConditions
            key='tc'
            termsChecked={termsChecked}
            onTermsToggled={this.onTermsToggled}
            Transfers={this.props.text.Transfers}
          />
        ];
      case 2:
        return [
          <TransferFromReview
            key='transferFromReview'
            transfer={this.props.transfer}
            destinationAccounts={this.props.destinationAccounts}
            sourceAccount={this.props.sourceAccount}
            Transfers={this.props.text.Transfers}
          />,
          <TransferToReview
            key='transferToReview'
            transfer={this.props.transfer}
            destinationAccounts={this.props.destinationAccounts}
            sourceAccount={this.props.sourceAccount}
            Transfers={this.props.text.Transfers}
          />,
          <div key='createdDate' className={styles.currentTime}>{dayjs(this.props.createdDate)
            .format('MM/DD/YYYY h:mm A')} {this.props.text.Transfers.text_timezone}</div>,
          <div key='print' className={`${styles.print} hideOnPrint`}>
            {this.props.text.Transfers.text_print_copy}
          </div>
        ];
      default: break;
    }
  };

  goToPreviousPage = () => {
    this.props.history.goBack();
  }

  stepperButtonsCompose = () => {
    const { Transfers } = this.props.text;
    const { stepIndex, termsChecked, isLoadingSubmit, transferSubmitted } = this.state;
    const cancelButton = (
      <Button
        variant='text'
        key='cancel'
        color='primary'
        disabled={isLoadingSubmit}
        onClick={this.goToPreviousPage}
        style={{ marginRight: '5px' }}
      >
        {Transfers.btn_cancel}
      </Button>
    );
    const nextButton = (
      <Button
        key='nextStep'
        disabled={isLoadingSubmit}
        onClick={this.handleNextStep}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        {Transfers.btn_next}
      </Button>
    );
    const backButton = (
      <Button
        key='prevStep'
        disabled={isLoadingSubmit}
        onClick={this.handlePrevStep}
        variant='text'
        color='primary'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        {Transfers.btn_back}
      </Button>
    );
    const submitButton = (
      <Button
        disabled={!termsChecked || isLoadingSubmit || transferSubmitted}
        onClick={this.handleNextStep}
        variant='contained'
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        {Transfers.btn_submit}
      </Button>
    );
    const printButton = (
      <Button
        variant='contained'
        key='print'
        onClick={() => window.print()}
        style={{ marginRight: '5px' }}
      >
        {Transfers.btn_print}
      </Button>
    );
    const finishButton = (
      <Button
        variant='contained'
        key='finish'
        onClick={() => this.props.history.goBack()}
        style={{ marginRight: '5px' }}
      >
        {Transfers.btn_finish}
      </Button>
    );
    let buttons = [];
    if (stepIndex === 0) {
      buttons = [
        cancelButton,
        nextButton
      ];
    }
    else if (stepIndex === 1) {
      buttons = [
        cancelButton,
        backButton,
        <LoadingOverlay key='nextStep' show={isLoadingSubmit}>
          {submitButton}
        </LoadingOverlay>
      ];
    }
    else if (stepIndex === 2) {
      buttons = [
        printButton,
        finishButton
      ];
    }
    return buttons;
  };

  render() {
    return (
      <div>
        <Card className={styles.Transfer_stepperContentContainer} title={this.props.cardTitle}>
          <div className={styles.Transfer_stepperStepsContainer}>
            <div className={`${styles.Transfer_stepperSteps} hideOnPrint`}>
              {this.stepperCompose()}
            </div>
          </div>
          <div className={`${styles.Transfer_stepperContent} ${styles[`step_${this.state.stepIndex}`]}`}>
            {this.stepperContentCompose()}
          </div>
          <div className='hideOnPrint'>
            {this.stepperButtonsCompose()}
          </div>
        </Card>
      </div>
    );
  }
}

export default withRouter(LanguageHOC(Transfer));
