/*
 *
 * ReviewDetails Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import {
  currencyFormatter,
} from '@frontend/common';
import styles from '../styles.module.scss';

ReviewDetails.propTypes = {
  contribution: PropTypes.object.isRequired,
  text: PropTypes.shape({
    ContributionOneTimeReview: PropTypes.shape({
      title_account_number: PropTypes.string,
      title_amount: PropTypes.string,
      title_bank_account: PropTypes.string,
      title_bene_name: PropTypes.string,
      title_date: PropTypes.string,
      title_total: PropTypes.string,
    })
  })
};

export function ReviewDetails({
  contribution: { bankAccount, day1, selectedAccounts, },
  text: { ContributionOneTimeReview },
}) {
  const populatedAccounts = selectedAccounts.filter(account => account.accountId);
  return (
    <React.Fragment>
      <div className={styles.reviewContainer}>

        <div className={styles.reviewAccountNumber}>
          <div className={styles.detailsTitle}>{ContributionOneTimeReview.title_account_number}</div>
          {populatedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{account.accountNumber}</div>))}
        </div>

        <div className={styles.reviewBeneficiaryName}>
          <div className={styles.detailsTitle}>{ContributionOneTimeReview.title_bene_name}</div>
          {populatedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{account.beneficiary.name.toUpperCase()}</div>))}
        </div>

        <div className={styles.reviewBankAccount}>
          <div className={styles.detailsTitle}>{ContributionOneTimeReview.title_bank_account}</div>
          <div className={styles.detail}>{bankAccount.BankName} {bankAccount.BankAccountType}, {bankAccount.MaskedBankAccountNumber}</div>
        </div>

        <div className={styles.reviewDate}>
          <div className={styles.detailsTitle}>{ContributionOneTimeReview.title_date}</div>
          {populatedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{dayjs(day1).format('L')}</div>))}
        </div>

        <div className={styles.reviewAmount}>
          <div className={styles.detailsTitle}>{ContributionOneTimeReview.title_amount}</div>
          {populatedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{currencyFormatter(account.contributionAmount)}</div>))}
        </div>
      </div>

      <div className={styles.totalAmountStep2}>
        <div>{ContributionOneTimeReview.title_total}</div>
        <div>{currencyFormatter(populatedAccounts.map(account => account.contributionAmount).reduce((val1, val2) => val1 + val2, 0))}</div>
      </div>
    </React.Fragment>
  );
}

export default (LanguageHOC(ReviewDetails));