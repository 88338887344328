import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

const select = (state) => ({
  isValid: state.session.isValid
});

export class PublicRoute extends React.Component {

  static propTypes = {
    isValid: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Home: PropTypes.shape({
        nav_path: PropTypes.string
      }),
    }).isRequired,
  };

  render() {
    const { component: Component, text: { Home }, ...rest } = this.props; // eslint-disable-line
    return (
      <Route {...rest} render={props => {
        return this.props.isValid ?
          <Redirect
            push
            to={Home.nav_path}
          />
          :
          <Component {...props} />;
      }}
      />
    );
  }
}

export default connect(select, {})(LanguageHOC(PublicRoute));
