/*
*
* VerifyEmail Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { 
  Button,
  Paper 
} from '@mui/material';
import {
  Modal,
  notificationShow
} from '@frontend/common';
import {
  clearStore,
  resendEmailValidation,
  userLogout
} from 'components/AppRoot/Navigation/actions';
import ChangeEmailWhileBlocked from './ChangeEmailWhileBlocked';
import AddMissingEmailWhileBlocked from './AddMissingEmailWhileBlocked';

import styles from './styles.module.scss';

const select = (state) => ({
  emailAddress: state.session.userDetails.email,
  supportNumber: state.static.environmentVars.SupportPhoneNumber,
});


export class VerifyEmail extends React.Component {

  static propTypes = {
    clearStore: PropTypes.func.isRequired,
    emailAddress: PropTypes.string.isRequired,
    notificationShow: PropTypes.func.isRequired,
    resendEmailValidation: PropTypes.func.isRequired,
    supportNumber: PropTypes.string.isRequired,
    text: PropTypes.shape({
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
        nav_path: PropTypes.string,
      }),
      VerifyEmail: PropTypes.shape({
        btn_change_email: PropTypes.string,
        btn_resend_email: PropTypes.string,
        head_validating_email_while_logged_in: PropTypes.string,
        msg_email_resent: PropTypes.string,
        msg_validating_while_logged_in: PropTypes.string,
        text_verify_email1: PropTypes.string,
        text_verify_email2: PropTypes.func,
        text_verify_email3: PropTypes.string,
      }),
    }).isRequired,
    userLogout: PropTypes.func.isRequired,
    validatingEmailWhileLoggedIn: PropTypes.bool.isRequired,
    isEmailMissing: PropTypes.bool.isRequired,
  };

  state = {
    changeEmailModalOpen: false,
    isResending: false,
    validatingEmailWhileLoggedInModalOpen: false,
  };

  emailResend = () => {
    this.setState({ isResending: true });
    this.props.resendEmailValidation()
      .then(() => {
        this.props.notificationShow(this.props.text.VerifyEmail.msg_email_resent, 'success');
        this.setState({ isResending: false });
      })
      .catch((response) => {
        const token = sessionStorage.getItem('token');
        this.props.userLogout({ token })
          .finally(() => {
            this.setState({ isResending: false });
            this.props.clearStore();
            this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success');
            response.payload.data.forEach(error => this.props.notificationShow(error.Message, 'error'));
          });
      });
  }

  closeChangeEmailModal = () => {
    this.setState({ changeEmailModalOpen: false });
  }

  openChangeEmailModal = () => {
    this.setState({ changeEmailModalOpen: true });
  }

  componentDidMount() {
    if (this.props.validatingEmailWhileLoggedIn) {
      this.setState({ validatingEmailWhileLoggedInModalOpen: true });
    }
  }

  render() {
    const { isEmailMissing, emailAddress, supportNumber, text: { VerifyEmail } } = this.props;
    const { changeEmailModalOpen, isResending, validatingEmailWhileLoggedInModalOpen } = this.state;
    return (
      isEmailMissing ? // just display missing email modal
        <AddMissingEmailWhileBlocked />
        : // otherwise, display verify email with resend and change options
        <>
          <Paper className={styles.containerCard}>
            <div style={{ padding: '30px' }}>
              <div style={{ textAlign: 'center' }}>
                <p className={styles.emailAddress}>{emailAddress}</p>
                <Button
                  color='primary'
                  onClick={() => this.openChangeEmailModal()}
                  disabled={isResending}
                >
                  {VerifyEmail.btn_change_email}
                </Button><br />
                <img src={ require('./EmailConfirmation.jpg') } alt={''} width='275px' />
              </div>
              <p>{VerifyEmail.text_verify_email1}</p>
              <p>{VerifyEmail.text_verify_email2(supportNumber)}</p>
              <p>{VerifyEmail.text_verify_email3}</p>
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <Button
                  variant='contained'
                  onClick={() => this.emailResend()}
                >
                  {VerifyEmail.btn_resend_email}
                </Button>
              </div>
            </div>
          </Paper>

          <Modal
            onCloseModal={() => this.setState({ validatingEmailWhileLoggedInModalOpen: false })}
            show={validatingEmailWhileLoggedInModalOpen}
            title={VerifyEmail.head_validating_email_while_logged_in}
          >
            {VerifyEmail.msg_validating_while_logged_in}
          </Modal>

          <ChangeEmailWhileBlocked
            changeEmailModalOpen={changeEmailModalOpen}
            closeChangeEmailModal={this.closeChangeEmailModal}
          />
        </>
    );
  }
}


export default connect(select, {
  clearStore,
  notificationShow,
  resendEmailValidation,
  userLogout,
})(LanguageHOC(VerifyEmail));