/*
*
* BalanceByDate Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { Link, } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import { cloneDeep, debounce, includes, uniq, } from 'lodash';
import {
  Divider,
  MenuItem,
  FormControl,
  Select,
  Input,
  ListSubheader,
} from '@mui/material';
import {
  currencyFormatter,
  DatePicker,
  IconBtnTooltip,
} from '@frontend/common';
import { accountsByFilter, } from 'utils/helpers/data_filters';
import Search from 'utils/components/Search';
import SkeletonLoader from 'utils/components/SkeletonLoader';
import * as material_ui_theme from 'utils/config/material_ui_theme';
import styles from './styles.module.scss';

const agentColor = material_ui_theme.default.palette.primary.main;
const interestedPartyColor = material_ui_theme.default.palette.secondary.main;

export class BalanceByDate extends React.Component {

  static propTypes = {
    dashboardAccountList: PropTypes.arrayOf(
      PropTypes.shape({
        accountBalanceHistory: PropTypes.arrayOf(
          PropTypes.shape({
            balance: PropTypes.number,
            date: PropTypes.object,
          })
        ),
        accountId: PropTypes.number,
        accountNumber: PropTypes.number,
        balanceAsOfGivenDate: PropTypes.string,
        beneficiaryName: PropTypes.string,
        optionName: PropTypes.string,
        isInterestedParty: PropTypes.bool,
      })
    ).isRequired,
    isBalanceHistoryLoading: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      AccountDetails: PropTypes.shape({
        nav_path: PropTypes.func,
      }),
      AccountsSummary: PropTypes.shape({
        head_interested_party: PropTypes.string,
      }),
      BalanceByDate: PropTypes.shape({
        btn_filter: PropTypes.string,
        lbl_date: PropTypes.string,
        text_NA: PropTypes.string,
        title_account_numbers: PropTypes.string,
        title_bene_names: PropTypes.string,
        title_investment_options: PropTypes.string,
      }),
    }).isRequired,
  }

  state = {
    balanceDate: null,
    dashboardAccountList: [],
    filteredAccountList: [],
    filterList: [],
    selectedFilters: [],
    searchedAccountList: [],
    searchIsActive: false,
    showFilterList: false,
  }

  setupFilterList(dashboardAccountList) {
    // get unique bene names and investment options
    const beneficiaryNames = uniq(this.props.dashboardAccountList.map(account => account.beneficiaryName));
    const optionNames = uniq(this.props.dashboardAccountList.map(account => account.optionName));

    return [
      { listHeader: 'Accounts', key: 'accountsHeader' },
      ...dashboardAccountList.map(account => ({ // list of Account Numbers
        label: account.accountNumber,
        key: account.accountNumber,
        isActive: selectedFilters => includes(selectedFilters, account.accountNumber),
      })),
      { listHeader: 'Beneficiaries', key: 'benesHeader' },
      ...beneficiaryNames.map(name => ({ // list of Beneficiaries
        label: name,
        key: name,
        isActive: selectedFilters => includes(selectedFilters, name),
      })),
      { listHeader: 'Options', key: 'optionsHeader' },
      ...optionNames.map(name => ({ // list of options
        label: name,
        key: name,
        isActive: selectedFilters => includes(selectedFilters, name),
      })),
    ];
  }

  handleShowFilterList = () => {
    this.setState({ showFilterList: !this.state.showFilterList });
  }

  handleFilterSelect = e => {
    const { dashboardAccountList, searchedAccountList, searchIsActive } = this.state;
    const selectedFilters = e.target.value;
    const accountListToUse = searchIsActive ? searchedAccountList : dashboardAccountList; // if searching is active, use searched results instead of full dashboardAccountList
    this.setState({ selectedFilters, filteredAccountList: accountsByFilter(accountListToUse, selectedFilters) });
  }

  onDateChange = (balanceDate) => {
    this.setState({
      balanceDate,
    }, () => dayjs(balanceDate).isValid() && this.findBalanceForGivenDate());
  }

  findBalanceForGivenDate = () => {
    const { balanceDate, selectedFilters, } = this.state;
    const dashboardAccountList = cloneDeep(this.state.dashboardAccountList);

    dashboardAccountList.forEach(account => {
      const balanceHistory = cloneDeep(account.accountBalanceHistory);
      const balance = balanceHistory.reverse().find(balance => dayjs(balanceDate).isSameOrAfter(balance.date, 'day'));
      account.balanceAsOfGivenDate = balance ? currencyFormatter(balance.balance) : this.props.text.BalanceByDate.text_NA;
    });

    this.setState({ dashboardAccountList, filteredAccountList: accountsByFilter(dashboardAccountList, selectedFilters) });
  }

  componentDidMount() {
    const balanceDate = this.props.dashboardAccountList.length > 0
      ? this.props.dashboardAccountList[0].accountBalanceHistory[this.props.dashboardAccountList[0].accountBalanceHistory.length - 1].date
      : null; // get the latest date from the accountBalanceHistory

    this.setState({
      dashboardAccountList: this.props.dashboardAccountList,
      balanceDate,
      filteredAccountList: this.props.dashboardAccountList,
      filterList: this.setupFilterList(this.props.dashboardAccountList),
    });
  }

  render() {
    const { isBalanceHistoryLoading, text: { AccountDetails, AccountsSummary, BalanceByDate } } = this.props;
    const { filteredAccountList, balanceDate, filterList, showFilterList, selectedFilters, } = this.state;

    return (
      <React.Fragment>
        <div className={styles.filterAndSearch}>
          <div className={styles.filter}>
            <IconBtnTooltip
              buttonProps={{ color: 'primary', style: { marginRight: '5px' }, disabled: isBalanceHistoryLoading, }}
              onClick={() => this.handleShowFilterList()}
              icon='filter_list'
              title={BalanceByDate.btn_filter}
            />
            <FormControl style={{ visibility: 'hidden', height: 0, width: 0 }}>
              <Select
                open={showFilterList}
                onClose={() => this.handleShowFilterList()}
                value={selectedFilters}
                onChange={this.handleFilterSelect}
                multiple
                input={<Input />}
              >
                {filterList.map(filter => filter.listHeader
                  ? <ListSubheader key={filter.key}>{filter.listHeader}</ListSubheader>
                  :
                  <MenuItem
                    key={filter.key}
                    value={filter.label}
                    style={filter.isActive(selectedFilters) ? { color: 'var(--primary)', fontWeight: 'bold', } : {}}
                  >
                    {filter.label}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          <div className={styles.search}>
            <Search
              idKey={'accountId'}
              data={this.state.dashboardAccountList}
              dataColumns={[
                { key: 'accountNumber', title: BalanceByDate.title_account_numbers, type: 'number' },
                { key: 'beneficiaryName', title: BalanceByDate.title_bene_names, type: 'string' },
                { key: 'optionName', title: BalanceByDate.title_investment_options, type: 'string' },
              ]}
              disabled={isBalanceHistoryLoading}
              updateData={(searchedAccountList, searchIsActive) => {
                this.setState({
                  searchedAccountList,
                  filteredAccountList: accountsByFilter(searchedAccountList, selectedFilters),
                  searchIsActive,
                });
              }}
              hasSearchMenu={true}
            />
          </div>
        </div>
        <div className={styles.balanceByDateContainer}>
          {filteredAccountList.map((account, index) => {
            const { balanceAsOfGivenDate, accountId, beneficiaryName, optionName, accountNumber, isInterestedParty } = account;
            const isLastAccount = index === filteredAccountList.length - 1;
            const displayedOptionName = isInterestedParty ? `${optionName} - ${AccountsSummary.head_interested_party}` : optionName;
            const balanceIsNA = balanceAsOfGivenDate === this.props.text.BalanceByDate.text_NA;

            return (
              <React.Fragment key={accountId}>
                <div
                  className={`${styles.accountValueContainer} ${balanceIsNA ? styles.hasNoBalance : ''}`}
                  style={{ borderLeft: `3px solid ${isInterestedParty ? interestedPartyColor : agentColor}` }}
                >
                  <div className={styles.accountDetailsContainer}>
                    <div className={styles.beneName}>{beneficiaryName.toUpperCase()}</div>
                    <div className={styles.optionName}>{displayedOptionName}</div>
                    <div className={styles.accountNumber}>
                      <Link to={AccountDetails.nav_path(accountId)}>{accountNumber}</Link>
                    </div>
                  </div>
                  <SkeletonLoader isLoading={!balanceAsOfGivenDate} height={20} width={60}>
                    <div className={styles.accountValue}>{balanceAsOfGivenDate}</div>
                  </SkeletonLoader>
                </div>
                {!isLastAccount && <Divider style={{ margin: '0 20px 0 10px' }} />}
              </React.Fragment>
            );
          })}
        </div>
        <div className={styles.dateFilter}>
          <DatePicker
            fullWidth
            variant='filled'
            label={BalanceByDate.lbl_date}
            onChange={debounce(this.onDateChange, 500)}
            value={balanceDate || null}
            disabled={isBalanceHistoryLoading}
          />
        </div>
      </React.Fragment>
    );
  }
}


export default (LanguageHOC(BalanceByDate));
