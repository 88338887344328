/*
*
* Google Analytics Helpers
*
*/

// sends stuff to GA
export function sendPageView(url) {
  window.ga('send', 'pageview', url);
}

export function sendClickEvent(eventCategory, eventLabel) {
  window.ga('send', 'event', {
    eventAction: 'click',
    eventCategory,
    eventLabel,
  });
}

// removes IDs from URLs so that pages can be tracked as a whole and sensitive data is removed before getting recorded by GA
export function urlScrub(path) {
  if (path.match(/\baccounts\b\/(\d+)\/\btransactions/)) { // Transactions
    let accountRoute = path.replace(/^\/accounts\/(\d+)\/transactions/, '/accounts/<accountId>/transactions/<transactionId>');

    if (path.match(/\btransfers\/(\d+)\/edit/)) { // Transfers Edit
      accountRoute = accountRoute.replace(/transfers\/(\d+)\/edit/, 'transfers/<transactionId>/edit');
    }
    if (path.match(/\bcontributions\/(\d+)\/edit/)) { // Contributions Edit
      accountRoute = accountRoute.replace(/contributions\/(\d+)\/edit/, 'contributions/<transactionId>/edit');
    }
    else if (path.match(/\bwithdrawals/)) { // Withdrawals
      accountRoute = accountRoute.replace(/withdrawals\/groups\/(\d+)/, 'withdrawals/groups/<groupId>');
    }
    else if (path.match(/\bwithdrawal-requests/)) { // Withdrawal Requests (CSA)
      accountRoute = accountRoute.replace(/withdrawal-requests\/groups\/(\d+)/, 'withdrawal-requests/groups/<groupId>');
    }
    return accountRoute;
  }
  else if (path.match(/\baccounts\b\/(\d+)/)) { // Account Details
    return path.replace(/^\/accounts\/(\d+)/, '/accounts/<accountId>');
  }
  else if (path.match(/\baccounts\b\/(\d+)\/\binvestment-path/)) { // Account Details Investment Path
    return path.replace(/\baccounts\b\/(\d+)\/\binvestment-path/, '/accounts/<accountId>/investment-path');
  }

  else {
    return path;
  }
}