/*
*
* Home Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { isEmpty } from 'lodash';
import { greetingGenerator } from 'utils/helpers/greeting_generator';
import {
  getAccounts,
  getAccountsSummary,
} from 'components/Features/protected/Accounts/actions';
import {
  get2FAPrecondition,
  getRSAPrecondition,
  getNotifications,
  saveUserPreferences,
} from 'components/AppRoot/Navigation/actions';
import { agentGet, getStatementDeliveryMethods } from 'components/Features/protected/MyInfo/actions';
import { getPreferredMethod } from 'components/Features/protected/Multifactor/actions';
import { getPredefinedOptions } from 'components/Features/protected/Accounts/Transactions/OptionChanges/actions';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
} from '@mui/material';
import { withStyles, } from '@mui/styles';
import {
  IconBtnTooltip,
  LoadingOverlay,
  sizify,
} from '@frontend/common';
import { TWO_FACTOR_STATUS } from 'components/AppRoot/Navigation/constants';
import { MAIL } from 'components/Features/protected/MyInfo/constants';
import Overview from './Overview';
import AccessCardBanner from './AccessCardBanner';
import PaperlessBanner from './PaperlessBanner';
import AccountsSummary from 'components/Features/protected/Accounts/AccountsSummary';
import Banner from 'components/Features/Banner';

import UpcomingSchedules from 'components/Features/protected/TransactionsHub/UpcomingSchedules';
import TransactionHistory from 'components/Features/protected/TransactionsHub/TransactionHistory';

import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';
import { mobileWidth } from 'utils/config/_sassconfig.scss';
import styles from './styles.module.scss';

const expansionPanelBar = {
  background: '#F5F5F5',
  borderRadius: 3,
  border: 0,
  color: 'white',
  height: 48,
  padding: '0 30px',
  boxShadow: 'none',
};

const muiStyles = () => ({
  expansionPanelBody: {
    background: '#F5F5F5',
    boxShadow: 'none',
  },
  expansionPanelBar,
  expansionPanelBarSmallScreen: {
    ...expansionPanelBar,
    height: 125
  }
});

const select = (state) => ({
  accountList: state.accounts.accountList.map(account => ({
    ...account,
    accountId: account.accountId,
    accountGroupId: account.accountGroupId,
    beneficiaryName: account.beneficiary.name,
    totalValue: account.totalValue,
    accountNumber: account.accountNumber,
    accountType: account.accountType,
    optionName: account.option.name,
    accountBalanceHistory: [
      { // only added for loading purposes of balance history
        balance: 0,
        date: new Date(),
      }
    ],
  })),
  balanceHistory: state.accounts.balanceHistory,
  userDetails: state.session.userDetails,
  is2FADialogOpen: state.session.is2FADialogOpen,
  claims: state.session.claims,
  isAgent_AccessRole: state.session.isAgent_AccessRole,
  preferredInfo: state.multifactor.preferredInfo,
  accessCardBannerPreference: state.session.userPreferences.find(pref => pref.Identifier === 'ShowAccessCardBanner') || {},
  paperlessBannerPreference: state.session.userPreferences.find(pref => pref.Identifier === 'ShowPaperlessBanner') || {},
  webMessages: state.static.webMessages,
  agent: state.myInfo.agent,
  DeliveryMethods: state.myInfo.DeliveryMethods,
});

export class Home extends React.Component {

  static propTypes = {
    accountList: PropTypes.arrayOf(
      PropTypes.shape({
        accountId: PropTypes.number,
        accountGroupId: PropTypes.number,
        accountIsClosed: PropTypes.bool,
        beneficiaryName: PropTypes.string,
        totalValue: PropTypes.string,
        accountNumber: PropTypes.number,
        accountType: PropTypes.string,
        optionName: PropTypes.string,
        taxableEntityProgramAccountId: PropTypes.number,
      })
    ).isRequired,
    balanceHistory: PropTypes.array,
    classes: PropTypes.object.isRequired,
    getAccounts: PropTypes.func.isRequired,
    getAccountsSummary: PropTypes.func.isRequired,
    get2FAPrecondition: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    saveUserPreferences: PropTypes.func.isRequired,
    agentGet: PropTypes.func.isRequired,
    getPredefinedOptions: PropTypes.func.isRequired,
    getPreferredMethod: PropTypes.func.isRequired,
    isAgent_AccessRole: PropTypes.bool.isRequired,
    claims: PropTypes.object.isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number,
    }).isRequired,
    text: PropTypes.shape({
      AccountDetails: PropTypes.shape({ nav_path: PropTypes.func }),
      Home: PropTypes.shape({
        btn_list_view: PropTypes.string,
        btn_tile_view: PropTypes.string,
        head_account_agent: PropTypes.string,
        head_accounts: PropTypes.string,
        head_email: PropTypes.string,
        head_overview: PropTypes.string,
      }),
    }).isRequired,
    userDetails: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    is2FADialogOpen: PropTypes.bool.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    accessCardBannerPreference: PropTypes.shape({ UserId: PropTypes.number, Identifier: PropTypes.string, Value: PropTypes.bool }).isRequired,
    paperlessBannerPreference: PropTypes.shape({ UserId: PropTypes.number, Identifier: PropTypes.string, Value: PropTypes.bool }).isRequired,
    webMessages: PropTypes.object.isRequired,
    agent: PropTypes.shape({
      OfficialCommunications: PropTypes.string,
      Newsletter: PropTypes.string,
      TaxForms: PropTypes.string,
    }).isRequired,
    DeliveryMethods: PropTypes.array,
    getStatementDeliveryMethods: PropTypes.func.isRequired,
  };

  state = {
    isTileView: false,
    loading: false,
    overviewIsExpanded: true,
    transactionsIsExpanded: true,
    greeting: '',
    hasCalledAccounts: false,
    userCookieIsSet: false,
  };

  isNotPaperless = (officialCommunications, newsletter, taxForms, deliveryMethods) => {
    // required data were not received
    if (!officialCommunications || !newsletter || !taxForms || !deliveryMethods) {
      return false;
    }
    // some of the agent's setting are not online only
    if (officialCommunications === MAIL || newsletter === MAIL || taxForms === MAIL) {
      return true;
    }
    // some of the bene's delivery method is not online only
    return Boolean(deliveryMethods.find(method => method.DeliveryMethod === MAIL));
  }

  accountsGet = () => {
    this.props.accountList.length === 0 && this.setState({ loading: true });
    // to find out if it was resolved in componentDidMount
    return this.props.getAccounts()
      .then(() => {
        const promises = [
          this.props.getAccountsSummary(),
          this.props.getNotifications(),
          this.props.getPredefinedOptions(),
        ];
        this.props.claims.AgentId && promises.push(this.props.agentGet());
        // returning all promises in order to chain it
        return Promise.all(promises);
      })
      .finally(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    const isMobile = this.props.size.windowWidth < parseInt(mobileWidth);
    const setStateOnMount = () => this.setState({
      isTileView: isMobile,
      overviewIsExpanded: !isMobile, // collapse overview section when on mobile phone
      transactionsIsExpanded: !isMobile,
      greeting: `${greetingGenerator()}, ${this.props.userDetails.name}`,
    });

    if (this.props.claims.AccountBlocked !== 'True' && this.props.accountList.length === 0) {
      this.setState(
        { hasCalledAccounts: true },
        () => {
          this.accountsGet().then(() => setStateOnMount());
          // data for paperless banner
          if (this.props.DeliveryMethods.length === 0) {
            this.props.getStatementDeliveryMethods();
          }
        }
      );
    }
    else {
      setStateOnMount();
    }

    if (isEmpty(this.props.preferredInfo)) {
      this.props.getPreferredMethod();
    }

    // default isActiveAgent cookie to false
    document.cookie = 'isActiveAgent=false';
  }

  componentDidUpdate({ size }) {
    const { size: { windowWidth }, accountList, isAgent_AccessRole } = this.props;
    const { userCookieIsSet } = this.state;

    if (size.windowWidth !== windowWidth) {
      this.setState({
        isTileView: windowWidth < parseInt(mobileWidth) ? true : this.state.isTileView,
      });
    }

    const { TwoFactorAuthentication, AccountBlocked } = this.props.claims;
    if (AccountBlocked === 'False'
      && TwoFactorAuthentication !== TWO_FACTOR_STATUS.PENDING
      && !this.state.hasCalledAccounts
      && this.props.accountList.length === 0) {
      this.setState({ hasCalledAccounts: true }, () => {
        // 2FA should be finished here
        this.accountsGet();
        // data for paperless banner
        if (this.props.DeliveryMethods.length === 0) {
          this.props.getStatementDeliveryMethods();
        }
      });
    }

    // if user is both an agent and has an open Individual account, set isActiveAgent cookie to true
    // should exclude all CSA accounts
    // this cookie is used in the Qualtrics logic to display a survey to specific users
    if (isAgent_AccessRole && !userCookieIsSet && accountList.length > 0) {
      const hasActiveIndividualAccount = accountList.some(account => !account.accountIsClosed && account.accountType === 'Individual' && account.taxableEntityProgramAccountId === 0);
      this.setState({ userCookieIsSet: true });
      if (hasActiveIndividualAccount) document.cookie = 'isActiveAgent=true';
    }
  }

  render() {
    const {
      classes, size: { windowWidth }, text: { Home }, userDetails, accountList,
      balanceHistory, claims, accessCardBannerPreference, saveUserPreferences,
      webMessages,
      paperlessBannerPreference,
      agent: {
        OfficialCommunications,
        Newsletter,
        TaxForms,
      },
      DeliveryMethods
    } = this.props;

    const { isTileView, loading, overviewIsExpanded, transactionsIsExpanded, greeting } = this.state;

    return (
      <div className={styles.homeContainer}>
        <Banner
          show={Boolean(webMessages[BANNER_LOCATIONS.HOME])}
          body={webMessages[BANNER_LOCATIONS.HOME]}
        />
        <PaperlessBanner
          show={paperlessBannerPreference.Value && this.isNotPaperless(OfficialCommunications, Newsletter, TaxForms, DeliveryMethods)}
          onCloseBanner={() => saveUserPreferences({
            Identifier: paperlessBannerPreference.Identifier,
            UserId: paperlessBannerPreference.UserId,
            Value: false
          })}
        />
        <LoadingOverlay show={loading} width='100%'>
          <div>
            <Accordion
              classes={{ root: classes.expansionPanelBody }}
              expanded={overviewIsExpanded}
              onChange={() => this.setState({ overviewIsExpanded: !overviewIsExpanded })}
            >
              <AccordionSummary
                aria-controls='account-agent-content'
                classes={{ root: windowWidth < mobileWidth ? classes.expansionPanelBar : classes.expansionPanelBarSmallScreen }}
                expandIcon={<Icon>expand_less</Icon>}
                id='account-agent-header'
              >
                <div className={styles.overviewHeading}>
                  <div className={styles.ownerName}>{greeting}</div>
                  <div className={styles.overviewTitle}>{Home.head_overview}</div>
                  <div className={styles.ownerEmail}>{userDetails.email}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Overview
                  accountList={accountList}
                  balanceHistory={balanceHistory}
                  claims={claims}
                  homeIsLoading={loading}
                />
              </AccordionDetails>
            </Accordion>

            <AccessCardBanner
              show={accessCardBannerPreference.Value}
              onCloseBanner={() => saveUserPreferences({
                Identifier: accessCardBannerPreference.Identifier,
                UserId: accessCardBannerPreference.UserId,
                Value: false
              })}
              size={this.props.size}
            />

            <div className={styles.accountsHeadingContainer}>
              <div className={styles.accountsHeading}>
                <h1>{Home.head_accounts}</h1>
              </div>
              <div className={styles.viewIcon}>
                <IconBtnTooltip
                  icon={isTileView ? 'view_list' : 'dashboard'}
                  onClick={(event) => {
                    this.setState({ isTileView: !isTileView });
                    event.stopPropagation();
                  }}
                  onFocus={event => event.stopPropagation()}
                  title={isTileView ? Home.btn_list_view : Home.btn_tile_view}
                />
              </div>
            </div>

            <div className={styles.accountsSummaryContainer}>
              <AccountsSummary
                isTileView={isTileView}
                size={this.props.size}
              />
            </div>

            <div style={{ paddingTop: '20px' }}>
              <Accordion
                classes={{ root: classes.expansionPanelBody }}
                expanded={transactionsIsExpanded}
                onChange={() => this.setState({ transactionsIsExpanded: !transactionsIsExpanded })}
              >
                <AccordionSummary
                  aria-controls='transaction-agent-content'
                  expandIcon={<Icon>expand_less</Icon>}
                  id='transaction-agent-header'
                >
                  <span style={{ color: '#000' }}>Transactions</span>
                </AccordionSummary>

                {accountList && accountList.length > 0 &&
                  <AccordionDetails>
                    <div className={styles.transactionsAccordion}>
                      <div style={{ paddingBottom: '10px' }}>
                        <UpcomingSchedules />
                      </div>
                      <div>
                        <TransactionHistory />
                      </div>
                    </div>
                  </AccordionDetails>
                }

              </Accordion>
            </div>

          </div>
        </LoadingOverlay>
      </div>
    );
  }
}


export default withStyles(muiStyles)(connect(select, {
  get2FAPrecondition,
  getAccounts,
  agentGet,
  getAccountsSummary,
  getNotifications,
  getPredefinedOptions,
  getPreferredMethod,
  getRSAPrecondition,
  saveUserPreferences,
  getStatementDeliveryMethods
})(sizify(LanguageHOC(Home))));