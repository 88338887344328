/*
 *
 * LpoaStepper Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Button,
} from '@mui/material';
import {
  LoadingOverlay,
  Card,
} from '@frontend/common';

import { sizify } from '@frontend/common';

import styles from './styles.module.scss';
import { desktopWidth } from 'utils/config/_sassconfig.scss';

export class LpoaStepper extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    clearStepError: PropTypes.bool.isRequired,
    verifyStep: PropTypes.func.isRequired,
    renderStepComponent: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    stepLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    submitStep: PropTypes.number.isRequired,
    clearStepErrors: PropTypes.func.isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number.isRequired,
    }).isRequired,
    history: PropTypes.object.isRequired,
    text: PropTypes.shape({
      Lpoa: PropTypes.shape({
        btn_agree: PropTypes.string,
        btn_back: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_finish: PropTypes.string,
        btn_next: PropTypes.string,
        btn_print: PropTypes.string,
      }),
    })
  };

  state = {
    step: 0,
    stepHasError: false,
    completed: {},
    stepsDisabled: false,
  };

  isFirstStep = () => {
    return this.state.step === 0;
  };

  isLastStep = () => {
    return this.state.step === this.props.stepLabels.length - 1;
  };

  completedSteps = () => {
    return Object.keys(this.state.completed).length;
  };

  setStep = step => () => {
    if (this.state.completed[step]) this.setState({ step });
  };

  handleBack = () => {
    this.props.clearStepErrors();
    if (!this.isFirstStep()) this.setState(({ step }) => ({ step: step - 1 }));
  };

  handleNext = () => {
    const { verifyStep } = this.props;

    const stepHasError = verifyStep(this.state.step);
    this.setState({ stepHasError });

    if (!stepHasError) {
      if (!this.isLastStep()) {
        const newCompleted = this.state.completed;
        newCompleted[this.state.step] = true;
        this.setState(({ step }) => ({
          step: step + 1,
          completed: newCompleted
        }));
      }
    }
  };

  getCompletedStepsToThisStep(step, completed, isCompleted) {
    for (let i = 0; i <= step; i++) {
      completed[i] = isCompleted;
    }
    return completed;
  }

  handleSubmit = () => {
    const { verifyStep, handleSubmit } = this.props;

    const stepHasError = verifyStep(this.state.step);
    this.setState({ stepHasError });

    if (!stepHasError) {
      handleSubmit();
    }
  }

  renderButtons = () => {
    const {
      submitStep,
      text: {
        Lpoa: {
          btn_agree,
          btn_back,
          btn_cancel,
          btn_next,
        }
      },
      loading,
      onCancel,
    } = this.props; //terms checked left as true, unless passed in props.
    const { step } = this.state;
    const cancelButton = (
      <Button
        variant='text'
        color='primary'
        key='cancel'
        onClick={onCancel}
        style={{ marginRight: '5px' }}
        disabled={loading}
      >
        {btn_cancel}
      </Button>
    );
    const backButton = (
      <Button
        variant='text'
        color='primary'
        key='backButton'
        disabled={step === 0 || loading}
        onClick={this.handleBack}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        {btn_back}
      </Button>
    );
    const nextButton = (
      <Button
        key='nextButton'
        variant='contained'
        onClick={this.handleNext}
        style={{ marginLeft: '5px', marginRight: '5px' }}
        disabled={loading}
      >
        {btn_next}
      </Button>
    );
    const submitButton = (
      <LoadingOverlay
        key='submitButton'
        show={loading}
      >
        <Button
          variant='contained'
          onClick={this.handleSubmit}
          style={{ marginLeft: '5px', marginRight: '5px' }}
          disabled={loading}
        >
          {btn_agree}
        </Button>
      </LoadingOverlay>
    );

    let buttons;

    if (submitStep === step) {
      buttons = [
        cancelButton,
        backButton,
        submitButton
      ];
    }
    else if (this.isLastStep()) {
      buttons = [];
    }
    else if (step === 0) {
      buttons = [
        cancelButton,
        nextButton,
      ];
    }
    else {
      buttons = [
        cancelButton,
        backButton,
        nextButton,
      ];
    }

    return buttons;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clearStepError !== this.props.clearStepError) {
      if (this.props.clearStepError) {
        this.setState({ stepHasError: false });
      }
    }
  }

  render() {
    const { step, completed, loading, stepHasError } = this.state;
    const { renderStepComponent, stepLabels, size } = this.props;

    return (
      <div className={styles.stepperContentContainer}>
        <div>
          <div className={styles.stepperSteps}>
            <Stepper
              activeStep={step}
              orientation={size.windowWidth <= parseInt(desktopWidth) ? 'vertical' : 'horizontal'}
              className={styles.stepper}
            >
              {stepLabels.map((label, index) => (
                <Step key={label}>
                  <StepButton
                    onClick={this.setStep(index)}
                    completed={completed[index]}
                    disabled={!completed[index] || loading}
                  >
                    <StepLabel
                      error={index === step && stepHasError}
                    >
                      {label}
                    </StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
          <Card>
            <div className={styles.stepperContent}>
              {renderStepComponent(step)}
            </div>

            <div className={styles.stepperButtons}>
              {this.renderButtons()}
            </div>
          </Card>
        </div>

      </div>
    );
  }
}

export default withRouter(sizify(LanguageHOC(LpoaStepper)));
