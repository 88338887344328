import { cloneDeep } from 'lodash';

import {
  GET_LPOA_ACCOUNT,
  PERSON_TYPES, 
  ADDRESS_TYPES,
  SAVE_TO_REDUX_LPOA_ACCOUNT,
  APPROVE_LPOA_ACCOUNT,
} from './constants';

const initialState = {
  newAccountNumber: 0,
  loginAlreadyExists: false,
  applicationInfo: {
    guid: '',
    [PERSON_TYPES.AO]: {
      firstName: '',
      middleName: '',
      lastName: '',
      birthDate: null,
      ssn: '',
      isUtahPayer: false,
      [ADDRESS_TYPES.MAILING]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      contact: {
        email: '',
        homePhone: '',
        workPhone: '',
        otherPhone: '',
        relationship: '',
      },
    },
    [PERSON_TYPES.BENE]: {
      firstName: '',
      middleName: '',
      lastName: '',
      birthDate: null,
      ssn: '',
      isUtahPayer: false,
      [ADDRESS_TYPES.MAILING]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    [PERSON_TYPES.SUCCESSOR_PRIMARY]: {
      firstName: '',
      middleName: '',
      lastName: '',
      birthDate: null,
      ssn: '',
      isUtahPayer: false,
      [ADDRESS_TYPES.MAILING]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    [PERSON_TYPES.SUCCESSOR_SECONDARY]: {
      firstName: '',
      middleName: '',
      lastName: '',
      birthDate: null,
      ssn: '',
      isUtahPayer: false,
      [ADDRESS_TYPES.MAILING]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    accountSummary: {
      applicationDate: '',
      type: '',
      option: '',
      poaLevel: '',
      fedTaxDocs: false,
      stateTaxDocs: false,
      status: '',
      promoCode: '',
    },
    beneAge: -1,
    ageBands: [
      {
        FundInformation: [],
        FeeInformation: {
          ExpenseRatio: 0,
          My529Fee: 0,
          TotalFee: 0,
        },
      },
    ],
    lpoaFirmName: '',
  },
};

const mapApplicationInfo = (account) => {
  function normalizeString(str) {
    return str ? str.toString() : '';
  }

  function normalizeBoolean(bool) {
    return Boolean(bool);
  }

  function normalizeNumber(num) {
    return num ? num : '';
  }

  function normalizeDate(date) {
    return date ? date : null;
  }

  return {
    guid: account.PoaAccountApplicationId,
    [PERSON_TYPES.AO]: {
      firstName: normalizeString(account.OwnerFirstName),
      middleName: normalizeString(account.OwnerMiddleName),
      lastName: normalizeString(account.OwnerLastName),
      birthDate: normalizeDate(account.OwnerBirthdate),
      ssn: normalizeNumber(account.OwnerSSN),
      isUtahPayer: normalizeBoolean(account.OwnerIsResident),
      [ADDRESS_TYPES.MAILING]: {
        street1: normalizeString(account.OwnerMailingAddress1),
        street2: normalizeString(account.OwnerMailingAddress2),
        city: normalizeString(account.OwnerMailingCity),
        state: normalizeString(account.OwnerMailingState),
        zipCode: normalizeNumber(account.OwnerMailingZipCode),
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: normalizeString(account.OwnerPhysicalAddress1),
        street2: normalizeString(account.OwnerPhysicalAddress2),
        city: normalizeString(account.OwnerPhysicalCity),
        state: normalizeString(account.OwnerPhysicalState),
        zipCode: normalizeNumber(account.OwnerPhysicalZipCode),
      },
      contact: {
        email: normalizeString(account.OwnerEmail),
        homePhone: account.OwnerHomePhone,
        workPhone: account.OwnerWorkPhone,
        otherPhone: account.OwnerOtherPhone,
        relationship: account.Relationship,
      },
    },
    [PERSON_TYPES.BENE]: {
      firstName: normalizeString(account.BeneficiaryFirstName),
      middleName: normalizeString(account.BeneficiaryMiddleName),
      lastName: normalizeString(account.BeneficiaryLastName),
      birthDate: normalizeDate(account.BeneficiaryBirthdate),
      ssn: normalizeString(account.BeneficiarySSN),
      isUtahPayer: normalizeBoolean(account.BeneficiaryIsResident),
      [ADDRESS_TYPES.MAILING]: {
        street1: normalizeString(account.BeneficiaryMailingAddress1),
        street2: normalizeString(account.BeneficiaryMailingAddress2),
        city: normalizeString(account.BeneficiaryMailingCity),
        state: normalizeString(account.BeneficiaryMailingState),
        zipCode: normalizeNumber(account.BeneficiaryMailingZipCode),
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: normalizeString(account.BeneficiaryPhysicalAddress1),
        street2: normalizeString(account.BeneficiaryPhysicalAddress2),
        city: normalizeString(account.BeneficiaryPhysicalCity),
        state: normalizeString(account.BeneficiaryPhysicalState),
        zipCode: normalizeNumber(account.BeneficiaryPhysicalZipCode),
      },
    },
    [PERSON_TYPES.SUCCESSOR_PRIMARY]: {
      firstName: normalizeString(account.SuccessorFirstName),
      middleName: normalizeString(account.SuccessorMiddleName),
      lastName: normalizeString(account.SuccessorLastName),
      birthDate: normalizeDate(account.SuccessorBirthdate),
      ssn: normalizeString(account.SuccessorSSN),
      isUtahPayer: normalizeBoolean(account.SuccessorUtahResident),
      [ADDRESS_TYPES.MAILING]: {
        street1: normalizeString(account.SuccessorMailingAddress1),
        street2: normalizeString(account.SuccessorMailingAddress2),
        city: normalizeString(account.SuccessorMailingCity),
        state: normalizeString(account.SuccessorMailingState),
        zipCode: normalizeNumber(account.SuccessorMailingZipCode),
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: normalizeString(account.SuccessorPhysicalAddress1),
        street2: normalizeString(account.SuccessorPhysicalAddress2),
        city: normalizeString(account.SuccessorPhysicalCity),
        state: normalizeString(account.SuccessorPhysicalState),
        zipCode: normalizeNumber(account.SuccessorPhysicalZipCode),
      },
    },
    [PERSON_TYPES.SUCCESSOR_SECONDARY]: {
      firstName: normalizeString(account.SSuccessorFirstName),
      middleName: normalizeString(account.SSuccessorMiddleName),
      lastName: normalizeString(account.SSuccessorLastName),
      birthDate: normalizeDate(account.SSuccessorBirthDate),
      ssn: normalizeString(account.SSuccessorSSN),
      isUtahPayer: normalizeBoolean(account.SSuccessorUtahResident),
      [ADDRESS_TYPES.MAILING]: {
        street1: normalizeString(account.SSuccessorMailingAddress1),
        street2: normalizeString(account.SSuccessorMailingAddress2),
        city: normalizeString(account.SSuccessorMailingCity),
        state: normalizeString(account.SSuccessorMailingState),
        zipCode: normalizeNumber(account.SSuccessorMailingZipCode),
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: normalizeString(account.SSuccessorPhysicalAddress1),
        street2: normalizeString(account.SSuccessorPhysicalAddress2),
        city: normalizeString(account.SSuccessorPhysicalCity),
        state: normalizeString(account.SSuccessorPhysicalState),
        zipCode: normalizeNumber(account.SSuccessorPhysicalZipCode),
      },
    },
    accountSummary: {
      applicationDate: normalizeDate(account.Date),
      type: normalizeString(account.AccountTypeName),
      option: normalizeString(account.OptionName),
      poaLevel: normalizeNumber(account.PoaLevel),
      fedTaxDocs: normalizeBoolean(account.CanViewFedTaxDocs),
      stateTaxDocs: normalizeBoolean(account.CanViewStateTaxDocs),
      status: normalizeString(account.Status),
      promoCode: normalizeString(account.PromoCode),
    },
    beneAge: account.BeneficiaryAge,
    ageBands: account.AgeBands,
    lpoaFirmName: account.FirmName,
  };
};

function LpoaAccountReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {   
    case GET_LPOA_ACCOUNT: {
      newState.applicationInfo = mapApplicationInfo(action.payload.data);
      return newState;
    }

    case SAVE_TO_REDUX_LPOA_ACCOUNT: {
      newState.applicationInfo = action.payload;
      return newState;
    }

    case APPROVE_LPOA_ACCOUNT: {
      newState.newAccountNumber = action.payload.data.AccountNumber;
      newState.loginAlreadyExists = action.payload.data.LoginAlreadyExists;
      return newState;
    }

    default:
      return state;
  }
}

export default LpoaAccountReducer;