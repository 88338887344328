/* eslint-disable newline-per-chained-call */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable id-length */
/*
*
* BalanceGraph Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { cloneDeep, isEqual, } from 'lodash';
import dayjs from 'dayjs';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  currencyFormatter,
  sizify,
} from '@frontend/common';
import styles from './styles.module.scss';

const margin = { top: 20, right: 0, bottom: 20, left: 40 };


export class BalanceGraph extends React.Component {

  static propTypes = {
    graphData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        accountBalanceHistory: PropTypes.arrayOf(
          PropTypes.shape({
            balance: PropTypes.number,
            date: PropTypes.object,
          }),
        )
      })
    ).isRequired,
    isGraphFullscreen: PropTypes.bool.isRequired,
    activeDateFilter: PropTypes.string.isRequired,
    size: PropTypes.shape({
      componentHeight: PropTypes.number,
      componentWidth: PropTypes.number,
      windowWidth: PropTypes.number,
    }).isRequired,
    text: PropTypes.shape({
      Overview: PropTypes.shape({
        lbl_filter_1month: PropTypes.string,
        lbl_filter_3month: PropTypes.string,
        lbl_filter_6month: PropTypes.string,
        lbl_filter_YTD: PropTypes.string,
        lbl_filter_1year: PropTypes.string,
        lbl_filter_all: PropTypes.string,
        lbl_filter_custom: PropTypes.string,
        lbl_total: PropTypes.string,
        text_NA: PropTypes.string,
      })
    }),
  };

  state = {
    componentHeight: this.props.size.componentHeight,
    componentWidth: this.props.size.componentWidth,
  }

  drawChart = (useTransition = false) => {
    const { graphData, activeDateFilter, size: { windowWidth }, text: { Overview }, } = this.props;
    const {
      componentHeight,
      componentWidth,
    } = this.state;

    const width = componentWidth - margin.left - margin.right;
    const height = componentHeight - margin.top - margin.bottom;

    const accountBeneNames = graphData.map(data => data.accountId);
    const selectedAccountsCount = accountBeneNames.length;

    // find max value from all balance histories, used for figuring out the yAxis domain and formatting
    const maxBalances = cloneDeep(graphData).map(data => {
      const sortedBalances = data.accountBalanceHistory.sort((a, b) => b.balance - a.balance);
      return sortedBalances[0].balance;
    });
    const maxAmount = Math.max(...maxBalances);
    // find the account with the longest balance history in order to make the proper domain for the x axis
    const longestDateRangeIndex = graphData.reduce((p, c, i, a) => a[p].accountBalanceHistory.length > c.accountBalanceHistory.length ? p : i, 0);

    const xScale = d3.scaleTime()
      .range([0, width])
      .clamp(true);

    const yScale = d3.scaleLinear()
      .range([height, 0]);

    // time formats
    const formatDay = d3.timeFormat('%d');
    const formatWeek = d3.timeFormat('%b %d');
    const formatMonth = d3.timeFormat('%b');
    const formatYearBeg = d3.timeFormat('%b %Y');
    const formatYear = d3.timeFormat('%Y');

    const is1YearFilter = activeDateFilter === Overview.lbl_filter_1year || activeDateFilter === Overview.lbl_filter_YTD;
    // time formatter for x axis 
    const multiFormat = (date) => {
      return (
        d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
          : isEqual(d3.timeYear(date), date) && is1YearFilter ? formatYearBeg
            : d3.timeYear(date) < date ? formatMonth
              : formatYear)(date);
    };

    let xTickCount = componentWidth <= 600 ? 3 : componentWidth <= 1000 ? 9 : 12; // determine x axis tick count based on width
    xTickCount = graphData[longestDateRangeIndex].accountBalanceHistory.length < xTickCount ? graphData[longestDateRangeIndex].accountBalanceHistory.length : xTickCount;

    // create color range by first using set default colors then d3's rainbow scale
    const colorRange = [];
    const defaultColors = ['#ED12FF', '#12FFB6', '#12ADFF', '#5B12FF', '#FFA412', '#FF1224', '#FFFF12', '#8271A7']; // default starting colors
    if (selectedAccountsCount > defaultColors.length) { // if there are more selected accounts then the default colors, use d3's rainbow scale
      accountBeneNames.forEach((key, index) => colorRange.push(d3.interpolateRainbow(index / accountBeneNames.length)));
    }
    else {
      accountBeneNames.forEach((key, index) => colorRange.push(defaultColors[index]));
    }

    const color = d3.scaleOrdinal()
      .range(colorRange)
      .domain(accountBeneNames); // Set the color domain equal to the account list keys
    const xAxis = d3.axisBottom().scale(xScale)
      .ticks(xTickCount)
      .tickFormat(date => multiFormat(date)); // Create an x axis component with d3.axisBottom
    const yAxis = d3.axisLeft().scale(yScale) // Create an y axis component with d3.axisLeft, formats to a thousands place
      .ticks(10)
      .tickFormat(value => maxAmount > 5000 ? `$${value / 1000}k` : `$${value}`); // if maxAmount is more than $5,000, format in 1,000s.


    const line = d3.line()
      .curve(d3.curveLinear) // apply smoothing to the line
      .x(data => xScale(data.date)) // set the x values for the line generator
      .y(data => yScale(data.balance)); // set the y values for the line generator 

    // remove whatever was previously in the .graph container so it can be redrawn on resizing
    d3.select('.graph')
      .selectAll('*')
      .remove();

    // set the width/height on the SVG
    const svg = d3.select('.graph')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate( ${margin.left} ${margin.top} )`)
      .attr('class', 'line-graph');


    // Set the domain (min/max) of the axes
    const xDomain = d3.extent(graphData[longestDateRangeIndex].accountBalanceHistory, d => d.date);
    xScale.domain(xDomain);

    yScale.domain([0, maxAmount > 0 ? maxAmount * 1.1 : 1000]); // sets yAxis domain (how high the axis will go). giving a 10% buffer. If maxAmount is 0, default to 1000.

    // call the x axis in a group tag
    svg.append('g')
      .attr('class', styles.xAxis)
      .attr('transform', `translate(0, ${height} )`)
      .attr('stroke', 'none')
      .transition()
      .duration(750)
      .call(xAxis)
      .call(g => {
        g.selectAll('text')
          .style('text-anchor', 'end')
          .attr('y', 10);
      });


    // call the y axis in a group tag
    svg.append('g')
      .attr('class', styles.yAxis)
      .style('text-anchor', 'end')
      .transition()
      .duration(750)
      .call(yAxis);

    // APPEND MULTIPLE LINES //
    const lines = svg.append('g')
      .attr('class', 'lines');

    const glines = lines.selectAll('.line-group')
      .data(graphData)
      .enter()
      .append('g')
      .attr('class', 'line-group');

    // determine if the line should be smaller, if there are more than 6 selected accounts over the time frame is over 1 year
    const overOneYearTimeframe = dayjs(xDomain[1]).subtract(1, 'year')
      .isAfter(xDomain[0]); // checks that latest date, minus 1 year, is still after the earliest date
    const useSmallerLine = selectedAccountsCount > 6 || overOneYearTimeframe;

    glines
      .append('path')
      .attr('class', 'line')
      .attr('id', (d, i) => `line_${i}`)
      .attr('d', d => line(d.accountBalanceHistory)) // calls the line generator 
      .style('stroke', (d, i) => color(i))
      .style('fill', 'none')
      .style('opacity', 1)
      .style('stroke-width', useSmallerLine ? '4px' : '6px'); // set the line size


    // LINE TRANSITIONS //
    if (useTransition) {
      glines.each((d, i) => {
        const selectedLine = d3.select(`#line_${i}`);
        const totalLength = selectedLine.node().getTotalLength();

        selectedLine
          .attr('stroke-dasharray', `${totalLength} ${totalLength}`)
          .attr('stroke-dashoffset', totalLength)
          .transition()
          .duration(1500)
          .delay(200 * i)
          .ease(d3.easeQuadInOut)
          .attr('stroke-dashoffset', 0);
      });
    }
    // CREATE HOVER TOOLTIP WITH VERTICAL LINE //

    // remove everything under the tooltip so it does not create additional elements
    d3.select('#tooltip')
      .remove();

    // create tooltip container
    const tooltip = d3.select('#chart')
      .append('div')
      .attr('id', 'tooltip')
      .attr('class', styles.tooltip)
      .style('position', 'absolute')
      .style('display', 'none');

    const mouseG = svg.append('g')
      .attr('class', 'mouse-over-effects');

    mouseG.append('path') // create vertical line to follow mouse
      .attr('class', 'mouse-line')
      .style('stroke', '#A9A9A9')
      .style('stroke-width', '2px')
      .style('stroke-dasharray', 6)
      .style('opacity', '0');

    const mousePerLine = mouseG.selectAll('.mouse-per-line')
      .data(graphData)
      .enter()
      .append('g')
      .attr('class', 'mouse-per-line');

    mousePerLine.append('circle') // creates the circle that goes over each line based on mouse position
      .attr('r', 8)
      .style('stroke', (d, i) => color(i))
      .style('fill', '#FFF')
      .style('stroke-width', '4px')
      .style('opacity', '0');

    mouseG.append('svg:rect') // append a rect to catch mouse movements on canvas
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseout', function() { // on mouse out hide line, circles and text
        d3.select('.mouse-line')
          .style('opacity', '0');
        d3.selectAll('.mouse-per-line circle')
          .style('opacity', '0');
        d3.selectAll('.mouse-per-line text')
          .style('opacity', '0');
        d3.selectAll('#tooltip')
          .style('display', 'none');
      })
      .on('mouseover touchstart', function() { // on mouse in show line, circles and text
        d3.select('.mouse-line')
          .style('opacity', '1');
        d3.selectAll('.mouse-per-line circle')
          .style('opacity', '1');
        d3.selectAll('#tooltip')
          .style('display', 'block');
      })
      .on('mousemove', function() { // update tooltip content, line, circles and text when mouse moves
        const [mouseX, mouseY] = d3.mouse(this); // gets the x, y position of where the mouse is at in the line graph area
        const date = xScale.invert(mouseX + 0.14); // use 'invert' to get date corresponding to distance from mouse position relative to svg; have to add 0.14 to x position due to the position being just slightly off
        const roundedDate = dayjs(date).format('HH') > 1 ? dayjs(date).add(1, 'day').startOf('day') : dayjs(date).startOf('day'); // rounds date for tooltip display. needed due to day being off by 1 day
        const bisectDate = d3.bisector(d => d.date).left; // create bisector function
        const moveTooltipToCenter = windowWidth < 450 && Math.abs((windowWidth / 2) - d3.event.pageX) < 50;
        const moveTooltipToLeft = windowWidth - d3.event.pageX < 240; // checks if there is still space for the tooltip to be viewed on the screen
        let longestHistoryLength = 0;

        // finds the cordinates for each line and mouse X position and places the line and circles there
        d3.selectAll('.mouse-per-line')
          .attr('transform', d => {
            const dateIndex = bisectDate(d.accountBalanceHistory, roundedDate);
            if (d.accountBalanceHistory.length > longestHistoryLength) { // only set the mouse line position based on the longest data history
              longestHistoryLength = d.accountBalanceHistory.length;
              d3.select('.mouse-line')
                .attr('d', () => {
                  let data = `M${xScale(d.accountBalanceHistory[dateIndex].date)},${height}`;
                  data += ` ${xScale(d.accountBalanceHistory[dateIndex].date)},0`;
                  return data;
                });
            }
            return `translate( ${xScale(d.accountBalanceHistory[dateIndex].date)},${yScale(d.accountBalanceHistory[dateIndex].balance)} )`;
          });

        // finds data for each line and total balance, based on the X position of the mouse
        let totalBalance = 0;

        const selectedBalances = graphData.map(data => {
          const beneFirstName = data.beneficiaryName.split(' ')[0].toUpperCase();
          let balance = Overview.text_NA; // default balance to N/A.
          if (!dayjs(roundedDate).isBefore(data.accountBalanceHistory[0].date, 'day')) { // if date selected is before earliest account history date, leave balance as N/A.
            const accountBalanceHistory = cloneDeep(data.accountBalanceHistory);
            const balanceObject = accountBalanceHistory.reverse().find(balance => dayjs(roundedDate).isSameOrAfter(balance.date)); // finds balance object for the selected date or previous buisness date, if selected date doesn't exist
            balance = balanceObject ? currencyFormatter(balanceObject.balance) : balance; // if account history ends before other selected accounts, set balance as N/A.

            // adds up total balance at bottom of tooltip
            if (balance !== Overview.text_NA && balanceObject) {
              totalBalance += balanceObject.balance;
            }
          }

          return {
            displayName: `${beneFirstName} <br/><span style="color: var(--primary)">${data.accountNumber}<span>`,
            balance,
          };
        });

        // adds formatted total balance to bottom of tooltip
        selectedBalances.push({ displayName: Overview.lbl_total, balance: currencyFormatter(totalBalance) });

        const tooltipLeftPosition = moveTooltipToCenter ? mouseX - 50 : (moveTooltipToLeft ? mouseX - 200 : mouseX + 70);
        // on the tooltip, sets date on top, position of tooltip container
        // eslint-disable-next-line newline-per-chained-call
        const tooltipLine = tooltip.html(dayjs(roundedDate).format('LL')) // add 1 day is necessary, fixes the date coming back off by 1 day from xScale.invert call.
          .style('display', 'block')
          .style('left', `${tooltipLeftPosition}px`)
          .style('top', `${moveTooltipToCenter ? mouseY - 55 : mouseY - (10 + (10 * graphData.length))}px`) // updates the tooltip to go above mouse/finger touch, mainly to prevent issues if mouse is being used on a small screen
          .style('font-size', 8)
          .selectAll()
          .data(selectedBalances)
          .enter() // for each account, list out name, acct #, and balance for that date
          .append('div') // creates the tooltipLine container where each line data will be listed
          .attr('class', styles.tooltipLine);

        // adds legend circle
        tooltipLine.append('div').attr('class', styles.tooltipLegend)
          .append('svg')
          .style('width', '20px')
          .style('height', '20px')
          .append('circle')
          .attr('transform', 'translate( 10 10 )')
          .attr('r', 6)
          .style('fill', (d, i) => color(i))
          .style('display', (d, i) => i === selectedBalances.length - 1 ? 'none' : 'inline');

        // adds name and account number to tooltipLine
        tooltipLine.append('div').attr('class', styles.tooltipName)
          .html(d => d.displayName);
        // adds balance to tooltipLine
        tooltipLine.append('div').attr('class', styles.tooltipBalance)
          .html(d => d.balance);
      });
  }

  componentDidMount() {
    const useTransition = true;
    this.drawChart(useTransition);
  }

  componentDidUpdate({ size, graphData }) {
    const { size: { componentWidth, componentHeight }, } = this.props;
    if (size.componentWidth !== componentWidth || size.componentHeight !== componentHeight || !isEqual(graphData, this.props.graphData)) {
      this.setState({
        componentHeight,
        componentWidth,
      }, () => this.drawChart()); // redraws chart on resize or data change
    }
  }

  render() {
    const { isGraphFullscreen } = this.props;

    return (
      <div className={isGraphFullscreen ? styles.fullscreenGraphContainer : styles.valueGraphContainer} id='chart' >
        <svg className='graph' />
      </div>
    );
  }
}


export default connect(null, {})(sizify(LanguageHOC(BalanceGraph)));
