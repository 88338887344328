import React from 'react';

import styles from './styles.module.scss';

const DELIVERY_METHODS = {
  Online: 'Online',
  Mail: 'Mail',
  Custom: 'Custom',
  Blocked: 'Blocked',
  Select: 'Please Select',
};

export const statementDeliveryMethodToString = (DeliveryMethods) => {
  if (DeliveryMethods) {
    let statementsDeliveryMethod;
    const hasBlocked = DeliveryMethods.some(method => method.DeliveryMethod === DELIVERY_METHODS.Blocked);
    if (!hasBlocked) {
      const isAllOnline = DeliveryMethods.every(method => method.DeliveryMethod === DELIVERY_METHODS.Online);
      const isAllMail = DeliveryMethods.every(method => method.DeliveryMethod === DELIVERY_METHODS.Mail);
      if (isAllOnline) {
        statementsDeliveryMethod = DELIVERY_METHODS.Online;
      }
      else if (isAllMail) {
        statementsDeliveryMethod = DELIVERY_METHODS.Mail;
      }
      else {
        statementsDeliveryMethod = DELIVERY_METHODS.Custom;
      }
    }
    else {
      statementsDeliveryMethod = DELIVERY_METHODS.Select;
    }
    return statementsDeliveryMethod;
  }
  else {
    return 'Not found';
  }
};

export const addressToString = (address, type) => {
  if (address.AddressId) {
    const isReadOnlyAddress = address.Country !== 'US' && address.Country !== '';
    return (
      <div className={styles.addressContainer}>
        <div className={styles.addressType}>{type}</div>
        <div className={styles.addressVerLine}>|</div>
        {address.StreetAddress1 && <div className={styles.addressStreet1}>{address.StreetAddress1}<span className={styles.addressComma}>,</span></div>}
        {address.StreetAddress2 && <div className={styles.addressStreet2}>{address.StreetAddress2}<span className={styles.addressComma}>,</span></div>}
        {address.StreetAddress3 && <div className={styles.addressStreet3}>{address.StreetAddress3}<span className={styles.addressComma}>,</span></div>}
        {address.City && <div className={styles.addressCity}>{address.City}<span className={styles.addressComma}>, </span></div>}
        {(address.PostalCode || address.State) && <div className={styles.addressStatePostal}>{address.State && address.State} {address.PostalCode && address.PostalCode}{isReadOnlyAddress && <span className={styles.addressComma}>,</span>}</div>}
        {isReadOnlyAddress && <div className={styles.addressCountry}> {address.CountryName}</div>}
      </div>
    );
  }
  else
    return null;
};