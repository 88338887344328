/*
*
* PersonInput Component
*
*/
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import {
  DatePicker,
  SsnInput,
} from '@frontend/common';

import styles from '../styles.module.scss';

export class PersonInput extends Component {
  static propTypes = {
    applicationInfo: PropTypes.object.isRequired,
    applicationInfoErrors: PropTypes.object.isRequired,
    personType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    onPersonInput: PropTypes.func.isRequired,
  }

  render() {
    const {
      applicationInfoErrors,
      applicationInfo,
      personType,
      title,
      subtitle,
      onPersonInput
    } = this.props;

    return (
      <Fragment>
        <div className={styles.sectionTitle}>{title}</div>
        {subtitle ? <div className={styles.sectionSubTitle}>{subtitle}</div> : <div className={styles.sectionSubTitle}><div className={styles.emptySubtitle} /></div>}
        <TextField
          autoComplete='off'
          error={Boolean(applicationInfoErrors[personType].firstName)}
          variant='filled'
          fullWidth
          helperText={applicationInfoErrors[personType].firstName}
          label={'First Name'}
          onChange={(e) => onPersonInput(e.target.value, personType, 'firstName')}
          value={applicationInfo[personType].firstName}
        />
        <TextField
          autoComplete='off'
          error={Boolean(applicationInfoErrors[personType].middleName)}
          variant='filled'
          fullWidth
          helperText={applicationInfoErrors[personType].middleName}
          label={'Middle Name'}
          onChange={(e) => onPersonInput(e.target.value, personType, 'middleName')}
          value={applicationInfo[personType].middleName}
        />
        <TextField
          autoComplete='off'
          error={Boolean(applicationInfoErrors[personType].lastName)}
          variant='filled'
          fullWidth
          helperText={applicationInfoErrors[personType].lastName}
          label={'Last Name'}
          onChange={(e) => onPersonInput(e.target.value, personType, 'lastName')}
          value={applicationInfo[personType].lastName}
        />
        <DatePicker
          autoComplete='off'
          error={Boolean(applicationInfoErrors[personType].birthDate)}
          fullWidth
          helperText={applicationInfoErrors[personType].birthDate}
          variant='filled'
          key='birthDate'
          label={'Date of Birth'}
          // labelFunc={'Date of Birth L'}
          // maxDate={dayjs().subtract(MinimumAccountOwnerAge, 'year')}
          // minDate={dayjs().subtract(MaximumAge, 'year')}
          onChange={value => onPersonInput(value, personType, 'birthDate')}
          openTo='year'
          value={applicationInfo[personType].birthDate}
        />
        <SsnInput
          autoComplete='off'
          error={Boolean(applicationInfoErrors[personType].ssn)}
          errorText={applicationInfoErrors[personType].ssn}
          variant='filled'
          key='ssn'
          label={'Social Security Number'}
          onChange={({ value }) => onPersonInput(value, personType, 'ssn')}
          value={applicationInfo[personType].ssn}
        />
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              inputProps={{ 'aria-label': 'Utah Tax Payer' }}
              onClick={() => onPersonInput(!applicationInfo[personType].isUtahPayer, personType, 'isUtahPayer')}
              checked={applicationInfo[personType].isUtahPayer}
            />
          }
          label={'I am a Utah Tax Payer'}
        />
      </Fragment>
    );
  }
}


export default LanguageHOC(PersonInput);
