/*
*
* BeneContact Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { isEqual, } from 'lodash';
import {
  Tab,
  Tabs,
  Button,
  Icon,
} from '@mui/material';
import { InfoIcon, LoadingOverlay, IconBtnTooltip } from '@frontend/common';

import { get2FAPrecondition, getRSAPrecondition } from 'components/AppRoot/Navigation/actions';
import { protectAction } from 'utils/helpers/multifactor_handler';
import { MULTIFACTOR_TYPE } from 'components/AppRoot/Navigation/constants';

import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';

import UpdateBeneAddressModal from './UpdateBeneAddressModal';

const select = state => ({
  ADDRESS_TYPES: state.static.environmentVars.AddressTypes,
  claims: state.session.claims,
});

export class BeneContact extends React.Component {

  static propTypes = {
    get2FAPrecondition: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    setLastClicked: PropTypes.func.isRequired,
    isLastClicked: PropTypes.bool.isRequired,
    addresses: PropTypes.shape({
      Mailing: PropTypes.shape({
        City: PropTypes.string,
        Country: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
      }),
      Physical: PropTypes.shape({
        City: PropTypes.string,
        Country: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
      }),
      Other: PropTypes.shape({
        City: PropTypes.string,
        Country: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
      })
    }).isRequired,
    ADDRESS_TYPES: PropTypes.shape({
      Physical: PropTypes.string,
      Mailing: PropTypes.string,
      Other: PropTypes.string
    }).isRequired,
    canEditAddress: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      BeneContact: PropTypes.shape({
        btn_add_address: PropTypes.string,
        title_update_bene_address: PropTypes.string,
        text_no_address: PropTypes.func,
      }),
      BeneContactForm: PropTypes.shape({
        text_intl_address: PropTypes.string,
      }),
      Login: PropTypes.shape({ nav_path: PropTypes.string }),
    }).isRequired,
  };

  state = {
    addressType: this.props.ADDRESS_TYPES.Mailing,
    Mailing: this.props.addresses.Mailing,
    Physical: this.props.addresses.Physical,
    Other: this.props.addresses.Other,
    showChangeAddressModal: false,
    isLoading: false,

    // For 2FA/RSA checks
    hasPrecondition: false,
  };


  addressTabChange = (e, value) => {
    this.setState({ addressType: value });
  };


  composeAddress = () => {
    const { canEditAddress, text: { BeneContact, BeneContactForm } } = this.props;
    const { StreetAddress1, StreetAddress2, StreetAddress3, City, State, PostalCode, Country, } = this.state[this.state.addressType];
    const isInternationalAddress = (Country !== 'US' && Country !== '');
    const isReadOnlyAddress = !canEditAddress || isInternationalAddress;
    const missingAddressCompose = !isReadOnlyAddress ? (
      <Button
        color='primary'
        startIcon={<Icon>add</Icon>}
        onClick={() => this.handleShowChangeAddressModal()}
      >
        {BeneContact.btn_add_address}
      </Button>
    ) : (<div>{BeneContact.text_no_address(this.state.addressType)}</div>);
    
    

    return (
      this.state[this.state.addressType].AddressId ? (
        <LoadingOverlay show={this.state.isLoading} width='100%'>
          <div className={styles.beneAddressRowContainer}>
            <div className={styles.beneAddressRow}>
              {!isInternationalAddress ? (
                <div className={styles.beneAddress}>
                  <span>{`${StreetAddress1}${StreetAddress2 ? ` ${StreetAddress2}` : ''}, `}</span>
                  <span>{`${City}, ${State} ${PostalCode} `}</span>
                </div>
              ) : (
                <div className={styles.beneAddress}>
                  {StreetAddress1 && <div>{`${StreetAddress1} `}</div>}
                  {StreetAddress2 && <div>{`${StreetAddress2} `}</div>}
                  {StreetAddress3 && <div>{`${StreetAddress3}, `}</div>}
                  {City && <div >{`${City}, `}</div>}
                  {State && <div>{`${State} `}</div>}
                  {PostalCode && <div>{`${PostalCode} `}</div>}
                  {Country && <div>{`${Country} `}</div>}
                </div>
              )
              }
              {canEditAddress && <InfoIcon message={BeneContactForm.text_intl_address} />}
            </div>

            {!isReadOnlyAddress &&
            <div className={styles.buttonIcons}>
              <IconBtnTooltip
                icon='edit'
                onClick={() => this.handleShowChangeAddressModal()}
                title={BeneContact.title_update_bene_address}
              />
            </div>}
          </div>
        </LoadingOverlay>
      ) : (
        <div className={styles.beneAddressRow}>
          {missingAddressCompose}
        </div>
      )

    );
  };

  handleShowChangeAddressModal = () => {
    const multiStatus = protectAction(this.props.claims);
    this.props.setLastClicked();
    this.setState({ isLoading: true });
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState({ hasPrecondition: true });
      this.props.get2FAPrecondition().catch(() => this.setState({ isLoading: false }));
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA) {
      this.setState({ hasPrecondition: true });
      this.props.getRSAPrecondition().catch(() => this.setState({ isLoading: false }));
    }
    else {
      this.setState({ showChangeAddressModal: true, isLoading: false, hasPrecondition: false, });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.addresses, this.props.addresses)) {
      const { Mailing, Physical, Other } = this.props.addresses;
      this.setState({ Mailing, Physical, Other });
    }

    // handle 2FA auth
    const multiStatus = protectAction(this.props.claims);
    if (this.state.hasPrecondition && multiStatus === MULTIFACTOR_TYPE.AUTH && this.props.isLastClicked) {
      this.handleShowChangeAddressModal();
    }
  }

  render() {
    const { addressType, showChangeAddressModal } = this.state;
    const { ADDRESS_TYPES, text: { BeneContact } } = this.props;

    return (
      <div className={parentStyles.beneContact}>
        <div className={styles.beneContact}>
          <Tabs
            onChange={this.addressTabChange}
            value={addressType}
            indicatorColor='primary'
            textColor='primary'
          >
            <Tab label={ADDRESS_TYPES.Mailing} value={ADDRESS_TYPES.Mailing} style={{ minWidth: '100px' }} />
            <Tab label={ADDRESS_TYPES.Physical} value={ADDRESS_TYPES.Physical} style={{ minWidth: '100px' }} />
            <Tab label={ADDRESS_TYPES.Other} value={ADDRESS_TYPES.Other} style={{ minWidth: '100px' }} />
          </Tabs>

          {this.composeAddress()}
        </div>

        <UpdateBeneAddressModal
          show={showChangeAddressModal}
          handleCancel={() => this.setState({ showChangeAddressModal: false })}
          modalTitle={BeneContact.title_update_bene_address}
          addressType={addressType}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, {
  get2FAPrecondition,
  getRSAPrecondition,
})(LanguageHOC(BeneContact)));
