import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import parse from 'html-react-parser';

import {
  Modal,
} from '@frontend/common';

import styles from './styles.module.scss';


PayrollTerms.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.shape({
    PayrollTerms: PropTypes.shape({
      head_terms: PropTypes.string,
      text_terms: PropTypes.array,
      text_terms_authorize: PropTypes.string,
    })
  }),
};

export function PayrollTerms({ open, onClose, text: { PayrollTerms }, }) {


  const renderTerms = () => (
    <div className={styles.terms}>
      <p>{PayrollTerms.text_terms_authorize}</p>
      <ul>
        {PayrollTerms.text_terms.map(line => <li key={line}>{parse(line)}</li>)}
      </ul>
    </div>
  );

  return (
    <Modal
      onCloseModal={onClose}
      show={open}
      title={PayrollTerms.head_terms}
    >
      {renderTerms()}
    </Modal>
  );
}

export default LanguageHOC(PayrollTerms);