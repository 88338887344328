/*
*
* PrrCalculator Component
*
*/
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import CountUp from 'react-countup';
import LanguageHOC from 'utils/translations/LanguageHOC';

import LearnMore from './LearnMore';
import {
  Icon,
  Button,
  Slide,
} from '@mui/material';

import {
  DatePicker,
  LoadingOverlay,
  Modal,
} from '@frontend/common';

import { calendarValidator } from 'utils/helpers/form_validation';

import { getPRRDates, getPRR } from '../../../actions';

import styles from './styles.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction='up' ref={ref} {...props} />;
});

const select = (state) => ({
  minDate: state.accounts.pRRDates.minDate,
  maxDate: state.accounts.pRRDates.maxDate,
});

export class PrrCalculator extends React.Component {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    onCloseHandler: PropTypes.func.isRequired,
    disablePRRButtonInDetails: PropTypes.func.isRequired,
    account: PropTypes.shape({
      accountId: PropTypes.number.isRequired,
      beneficiary: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      option: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired
    }).isRequired,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    getPRRDates: PropTypes.func.isRequired,
    getPRR: PropTypes.func.isRequired,
    text: PropTypes.shape({
      PrrCalculator: PropTypes.object.isRequired,
    }).isRequired,
  }

  state = {
    loading: false,
    learnMoreOpen: false,
    startDate: null, // dayjs object
    endDate: null, // dayjs object
    startDateError: '',
    endDateError: '',
    pRRInit: 0,
    pRR: 0,
  }

  resetErrorsAndPRR = () => {
    this.setState({
      startDateError: '',
      endDateError: '',
      pRR: 0,
    });
  }

  onStartDateInput = (value) => {
    this.resetErrorsAndPRR();
    this.setState({ startDate: value });
  }

  onEndDateInput = (value) => {
    this.resetErrorsAndPRR();
    this.setState({ endDate: value });
  }

  validateForm = (startDate, endDate) => {
    const { minDate, maxDate } = this.props;
    let startDateError = '';
    let endDateError = '';

    startDateError = calendarValidator(startDate, minDate, maxDate);
    const startDatePlus1Day = dayjs(startDate).add(1, 'day');
    const minEndDate = startDatePlus1Day.isBefore(minDate) ? dayjs(minDate) : startDatePlus1Day.isAfter(maxDate) ? dayjs(maxDate) : startDatePlus1Day;
    endDateError = calendarValidator(endDate, minEndDate, maxDate);
    this.setState({ startDateError, endDateError });

    return !(Boolean(startDateError) || Boolean(endDateError));
  }

  onCalculateHandler = () => {
    const { startDate, endDate } = this.state;
    const { getPRR, account: { accountId }, minDate } = this.props;

    // since countup is setting loading to false it needs to reset pRR each time in case user
    // repeatedly clicks calculate without changing the dates
    this.setState({ pRR: 0 }, () => {
      if (this.validateForm(startDate, endDate)) {
        this.setState({ loading: true });
        // check if user selected date before account was opened, if yes reset start date
        const resetStartDate = startDate.isBefore(minDate) ? minDate : startDate;
        this.setState({ startDate: resetStartDate });

        getPRR(
          accountId,
          dayjs(resetStartDate).format('MM-DD-YYYY'), // eslint-disable-line
          dayjs(endDate).format('MM-DD-YYYY') // eslint-disable-line
        )
          .then((response) => {
            const pRR = response.payload.data;
            this.setState({ pRR });
            if (pRR === -100 || pRR === 0.00)
              this.setState({ loading: false });
          })
          .catch(() => this.setState({ loading: false }));
      }
    });
  }

  calculateDates = (minDate, maxDate) => {
    const openAccountDate = dayjs(minDate);
    const endDate = dayjs(maxDate);
    const previousYear = dayjs().subtract(1, 'year').format('YYYY'); // eslint-disable-line
    const lastDateOfPreviousYear = dayjs(`${previousYear}-12-31`);

    let startDate;
    if (
      endDate.isBefore(lastDateOfPreviousYear) ||
      lastDateOfPreviousYear.isBefore(openAccountDate)
    ) {
      startDate = openAccountDate;
    }
    else {
      startDate = lastDateOfPreviousYear;
    }

    return ({ startDate, endDate }); // returns dayjs objects
  }

  // Methods to render components
  renderNavigationButton = (label, onClick, variant, fullWidth = false, disabled = false, color = 'primary') => {
    return (
      <Button
        key={`button_${label}`}
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        color={color}
        fullWidth={fullWidth}
      >
        {label}
      </Button>
    );
  }

  componentDidMount() {
    const {
      getPRRDates,
      getPRR,
      disablePRRButtonInDetails,
      account: {
        accountId
      },
    } = this.props;

    this.setState({ loading: true });
    getPRRDates(accountId)
      .then(() => {
        // disable PRR button in details and stop execution here
        if (this.props.minDate === null || this.props.maxDate === null) {
          disablePRRButtonInDetails();
        }
        else {
          const dates = this.calculateDates(this.props.minDate, this.props.maxDate);
          return getPRR(
            accountId,
            dates.startDate.format('MM-DD-YYYY'),
            dates.endDate.format('MM-DD-YYYY')
          )
            .then((response) => {
              const pRR = response.payload.data;
              this.setState({
                pRR,
                pRRInit: pRR,
                startDate: dates.startDate,
                endDate: dates.endDate,
              });
              if (pRR === -100 || pRR === 0.00)
                this.setState({ loading: false });
            });
        }
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      const dates = this.calculateDates(this.props.minDate, this.props.maxDate);
      this.setState({
        startDate: dates.startDate,
        endDate: dates.endDate,
        startDateError: '',
        endDateError: '',
        pRR: this.state.pRRInit,
      });
    }
  }

  render() {
    const {
      loading,
      startDate,
      endDate,
      startDateError,
      endDateError,
      pRR,
      learnMoreOpen,
    } = this.state;

    const {
      account,
      text: { PrrCalculator },
      minDate,
      maxDate,
    } = this.props;

    let prrClass, prrArrowIcon;
    if (pRR === -100) { // API response for PRR not available
      prrClass = styles.pprNotAvailable;
      prrArrowIcon = '';
    }
    else if (pRR < 0) {
      prrClass = styles.negativeReturn;
      prrArrowIcon = 'arrow_drop_down';
    }
    else if (pRR === 0) {
      prrArrowIcon = '';
    }
    else {
      prrClass = styles.positiveReturn;
      prrArrowIcon = 'arrow_drop_up';
    }

    return (
      <Fragment>
        <Modal
          show={this.props.open}
          onCloseModal={this.props.onCloseHandler}
          title={PrrCalculator.title}
          DialogProps={{ 'TransitionComponent': Transition, 'fullScreen': true }}
        >
          <div className={styles.prrCalculator}>
            {PrrCalculator.subtitle}
            <div className={styles.beneficiary}>
              <p className={styles.dataTILE}>{account.beneficiary.name}</p>
              <label className={styles.labelTILE}>{account.option.name}</label>
              <div className={styles.rorContainer}>
                <span className={styles.rorTitle}>{PrrCalculator.rate_of_return_txt}</span>
                <div className={`${styles.ppr} ${prrClass}`}>
                  {
                    pRR !== -100
                      ?
                      <Fragment>
                        <Icon>{prrArrowIcon}</Icon>
                        <CountUp
                          start={0.00}
                          end={pRR}
                          duration={1.50}
                          separator=' '
                          decimals={2}
                          decimal='.'
                          prefix=''
                          suffix='%'
                          onEnd={() => this.setState({ loading: false })}
                          onStart={() => null}
                        />
                      </Fragment>
                      :
                      <div className={styles.NA}>
                        {PrrCalculator.prr_not_available}
                      </div>
                  }
                </div>
              </div>

              <div>
                <DatePicker
                  error={Boolean(startDateError)}
                  helperText={startDateError}
                  fullWidth
                  variant='filled'
                  key='startDate'
                  label={PrrCalculator.start_date_label}
                  minDate={dayjs(minDate)}
                  maxDate={dayjs(maxDate)}
                  onChange={this.onStartDateInput}
                  value={startDate}
                />
              </div>

              <div>
                <DatePicker
                  error={Boolean(endDateError)}
                  helperText={endDateError}
                  fullWidth
                  variant='filled'
                  key='endDate'
                  label={PrrCalculator.end_date_label}
                  minDate={dayjs(startDate).add(1, 'day')}
                  maxDate={dayjs(maxDate)}
                  onChange={this.onEndDateInput}
                  value={endDate}
                />
              </div>

              <div className={styles.buttons}>
                <LoadingOverlay show={loading}>
                  {this.renderNavigationButton(PrrCalculator.btn_calculate, this.onCalculateHandler, 'contained', true, loading)}
                </LoadingOverlay>

                <div className={styles.buttons}>
                  {this.renderNavigationButton(PrrCalculator.btn_learnmore, () => this.setState({ learnMoreOpen: true }), 'outlined', true)}
                </div>
              </div>

            </div>
          </div>
        </Modal>

        <LearnMore
          onClose={() => this.setState({ learnMoreOpen: false })}
          open={learnMoreOpen}
        />
      </Fragment>
    );
  }
}

export default connect(select, {
  getPRRDates,
  getPRR,
})(LanguageHOC(PrrCalculator));

