/*
*
* UsernameCard Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  CHANGE_USERNAME,
} from 'components/AppRoot/Navigation/constants';

import {
  CardTitle,
  CardButtons,
  LoadingOverlay,
  notificationShow,
  PasswordInput,
} from '@frontend/common';

import {
  Button,
  TextField
} from '@mui/material';

import { changeUsername } from 'components/AppRoot/Navigation/actions';

export class UsernameCard extends React.Component {
  static propTypes = {
    changeUsername: PropTypes.func.isRequired,
    currentUsername: PropTypes.string.isRequired,
    notificationShow: PropTypes.func.isRequired,
    displayUnsavedChangesPrompt: PropTypes.func.isRequired,
    hasTitle: PropTypes.bool,
    text: PropTypes.shape({
      UsernameCard: PropTypes.shape({
        btn_change_username: PropTypes.string,
        err_different_username: PropTypes.string,
        err_required: PropTypes.string,
        err_username_length: PropTypes.string,
        err_username_min_length: PropTypes.string,
        err_username_spaces: PropTypes.string,
        err_username_with_numbers: PropTypes.string,
        head_title: PropTypes.string,
        lbl_current_password: PropTypes.string,
        lbl_current_username: PropTypes.string,
        lbl_new_username: PropTypes.string,
        msg_username_changed: PropTypes.string,
      }),
    }).isRequired,
  };

  static defaultProps = {
    hasTitle: true,
  };

  state = {
    apiErrors: [],
    formErrors: [],
    isSaving: false,
    newUsername: '',
    password: '',
  };

  errorGet = name => {
    const error = this.state.formErrors.find(error => error.name === name);
    return error ? error.message : '';
  }

  formValidate = () => {
    const { apiErrors, newUsername, password } = this.state;
    const { err_different_username, err_required, err_username_length, err_username_with_numbers, err_username_min_length, err_username_spaces } = this.props.text.UsernameCard;
    const formErrors = [];

    if (!password) {
      formErrors.push({
        name: 'password',
        message: err_required
      });
    }

    if (!newUsername) {
      formErrors.push({
        name: 'newUsername',
        message: err_required
      });
    }

    if (newUsername.toLowerCase() === this.props.currentUsername.toLowerCase()) {
      formErrors.push({
        name: 'newUsername',
        message: err_different_username
      });
    }

    if (newUsername.match(/\d\d\d\d/)) {
      formErrors.push({
        name: 'newUsername',
        message: err_username_with_numbers
      });
    }

    if (newUsername.length > 40) {
      formErrors.push({
        name: 'newUsername',
        message: err_username_length
      });
    }

    if (newUsername.match(/^\s+|\s+$/)) {
      formErrors.push({
        name: 'newUsername',
        message: err_username_spaces
      });
    }

    if (newUsername.length > 0 && newUsername.length < 6) {
      formErrors.push({
        name: 'newUsername',
        message: err_username_min_length
      });
    }

    if (apiErrors.length > 0) {
      apiErrors.forEach(error => {
        if (error.Field === 'Username') {
          formErrors.push({
            name: 'newUsername',
            message: error.Message
          });
        }
        if (error.Field === 'Password' || error.Field === 'password') {
          formErrors.push({
            name: 'password',
            message: error.Message
          });
        }
      });
    }

    this.setState({
      apiErrors: [],
      formErrors
    });

    return formErrors.length === 0;
  }

  updateButtonIsDisabled = () => {
    return !this.state.newUsername || this.props.currentUsername === this.state.newUsername;
  }

  onInputChange = (event, name) => {
    this.setState({
      [name]: event.target.value,
      formErrors: this.state.formErrors.filter(error => error.name !== name)
    });
  }

  usernameChange = (event) => {
    event.preventDefault();

    if (this.formValidate()) {
      this.setState({ isSaving: true });
      this.props.changeUsername(this.state.newUsername, this.state.password)
        .then(() => {
          this.setState({
            isSaving: false,
            newUsername: '',
            password: '',
          });
          this.props.notificationShow(this.props.text.UsernameCard.msg_username_changed, 'success');
        })
        .catch(response => {
          this.setState({
            apiErrors: response.payload.data,
            isSaving: false,
          }, () => this.formValidate());
        });
    }
  }

  componentDidUpdate() {
    if (this.state.newUsername && this.props.currentUsername !== this.state.newUsername) {
      this.props.displayUnsavedChangesPrompt(CHANGE_USERNAME, true);
    }
    else {
      this.props.displayUnsavedChangesPrompt(CHANGE_USERNAME, false);
    }
  }

  render() {
    const { isSaving, newUsername, password } = this.state;
    const { currentUsername, text: { UsernameCard }, hasTitle } = this.props;
    return (
      <form onSubmit={this.usernameChange}>
        <div>
          {hasTitle && <CardTitle>{this.props.text.UsernameCard.head_title}</CardTitle>}
          <div>
            <TextField
              disabled
              fullWidth
              label={UsernameCard.lbl_current_username}
              name='currentUsername'
              value={currentUsername}
            />
            <TextField
              error={Boolean(this.errorGet('newUsername'))}
              fullWidth
              helperText={this.errorGet('newUsername')}
              label={UsernameCard.lbl_new_username}
              name='newUsername'
              onChange={e => this.onInputChange(e, 'newUsername')}
              value={newUsername}
              inputProps={{ form: { autocomplete: 'off' } }}
            />
            <PasswordInput
              autoComplete='current-password'
              errorText={this.errorGet('password')}
              fullWidth
              label={UsernameCard.lbl_current_password}
              name='CurrentPassword'
              onChange={e => this.onInputChange(e, 'password')}
              type='password'
              value={password}
              variant='standard'
            />
          </div>
        </div>
        <CardButtons>
          <LoadingOverlay show={isSaving}>
            <Button
              disabled={isSaving || this.updateButtonIsDisabled()}
              onClick={this.usernameChange}
              type='submit'
              variant='contained'
            >
              {UsernameCard.btn_change_username}
            </Button>
          </LoadingOverlay>
        </CardButtons>
      </form>
    );
  }
}


export default connect(null, {
  changeUsername,
  notificationShow,
})(LanguageHOC(UsernameCard));