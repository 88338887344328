import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Checkbox,
  FormHelperText,
  FormControl,
  FormControlLabel,
} from '@mui/material';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';

import {
  CalculatorForm,
} from '@frontend/common';

import TermsAndConditions from '../TermsAndConditions';

import styles from '../styles.module.scss';

const select = (state) => ({
  ServerDateTime: state.static.ServerDateTime,
  documents: state.static.documents ? state.static.documents : {},
});

export class Step3Submit extends React.Component {
  static propTypes = {
    selectedOptionName: PropTypes.string.isRequired,
    selectedCategory: PropTypes.string.isRequired,
    formInputs: PropTypes.shape({
      checked: PropTypes.bool,
    }),
    formErrors: PropTypes.shape({
      checked: PropTypes.string,
    }),
    onChangeHandle: PropTypes.func.isRequired,
    currentSchema: PropTypes.object.isRequired,
    matrixAllocations: PropTypes.array.isRequired,
    isCustomized: PropTypes.bool.isRequired,
    ServerDateTime: PropTypes.string,
    documents: PropTypes.object,
  };

  state = { 
    showCustomized: false,
  };

  render() {
    const { 
      formInputs, formErrors, onChangeHandle, selectedOptionName, isCustomized,
      ServerDateTime, selectedCategory, currentSchema, documents,
    } = this.props;

    const {
      showCustomized
    } = this.state;

    return (
      <div className={styles.Submit_container}>
        <div className={styles.selectedOption}>Selected Option</div>
        {isCustomized ?
          <div
            className={styles.Submit_collapsableCustomizedName}
            onClick={() => this.setState({ showCustomized: !showCustomized })}
          >
            <div />
            <div>{selectedCategory}</div>
            <div>
              {showCustomized ? (
                <ExpandLessIcon className='hideOnPrint' />
              ) : (
                <ExpandMoreIcon className='hideOnPrint' />
              )}
            </div>
          </div>
          :
          <div>{selectedOptionName}</div>
        }
        {isCustomized && showCustomized &&
          <CalculatorForm
            stickyHeaderTopOffset={60}
            schema={currentSchema}
            isViewing={true}
            currentDate={ServerDateTime}
            hideTemplateName={true}
          />
        }
        <TermsAndConditions documents={documents} />
        <FormControl
          error={Boolean(formErrors.checked)}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={formInputs.checked}
                onChange={(e) => onChangeHandle({ checked: e.target.checked })}
                value='primary'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={'I have read and understand the above Terms and Conditions.'}
          />
          <FormHelperText style={{ textAlign: 'center', fontSize: '100%' }}>{formErrors.checked}</FormHelperText>
        </FormControl>
      </div>
    );
  }
}

export default connect(select, {}, null)(Step3Submit);