/*
 *
 * Step0AmountAndBank Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Button,
  Icon,
  FormHelperText
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  CurrencyInput,
  currencyFormatter,
  Dropdown,
  LoadingOverlay,
} from '@frontend/common';

import styles from '../styles.module.scss';

const muiStyles = () => ({
  root: {
    whiteSpace: 'normal',
    wordBreak: 'normal',
    overflowWrap: 'anywhere'
  }
});

Step0AmountAndBank.propTypes = {
  classes: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  agentBankAccounts: PropTypes.array.isRequired,
  bankAccountsLoading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  toggleShowAddBank: PropTypes.func.isRequired,
  contribution: PropTypes.object.isRequired,
  contributionDetails: PropTypes.object.isRequired, 
  contributionEnvironmentVars: PropTypes.object.isRequired,
  errorGet: PropTypes.func.isRequired,
  accountSelect: PropTypes.func.isRequired,
  amountHandle: PropTypes.func.isRequired,
  bankAccountSelectHandle: PropTypes.func.isRequired,
  text: PropTypes.shape({
    ContributionRecurringStep0: PropTypes.shape({
      btn_add_bank: PropTypes.string,
      err_amount_between: PropTypes.func,
      lbl_amount: PropTypes.string,
      lbl_bank_account: PropTypes.string,
      lbl_selected_account: PropTypes.string,
      text_add_new_bank: PropTypes.string,
    })
  })
};

export function Step0AmountAndBank({
  classes,
  isEditing,
  agentBankAccounts,
  bankAccountsLoading,
  toggleShowAddBank,
  contribution,
  contributionDetails, 
  contributionEnvironmentVars: { MinimumRecurringOnlineContribution, MaximumRecurringOnlineContribution, },
  errorGet,
  accountSelect,
  amountHandle,
  bankAccountSelectHandle,
  text: { ContributionRecurringStep0 },
}) {
  const rowIndex = 0; //hard coding rowIndex as 0 since only one account can be selected in Recurring
  const agentAccountsFormatted = agentBankAccounts.map(account => {
    if (account.Nickname && account.Nickname.length > 0) {
      return {
        value: account.BankAccountId,
        display: `${account.Nickname}, ${account.MaskedBankAccountNumber}`
      };
    }
    else {
      return {
        value: account.BankAccountId,
        display: account.BankAccountId === 0 ? account.BankName : `${account.BankName}, ${account.MaskedBankAccountNumber}`
      };
    }
  });
  return (
    <React.Fragment>
      <div className={styles.step0Container} key='accountAndAmountContainer'>
        <div className={styles.accountListDropdown}>
          <Dropdown
            label={ContributionRecurringStep0.lbl_selected_account}
            value={contribution.selectedAccounts[rowIndex].accountId}
            onChange={(value) => accountSelect(value, rowIndex)}
            options={(isEditing ? contribution.selectedAccounts : contributionDetails.canContributeAccountList).map(account => ({
              value: account.accountId,
              display: `${account.beneficiary.name.toUpperCase()} ...${account.accountNumber.toString().slice(account.accountNumber.toString().length - 5)}`,
              props: {
                disabled: (contribution.selectedAccounts.some(selAccount => selAccount.accountId === account.accountId) 
                && contribution.selectedAccounts[rowIndex].accountId !== account.accountId)
                || account.overMaxBalance,
              },
            }))}
            FormControlProps={{ style: { width: '100%', minWidth: '256px', textAlign: 'left' } }}
            MenuItemProps={{
              classes: { root: classes.root }
            }}
          />
        </div>
        <div className={styles.amount} key='amountContainer'>
          <CurrencyInput
            className={styles.currencyInput}
            errorText={errorGet('amount')}
            label={ContributionRecurringStep0.lbl_amount}
            onChange={amountHandle}
            value={contribution.selectedAccounts[rowIndex].contributionAmount}
          />
        </div>

        {agentBankAccounts.length > 0 ?
          <div className={styles.bankAccount}>
            <LoadingOverlay show={bankAccountsLoading}>
              <Dropdown
                label={ContributionRecurringStep0.lbl_bank_account}
                value={contribution.bankAccount.BankAccountId}
                errorText={errorGet('bankAccount')}
                onChange={bankAccountSelectHandle}
                options={agentAccountsFormatted}
                FormControlProps={{ style: { width: '256px', textAlign: 'left' } }}
                MenuItemProps={{
                  classes: { root: classes.root }
                }}
              />
            </LoadingOverlay>

            <div className={styles.bankAccount}>
              <LoadingOverlay show={bankAccountsLoading}>
                <Button
                  color='secondary'
                  startIcon={<Icon>add</Icon>}
                  onClick={toggleShowAddBank}
                >
                  {ContributionRecurringStep0.btn_add_bank}
                </Button>
              </LoadingOverlay>
            </div>
          </div>
          :
          <div className={styles.noBankAccount}>
            <div>
              <Button
                onClick={toggleShowAddBank}
                variant='contained'
              >
                {ContributionRecurringStep0.btn_add_bank}
              </Button>
            </div>
            <div className={styles.errorsContainer}>
              {errorGet('bankAccount') &&
                <FormHelperText 
                  error={true}
                  style={{ margin: '0' }}
                >
                  {ContributionRecurringStep0.text_add_new_bank}
                </FormHelperText>
              }
            </div>
          </div>
        }
      </div>

      <div className={styles.errorsContainer}>
        {errorGet('amount') &&
          <FormHelperText 
            error={true}
            style={{ marginTop: '10' }}
          >
            {ContributionRecurringStep0.err_amount_between(currencyFormatter(MinimumRecurringOnlineContribution), currencyFormatter(MaximumRecurringOnlineContribution), currencyFormatter(MaximumRecurringOnlineContribution / 2))}
          </FormHelperText>
        }
      </div>
    </React.Fragment>
  );
}

export default (LanguageHOC(withStyles(muiStyles)(Step0AmountAndBank)));