/*
*
* BeneTable Component
*
*/
import React from 'react';
import PropTypes, { number } from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  CardAligned,
  ConfirmModal,
  LoadingOverlay,
} from '@frontend/common';

import {
  Checkbox,
} from '@mui/material';

import styles from './styles.module.scss';

export class BeneTable extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    lpoa: PropTypes.shape({
      PoaName: PropTypes.string,
      BeneficiaryInfo: PropTypes.array.isRequired,
    }),
    disableRevoke: PropTypes.bool.isRequired,
    selectedBeneIds: PropTypes.arrayOf(number).isRequired,
    onCheckboxClickHandler: PropTypes.func.isRequired,
    renderNavigationButton: PropTypes.func.isRequired,
    selectAllClickHandler: PropTypes.func.isRequired,
    unlinkLpoa: PropTypes.func.isRequired,
    revokeSelectedBenesHandler: PropTypes.func.isRequired,
    editSelectedBenesHandler: PropTypes.func.isRequired,
    openConfirmModalRevokeBene: PropTypes.bool.isRequired,
    openConfirmModalUnlinkLpoa: PropTypes.bool.isRequired,
    setOpenConfirmModalRevokeBene: PropTypes.func.isRequired,
    setOpenConfirmModalUnlinkLpoa: PropTypes.func.isRequired,
    cancelConfirmModalRevokeBene: PropTypes.func.isRequired,
    cancelConfirmModalUnlinkLpoa: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Lpoa: PropTypes.shape({
        modal_title_revoke_benes: PropTypes.string.isRequired,
        modal_body_revoke_benes: PropTypes.string.isRequired,
        modal_title_unlink_lpoa: PropTypes.string.isRequired,
        nav_title: PropTypes.string.isRequired,
        column_labels: PropTypes.arrayOf(PropTypes.string).isRequired,
        text_yes: PropTypes.string.isRequired,
        text_no: PropTypes.string.isRequired,
        text_disabled: PropTypes.string.isRequired,
        checkbox_all_alt_text: PropTypes.string.isRequired,
        checkbox_select_bene_alt_text: PropTypes.string.isRequired,
        table_title: PropTypes.string.isRequired,
        btn_add_edit: PropTypes.string.isRequired,
        btn_add_edit_help_txt: PropTypes.string.isRequired,
        btn_revoke: PropTypes.string.isRequired,
        btn_unlink: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  render() {
    const {
      loading,
      lpoa: { PoaName, BeneficiaryInfo },
      selectedBeneIds,
      disableRevoke,
      onCheckboxClickHandler,
      renderNavigationButton,
      selectAllClickHandler,
      editSelectedBenesHandler,
      unlinkLpoa,
      revokeSelectedBenesHandler,
      openConfirmModalRevokeBene,
      openConfirmModalUnlinkLpoa,
      setOpenConfirmModalRevokeBene,
      setOpenConfirmModalUnlinkLpoa,
      cancelConfirmModalRevokeBene,
      cancelConfirmModalUnlinkLpoa,
      text: {
        Lpoa: {
          nav_title,
          modal_title_revoke_benes,
          modal_body_revoke_benes,
          modal_title_unlink_lpoa,
          column_labels,
          text_yes,
          text_no,
          text_disabled,
          checkbox_all_alt_text,
          checkbox_select_bene_alt_text,
          table_title,
          btn_add_edit,
          btn_add_edit_help_txt,
          btn_revoke,
          btn_unlink,
        },
      },
    } = this.props;

    const isSelected = (beneId) => selectedBeneIds.indexOf(beneId) !== -1;
    const numSelected = selectedBeneIds.length;
    const rowCount = BeneficiaryInfo.length;
    const noSelection = selectedBeneIds.length === 0;

    const results = BeneficiaryInfo.length === 0
      ? 'No beneficiaries.'
      : BeneficiaryInfo.map((bene, index) => {
        const isItemSelected = isSelected(bene.BeneficiaryId);
        return (
          <div className={`${styles.dashRow} ${index === 0 ? styles.borderTop : ''}`} key={bene.BeneficiaryId}>
            <div className={styles.checkbox}>
              <p className={styles.dataTILE}>
                <Checkbox
                  color='primary'
                  inputProps={{ 'aria-label': checkbox_select_bene_alt_text }}
                  onClick={(e) => onCheckboxClickHandler(e, bene.BeneficiaryId)}
                  checked={isItemSelected}
                />
              </p>
            </div>
            <div className={styles.beneficiary}>
              <label className={styles.labelTILE}>{column_labels[0]}</label>
              <p className={styles.dataTILE}>{bene.BeneficiaryName}</p>
            </div>
            <div className={styles.poaLevel}>
              <label className={styles.labelTILE}>{column_labels[1]}</label>
              <p className={styles.dataTILE} style={{ textTransform: 'capitalize' }}>{bene.PoaLevel === 0 ? text_disabled : bene.PoaLevel}</p>
            </div>
            <div className={styles.fedTaxDocs}>
              <label className={styles.labelTILE}>{column_labels[2]}</label>
              <p className={styles.dataTILE} style={{ textTransform: 'capitalize' }}>{bene.FederalTaxDocs ? text_yes : text_no}</p>
            </div>
            <div className={styles.stateTaxDocs}>
              <label className={styles.labelTILE}>{column_labels[3]}</label>
              <p className={styles.dataTILE} style={{ textTransform: 'capitalize' }}>{bene.StateTaxDocs ? text_yes : text_no}</p>
            </div>
          </div>
        );
      });

    return (
      <div className={styles.lpoaList}>
        <div className={styles.lpoaHeader}>
          <div className={styles.lpoaHeaderName}>
            <label className={styles.labelTILE}>{nav_title}</label>
            <p className={styles.dataTILE}>{PoaName}</p>
          </div>
          {renderNavigationButton(btn_unlink, setOpenConfirmModalUnlinkLpoa, 'contained')}
        </div>

        <div className={styles.paperContainer}>
          <div className={styles.paperTitle}>{table_title}</div>
          <CardAligned>
            <LoadingOverlay show={loading} width='100%'>
              <div className={styles.actionHeader}>
                <div className={styles.addEditHelpText}>{btn_add_edit_help_txt}</div>
                <div className={styles.actionButtons}>
                  <div className={styles.button}>
                    <Checkbox
                      color='primary'
                      inputProps={{ 'aria-label': checkbox_all_alt_text }}
                      onClick={selectAllClickHandler}
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                    />
                  </div>
                  {renderNavigationButton(btn_add_edit, editSelectedBenesHandler, 'contained', noSelection)}
                  {renderNavigationButton(btn_revoke, setOpenConfirmModalRevokeBene, 'text', disableRevoke || noSelection)}
                </div>
              </div>

              {results}
            </LoadingOverlay>
          </CardAligned>
        </div>
        <ConfirmModal
          body={modal_body_revoke_benes}
          isLoading={loading}
          key={'confirmModalRevokeBenes'}
          onConfirm={revokeSelectedBenesHandler}
          onModalClose={cancelConfirmModalRevokeBene}
          show={openConfirmModalRevokeBene}
          title={modal_title_revoke_benes}
        />
        <ConfirmModal
          body={modal_body_revoke_benes}
          isLoading={loading}
          key={'confirmModalUnlinkLpoa'}
          onConfirm={unlinkLpoa}
          onModalClose={cancelConfirmModalUnlinkLpoa}
          show={openConfirmModalUnlinkLpoa}
          title={modal_title_unlink_lpoa}
        />
      </div>
    );
  }
}


export default LanguageHOC(BeneTable);
