import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  IconBtnTooltip,
  InfoIcon,
} from '@frontend/common';

import UpdateAgentAddressModal from './UpdateAgentAddressModal';
import UpdateBeneAddressModal from '../../../../AccountDetails/BeneContact/UpdateBeneAddressModal';

import {
  ACCOUNT_OWNER,
  BENEFICIARY,
  ACCOUNT_TYPE_UGMA_UTMA,
} from '../../constants';

import styles from './styles.module.scss';

const tippyProps = {
  trigger: 'click',
  hideOnClick: true,
  interactive: true,
};

const select = (state) => ({
  preTransactionDetails: state.transactions.preTransactionDetails,
});

export class CheckAddress extends React.Component {
  static propTypes = {
    stepIndex: PropTypes.number.isRequired,
    recipientAddress: PropTypes.object.isRequired,
    recipient: PropTypes.string.isRequired,
    agentAddressChanged: PropTypes.bool.isRequired,
    beneficiaryAddressChanged: PropTypes.bool.isRequired,
    preTransactionDetails: PropTypes.shape({
      AccountType: PropTypes.string.isRequired,
    }),
    text: PropTypes.shape({
      Payments: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
      Withdrawals: PropTypes.shape({
        btn_change_agent_address: PropTypes.string,
        btn_change_bene_address: PropTypes.string,
        check_mailing_text: PropTypes.string,
        ao_school_address_modal_title: PropTypes.string,
        bene_school_address_modal_title: PropTypes.string,
        no_payment_accounts_text: PropTypes.func,
      }),
    }).isRequired,
  };

  state = {
    showChangeAddressModal: false,
    accountIsUGMAUTMA: this.props.preTransactionDetails.AccountType === ACCOUNT_TYPE_UGMA_UTMA,
  };

  addressChangeMsg = (checkRecipient) => {
    return <span className={styles.error}>{`Check withdrawals to the ${checkRecipient} are disabled because of a recent mailing address change.`}</span>;
  };

  render() {
    const { showChangeAddressModal, accountIsUGMAUTMA } = this.state;
    const {
      stepIndex,
      recipientAddress,
      recipient,
      agentAddressChanged,
      beneficiaryAddressChanged,
      text: {
        Payments,
        Withdrawals: {
          btn_change_agent_address,
          btn_change_bene_address,
          check_mailing_text,
          ao_school_address_modal_title,
          bene_school_address_modal_title,
          no_payment_accounts_text,
        }
      },
    } = this.props;

    const onClickUrl = () => this.props.history.push(Payments.nav_path);

    const checkNote = stepIndex !== 3 && (
      <div className={styles.checkMailingMessage}>
        <strong>{check_mailing_text}</strong>
      </div>
    );

    if (recipient === ACCOUNT_OWNER) {
      const agent = recipientAddress.agent;
      return (
        <div className={styles.checkAddressContainer}>
          {agentAddressChanged ? this.addressChangeMsg('account agent/account owner') : checkNote}
          <div className={stepIndex !== 3 ? styles.rowCentered : styles.row}>
            <div>
              <p>{agent.line1}</p>
              {agent.line2 && <p>{agent.line2}</p>}
              <p>{agent.street1}</p>
              {agent.street2 && <p>{agent.street2}</p>}
              <p>{agent.city}, {agent.state.Code} {agent.postalCode}</p>
            </div>
            {stepIndex === 2 &&
              <div>
                <IconBtnTooltip
                  icon='edit'
                  onClick={() => this.setState({ showChangeAddressModal: true })}
                  title={btn_change_agent_address}
                />
                {!accountIsUGMAUTMA &&
                  <InfoIcon
                    message={no_payment_accounts_text(onClickUrl)}
                    tippyProps={tippyProps}
                  />
                }

              </div>
            }
          </div>

          <UpdateAgentAddressModal
            show={showChangeAddressModal}
            handleCancel={() => this.setState({ showChangeAddressModal: false })}
            modalTitle={ao_school_address_modal_title}
          />
        </div>
      );
    }
    else if (recipient === BENEFICIARY) {
      const beneficiary = recipientAddress.beneficiary;
      return (
        <div className={styles.checkAddressContainer}>
          {beneficiaryAddressChanged ? this.addressChangeMsg('beneficiary') : checkNote}
          <div className={stepIndex !== 3 ? styles.rowCentered : styles.row}>
            <div>
              <p>{beneficiary.name}</p>
              <p>{beneficiary.street1}</p>
              {beneficiary.street2 && <p>{beneficiary.street2}</p>}
              <p>{beneficiary.city}, {beneficiary.state.Code} {beneficiary.postalCode}</p>
            </div>
            {stepIndex === 2 &&
              <div>
                <IconBtnTooltip
                  icon='edit'
                  onClick={() => this.setState({ showChangeAddressModal: true })}
                  title={btn_change_bene_address}
                />
                {!accountIsUGMAUTMA &&
                  <InfoIcon
                    message={no_payment_accounts_text(onClickUrl)}
                    tippyProps={tippyProps}
                  />
                }
              </div>
            }
          </div>

          <UpdateBeneAddressModal
            show={showChangeAddressModal}
            handleCancel={() => this.setState({ showChangeAddressModal: false })}
            modalTitle={bene_school_address_modal_title}
          />
        </div>


      );
    }
    else {
      const school = recipientAddress.school;
      return (
        <div className={styles.checkAddressContainer}>
          {checkNote}
          <p>{school.name}</p>
          {school.attn && <p>{school.attn}</p>}
          <p>{school.street1}</p>
          <p>{school.city} {school.state.Code} {school.postalCode}</p>
          <p>FBO: {school.fbo ? school.fbo : school.beneficiaryName}</p>
          {school.studentIdNum && <p>Student ID: {school.studentIdNum}</p>}
        </div>
      );
    }
  }
}

export default withRouter(connect(select, null)(LanguageHOC(CheckAddress)));
