import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import LanguageHOC from 'utils/translations/LanguageHOC';

import ReviewDetails from './ReviewDetails';

import styles from '../styles.module.scss';

export class Step3PrintReview extends React.Component {
  static propTypes = {
    contribution: PropTypes.object.isRequired,
    nextRunDates: PropTypes.object.isRequired,
    createdDate: PropTypes.string.isRequired,
    text: PropTypes.shape({
      ContributionRecurringStep3: PropTypes.shape({
        text_print_copy: PropTypes.string,
        text_timezone: PropTypes.string,
      })
    })
  }

  render() {
    const { contribution, nextRunDates, createdDate, text, } = this.props;
    return (
      <div className={styles.printContainer}>
        <ReviewDetails
          contribution={contribution}
          nextRunDates={nextRunDates}
        />
        <div className={styles.currentTime}>{dayjs(createdDate)
          .format('MM/DD/YYYY h:mm A')} {text.ContributionRecurringStep3.text_timezone}</div>
        <div className={`${styles.print} hideOnPrint`}>
          {text.ContributionRecurringStep3.text_print_copy}
        </div>
      </div>
    );
  }
}

export default (LanguageHOC(Step3PrintReview));