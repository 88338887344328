/*
*
* Signup Actions
*
*/
import {
  CREATE_ACCOUNT,
  DELETE_APPLICATION,
  GET_EXISTING_BENEFICIARIES,
  GET_EXISTING_BENEFICIARY,
  GET_SIGNUP_APPLICATION,
  RESET_ACCOUNT_APPLICATION,
  SAVE_ACCOUNT_OWNER,
  SAVE_BENEFICIARY,
  SAVE_CUSTOMIZED_OPTION,
  SAVE_PREDEFINED_INVESTMENT_OPTION,
  SAVE_PROMO_CODE,
} from './constants';

import {
  GETrequest,
  POSTrequest,
  PUTrequest,
} from 'utils/helpers/api_handler';

import { convert3DCalculatorSchemaTo2D } from '@frontend/common';

export function createAccount() {
  const payload = POSTrequest('/SimpleSignup/Account');
  return {
    type: CREATE_ACCOUNT,
    payload
  };
}

export function deleteApplication() {
  const payload = PUTrequest('/SimpleSignup/Delete');
  return {
    type: DELETE_APPLICATION,
    payload
  };
}

export function getExistingBeneficiaries() {
  const payload = GETrequest('/SimpleSignup/Beneficiaries');
  return {
    type: GET_EXISTING_BENEFICIARIES,
    payload
  };
}

export function getExistingBeneficiary(beneficiaryId) {
  const payload = GETrequest(`/SimpleSignup/Beneficiary/${beneficiaryId}`);
  return {
    type: GET_EXISTING_BENEFICIARY,
    payload
  };
}

export function getSignupApplication() {
  const payload = GETrequest('/SimpleSignup');
  return {
    type: GET_SIGNUP_APPLICATION,
    payload
  };
}

export function resetAccountApplication() {
  return {
    type: RESET_ACCOUNT_APPLICATION,
  };
}

export function saveAccountOwner(accountOwnerInfo) {
  const payload = accountOwnerInfo.InterestedPartyId && !accountOwnerInfo.AgentId ?
    POSTrequest('/SimpleSignup/Agent', accountOwnerInfo) // saves new AO info for interested party
    :
    PUTrequest('/SimpleSignup/Agent', accountOwnerInfo); // saves AO info for existing AO or new AO
  return {
    type: SAVE_ACCOUNT_OWNER,
    payload
  };
}

export function saveBeneficiary(beneficiaryInfo) {
  const payload = beneficiaryInfo.BeneficiaryId ?
    POSTrequest('/SimpleSignup/Beneficiary', beneficiaryInfo) // saves a new beneficiary
    :
    PUTrequest('/SimpleSignup/Beneficiary', beneficiaryInfo); // saves an existing beneficiary
  
  return {
    type: SAVE_BENEFICIARY,
    payload
  };
}

export function savePredefinedInvestmentOption(InvestmentOptionId) {
  const payload = PUTrequest('/SimpleSignup/OptionAllocation', { InvestmentOptionId });
  return {
    type: SAVE_PREDEFINED_INVESTMENT_OPTION,
    payload
  };
}

export function saveCustomizedOption(matrixAllocations, template) {
  const populatedTemplate = convert3DCalculatorSchemaTo2D(matrixAllocations, template);
  const payload = POSTrequest('/SimpleSignup/OptionAllocationCustom', populatedTemplate);
  return {
    type: SAVE_CUSTOMIZED_OPTION,
    payload
  };
}

export function savePromoCode(PromotionalCode) {
  const payload = PUTrequest('/SimpleSignup/PromoCode', { PromotionalCode });
  return {
    type: SAVE_PROMO_CODE,
    payload
  };
}