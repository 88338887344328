import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';

import {
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Radio,
  RadioGroup,
} from '@mui/material';

import {
  LoadingOverlay,
  DatePicker,
  notificationShow
} from '@frontend/common';

import {
  clearStore,
  userLogout,
  resetClaims,
  toggleRSADialog
} from 'components/AppRoot/Navigation/actions';

import {
  getRSAStatus,
  rsaVerifyBirthday,
  getRSAQuestionSet,
  rsaAnswers
} from '../actions';

import { My529Logo } from '@frontend/common';

import styles from './styles.module.scss';

const select = (state) => ({
  isRSALockedOut: state.multifactor.isRSALockedOut,
  isIdentityVerified: state.multifactor.isIdentityVerified,
  isDOBValid: state.multifactor.isDOBValid,
  RSADOB: state.multifactor.string,
  RSAQuestionSet: state.multifactor.RSAQuestionSet,
  userDetails: state.session.userDetails
});

export class RSA extends React.Component {

  static propTypes = {
    getRSAQuestionSet: PropTypes.func.isRequired,
    getRSAStatus: PropTypes.func.isRequired,
    rsaVerifyBirthday: PropTypes.func.isRequired,
    rsaAnswers: PropTypes.func.isRequired,
    clearStore: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    resetClaims: PropTypes.func.isRequired,
    toggleRSADialog: PropTypes.func.isRequired,
    isIdentityVerified: PropTypes.bool.isRequired,
    isDOBValid: PropTypes.bool.isRequired,
    isRSALockedOut: PropTypes.bool.isRequired,
    RSAQuestionSet: PropTypes.object.isRequired,
    notificationShow: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_change_dob: PropTypes.string,
        btn_confirm_dob: PropTypes.string,
        btn_learn_more: PropTypes.string,
        btn_logout: PropTypes.string,
        btn_ok: PropTypes.string,
        btn_submit_answer: PropTypes.string,
        btn_verify_dob: PropTypes.string,
        err_incorrect_answer: PropTypes.string,
        err_required: PropTypes.string,
        register_path: PropTypes.string,
        lbl_ao_name: PropTypes.string,
        lbl_ao_dob: PropTypes.string,
        lbl_question: PropTypes.string,
        msg_discrepancy: PropTypes.string,
        msg_dob_select: PropTypes.string,
        msg_locked_out: PropTypes.string,
        msg_maintain_security: PropTypes.string,
        msg_not_verified: PropTypes.string,
        msg_protection: PropTypes.string,
        msg_rsa_select: PropTypes.string,
        msg_unable_verify: PropTypes.string,
        verify_identity_path: PropTypes.string,
        verify_identity_title: PropTypes.string,
        learn_more_url: PropTypes.string,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
      }),
      Home: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
    isRSADOB: true, // should render RSA DOB
    isRSAQuestions: false, // should render RSA Questions
    isUnableToVerify: false,

    hasRSADOBerror: false,
    hasConfirmedDOB: false, // is RSA DOB confirmed by API
    hasDisplayMessage: false,

    RSADOBError: '',
    RSADOB: null, // actual RSA date
    displayMessage: '',
    questionSet: {},
    regMethod: '',
  };

  sendRSADOB = e => {
    e.preventDefault();
    const { text: { Multifactor } } = this.props;
    const { hasConfirmedDOB, RSADOB } = this.state;
    if (!dayjs(RSADOB).isValid()) {
      this.setState({
        hasRSADOBerror: true,
        RSADOBError: Multifactor.err_required
      });
    }
    else {
      if (!hasConfirmedDOB) {
        this.setState({
          isLoading: false,
          hasSecurityQuestion: false,
          hasDisplayMessage: false,
          hasConfirmedDOB: true,
          isRSADOB: true,
        }, () => this.renderRSADOB());
      }
      else {
        const birthdate = { birthdate: dayjs(this.state.RSADOB).format('YYYY-MM-DD') };
        this.setState({ isLoading: true });
        this.props.rsaVerifyBirthday(birthdate)
          .then(() => {
            if (this.props.isRSALockedOut) { // 2 bad DOB's provided
              this.setState({ isLoading: false });
              this.props.notificationShow(Multifactor.msg_unable_verify, 'error');
              setTimeout(() => this.logOut(), 4000);
            }
            else if (this.props.isDOBValid) { // Good RSA date, get RSA questions
              this.props.getRSAQuestionSet()
                .then(() => {
                  if (this.props.RSAQuestionSet.length === 0 || this.props.isRSALockedOut) { // API returns no RSA Questions
                    this.setState({ isLoading: false });
                    this.props.notificationShow(Multifactor.msg_unable_verify, 'error');
                    setTimeout(() => this.logOut(), 4000);
                  }
                  else {
                    this.setState({
                      isLoading: false,
                      isRSADOB: false,
                      hasSecurityQuestion: false,
                      hasDisplayMessage: false,
                      isRSAQuestions: true,
                      questionSet: this.props.RSAQuestionSet
                    });
                  }
                });
            }
            else { // RSA confirmed invalid date, display discrepancy message
              this.setState({
                isLoading: false,
                isRSADOB: false,
                hasSecurityQuestion: false,
                hasConfirmedDOB: false,
                isRSAQuestions: false,
                hasDisplayMessage: true,
                displayMessage: Multifactor.msg_discrepancy
              });
            }
          });
      }
    }
  }

  onInputChange = (name, e) => {
    const dob = e.target.value ? dayjs(e.target.value) : null;
    this.setState({ [name]: dob, hasRSADOBerror: false, RSADOBError: '' });
  }

  renderRSADOB() {
    const { userDetails, text: { Multifactor } } = this.props;
    const { hasConfirmedDOB, hasRSADOBerror, RSADOB, RSADOBError, isLoading } = this.state;
    if (hasConfirmedDOB) {
      return (
        <form
          id='send-rsa-dob'
          onSubmit={this.sendRSADOB}
        >
          <p style={{ fontWeight: 'bold' }}>{Multifactor.msg_maintain_security}</p>
          <p style={{ fontWeight: 'bold', marginBottom: '20px' }}>{Multifactor.msg_protection}</p>
          <div className={styles.DOBcontainer}>
            <div className={styles.aoNameLabel}>{Multifactor.lbl_ao_name}</div>
            <div className={styles.aoName}>{userDetails.name}</div>
            <div className={styles.aoDOBLabel}>{Multifactor.lbl_ao_dob}</div>
            <div className={styles.aoDOB}>{dayjs(RSADOB).format('MM/DD/YYYY')}</div>
          </div>
          <Button
            variant='contained'
            style={{ width: '80%', marginBottom: '15px', marginTop: '30px' }}
            type='submit'
            disabled={isLoading}
          >
            {Multifactor.btn_confirm_dob}
          </Button>
          <Button
            variant='contained'
            style={{ width: '80%', marginBottom: '15px' }}
            onClick={() => this.setState({ RSADOB: null, isRSADOB: true, hasConfirmedDOB: false, hasRSADOBerror: false, RSADOBError: '' })}
          >
            {Multifactor.btn_change_dob}
          </Button>
          <Button
            color='primary'
            variant='text'
            style={{ width: '80%' }}
            onClick={() => this.props.toggleRSADialog()}
          >
            {Multifactor.btn_cancel}
          </Button>
        </form>
      );
    }
    else {
      const datePickerWidth = window.innerWidth < 600 ? '95%' : '40%';
      return (
        <form
          id='send-rsa-dob'
          onSubmit={this.sendRSADOB}
        >
          <p style={{ fontWeight: 'bold' }}>{Multifactor.msg_maintain_security}</p>
          <p style={{ fontWeight: 'bold' }}>{Multifactor.msg_protection}</p>
          <p>{Multifactor.msg_dob_select}</p>
          <div className={styles.DOB}>
            <DatePicker
              error={Boolean(hasRSADOBerror)}
              helperText={RSADOBError}
              label={Multifactor.lbl_ao_dob}
              onChange={e => this.onInputChange('RSADOB', { target: { value: e || '' } })}
              openTo='year'
              value={RSADOB}
              sx={{ width: datePickerWidth }}
            />
          </div>
          <Button
            variant='contained'
            style={{ width: '60%' }}
            type='submit'
            disabled={isLoading}
          >
            {Multifactor.btn_verify_dob}
          </Button>
          <Button
            color='primary'
            variant='text'
            style={{ width: '60%', marginTop: '10px' }}
            onClick={() => this.props.toggleRSADialog()}
          >
            {Multifactor.btn_cancel}
          </Button>
        </form>
      );
    }

  }

  handleOptions = e => {
    const questionSet = cloneDeep(this.state.questionSet);
    let unparsed = null;
    if (e.target.name.includes('|')) {
      unparsed = e.target.name;
    }
    if (e.target.value.includes('|')) {
      unparsed = e.target.value;
    }
    if (unparsed) {
      const qId = unparsed.substr(0, unparsed.indexOf('|'));
      const cId = unparsed.substr(unparsed.indexOf('|') + 1, unparsed.length);
      const questionsIndex = questionSet.Questions.findIndex(
        question => question.QuestionId === parseInt(qId)
      );

      // clear all other choices before selecting new choice
      questionSet.Questions[questionsIndex].Choices.forEach(choice => delete choice.isSelected);

      const choiceIndex = questionSet.Questions[questionsIndex].Choices.findIndex(
        choice => choice.ChoiceId === parseInt(cId)
      );
      questionSet.Questions[questionsIndex].Choices[choiceIndex].isSelected = true;
      this.setState({ questionSet });
    }
    else {
      console.log('Unable to parse question and/or choice ids'); // eslint-disable-line
    }
  }

  validateRSAForm() {
    // validate choices have been made
    const { text: { Multifactor } } = this.props;
    const questionSet = cloneDeep(this.state.questionSet);

    // validate choices have been made
    let isFormValidated = true;
    questionSet.Questions.forEach(question => {
      const displayError = question.Choices.some(choice => choice.isSelected);
      if (!displayError) {
        question.errorMessage = Multifactor.err_required;
        isFormValidated = false;
      }
      else {
        delete question.errorMessage;
      }
    });

    this.setState({ questionSet });
    return isFormValidated;
  }

  sendRSAAnswers = e => {
    e.preventDefault();
    const { text: { Multifactor } } = this.props;
    const questionSet = cloneDeep(this.state.questionSet);

    if (this.validateRSAForm()) { // questions without selected answers

      // build Answer Set
      const answerSet = {};
      answerSet.questionSetId = questionSet.QuestionSetId;
      answerSet.transactionid = questionSet.TransactionId;
      answerSet.answers = [];
      questionSet.Questions.forEach(question => {
        const answer = {
          questionId: question.QuestionId,
          choices: []
        };
        question.Choices.forEach(choice => {
          if (choice.isSelected) {
            answer.choices.push({
              choiceId: choice.ChoiceId,
              text: choice.Text
            });
          }
        });
        answerSet.answers.push(answer);
      });

      this.setState({ isLoading: true });
      this.props.rsaAnswers(answerSet)
        .then(() => {
          if (this.props.isIdentityVerified) {
            this.props.resetClaims()
              .then(() => {
                this.props.toggleRSADialog();
              });
          }
          else {
            const { RSAQuestionSet } = this.props;
            if (RSAQuestionSet.Questions.length > 0) {
              this.setState({
                isLoading: false,
                isRSADOB: false,
                hasSecurityQuestion: false,
                isRSAQuestions: true,
                questionSet: RSAQuestionSet
              }, () => this.renderRSAQandA());
            }
            else {
              this.props.getRSAQuestionSet()
                .then(() => {
                  const latestSet = this.props.RSAQuestionSet;
                  if (latestSet && latestSet.Questions.length === 0) {
                    this.setState({ isLoading: false },
                      () => this.props.notificationShow(Multifactor.msg_unable_verify, 'error')
                    );
                    setTimeout(() => this.logOut(), 2000);
                  }
                  else {
                    this.setState({
                      isLoading: false,
                      isRSADOB: false,
                      hasSecurityQuestion: false,
                      isRSAQuestions: true,
                      questionSet: latestSet
                    }, () => this.renderRSAQandA());
                  }
                });
            }
          }
        });

    }
  }

  renderRSAQandA() {
    const { text: { Multifactor } } = this.props;
    const questions = this.state.questionSet.Questions ? cloneDeep(this.state.questionSet.Questions) : [];
    const questRendered = questions.map(question => {
      if (question.AnswerType === 'Single') {
        const choices = question.Choices.map(choice => {
          return (<FormControlLabel value={`${question.QuestionId}|${choice.ChoiceId}`} key={choice.ChoiceId} control={<Radio />} label={choice.Text} />);
        });
        return (
          <div key={question.QuestionId} style={{ padding: '10px', fontWeight: 'bold' }}>
            {question.Text}
            <div style={{ fontWeight: 'normal', padding: '10px' }}>{question.HelpText}</div>
            <RadioGroup aria-label='registration method' name='method' value={question.selectedChoice} onChange={this.handleOptions} style={{ width: '70%', margin: 'auto' }}>
              {choices}
            </RadioGroup>
            <FormHelperText style={{ color: 'red', width: '70%', textAlign: 'center' }}>{question.errorMessage}</FormHelperText>
          </div>
        );
      }
      else {
        const choices = question.Choices.map(choice => {
          return (
            <FormControlLabel key={choice.ChoiceId}
              control={<Checkbox style={{ padding: '3px' }} />}
              label={choice.Text}
              onChange={this.handleOptions}
              name={`${question.QuestionId}|${choice.ChoiceId}`}
              value={choice.ChoiceId}
            />
          );
        });
        return (
          <div key={question.QuestionId} style={{ padding: '10px', fontWeight: 'bold' }}>
            {question.Text}
            <div style={{ fontWeight: 'normal', padding: '10px' }}>{question.HelpText}</div>
            <FormControl component='fieldset'>
              <FormGroup style={{ width: '70%', margin: 'auto' }}>
                {choices}
              </FormGroup>
              <FormHelperText style={{ color: 'red', width: '70%', textAlign: 'center' }}>{question.errorMessage}</FormHelperText>
            </FormControl>
          </div>
        );
      }
    });

    return (
      <React.Fragment>
        <p>{Multifactor.msg_rsa_select}</p>

        <form
          id='send-rsa-answers'
          onSubmit={this.sendRSAAnswers}
        >
          {questRendered}
          <Button
            variant='contained'
            style={{ width: '60%' }}
            type='submit'
            disabled={this.state.isLoading}
          >
            {Multifactor.btn_submit_answer}
          </Button>
          <Button
            color='primary'
            variant='text'
            style={{ width: '60%', marginTop: '10px' }}
            onClick={() => this.props.toggleRSADialog()}
          >
            {Multifactor.btn_cancel}
          </Button>
        </form><br />
      </React.Fragment>
    );

  }

  displayMessage() {
    const { displayMessage } = this.state;
    return (
      <div>
        <h4 className={styles.errorMsg}>{displayMessage}</h4>
        <Button
          variant='contained'
          style={{ width: '60%' }}
          onClick={() => this.logOut()}
        >
          {this.props.text.Multifactor.btn_logout}
        </Button>
      </div>
    );
  }

  logOut() {
    const { text: { Login } } = this.props;
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .finally(() => this.props.notificationShow(Login.msg_you_have_logged_out, 'success'));
    this.props.clearStore();
  }

  render() {
    const { text: { Multifactor } } = this.props;
    const { isRSADOB, isRSAQuestions, hasDisplayMessage } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.logo}><My529Logo /></div>
          <div style={{ textAlign: 'center' }}>
            <LoadingOverlay show={this.state.isLoading}>
              <h3>{Multifactor.verify_identity_title}</h3>

              {isRSADOB && this.renderRSADOB()}
              {isRSAQuestions && this.renderRSAQandA()}
              {hasDisplayMessage && this.displayMessage()}
            </LoadingOverlay>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(select, {
  getRSAStatus,
  rsaVerifyBirthday,
  getRSAQuestionSet,
  rsaAnswers,
  toggleRSADialog,
  clearStore,
  userLogout,
  notificationShow,
  resetClaims
})(LanguageHOC(RSA));