import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
  Switch,
  Slide
} from '@mui/material';

import {
  notificationShow,
  LoadingOverlay,
  CardAligned,
  currencyFormatter,
  Modal,
} from '@frontend/common';

import {
  getGiftingList,
  changeStatus,
  updateGift,
  changeNameVisibility,
} from '../actions';

import LearnMore from '../LearnMore';
import SkeletonLoader from 'utils/components/SkeletonLoader';

import styles from './styles.module.scss';

const select = (state) => ({
  accountList: state.accounts.accountList.filter(account => account.permissions.GiftingEnabled),
  beneficiaryList: state.gifting.beneficiaryList,
  currentYear: state.static.environmentVars.ServerDateTime.substring(0, 4),
  annualGiftExclusionAmount: currencyFormatter(state.static.environmentVars.AnnualGiftExclusionAmount),
  maximumBeneficiaryDeposit: currencyFormatter(state.static.environmentVars.MaximumBeneficiaryDeposit),
  maximumBenficiaryAccountValueForGift: currencyFormatter(state.static.environmentVars.MaximumBenficiaryAccountValueForGift),
});

export class GiftingDashboard extends Component {

  static propTypes = {
    getGiftingList: PropTypes.func.isRequired,
    updateGift: PropTypes.func.isRequired,
    accountList: PropTypes.array.isRequired,
    beneficiaryList: PropTypes.array.isRequired,
    changeStatus: PropTypes.func.isRequired,
    changeNameVisibility: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    currentYear: PropTypes.string.isRequired,
    annualGiftExclusionAmount: PropTypes.string.isRequired,
    maximumBeneficiaryDeposit: PropTypes.string.isRequired,
    maximumBenficiaryAccountValueForGift: PropTypes.string.isRequired,
    text: PropTypes.shape({
      AccountDetails: PropTypes.shape({ nav_path: PropTypes.func }),
      Gifting: PropTypes.shape({
        btn_learn_more: PropTypes.string,
        btn_manage: PropTypes.string,
        btn_ok: PropTypes.string,
        head_title: PropTypes.string,
        lbl_account: PropTypes.string,
        lbl_beneficiary: PropTypes.string,
        lbl_code: PropTypes.string,
        lbl_disabled: PropTypes.string,
        lbl_enabled: PropTypes.string,
        lbl_hide_last_name: PropTypes.string,
        lbl_hidden: PropTypes.string,
        lbl_inactive: PropTypes.string,
        lbl_shown: PropTypes.string,
        lbl_status: PropTypes.string,
        msg_no_beneficiary: PropTypes.string,
        text_max_aggregate: PropTypes.func,
        msg_save: PropTypes.string,
        nav_path: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    loading: false,
    learnMoreOpen: false,
    shouldNavAway: true,
    loadingStatusChange: false,
    loadingHideLastNameChange: false,
    isAggregate: false,
  };

  statusChange(beneficiary) {
    const { updateGift, changeStatus, text: { Gifting } } = this.props;
    if (beneficiary.CanEnable === 0) {
      this.setState({
        loadingStatusChange: false,
        isAggregate: true
      });
    }
    else {
      this.setState({ loadingStatusChange: true });
      beneficiary.IsActive = beneficiary.IsActive ? 0 : 1;
      const hasNoGiftCodeYet = beneficiary.GiftCodeId === 0 && beneficiary.GiftCode === '';

      if (hasNoGiftCodeYet) {
        // no gift code yet gift needs to be created first
        updateGift(beneficiary)
          .then(() => {
            this.setState({ loadingStatusChange: false }, () => {
              notificationShow(Gifting.msg_save, 'success');
            });
          })
          .catch(() => {
            this.setState({ loadingStatusChange: false });
          });
      }
      else {
        // already has gift code just update gift status (no need for uploading the bene image again)
        changeStatus(beneficiary.AccountId, beneficiary.IsActive)
          .then(() => {
            this.setState({
              loadingStatusChange: false,
            }, () => {
              this.props.notificationShow(Gifting.msg_save, 'success');
            });
          })
          .catch(() => {
            this.setState({ loadingStatusChange: false });
          });
      }

    }
  }

  hideLastNameChange = beneficiary => e => {
    const { changeNameVisibility, text: { Gifting } } = this.props;
    const HideLastName = e.target.checked ? 1 : 0;

    this.setState({ loadingHideLastNameChange: true });

    changeNameVisibility(beneficiary.AccountId, HideLastName)
      .then(() => {
        this.setState({ loadingHideLastNameChange: false }, () => {
          this.props.notificationShow(Gifting.msg_save, 'success');
        });
      })
      .catch(() => {
        this.setState({ loadingHideLastNameChange: false });
      });
  }

  renderBeneficiaryRows() {
    const { beneficiaryList, text: { Gifting, AccountDetails } } = this.props;

    const results = beneficiaryList.length === 0
      ? Gifting.msg_no_beneficiary
      : beneficiaryList.map(bene => {
        const isActive = Boolean(bene.IsActive);
        const statusText = isActive ? Gifting.lbl_enabled : Gifting.lbl_disabled;
        const hideLastName = Boolean(bene.HideLastName);
        const nameStatusText = hideLastName ? Gifting.lbl_hidden : Gifting.lbl_shown;
        const giftCode = isActive ? bene.GiftCode : Gifting.lbl_inactive;
        const giftCodeColor = isActive ? '#000' : 'rgba(0,0,0,.30)';
        return (
          <div className={styles.dashRow} key={bene.AccountId}>
            <div className={styles.bene}>
              <p className={styles.labelTILE}>{Gifting.lbl_beneficiary}</p>
              <p className={styles.dataTILE}>{`${bene.FirstName} ${bene.LastName}`}</p>
            </div>
            <div className={styles.code}>
              <p className={styles.labelTILE}>{Gifting.lbl_code}</p>
              <p className={styles.dataTILE} style={{ textTransform: 'capitalize', color: giftCodeColor }}>{giftCode}</p>
            </div>
            <div className={styles.acct}>
              <p className={styles.labelTILE}>{Gifting.lbl_account}</p>
              <p className={styles.acctType}>{bene.OptionName}</p>
              <p className={styles.dataTILE} style={{ color: '#3399FF', letterSpacing: '3px' }}>
                <Link to={AccountDetails.nav_path(bene.AccountId)}>{bene.AccountNumber}</Link>
              </p>
            </div>
            <div className={styles.status}>
              <p className={styles.labelTILE} style={{ justifySelf: 'center' }}>{Gifting.lbl_status}</p>
              <Switch
                checked={isActive}
                onChange={() => this.statusChange(bene)}
                value={isActive}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <p style={{ fontSize: '10px', marginTop: '3px' }}>{statusText}</p>
            </div>
            <div className={styles.hideName}>
              <p className={styles.labelTILE}>{Gifting.lbl_hide_last_name}</p>
              <Switch
                checked={hideLastName}
                disabled={!isActive}
                onChange={this.hideLastNameChange(bene)}
                value={hideLastName}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <p style={{ fontSize: '10px', marginTop: '3px' }}>{nameStatusText}</p>
            </div>
            <div className={styles.manage} style={{ color: '#0072CF' }} onClick={() => this.props.history.push(`${Gifting.nav_path}/${bene.AccountId}`)}>
              {Gifting.btn_manage}
            </div>
          </div>
        );
      });

    return (
      results
    );
  }

  renderSkeletonRows() {
    const { accountList, text: { Gifting, } } = this.props;
    
    return (
      accountList.map(account => (   
        <div className={styles.dashRow} key={account.accountId}>
          <div className={styles.bene}>
            <p className={styles.labelTILE}>{Gifting.lbl_beneficiary}</p>
            <SkeletonLoader textVariant='h3' />
          </div>
          <div className={styles.code}>
            <p className={styles.labelTILE}>{Gifting.lbl_code}</p>
            <SkeletonLoader textVariant='h3' />
          </div>
          <div className={styles.acct}>
            <p className={styles.labelTILE}>{Gifting.lbl_account}</p>
            <SkeletonLoader textVariant='h3' />
          </div>
          <div className={styles.status}>
            <p className={styles.labelTILE} style={{ justifySelf: 'center' }}>{Gifting.lbl_status}</p>
            <SkeletonLoader textVariant='h3' />
          </div>
          <div className={styles.hideName}>
            <p className={styles.labelTILE}>{Gifting.lbl_hide_last_name}</p>
            <SkeletonLoader textVariant='h3' />
          </div>
          <div className={styles.manage} style={{ marginTop: '32px' }}>
            <SkeletonLoader textVariant='h3' />
          </div>
        </div>
      ))
    );
  }

  componentDidMount() {
    if (this.props.beneficiaryList.length === 0) {
      this.setState({ loading: true });
      this.props.getGiftingList()
        .then(() => {
          this.setState({ loading: false });
        });
    }
  }

  render() {
    const {
      loading,
      loadingStatusChange,
      learnMoreOpen,
      isAggregate,
    } = this.state;
    const { 
      currentYear,
      annualGiftExclusionAmount,
      maximumBeneficiaryDeposit,
      maximumBenficiaryAccountValueForGift,
      text: { Gifting } 
    } = this.props;

    return (
      <div className={styles.paperContainer}>
        <div className={styles.paperTitle}>{Gifting.head_title}</div>
        <CardAligned>

          {loading
            ? (
              this.renderSkeletonRows()
            ) : (
              <LoadingOverlay show={loadingStatusChange}>
                {this.renderBeneficiaryRows()}
              </LoadingOverlay>
            )}

        </CardAligned>
        <div className={styles.learnMore}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => this.setState({ learnMoreOpen: true })}
          >
            {Gifting.btn_learn_more}
          </Button>
        </div>

        <LearnMore
          onClose={() => this.setState({ learnMoreOpen: false })}
          open={learnMoreOpen}
          currentYear={currentYear}
          annualGiftExclusionAmount={annualGiftExclusionAmount}
          maximumBeneficiaryDeposit={maximumBeneficiaryDeposit}
          maximumBenficiaryAccountValueForGift={maximumBenficiaryAccountValueForGift}
        />

        <Modal
          show={isAggregate}
          title=''
          onCloseModal={() => this.shouldNavAway()}
          actionButtons={[
            {
              action: () => this.setState({ isAggregate: false }),
              label: Gifting.btn_ok,
            }
          ]}
          DialogProps={{ 'TransitionComponent': Transition }}
          
        >
          <div>{Gifting.text_max_aggregate(maximumBeneficiaryDeposit)}</div>
        </Modal>

      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction='up' ref={ref} {...props} />;
});

export default withRouter(connect(select, {
  getGiftingList,
  updateGift,
  changeStatus,
  changeNameVisibility,
  notificationShow
})(LanguageHOC(GiftingDashboard)));