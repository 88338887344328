import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  currencyFormatter,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
} from '@frontend/common';

import {
  ACH_PAYMENT,
  ACCESS_CARD_PAYMENT,
  FULL_BALANCE,
  PARTIAL_BALANCE,
  RECURRING,
  ELECTRONIC,
} from '../../../constants';

import BankInfo from '../BankInfo';
import CheckAddress from '../CheckAddress';

import styles from './styles.module.scss';

export class Step3Print extends React.Component {
  static propTypes = {
    withdrawal: PropTypes.shape({
      actualSellDate: PropTypes.string,
      amount: PropTypes.number,
      frequency: PropTypes.string,
      amountType: PropTypes.string,
      recipientAddress: PropTypes.object,
      recipient: PropTypes.string,
      proportionalAmounts: PropTypes.array,
      leaveAccountOpen: PropTypes.bool,
      paymentType: PropTypes.string,
      paymentDeliveryType: PropTypes.string,
      paymentAccount: PropTypes.object,
      actualStartDateDayOfMonth: PropTypes.number,
      actualEndDate: PropTypes.string,
      createdDate: PropTypes.string,
    }).isRequired,
    isProportional: PropTypes.bool.isRequired,
    preTransactionDetails: PropTypes.shape({
      agentAddressChanged: PropTypes.bool,
      beneficiaryAddressChanged: PropTypes.bool,
      groupAccounts: PropTypes.arrayOf(
        PropTypes.shape({
          AccountId: PropTypes.number,
          availableForWithdrawalNum: PropTypes.number,
          AllowFullWithdrawal: PropTypes.bool,
        })
      ),
      OptionName: PropTypes.string,
      AccountNumber: PropTypes.number,
    }).isRequired,
    text: PropTypes.shape({
      Withdrawals: PropTypes.shape({
        step3_print_and_summary: PropTypes.object.isRequired,
        total_amount_full_balance: PropTypes.string,
        total_amount_full_balance_electronic: PropTypes.string,
      }).isRequired
    }).isRequired,
  };

  render() {
    const {
      withdrawal: {
        amount,
        frequency,
        amountType,
        recipientAddress,
        recipient,
        proportionalAmounts,
        leaveAccountOpen,
        paymentType,
        paymentDeliveryType,
        paymentAccount,
        actualSellDate,
        actualStartDateDayOfMonth,
        actualEndDate,
        createdDate,
      },
      isProportional,
      preTransactionDetails: {
        groupAccounts,
        OptionName,
        AccountNumber,
        agentAddressChanged,
        beneficiaryAddressChanged,
      },
      text: {
        Withdrawals: {
          step3_print_and_summary,
          total_amount_full_balance,
          total_amount_full_balance_electronic,
        }
      },
    } = this.props;
    const stepIndex = 3;

    const createdTime = dayjs(createdDate).format('MM/DD/YYYY h:mm A');  // eslint-disable-line

    let payeeAddress;
    let isElectronicSchoolPayment = false;
    switch (recipient) {
      case 'I':
        payeeAddress = recipientAddress.school;
        if (paymentDeliveryType === ELECTRONIC) {
          isElectronicSchoolPayment = true;
        }
        break;
      case 'P':
        payeeAddress = recipientAddress.agent;
        break;
      case 'B':
        payeeAddress = recipientAddress.beneficiary;
        break;
      default:
        payeeAddress = 'Incorrect recipient';
    }

    const isFullBalance = amountType === FULL_BALANCE;
    const closeAccount = amountType === PARTIAL_BALANCE ? false : !leaveAccountOpen;
    let tableData = [];
    const isRecurring = frequency === RECURRING;
    if (isProportional) {
      let tableId = 0;
      // multiple accounts
      tableData = proportionalAmounts.map(pAccount => ({
        tableId: ++tableId,
        accountNumber: pAccount.accountNumber,
        investmentOption: groupAccounts.find(gAccount => gAccount.AccountId === pAccount.accountId).OptionName,
        fullMarket: isFullBalance ? 'Yes' : 'No',
        leaveOpen: leaveAccountOpen ? 'Yes' : 'No',
        withdrawalAmount: isFullBalance ? 'Full Balance' : pAccount.amount,
      }));

      if (isElectronicSchoolPayment) {
        const feeRow = {
          tableId: ++tableId,
          accountNumber: AccountNumber,
          investmentOption: OptionName,
          fullMarket: '',
          leaveOpen: '',
          withdrawalAmount: isFullBalance ? currencyFormatter(3.5) : 3.5,
        };
        tableData.push(feeRow);
      }
    }
    else {
      // single account
      const amntRow = {
        tableId: 0,
        accountNumber: AccountNumber,
        investmentOption: OptionName,
        fullMarket: isFullBalance ? 'Yes' : 'No',
        leaveOpen: closeAccount ? 'No' : 'Yes',
        withdrawalAmount: isFullBalance ? 'Full Balance' : amount,
      };
      tableData.push(amntRow);

      if (isElectronicSchoolPayment) {
        const feeRow = {
          tableId: 1,
          accountNumber: AccountNumber,
          investmentOption: OptionName,
          fullMarket: '',
          leaveOpen: '',
          withdrawalAmount: isFullBalance ? currencyFormatter(3.5) : 3.5,
        };
        tableData.push(feeRow);
      }
    }

    const totalAmountOf = currencyFormatter(tableData.reduce((total, row) => total += row.withdrawalAmount, 0));
    let totalAmtMsg = '';
    let amountDataType = 'currency';
    if (isFullBalance) {
      totalAmtMsg = isElectronicSchoolPayment
        ? total_amount_full_balance_electronic
        : total_amount_full_balance;
      amountDataType = 'string';
    }
    else {
      totalAmtMsg = step3_print_and_summary.total_amount(totalAmountOf);
    }

    const displayTotal = proportionalAmounts.length > 0 || isElectronicSchoolPayment;

    return (
      <div className={styles.summaryContainer}>
        <h2 className={styles.summaryTitle}>{step3_print_and_summary.title}</h2>
        <p>{step3_print_and_summary.comment1}</p>
        <br />
        <p>{step3_print_and_summary.comment2}</p>

        <div className={styles.subSummaryContainer}>
          <h3 className={styles.summarySubtitle}>Payee</h3>
          <p>{payeeAddress.name}</p>

          {(paymentType === ACH_PAYMENT || paymentType === ACCESS_CARD_PAYMENT) ?
            <div >
              <h3 className={styles.summarySubtitle}>{step3_print_and_summary.subtitle_payee_bank}</h3>
              <BankInfo
                recipient={recipient}
                paymentAccount={paymentAccount}
                recipientAddress={recipientAddress}
              />
            </div>
            :
            <div>
              <h3 className={styles.summarySubtitle}>{step3_print_and_summary.subtitle_payee_address}</h3>
              <CheckAddress
                stepIndex={stepIndex}
                recipientAddress={recipientAddress}
                recipient={recipient}
                agentAddressChanged={agentAddressChanged}
                beneficiaryAddressChanged={beneficiaryAddressChanged}
              />
            </div>
          }

          <h3 className={styles.summarySubtitle}>{step3_print_and_summary.subtitle_schedule}</h3>
          {isRecurring ?
            <div>
              <p>{step3_print_and_summary.frequency_recurring(dayjs(`01-${actualStartDateDayOfMonth}-2020`).format('Do'))}</p>
              {actualEndDate && <p>{step3_print_and_summary.end_date_text(dayjs(actualEndDate).format('L'))}</p>}
            </div>
            :
            <p>{step3_print_and_summary.frequency_onetime(dayjs(actualSellDate).format('L'))}</p>
          }

        </div>

        <div className={styles.summaryTable}>
          <h3 className={styles.summarySubtitle}>{step3_print_and_summary.table_title}</h3>
          <p style={{ marginBottom: '10px' }}>{step3_print_and_summary.table_note}</p>
          <SmartTable
            idKey='tableId'
            rows={tableData}
            columns={[
              {
                key: 'accountNumber',
                title: step3_print_and_summary.table_column1_title,
                type: 'number',
              },
              {
                key: 'investmentOption',
                title: step3_print_and_summary.table_column2_title,
                type: 'string',
                hideOn: ['phone'],
                customStyle: { width: '1.5fr' }
              },
              {
                key: 'fullMarket',
                title: step3_print_and_summary.table_column3_title,
                type: 'string',
                hideOn: ['phone'],
              },
              {
                key: 'leaveOpen',
                title: step3_print_and_summary.table_column4_title,
                type: 'string',
                hideOn: ['phone'],
              },
              {
                key: 'withdrawalAmount',
                title: step3_print_and_summary.table_column5_title,
                type: amountDataType,
                hideOn: ['phone'],
              },
            ]}
          >
            <TableContainer minWidth='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
          {displayTotal && <div className={styles.totalAmountOf}>{totalAmtMsg}</div>}
        </div>

        <div>{step3_print_and_summary.star_note}</div>
        <div className={styles.createdTime}>{createdTime} MT</div>
        <div className={`${styles.print} hideOnPrint`}>
          {step3_print_and_summary.print_note}
        </div>
      </div>
    );
  }
}

export default LanguageHOC(Step3Print);

