import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot, } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import promise from 'redux-promise';

import { get, set, clear, } from 'idb-keyval';

import { apiMessages } from 'utils/helpers/middleware';

import throttle from 'lodash/throttle';

import rootReducer from './reducers';

import { AppRoot } from 'components/AppRoot/index';

if (process.env.NODE_ENV !== 'test') {
  require('./utils/ga/analytics.js');
}

export let store = {};

if (process.env.NODE_ENV !== 'test') {
  get('osprey_idb') // retrieve redux store from IndexedDB
    .then(val => {
      let preloadedState = undefined;
      const token = sessionStorage.getItem('token');
      if (val && token) {
        preloadedState = JSON.parse(val);
      }
      else {
        sessionStorage.removeItem('token');
        clear();
      }

      // check if in dev environment
      const URL = window.location.host;
      const isDevEnv = Boolean(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) // eslint-disable-line no-underscore-dangle
        && (URL.includes('localhost') || URL.includes('dev') || URL.includes('qa'));

      // create store
      store = configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: [promise, apiMessages],
        devTools: isDevEnv, // makes sure devTools are turn off in prod
      });

      // save redux store to IndexedDB
      store.subscribe(throttle(() => {
        const serializedState = JSON.stringify(store.getState());
        set('osprey_idb', serializedState);
      }), 1000);

      const container = document.getElementById('root');
      const root = createRoot(container);

      root.render(
        <Provider store={store}>
          <BrowserRouter>
            <AppRoot />
          </BrowserRouter>
        </Provider>
      );
    });
}
else {
  store = configureStore({
    reducer: rootReducer,
    middleware: [promise, apiMessages],
    // makes sure devTools are turned off in prod
    devTools: Boolean(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__), // eslint-disable-line
  });
}
