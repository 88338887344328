/*
*
* Successors Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';

import {
  changeSuccessor,
  getAccounts,
  getSuccessors,
  removeSuccessor,
} from '../../actions';
import { getNotifications, } from 'components/AppRoot/Navigation/actions';

import {
  ConfirmModal,
  DatePicker,
  Dropdown,
  IconBtnTooltip,
  InfoIcon,
  Modal,
  notificationShow,
  SsnInput,
  LoadingOverlay,
} from '@frontend/common';

import {
  Button,
  Divider,
  Icon,
  TextField,
} from '@mui/material';

import { get2FAPrecondition, getRSAPrecondition } from 'components/AppRoot/Navigation/actions';

import { protectAction } from 'utils/helpers/multifactor_handler';
import { MULTIFACTOR_TYPE } from 'components/AppRoot/Navigation/constants';
import * as validator from 'utils/helpers/form_validation';

import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const select = state => ({
  claims: state.session.claims,
  environmentVars: state.static.environmentVars,
  successors: state.accounts.successors,
  SUCCESSOR_TYPES: state.static.environmentVars.SuccessorTypes,
});

export class Successors extends React.Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    changeSuccessor: PropTypes.func.isRequired,
    environmentVars: PropTypes.object.isRequired,
    getAccounts: PropTypes.func.isRequired,
    getSuccessors: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    get2FAPrecondition: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    setLastClicked: PropTypes.func.isRequired,
    isLastClicked: PropTypes.bool.isRequired,
    claims: PropTypes.object.isRequired,
    removeSuccessor: PropTypes.func.isRequired,
    successors: PropTypes.array.isRequired,
    SUCCESSOR_TYPES: PropTypes.shape({
      Primary: PropTypes.string,
      Secondary: PropTypes.string,
    }).isRequired,
    text: PropTypes.shape({
      Successors: PropTypes.shape({
        btn_add_successor: PropTypes.func,
        btn_change_successor: PropTypes.func,
        btn_close: PropTypes.string,
        err_age_limits: PropTypes.func,
        err_missing_dob: PropTypes.string,
        err_required: PropTypes.string,
        head_remove_successor: PropTypes.func,
        head_title: PropTypes.string,
        head_title_primary_successor: PropTypes.string,
        head_title_secondary_successor: PropTypes.string,
        lbl_birth_date: PropTypes.string,
        lbl_first_name: PropTypes.string,
        lbl_last_name: PropTypes.string,
        lbl_middle_name: PropTypes.string,
        lbl_ssn: PropTypes.string,
        lbl_successor: PropTypes.string,
        menu_new_successor: PropTypes.string,
        msg_successor_added: PropTypes.string,
        msg_successor_changed: PropTypes.string,
        msg_successor_removed: PropTypes.string,
        text_confirm_remove_successor: PropTypes.func,
        text_none: PropTypes.string,
        text_successor_info_hide: PropTypes.string,
        text_successor_to_trust: PropTypes.string,
      })
    }).isRequired
  };

  blankSuccessor = {
    birthDate: '',
    firstName: '',
    middleName: '',
    lastName: '',
    ssn: '',
    successorId: 'new',
  };

  state = {
    isExistingSuccessor: false,
    formErrors: [],
    newSuccessor: this.blankSuccessor,
    showChangeSuccessor: false,
    showRemoveSuccessor: false,
    successorLoading: false,
    successorType: this.props.SUCCESSOR_TYPES.Primary,
    validationRan: false,

    // For 2FA/RSA checks
    hasPrecondition: false,
    rememberIsRemove: null,
    rememberSuccessorType: null,
  };

  errorGet = (key) => {
    const error = this.state.formErrors.find(error => error.key === key);
    return error ? error.message : '';
  }

  formValidate = () => {
    const formErrors = [];
    const { newSuccessor } = this.state;
    const {
      environmentVars: { MaximumAge, MinimumAccountOwnerAge },
      text: { Successors: { err_age_limits, err_required, err_missing_dob } }
    } = this.props;

    const existingSuccessor = this.props.successors.find(successor => successor.successorId === newSuccessor.successorId);

    Object.keys(newSuccessor).forEach(field => {
      if (!existingSuccessor && newSuccessor[field] === '' && field !== 'middleName') {
        formErrors.push({
          key: field,
          message: err_required
        });
      }
      else if (field === 'ssn' && newSuccessor.ssn.length !== 9) {
        const message = validator.socialSecurityNumValidator(newSuccessor.ssn);
        message && formErrors.push({
          key: field,
          message,
        });
      }
      else if (field === 'birthDate') {
        if (!existingSuccessor) {
          if (
            !dayjs(newSuccessor[field]).isValid() ||
            dayjs(newSuccessor[field]).isBefore(dayjs().subtract(MaximumAge, 'year')) ||
            dayjs(newSuccessor[field]).isAfter(dayjs().subtract(MinimumAccountOwnerAge, 'year'))
          ) {
            formErrors.push({
              key: field,
              message: err_age_limits(MinimumAccountOwnerAge, MaximumAge)
            });
          }
        }
        else if (!existingSuccessor.birthDate) {
          // missing DOB for existing successor
          formErrors.push({
            key: field,
            message: err_missing_dob
          });
        }
      }
    });

    this.setState({ formErrors });
    return formErrors.length === 0;
  }

  modifySuccessorModalCompose = () => {
    const { showChangeSuccessor, successorLoading, successorType: type } = this.state;
    const { account: { accountType, primarySuccessor, secondarySuccessor }, text: { Successors } } = this.props;
    const successor = type === this.props.SUCCESSOR_TYPES.Primary ? primarySuccessor : secondarySuccessor;

    return (
      <Modal
        actionButtons={[
          {
            action: this.onSuccessorChange,
            disabled: successorLoading,
            label: successor ? Successors.btn_change_successor(type) : Successors.btn_add_successor(type),
            loading: successorLoading
          }
        ]}
        DialogProps={{ 'aria-labelledby': successor ? Successors.btn_change_successor(type) : Successors.btn_add_successor(type) }}
        DialogContentProps={{ className: styles.modal }}
        key={`showChange${type}_successorModal}`}
        onCloseModal={() => this.onModalClose()}
        show={showChangeSuccessor}
        title={successor ? Successors.btn_change_successor(type) : Successors.btn_add_successor(type)}
        includeLeftCancelBtn
      >
        {accountType === 'Individual' && <div className={styles.formMessage}>{Successors.text_successor_to_trust}</div>}
        {this.successorFormCompose()}
      </Modal>
    );
  }

  onInputChange = (name, event) => {
    this.setState({
      newSuccessor: {
        ...this.state.newSuccessor,
        [name]: event.target.value
      }
    }, () => this.state.validationRan && this.formValidate());
  }

  onModalClose = () => {
    this.setState({
      isExistingSuccessor: false,
      formErrors: [],
      newSuccessor: this.blankSuccessor,
      showChangeSuccessor: false,
      showRemoveSuccessor: false,
      validationRan: false,
    });
  }

  onSuccessorChange = (e) => {
    e.preventDefault();
    this.setState({ validationRan: true });
    const {
      newSuccessor: {
        birthDate,
        firstName: FirstName,
        middleName: MiddleName,
        lastName: LastName,
        ssn: SSN,
        successorId: SuccessorId,
      },
      successorType
    } = this.state;
    const BirthDate = dayjs(birthDate).format('L'); // format to just date so it is not unintentionally converted due to timezone

    if (this.formValidate()) {
      this.setState({ successorLoading: true });
      const existingSuccessor = this.props.successors.find(successor => successor.successorId === this.state.newSuccessor.successorId);

      if (existingSuccessor) {

        const changedSuccessor = {
          SuccessorId,
          Type: successorType === this.props.SUCCESSOR_TYPES.Primary ? 'S' : 'SS',
        };

        if (!existingSuccessor.birthDate) { // if the existing successor is missing a DOB, send it to be updated.
          changedSuccessor.BirthDate = BirthDate;
        }

        this.props.changeSuccessor(this.props.account.accountId, changedSuccessor)
          .then(() => {
            this.props.notificationShow(this.props.text.Successors.msg_successor_changed, 'success');
            this.onModalClose();
            this.props.getAccounts().then(() => {
              this.props.getSuccessors();
              this.props.getNotifications(); // refreshes app notifications
              this.setState({ successorLoading: false });
            });
          })
          .catch(() => this.setState({ successorLoading: false }));
      }
      else {
        this.props.changeSuccessor(this.props.account.accountId, {
          BirthDate,
          FirstName,
          MiddleName,
          LastName,
          SSN,
          Type: successorType === this.props.SUCCESSOR_TYPES.Primary ? 'S' : 'SS',
        })
          .then(() => {
            this.props.notificationShow(this.props.text.Successors.msg_successor_added, 'success');
            this.onModalClose();
            this.props.getAccounts().then(() => {
              this.props.getSuccessors();
              this.props.getNotifications(); // refreshes app notifications
              this.setState({ successorLoading: false });
            });
          })
          .catch(() => this.setState({ successorLoading: false }));
      }
    }
  }

  onSuccessorRemove = () => {
    this.setState({ successorLoading: true });
    const { accountId, primarySuccessor, secondarySuccessor } = this.props.account;
    const successorId = this.state.successorType === this.props.SUCCESSOR_TYPES.Primary ? primarySuccessor.successorId : secondarySuccessor.successorId;

    this.props.removeSuccessor(accountId, successorId)
      .then(() => {
        this.props.notificationShow(this.props.text.Successors.msg_successor_removed, 'success');
        this.onModalClose();
        this.props.getAccounts().then(() => {
          this.props.getSuccessors();
          this.setState({ successorLoading: false });
        });
      })
      .catch(() => this.onModalClose());
  }

  onSuccessorSelect = (e) => {
    const successor = this.props.successors.find(successor => successor.successorId === e);

    if (!successor) {
      this.setState({
        isExistingSuccessor: false,
        formErrors: [],
        newSuccessor: this.blankSuccessor
      });
    }
    else {
      this.setState({
        isExistingSuccessor: true,
        formErrors: [],
        newSuccessor: {
          ...successor,
        }
      });
    }
  }

  removeSuccessorModalCompose = () => {
    const { account: { primarySuccessor, secondarySuccessor }, SUCCESSOR_TYPES, text: { Successors } } = this.props;
    const { showRemoveSuccessor, successorLoading, successorType } = this.state;
    const successor = successorType === SUCCESSOR_TYPES.Primary ? primarySuccessor : secondarySuccessor;

    return (showRemoveSuccessor &&
      <ConfirmModal
        body={Successors.text_confirm_remove_successor(successor.firstName, successor.middleName, successor.lastName, successorType.toLowerCase())}
        isLoading={successorLoading}
        key={`${successor.successorId}_${showRemoveSuccessor}_remove${successorType}SuccessorModal`}
        onConfirm={() => this.onSuccessorRemove()}
        onModalClose={this.onModalClose}
        show={Boolean(successor && showRemoveSuccessor)}
        title={Successors.head_remove_successor(successorType)}
      />
    );
  }

  successorFormCompose = () => {
    const { isExistingSuccessor, successorLoading, newSuccessor } = this.state;
    const { environmentVars: { MaximumAge, MinimumAccountOwnerAge }, text: { Successors } } = this.props;

    return (
      <form onSubmit={this.onSuccessorChange} className={styles.form}>
        <Dropdown
          data-auto-id='SuccessorDropdown'
          label={Successors.lbl_successor}
          onChange={e => this.onSuccessorSelect(e)}
          options={this.successorsListCompose()}
          value={newSuccessor.successorId || 'new'}
        />
        {isExistingSuccessor
          ?
          [
            <div className={styles.datePickerContainer} key='existingSuccessorBirthDate'>
              <DatePicker
                readOnly // disabled when saving or when selecting existing successor that has DOB
                error={Boolean(this.errorGet('birthDate'))}
                fullWidth
                helperText={this.errorGet('birthDate')}
                label={Successors.lbl_birth_date}
                format={'MMMM DD'}
                minDate={'1896-01-01'} // needed as fake year of 1886 is used for existing successors to not expose actual birthdate year
                value={newSuccessor.birthDate}
                onChange={() => null} // added due to onChange prop requirement; not actually needed here as it is just for viewing
              />
              <InfoIcon message={Successors.text_successor_info_hide} />
            </div>
          ]
          :
          [
            <TextField
              disabled={successorLoading}
              error={Boolean(this.errorGet('firstName'))}
              helperText={this.errorGet('firstName')}
              key='firstName'
              label={Successors.lbl_first_name}
              onChange={e => this.onInputChange('firstName', e)}
              value={newSuccessor.firstName}
            />,
            <TextField
              disabled={successorLoading}
              error={Boolean(this.errorGet('middleName'))}
              helperText={this.errorGet('middleName')}
              key='middleName'
              label={Successors.lbl_middle_name}
              onChange={e => this.onInputChange('middleName', e)}
              value={newSuccessor.middleName}
            />,
            <TextField
              disabled={successorLoading}
              error={Boolean(this.errorGet('lastName'))}
              helperText={this.errorGet('lastName')}
              key='lastName'
              label={Successors.lbl_last_name}
              onChange={e => this.onInputChange('lastName', e)}
              value={newSuccessor.lastName}
            />,
            <SsnInput
              disabled={successorLoading}
              error={Boolean(this.errorGet('ssn'))}
              errorText={this.errorGet('ssn')}
              key='ssn'
              label={Successors.lbl_ssn}
              onChange={({ value }) => this.onInputChange('ssn', { target: { value } })}
              value={newSuccessor.ssn}
            />,
            <div className={styles.datePickerContainer} key='newSuccessorBirthDate'>
              <DatePicker
                disabled={successorLoading} // disabled when saving
                error={Boolean(this.errorGet('birthDate'))}
                fullWidth
                helperText={this.errorGet('birthDate')}
                label={Successors.lbl_birth_date}
                maxDate={dayjs().subtract(MinimumAccountOwnerAge, 'year')}
                minDate={dayjs().subtract(MaximumAge, 'year')}
                onChange={value => this.onInputChange('birthDate', { target: { value } })}
                openTo='year'
                value={newSuccessor.birthDate}
              />
            </div>
          ]}

      </form>
    );
  }

  successorsListCompose = () => {
    const { successors, account: { primarySuccessor, secondarySuccessor }, text: { Successors } } = this.props;

    const successorsList = successors.map(successor => ({
      display: `${successor.firstName} ${successor.middleName} ${successor.lastName}`,
      props: {
        disabled: (primarySuccessor && successor.successorId === primarySuccessor.successorId) || (secondarySuccessor && successor.successorId === secondarySuccessor.successorId)
      },
      value: successor.successorId
    }));

    return [{ display: Successors.menu_new_successor, value: 'new' }].concat(successorsList);
  }

  tooltipsComposeModifySuccessor = (successorType) => {
    return [
      <div key={`remove${successorType}`} className={styles.successorRemove}>
        <IconBtnTooltip
          icon='remove'
          onClick={() => this.handleShowSuccessorModal(successorType, true)}
          title={`Remove ${successorType} Successor`}
        />
      </div>,
      <div key={`change${successorType}`} className={styles.successorEdit}>
        <IconBtnTooltip
          icon='edit'
          onClick={() => this.handleShowSuccessorModal(successorType)}
          title={`Change ${successorType} Successor`}
        />
      </div>
    ];
  }

  addButtonCompose = successorType => {
    return (
      <div className={styles.addSuccessorContainer}>
        <Button
          key={successorType}
          color='primary'
          startIcon={<Icon>add</Icon>}
          onClick={() => this.handleShowSuccessorModal(successorType)}
        >
          {this.props.text.Successors.btn_add_successor(successorType)}
        </Button>
      </div>
    );
  }

  handleShowSuccessorModal = (successorType, isRemove = false) => {
    const showProperty = isRemove ? 'showRemoveSuccessor' : 'showChangeSuccessor';
    const multiStatus = protectAction(this.props.claims);

    this.props.setLastClicked();
    this.setState({ successorLoading: true });
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState({ rememberIsRemove: isRemove, rememberSuccessorType: successorType, hasPrecondition: true });
      this.props.get2FAPrecondition().catch(() => this.setState({ successorLoading: false }));
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA) {
      this.setState({ rememberIsRemove: isRemove, rememberSuccessorType: successorType, hasPrecondition: true });
      this.props.getRSAPrecondition().catch(() => this.setState({ successorLoading: false }));
    }
    else {
      this.setState({
        [showProperty]: true,
        successorType,
        successorLoading: false,
        hasPrecondition: false,
      });
    }
  }

  componentDidMount() {
    if (this.props.successors.length === 0) {
      this.props.getSuccessors();
    }
  }

  componentDidUpdate() {
    const { rememberSuccessorType, rememberIsRemove, hasPrecondition, } = this.state;
    const multiStatus = protectAction(this.props.claims);
    if (hasPrecondition && multiStatus === MULTIFACTOR_TYPE.AUTH && this.props.isLastClicked) {
      this.handleShowSuccessorModal(rememberSuccessorType, rememberIsRemove);
    }
  }

  render() {
    const { account: { permissions, primarySuccessor, secondarySuccessor }, SUCCESSOR_TYPES, text: { Successors } } = this.props;
    // if successor exists AND they have permissions, use the name, if not use 'None'
    const primarySuccessorName = primarySuccessor ? `${primarySuccessor.firstName} ${primarySuccessor.middleName} ${primarySuccessor.lastName}` : Successors.text_none;
    const secondarySuccessorName = secondarySuccessor ? `${secondarySuccessor.firstName} ${secondarySuccessor.middleName} ${secondarySuccessor.lastName}` : Successors.text_none;

    return (
      <React.Fragment>
        <div className={parentStyles.successorsContainer}>
          <LoadingOverlay show={this.state.successorLoading} width='100%'>
            {(primarySuccessor || !permissions.ChangeSuccessor) ? ( // shows successor name display if successor exists or they do not have edit permissions
              <div className={styles.primarySuccessor2}>
                <div className={styles.successorTitle}>{Successors.head_title_primary_successor}</div>
                <div className={styles.successorName}>{primarySuccessorName}</div>
                {permissions.ChangeSuccessor && <div className={styles.buttonIcons}>{this.tooltipsComposeModifySuccessor(SUCCESSOR_TYPES.Primary)}</div>}
              </div>
            ) : (
              this.addButtonCompose(SUCCESSOR_TYPES.Primary)
            )}
            <Divider style={{ margin: '20px 0' }} />
            {(secondarySuccessor || !permissions.ChangeSuccessor) ? ( // shows successor name display if successor exists or they do not have edit permissions
              <div className={styles.secondarySuccessor2}>
                <div className={styles.successorTitle}>{Successors.head_title_secondary_successor}</div>
                <div className={styles.successorName}>{secondarySuccessorName}</div>
                {permissions.ChangeSuccessor && <div className={styles.buttonIcons}>{this.tooltipsComposeModifySuccessor(SUCCESSOR_TYPES.Secondary)}</div>}
              </div>
            ) : (
              this.addButtonCompose(SUCCESSOR_TYPES.Secondary)
            )}
          </LoadingOverlay>
        </div>

        {this.modifySuccessorModalCompose()}
        {this.removeSuccessorModalCompose()}
      </React.Fragment>

    );
  }
}

export default connect(select, {
  changeSuccessor,
  getAccounts,
  getSuccessors,
  notificationShow,
  removeSuccessor,
  getNotifications,
  get2FAPrecondition,
  getRSAPrecondition,
})(LanguageHOC(Successors));