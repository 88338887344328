import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Typography, Box } from '@mui/material';

function CircularProgressWithLabel(props) {
  const { value } = props;
  return (
    <Box
      display='inline-flex'
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '10'
      }}
    >
      <CircularProgress variant='determinate' size='5em' {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          component='div'
          color='primary'
          variant='h5'
        >{value ? `${Math.round(value)}%` : '0%'}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
  value: PropTypes.number
};

export default function CircularStatic(props) {
  const { show, progress, children } = props;
  if (show)
    return (
      <>
        <CircularProgressWithLabel value={progress} />
        <div>
          <div
            id='over'
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              backgroundColor: 'rgba(255, 255, 255, 0.35)',
              width: '100%',
              height: '100%',
              zIndex: '9'
            }}
          />
          <div
            style={{
              filter: 'blur(2px)',
              opacity: '0.4'
            }}
          >
            {children}
          </div>
        </div>
      </>
    );
  else 
    return (
      <>
        {children}
      </>
    );
}

CircularStatic.propTypes = {
  /**
       * The value of the progress indicator for the determinate variant.
       * Value between 0 and 100.
       */
  progress: PropTypes.number,
  show: PropTypes.bool,
};
