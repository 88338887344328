// Keep common labels, buttons and messages in this file
import React from 'react';

const buttons = {
  add: 'Add',
  agree: 'I Agree',
  agree_terms: 'I agree to the terms and conditions',
  back: 'Back',
  begin: 'Begin',
  cancel: 'Cancel',
  change: 'Change',
  close: 'Close',
  confirm: 'Confirm',
  continue: 'Continue',
  create: 'Create',
  delete: 'Delete',
  done: 'Done',
  edit: 'Edit',
  filter: 'Filter',
  finish: 'Finish',
  learn_more: 'Learn More',
  next: 'Next',
  ok: 'OK',
  print: 'Print',
  read_terms: 'Terms and conditions',
  register: 'register',
  reject: 'Reject',
  remove: 'Remove',
  revoke: 'Revoke',
  save: 'Save',
  skip: 'Skip',
  submit: 'Submit',
  toggle_password: 'Toggle password visibility',
  unlink: 'Unlink',
  update: 'Update',
  validate: 'Validate',
  view: 'View',
};
const errors = {
  age_limits(minAge, maxAge) { return `Age must be between ${minAge} and ${maxAge}.`; }, // eslint-disable-line
  amount_invalid: 'Enter a valid amount.',
  copy_email: 'Please type email to confirm.',
  copy_ssn_tin: 'Please type Social Security Number / TIN to confirm.',
  date_invalid: 'Select a valid date.',
  date_limits(minDate, maxDate) { return `Date must be between ${minDate} and ${maxDate}.`; }, // eslint-disable-line
  email_invalid: 'Email address is not valid.',
  emails_must_match: 'Emails must match.',
  field_max_length_20: 'Field cannot exceed 20 characters.',
  field_max_length_40: 'Field cannot exceed 40 characters.',
  no_trailing_leading_spaces: 'Field cannot contain leading or trailing spaces.',
  no_spaces_anywhere: 'This field cannot contain spaces',
  password_criteria: 'Password does not meet criteria.',
  passwords_match: 'Passwords must match.',
  phone_number_too_short: 'Phone number needs to be 10 digits.',
  postal_code_reqs: 'Postal code must be 5 or 9 digits long.',
  required: 'Field is required.',
  school_required: 'School is required. Click the button to select one.',
  select_option: 'Please select an option.',
  smarty_streets_generic: 'Address validation has stopped working. You may be sending too many requests, or your address may be malformed.',
  ssn_length: 'Social security number must be 9 digits.',
  ssns_tins_must_match: 'Social Security Numbers / TINs must match.',
  terms_agree: 'You must agree to the terms and conditions.',
  username_min_length: 'Username must be at least 6 characters.',
  username_with_numbers: 'Username cannot have more than 3 consecutive digits.',
  alphabetic_and_allowable_characters_only(allowableChars) {
    return `Allowable characters: alphabetic and ${allowableChars}`;
  },
  alpha_numeric_only: 'Please use only alphanumeric characters.',
  alpha_numeric_and_allowable_characters_only(allowableChars) {
    return `Allowable characters: alphanumeric and ${allowableChars}`;
  },
};
const formLabels = {
  address_city: 'City',
  address_postalCode: 'Postal Code',
  address_state: 'State',
  address_street1: 'Street Address 1',
  address_street2: 'Street Address 2',
  beneUtahTaxPayer: 'Utah taxpayer/resident',
  birthdate: 'Birth Date',
  clear_state: 'Clear State',
  email: 'Email',
  email_confirm: 'Confirm Email',
  firstName: 'First Name',
  homePhone: 'Home Phone',
  lastName: 'Last Name',
  middleName: 'Middle Name',
  password: 'Password',
  password_confirm: 'Confirm Password',
  pin: 'Pin',
  ssn: 'Social Security Number',
  tin: 'TIN',
  username: 'Username',
  utahTaxPayer: 'I am a Utah tax payer',
};

const mountain_time_zone = 'MT';
const intl_address = 'Do you have an international address? If so, please submit form 500, Account Information Change';
const current_password = 'Current password';
const unsaved_changes = 'Are you sure you want to leave? There are unsaved changes on this page.';
const recaptchaLanguage = 'This site is protected by reCAPTCHA and the Google <a target=_blank href=https://policies.google.com/privacy rel=\'noopener noreferrer\'>Privacy Policy</a> and <a href=https://policies.google.com/terms target=_blank rel=\'noopener noreferrer\'>Terms of Service</a> apply.';
const NA = 'N/A';
const fullBalanceWithdrawalNote = '*If this is a full-balance withdrawal, the actual amount will be based on the market closing price of your account when the transaction is completed.';

const msg_available_balance_contribution = (
  <>
    <p>All gift contributions to my529 accounts are subject to a hold, regardless of whether they are contributed directly from a bank account, from a debit card or by check.</p>
    <ul>
      <li>
        <strong>Contributions for account owners who have been with my529 less than six months:</strong> Funds will be held for 20 business days after the deposit.
      </li>
      <li>
        <strong>Contributions for account owners that have been with my529 over six months:</strong> Funds will be held for at least 7 business days after the deposit.
      </li>
      <li>
        <strong>Gift Program contributions made by debit card</strong> cannot be withdrawn until 45 business days after deposit.
      </li>
    </ul>
  </>
);

export {
  buttons,
  errors,
  formLabels,
  mountain_time_zone,
  intl_address,
  current_password,
  unsaved_changes,
  recaptchaLanguage,
  NA,
  fullBalanceWithdrawalNote,
  msg_available_balance_contribution,
};