/*
*
* Step2AmountDate Component
* Amount, Sell Date
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import parse from 'html-react-parser';

import {
  FULL_BALANCE,
  PARTIAL_BALANCE,
  RECURRING,
} from '../../../constants';

import {
  CurrencyInput,
  DatePicker,
  InfoIcon,
  currencyFormatter,
} from '@frontend/common';

import {
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  FormControl,
  Collapse,
  FormHelperText,
} from '@mui/material';

import styles from './styles.module.scss';

Step2AmountDate.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  partialWithdrawalMessageOpen: PropTypes.bool.isRequired,
  partialBalanceWithdrawalOnly: PropTypes.bool.isRequired,
  fullBalanceWithdrawalOnly: PropTypes.bool.isRequired,
  withdrawal: PropTypes.shape({
    amount: PropTypes.number,
    amountType: PropTypes.string.isRequired,
    leaveAccountOpen: PropTypes.bool.isRequired,
    proportionalAmounts: PropTypes.array.isRequired,
    sellDate: PropTypes.object,
  }),
  withdrawalScreenshot: PropTypes.shape({
    sellDate: PropTypes.object,
  }),
  isProportional: PropTypes.bool.isRequired,
  preTransactionDetails: PropTypes.object.isRequired,
  withdrawalVariables: PropTypes.object.isRequired,
  continueButtonHandle: PropTypes.func.isRequired,
  amountHandle: PropTypes.func.isRequired,
  dateHandle: PropTypes.func.isRequired,
  radioButtonLabelGenerate: PropTypes.func.isRequired,
  leaveAccountOpenHandle: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    amountError: PropTypes.string.isRequired,
    amountTypeError: PropTypes.string.isRequired,
    withdrawalTypeError: PropTypes.string.isRequired,
    sellDateError: PropTypes.string.isRequired,
    startDateError: PropTypes.string.isRequired,
    endDateError: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.shape({
    Withdrawals: PropTypes.shape({
      step0_amount_date: PropTypes.object.isRequired,
      text_full_balance_info: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  disableStartDates: PropTypes.func.isRequired,
  disableSellDates: PropTypes.func.isRequired,
  diableDatesMoreThan90Days: PropTypes.func.isRequired,
  nextRunStartDates: PropTypes.array.isRequired,
};

export function Step2AmountDate(props) {
  const {
    isEditing,
    partialWithdrawalMessageOpen,
    partialBalanceWithdrawalOnly,
    fullBalanceWithdrawalOnly,
    withdrawal: {
      amount,
      frequency,
      amountType,
      leaveAccountOpen,
      proportionalAmounts,
      sellDate,
      startDate,
      endDate,
      nextRunDate,
    },
    withdrawalScreenshot,
    isProportional,
    preTransactionDetails,
    withdrawalVariables,
    continueButtonHandle,
    amountHandle,
    amountTypeHandle,
    dateHandle,
    radioButtonLabelGenerate,
    leaveAccountOpenHandle,
    errors: {
      amountError,
      amountTypeError,
      sellDateError,
      startDateError,
      endDateError,
    },
    text: { Withdrawals: { step0_amount_date, text_full_balance_info } },
    disableStartDates,
    disableSellDates,
    nextRunStartDates,
  } = props;

  const getNextStartDate = (startDate, startDates, nextStartDates) => {
    const startDateNotChanged = dayjs(startDate).isSame(withdrawalScreenshot.startDate);
    let nextStartDate;
    if (isEditing && startDateNotChanged) {
      nextStartDate = nextRunDate;
    }
    else {
      // assuming starDates was sent to next run date api as an array and returned nextStartDates in the same order
      const startDateMoment = dayjs(startDate);
      const dateIndex = startDates.findIndex(date => dayjs(date).isSame(startDateMoment, 'date'));
      nextStartDate = nextStartDates[dateIndex];
    }

    return nextStartDate;
  };


  const isRecurring = frequency === RECURRING;
  // condition for partial withdrawals message
  const availableBalanceIsMoreThanMax = preTransactionDetails.totalAvailableForWithdrawalNum >= withdrawalVariables.maxWithdrawal;
  const areThereUncollectedFunds = preTransactionDetails.totalMarketValueNum > preTransactionDetails.totalAvailableBalanceNumber;

  const nextRunDateComponent = () => (
    <div>
      <small>Next run date: {dayjs(getNextStartDate(startDate, withdrawalVariables.startDates, nextRunStartDates)).format('L')}</small>
    </div>
  );

  const partialBalanceMessage = (
    <div className={styles.amountDateContainer}>
      <div className={styles.partialBalanceMessaging}>
        <strong>{step0_amount_date.partial_balance.title}</strong>
        {availableBalanceIsMoreThanMax ?
          <p>{step0_amount_date.partial_balance.more_than_max_p_long}</p>
          :
          <p>{step0_amount_date.partial_balance.more_than_max_p_short}</p>
        }
        <ul>
          <li>{step0_amount_date.partial_balance.listItem1}</li>
          {areThereUncollectedFunds && <li>{step0_amount_date.partial_balance.uncollected_funds_text}</li>}
          {availableBalanceIsMoreThanMax && <li>{parse(step0_amount_date.partial_balance.html_listItem2_more_than_max(currencyFormatter(withdrawalVariables.maxWithdrawal)))}</li>}
        </ul>
        <Button
          key='partialBalance'
          onClick={continueButtonHandle}
          style={{ marginTop: '12px' }}
          variant='contained'
        >
          {step0_amount_date.continue_btn_label}
        </Button>
      </div>
    </div>
  );

  const partialBalanceOnlyComponent = (
    <div className={styles.amountDateContainer}>
      <div key='amountInput'>
        <CurrencyInput
          autoFocus
          disabled={amountType === FULL_BALANCE || fullBalanceWithdrawalOnly}
          errorText={amountError}
          onChange={amountHandle}
          label={step0_amount_date.currency_input_label}
          value={amount}
          fullWidth
        />
      </div>

      <Collapse in={amountType === PARTIAL_BALANCE && proportionalAmounts.length > 0} key='partialProportionalAmount'>
        {proportionalAmounts.map(amount => (
          <div key={amount.accountId}>
            <small>{step0_amount_date.proportional_amounts(amount.accountNumber, currencyFormatter(amount.amount))}</small>
          </div>
        ))}
      </Collapse>

      {isRecurring ?
        <div>
          <div className={styles.sellDate} key='startDate'>
            <DatePicker
              clearable={false}
              error={Boolean(startDateError)}
              helperText={startDateError}
              label='Start Date'
              key='startDate'
              minDate={withdrawalVariables.sellDates[0]}
              onChange={(date) => dateHandle(date, 'startDate')}
              shouldDisableDate={disableStartDates}
              value={startDate}
            />
            <InfoIcon
              message={<div>{step0_amount_date.sellDateText(isRecurring).map(text => <p key={text}>{text}</p>)}</div>}
            />
          </div>
          {nextRunDateComponent()}
          <div className={styles.sellDate} key='endDate'>
            <DatePicker
              clearable={false}
              error={Boolean(endDateError)}
              helperText={endDateError}
              label='Optional End Date'
              key='endDate'
              minDate={dayjs(startDate).add(1, 'day')}
              onChange={(date) => dateHandle(date, 'endDate')}
              value={endDate}
            />
          </div>
        </div>
        :
        <div className={styles.sellDate} key='sellDate'>
          <DatePicker
            clearable={false}
            error={Boolean(sellDateError)}
            helperText={sellDateError}
            label={step0_amount_date.sell_date_label}
            key='sellDate'
            minDate={withdrawalVariables.sellDates[0]}
            maxDate={withdrawalVariables.sellDates[withdrawalVariables.sellDates.length - 1]}
            onChange={(date) => dateHandle(date, 'sellDate')}
            shouldDisableDate={disableSellDates}
            value={sellDate}
          />
          <InfoIcon
            message={<div>{step0_amount_date.sellDateText(isRecurring).map(text => <p key={text}>{text}</p>)}</div>}
          />
        </div>
      }
    </div>
  );

  const fullBalanceText = {
    name: 'Full Balance',
    messages: [text_full_balance_info]
  };

  const partialAndFullBalanceComponent = (
    <div className={styles.amountDateContainer}>
      <FormControl error={Boolean(amountTypeError)}>
        <RadioGroup
          onChange={amountTypeHandle}
          value={amountType}
        >
          <FormControlLabel
            label={radioButtonLabelGenerate(fullBalanceText)}
            value={FULL_BALANCE}
            control={<Radio disableRipple />}
            disabled={isRecurring || isEditing}
          />

          <FormControlLabel
            label='Partial Balance'
            value={PARTIAL_BALANCE}
            control={<Radio disableRipple />}
            disabled={fullBalanceWithdrawalOnly || isEditing}
          />
        </RadioGroup>
        {amountTypeError && <FormHelperText>{amountTypeError}</FormHelperText>}
      </FormControl>

      <div className={amountType === '' ? '' : 'NewWithdrawals_amountType'} key='amountTypeOptions'>
        <Collapse in={amountType === FULL_BALANCE}>
          <div className={styles.leaveAccountOpenCheckbox}>
            <FormControlLabel
              label={isProportional ? 'Leave accounts open' : 'Leave account open'}
              control={
                <Checkbox
                  checked={leaveAccountOpen}
                  onChange={leaveAccountOpenHandle}
                  disableRipple
                />
              }
            />
          </div>
        </Collapse>
        <Collapse in={amountType === PARTIAL_BALANCE}>
          {amountType === PARTIAL_BALANCE && // prevents console errors from <CurrencyInput /> when it tries to mount but isn't rendered
            <div>
              <CurrencyInput
                autoFocus
                disabled={amountType === FULL_BALANCE}
                errorText={amountError}
                onChange={amountHandle}
                label='Amount to withdraw...'
                value={!amount ? 0 : amount}
              />
            </div>
          }
        </Collapse>
        <Collapse in={amountType === PARTIAL_BALANCE && proportionalAmounts.length > 0}>
          {proportionalAmounts.map(amount => (
            <div key={amount.accountId}>
              <small>Account {amount.accountNumber}: {currencyFormatter(amount.amount)}</small>
            </div>
          ))}
        </Collapse>
      </div>

      {isRecurring ?
        <div>
          <div className={styles.sellDate} key='startDate'>
            <DatePicker
              clearable={false}
              error={Boolean(startDateError)}
              helperText={startDateError}
              label='Start Date'
              key='startDate'
              minDate={withdrawalVariables.sellDates[0]}
              onChange={(date) => dateHandle(date, 'startDate')}
              shouldDisableDate={disableStartDates}
              value={startDate}
            />
            <InfoIcon
              message={<div>{step0_amount_date.sellDateText(isRecurring).map(text => <p key={text}>{text}</p>)}</div>}
            />
          </div>
          {nextRunDateComponent()}
          <div className={styles.sellDate} key='endDate'>
            <DatePicker
              clearable={false}
              error={Boolean(endDateError)}
              helperText={endDateError}
              label='Optional End Date'
              key='endDate'
              minDate={dayjs(startDate).add(1, 'day')}
              onChange={(date) => dateHandle(date, 'endDate')}
              value={endDate}
            />
          </div>
        </div>
        :
        <div className={styles.sellDate} key='sellDate'>
          <DatePicker
            clearable={false}
            error={Boolean(sellDateError)}
            helperText={sellDateError}
            label='Sell Date'
            key='sellDate'
            minDate={withdrawalVariables.sellDates[0]}
            maxDate={withdrawalVariables.sellDates[withdrawalVariables.sellDates.length - 1]}
            onChange={(date) => dateHandle(date, 'sellDate')}
            shouldDisableDate={disableSellDates}
            value={sellDate}
          />
          <InfoIcon
            message={<div>{step0_amount_date.sellDateText(isRecurring).map(text => <p key={text}>{text}</p>)}</div>}
          />
        </div>
      }
    </div>
  );

  let component;
  if (Object.keys(preTransactionDetails).length > 0 && partialBalanceWithdrawalOnly) {
    component = partialWithdrawalMessageOpen ? partialBalanceMessage : partialBalanceOnlyComponent;
  }
  else {
    component = partialAndFullBalanceComponent;
  }

  return component;
}

export default LanguageHOC(Step2AmountDate);