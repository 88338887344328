import { combineReducers } from '@reduxjs/toolkit';

import { INITIALIZE_STORE } from 'components/AppRoot/Navigation/constants'; 

import Accounts from 'components/Features/protected/Accounts/reducer';
import Gifting from 'components/Features/protected/Gifting/reducer';
import Transactions from 'components/Features/protected/Accounts/Transactions/reducer';
import Payments from 'components/Features/protected/Payments/reducer';
import Notifications from '@frontend/common/build/components/Notifications/reducer';
import Session from 'components/AppRoot/Navigation/reducer';
import Static from 'components/AppRoot/StaticResources/reducer';
import MyInfo from 'components/Features/protected/MyInfo/reducer';
import Multifactor from 'components/Features/protected/Multifactor/reducer';
import OptionChanges from 'components/Features/protected/Accounts/Transactions/OptionChanges/reducer';
import Signup from 'components/Features/protected/Signup/reducer';
import Documents from 'components/Features/protected/Documents/reducer';
import PayrollContribution from 'components/Features/protected/PayrollContribution/reducer';
import ForgotUsername from 'components/Features/public/ForgotUsername/reducer';
import RegistrationType from 'components/Features/public/RegistrationType/reducer';
import LpoaReducer from 'components/Features/protected/Lpoa/reducer';
import InterestedPartiesReducer from 'components/Features/protected/InterestedParties/reducer';
import LpoaAccountReducer from 'components/Features/public/LpoaAccount/reducer';

/*
Ability to reinitialize the entire store.
This action type is used by /logout
*/
const rootReducer = (state, action) => {
  if (action.type === INITIALIZE_STORE) {
    state = {
      static: {
        environmentVars: state.static.environmentVars,
        documents: state.static.documents,
        webMessages: state.static.webMessages,
        loadingPublicVars: state.static.loadingPublicVars,
        passwordRequirements: state.static.passwordRequirements,
        serverDateTime: '',
        threatMetrixVars: {
          SessionId: '',
          ThreatMetrixUrl: '',
        },
        usStates: state.static.usStates,
        withdrawalVariables: {
          paymentTypes: [],
          recipientTypes: [],
          sellDates: [],
          startDates: [],
          withdrawalTypes: [],
          minWithdrawal: 0,
          maxWithdrawal: 0,
          tradeCutoff: {},
          tradeCutoffWarningTime: 0,
        }
      }
    };
  }
  
  return appReducer(state, action);
};

const appReducer = combineReducers({
  accounts: Accounts,
  gifting: Gifting,
  transactions: Transactions,
  payments: Payments,
  notifications: Notifications,
  session: Session,
  static: Static,
  myInfo: MyInfo,
  multifactor: Multifactor,
  optionChanges: OptionChanges,
  signup: Signup,
  documents: Documents,
  payrollContribution: PayrollContribution,
  forgotusername: ForgotUsername,
  registrationtype: RegistrationType,
  lpoaReducer: LpoaReducer,
  interestedParties: InterestedPartiesReducer,
  lpoaAccount: LpoaAccountReducer,
});

export default rootReducer;
