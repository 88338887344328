export function getRegisteredDevice() {
  const allCookies = document.cookie.split(';');
  const devicePair = allCookies.find(crumb => crumb.includes('device'));
  return devicePair ? devicePair.substr(devicePair.indexOf('=') + 1, devicePair.length) : '';
}

export function setRegisteredDevice(GUID, currentDateFromApi, cookieExpirationInDaysFromApi) {
  const newDate = new Date(currentDateFromApi);
  newDate.setDate(newDate.getDate() + cookieExpirationInDaysFromApi);
  const expiration = newDate.toUTCString(); // per w3 cookie rules
  const hostName = document.location.hostname;
  hostName === 'localhost' 
    ? document.cookie = `device=${GUID}; expires=${expiration}; path=/; SameSite=Strict;` // local DEV
    : document.cookie = `device=${GUID}; expires=${expiration}; path=/; domain=my529.org; SameSite=Strict;`;
}