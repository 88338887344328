/*
 *
 * Sessions constants
 *
 */
export const INITIALIZE_STORE = 'App_Root/components/Sessions/INITIALIZE_STORE';

export const BROWSER_WARNING = 'App_Root/components/Sessions/BROWSER_WARNING';

export const USER_LOGIN = 'App_Root/components/Sessions/USER_LOGIN';
export const CHANGE_USERNAME = 'App_Root/components/Sessions/CHANGE_USERNAME';

export const TOKEN_VALIDATE = 'App_Root/components/Sessions/TOKEN_VALIDATE';
export const TOKEN_VALIDATE_INTERVAL_ID_SET = 'App_Root/components/Sessions/TOKEN_VALIDATE_INTERVAL_ID_SET';
export const TOKEN_VALIDATE_INTERVAL_ID_CLEAR = 'App_Root/components/Sessions/TOKEN_VALIDATE_INTERVAL_ID_CLEAR';
export const SESSION_END_WARNING_TIMER_ID_SET = 'App_Root/components/Sessions/SESSION_END_WARNING_TIMER_ID_SET';
export const SESSION_END_WARNING_TIMER_ID_CLEAR = 'App_Root/components/Sessions/SESSION_END_WARNING_TIMER_ID_CLEAR';
export const VERIFY_RECAPTCHA = 'App_Root/components/Sessions/VERIFY_RECAPTCHA';

export const LANGUAGE_CHANGE = 'App_Root/components/Sessions/LANGUAGE_CHANGE';

export const RESEND_EMAIL_VALIDATION = 'App_Root/components/Sessions/RESEND_EMAIL_VALIDATION';

export const USER_REGISTRATION = 'App_Root/components/Sessions/USER_REGISTRATION';
export const USER_REG_INFO_SYNC = 'App_Root/components/Sessions/USER_REG_INFO_SYNC';
export const RESEND_REGISTRATION_EMAIL = 'App_Root/components/Sessions/RESEND_REGISTRATION_EMAIL';

export const NEW_ACCOUNT_CREATED = 'App_Root/components/Sessions/NEW_ACCOUNT_CREATED';

export const REFRESH_CLAIMS = 'App_Root/components/Sessions/REFRESH_CLAIMS';
export const RESET_CLAIMS = 'App_Root/components/Sessions/RESET_CLAIMS';

export const TOGGLE_SIDENAV = 'App_Root/components/Sessions/TOGGLE_SIDENAV';
export const TOGGLE_NOTIFICATIONS = 'App_Root/components/Sessions/TOGGLE_NOTIFICATIONS';
export const SET_NOTIFICATIONS_VIEWED = 'App_Root/components/Sessions/SET_NOTIFICATIONS_VIEWED';

export const TWO_FACTOR_PRECONDITION = 'App_Root/components/Sessions/TWO_FACTOR_PRECONDITION_PRECONDITION';
export const RSA_PRECONDITION = 'App_Root/components/Sessions/RSA_PRECONDITION';

export const TOGGLE_2FA_DIALOG = 'App_Root/components/Sessions/TOGGLE_2FA_DIALOG';
export const TOGGLE_AUTHYDOWN_DIALOG = 'App_Root/components/Sessions/TOGGLE_AUTHYDOWN_DIALOG';
export const TOGGLE_RSA_DIALOG = 'App_Root/components/Sessions/TOGGLE_RSA_DIALOG';

export const GET_USER_PREFERENCES = 'App_Root/components/Sessions/GET_USER_PREFERENCES';
export const SAVE_USER_PREFERENCES = 'App_Root/components/Sessions/SAVE_USER_PREFERENCES';

export const GET_NOTIFICATIONS = 'App_Root/components/Sessions/GET_NOTIFICATIONS';

export const TWO_FACTOR_ROUTE_CHANGE = 'App_Root/components/Sessions/TWO_FACTOR_ROUTE_CHANGE';

export const SAVE_BIRTHDATE = 'App_Root/components/Sessions/SAVE_BIRTHDATE';

export const DISABLE_LEFT_MENU = 'App_Root/components/Sessions/DISABLE_LEFT_MENU';

export const TOGGLE_SPLASH_HAS_DISPLAYED = 'App_Root/components/Sessions/TOGGLE_SPLASH_HAS_DISPLAYED';

export const REASONS_BLOCKED = {
  MISSING_EMAIL_REQUIRED: 'EmailOnFile',
  EMAIL_VALIDATION_REQUIRED: 'EmailValidation',
  TWO_FACTOR_REGISTRATION: 'TwoFactorRegistration',
  TWO_FACTOR_AUTHENTICATION: 'TwoFactorAuthentication',
  RSA_VERIFICATION: 'RSAVerification',
  NO_BIRTHDATE: 'BirthdateOnFile',
};

export const TWO_FACTOR_STATUS = {
  COMPLETED: 'Completed',
  PENDING: 'Pending',
  BYPASSED: 'Bypassed',
  UNAVAILABLE: 'Unavailable',
  REQUIRED: 'Required'
};

export const RSA_STATUS = {
  LOCKED: 'LockedOut',
  AVAILABLE: 'Available',
  PENDING: 'Pending',
  BYPASSED: 'Bypassed',
  COMPLETED: 'Completed',
};

export const MULTIFACTOR_TYPE = {
  TWO_FACTOR: '2FA',
  RSA: 'RSA',
  AUTH: 'AUTHENTICATED',
};

export const TWO_FACTOR_TYPES = {
  REGISTRATION: 'registrations',
  AUTHENTICATION: 'authentication',
  PROTECTED: 'protected',
  UPDATE: 'update'
};

export const NOTIFICATION_TYPES = {
  CONTRIBUTION: 'Contribution',
  GIFTING: 'Gifting',
  ACCOUNT: 'Account',
  BANK_ACCOUNT: 'Bank Account',
  DOCUMENTS: 'Documents',
  INTERESTED_PARTY: 'Interested Party',
};