/*
*
* OptionChangeDetails Component
*
*/
import React from 'react';

import PropTypes from 'prop-types';
import {
  CardAligned,
  CardTitle,
  CardBody,
} from '@frontend/common';

import styles from './styles.module.scss';

export class OptionChangeDetails extends React.Component {

  static propTypes = {
    currentOptionName: PropTypes.string.isRequired,
    remainingOptionChanges: PropTypes.number.isRequired,
  };

  render() {
    const {
      currentOptionName,
      remainingOptionChanges,
    } = this.props;

    return (
      <CardAligned className={styles.optionDetails}>
        <CardTitle>Option Change Details</CardTitle>
        <CardBody>
          <div className={styles.detailsTitle}>Current Option</div>
          {currentOptionName}
          <div className={styles.detailsTitle}>Option Changes Remaining for Calendar Year</div>
          <div className={remainingOptionChanges === 0 ? styles.zeroRemainingOptionChanges : ''}>{remainingOptionChanges}</div>
        </CardBody>
      </CardAligned>
    );
  }
}

export default OptionChangeDetails;