/*
*
* Overview Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { cloneDeep, debounce, } from 'lodash';
import dayjs from 'dayjs';
import * as validator from 'utils/helpers/form_validation';

import {
  Button,
  Chip,
  Icon,
} from '@mui/material';

import {
  currencyFormatter,
  DatePicker,
  Dropdown,
  IconBtnTooltip,
  Modal,
} from '@frontend/common';

import { getBalanceHistory, } from 'components/Features/protected/Accounts/actions';
import { TWO_FACTOR_STATUS } from 'components/AppRoot/Navigation/constants';

import BalanceGraph from './BalanceGraph';
import BalanceByDate from './BalanceByDate';
import ThirdPartyWarningModal from 'components/AppRoot/Navigation/Sidenav/ThirtPartyWarningModal';
import butterfly from './image/butterfly.svg';

import styles from './styles.module.scss';
import { mobileWidth } from 'utils/config/_sassconfig.scss';
import SkeletonLoader from 'utils/components/SkeletonLoader';

const selectAll = 'selectAll';
const warningTypes = {
  noActiveAccounts: 'noActiveAccounts',
  dataUnavailable: 'dataUnavailable',
  newAccount: 'newAccount',
};

export class Overview extends React.Component {

  static propTypes = {
    accountList: PropTypes.array.isRequired,
    balanceHistory: PropTypes.array.isRequired,
    claims: PropTypes.object.isRequired,
    getBalanceHistory: PropTypes.func.isRequired,
    homeIsLoading: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      AccountsSummary: PropTypes.shape({
        head_interested_party: PropTypes.string,
      }),
      Overview: PropTypes.shape({
        err_date_past_today: PropTypes.string,
        err_end_before_start: PropTypes.string,
        err_start_after_end: PropTypes.string,
        lbl_bene_account: PropTypes.func,
        lbl_end_date: PropTypes.string,
        lbl_filter_1month: PropTypes.string,
        lbl_filter_3month: PropTypes.string,
        lbl_filter_6month: PropTypes.string,
        lbl_filter_YTD: PropTypes.string,
        lbl_filter_1year: PropTypes.string,
        lbl_filter_all: PropTypes.string,
        lbl_filter_custom: PropTypes.string,
        lbl_start_date: PropTypes.string,
        lbl_savings_calculator: PropTypes.string,
        text_all_selected: PropTypes.string,
        text_NA: PropTypes.string,
        text_no_open_accounts: PropTypes.string,
        text_oops: PropTypes.string,
        text_see_more: PropTypes.string,
        text_select_all: PropTypes.string,
        text_something_wrong: PropTypes.string,
        title_close_fullscreen: PropTypes.string,
        title_open_fullscreen: PropTypes.string,
      }),
      Sidenav: PropTypes.shape({
        resources: PropTypes.shape({
          txt_third_party_warning_for_info: PropTypes.string,
        }),
      }),
    }),
  }

  state = {
    dateFilters: [
      { label: this.props.text.Overview.lbl_filter_1month, filterDate: '' },
      { label: this.props.text.Overview.lbl_filter_3month, filterDate: '' },
      { label: this.props.text.Overview.lbl_filter_6month, filterDate: '' },
      { label: this.props.text.Overview.lbl_filter_YTD, filterDate: '' },
      { label: this.props.text.Overview.lbl_filter_1year, filterDate: '' },
      { label: this.props.text.Overview.lbl_filter_all, filterDate: '' },
      { label: this.props.text.Overview.lbl_filter_custom, filterDate: '' },
    ],
    activeGraphDateFilter: { label: this.props.text.Overview.lbl_filter_1year, filterDate: dayjs().subtract(1, 'year') },
    dashboardAccountList: [], // main balance history data
    dateFilteredDashboardAccountList: [], // all accounts balance history with filtered dates
    graphData: [{ name: '', accountBalanceHistory: [{ balance: 0, date: dayjs() }] }], // fully filtered data for graph
    isBalanceHistoryLoading: true,
    selectedAccounts: [],
    showCustomDatePickers: false,
    customFilterDates: { startDate: '', endDate: '' },
    formErrors: {},
    minMaxDates: { minDate: '', maxDate: '' },
    showBeneDropdown: false,
    isGraphFullscreen: false,
    hasPrecondition: false,
    warningType: '',
    thirdPartyWarningOn: false,
  }

  customDateHandle = e => {
    const { minDate, maxDate } = this.state.minMaxDates;
    const fieldName = e.target.name;
    let date = e.target.value;

    if (fieldName === 'startDate' && dayjs(date).isBefore(minDate, 'day')) {
      date = minDate;
    }
    if (fieldName === 'endDate' && dayjs(date).isAfter(maxDate, 'day')) {
      date = maxDate;
    }

    this.setState({
      customFilterDates: {
        ...this.state.customFilterDates,
        [fieldName]: date
      },
      formErrors: {
        ...this.state.formErrors,
        [fieldName]: '',
      }
    }, () => this.filterByCustomDates(fieldName));
  }

  accountSelect = (selections) => {
    const { dateFilteredDashboardAccountList, selectedAccounts } = this.state;
    let newSelections = [];
    let newGraphData = [];

    if (selections.length !== 0 || selectedAccounts.includes(selectAll)) { // only run, if selections exist or if selectAll was previously selected
      if (selections.includes(selectAll) && !selectedAccounts.includes(selectAll)) { // if select all is newly selected and currently not in selection, shows all accounts
        newSelections = [selectAll];
        newGraphData = dateFilteredDashboardAccountList; // sets the full list as graphData
        this.getMinMaxDates(newSelections);
        this.setState({ showBeneDropdown: false });
      }
      else if (selectedAccounts.includes(selectAll) && selections.length > 1) { // if select all is already selected and an account is selected, turns off select all and goes to single account
        const selectAllIndex = selections.indexOf(selectAll);
        selections.splice(selectAllIndex, 1);
        newSelections = selections;
        newGraphData = this.filterGraphData(newSelections);
      }
      else if (!selections.includes(selectAll) && selectedAccounts.includes(selectAll)) { // if select all is newly selected and currently in selection, defaults back to first account
        newSelections = [dateFilteredDashboardAccountList[0].accountNumber];
        newGraphData = this.filterGraphData(newSelections);
      }
      else { // normal, multiple selection
        newSelections = selections;
        newGraphData = this.filterGraphData(newSelections);
      }

      this.setState({ selectedAccounts: newSelections, graphData: newGraphData, });
    }
  }

  getMinMaxDates = selectedAccounts => {
    const { dashboardAccountList, } = this.state;
    const filteredDashboardAccountList = selectedAccounts.includes(selectAll) ? dashboardAccountList : dashboardAccountList.filter(account => selectedAccounts.includes(account.accountNumber));
    const minMaxDates = { minDate: '', maxDate: dayjs() };
    filteredDashboardAccountList.forEach(account => { // find max earliest and latest dates from selected accounts
      const earliestBalanceDate = account.accountBalanceHistory[0].date;
      const latestBalanceDate = account.accountBalanceHistory[account.accountBalanceHistory.length - 1].date;
      minMaxDates.minDate = !minMaxDates.minDate || dayjs(earliestBalanceDate).isBefore(minMaxDates.minDate, 'day') ? earliestBalanceDate : minMaxDates.minDate;
      minMaxDates.maxDate = latestBalanceDate;
    });
    const hasLimitedData = !filteredDashboardAccountList.some(account => account.accountBalanceHistory.length > 3);

    this.setState({ minMaxDates, warningType: hasLimitedData ? warningTypes.newAccount : '' });
  }

  filterGraphData = selectedAccounts => {
    if (selectedAccounts[0] !== selectAll) { // don't filter, if all accounts are selected
      this.getMinMaxDates(selectedAccounts);
      return this.state.dateFilteredDashboardAccountList.filter(account => selectedAccounts.includes(account.accountNumber));
    }
    else {
      return this.state.dateFilteredDashboardAccountList;
    }
  }

  filterByDateRange = dateFilter => {
    const { Overview } = this.props.text;
    const { dashboardAccountList, selectedAccounts, minMaxDates, } = this.state;
    let dateFilteredDashboardAccountList = this.state.dateFilteredDashboardAccountList;
    const customFilterDates = this.state.customFilterDates;
    const formErrors = this.state.formErrors;
    let runFilter = true;
    let showCustomDatePickers = false;

    if (dateFilter.label === Overview.lbl_filter_all) {
      dateFilteredDashboardAccountList = dashboardAccountList;
      customFilterDates.startDate = minMaxDates.minDate;
      customFilterDates.endDate = minMaxDates.maxDate;
    }
    else if (dateFilter.label === Overview.lbl_filter_custom) {
      formErrors.startDate = ''; // clear form errors
      formErrors.endDate = '';
      showCustomDatePickers = true; // show date pickers for custom range
      runFilter = false; // Custom filter will run when two valid dates are entered
    }
    else { // uses one of the set date ranges
      dateFilteredDashboardAccountList = dashboardAccountList.map(account => ({
        ...account,
        accountBalanceHistory: account.accountBalanceHistory.filter(balance => dayjs(balance.date).isSameOrAfter(dateFilter.filterDate, 'day')),
      }));
      customFilterDates.startDate = dateFilter.filterDate;
      customFilterDates.endDate = minMaxDates.maxDate;
    }

    this.setState({
      activeGraphDateFilter: dateFilter,
      customFilterDates,
      dateFilteredDashboardAccountList,
      showCustomDatePickers,
      formErrors,
    }, () => (
      runFilter && this.setState({ graphData: this.filterGraphData(selectedAccounts) }))
    );
  }

  filterByCustomDates = fieldName => {
    const { dashboardAccountList, customFilterDates, selectedAccounts, } = this.state;

    if (this.formValidate(fieldName)) {
      let dateFilteredDashboardAccountList = this.state.dateFilteredDashboardAccountList; // default to main dashboard list, if dates are removed
      if (customFilterDates.startDate && customFilterDates.endDate) { // if both dates are provided, then get filtered transactions
        dateFilteredDashboardAccountList = dashboardAccountList.map(account => ({
          ...account,
          accountBalanceHistory: account.accountBalanceHistory.filter(balance => (
            dayjs(balance.date).isSameOrAfter(customFilterDates.startDate, 'day') && dayjs(balance.date).isSameOrBefore(customFilterDates.endDate, 'day'))
          ),
        }));
      }
      this.setState({ dateFilteredDashboardAccountList, }, () => this.setState({ graphData: this.filterGraphData(selectedAccounts) }));
    }
  }

  formValidate = (fieldName) => {
    const { Overview } = this.props.text;
    const { startDate, endDate } = this.state.customFilterDates;
    const formErrors = {};

    // startDate validation
    formErrors.startDate = validator.calendarValidator(startDate);
    if (dayjs(startDate).isAfter(dayjs(), 'day')) {
      formErrors.startDate = Overview.err_date_past_today;
    }
    if (dayjs(startDate).isAfter(dayjs(endDate), 'day') && fieldName === 'startDate') {
      formErrors.startDate = Overview.err_start_after_end;
    }

    // endDate validation
    formErrors.endDate = validator.calendarValidator(endDate);
    if (dayjs(endDate).isBefore(dayjs(startDate), 'day') && fieldName === 'endDate') {
      formErrors.endDate = Overview.err_end_before_start;
    }

    // if both dates are blank, empty out errors
    if (!startDate && !endDate) {
      formErrors.startDate = '';
      formErrors.endDate = '';
    }

    this.setState({ formErrors });
    const hasErrors = Object.keys(formErrors).some(field => formErrors[field] !== '');

    return !hasErrors;
  }

  renderWarningDisplay = () => {
    const { Overview, } = this.props.text;
    const { warningType } = this.state;
    let message;

    switch (warningType) {
      case warningTypes.noActiveAccounts:
        message = [
          <p className={styles.warningText} key='warningText'>{Overview.text_no_open_accounts}</p>,
        ];
        break;

      case warningTypes.dataUnavailable:
        message = [
          <div className={styles.warningHeader} key='warningHeader'>{Overview.text_oops}</div>,
          <p className={styles.warningText} key='warningText'>{Overview.text_something_wrong}</p>
        ];
        break;

      case warningTypes.newAccount:
        message = [
          <p className={styles.warningText} key='warningText'>{Overview.text_see_more}</p>,
        ];
        break;

      default: break;
    }

    return (
      <div className={styles.warningDisplayContainer}>
        <img src={butterfly} className={styles.butterfly} alt='butterfly' />
        {message}
      </div>
    );
  }

  renderSelectionAccount = account => {
    const displayedOptionName = account.isInterestedParty ? `${account.optionName} - ${this.props.text.AccountsSummary.head_interested_party}` : account.optionName;

    return (
      <div className={styles.accountSelectionMenu}>
        <div>{account.beneficiaryName.toUpperCase()}</div>
        <div style={{ color: 'rgb(0, 0, 0, 0.54)', fontSize: 'x-small', fontWeight: 'bold' }}>{displayedOptionName}</div>
        <div style={{ color: 'var(--primary)' }}>{account.accountNumber}</div>
      </div>
    );
  }

  renderDateFilterChips = () => {
    const { Overview } = this.props.text;
    const { dateFilters, activeGraphDateFilter, showCustomDatePickers, customFilterDates, formErrors, minMaxDates } = this.state;

    return (
      <div className={styles.graphDateFilterContainer}>
        <div className={styles.dateFilterChips}>
          {dateFilters.map(dateFilter => {
            const isActiveChip = activeGraphDateFilter.label === dateFilter.label;
            const isSpecialFilter = dateFilter.label === Overview.lbl_filter_custom || dateFilter.label === Overview.lbl_filter_all; // checks if it is custom or all filter
            const isDisabled = !isSpecialFilter ? !dayjs(minMaxDates.minDate).isSameOrBefore(dateFilter.filterDate, 'day') : false; // if not a special filter, check if oldest balance date is past the set filter date

            return (
              <Chip
                className={isActiveChip ? styles.active : styles.inactive}
                color={isActiveChip ? 'primary' : 'default'}
                disabled={isDisabled}
                key={dateFilter.label}
                label={dateFilter.label}
                onClick={() => this.filterByDateRange(dateFilter)}
                style={{ margin: '2px 5px 2px 0', }}
                variant='filled'
              />
            );
          })}
        </div>
        {showCustomDatePickers &&
          <div className={styles.datePickers}>
            <div className={styles.startDate}>
              <DatePicker
                error={Boolean(formErrors.startDate)}
                fullWidth
                helperText={formErrors.startDate}
                variant='filled'
                label={Overview.lbl_start_date}
                maxDate={dayjs(minMaxDates.maxDate).subtract(1, 'day')}
                minDate={minMaxDates.minDate}
                onChange={debounce(value => this.customDateHandle({ target: { name: 'startDate', value, } }), 500)}
                openTo='year'
                value={customFilterDates.startDate || null}
              />
            </div>
            <div className={styles.endDate}>
              <DatePicker
                error={Boolean(formErrors.endDate)}
                fullWidth
                helperText={formErrors.endDate}
                variant='filled'
                label={Overview.lbl_end_date}
                maxDate={minMaxDates.maxDate}
                minDate={dayjs(minMaxDates.minDate).subtract(1, 'day')}
                onChange={debounce(value => this.customDateHandle({ target: { name: 'endDate', value, } }), 500)}
                openTo='year'
                value={customFilterDates.endDate || null}
              />
            </div>
          </div>}
      </div>
    );
  }

  renderBalanceOverTime = () => {
    const { Overview } = this.props.text;
    const { selectedAccounts, dashboardAccountList, graphData, showBeneDropdown, isGraphFullscreen, activeGraphDateFilter, warningType, isBalanceHistoryLoading, } = this.state;
    const isNotMobileView = window.innerWidth > parseInt(mobileWidth);
    const multipleSelected = selectedAccounts.length > 1 || selectedAccounts.includes(selectAll);

    const selectedProps = {
      renderValue: (selected) => {
        const isSelectAll = selected.includes(selectAll);
        const selectedAccount = dashboardAccountList.find(account => account.accountNumber === selected[0]);
        const selections = isSelectAll ? [Overview.text_all_selected.toUpperCase()] : selected; // if select all, render Selected All text, otherwise use list of accountNumbers

        return selections.length !== 1 || isSelectAll
          ? <span style={{ color: 'var(--primary)' }}>{selections.join(', ')}</span>
          : (
            this.renderSelectionAccount(selectedAccount)
          );
      },
      open: showBeneDropdown,
      onClose: () => this.setState({ showBeneDropdown: false }),
      SelectDisplayProps: { onClick: () => this.setState({ showBeneDropdown: !showBeneDropdown }) },
      disabled: isBalanceHistoryLoading,
    };

    return (
      <>
        <div className={styles.aboveGraphContainer}>
          <div className={styles.dropdownContainer}>
            <div className={styles.accountDropdown}>
              <SkeletonLoader isLoading={isBalanceHistoryLoading} height={85}>
                <Dropdown
                  label={Overview.lbl_bene_account(multipleSelected)}
                  value={selectedAccounts}
                  onChange={value => this.accountSelect(value)}
                  options={[
                    {
                      value: selectAll,
                      display: Overview.text_select_all.toUpperCase(),
                      props: { style: selectedAccounts.includes(selectAll) ? { color: 'var(--primary)', fontWeight: 'bold', } : {} }
                    },
                    ...dashboardAccountList.map(account => ({
                      value: account.accountNumber,
                      display: this.renderSelectionAccount(account),
                      props: { style: selectedAccounts.includes(account.accountNumber) ? { color: 'var(--primary)', fontWeight: 'bold', } : {} }
                    }))
                  ]}
                  multiple={true}
                  SelectProps={selectedProps}
                  FormControlProps={{ variant: 'filled', disabled: isBalanceHistoryLoading, }}
                />
              </SkeletonLoader>
            </div>
            <div className={styles.savingsCalulatorButton}>
              <Button
                variant='text'
                color='secondary'
                startIcon={<Icon>calculate</Icon>}
                onClick={() => this.setState({ thirdPartyWarningOn: true })}
              >
                {Overview.lbl_savings_calculator}
              </Button>
            </div>
          </div>

          <div className={styles.toolTipContainer}>
            {(!isGraphFullscreen && isNotMobileView && !warningType && !isBalanceHistoryLoading) &&
              <IconBtnTooltip
                icon='launch'
                onClick={() => this.setState({ isGraphFullscreen: true })}
                title={Overview.title_open_fullscreen}
              />}
          </div>
        </div>
        <SkeletonLoader isLoading={isBalanceHistoryLoading} height={400}>
          {warningType === warningTypes.newAccount ?
            this.renderWarningDisplay() :
            <>
              <div className={isGraphFullscreen ? styles.balanceGraphFullscreenContainer : styles.balanceGraphContainer}>
                <BalanceGraph
                  key={selectedAccounts}
                  graphData={graphData}
                  isGraphFullscreen={isGraphFullscreen}
                  activeDateFilter={activeGraphDateFilter.label}
                />
              </div>
              {this.renderDateFilterChips()}
            </>
          }
        </SkeletonLoader>
      </>
    );
  }

  getBalanceHistory() {
    this.setState({ isBalanceHistoryLoading: true });
    this.props.getBalanceHistory()
      .then(response => {
        const balanceHistory = response.payload.data;

        if (balanceHistory.length === 0) {
          this.setState({ warningType: warningTypes.noActiveAccounts, isBalanceHistoryLoading: false });
        }
        else { // if more than 10 accounts, the data will not be stored in redux
          this.formatDashboardAccountList(balanceHistory);
        }
      })
      .catch(() => this.setState({ isBalanceHistoryLoading: false, }));
  }

  formatDashboardAccountList = balanceHistory => {
    const alphabetizedBalanceHistory = balanceHistory.sort((obj1, obj2) => { // alphabetizes account list by bene first name
      const id1 = `${obj1.BeneficiaryName.toUpperCase()}`;
      const id2 = `${obj2.BeneficiaryName.toUpperCase()}`;

      return id1 < id2 ? -1 : 1;
    });

    const dashboardAccountList = alphabetizedBalanceHistory.map(account => {
      const sortedBalanceHistory = account.BalanceHistory.sort((first, second) => new Date(first.Date) - new Date(second.Date));
      return {
        accountBalanceHistory: sortedBalanceHistory.map(balance => ({
          balance: balance.Balance,
          date: dayjs(balance.Date),
        })),
        accountId: account.AccountId,
        accountNumber: account.AccountNumber,
        balanceAsOfGivenDate: currencyFormatter(sortedBalanceHistory[sortedBalanceHistory.length - 1].Balance),
        beneficiaryName: account.BeneficiaryName,
        dataAvailable: account.DataAvailable,
        isInterestedParty: Boolean(account.InterestedPartyId),
        optionName: account.OptionName,
      };
    });

    const { Overview } = this.props.text;
    const defaultSelectedAccount = dashboardAccountList.length > 0 ? [dashboardAccountList[0].accountNumber] : '';
    const maxDate = dashboardAccountList[0].accountBalanceHistory[dashboardAccountList[0].accountBalanceHistory.length - 1].date;
    const minDate = dashboardAccountList[0].accountBalanceHistory[0].date;
    const minMaxDates = {
      maxDate,
      minDate
    };

    const dateFilters = [
      { label: Overview.lbl_filter_1month, filterDate: dayjs(maxDate).subtract(1, 'month') },
      { label: Overview.lbl_filter_3month, filterDate: dayjs(maxDate).subtract(3, 'month') },
      { label: Overview.lbl_filter_6month, filterDate: dayjs(maxDate).subtract(6, 'month') },
      { label: Overview.lbl_filter_YTD, filterDate: dayjs(maxDate).startOf('year') },
      { label: Overview.lbl_filter_1year, filterDate: dayjs(maxDate).subtract(1, 'year') },
      { label: Overview.lbl_filter_all, filterDate: '' },
      { label: Overview.lbl_filter_custom, filterDate: '' },
    ];

    let activeGraphDateFilter = dateFilters[4]; //default to 1 year view
    if (dayjs(minMaxDates.minDate).isAfter(dayjs().subtract(1, 'year'))) { // if less than 1 year data on default account, loop through filters to set apporiate filter
      const setDateFilters = cloneDeep(dateFilters).splice(0, 4); // get only the set filter dates
      setDateFilters.forEach(filter => {
        if (dayjs(minMaxDates.minDate).isBefore(filter.filterDate, 'day')) {
          activeGraphDateFilter = filter;
        }
      });
    }

    // check if warning messages need to be displayed //
    // check that balance history data was pulled correctly; this should only be false in cases of a database connection error.
    const dataAvailable = dashboardAccountList.some(account => account.dataAvailable);

    this.setState({
      activeGraphDateFilter,
      dateFilters,
      dashboardAccountList,
      isBalanceHistoryLoading: false,
      minMaxDates,
      selectedAccounts: defaultSelectedAccount,
      warningType: !dataAvailable ? warningTypes.dataUnavailable : '', // if balance history data is not available, display warning
    }, () => dataAvailable && this.filterByDateRange(this.state.activeGraphDateFilter)); // runs filter to get 1 year default graphData
  }

  componentDidMount() {
    const { balanceHistory, claims, } = this.props;
    if (claims.AccountBlocked === 'True') {
      this.setState({ hasPrecondition: true });
    }
    else if (claims.AccountBlocked === 'False') {
      if (balanceHistory.length > 0) this.formatDashboardAccountList(balanceHistory);
      else this.getBalanceHistory();
    }
  }

  componentDidUpdate() {
    const { balanceHistory, claims, } = this.props;

    if (claims.AccountBlocked === 'False'
      && (claims.TwoFactorAuthentication !== TWO_FACTOR_STATUS.PENDING)
      && this.state.hasPrecondition) {
      this.setState({ hasPrecondition: false }, () => {
        if (balanceHistory.length > 0) this.formatDashboardAccountList(balanceHistory);
        else this.getBalanceHistory();
      });
    }
  }


  render() {
    const { accountList, text: { Sidenav } } = this.props;
    const { dashboardAccountList, isBalanceHistoryLoading, isGraphFullscreen, thirdPartyWarningOn, warningType, } = this.state;

    return (
      <>
        {warningType === warningTypes.noActiveAccounts
          ? this.renderWarningDisplay()
          : (
            <div className={styles.overviewContainer}>
              <div className={styles.balanceOverTimeSection} key='balanceOverTime'>
                {warningType === warningTypes.dataUnavailable ? this.renderWarningDisplay() : !isGraphFullscreen && this.renderBalanceOverTime()}
              </div>
              <div className={styles.balanceByDateSection} key='balanceByDate'>
                <BalanceByDate
                  key={dashboardAccountList.length}
                  dashboardAccountList={isBalanceHistoryLoading ? accountList : dashboardAccountList} // provide accountList, until balance history is loaded
                  isBalanceHistoryLoading={isBalanceHistoryLoading}
                />
              </div>
            </div>
          )}

        {!warningType && isGraphFullscreen &&
          <Modal
            show={isGraphFullscreen}
            title=''
            onCloseModal={() => this.setState({ isGraphFullscreen: false })}
            DialogProps={{ fullScreen: true, maxWidth: false, }}
          >
            <div className={styles.balanceOverTimeFullscreen}>
              {this.renderBalanceOverTime()}
            </div>
          </Modal>}

        <ThirdPartyWarningModal
          open={thirdPartyWarningOn}
          onClose={() => this.setState({ thirdPartyWarningOn: false })}
          eventLabel='Invite Education-Home' // for Google analytics
          thirdPartyURL='https://tools.inviteeducation.com/tools/college_savings_estimator?client_id=209'
          warningLanguage={Sidenav.resources.txt_third_party_warning_for_info}
        />
      </>
    );
  }
}


export default withRouter(connect(null, { getBalanceHistory, })(LanguageHOC(Overview)));
