import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { Modal } from '@frontend/common';

export const OptionChangeDialog = ({ show, onClose, text: { Transfers }, }) => (
  <Modal
    title={Transfers.title_option_change_info}
    show={show}
    onCloseModal={onClose}
    modal={false}
    actionButtons={[
      {
        label: Transfers.btn_ok,
        action: onClose,
      }
    ]}
  >
    {Transfers.text_option_change_info()}
  </Modal>
);

OptionChangeDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.shape({
    Transfers: PropTypes.shape({
      btn_ok: PropTypes.string,
      text_option_change_info: PropTypes.func,
      title_option_change_info: PropTypes.string,
    })
  })
};

export default LanguageHOC(OptionChangeDialog);
