import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';

import {
  ACH_PAYMENT,
  ACCESS_CARD_PAYMENT,
  FULL_BALANCE,
  RECURRING,
  CHECK_PAYMENT,
  ELECTRONIC,
} from '../../../constants';

import {
  currencyFormatter,
} from '@frontend/common';

import {
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import CheckAddress from '../CheckAddress';
import BankInfo from '../BankInfo';

import styles from './styles.module.scss';

Step3TermsAndConditions.propTypes = {
  termsChecked: PropTypes.bool.isRequired,
  withdrawal: PropTypes.shape({
    frequency: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    amountType: PropTypes.string.isRequired,
    leaveAccountOpen: PropTypes.bool.isRequired,
    sellDate: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    withdrawalType: PropTypes.string.isRequired,
    paymentType: PropTypes.string.isRequired,
    paymentDeliveryType: PropTypes.string.isRequired,
    recipient: PropTypes.string.isRequired,
    recipientAddress: PropTypes.object.isRequired,
    paymentAccount: PropTypes.object.isRequired,
  }).isRequired,
  handleTermsChecked: PropTypes.func.isRequired,
  preTransactionDetails: PropTypes.shape({
    agentAddressChanged: PropTypes.bool.isRequired,
    beneficiaryAddressChanged: PropTypes.bool.isRequired,
  }).isRequired,
  withdrawalVariables: PropTypes.shape({
    paymentTypes: PropTypes.array.isRequired,
    withdrawalTypes: PropTypes.array.isRequired,
  }).isRequired,
  text: PropTypes.shape({
    Withdrawals: PropTypes.object.isRequired,
  }).isRequired,
};

const ACH = 'Electronic Funds Transfer';
const ELECTRONIC_SCHOOL = 'Electronic Payment';

export function Step3TermsAndConditions(props) {
  const {
    termsChecked,
    withdrawal: {
      frequency,
      amount,
      amountType,
      leaveAccountOpen,
      sellDate,
      startDate,
      endDate,
      withdrawalType,
      paymentType,
      paymentDeliveryType,
      recipient,
      recipientAddress,
      paymentAccount,
    },
    preTransactionDetails: {
      agentAddressChanged,
      beneficiaryAddressChanged,
    },
    withdrawalVariables: {
      paymentTypes,
      withdrawalTypes
    },
    handleTermsChecked,
    text: { Withdrawals },
  } = props;
  const stepIndex = 3;

  const isRecurring = frequency === RECURRING;

  let amtDisplayMsg = '';
  let method = paymentTypes.find(type => type.id === paymentType).name;
  if (amountType.includes('Partial')) {
    if (recipientAddress.school.IsElectronicSchoolPaymentSchool) {
      if (paymentDeliveryType === ELECTRONIC) {
        amtDisplayMsg = `${currencyFormatter(Number(amount + 3.50))} (${currencyFormatter(amount)} + $3.50 Electronic Payment fee)`;
        method = ELECTRONIC_SCHOOL;
      }
      else {
        amtDisplayMsg = currencyFormatter(amount);
        method = CHECK_PAYMENT;
      }
    }
    else {
      amtDisplayMsg = currencyFormatter(amount);
      if (paymentType === CHECK_PAYMENT) {
        method = CHECK_PAYMENT;
      }
      else {
        method = ACH;
      }
    }
  }
  else {
    amtDisplayMsg = recipientAddress.school.IsElectronicSchoolPaymentSchool && paymentDeliveryType === ELECTRONIC
      ? 'Full Balance (less $3.50 Electronic School fee)'
      : 'Full Balance';
  }

  return [
    <div className={styles.reviewContainer} key='review'>
      <div className={styles.recipient}>
        <div className={styles.recipientInfo}>
          <div className={styles.reviewAmount}>
            <div className={styles.detailsTitle}>Amount</div>
            {amtDisplayMsg}
          </div>

          {amountType === FULL_BALANCE ?
            <div className={styles.reviewAmount}>
              <div className={styles.detailsTitle}>Account Status</div>
              {leaveAccountOpen ? 'Will remain open' : 'Will be closed'}
            </div>
            :
            null
          }

          {isRecurring ?
            <div className={styles.reviewDate}>
              <div className={styles.detailsTitle}>Start Date</div>
              {frequency} on {dayjs(startDate).format('L')}
              {endDate &&
                <div>
                  <div className={styles.detailsTitle}>End Date</div>
                  Ending on {dayjs(endDate).format('L')}
                </div>
              }
            </div> :
            <div className={styles.reviewDate}>
              <div className={styles.detailsTitle}>Sell Date</div>
              {frequency} on {dayjs(sellDate).format('L')}
            </div>
          }

          <div className={styles.reviewType}>
            <div className={styles.detailsTitle}>Withdrawal Type</div>
            {withdrawalTypes.find(type => type.id === withdrawalType).name}
          </div>

          <div className={styles.reviewPayment}>
            <div className={styles.detailsTitle}>Payment Method</div>
            {method}
          </div>
        </div>

        <div className={styles.recipientAddress}>
          {(paymentType === ACH_PAYMENT || paymentType === ACCESS_CARD_PAYMENT) ?
            <div >
              <div className={styles.detailsTitle}>Recipient</div>
              <BankInfo
                recipient={recipient}
                paymentAccount={paymentAccount}
                recipientAddress={recipientAddress}
              />
            </div>
            :
            <div>
              <div className={styles.detailsTitle}>Recipient</div>
              <CheckAddress
                stepIndex={stepIndex}
                recipientAddress={recipientAddress}
                recipient={recipient}
                agentAddressChanged={agentAddressChanged}
                beneficiaryAddressChanged={beneficiaryAddressChanged}
              />
            </div>
          }
        </div>
      </div>
    </div>,
    <div className={styles.termsAndConditions} key='terms&conditions'>
      <h2>{Withdrawals.step3_terms_and_conditions.title}</h2>
      <h3>{Withdrawals.step3_terms_and_conditions.title_help}</h3>

      <h5>{Withdrawals.step3_terms_and_conditions.p1.title}</h5>
      <p>{Withdrawals.step3_terms_and_conditions.p1.text1}</p>
      <p>{Withdrawals.step3_terms_and_conditions.p1.text2}</p>

      <h5>{Withdrawals.step3_terms_and_conditions.p2.title}</h5>
      <p>{Withdrawals.step3_terms_and_conditions.p2.text1}</p>
      <p>{Withdrawals.step3_terms_and_conditions.p2.text2}</p>

      <h5>{Withdrawals.step3_terms_and_conditions.p3.title}</h5>
      <p>{Withdrawals.step3_terms_and_conditions.p3.text1}</p>
      <p>{Withdrawals.step3_terms_and_conditions.p3.text2}</p>

      <h5>{Withdrawals.step3_terms_and_conditions.p4.title}</h5>
      <p>{Withdrawals.step3_terms_and_conditions.p4.text1}</p>
      <p>{Withdrawals.step3_terms_and_conditions.p4.text2}</p>
      <p>{Withdrawals.step3_terms_and_conditions.p4.text3}</p>
    </div>,
    <div
      className={styles.termsAndConditionsCheckbox}
      key='termsAndConditionsCheckbox'
    >
      <FormControlLabel
        label={Withdrawals.step3_terms_and_conditions.agree_btn_label}
        control={
          <Checkbox
            checked={termsChecked}
            onChange={handleTermsChecked}
          />
        }
      />
    </div>
  ];
}

export default LanguageHOC(Step3TermsAndConditions);