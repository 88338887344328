/*
*
* CurrentAllocation Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import AllocationChart from './AllocationChart';
import TransactionButtons from '../../TransactionButtons';

import styles from './styles.module.scss';
import parentStyles from '../styles.module.scss';

export class CurrentAllocation extends React.Component {

  static propTypes = {
    account: PropTypes.shape({
      accountIsClosed: PropTypes.bool,
      interestedPartyId: PropTypes.number,
      moneyAllocations: PropTypes.array,
      totalValueNum: PropTypes.number,
      permissions: PropTypes.shape({
        Contribute: PropTypes.bool,
      }),
    }).isRequired,
    isCustomOption: PropTypes.bool.isRequired,
    size: PropTypes.object.isRequired,
    text: PropTypes.shape({
      CurrentAllocation: PropTypes.shape({
        btn_chart_toggle: PropTypes.string,
        btn_list_toggle: PropTypes.string,
        head_account_closed: PropTypes.string,
        head_no_info: PropTypes.string,
        text_actual_percentage: PropTypes.string,
        text_make_contribution: PropTypes.string,
        text_value: PropTypes.string,
        text_target_percentage: PropTypes.string,
      }),
      Transactions: PropTypes.shape({
        TRANSACTION_TYPES: PropTypes.shape({
          CONTRIBUTION: PropTypes.object,
        }),
      }),
    }).isRequired,
  };

  state = {
    activeIndex: 0,
    isInterestedParty: Boolean(this.props.account.interestedPartyId), // id is only populated for an Interested Party account
    accountHasFunds: this.props.account.totalValueNum > 0,
  };

  onPieEnter = (data, index) => {
    if (index !== this.state.activeIndex) {
      this.setState({ activeIndex: index });
    }
  }

  tooltipCompose = () => {
    const { isCustomOption } = this.props;
    const allocation = this.props.account.moneyAllocations[this.state.activeIndex];

    return (
      <div className={styles.tooltipsContainer}>
        {allocation.allocationDetails.map(details => (
          <div className={styles.tooltipContainer} key={details.id}>
            <div className={styles.categoryName}>{details.Category}</div>
            <div className={styles.tooltip}>
              {isCustomOption // custom should be Actual %, non-custom should be Target %
                ? <div className={styles.header}>{this.props.text.CurrentAllocation.text_actual_percentage}</div>
                : <div className={styles.header}>{this.props.text.CurrentAllocation.text_target_percentage}</div>
              }
              <div className={styles.data}>{details.ActualPercentage}</div>
            </div>
            {isCustomOption &&
            <div className={styles.tooltip}>
              <div className={styles.header}>{this.props.text.CurrentAllocation.text_value}</div>
              <div className={styles.data}>{details.Value}</div>
            </div>}
          </div>
        ))}
      </div>
    );
  }

  chartCompose = () => {
    const { account, size, } = this.props;
    const { activeIndex, isInterestedParty } = this.state;

    return (
      <>
        <AllocationChart 
          key={size.windowWidth} // this is needed or the chart does not update properly on screen resizing
          allocations={account.moneyAllocations}
          activeIndex={activeIndex}
          pieSelection={this.onPieEnter}
          isInterestedParty={isInterestedParty}
        />
        {this.tooltipCompose()}
      </>
    );
  }
  
  noFundsViewCompose = () => {
    const { 
      account,
      account: { accountIsClosed, 
        permissions: { Contribute } }, 
      text: { CurrentAllocation, Transactions }, 
    } = this.props;

    return (
      <div className={styles.noFundsViewContainer}>
        {accountIsClosed ? (
          <div className={styles.closedAccountView}>
            <div className={styles.closedHeader}>{CurrentAllocation.head_account_closed}</div>
          </div>
        ) : (
          <div className={styles.noFundsView}>
            <div className={styles.noFundsHeader}>{CurrentAllocation.head_no_info}</div>
            <div className={styles.makeContributionText}>{CurrentAllocation.text_make_contribution}</div>
            {Contribute &&
              <TransactionButtons
                account={account}
                callToActionType={Transactions.TRANSACTION_TYPES.CONTRIBUTION.type}
                isCallToAction={true}
                size={{ windowWidth: 0 }}
              />
            } 
          </div>
        )}
      </div>
    );
  }


  render() {
    const { accountHasFunds } = this.state;

    return (
      <div className={parentStyles.currentAllocation}>
        {accountHasFunds ? this.chartCompose() : this.noFundsViewCompose()} 
      </div>
    );
  }
}


export default (LanguageHOC(CurrentAllocation));
