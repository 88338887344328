/*
*
* FormWrapper Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';

import {
  My529Logo,
  Dropdown,
} from '@frontend/common';

import { setGetRegistrationType } from '../actions';

import styles from './styles.module.scss';

import { REGISTRATION_TYPES } from '../constants';

const select = (state) => ({
  registrationType: state.registrationtype.registrationType,
  documents: state.static.documents,
});

export class FormWrapper extends React.Component {
  static propTypes = {
    setGetRegistrationType: PropTypes.func.isRequired,
    registrationType: PropTypes.string.isRequired,
    children: PropTypes.node,
    documents: PropTypes.shape({
      form102: PropTypes.shape({ Location: PropTypes.string }),
      form104: PropTypes.shape({ Location: PropTypes.string }),
      form500: PropTypes.shape({ Location: PropTypes.string }),
    }).isRequired,
    text: PropTypes.shape({
      Register: PropTypes.shape({
        dropdown_label: PropTypes.string,
        head_other_account: PropTypes.string,
        head_other_account_by_form: PropTypes.string,
        msg_recaptcha_language: PropTypes.string,
        text_about_form104: PropTypes.string,
        text_about_institutional_account: PropTypes.string,
        text_about_intl_number: PropTypes.string,
        text_about_other_account: PropTypes.object,
        text_about_ugma_utma_account: PropTypes.string,
        text_form104: PropTypes.string,
        text_form500: PropTypes.string,
        text_institutional_account: PropTypes.string,
        text_intl_number: PropTypes.string,
        text_ugma_utma_account: PropTypes.string,
        nav_path: PropTypes.string,
        text_i_am_new_to_my529: PropTypes.string,
        text_i_have_accounts_with_529: PropTypes.string,
        text_account_types_requirement: PropTypes.string,
      }),
      SetupUser: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
    }),
  };

  dropdownOptions = [
    {
      value: REGISTRATION_TYPES.I_AM_NEW_TO_MY529,
      display: this.props.text.Register.text_i_am_new_to_my529,
    },
    {
      value: REGISTRATION_TYPES.I_HAVE_ACCOUNTS_WITH_MY529,
      display: this.props.text.Register.text_i_have_accounts_with_529,
    },
  ];

  onChangeHandle = (registrationType) => {
    this.props.setGetRegistrationType(registrationType);

    if (registrationType === REGISTRATION_TYPES.I_HAVE_ACCOUNTS_WITH_MY529)
      this.props.history.push(this.props.text.SetupUser.nav_path);
    else
      this.props.history.push(this.props.text.Register.nav_path);
  }

  render() {
    const {
      children,
      documents,
      text: { Register },
      registrationType
    } = this.props;

    return (
      <div className={styles.container}>
        <div>
          <div className={styles.body}>
            <div className={styles.headerContainer}>
              <a
                className={styles.logo}
                href='https://www.my529.org/'
                target='_self'
                rel='noopener noreferrer'
              >
                <My529Logo />
              </a>
              <div>
                <h2>{Register.head_other_account}</h2>

                <p>{Register.text_about_other_account}</p>
              </div>
            </div>

            <div style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>
              <Dropdown
                labelId={'registrationTypeId'}
                label={Register.dropdown_label}
                value={registrationType}
                onChange={this.onChangeHandle}
                options={this.dropdownOptions}
                FormControlProps={{ style: { width: '40%', minWidth: '300px', textAlign: 'left' } }}
              />
            </div>

            <div className={styles.formContainer}>
              {children}
            </div>

            <div className={styles.otherInfo}>
              <h2>{Register.head_other_account_by_form}</h2>

              <p>{Register.text_account_types_requirement}</p>
              <p><strong>{Register.text_institutional_account}</strong> - {Register.text_about_institutional_account}</p>
              <a href={documents.form102.Location} target='_blank' rel='noopener noreferrer'>{Register.text_form104}</a>

              <p><strong>{Register.text_ugma_utma_account}</strong> - {Register.text_about_ugma_utma_account}</p>
              <a href={documents.form104.Location} target='_blank' rel='noopener noreferrer'>{Register.text_about_form104}</a>

              <h2>{Register.text_intl_number}</h2>
              <p>{Register.text_about_intl_number}</p>
              <a href={documents.form500.Location} target='_blank' rel='noopener noreferrer'>{Register.text_form500}</a>
            </div>


            <div className={styles.recaptcha}> {/* This reCAPTCHA text is required since we are hiding the badge on all pages */}
              <p>{parse(Register.msg_recaptcha_language)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default withRouter(connect(select, {
  setGetRegistrationType
})(LanguageHOC(FormWrapper)));