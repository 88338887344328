/*
*
* Payments Constants
*
*/
export const CHECK_ROUTING_NUMBER = 'src/components/Features/protected/ManageBankAccounts/CHECK_ROUTING_NUMBER';
export const GET_BANK_ACCOUNTS = 'src/components/Features/protected/ManageBankAccounts/GET_BANK_ACCOUNTS';
export const GET_AGENT_BANK_ACCOUNTS = 'src/components/Features/protected/ManageBankAccounts/GET_AGENT_BANK_ACCOUNTS';
export const GET_GROUPED_BANK_ACCOUNTS = 'src/components/Features/protected/ManageBankAccounts/GET_GROUPED_BANK_ACCOUNTS';
export const GET_ALLOWED_BANK_HOLDERS = 'src/components/Features/protected/ManageBankAccounts/GET_ALLOWED_BANK_HOLDERS';
export const DELETE_BANK_ACCOUNT = 'src/components/Features/protected/ManageBankAccounts/DELETE_BANK_ACCOUNT';
export const RENEW_BANK_ACCOUNT = 'src/components/Features/protected/ManageBankAccounts/RENEW_BANK_ACCOUNT';
export const CREATE_AGENT_BANK_ACCOUNT = 'src/components/Features/protected/ManageBankAccounts/CREATE_AGENT_BANK_ACCOUNT';
export const CREATE_BENEFICIARY_BANK_ACCOUNT = 'src/components/Features/protected/ManageBankAccounts/CREATE_BENEFICIARY_BANK_ACCOUNT';
export const POA_APPROVAL = 'src/components/Features/protected/ManageBankAccounts/POA_APPROVAL';
export const START_MIRCO_DEPOSITS = 'src/components/Features/protected/ManageBankAccounts/START_MIRCO_DEPOSITS';
export const CONFIRM_MIRCO_DEPOSITS = 'src/components/Features/protected/ManageBankAccounts/CONFIRM_MIRCO_DEPOSITS';
export const SAVE_POA_BANK_SELECTION = 'src/components/Features/protected/ManageBankAccounts/SAVE_POA_BANK_SELECTION';
export const GET_ACCESS_CARDS = 'src/components/Features/protected/ManageBankAccounts/GET_ACCESS_CARDS';
export const UPDATE_NICKNAME = 'src/components/Features/protected/ManageBankAccounts/UPDATE_NICKNAME';
export const GET_ACCESS_CARD_URL = 'src/components/Features/protected/ManageBankAccounts/GET_ACCESS_CARD_URL';
