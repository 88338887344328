/*
 *
 * Step1Bank Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Button,
  Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  Dropdown,
  LoadingOverlay,
} from '@frontend/common';

import styles from '../styles.module.scss';

const muiStyles = () => ({
  root: {
    whiteSpace: 'normal',
    wordBreak: 'normal',
    overflowWrap: 'anywhere'
  }
});

Step1Bank.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  agentBankAccounts: PropTypes.array.isRequired,
  bankAccountsLoading: PropTypes.bool.isRequired,
  toggleShowAddBank: PropTypes.func.isRequired,
  bankAccountSelectHandle: PropTypes.func.isRequired,
  contribution: PropTypes.object.isRequired,
  errorGet: PropTypes.func.isRequired,
  text: PropTypes.shape({
    ContributionSpecialStep1: PropTypes.shape({
      btn_add_bank: PropTypes.string,
      lbl_bank_account: PropTypes.string,
      lbl_add_bank: PropTypes.string,
    })
  })
};

export function Step1Bank({
  classes,
  agentBankAccounts,
  bankAccountsLoading,
  toggleShowAddBank,
  contribution: { bankAccount },
  errorGet,
  bankAccountSelectHandle,
  text: { ContributionSpecialStep1 },
}) {
  const agentAccountsFormatted = agentBankAccounts.map(account => {
    if (account.Nickname && account.Nickname.length > 0) {
      return {
        value: account.BankAccountId,
        display: `${account.Nickname}, ${account.MaskedBankAccountNumber}`
      };
    }
    else {
      return {
        value: account.BankAccountId,
        display: account.BankAccountId === 0 ? account.BankName : `${account.BankName}, ${account.MaskedBankAccountNumber}`
      };
    }
  });
  return (
    <div>
      {agentBankAccounts.length > 0 ?
        <div className={styles.bankAccount}>
          <LoadingOverlay show={bankAccountsLoading}>
            <Dropdown
              label={ContributionSpecialStep1.lbl_bank_account}
              value={bankAccount.BankAccountId}
              errorText={errorGet('bankAccount')}
              onChange={bankAccountSelectHandle}
              options={agentAccountsFormatted}
              FormControlProps={{ style: { width: '256px' } }}
              MenuItemProps={{
                classes: { root: classes.root }
              }}
            />
          </LoadingOverlay>
          <div className={styles.bankAccount}>
            <LoadingOverlay show={bankAccountsLoading}>
              <Button
                color='secondary'
                startIcon={<Icon>add</Icon>}
                onClick={toggleShowAddBank}
              >
                {ContributionSpecialStep1.btn_add_bank}
              </Button>
            </LoadingOverlay>
          </div>
        </div>
        :
        <div className={styles.bankAccount}>
          <Button onClick={toggleShowAddBank} variant='contained'>
            {ContributionSpecialStep1.btn_add_bank}
          </Button>
        </div>
      }
    </div>
  );
}

export default (LanguageHOC(withStyles(muiStyles)(Step1Bank)));