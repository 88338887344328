/*
*
* TransactionsHub Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';

import UpcomingSchedules from './UpcomingSchedules';
import TransactionHistory from './TransactionHistory';
import TransactionButtons from './TransactionButtons';

import styles from './styles.module.scss';

const select = (/*state*/) => ({

});

export class TransactionsHub extends React.Component {
  static propTypes = {
    text: PropTypes.shape({
      Transactions: PropTypes.object.isRequired,
    }),
  };

  render() {
    return (
      <div className={styles.container}>
        <TransactionButtons />
        <UpcomingSchedules />
        <TransactionHistory />
      </div>
    );
  }
}


export default connect(select, {})(LanguageHOC(TransactionsHub));
