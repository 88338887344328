/*
*
* InvestmentOptionStep Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import parse from 'html-react-parser';
import {
  Button,
  Drawer,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Icon,
  Paper,
  Radio,
  RadioGroup,
} from '@mui/material';
import { withStyles, } from '@mui/styles';
import {
  allNotificationsHide,
  IconBtnTooltip,
  InfoIcon,
  LoadingOverlay,
  Modal,
  notificationShow,
  CalculatorForm
} from '@frontend/common';
import {
  getNewAgeBasedSchema,
  getNewStaticSchema,
} from 'components/Features/protected/Accounts/Transactions/OptionChanges/actions.js';
import { getNotifications, } from 'components/AppRoot/Navigation/actions';
import { saveCustomizedOption, savePredefinedInvestmentOption } from '../actions';
import { mobileWidth, tabletWidth } from 'utils/config/_sassconfig.scss';

import styles from './styles.module.scss';


const muiStyles = () => ({
  expansionPanelBody: {
    background: '#F8F8F8',
    boxShadow: 'none',
    marginBottom: '0px',
  },
  expansionPanelSummaryContent: {
    margin: '20px 0px 0px 0px',
  },
  expansionPanelSummaryRoot: {
    margin: '0px',
  },
  expansionPanelDetails: {
    paddingTop: '0px'
  }
});

const select = (state) => ({
  beneficiaryInfo: state.signup.beneficiaryInfo,
  customAgeBasedOption: state.optionChanges.predefinedOptions ? state.optionChanges.predefinedOptions.find(option => option.OptionId === 14) : {},
  customStaticOption: state.optionChanges.predefinedOptions ? state.optionChanges.predefinedOptions.find(option => option.OptionId === 12) : {},
  investmentInfo: state.signup.investmentInfo,
  newAgeBasedSchema: state.optionChanges.newAgeBasedSchema,
  newStaticSchema: state.optionChanges.newStaticSchema,
  predefinedEnrollmentDateOptions: state.optionChanges.predefinedOptions ? state.optionChanges.predefinedOptions.filter(option => option.isEnrollmentDate) : [],
  predefinedStaticOptions: state.optionChanges.predefinedOptions ? state.optionChanges.predefinedOptions.filter(option => option.isStatic) : [],
  programDescription: state.static.documents.programDescription,
  selectedExistingBene: state.signup.existingBenesList.find(bene => bene.BeneficiaryId === state.signup.beneficiaryInfo.BeneficiaryId) || {},
  ServerDateTime: state.static.ServerDateTime,
});


export class InvestmentOptionStep extends React.Component {
  static propTypes = {
    getNotifications: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    beneficiaryInfo: PropTypes.shape({ BeneficiaryId: PropTypes.number }).isRequired,
    classes: PropTypes.object.isRequired,
    customAgeBasedOption: PropTypes.object.isRequired,
    customStaticOption: PropTypes.object.isRequired,
    existingBenesList: PropTypes.array.isRequired,
    getNewAgeBasedSchema: PropTypes.func.isRequired,
    getNewStaticSchema: PropTypes.func.isRequired,
    investmentInfo: PropTypes.shape({
      InvestmentOptionId: PropTypes.number,
      Template: PropTypes.object
    }).isRequired,
    newAgeBasedSchema: PropTypes.object.isRequired,
    newStaticSchema: PropTypes.object.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onBackClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    predefinedEnrollmentDateOptions: PropTypes.array.isRequired,
    predefinedStaticOptions: PropTypes.array.isRequired,
    programDescription: PropTypes.string.isRequired,
    saveCustomizedOption: PropTypes.func.isRequired,
    savePredefinedInvestmentOption: PropTypes.func.isRequired,
    selectedExistingBene: PropTypes.object.isRequired,
    setLoadingOff: PropTypes.func.isRequired,
    setLoadingOn: PropTypes.func.isRequired,
    ServerDateTime: PropTypes.string,
    text: PropTypes.shape({
      InvestmentOptionStep: PropTypes.shape({
        btn_back: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_continue: PropTypes.string,
        btn_create_ageBased: PropTypes.string,
        btn_create_static: PropTypes.string,
        btn_details: PropTypes.string,
        btn_edit_ageBased: PropTypes.string,
        btn_edit_static: PropTypes.string,
        btn_ok: PropTypes.string,
        btn_save: PropTypes.string,
        err_must_select_opt: PropTypes.string,
        head_enrollmentdate_opts: PropTypes.string,
        head_customized_opts: PropTypes.string,
        head_investment_opt_disclosures: PropTypes.string,
        head_investment_option: PropTypes.string,
        head_static_opts: PropTypes.string,
        msg_cannot_select_opt: PropTypes.string,
        msg_investment_opt_saved: PropTypes.string,
        msg_total_allocations_ageBased: PropTypes.string,
        msg_total_allocations_static: PropTypes.string,
        text_about_investment_opts: PropTypes.string,
        text_enrollmentdate_details1: PropTypes.string,
        text_enrollmentdate_details2: PropTypes.string,
        text_enrollmentdate_details3: PropTypes.string,
        text_enrollmentdate_summary: PropTypes.string,
        text_customized_details1: PropTypes.string,
        text_customized_details2: PropTypes.string,
        text_customized_review_performance: PropTypes.string,
        text_customized_summary: PropTypes.string,
        text_disclosures: PropTypes.func,
        text_options_on_mobile: PropTypes.string,
        text_static_details1: PropTypes.string,
        text_static_details2: PropTypes.string,
        text_static_details3: PropTypes.string,
        text_static_summary: PropTypes.string,
        title_options_on_mobile: PropTypes.string,
      }),
    }).isRequired,
    welcomeModalOpen: PropTypes.bool.isRequired,
  };

  state = {
    enrollmentDateExpanded: false,
    customizedExpanded: false,
    customizedIsSaving: false,
    formErrors: {},
    investmentOptionValue: this.props.investmentInfo.InvestmentOptionId,
    showCustomizedAgeBased: false,
    showCustomizedStatic: false,
    showDisclosures: false,
    staticExpanded: false,
    template: {
      TemplateName: '',
      FundCategories: []
    },
    calculatorErrors: {},
    matrixAllocations: [],
    showModalWhenMobileAndCustomized: false,
  };

  closeCustomized = () => {
    this.setState({
      showCustomizedAgeBased: false,
      showCustomizedStatic: false,
    }, () => this.setCustomized()); // calling this will reset template

    this.props.allNotificationsHide();
  }

  continueHandle = e => {
    e.preventDefault();
    if (!this.state.investmentOptionValue && this.state.template.FundCategories.length > 0) {
      this.saveCustomized(e);
    }
    else {
      this.savePredefined(e);
    }
  }

  onAllocationChange = (matrixAllocations, calculatorErrors) => {
    this.setState({ matrixAllocations, calculatorErrors });
    const { hasAllocationAmountError, hasBracketTotalSumOver100Error } = calculatorErrors;
    // throw a toast on specific form errors
    if (hasAllocationAmountError || hasBracketTotalSumOver100Error) {
      hasBracketTotalSumOver100Error && this.props.notificationShow(hasBracketTotalSumOver100Error, 'error');
      hasAllocationAmountError && this.props.notificationShow(hasAllocationAmountError, 'error');
    }
    else {
      this.props.allNotificationsHide();
    }
  }

  customizedValidate = () => {
    const {
      calculatorErrors: {
        hasAnyBracketTotalNot100Error,
        hasAllocationAmountError,
        hasBracketTotalSumOver100Error
      }
    } = this.state;

    const errorMessages = [];
    hasAnyBracketTotalNot100Error && errorMessages.push(hasAnyBracketTotalNot100Error);
    hasBracketTotalSumOver100Error && errorMessages.push(hasBracketTotalSumOver100Error);
    hasAllocationAmountError && errorMessages.push(hasAllocationAmountError);

    if (errorMessages.length > 0) {
      errorMessages.forEach(message => {
        this.props.notificationShow(message, 'error');
      });
    }

    return errorMessages.length === 0; // returns true (is valid) if no error messages
  }

  expandSelection = () => {
    const { investmentInfo: { InvestmentOptionId, Template }, predefinedEnrollmentDateOptions, predefinedStaticOptions } = this.props;

    // expands panel where option is selected
    if (InvestmentOptionId && predefinedEnrollmentDateOptions.find(opt => opt.OptionId === InvestmentOptionId)) {
      this.setState({ enrollmentDateExpanded: true });
    }
    else if (InvestmentOptionId && predefinedStaticOptions.find(opt => opt.OptionId === InvestmentOptionId)) {
      this.setState({ staticExpanded: true });
    }
    else if (Template) {
      this.setState({ customizedExpanded: true });
    }
    // else, leave it as initial state (all collapsed)
  }

  formValidate = () => {
    const formErrors = {};
    if (!this.state.investmentOptionValue && !this.props.investmentInfo.Template) {
      formErrors.investmentOptionValue = this.props.text.InvestmentOptionStep.err_must_select_opt;
    }

    this.setState({ formErrors });
    return Object.values(formErrors).every(error => formErrors[error] === '');
  }

  openCustomized = templateType => {
    if (window.innerWidth <= parseInt(mobileWidth)) {
      this.setState({ showModalWhenMobileAndCustomized: true });
    }
    else {
      this.setState({
        [templateType]: true
      }, () => this.setCustomized());
    }
  }

  optionIsDisabled = option => { // disable options that an existing bene already has accounts for
    const { beneficiaryInfo, selectedExistingBene } = this.props;


    const options = selectedExistingBene.Options || [];

    if (beneficiaryInfo.BeneficiaryId) { // determines if an existing beneficiary is selected
      if (option === 'age-based') {
        return options.some(opt => opt.IsCustomAgeBased); // returns true if any existing option is age-based
      }
      else if (option === 'static') {
        return options.some(opt => opt.IsCustomStatic); // returns true if any existing option is static
      }
      return options.some(opt => opt.OptionId === option.OptionId); // returns true if any existing option equals the option ID being passed in
    }
    return false; // else, return false so that options aren't disabled
  }

  saveCustomized = e => {
    e.preventDefault();
    const { showCustomizedAgeBased, showCustomizedStatic } = this.state;
    let template;

    if (!showCustomizedAgeBased && !showCustomizedStatic && this.props.investmentInfo.Template) { // if user has not created a new customized template, nor selected an investment option, use customized template in props
      template = this.props.investmentInfo.Template;
    }
    else {
      template = { ...this.state.template };
    }

    if (this.customizedValidate()) {
      this.setState({ customizedIsSaving: true }); // need to use loading from state instead of setLoadingOn() because the bottom sheet hides the loader
      this.props.allNotificationsHide();
      this.props.saveCustomizedOption(this.state.matrixAllocations, template)
        .then(() => {
          this.props.notificationShow('Customized option created.', 'success');
          this.props.getNotifications(); // refreshes app notifications
          this.props.onNextClick();
          this.setState({ customizedIsSaving: false });
        })
        .catch(() => this.setState({ customizedIsSaving: false }));
    }
  }

  savePredefined = e => {
    e.preventDefault();

    if (this.formValidate()) {
      this.props.setLoadingOn();

      this.props.savePredefinedInvestmentOption(this.state.investmentOptionValue)
        .then(() => {
          this.props.notificationShow(this.props.text.InvestmentOptionStep.msg_investment_opt_saved, 'success');
          this.props.getNotifications(); // refreshes app notifications
          this.props.onNextClick();
          this.props.setLoadingOff();
        })
        .catch(() => this.props.setLoadingOff());
    }
  }

  setOptionId = e => {
    this.setState({
      formErrors: {
        ...this.state.formErrors,
        investmentOptionValue: ''
      },
      investmentOptionValue: parseInt(e.target.value),
    });
  }

  setCustomized = () => { // sets template type based on what button was clicked to open it, and then resets template back to original if no changes were saved
    const { investmentInfo: { Template }, newAgeBasedSchema, newStaticSchema } = this.props;
    const { showCustomizedAgeBased, showCustomizedStatic } = this.state;

    if (!showCustomizedAgeBased && !showCustomizedStatic && Template) { // if user is trying to proceed through this step without having anything open, but already has a template saved in the store
      this.setState({
        template: Template, // checks if user has previously completed age-based calc and sets that for editing
      });
    }

    else if (showCustomizedAgeBased) { // if we are showing age-based calc
      this.setState({
        template: Template && Template.TemplateType === 'A' ? Template : newAgeBasedSchema, // checks if user has previously completed age-based calc and sets that for editing
      });
    }
    else {
      this.setState({
        template: Template && Template.TemplateType === 'S' ? Template : newStaticSchema, // checks if user has previously completed static calc and sets that for editing
      });
    }
  }

  componentDidMount() {
    this.setState({ showDisclosures: true });

    this.expandSelection();

    // gets new templates if not already in store
    const promises = [];
    if (this.props.newAgeBasedSchema.FundCategories.length === 0)
      promises.push(this.props.getNewAgeBasedSchema());

    if (this.props.newStaticSchema.FundCategories.length === 0)
      promises.push(this.props.getNewStaticSchema());

    if (promises.length > 0)
      Promise.all(promises);
  }

  render() {
    const {
      enrollmentDateExpanded, customizedExpanded, customizedIsSaving, formErrors,
      investmentOptionValue, showCustomizedAgeBased, showCustomizedStatic, showDisclosures,
      staticExpanded, template
    } = this.state;
    const {
      classes, customAgeBasedOption, customStaticOption, investmentInfo: { Template },
      onBackClick, predefinedEnrollmentDateOptions, predefinedStaticOptions, programDescription,
      text: { InvestmentOptionStep }, welcomeModalOpen, ServerDateTime
    } = this.props;

    const saveAndCloseCustomizedButtons = (
      <div className={styles.customizedButtons}>
        <Button
          onClick={this.closeCustomized}
          variant='text'
          color='primary'
        >
          {InvestmentOptionStep.btn_cancel}
        </Button>
        <LoadingOverlay show={customizedIsSaving}>
          <Button
            onClick={this.saveCustomized}
            variant='contained'
          >
            {InvestmentOptionStep.btn_save}
          </Button>
        </LoadingOverlay>
      </div>
    );

    return (
      <React.Fragment>
        <Paper>
          <div className={styles.container}>
            <div className={styles.generalInfo}>
              <h1>{InvestmentOptionStep.head_investment_option}</h1>
              <p>{InvestmentOptionStep.text_about_investment_opts}</p>
            </div>

            <form className={styles.form}>
              <Accordion
                classes={{ root: classes.expansionPanelBody }}
                expanded={enrollmentDateExpanded}
                onChange={() => this.setState({ enrollmentDateExpanded: !enrollmentDateExpanded })}
              >
                <AccordionSummary
                  aria-controls='Age-Based Investment Options'
                  classes={{ content: classes.expansionPanelSummaryContent, root: classes.expansionPanelSummaryRoot }}
                  expandIcon={<Icon>expand_more</Icon>}
                  id='age-based-investment-options'
                >
                  <div>
                    <h2>{InvestmentOptionStep.head_enrollmentdate_opts}</h2>
                    <p className={styles.expansionSummaryText}>{InvestmentOptionStep.text_enrollmentdate_summary}</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  classes={{ root: classes.expansionPanelDetails }}
                >
                  <div>
                    <div className={styles.expandedDetails}>
                      <p>{InvestmentOptionStep.text_enrollmentdate_details1}</p>
                      <p>{InvestmentOptionStep.text_enrollmentdate_details2}</p>
                      <div className={styles.enrollmentPerformanceAndDetails}>
                        <p style={{ margin: 0 }}>
                          <a href='https://my529.org/performance-returns' target='_blank' rel='noopener noreferrer'>{InvestmentOptionStep.text_enrollmentdate_details3}</a>
                        </p> {/* TODO use links from API when it becomes available */}
                        {window.innerWidth <= parseInt(tabletWidth) ? // shows icon on tablet and smaller, shows button text on desktop
                          <IconBtnTooltip
                            icon='launch'
                            onClick={() => window.open(predefinedEnrollmentDateOptions[0].OptionOnlineLink, '_blank', 'noopener noreferrer')}
                            title={InvestmentOptionStep.btn_details}
                          />
                          :
                          <Button color='primary' onClick={() => window.open(predefinedEnrollmentDateOptions[0].OptionOnlineLink, '_blank', 'noopener noreferrer')}>
                            {InvestmentOptionStep.btn_details}
                          </Button>}
                      </div>
                    </div>
                    <FormControl component='fieldset' style={{ width: '100%' }}>
                      <RadioGroup
                        aria-label={InvestmentOptionStep.head_enrollmentdate_opts}
                        name='age-based options'
                        value={investmentOptionValue || 0}
                        onChange={e => this.setOptionId(e)}
                      >
                        {predefinedEnrollmentDateOptions.map(option => (
                          <div className={styles.radioButtonContainer} key={option.OptionId}>
                            <div className={styles.radioLabelContainer}>
                              <FormControlLabel
                                control={<Radio color='primary' disabled={this.optionIsDisabled(option)} />}
                                label={
                                  <div className={styles.radioLabel}>
                                    <div>{option.Name}</div>
                                    {option.OptionOnlineDescription
                                      && <InfoIcon message={this.optionIsDisabled(option) ? InvestmentOptionStep.msg_cannot_select_opt : option.OptionOnlineDescription} />}
                                  </div>
                                }
                                labelPlacement='end'
                                value={option.OptionId}
                              />
                            </div>
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                classes={{ root: classes.expansionPanelBody }}
                expanded={staticExpanded}
                onChange={() => this.setState({ staticExpanded: !staticExpanded })}
              >
                <AccordionSummary
                  aria-controls='Static Investment Options'
                  classes={{ content: classes.expansionPanelSummaryContent, root: classes.expansionPanelSummaryRoot }}
                  expandIcon={<Icon>expand_more</Icon>}
                >
                  <div>
                    <h2>{InvestmentOptionStep.head_static_opts}</h2>
                    <p>{InvestmentOptionStep.text_static_summary}</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <div className={styles.expandedDetails}>
                      <p>{InvestmentOptionStep.text_static_details1}</p>
                      <p>{InvestmentOptionStep.text_static_details2}</p>
                      <p><a href='https://my529.org/performance-returns' target='_blank' rel='noopener noreferrer'>{InvestmentOptionStep.text_static_details3}</a></p> {/* TODO use links from API when it becomes available */}
                    </div>
                    <FormControl component='fieldset' style={{ width: '100%' }}>
                      <RadioGroup
                        aria-label={InvestmentOptionStep.head_static_opts}
                        name='static options'
                        value={investmentOptionValue || 0}
                        onChange={e => this.setOptionId(e)}
                      >
                        {predefinedStaticOptions.map(option => (
                          <div className={styles.radioButtonContainer} key={option.OptionId}>
                            <div className={styles.radioLabelContainer}>
                              <FormControlLabel
                                control={<Radio color='primary' disabled={this.optionIsDisabled(option)} />}
                                label={
                                  <div className={styles.radioLabel}>
                                    <div>{option.Name}</div>
                                    {option.OptionOnlineDescription
                                      && <InfoIcon message={this.optionIsDisabled(option) ? InvestmentOptionStep.msg_cannot_select_opt : parse(option.OptionOnlineDescription)} />}
                                  </div>
                                }
                                labelPlacement='end'
                                value={option.OptionId}
                              />
                            </div>
                            {window.innerWidth <= parseInt(tabletWidth) ? // shows icon on tablet and smaller, shows button text on desktop
                              <IconBtnTooltip
                                icon='launch'
                                onClick={() => window.open(option.OptionOnlineLink, '_blank', 'noopener noreferrer')}
                                title={InvestmentOptionStep.btn_details}
                              />
                              :
                              <Button color='primary' onClick={() => window.open(option.OptionOnlineLink, '_blank', 'noopener noreferrer')}>
                                {InvestmentOptionStep.btn_details}
                              </Button>
                            }
                          </div>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                classes={{ root: classes.expansionPanelBody }}
                expanded={customizedExpanded}
                onChange={() => this.setState({ customizedExpanded: !customizedExpanded })}
              >
                <AccordionSummary
                  aria-controls={InvestmentOptionStep.head_customized_opts}
                  classes={{ content: classes.expansionPanelSummaryContent, root: classes.expansionPanelSummaryRoot }}
                  expandIcon={<Icon>expand_more</Icon>}
                >
                  <div>
                    <h2>{InvestmentOptionStep.head_customized_opts}</h2>
                    <p>{InvestmentOptionStep.text_customized_summary}</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.expandedDetails}>
                    <p>{InvestmentOptionStep.text_customized_details1}</p>
                    <p>{InvestmentOptionStep.text_customized_details2}</p>
                    <p><a href='https://my529.org/performance-returns' target='_blank' rel='noopener noreferrer'>{InvestmentOptionStep.text_customized_review_performance}</a></p> {/* TODO use links from API when it becomes available */}
                    <div className={styles.createCustomizedButtons}>
                      <div className={styles.customizedBtnInfo}>
                        <Button
                          color='primary'
                          disabled={this.optionIsDisabled('age-based')}
                          onClick={() => this.openCustomized('showCustomizedAgeBased')}
                          variant='text'
                        >
                          {Template && Template.TemplateType === 'A' ? InvestmentOptionStep.btn_edit_ageBased : InvestmentOptionStep.btn_create_ageBased}
                        </Button>
                        {customAgeBasedOption.OptionOnlineDescription
                          && <InfoIcon message={this.optionIsDisabled('age-based') ? InvestmentOptionStep.msg_cannot_select_opt : customAgeBasedOption.OptionOnlineDescription} />}
                        <IconBtnTooltip
                          icon='launch'
                          onClick={() => window.open(customAgeBasedOption.OptionOnlineLink, '_blank', 'noopener noreferrer')}
                          title={InvestmentOptionStep.btn_details}
                        />
                      </div>
                      <div className={styles.customizedBtnInfo}>
                        <Button
                          color='primary'
                          disabled={this.optionIsDisabled('static')}
                          onClick={() => this.openCustomized('showCustomizedStatic')}
                          variant='text'
                        >
                          {Template && Template.TemplateType === 'S' ? InvestmentOptionStep.btn_edit_static : InvestmentOptionStep.btn_create_static}
                        </Button>
                        {customStaticOption.OptionOnlineDescription
                          && <InfoIcon message={this.optionIsDisabled('static') ? InvestmentOptionStep.msg_cannot_select_opt : customStaticOption.OptionOnlineDescription} />}
                        <IconBtnTooltip
                          icon='launch'
                          onClick={() => window.open(customStaticOption.OptionOnlineLink, '_blank', 'noopener noreferrer')}
                          title={InvestmentOptionStep.btn_details}
                        />
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              {Boolean(formErrors.investmentOptionValue) && <div className={styles.formError}>{formErrors.investmentOptionValue}</div>}
              <div className={styles.stepperButtons}>
                <Button
                  onClick={onBackClick}
                  variant='text'
                  color='primary'
                >
                  {InvestmentOptionStep.btn_back}
                </Button>
                <Button
                  color='primary'
                  onClick={this.continueHandle} // if a predefined opt is selected, calls savePredefined() method, otherwise just continues to next step b/c validation for template is handled in <CustomizedOption />
                  variant='contained'
                  type='submit'
                >
                  {InvestmentOptionStep.btn_continue}
                </Button>
              </div>
            </form>
          </div>
        </Paper>

        <Drawer anchor='bottom' open={showCustomizedAgeBased || showCustomizedStatic}>
          <div className={styles.customizedOptionsContainer}>
            {saveAndCloseCustomizedButtons}
            <CalculatorForm
              loading={customizedIsSaving}
              stickyHeaderTopOffset={0}
              schema={template}
              onAllocationChange={this.onAllocationChange}
              currentDate={ServerDateTime}
              hideTemplateName={true}
            />
            {saveAndCloseCustomizedButtons}
          </div>
        </Drawer>
        <Modal
          title={InvestmentOptionStep.head_investment_opt_disclosures}
          show={showDisclosures}
          maxWidth='md'
          onCloseModal={() => this.setState({ showDisclosures: false })}
          DialogProps={{ style: welcomeModalOpen ? { zIndex: 1 } : {} }} // keeps the disclosures modal behind the welcome modal
          actionButtons={[
            {
              action: () => this.setState({ showDisclosures: false }),
              label: InvestmentOptionStep.btn_ok,
            }
          ]}
        >
          <ul>
            {InvestmentOptionStep.text_disclosures(programDescription).map((line, idx) => idx !== 7 && <li key={line}>{line}</li>)}
          </ul>
          <p>{InvestmentOptionStep.text_disclosures(programDescription)[7]}</p>
        </Modal>

        <Modal
          actionButtons={[
            {
              label: InvestmentOptionStep.btn_ok,
              action: () => this.setState({ showModalWhenMobileAndCustomized: false }),
            }
          ]}
          fullScreen={false}
          onCloseModal={() => this.setState({ showModalWhenMobileAndCustomized: false })}
          show={this.state.showModalWhenMobileAndCustomized}
          title={InvestmentOptionStep.title_options_on_mobile}
        >
          {InvestmentOptionStep.text_options_on_mobile}
        </Modal>
      </React.Fragment>
    );
  }
}


export default withStyles(muiStyles)(connect(select, {
  getNotifications,
  allNotificationsHide,
  getNewAgeBasedSchema,
  getNewStaticSchema,
  notificationShow,
  saveCustomizedOption,
  savePredefinedInvestmentOption,
})(LanguageHOC(InvestmentOptionStep)));
