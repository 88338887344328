/*
 *
 * Step1DateAndBank Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Button,
  Icon,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  DatePicker,
  Dropdown,
  LoadingOverlay,
} from '@frontend/common';

import styles from '../styles.module.scss';

const muiStyles = () => ({
  root: {
    whiteSpace: 'normal',
    wordBreak: 'normal',
    overflowWrap: 'anywhere'
  }
});

Step1DateAndBank.propTypes = {
  classes: PropTypes.object.isRequired,
  contribution: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  agentBankAccounts: PropTypes.array.isRequired,
  bankAccountsLoading: PropTypes.bool.isRequired,
  toggleShowAddBank: PropTypes.func.isRequired,
  contributionDateHandle: PropTypes.func.isRequired,
  contributionDatesDisable: PropTypes.func.isRequired,
  bankAccountSelectHandle: PropTypes.func.isRequired,
  errorGet: PropTypes.func.isRequired,
  text: PropTypes.shape({
    ContributionOneTimeStep1: PropTypes.shape({
      btn_add_bank: PropTypes.string,
      lbl_add_bank: PropTypes.string,
      lbl_bank_account: PropTypes.string,
      lbl_contibution_date: PropTypes.string,
      lbl_contribution_date: PropTypes.string,
    })
  }).isRequired,
};

export function Step1DateAndBank({
  classes,
  contribution,
  errors,
  agentBankAccounts,
  bankAccountsLoading,
  toggleShowAddBank,
  contributionDateHandle,
  contributionDatesDisable,
  bankAccountSelectHandle,
  errorGet,
  text: { ContributionOneTimeStep1 },
}) {
  const agentAccountsFormatted = agentBankAccounts.map(account => {
    if (account.Nickname && account.Nickname.length > 0) {
      return {
        value: account.BankAccountId,
        display: `${account.Nickname}, ${account.MaskedBankAccountNumber}`
      };
    }
    else {
      return {
        value: account.BankAccountId,
        display: account.BankAccountId === 0 ? account.BankName : `${account.BankName}, ${account.MaskedBankAccountNumber}`
      };
    }
  });

  return (
    <React.Fragment>
      <div className={styles.datePicker}>
        <DatePicker
          clearable={false}
          key='datePicker'
          label={ContributionOneTimeStep1.lbl_contribution_date}
          onChange={contributionDateHandle}
          shouldDisableDate={contributionDatesDisable}
          value={contribution.day1}
          error={errors.some(error => error.key === 'day1')}
          helperText={errorGet('day1')}
          sx={{ minWidth: '256px', width: '100%' }}
        />
      </div>

      {agentBankAccounts.length > 0 ?
        <div className={styles.bankAccount}>
          <LoadingOverlay show={bankAccountsLoading}>
            <Dropdown
              label={ContributionOneTimeStep1.lbl_bank_account}
              value={contribution.bankAccount.BankAccountId}
              errorText={errorGet('bankAccount')}
              onChange={bankAccountSelectHandle}
              options={agentAccountsFormatted}
              FormControlProps={{ style: { width: '256px', textAlign: 'left' } }}
              MenuItemProps={{
                classes: { root: classes.root }
              }}
            />
          </LoadingOverlay>

          <div className={styles.bankAccount}>
            <LoadingOverlay show={bankAccountsLoading}>
              <Button
                color='secondary'
                startIcon={<Icon>add</Icon>}
                onClick={toggleShowAddBank}
              >
                {ContributionOneTimeStep1.btn_add_bank}
              </Button>
            </LoadingOverlay>
          </div>
        </div>
        :
        <div className={styles.bankAccount}>
          <LoadingOverlay show={bankAccountsLoading}>
            <Button
              onClick={toggleShowAddBank}
              variant='contained'
            >
              {ContributionOneTimeStep1.btn_add_bank}
            </Button>
          </LoadingOverlay>
        </div>
      }
    </React.Fragment>
  );
}

export default (LanguageHOC(withStyles(muiStyles)(Step1DateAndBank)));