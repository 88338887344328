/*
*
* OptionDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  IconBtnTooltip,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
} from '@frontend/common';

import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';

export class OptionDetails extends React.Component {
  static propTypes = {
    account: PropTypes.shape({
      fundAllocations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          fundName: PropTypes.string.isRequired,
          ticker: PropTypes.string.isRequired,
          priceNum: PropTypes.number.isRequired,
          Units: PropTypes.string.isRequired,
          Value: PropTypes.string.isRequired,
        }).isRequired,
      ).isRequired,
      option: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        isCustom: PropTypes.bool,
      }),
      optionChangesRemaining: PropTypes.number,
    }),
    isCustomOption: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    predefinedOptions: PropTypes.array.isRequired,
    text: PropTypes.shape({
      OptionDetails: PropTypes.shape({
        head_investment_option: PropTypes.func,
        head_remaining_option_changes: PropTypes.string,
        nav_path_investment: PropTypes.string,
        text_custom: PropTypes.string,
        text_enrollment_date: PropTypes.string,
        text_static: PropTypes.string,
        title_portfolio: PropTypes.string,
        title_nav: PropTypes.string,
        title_see_more_details: PropTypes.string,
        title_symbol: PropTypes.string,
        title_total: PropTypes.string,
        title_units: PropTypes.string,
      }),
    }),
  };

  state = {
    currentOptionLink: '',
    currentOptionType: '',
  }

  componentDidMount() {
    const { account, isCustomOption, path, predefinedOptions, text: { OptionDetails } } = this.props;

    const currentOption = predefinedOptions.find(option => option.OptionId === account.option.id);
    const currentOptionLink = isCustomOption
      // only Customized options can use the investment path link
      ? `${path}/${OptionDetails.nav_path_investment}`
      : currentOption.OptionOnlineLink;
    const currentOptionType = isCustomOption
      ? OptionDetails.text_custom
      : currentOption.isStatic
        ? OptionDetails.text_static
        : OptionDetails.text_enrollment_date;
    this.setState({ currentOptionLink, currentOptionType });
  }

  render() {
    const { account: { fundAllocations, option, optionChangesRemaining }, isCustomOption, history, text: { OptionDetails } } = this.props;
    const { currentOptionLink, currentOptionType } = this.state;
    const handleOptionLink = () => isCustomOption
      ? history.push(currentOptionLink)
      : window.open(currentOptionLink, '_blank', 'noopener noreferrer');
    
    return (
      <div className={styles.optionDetailsContainer}>
        <div className={styles.optionDetails}>
          <div className={styles.optionName}>
            <div className={parentStyles.topLabel}>
              {OptionDetails.head_investment_option(currentOptionType)}
            </div>
            <div className={` ${styles.option} ${parentStyles.option}`}>
              <div>{option.name}</div>
              <div>
                <IconBtnTooltip
                  buttonProps={{ style: { marginTop: '-10px' } }}
                  icon='launch'
                  onClick={handleOptionLink}
                  title={OptionDetails.title_see_more_details}
                />
              </div>
            </div>
          </div>

          <div className={styles.remainingOptions}>
            <div className={parentStyles.topLabel}>
              {OptionDetails.head_remaining_option_changes}
            </div>
            <div className={parentStyles.optionChangesRemaining}>{optionChangesRemaining}</div>
          </div>
        </div>

        <SmartTable
          idKey='id'
          rows={fundAllocations}
          columns={[
            {
              key: 'fundName',
              title: OptionDetails.title_portfolio,
              type: 'string',
            },
            {
              key: 'ticker',
              title: OptionDetails.title_symbol,
              type: 'string',
            },
            {
              key: 'Price',
              title: OptionDetails.title_nav,
              type: 'string',
              hideOn: ['phone'],
              customStyle: { textAlign: 'right' },
            },
            {
              key: 'Units',
              title: OptionDetails.title_units,
              type: 'string',
              hideOn: ['phone'],
              customStyle: { textAlign: 'right' },
            },
            {
              key: 'Value',
              title: OptionDetails.title_total,
              type: 'string',
              customStyle: { textAlign: 'right' },
            },
          ]}
        >
          <TableContainer minWidth='100%' maxHeight='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>
      </div>
    );
  }

}

export default withRouter(LanguageHOC(OptionDetails));