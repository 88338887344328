import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
} from '@mui/material';
import FormWrapper from '../FormWrapper';
import {
  LoadingOverlay,
  PasswordRequirements,
  notificationShow,
  PasswordInput,
} from '@frontend/common';

import { resetPassword } from 'components/Features/public/ResetPassword/actions';
import { passwordRequirementsGet } from 'components/AppRoot/StaticResources/actions';

import styles from './styles.module.scss';


const select = (state) => ({
  passwordRequirements: state.static.passwordRequirements,
});

export class ResetPassword extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    passwordRequirementsGet: PropTypes.func.isRequired,
    passwordRequirements: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    text: PropTypes.shape({
      Login: PropTypes.shape({ nav_path: PropTypes.string }),
      ResetPassword: PropTypes.shape({
        btn_submit: PropTypes.string,
        err_confirm_password: PropTypes.string,
        err_password_criteria: PropTypes.string,
        err_password_match: PropTypes.string,
        err_password_required: PropTypes.string,
        head_title: PropTypes.string,
        msg_password_changed: PropTypes.string,
        text_confirm_password: PropTypes.string,
        text_new_password: PropTypes.string,
      })
    }).isRequired,
  };

  state = {
    loading: false,
    password: '',
    passwordConfirmation: '',
    formErrors: {},
    apiErrors: [],
    passwordPassesValidation: false,
  };

  formValidate() {
    const formErrors = {};
    const { err_confirm_password, err_password_criteria, err_password_match, err_password_required, } = this.props.text.ResetPassword;
    const { apiErrors } = this.state;

    if (!this.state.password) {
      formErrors.password = err_password_required;
    }

    if (!this.state.passwordConfirmation) {
      formErrors.passwordConfirmation = err_confirm_password;
    }

    if (this.state.passwordConfirmation && this.state.password !== this.state.passwordConfirmation) {
      formErrors.passwordConfirmation = err_password_match;
    }
    if (this.state.password && !this.state.passwordPassesValidation) {
      formErrors.password = err_password_criteria;
    }

    if (apiErrors.length > 0) {
      // this is assuming that api Field properties would not change!
      apiErrors.forEach(error => {
        if (error.Field === 'password1') {
          formErrors.password = error.Message;
        }
        else if (error.Field === 'password2') {
          formErrors.passwordConfirmation = error.Message;
        }
      });
    }

    this.setState({
      formErrors,
      apiErrors: []
    });

    return Object.keys(formErrors).length === 0;
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    if (this.formValidate()) {
      document.getElementById('password').blur();
      document.getElementById('passwordConfirm').blur();

      const postData = {
        id: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).request,
        password1: this.state.password,
        password2: this.state.passwordConfirmation,
      };

      this.setState({ loading: true });
      this.props.resetPassword(postData)
        .then(() => {
          this.props.history.push(this.props.text.Login.nav_path);
          this.props.notificationShow(this.props.text.ResetPassword.msg_password_changed, 'success');
        })
        .catch(response => this.setState({
          apiErrors: response.payload.data,
          loading: false
        }, () => this.formValidate()));
    }
  };

  onInputChange = (inputName, value) => {
    this.setState({
      [inputName]: value,
      formErrors: {
        ...this.state.formErrors,
        [inputName]: '',
      }
    });
  };

  componentDidMount() {
    if (this.props.passwordRequirements.length === 0) {
      this.setState({ loading: true });
      if (this.props.passwordRequirements.length === 0) {
        this.props.passwordRequirementsGet()
          .finally(() => this.setState({ loading: false }));
      }
    }
  }

  render() {
    const { btn_submit, head_title, text_confirm_password, text_new_password, } = this.props.text.ResetPassword;

    return (
      <FormWrapper title={head_title}>
        <LoadingOverlay
          show={this.state.loading}
          width='100%'
        >
          <form
            className='formContainer'
            onSubmit={this.onFormSubmit}
          >
            <PasswordInput
              id='password'
              onChange={(e) => this.onInputChange('password', e.target.value)}
              label={text_new_password}
              errorText={this.state.formErrors.password}
              fullWidth
              disabled={this.state.loading}
              autoComplete='new-password'
              variant='filled'
            />
            <PasswordInput
              id='passwordConfirm'
              onChange={(e) => this.onInputChange('passwordConfirmation', e.target.value)}
              label={text_confirm_password}
              errorText={this.state.formErrors.passwordConfirmation}
              fullWidth
              disabled={this.state.loading}
              autoComplete='new-password'
              variant='filled'
            />
            <div className={styles.divPadding}>
              <PasswordRequirements
                passwordRequirements={this.props.passwordRequirements}
                password={this.state.password}
                onPasswordCheck={isValid => this.setState({ passwordPassesValidation: isValid })}
              />
            </div>
            <div className={styles.buttons}>
              <Button
                variant='text'
                color='primary'
                style={{ width: '48%' }}
                onClick={() => this.props.history.push(this.props.text.Login.nav_path)}
                disabled={this.state.loading}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                disabled={this.state.loading}
                style={{ width: '48%' }}
              >
                {btn_submit}
              </Button>
            </div>
          </form>
        </LoadingOverlay>
      </FormWrapper>
    );
  }
}

export default withRouter(connect(select, {
  resetPassword,
  passwordRequirementsGet,
  notificationShow,
})(LanguageHOC(ResetPassword)));