/*
*
* ValidationFinishModal Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import {
  Modal,
  BaseNumberInput,
  notificationShow,
} from '@frontend/common';
import {
  confirmMicroDeposits,
} from '../actions';

import styles from './styles.module.scss';


export class ValidationFinishModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleShowValidationFinish: PropTypes.func.isRequired,
    selectedBankAccount: PropTypes.object.isRequired,
    handleGetBankAccounts: PropTypes.func.isRequired,
    confirmMicroDeposits: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    text: PropTypes.shape({
      ValidationFinish: PropTypes.shape({
        btn_back: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_validate: PropTypes.string,
        err_required: PropTypes.string,
        err_full_amount: PropTypes.string,
        head_validate: PropTypes.func,
        lbl_amount1: PropTypes.string,
        lbl_amount2: PropTypes.string,
        msg_validated: PropTypes.string,
        text_exceeded_attempts_error: PropTypes.string,
        text_match_error: PropTypes.func,
        text_validation_instructions: PropTypes.string,
      })
    })
  };

  state = {
    validationAmounts: {
      amount1: '',
      amount2: ''
    },
    validationValues: { // this is only for validation needs
      amount1: '',
      amount2: ''
    },
    formErrors: {},
    apiError: {},
    isValidating: false,
    attemptsRemaining: null,
  }

  onInputChange = (name, e) => {
    this.setState({
      validationAmounts: {
        ...this.state.validationAmounts,
        [name]: e.formattedValue,
      },
      validationValues: { // values needed for validation
        ...this.state.validationValues,
        [name]: e.value,
      },
    },() => this.formValidate(name));
  }

  formValidate = (name) => {
    const { err_required, err_full_amount } = this.props.text.ValidationFinish;
    const { validationValues } = this.state;
    const formErrors = {};

    if (name === 'amount1' || name === 'all') {
      if (validationValues.amount1.length < 2 && validationValues.amount1.length > 0) {
        formErrors.amount1 = err_full_amount;
      }
      if (!validationValues.amount1) {
        formErrors.amount1 = err_required;
      }
    }
    if (name === 'amount2' || name === 'all') {
      if (validationValues.amount2.length < 2 && validationValues.amount2.length > 0) {
        formErrors.amount2 = err_full_amount;
      }
      if (!validationValues.amount2) {
        formErrors.amount2 = err_required;
      }
    }
    
    if (name === 'all') {
      this.setState({
        formErrors,
      });
      return Object.keys(formErrors).length !== 0;
    }
    else {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          [name]: formErrors[name],
        },
      });
    }
  }

  closeForm = () => {
    setTimeout(() => { // timeout needed to clear state, due to input change getting called after from the CurrencyInput component
      this.setState({ 
        validationAmounts: {
          amount1: '',
          amount2: ''
        },
        validationValues: { // this is only for validation needs
          amount1: '',
          amount2: ''
        },
        formErrors: {}, 
        apiError: {},
      });
    }, 250);
    this.props.handleShowValidationFinish();
  }

  onFormSubmit = () => {
    const { 
      selectedBankAccount, 
      confirmMicroDeposits, 
      handleGetBankAccounts,
      notificationShow,
      text: { ValidationFinish },
    } = this.props;
    const { validationAmounts } = this.state;

    if (this.formValidate('all')) {
      return; // has errors, stop the submit
    }

    this.setState({ isValidating: true });
    confirmMicroDeposits(selectedBankAccount.BankAccountId, validationAmounts)
      .then(({ payload: { data } }) => {
        const verificationResponse = data;
        const apiError = {};

        handleGetBankAccounts();
        
        if (verificationResponse) {
          this.closeForm();
          notificationShow(ValidationFinish.msg_validated, 'success');
        } 
        else if (this.state.attemptsRemaining > 1) {
          apiError.didNotMatch = true;
        }
        else if (this.state.attemptsRemaining === 1) {
          apiError.validationAttemptsExceeded = true;
        }

        this.setState({ isValidating: false, apiError, attemptsRemaining: this.state.attemptsRemaining - 1, });
      })
      .catch(() => {
        this.setState({ isValidating: false });
      });
  }

  componentDidMount() {
    this.setState({ attemptsRemaining: this.props.selectedBankAccount.VerificationAttemptsRemaining });
  }

  render() {
    const { 
      open, 
      selectedBankAccount,
      text: { ValidationFinish }
    } = this.props;
    const { validationAmounts, isValidating, formErrors, apiError } = this.state;
    const pluralize = this.state.attemptsRemaining > 1;
    const actionButtons = this.state.attemptsRemaining === 0 ?
      [
        {
          label: ValidationFinish.btn_back,
          action: () => this.closeForm(),
          buttonType: 'contained',
        },
      ] :
      [
        {
          label: ValidationFinish.btn_validate,
          loading: isValidating,
          action: () => this.onFormSubmit(),
          buttonType: 'contained',
        }
      ];
    
    return (
      <Modal
        title={ValidationFinish.head_validate(selectedBankAccount.MaskedBankAccountNumber)}
        show={open}
        onCloseModal={this.closeForm}
        modal={false}
        maxWidth='md'
        fullWidth={true}
        actionButtons={actionButtons}
        includeLeftCancelBtn={this.state.attemptsRemaining > 0}
      >
        <p className={styles.validateDepositsText}> 
          {ValidationFinish.text_validation_instructions}
        </p>
        {this.state.attemptsRemaining !== 0 &&
          <div className={styles.amountInputs}>
            <BaseNumberInput 
              className={styles.amountInput}
              label={ValidationFinish.lbl_amount1}
              name='amount1'
              variant='filled'
              value={validationAmounts.amount1}
              onChange={e => this.onInputChange('amount1', e)}
              errorText={formErrors.amount1}
              error={Boolean(formErrors.amount1)}
              disabled={isValidating}
              isNumericFormat={false} // only way to format this the way we wanted was to use the PatternFormat version
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              inputProps={{
                maxLength: '5',
                format: '0.##',
              }}
            />
            <BaseNumberInput 
              className={styles.amountInput}
              label={ValidationFinish.lbl_amount2}
              name='amount2'
              variant='filled'
              value={validationAmounts.amount2}
              onChange={e => this.onInputChange('amount2', e)}
              errorText={formErrors.amount2}
              error={Boolean(formErrors.amount2)}
              disabled={isValidating}
              isNumericFormat={false} // only way to format this the way we wanted was to use the PatternFormat version
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              inputProps={{
                maxLength: '5',
                format: '0.##',
              }}
            />
          </div>}

        {apiError.didNotMatch && <FormHelperText error={Boolean(apiError.didNotMatch)}>
          {ValidationFinish.text_match_error(this.state.attemptsRemaining, pluralize)}
        </FormHelperText>}
        {apiError.validationAttemptsExceeded && <FormHelperText error={Boolean(apiError.validationAttemptsExceeded)}>
          {ValidationFinish.text_exceeded_attempts_error}
        </FormHelperText>}
      </Modal>
    );
  }
}

export default connect(null, { notificationShow, confirmMicroDeposits, })(LanguageHOC(ValidationFinishModal));