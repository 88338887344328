export const ACCOUNT_TYPE_UGMA_UTMA = 'UGMA/UTMA';
export const ACCESS_CARD_PAYMENT = 'AccessCard';
export const ACCOUNT_OWNER = 'P';
export const BENEFICIARY = 'B';
export const ACH_PAYMENT = 'Ach';
export const CHECK_PAYMENT = 'Check';
export const ELECTRONICSCHOOL_PAYMENT = 'ElectronicSchoolPayment';
export const K12_ED = 'K-12Tuition';
export const FULL_BALANCE = 'Full Balance';
export const HIGHER_ED = 'HigherEd';
export const NONQUALIFIED = 'Nonqualified';
export const ONE_TIME = 'One-Time';
export const MONTHLY = 'Monthly';
export const RECURRING = 'Recurring';
export const PARTIAL_BALANCE = 'Partial Balance';
export const SCHOOL = 'I';
export const ELECTRONIC = 'electronic';
export const MAIL = 'mail';
export const ELECTRONICSCHOOL = 'ElectronicSchool';