/*
*
* EditLpoa Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  IconBtnTooltip,
  InfoIcon,
  notificationShow
} from '@frontend/common';

import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Radio,
} from '@mui/material';

import LpoaStepper from './LpoaStepper';

import { postLpoa } from '../actions';

import styles from './styles.module.scss';

const select = () => ({});

export class EditLpoa extends React.Component {

  static propTypes = {
    selectedBeneIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    lpoa: PropTypes.shape({
      PoaCode: PropTypes.string.isRequired,
      PoaName: PropTypes.string.isRequired,
      AgentId: PropTypes.number.isRequired,
      BeneficiaryInfo: PropTypes.arrayOf(PropTypes.shape({
        BeneficiaryId: PropTypes.number.isRequired,
        BeneficiaryName: PropTypes.string.isRequired,
        PoaLevel: PropTypes.number.isRequired,
        FederalTaxDocs: PropTypes.bool.isRequired,
        StateTaxDocs: PropTypes.bool.isRequired,
      }).isRequired),
    }).isRequired,
    text: PropTypes.shape({
      Lpoa: PropTypes.object.isRequired,
    }).isRequired,
    postLpoa: PropTypes.func.isRequired,
    goBackToLpoaTableAndRefreshLpoa: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
  }

  state = {
    loading: false,
    selectedPoaLevel: '',
    selectedPoaLevelError: '',
    selectedFedTaxDocs: false,
    selectedStateTaxDocs: false,
    termsChecked: false,
    termsCheckedError: '',
    clearStepError: false,
  }

  clearStepErrors = () => {
    this.setState({
      selectedPoaLevelError: '',
      termsCheckedError: '',
      clearStepError: true,
    });
  }

  formValidate = (step) => {
    const {
      text: {
        Lpoa: {
          errors: {
            missing_lpoa_level,
            missing_terms_checked,
          },
        }
      }
    } = this.props;

    const { selectedPoaLevel, termsChecked } = this.state;

    let selectedPoaLevelError = '';
    let termsCheckedError = '';

    switch (step) {
      case 0:
        if (selectedPoaLevel === '')
          selectedPoaLevelError = missing_lpoa_level;
        break;
      case 1:
        if (!termsChecked)
          termsCheckedError = missing_terms_checked;
        break;
      default:
      // something wrong do nothing
    }

    const stepHasError = Boolean(selectedPoaLevelError) || Boolean(termsCheckedError);

    this.setState({
      selectedPoaLevelError,
      termsCheckedError,
      clearStepError: !stepHasError,
    });

    return stepHasError;
  }

  levelSelectionHandler = (e) => {
    this.setState({
      selectedPoaLevel: parseInt(e.target.value),
    });
    this.clearStepErrors();
  }

  onFedTaxDocsClickHandler = () => {
    const selectedFedTaxDocs = !this.state.selectedFedTaxDocs;
    this.setState({ selectedFedTaxDocs });
  }

  onStateTaxDocsClickHandler = () => {
    const selectedStateTaxDocs = !this.state.selectedStateTaxDocs;
    this.setState({ selectedStateTaxDocs });
  }

  onTermsCheckHandler = () => {
    const termsChecked = !this.state.termsChecked;
    this.setState({ termsChecked });
    this.clearStepErrors();
  }

  onSubmitHandler = () => {
    const { selectedBeneIds, lpoa, postLpoa, goBackToLpoaTableAndRefreshLpoa, text: { Lpoa } } = this.props;
    const { selectedPoaLevel, selectedFedTaxDocs, selectedStateTaxDocs } = this.state;

    const updatedBeneficiaryInfo = lpoa.BeneficiaryInfo
      .filter(bene => selectedBeneIds.includes(bene.BeneficiaryId))
      .map(bene => ({
        BeneficiaryId: bene.BeneficiaryId,
        BeneficiaryName: bene.BeneficiaryName,
        PoaLevel: selectedPoaLevel,
        FederalTaxDocs: !selectedFedTaxDocs, // we want reversed for disallowing
        StateTaxDocs: !selectedStateTaxDocs, // we want reversed for disallowing
      }));

    const updatedLpoaBody = {
      PoaCode: lpoa.PoaCode,
      AgentId: lpoa.AgentId,
      BeneficiaryInfo: updatedBeneficiaryInfo,
    };

    this.setState({ loading: true });
    return postLpoa(updatedLpoaBody)
      .finally(() => {
        this.setState({ loading: false });
        this.props.notificationShow(Lpoa.success_lpoa_updated, 'success');
        goBackToLpoaTableAndRefreshLpoa();
      });
  }

  renderStepComponent = step => {
    const { lpoa, text: { Lpoa }, selectedBeneIds } = this.props;
    const {
      selectedPoaLevelError,
      termsCheckedError,
      selectedPoaLevel,
      selectedFedTaxDocs,
      selectedStateTaxDocs,
      termsChecked,
    } = this.state;

    let stepComponent;
    switch (step) {
      case 0:
        stepComponent = () => (
          <div className={styles.levelSelectionContainer}>
            <div>
              <h2>{Lpoa.edit_level_table.title}</h2>
              <p>{Lpoa.edit_level_table.lpoa_name_label}</p>
              <p>{lpoa.PoaName}</p>
            </div>
            <div style={{ overflowX: 'auto' }}> {/* make sure the table scrolls horizontally in mobile */}
              <table>
                <thead>
                  <tr>
                    <td rowSpan={2}>{Lpoa.edit_level_table.column_titles[0]}</td>
                    <td rowSpan={2}>{Lpoa.edit_level_table.column_titles[1]}</td>
                    <td colSpan={4}>{Lpoa.edit_level_table.span_column_3_4_5_6_title}</td>
                  </tr>
                  <tr>
                    <td>{Lpoa.edit_level_table.column_titles[2]}</td>
                    <td>{Lpoa.edit_level_table.column_titles[3]}</td>
                    <td>{Lpoa.edit_level_table.column_titles[4]}</td>
                    <td>{Lpoa.edit_level_table.column_titles[5]}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FormControlLabel
                        control={
                          <Radio
                            color='primary'
                            checked={selectedPoaLevel === 1}
                            onChange={this.levelSelectionHandler}
                            value='1'
                            name='level1RadioButton'
                            inputProps={{ 'aria-label': Lpoa.edit_level_table.levels[0] }}
                          />
                        }
                        label={Lpoa.edit_level_table.levels[0]}
                      />
                    </td>
                    <td>{Lpoa.edit_level_table.online_phone_text}</td>
                    <td>{Lpoa.text_no}</td>
                    <td>{Lpoa.text_no}</td>
                    <td>{Lpoa.text_no}</td>
                    <td>{Lpoa.text_no}</td>
                  </tr>
                  <tr>
                    <td>
                      <FormControlLabel
                        control={
                          <Radio
                            color='primary'
                            checked={selectedPoaLevel === 2}
                            onChange={this.levelSelectionHandler}
                            value='2'
                            name='level2RadioButton'
                            inputProps={{ 'aria-label': Lpoa.edit_level_table.levels[1] }}
                          />
                        }
                        label={Lpoa.edit_level_table.levels[1]}
                      />
                    </td>
                    <td>{Lpoa.edit_level_table.online_phone_text}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>{Lpoa.text_no}</td>
                  </tr>
                  <tr>
                    <td>
                      <FormControlLabel
                        control={
                          <Radio
                            color='primary'
                            checked={selectedPoaLevel === 3}
                            onChange={this.levelSelectionHandler}
                            value='3'
                            name='level3RadioButton'
                            inputProps={{ 'aria-label': Lpoa.edit_level_table.levels[2] }}
                          />
                        }
                        label={Lpoa.edit_level_table.levels[2]}
                      />
                    </td>
                    <td>{Lpoa.edit_level_table.online_phone_text}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>
                      {Lpoa.text_yes}
                      <InfoIcon message={Lpoa.level_3_info_bubble} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <FormHelperText
              error={Boolean(selectedPoaLevelError)}
              style={{ textAlign: 'center' }}
            >
              {selectedPoaLevelError}
            </FormHelperText>

            <div className={styles.formsTitle}>
              {Lpoa.edit_tax_docs.label}
            </div>
            <div className={styles.formsRow}>
              <div className={styles.formSelection}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      inputProps={{ 'aria-label': 'Federal Tax Forms' }}
                      onClick={this.onFedTaxDocsClickHandler}
                      checked={selectedFedTaxDocs}
                    />
                  }
                  label={Lpoa.fed_tax_docs_label}
                />
              </div>
              <div className={styles.formSelectionLabel}>
                {Lpoa.fed_tax_docs_body()}
              </div>
            </div>
            <hr className={styles.taxSelectionSeparator} />
            <div className={styles.formsRow}>
              <div className={styles.formSelection}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      inputProps={{ 'aria-label': 'State Tax Forms' }}
                      onClick={this.onStateTaxDocsClickHandler}
                      checked={selectedStateTaxDocs}
                    />
                  }
                  label={Lpoa.state_tax_docs_label}
                />
              </div>
              <div className={styles.formSelectionLabel}>
                {Lpoa.state_tax_docs_body()}
              </div>
            </div>
          </div>
        );
        break;

      case 1: {
        const beneListFormatted = selectedBeneIds
          .map(beneId => {
            const bene = lpoa.BeneficiaryInfo.find(bene => bene.BeneficiaryId === beneId);
            return (
              <li key={beneId}>{bene.BeneficiaryName}</li>
            );
          });
        const allowFedTax = selectedFedTaxDocs ? 'disallow' : 'allow';
        const allowStateTax = selectedStateTaxDocs ? 'disallow' : 'allow';
        stepComponent = () => (
          <div>
            <div className={styles.printButton}>
              <IconBtnTooltip
                icon='print'
                onClick={() => window.print()}
              />
            </div>
            <div className={styles.review}>
              <h2>{Lpoa.review_title}</h2>
              {Lpoa.review_details(lpoa.PoaName, selectedPoaLevel, beneListFormatted, allowFedTax, allowStateTax)}
              <h3>{Lpoa.terms_and_conditions_title}</h3>
              {Lpoa.terms_and_conditions_body(lpoa.PoaName)}
              <div className={styles.agreeToTerms}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      inputProps={{ 'aria-label': 'Agree to Terms' }}
                      onClick={this.onTermsCheckHandler}
                      checked={termsChecked}
                    />
                  }
                  label={Lpoa.terms_and_conditions_agree_button_label}
                />

                <FormHelperText
                  error={Boolean(termsCheckedError)}
                  style={{ textAlign: 'center' }}
                >
                  {termsCheckedError}
                </FormHelperText>
              </div>
            </div>
          </div>
        );
        break;
      }


      default:
        stepComponent = () => <div>Step does not exist</div>;
    }

    return stepComponent();
  }

  render() {
    const { goBackToLpoaTableAndRefreshLpoa, text: { Lpoa } } = this.props; // no need to refresh the lpoa
    const { loading, clearStepError } = this.state;

    return (
      <div className={styles.container}>
        <LpoaStepper
          clearStepErrors={this.clearStepErrors}
          clearStepError={clearStepError}
          verifyStep={(step) => this.formValidate(step)}
          renderStepComponent={(step) => this.renderStepComponent(step)}
          handleSubmit={this.onSubmitHandler}
          stepLabels={Lpoa.edit_lpoa_step_labels}
          loading={loading}
          submitStep={1}
          onCancel={() => goBackToLpoaTableAndRefreshLpoa(false)}
        />
      </div>
    );
  }
}


export default connect(select, {
  postLpoa,
  notificationShow,
})(LanguageHOC(EditLpoa));
