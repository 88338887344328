import { cloneDeep } from 'lodash';

import {
  GIFTING_LIST_GET,
  GIFTING_CHANGE_STATUS,
  UPDATE_GIFT,
  GIFTING_CHANGE_NAME_VISIBILITY,
} from './constants';

const initialState = {
  beneficiaryList: [],
};

const mapGiftingList = (beneList) => {
  if (beneList && beneList.length > 0) {
    return beneList.map(bene => {
      bene.IsActive = Boolean(bene.IsActive);
      return bene;
    });
  }
  return [];
};

function GiftingReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {
    case GIFTING_LIST_GET: {
      if (action.payload.data && action.payload.data.length > 0) {
        newState.beneficiaryList = mapGiftingList(action.payload.data);
      }
      return newState;
    }
    
    case GIFTING_CHANGE_NAME_VISIBILITY: {
      const { AccountId, HideLastName } = action.payload.data;
      const beneficiaryListIndex = newState.beneficiaryList.findIndex(bene => bene.AccountId === AccountId);
      newState.beneficiaryList[beneficiaryListIndex].HideLastName = HideLastName;
      return newState;
    }

    case GIFTING_CHANGE_STATUS: {
      const { AccountId, IsActive } = action.payload.data;
      const beneficiaryListIndex = newState.beneficiaryList.findIndex(bene => bene.AccountId === AccountId);
      newState.beneficiaryList[beneficiaryListIndex].IsActive = IsActive;
      return newState;
    }

    case UPDATE_GIFT: {
      const { HasImage, GiftMessage, AccountId, GiftCode, GiftCodeId, IsActive, HideLastName } = action.payload.data;
      const beneficiaryListIndex = newState.beneficiaryList.findIndex(bene => bene.AccountId === AccountId);
      newState.beneficiaryList[beneficiaryListIndex].HasImage = HasImage;
      newState.beneficiaryList[beneficiaryListIndex].GiftMessage = GiftMessage;
      newState.beneficiaryList[beneficiaryListIndex].GiftCode = GiftCode;
      newState.beneficiaryList[beneficiaryListIndex].GiftCodeId = GiftCodeId;
      newState.beneficiaryList[beneficiaryListIndex].IsActive = IsActive;
      newState.beneficiaryList[beneficiaryListIndex].HideLastName = HideLastName;
      return newState;
    }

    default:
      return state;
  }
}

export default GiftingReducer;