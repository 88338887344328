/*
*
* EmailCard Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { camelCase, isEqual } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  CardButtons,
  CardTitle,
  InfoIcon,
  LoadingOverlay,
  Modal,
  notificationShow,
} from '@frontend/common';
import { emailValidator } from 'utils/helpers/form_validation';
import {
  Button,
  TextField
} from '@mui/material';
import { changeEmail } from '../actions';
import {
  clearStore,
  userLogout
} from 'components/AppRoot/Navigation/actions';
import {
  CHANGE_EMAIL
} from '../constants';

import styles from './styles.module.scss';


const select = state => ({
  isIpOnly_AccessRole: state.session.isIpOnly_AccessRole,
  supportNumber: state.static.environmentVars.SupportPhoneNumber,
});

export class EmailCard extends React.Component {
  static propTypes = {
    emailAddress: PropTypes.string.isRequired,
    changeEmail: PropTypes.func.isRequired,
    clearStore: PropTypes.func.isRequired,
    displayUnsavedChangesPrompt: PropTypes.func.isRequired,
    isIpOnly_AccessRole: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    supportNumber: PropTypes.string.isRequired,
    hasTitle: PropTypes.bool,
    text: PropTypes.shape({
      EmailCard: PropTypes.shape({
        btn_cancel_change_email: PropTypes.string,
        btn_change_email: PropTypes.string,
        btn_confirm_change_email: PropTypes.string,
        err_different_email: PropTypes.string,
        err_emails_must_match: PropTypes.string,
        err_required: PropTypes.string,
        err_valid_email: PropTypes.string,
        head_title: PropTypes.string,
        head_verify_email: PropTypes.string,
        lbl_current_email: PropTypes.string,
        lbl_new_email: PropTypes.string,
        lbl_new_email_confirm: PropTypes.string,
        msg_check_email_to_verify: PropTypes.func,
        msg_unsaved_changes: PropTypes.string,
        text_ip_email_visible_to_ao: PropTypes.string,
        text_verify_email1: PropTypes.string,
        text_verify_email2: PropTypes.string,
        text_verify_email3: PropTypes.string,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
        nav_path: PropTypes.string,
      }),
    }).isRequired,
    userLogout: PropTypes.func.isRequired,
  };

  static defaultProps = {
    hasTitle: true,
  };

  state = {
    apiErrors: [],
    changeEmailModalOpen: false,
    emailChangeConfirmed: false,
    formErrors: [],
    isSaving: false,
    newEmail: '',
    newEmailConfirm: '',
  };

  emailConfirm = () => {
    this.setState({ emailChangeConfirmed: true }, () => this.emailChange());
  }

  emailChange = event => {
    event && event.preventDefault();
    const { emailChangeConfirmed, newEmail } = this.state;

    if (!this.formValidate()) {
      return;
    }
    else if (!emailChangeConfirmed) {
      this.setState({ changeEmailModalOpen: true });
    }
    else {
      this.setState({ isSaving: true });
      this.props.changeEmail(newEmail)
        .then(() => {
          const token = sessionStorage.getItem('token');
          this.props.userLogout({ token })
            .finally(() => {
              this.props.clearStore();
              this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success');
              this.props.notificationShow(this.props.text.EmailCard.msg_check_email_to_verify(this.props.supportNumber), 'warning');
            });
        })
        .catch(response => {
          this.setState({
            apiErrors: response.payload.data,
            changeEmailModalOpen: false,
            emailChangeConfirmed: false,
            isSaving: false
          }, () => this.formValidate());
        });
    }
  }

  errorGet = name => {
    const error = this.state.formErrors.find(error => error.name === name);
    return error ? error.message : '';
  }

  formValidate = () => {
    const { apiErrors, newEmail, newEmailConfirm } = this.state;
    const { err_different_email, err_emails_must_match, err_required } = this.props.text.EmailCard;
    const formErrors = [];

    if (!newEmail) {
      formErrors.push({
        name: 'newEmail',
        message: err_required
      });
    }

    if (!newEmailConfirm) {
      formErrors.push({
        name: 'newEmailConfirm',
        message: err_required
      });
    }

    if (newEmail === this.props.emailAddress) {
      formErrors.push({
        name: 'newEmail',
        message: err_different_email
      });
    }

    if (newEmail) {
      const emailError = emailValidator(newEmail);
      emailError && formErrors.push({
        name: 'newEmail',
        message: emailError
      });
    }

    if (newEmail !== newEmailConfirm) {
      formErrors.push({
        name: 'newEmailConfirm',
        message: err_emails_must_match
      });
    }

    if (apiErrors.length > 0) {
      apiErrors.forEach(error => {
        if (error.Field === 'Email') {
          formErrors.push({
            name: 'newEmail',
            message: error.Message
          });
        }
        else {
          formErrors.push({
            name: camelCase(error.Field),
            message: error.Message
          });
        }
      });
    }

    this.setState({
      apiErrors: [],
      formErrors
    });

    return formErrors.length === 0;
  }

  onInputChange = (event, name) => {
    this.setState({
      [name]: event.target.value,
      formErrors: this.state.formErrors.filter(error => error.name !== name)
    });
  }

  updateButtonIsDisabled = () => {
    const { isSaving, newEmail } = this.state;
    return isSaving || !newEmail;
  }

  componentDidUpdate() {
    const { newEmail } = this.state;
    if (newEmail !== '' && !isEqual(newEmail, this.props.emailAddress)) {
      this.props.displayUnsavedChangesPrompt(CHANGE_EMAIL, true);
    }
    else {
      this.props.displayUnsavedChangesPrompt(CHANGE_EMAIL, false);
    }
  }

  render() {
    const { emailAddress, text: { EmailCard }, isIpOnly_AccessRole, hasTitle } = this.props;
    const { isSaving, newEmail, newEmailConfirm, changeEmailModalOpen } = this.state;

    return (
      <React.Fragment>
        <form onSubmit={this.emailChange}>
          <div>
            {hasTitle &&
              <CardTitle>
                <div className={styles.titleContainer}>
                  {EmailCard.head_title}
                  {isIpOnly_AccessRole && <InfoIcon message={EmailCard.text_ip_email_visible_to_ao} />}
                </div>
              </CardTitle>
            }
            <div>
              <TextField
                fullWidth
                label={EmailCard.lbl_current_email}
                name='currentEmail'
                disabled
                value={emailAddress}
              />
              <TextField
                error={Boolean(this.errorGet('newEmail'))}
                helperText={this.errorGet('newEmail')}
                fullWidth
                label={EmailCard.lbl_new_email}
                name='newEmail'
                onChange={e => this.onInputChange(e, 'newEmail')}
                value={newEmail}
              />
              <TextField
                error={Boolean(this.errorGet('newEmailConfirm'))}
                fullWidth
                helperText={this.errorGet('newEmailConfirm')}
                id='newEmailConfirm'
                label={EmailCard.lbl_new_email_confirm}
                name='newEmailConfirm'
                onChange={e => this.onInputChange(e, 'newEmailConfirm')}
                value={newEmailConfirm}
              />
            </div>

          </div>
          <CardButtons>
            <LoadingOverlay show={isSaving}>
              <Button
                disabled={this.updateButtonIsDisabled()}
                onClick={this.emailChange}
                type='submit'
                variant='contained'
              >
                {EmailCard.btn_change_email}
              </Button>
            </LoadingOverlay>
          </CardButtons>
        </form>

        <Modal
          actionButtons={[
            {
              action: () => this.emailConfirm(),
              buttonType: 'contained',
              label: EmailCard.btn_confirm_change_email,
              loading: isSaving,
            },
          ]}
          DialogProps={{ 'aria-labelledby': EmailCard.head_verify_email }}
          key='confirmEmailModal'
          loading={isSaving}
          onCloseModal={() => this.setState({ changeEmailModalOpen: false })}
          show={changeEmailModalOpen}
          title={EmailCard.head_verify_email}
          includeLeftCancelBtn
        >
          <div>
            <p>{EmailCard.text_verify_email1}</p>
            <p className={styles.emailAddress}>{newEmail}</p>
            <p>{EmailCard.text_verify_email2}</p>
            <p>{EmailCard.text_verify_email3}</p>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}


export default connect(select, {
  changeEmail,
  clearStore,
  notificationShow,
  userLogout,
})(LanguageHOC(EmailCard));
