/*
*
* ValueDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';

export class ValueDetails extends React.Component {
  static propTypes = {
    account: PropTypes.shape({
      netPrincipal: PropTypes.string,
      earnings: PropTypes.string,
      ytdContributions: PropTypes.string,
      priorContributions: PropTypes.string,
    }),
    text: PropTypes.shape({
      Details: PropTypes.shape({
        head_beneficiary: PropTypes.string,
        head_current_tax_year_contributions: PropTypes.string,
        head_earnings: PropTypes.string,
        head_netPrincipal: PropTypes.string,
        head_ppr: PropTypes.string,
        head_prior_tax_year_contributions: PropTypes.string,
        head_remaining_option_changes: PropTypes.string,
        head_total_value: PropTypes.func,
        nav_path_investment: PropTypes.string,
      }),
    }),
  };

  render() {
    const { account, text: { Details }, } = this.props;

    return (
      <div className={`${parentStyles.valueDetails} ${styles.valueDetails}`}>
        <div className={styles.activitySummary}>
          <div className={styles.summaryRow}>
            <div className={styles.detailTitle}>{Details.head_netPrincipal}</div>
            <div className={styles.detailText}>{account.netPrincipal}</div>
          </div>
          <div className={styles.summaryRow}>
            <div className={styles.detailTitle}>{Details.head_earnings}</div>
            <div className={styles.detailText}>{account.earnings}</div>
          </div>
          <div className={styles.summaryRow}>
            <div className={styles.detailTitle}>{Details.head_current_tax_year_contributions}</div>
            <div className={styles.detailText}>{account.ytdContributions}</div>
          </div>
          <div className={styles.summaryRow}>
            <div className={styles.detailTitle}>{Details.head_prior_tax_year_contributions}</div>
            <div className={styles.detailText}>{account.priorContributions}</div>
          </div>
        </div>
      </div>
    );
  }

}

export default LanguageHOC(ValueDetails);