/*
*
* LpoaAccount Actions
*
*/
import {
  GET_RSA,
  POST_RSA,
  GET_LPOA_ACCOUNT,
  APPROVE_LPOA_ACCOUNT,
  VALIDATE_LPOA_ACCOUNT,
  REJECT_LPOA_ACCOUNT,
  PERSON_TYPES,
  ADDRESS_TYPES,
  SAVE_TO_REDUX_LPOA_ACCOUNT,
} from './constants';

import { GETrequest, POSTrequest, DELETErequest } from 'utils/helpers/api_handler';

const mapAccount = (applicationInfo, guid) => {
  return {
    PoaAccountApplicationId: guid,
    OwnerFirstName: applicationInfo[PERSON_TYPES.AO].firstName,
    OwnerMiddleName: applicationInfo[PERSON_TYPES.AO].middleName,
    OwnerLastName: applicationInfo[PERSON_TYPES.AO].lastName,
    OwnerBirthdate: applicationInfo[PERSON_TYPES.AO].birthDate,
    OwnerSSN: applicationInfo[PERSON_TYPES.AO].ssn,
    OwnerIsResident: applicationInfo[PERSON_TYPES.AO].isUtahPayer,
    OwnerMailingAddress1: applicationInfo[PERSON_TYPES.AO][ADDRESS_TYPES.MAILING].street1,
    OwnerMailingAddress2: applicationInfo[PERSON_TYPES.AO][ADDRESS_TYPES.MAILING].street2,
    OwnerMailingCity: applicationInfo[PERSON_TYPES.AO][ADDRESS_TYPES.MAILING].city,
    OwnerMailingState: applicationInfo[PERSON_TYPES.AO][ADDRESS_TYPES.MAILING].state,
    OwnerMailingZipCode: applicationInfo[PERSON_TYPES.AO][ADDRESS_TYPES.MAILING].zipCode,
    OwnerPhysicalAddress1: applicationInfo[PERSON_TYPES.AO][ADDRESS_TYPES.PHYSICAL].street1,
    OwnerPhysicalAddress2: applicationInfo[PERSON_TYPES.AO][ADDRESS_TYPES.PHYSICAL].street2,
    OwnerPhysicalCity: applicationInfo[PERSON_TYPES.AO][ADDRESS_TYPES.PHYSICAL].city,
    OwnerPhysicalState: applicationInfo[PERSON_TYPES.AO][ADDRESS_TYPES.PHYSICAL].state,
    OwnerPhysicalZipCode: applicationInfo[PERSON_TYPES.AO][ADDRESS_TYPES.PHYSICAL].zipCode,
    OwnerEmail: applicationInfo[PERSON_TYPES.AO].contact.email,
    OwnerHomePhone: applicationInfo[PERSON_TYPES.AO].contact.homePhone,
    OwnerWorkPhone: applicationInfo[PERSON_TYPES.AO].contact.workPhone,
    OwnerOtherPhone: applicationInfo[PERSON_TYPES.AO].contact.otherPhone,
    Relationship: applicationInfo[PERSON_TYPES.AO].contact.relationship,
    BeneficiaryFirstName: applicationInfo[PERSON_TYPES.BENE].firstName,
    BeneficiaryMiddleName: applicationInfo[PERSON_TYPES.BENE].middleName,
    BeneficiaryLastName: applicationInfo[PERSON_TYPES.BENE].lastName,
    BeneficiaryBirthdate: applicationInfo[PERSON_TYPES.BENE].birthDate,
    BeneficiarySSN: applicationInfo[PERSON_TYPES.BENE].ssn,
    BeneficiaryIsResident: applicationInfo[PERSON_TYPES.BENE].isUtahPayer,
    BeneficiaryMailingAddress1: applicationInfo[PERSON_TYPES.BENE][ADDRESS_TYPES.MAILING].street1,
    BeneficiaryMailingAddress2: applicationInfo[PERSON_TYPES.BENE][ADDRESS_TYPES.MAILING].street2,
    BeneficiaryMailingCity: applicationInfo[PERSON_TYPES.BENE][ADDRESS_TYPES.MAILING].city,
    BeneficiaryMailingState: applicationInfo[PERSON_TYPES.BENE][ADDRESS_TYPES.MAILING].state,
    BeneficiaryMailingZipCode: applicationInfo[PERSON_TYPES.BENE][ADDRESS_TYPES.MAILING].zipCode,
    BeneficiaryPhysicalAddress1: applicationInfo[PERSON_TYPES.BENE][ADDRESS_TYPES.PHYSICAL].street1,
    BeneficiaryPhysicalAddress2: applicationInfo[PERSON_TYPES.BENE][ADDRESS_TYPES.PHYSICAL].street2,
    BeneficiaryPhysicalCity: applicationInfo[PERSON_TYPES.BENE][ADDRESS_TYPES.PHYSICAL].city,
    BeneficiaryPhysicalState: applicationInfo[PERSON_TYPES.BENE][ADDRESS_TYPES.PHYSICAL].state,
    BeneficiaryPhysicalZipCode: applicationInfo[PERSON_TYPES.BENE][ADDRESS_TYPES.PHYSICAL].zipCode,
    SuccessorFirstName: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY].firstName,
    SuccessorMiddleName: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY].middleName,
    SuccessorLastName: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY].lastName,
    SuccessorBirthdate: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY].birthDate,
    SuccessorSSN: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY].ssn,
    SuccessorUtahResident: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY].isUtahPayer,
    SuccessorMailingAddress1: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY][ADDRESS_TYPES.MAILING].street1,
    SuccessorMailingAddress2: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY][ADDRESS_TYPES.MAILING].street2,
    SuccessorMailingCity: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY][ADDRESS_TYPES.MAILING].city,
    SuccessorMailingState: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY][ADDRESS_TYPES.MAILING].state,
    SuccessorMailingZipCode: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY][ADDRESS_TYPES.MAILING].zipCode,
    SuccessorPhysicalAddress1: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY][ADDRESS_TYPES.PHYSICAL].street1,
    SuccessorPhysicalAddress2: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY][ADDRESS_TYPES.PHYSICAL].street2,
    SuccessorPhysicalCity: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY][ADDRESS_TYPES.PHYSICAL].city,
    SuccessorPhysicalState: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY][ADDRESS_TYPES.PHYSICAL].state,
    SuccessorPhysicalZipCode: applicationInfo[PERSON_TYPES.SUCCESSOR_PRIMARY][ADDRESS_TYPES.PHYSICAL].zipCode,
    SSuccessorFirstName: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY].firstName,
    SSuccessorMiddleName: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY].middleName,
    SSuccessorLastName: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY].lastName,
    SSuccessorBirthDate: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY].birthDate,
    SSuccessorSSN: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY].ssn,
    SSuccessorUtahResident: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY].isUtahPayer,
    SSuccessorMailingAddress1: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY][ADDRESS_TYPES.MAILING].street1,
    SSuccessorMailingAddress2: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY][ADDRESS_TYPES.MAILING].street2,
    SSuccessorMailingCity: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY][ADDRESS_TYPES.MAILING].city,
    SSuccessorMailingState: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY][ADDRESS_TYPES.MAILING].state,
    SSuccessorMailingZipCode: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY][ADDRESS_TYPES.MAILING].zipCode,
    SSuccessorPhysicalAddress1: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY][ADDRESS_TYPES.PHYSICAL].street1,
    SSuccessorPhysicalAddress2: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY][ADDRESS_TYPES.PHYSICAL].street2,
    SSuccessorPhysicalCity: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY][ADDRESS_TYPES.PHYSICAL].city,
    SSuccessorPhysicalState: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY][ADDRESS_TYPES.PHYSICAL].state,
    SSuccessorPhysicalZipCode: applicationInfo[PERSON_TYPES.SUCCESSOR_SECONDARY][ADDRESS_TYPES.PHYSICAL].zipCode,
    Date: applicationInfo.accountSummary.applicationDate,
    AccountTypeName: applicationInfo.accountSummary.type,
    OptionName: applicationInfo.accountSummary.option,
    PoaLevel: applicationInfo.accountSummary.poaLevel,
    CanViewFedTaxDocs: applicationInfo.accountSummary.fedTaxDocs,
    CanViewStateTaxDocs: applicationInfo.accountSummary.stateTaxDocs,
    Status: applicationInfo.accountSummary.status,
    AgeBands: applicationInfo.ageBands,
    FirmName: applicationInfo.lpoaFirmName,
  };
};

export function getRSA(guid) {
  const payload = GETrequest(`/lpoaaccount/verify/${guid}`, { headers: { 'lpoa-client-token': getRSAPassedCookie() } });
  return {
    type: GET_RSA,
    payload,
  };
}

export function postRSA(guid, body) {
  const payload = POSTrequest(`/lpoaaccount/verify/${guid}`, body, { headers: { 'lpoa-client-token': getRSAPassedCookie() } });
  return {
    type: POST_RSA,
    payload,
  };
}

export function getLpoaAccount(guid) {
  const payload = GETrequest(`/lpoaaccount/${guid}`, { headers: { 'lpoa-client-token': getRSAPassedCookie() } });
  return {
    type: GET_LPOA_ACCOUNT,
    payload,
  };
}

export function rejectLpoaAccount(guid) {
  const payload = DELETErequest(`/lpoaaccount/reject/${guid}`, { headers: { 'lpoa-client-token': getRSAPassedCookie() } });
  return {
    type: REJECT_LPOA_ACCOUNT,
    payload,
  };
}

export function saveToReduxLpoaAccount(applicationInfo) {
  return {
    type: SAVE_TO_REDUX_LPOA_ACCOUNT,
    payload: applicationInfo,
  };
}

export function validateLpoaAccount(applicationInfo, guid) {
  const body = mapAccount(applicationInfo, guid);
  const payload = POSTrequest('/lpoaaccount/validate', body, { headers: { 'lpoa-client-token': getRSAPassedCookie() } });
  return {
    type: VALIDATE_LPOA_ACCOUNT,
    payload,
  };
}

export function approveLpoaAccount(applicationInfo, guid) {
  const body = mapAccount(applicationInfo, guid);
  const payload = POSTrequest('/lpoaaccount/approve', body, { headers: { 'lpoa-client-token': getRSAPassedCookie() } });
  return {
    type: APPROVE_LPOA_ACCOUNT,
    payload,
  };
}

//Get the cookie to see if this browser has already passed RSA
const getRSAPassedCookie = () => {
  let cookie = '';
  const cookies = document.cookie.split(';');
  const rsaPassedCookie = cookies.find(cookie => cookie.includes('LPOA_RSA_Passed'));
  if (rsaPassedCookie) cookie = rsaPassedCookie.split('=').pop();
  return cookie;
};
