import { GETrequest, POSTrequest, PUTrequest } from 'utils/helpers/api_handler';
import {
  DEVICE_TOKEN_CREATE,
  DEVICE_TOKEN_GET,
  SECURITY_QESTION_GET,
  SECURITY_QESTIONS_ANSWER,
  RSA_STATUS_GET,
  RSA_QESTIONS_GET,
  RSA_ANSWERS,
  RSA_VERIFY_BIRTHDAY,
  TWO_FACTOR_REGISTRATION,
  TWO_FACTOR_REGISTRATION_UPDATE,
  TWO_FACTOR_TOKEN_VERIFICATION,
  TWO_FACTOR_TOKEN,
  TWO_FACTOR_QRCODES,
  TWO_FACTOR_PREFERRED_METHOD,
  TWO_FACTOR_SAVE_METHOD
} from './constants';


export function deviceTokenCreate() {
  const response = POSTrequest('/DeviceToken');
  return {
    type: DEVICE_TOKEN_CREATE,
    payload: response
  };
}

export function getDeviceToken(deviceToken) {
  const response = GETrequest(`/DeviceToken/${deviceToken}`);
  return {
    type: DEVICE_TOKEN_GET,
    payload: response
  };
}

export function getSecurityQuestion() {
  const response = GETrequest('/SecurityQuestion');
  return {
    type: SECURITY_QESTION_GET,
    payload: response
  };
}

export function securityQuestionAnswer(answer) {
  const response = POSTrequest('/SecurityQuestion/Answer', answer);
  return {
    type: SECURITY_QESTIONS_ANSWER,
    payload: response
  };
}

export function getRSAStatus() {
  const response = GETrequest('/RSA/Status');
  return {
    type: RSA_STATUS_GET,
    payload: response
  };
}

export function getRSAQuestionSet() {
  const response = GETrequest('/RSA/QuestionSet');
  return {
    type: RSA_QESTIONS_GET,
    payload: response
  };
}

export function rsaAnswers(answerSet) {
  const response = POSTrequest('/RSA/AnswerSet', answerSet);
  return {
    type: RSA_ANSWERS,
    payload: response
  };
}

export function rsaVerifyBirthday(birthday) {
  const response = POSTrequest('/RSA/VerifyBirthdate', birthday);
  return {
    type: RSA_VERIFY_BIRTHDAY,
    payload: response
  };
}

export function twoFactorRegistration(regInfo) {
  const response = POSTrequest('/TwoFactor/Registrations', regInfo);
  return {
    type: TWO_FACTOR_REGISTRATION,
    payload: response,
    meta: { regMethod: regInfo.verificationMethod }
  };
}

export function twoFactorRegistrationUpdate(regInfo) {
  const response = PUTrequest('/TwoFactor/Registrations', regInfo);
  return {
    type: TWO_FACTOR_REGISTRATION_UPDATE,
    payload: response,
    meta: { regMethod: regInfo.verificationMethod }
  };
}

export function twoFactorTokenVerification(token, method) {
  const response = GETrequest(`/TwoFactor/TokenVerification/${token}`, {
    params: {
      method,
    }
  });
  return {
    type: TWO_FACTOR_TOKEN_VERIFICATION,
    payload: response
  };
}

export function getTwoFactorToken(method) {
  const response = GETrequest(`/TwoFactor/Token/${method}`);
  return {
    type: TWO_FACTOR_TOKEN,
    payload: response
  };
}

// will be used on initial 2FA registration, when token does NOT need to be validated
export function getInitialTwoFactorQRCode() {
  const response = GETrequest('/TwoFactor/QRCodes/Initial');
  return {
    type: TWO_FACTOR_QRCODES,
    payload: response
  };
}

// will be used on updating 2FA registration, when token does need to be validated
export function getTwoFactorQRCode() {
  const response = GETrequest('/TwoFactor/QRCodes');
  return {
    type: TWO_FACTOR_QRCODES,
    payload: response
  };
}

export function getPreferredMethod() {
  const response = GETrequest('/TwoFactor/Users');
  return {
    type: TWO_FACTOR_PREFERRED_METHOD,
    payload: response
  };
}

export function savePreferredMethod(preferredMethod) {
  const response = PUTrequest('/TwoFactor/Users', preferredMethod);
  return {
    type: TWO_FACTOR_SAVE_METHOD,
    payload: response,
  };
}