/*
 *
 * Step0AmountAndDate Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';

import {
  Icon,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  Button
} from '@mui/material';

import { withStyles } from '@mui/styles';

import {
  CurrencyInput,
  currencyFormatter,
  Dropdown,
  DatePicker,
  InfoIcon,
  IconBtnTooltip
} from '@frontend/common';

import {
  ANNUAL_EVENT,
  BIRTHDAY,
} from '../../constants';

import transactionsStyles from 'components/Features/protected/Accounts/Transactions/styles.module.scss';
import styles from '../styles.module.scss';

const muiStyles = () => ({
  root: {
    whiteSpace: 'normal',
    wordBreak: 'normal',
    overflowWrap: 'anywhere'
  }
});

Step0AmountAndDate.propTypes = {
  classes: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  contributionSet: PropTypes.func.isRequired,
  contributionDateHandle: PropTypes.func.isRequired,
  contribution: PropTypes.object.isRequired,
  contributionDetails: PropTypes.object.isRequired,
  contributionEnvironmentVars: PropTypes.object.isRequired,
  nextRunDates: PropTypes.object.isRequired,
  errorGet: PropTypes.func.isRequired,
  accountSelect: PropTypes.func.isRequired,
  amountHandle: PropTypes.func.isRequired,
  addAccount: PropTypes.func.isRequired,
  removeAccount: PropTypes.func.isRequired,
  text: PropTypes.shape({
    ContributionSpecialStep0: PropTypes.shape({
      err_amount_min_max: PropTypes.func,
      lbl_add_account: PropTypes.string,
      lbl_amount: PropTypes.string,
      lbl_birthday: PropTypes.string,
      lbl_occasion: PropTypes.string,
      lbl_optional_end_date: PropTypes.string,
      lbl_remove_account: PropTypes.string,
      lbl_start_date: PropTypes.string,
      lbl_selected_account: PropTypes.string,
      text_multiple_annual_events: PropTypes.string,
      text_next_run: PropTypes.string,
      text_total: PropTypes.string,
    })
  })
};

export function Step0AmountAndDate({
  classes,
  isEditing,
  errors,
  contribution: { day1, occasion, selectedAccounts, },
  contributionDetails: { canContributeAccountList, },
  contributionEnvironmentVars: { CurrentTradeDate, MaximumOneTimeOnlineContribution, MinimumOneTimeOnlineContribution, },
  errorGet,
  contributionSet,
  contributionDateHandle,
  accountSelect,
  amountHandle,
  addAccount,
  removeAccount,
  nextRunDates,
  text: { ContributionSpecialStep0 },
}) {

  return (
    <React.Fragment>
      <div className={styles.occasionSelectionContainer}>
        <RadioGroup
          label={ContributionSpecialStep0.lbl_occasion}
          name='Occasion Type'
          onChange={(e) => contributionSet(0, 'occasion', e.target.value)}
          value={occasion}
        >
          <FormControlLabel
            control={<Radio disabled={isEditing && occasion !== BIRTHDAY.id} />}
            label={BIRTHDAY.display}
            value={BIRTHDAY.id}
          />
          <FormControlLabel
            control={<Radio disabled={isEditing && occasion !== ANNUAL_EVENT.id} />}
            label={
              <span className={styles.radioButtonLabel}>
                <div className={styles.radioButtonsLabelText}>{ANNUAL_EVENT.display}</div>
                <div className={styles.radioButtonsInfoIcon}>
                  <InfoIcon message={ContributionSpecialStep0.text_multiple_annual_events} />
                </div>
              </span>
            }
            value={ANNUAL_EVENT.id}
          />
        </RadioGroup>

        <Collapse in={occasion === ANNUAL_EVENT.id} className={styles.annualEventDateContainer}>
          <DatePicker
            clearable={false}
            error={Boolean(errorGet('day1'))}
            helperText={errorGet('day1')}
            label={ContributionSpecialStep0.lbl_start_date}
            minDate={dayjs(CurrentTradeDate)}
            maxDate={dayjs(CurrentTradeDate).add(1, 'y').subtract(1, 'd')} // eslint-disable-line
            onChange={date => contributionDateHandle(date, 'day1')}
            value={day1 ? day1 : null}
          />
          {dayjs(nextRunDates.day1).isValid() && dayjs(day1).isValid() && <small>{ContributionSpecialStep0.text_next_run} {dayjs(nextRunDates.day1).format('L')}</small>}
        </Collapse>
      </div>

      <div className={styles.step0Container}>
        {selectedAccounts.map((selectedAccount, rowIndex) => {
          return (
            <div key={rowIndex} className={occasion === BIRTHDAY.id ? styles.step0Birthday : styles.step0AnnualEvent}>
              <div className={styles.accounts}>
                <Dropdown
                  label={ContributionSpecialStep0.lbl_selected_account}
                  value={selectedAccount.accountId}
                  errorText={errorGet('account', rowIndex)}
                  onChange={value => accountSelect(value, rowIndex)}
                  options={(isEditing ? selectedAccounts : canContributeAccountList).map(account => ({
                    value: account.accountId,
                    display: `${account.beneficiary.name.toUpperCase()} ...${account.accountNumber.toString().slice(account.accountNumber.toString().length - 5)}`,
                    props: isEditing
                      ? {}
                      : {
                        disabled: (selectedAccounts.some(selAccount => selAccount.accountId === account.accountId)
                          && selectedAccounts[rowIndex].accountId !== account.accountId)
                          || account.overMaxBalance,
                      },
                  }))}
                  FormControlProps={{ style: { width: '100%', minWidth: '256px', margin: '0px', textAlign: 'left', } }}
                  MenuItemProps={{
                    classes: { root: classes.root }
                  }}
                />
              </div>

              <div className={styles.amount}>
                <CurrencyInput
                  className={styles.currencyInput}
                  errorText={errorGet('amount', rowIndex)}
                  label={ContributionSpecialStep0.lbl_amount}
                  onChange={vals => amountHandle(vals, rowIndex)}
                  style={{ margin: '0px' }}
                  value={selectedAccounts[rowIndex].contributionAmount}
                />
              </div>

              {occasion === BIRTHDAY.id &&
                <div className={styles.birthdayContainer}>
                  <div className={styles.birthday} id='birthday'>
                    {selectedAccount.beneficiary.birthday && dayjs(selectedAccount.beneficiary.birthday).format('MMM D')}
                  </div>
                  <label htmlFor='birthday' className={styles.birthdayLabel}>{ContributionSpecialStep0.lbl_birthday}</label>
                  {selectedAccount.nextBdayRunDate && dayjs(selectedAccount.nextBdayRunDate).isValid() && <small>{ContributionSpecialStep0.text_next_run} {dayjs(selectedAccount.nextBdayRunDate).format('L')}</small>}
                </div>
              }

              <div className={styles.endDateContainer}>
                <DatePicker
                  error={Boolean(errorGet('endDate', rowIndex))}
                  helperText={errorGet('endDate', rowIndex)}
                  label={ContributionSpecialStep0.lbl_optional_end_date}
                  minDate={occasion === ANNUAL_EVENT.id ? dayjs(day1).add(1, 'd') : dayjs(selectedAccount.nextBdayRunDate).add(1, 'd')}
                  onChange={date => contributionDateHandle(date, 'endDate', rowIndex)}
                  value={selectedAccount.endDate || null}
                  sx={{ margin: '0px', width: '100%' }}
                  slotProps={{ field: { clearable: true } }}
                />
              </div>

              {rowIndex !== 0 &&
                <div className={styles.removeAccount}>
                  <IconBtnTooltip
                    icon='remove_circle'
                    onClick={() => removeAccount(rowIndex)}
                    title={ContributionSpecialStep0.lbl_remove_account}
                    iconProps={{ color: 'secondary' }}
                  />
                </div>
              }
            </div>
          );
        })}

        {canContributeAccountList.length !== selectedAccounts.length && !isEditing && (
          <div className={transactionsStyles.addAccountIcon}>
            <Button
              color='secondary'
              startIcon={<Icon>add</Icon>}
              onClick={addAccount}
            >
              {ContributionSpecialStep0.lbl_add_account}
            </Button>
          </div>
        )}

        <div className={styles.totalAndErrorsContainer}>
          {errors.some(error => error.key === 'amount') &&
            <div>
              <FormHelperText
                error={true}
                style={{ margin: '0' }}
              >
                {ContributionSpecialStep0.err_amount_min_max(currencyFormatter(MinimumOneTimeOnlineContribution), currencyFormatter(MaximumOneTimeOnlineContribution))}
              </FormHelperText>
            </div>}
          <div className={styles.totalAmountStep0}>
            <div>{ContributionSpecialStep0.text_total}</div>
            <div>{currencyFormatter(selectedAccounts.map(account => account.contributionAmount).reduce((val1, val2) => val1 + val2, 0))}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default (LanguageHOC(withStyles(muiStyles)(Step0AmountAndDate)));