import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Drawer,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Typography,
} from '@mui/material';
import { IconBtnTooltip } from '@frontend/common';

import styles from './styles.module.scss';

export function LearnMoreDrawer({ open, onClose, text: { Gifting }, currentYear, annualGiftExclusionAmount, maximumBeneficiaryDeposit, maximumBenficiaryAccountValueForGift }) {

  const learnMore = () => (
    <div className={styles.learnMoreContainer}>
      <div className={styles.closeIcon}>
        <IconBtnTooltip
          icon='close'
          onClick={onClose}
        />
      </div>
      <hr className={styles.divider} />
      <h3 className={styles.learnMoreInfoHeader}>{Gifting.learn_more_title}</h3>
      <div className={styles.learnMoreInfo}>
        {Gifting.text_learn_more}
      </div>
      {Gifting.text_faq(currentYear, annualGiftExclusionAmount, maximumBeneficiaryDeposit, maximumBenficiaryAccountValueForGift).map(qa => {
        return (
          <Accordion key={qa.question}>
            <AccordionSummary
              expandIcon={<Icon>keyboard_arrow_down</Icon>}
              aria-controls='learn-more'
              id='learn-more'
            >
              <Typography>{qa.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{qa.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );

  return (
    <Drawer anchor='bottom' open={open} onClose={onClose}>
      {learnMore()}
    </Drawer>
  );
}

LearnMoreDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentYear:PropTypes.string.isRequired,
  annualGiftExclusionAmount: PropTypes.string.isRequired,
  maximumBeneficiaryDeposit: PropTypes.string.isRequired,
  maximumBenficiaryAccountValueForGift: PropTypes.string.isRequired,
  text: PropTypes.shape({
    Gifting: PropTypes.shape({
      learn_more_title: PropTypes.string,
      text_faq: PropTypes.func,
      text_learn_more: PropTypes.string,
    }),
  }),
};

export default LanguageHOC(LearnMoreDrawer);