/*
*
* PayrollContribution Constants
*
*/
export const PAYROLL_GET = 'src/components/Features/protected/PayrollContribution/PAYROLL_GET';
export const PAYROLL_UPDATE = 'src/components/Features/protected/PayrollContribution/PAYROLL_UPDATE';
export const NON_AGENT_ACCOUNT_VERIFY = 'src/components/Features/protected/PayrollContribution/NON_AGENT_ACCOUNT_VERIFY';

export const AMOUNT = 'Amount';
export const PERCENTAGE = 'Percentage';