/*
*
* UpdateAgentAddressModal Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getPreTransactionDetails,
} from 'components/Features/protected/Accounts/Transactions/actions';

import AddressCard from 'components/Features/protected/MyInfo/AddressCard';

import {
  LoadingOverlay,
  Modal
} from '@frontend/common';

import { agentGet } from 'components/Features/protected/MyInfo/actions';

const select = (state) => ({
  agent: state.myInfo.agent,
});

export class UpdateAgentAddressModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    agentGet: PropTypes.func.isRequired,
    agent: PropTypes.shape({
      AgentId: PropTypes.number.isRequired,
      Addresses: PropTypes.shape({
        Physical: PropTypes.object,
        Mailing: PropTypes.object,
        Other: PropTypes.object,
      }),
      UtahResident: PropTypes.bool.isRequired,
    }).isRequired,
    handleCancel: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    getPreTransactionDetails: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
  };

  state = {
    loading: false,
  };

  callbackOnAddressChange = () => {
    this.props.getPreTransactionDetails(this.props.match.params.accountId, this.props.match.params.groupId);
  }

  componentDidMount() {
    if (!this.props.agent.AgentId) {
      this.setState({ loading: true });
      this.props.agentGet()
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    const { loading } = this.state;

    const {
      show,
      agent: { Addresses },
      handleCancel,
      modalTitle,
    } = this.props;

    return (
      <div>
        <Modal
          show={show}
          title={modalTitle}
          onCloseModal={handleCancel}
        >
          <LoadingOverlay show={loading} indicatorHeight='15px'>
            <AddressCard
              addresses={Addresses}
              displayUnsavedChangesPrompt={() => null}
              isLoading={false}
              key='Agent_Check_Address'
              residentCheck={() => null}
              callbackOnAddressChange={this.callbackOnAddressChange}
            />
          </LoadingOverlay>
        </Modal>
      </div>

    );
  }

}

export default withRouter(connect(select, {
  agentGet,
  getPreTransactionDetails,
})(UpdateAgentAddressModal));