import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Switch,
} from '@mui/material';

import {
  LoadingOverlay,
  notificationShow,
  Dropdown,
  Modal,
  InfoIcon,
} from '@frontend/common';

import SkeletonLoader from 'src/utils/components/SkeletonLoader';

import {
  officialNotificationUpdate,
  taxFormsNotificationUpdate,
  newsletterNotificationUpdate,
  promoNotificationUpdate,
  bdayNotificationUpdate,
  utahResidentSave,
} from '../actions';

import { notificationTypes, BLOCKED } from '../constants';

import { get2FAPrecondition, getRSAPrecondition } from 'components/AppRoot/Navigation/actions';
import { protectAction } from 'utils/helpers/multifactor_handler';
import { MULTIFACTOR_TYPE } from 'components/AppRoot/Navigation/constants';

import styles from './styles.module.scss';

const select = state => ({
  agent: state.myInfo.agent,
  claims: state.session.claims,
  isAgent_AccessRole: state.session.isAgent_AccessRole,
});

export class MyInfoToggles extends React.Component {
  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    officialNotificationUpdate: PropTypes.func.isRequired,
    taxFormsNotificationUpdate: PropTypes.func.isRequired,
    newsletterNotificationUpdate: PropTypes.func.isRequired,
    promoNotificationUpdate: PropTypes.func.isRequired,
    bdayNotificationUpdate: PropTypes.func.isRequired,
    utahResidentSave: PropTypes.func.isRequired,
    get2FAPrecondition: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    agent: PropTypes.shape({
      OfficialCommunications: PropTypes.string,
      TaxForms: PropTypes.string,
      Newsletter: PropTypes.string,
      Promotional: PropTypes.string,
      BirthdayNotifications: PropTypes.string,
      UtahResident: PropTypes.bool,
    }).isRequired,
    isAgent_AccessRole: PropTypes.bool.isRequired,
    residentCheck: PropTypes.func.isRequired,
    residentModalOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    text: PropTypes.shape({
      MyInfo: PropTypes.shape({
        msg_unsaved_changes: PropTypes.string,
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
      }),
      Preferences: PropTypes.shape({
        head_utah_resident: PropTypes.string,
        head_official_communications: PropTypes.string,
        head_tax_forms: PropTypes.string,
        head_promotional: PropTypes.string,
        head_newsletter: PropTypes.string,
        head_birthday: PropTypes.string,
        lbl_official_communications: PropTypes.string,
        lbl_tax_forms: PropTypes.string,
        lbl_promotional_info: PropTypes.string,
        lbl_options: PropTypes.string,
        lbl_utah_resident: PropTypes.string,
        msg_preferences_changed: PropTypes.string,
        text_no_accounts: PropTypes.string,
        text_residency_mismatch: PropTypes.string,
        text_will_not_send_material_for_other: PropTypes.string,
        text_tax_document_online_only: PropTypes.string,
        text_utah_tax_form_changes: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    hasPrecondition: false,
    rememberedType: '',
    value: '',
    isOfficialNotificationUpdating: false,
    isTaxFormUpdating: false,
    isNewsletterUpdating: false,
    isPromotionalUpdating: false,
    isBirthdayUpdating: false,
    isSavingUtahResidency: false,
  };

  protectHandleNotificationUpdates(value, rememberedType) {
    const multiStatus = protectAction(this.props.claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      this.setState(
        { hasPrecondition: true, rememberedType, value },
        () => this.props.get2FAPrecondition().catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA) {
      this.setState(
        { hasPrecondition: true, rememberedType, value },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    }
    else {
      // just handle without 2FA protection
      this.setState({ hasPrecondition: false });
      this.handleNotificationUpdates(value, rememberedType);
    }
  }

  handleNotificationUpdates(val, type) {
    const notificationApiCall = [];
    switch (type) {
      case notificationTypes.OfficialCommunications.title:
        this.setState({ isOfficialNotificationUpdating: true });
        notificationApiCall.push(this.props.officialNotificationUpdate(val));
        break;
      case notificationTypes.TaxForms.title:
        this.setState({ isTaxFormUpdating: true });
        notificationApiCall.push(this.props.taxFormsNotificationUpdate(val));
        break;
      case notificationTypes.Newsletter.title:
        this.setState({ isNewsletterUpdating: true });
        notificationApiCall.push(this.props.newsletterNotificationUpdate(val));
        break;
      case notificationTypes.Promotional.title:
        this.setState({ isPromotionalUpdating: true });
        notificationApiCall.push(this.props.promoNotificationUpdate(val));
        break;
      case notificationTypes.BirthdayNotifications.title:
        this.setState({ isBirthdayUpdating: true });
        notificationApiCall.push(this.props.bdayNotificationUpdate(val));
        break;
      case notificationTypes.UTRESIDENT.title:
        const UtahResident = !this.props.agent.UtahResident; // eslint-disable-line
        this.setState({ isSavingUtahResidency: true });
        notificationApiCall.push(this.props.utahResidentSave(UtahResident));
        break;
      default:
        return null;
    }

    Promise.all(notificationApiCall)
      .then(() => {
        this.setState(
          {
            isOfficialNotificationUpdating: false,
            isTaxFormUpdating: false,
            isNewsletterUpdating: false,
            isPromotionalUpdating: false,
            isBirthdayUpdating: false,
            isSavingUtahResidency: false,
          },
          () => this.props.notificationShow(this.props.text.Preferences.msg_preferences_changed, 'success')
        );
      })
      .then(() => this.props.residentCheck());
  }

  componentDidMount() {
    const { agent } = this.props;
    for (const [key, value] of Object.entries(agent)) { // eslint-disable-line
      for (const [k, v] of Object.entries(notificationTypes)) {  // eslint-disable-line
        let hasBlocked = false;
        if (v.options) {
          hasBlocked = v.options.some(option => option.display === 'Please Select');
          if (key === k && value === BLOCKED && !hasBlocked) {
            v.options.push({ display: 'Please Select', value: BLOCKED });
          }
        }
      }
    }
  }

  componentDidUpdate() {
    const {
      hasPrecondition,
      isOfficialNotificationUpdating,
      isTaxFormUpdating,
      isNewsletterUpdating,
      isPromotionalUpdating,
      isBirthdayUpdating,
      isSavingUtahResidency,
      value,
      rememberedType
    } = this.state;
    const isNotUpdatingAny = !(isOfficialNotificationUpdating || isTaxFormUpdating || isNewsletterUpdating || isPromotionalUpdating || isBirthdayUpdating || isSavingUtahResidency);
    const multiStatus = protectAction(this.props.claims);
    if (hasPrecondition && multiStatus === MULTIFACTOR_TYPE.AUTH && isNotUpdatingAny) {
      this.setState({ hasPrecondition: false });
      this.handleNotificationUpdates(value, rememberedType);
    }
  }

  render() {
    const {
      isLoading,
      isAgent_AccessRole,
      agent: {
        BirthdayNotifications,
        UtahResident,
        TaxForms,
        Newsletter,
        OfficialCommunications,
        Promotional,
      },
      residentModalOpen,
      onModalClose,
      text: {
        Preferences: {
          head_birthday,
          head_newsletter,
          head_official_communications,
          head_promotional,
          head_tax_forms,
          head_utah_resident,
          lbl_official_communications,
          lbl_options,
          lbl_promotional_info,
          lbl_tax_forms,
          lbl_utah_resident,
          text_residency_mismatch,
          text_utah_tax_form_changes,
        },
      },
    } = this.props;
    const {
      isOfficialNotificationUpdating,
      isTaxFormUpdating,
      isNewsletterUpdating,
      isPromotionalUpdating,
      isBirthdayUpdating,
      isSavingUtahResidency,
    } = this.state;
    return (
      <React.Fragment>
        {isAgent_AccessRole &&
          <div className={styles.container}>

            <div>
              <div className={styles.row}>
                <div className={styles.header}>{head_official_communications}</div>
                <div className={styles.header_description}>{lbl_official_communications}</div>
              </div>
              <div className={styles.dropDownStyle}>
                <SkeletonLoader isLoading={isLoading}>
                  <LoadingOverlay show={isOfficialNotificationUpdating} width='250px'>
                    <Dropdown
                      FormControlProps={{ variant: 'filled' }}
                      label={lbl_options}
                      onChange={value => this.protectHandleNotificationUpdates(value, notificationTypes.OfficialCommunications.title)}
                      options={notificationTypes.OfficialCommunications.options}
                      value={OfficialCommunications}
                    />
                  </LoadingOverlay>
                </SkeletonLoader>
              </div>
            </div>

            <div className={styles.eachDropDown}>
              <div className={styles.row}>
                <div className={styles.header}>{head_tax_forms}</div>
                <div className={styles.header_description}>{lbl_tax_forms}</div>
              </div>
              <div className={styles.dropDownStyle}>
                <SkeletonLoader isLoading={isLoading}>
                  <LoadingOverlay show={isTaxFormUpdating} width='250px'>
                    <Dropdown
                      FormControlProps={{ variant: 'filled' }}
                      label={lbl_options}
                      onChange={value => this.protectHandleNotificationUpdates(value, notificationTypes.TaxForms.title)}
                      options={notificationTypes.TaxForms.options}
                      value={TaxForms}
                    />
                  </LoadingOverlay>
                </SkeletonLoader>
              </div>
            </div>

            <hr className={styles.divider} />

            <div className={styles.eachDropDown}>
              <div className={styles.row}>
                <div className={styles.header}>{head_newsletter}</div>
                <div className={styles.header_description} />
              </div>
              <div className={styles.dropDownStyle}>
                <SkeletonLoader isLoading={isLoading}>
                  <LoadingOverlay show={isNewsletterUpdating} width='250px'>
                    <Dropdown
                      FormControlProps={{ variant: 'filled' }}
                      label={lbl_options}
                      onChange={value => this.protectHandleNotificationUpdates(value, notificationTypes.Newsletter.title)}
                      options={notificationTypes.Newsletter.options}
                      value={Newsletter}
                    />
                  </LoadingOverlay>
                </SkeletonLoader>
              </div>
            </div>

            <div className={styles.eachDropDown}>
              <div className={styles.row}>
                <div className={styles.header}>{head_promotional}</div>
                <div className={styles.header_description}>{lbl_promotional_info}</div>
              </div>
              <div className={styles.dropDownStyle}>
                <SkeletonLoader isLoading={isLoading}>
                  <LoadingOverlay show={isPromotionalUpdating} width='250px'>
                    <Dropdown
                      FormControlProps={{ variant: 'filled' }}
                      label={lbl_options}
                      onChange={value => this.protectHandleNotificationUpdates(value, notificationTypes.Promotional.title)}
                      options={notificationTypes.Promotional.options}
                      value={Promotional}
                    />
                  </LoadingOverlay>
                </SkeletonLoader>
              </div>
            </div>

            <div className={styles.eachDropDown}>
              <div className={styles.row}>
                <div className={styles.header}>{head_birthday}</div>
                <div className={styles.header_description} />
              </div>
              <div className={styles.dropDownStyle}>
                <SkeletonLoader isLoading={isLoading}>
                  <LoadingOverlay show={isBirthdayUpdating} width='250px'>
                    <Dropdown
                      FormControlProps={{ variant: 'filled' }}
                      label={lbl_options}
                      onChange={value => this.protectHandleNotificationUpdates(value, notificationTypes.BirthdayNotifications.title)}
                      options={notificationTypes.BirthdayNotifications.options}
                      value={BirthdayNotifications}
                    />
                  </LoadingOverlay>
                </SkeletonLoader>
              </div>
            </div>

            <div className={styles.row} style={{ paddingTop: '15px' }}>
              <div className={`${styles.col} ${styles.label}`} style={{ display: 'flex' }}>
                <div style={{ alignSelf: 'center' }}>{lbl_utah_resident}</div>
                <div><InfoIcon message={text_utah_tax_form_changes} /></div>
              </div>
              <div
                className={`${styles.col} ${styles.toggle}`}
                style={{ cursor: 'pointer' }}
                onChange={value => this.protectHandleNotificationUpdates(value, notificationTypes.UTRESIDENT.title)}
              >
                <SkeletonLoader isLoading={isLoading}>
                  <LoadingOverlay show={isSavingUtahResidency}>
                    <Switch
                      value='UtahResident'
                      checked={UtahResident}
                    />
                  </LoadingOverlay>
                </SkeletonLoader>
              </div>
            </div>

          </div>
        }
        <Modal
          onCloseModal={onModalClose}
          show={residentModalOpen}
          title={head_utah_resident}
        >
          {text_residency_mismatch}
        </Modal>
      </React.Fragment>
    );
  }

}

export default connect(select, {
  notificationShow,
  officialNotificationUpdate,
  taxFormsNotificationUpdate,
  newsletterNotificationUpdate,
  promoNotificationUpdate,
  bdayNotificationUpdate,
  utahResidentSave,
  get2FAPrecondition,
  getRSAPrecondition
})(LanguageHOC(MyInfoToggles));
