/*
*
* WithdrawalRequests Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { isEqual, cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  getPreTransactionDetails,
  requestWithdrawal,
} from 'components/Features/protected/Accounts/Transactions/actions';
import { getNotifications, } from 'components/AppRoot/Navigation/actions';
import {
  Button,
  Paper,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from '@mui/material';
import {
  Breadcrumbs,
  Card,
  InfoIcon,
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';
import Step1Amount from './Step1Amount';
import Step2Recipient from './Step2Recipient';
import Step3Terms from './Step3Terms';
import Step4Print from './Step4Print';
import { tabletWidth } from 'utils/config/_sassconfig.scss';

import styles from './styles.module.scss';

const select = (state) => ({
  accountList: state.accounts.accountList,
  preTransactionDetails: state.transactions.preTransactionDetails,
});

export class WithdrawalRequests extends React.Component {

  static propTypes = {
    getNotifications: PropTypes.func.isRequired,
    getPreTransactionDetails: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    preTransactionDetails: PropTypes.shape({
      AccountNumber: PropTypes.number,
      AgentId: PropTypes.number,
      BeneficiaryId: PropTypes.number,
      OptionName: PropTypes.string,
      beneficiaryName: PropTypes.string,
      totalAvailableForWithdrawal: PropTypes.string,
    }).isRequired,
    requestWithdrawal: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Accounts: PropTypes.shape({
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
      }),
      AccountDetails: PropTypes.shape({
        nav_path: PropTypes.func,
        nav_title: PropTypes.string,
      }),
      WithdrawalRequests: PropTypes.shape({
        msg_unsaved_changes: PropTypes.string,
        btn_back: PropTypes.string,
        btn_cancel: PropTypes.string,
        head_account_details: PropTypes.string,
        head_amount: PropTypes.string,
        head_recipient: PropTypes.string,
        head_terms: PropTypes.string,
        lbl_confirmation: PropTypes.string,
        msg_withdrawal_requested: PropTypes.string,
        nav_title: PropTypes.string,
        text_account_num: PropTypes.string,
        text_available_balance_info: PropTypes.array,
        text_available_for_withdrawal: PropTypes.string,
        text_bene: PropTypes.string,
        text_current_opt: PropTypes.string,
      }),
    }).isRequired,
  }

  state = {
    activeStep: 0,
    loading: false,
    steps: [
      this.props.text.WithdrawalRequests.head_amount,
      this.props.text.WithdrawalRequests.head_recipient,
      this.props.text.WithdrawalRequests.head_terms,
      this.props.text.WithdrawalRequests.lbl_confirmation,
    ],
    withdrawalInfo: {
      AccountGroupId: this.props.match.params.groupId,
      AccountId: this.props.match.params.accountId,
      AgentId: this.props.preTransactionDetails.AgentId,
      Amount: '',
      BeneficiaryBirthdate: '',
      BeneficiaryId: this.props.preTransactionDetails.BeneficiaryId,
      CreatedDate: dayjs().toDate(), // API requires this property
      IsFullBalance: '',
      IsRecurring: false, // API requires this property
      LastModifiedDate: dayjs().toDate(), // API requires this property
      PayeeAddressLine1: '',
      PayeeAddressLine2: '',
      PayeeCity: '',
      PayeeState: '',
      PayeeZipCode: '',
      SchoolDepartment: '',
      SchoolId: '',
      SchoolName: '',
      SessionIdentifier: 'API requires this property', // literally can say anything here
      StudentNumber: '',
    },
    withdrawalInfoScreenshot: {
      AccountGroupId: this.props.match.params.groupId,
      AccountId: this.props.match.params.accountId,
      AgentId: this.props.preTransactionDetails.AgentId,
      Amount: '',
      BeneficiaryBirthdate: '',
      BeneficiaryId: this.props.preTransactionDetails.BeneficiaryId,
      CreatedDate: dayjs().toDate(), // API requires this property
      IsFullBalance: '',
      IsRecurring: false, // API requires this property
      LastModifiedDate: dayjs().toDate(), // API requires this property
      PayeeAddressLine1: '',
      PayeeAddressLine2: '',
      PayeeCity: '',
      PayeeState: '',
      PayeeZipCode: '',
      SchoolDepartment: '',
      SchoolId: '',
      SchoolName: '',
      SessionIdentifier: 'API requires this property', // literally can say anything here
      StudentNumber: '',
    }
  };

  displayUnsavedChangesPrompt = () => {
    return !this.state.loading && !isEqual(this.state.withdrawalInfo, this.state.withdrawalInfoScreenshot);
  }

  backClickHandle = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
    window.scrollTo(0, 0);
  }

  nextClickHandle = () => {
    if (this.state.activeStep === 2) {
      this.requestWithdrawal();
    }
    else {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
    window.scrollTo(0, 0);
  }

  renderBackButton = () => {
    return (
      <Button
        onClick={this.backClickHandle}
        variant='text'
        color='primary'
      >
        {this.props.text.WithdrawalRequests.btn_back}
      </Button>
    );
  }

  renderCancelButton = () => {
    return (
      <Button
        onClick={() => this.props.history.goBack()}
        variant='text'
        color='primary'
      >
        {this.props.text.WithdrawalRequests.btn_cancel}
      </Button>
    );
  }

  requestWithdrawal = () => {
    this.setState({ loading: true });
    this.props.requestWithdrawal(this.state.withdrawalInfo)
      .then((response) => {
        const data = response.payload.data;
        const withdrawalInfo = { ...this.state.withdrawalInfo, CreatedDate: data.CreatedDate };
        this.setState({
          activeStep: this.state.activeStep + 1,
          loading: false,
          withdrawalInfo,
          withdrawalInfoScreenshot: cloneDeep(withdrawalInfo),
        });
        this.props.notificationShow(this.props.text.WithdrawalRequests.msg_withdrawal_requested, 'success');
        this.props.getNotifications(); // refreshes app notifications
        window.scrollTo(0, 0);
      })
      .catch(() => this.setState({ loading: false }));
  }

  setWithdrawalInfo = info => {
    this.setState({
      withdrawalInfo: {
        ...this.state.withdrawalInfo,
        ...info,
      }
    });
  }

  stepClickHandle = (index) => {
    this.setState({ activeStep: index });
  }

  stepperContentGet = () => {
    switch (this.state.activeStep) {
      case 0: return (
        <Step1Amount
          nextClickHandle={this.nextClickHandle}
          renderCancelButton={this.renderCancelButton}
          setWithdrawalInfo={this.setWithdrawalInfo}
          withdrawalInfo={this.state.withdrawalInfo}
        />
      );
      case 1: return (
        <Step2Recipient
          beneficiaryName={this.props.preTransactionDetails.beneficiaryName}
          nextClickHandle={this.nextClickHandle}
          renderBackButton={this.renderBackButton}
          renderCancelButton={this.renderCancelButton}
          setWithdrawalInfo={this.setWithdrawalInfo}
          withdrawalInfo={this.state.withdrawalInfo}
        />
      );
      case 2: return (
        <Step3Terms
          activeStep={this.state.activeStep}
          beneficiaryName={this.props.preTransactionDetails.beneficiaryName}
          loading={this.state.loading}
          nextClickHandle={this.nextClickHandle}
          renderBackButton={this.renderBackButton}
          renderCancelButton={this.renderCancelButton}
          setWithdrawalInfo={this.setWithdrawalInfo}
          withdrawalInfo={this.state.withdrawalInfo}
        />
      );
      case 3: return (
        <Step4Print
          activeStep={this.state.activeStep}
          beneficiaryName={this.props.preTransactionDetails.beneficiaryName}
          withdrawalInfo={this.state.withdrawalInfo}
        />
      );
      default: return;
    }
  }

  componentDidMount() {
    const { accountId, groupId } = this.props.match.params;
    this.setState({ loading: true });

    if (Object.keys(getPreTransactionDetails).length === 0) {
      this.props.getPreTransactionDetails(accountId, groupId)
        .then(() => {
          this.setState({ loading: false });
        });
    }
  }

  render() {
    const { activeStep, loading, steps } = this.state;
    const {
      match, preTransactionDetails,
      text: { Accounts, AccountDetails, WithdrawalRequests }
    } = this.props;

    return (
      loading ?
        <div className={styles.loader}>
          <LoadingOverlay show={loading} indicatorHeight='15px' width='100%' />
        </div>
        :
        <React.Fragment>
          <div className={`${styles.breadcrumbs} hideOnPrint`}>
            <Breadcrumbs
              crumbs={[
                {
                  title: Accounts.nav_title,
                  link: Accounts.nav_path,
                },
                {
                  title: AccountDetails.nav_title,
                  link: AccountDetails.nav_path(match.params.accountId),
                },
                {
                  title: WithdrawalRequests.nav_title,
                },
              ]}
            />
          </div>
          <Card title={WithdrawalRequests.head_account_details}>
            <div className={styles.accountDetailsContainer}>
              <h2 className={styles.detailsTitle}>{WithdrawalRequests.text_bene}</h2>
              <p>{preTransactionDetails.beneficiaryName}</p>

              <h2 className={styles.detailsTitle}>{WithdrawalRequests.text_account_num}</h2>
              <p>{preTransactionDetails.AccountNumber}</p>

              <h2 className={styles.detailsTitle}>{WithdrawalRequests.text_current_opt}</h2>
              <p>{preTransactionDetails.OptionName}</p>

              <div className={styles.availableForWithdrawal}>
                <h2 className={styles.detailsTitle}>{WithdrawalRequests.text_available_for_withdrawal}</h2>
                <InfoIcon message={<div>{WithdrawalRequests.text_available_balance_info.map(line => <p key={line}>{line}</p>)}</div>} />
              </div>
              <p>{preTransactionDetails.totalAvailableForWithdrawal}</p>
            </div>
          </Card>
          <br />
          <Paper className={styles.container}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel={window.innerWidth > parseInt(tabletWidth)} // Only uses alternativeLabel for desktop and tablet
              orientation={window.innerWidth < parseInt(tabletWidth) ? 'vertical' : 'horizontal'} // Vertical orientation for mobile
              className='hideOnPrint'
            >
              {steps.map((label, index) => (
                activeStep === 3 ? // on confirmation step, disables other steps from being clickable
                  <Step key={label} disabled={activeStep === 3}>
                    <StepButton onClick={() => this.stepClickHandle(index)}>
                      <StepLabel>{label}</StepLabel>
                    </StepButton>
                  </Step>
                  :
                  <Step key={label}>
                    <StepButton onClick={() => this.stepClickHandle(index)}>
                      <StepLabel>{label}</StepLabel>
                    </StepButton>
                  </Step>
              ))}
            </Stepper>

            <div className={styles.stepperContent}>
              {this.stepperContentGet()}
            </div>
          </Paper>

          <Prompt
            message={WithdrawalRequests.msg_unsaved_changes}
            when={this.displayUnsavedChangesPrompt()}
          />
        </React.Fragment>
    );
  }
}


export default withRouter(connect(select, {
  getNotifications,
  getPreTransactionDetails,
  notificationShow,
  requestWithdrawal,
})(LanguageHOC(WithdrawalRequests)));
