/*
*
* PayrollContribution Reducers
*
*/
import { cloneDeep } from 'lodash';
import {
  PAYROLL_GET, 
  PAYROLL_UPDATE,
} from './constants';

const initialState = {
  payrollContributionData: {},
};

function PayrollContributionsReducer(state = initialState, action) {
  
  const newState = cloneDeep(state);

  switch (action.type) {
    
    case PAYROLL_GET:
    case PAYROLL_UPDATE: {
      newState.payrollContributionData = action.payload.data;
      return newState;
    }

    default:
      return state;
  }

}

export default PayrollContributionsReducer;