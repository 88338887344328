import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const createMarkup = (body) => {
  return { __html: body };
};

// this will have a alternate text input for new api messages and the old way just passing children

export function Banner(props) {
  if (props.show)
    return (
      <div className={styles.banner}>
        <div className={styles.bannerMessages}>
          {props.body
            ?
            <div dangerouslySetInnerHTML={createMarkup(props.body)} />
            :
            props.children
          }
        </div>
      </div>
    );
  else
    return null;
}

Banner.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  body: PropTypes.string,
};

export default Banner;
