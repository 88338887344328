import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import { IconBtnTooltip, } from '@frontend/common';

import {
  Button,
  Icon
} from '@mui/material';

import styles from './styles.module.scss';

export const PaperlessBanner = ({ show, history, text: { MyInfo }, onCloseBanner }) => {
  return (
    show &&
    <div className={styles.container}>
      <div className={styles.paperlessContainer}>
        <div className={styles.paperless}>
          <Icon className={styles.paperlessIcon}>settings</Icon>
          <span className={styles.paperlessLabel}>{MyInfo.label_paperless}</span>|<span className={styles.paperlessText}>{MyInfo.msg_paperless}</span>
        </div>
        <div className={styles.buttonSettings}>
          <Button
            color='inherit'
            variant='outlined'
            style={{ borderColor: 'white' }}
            onClick={() => history.push(MyInfo.nav_path)}
          >
            {MyInfo.nav_title}
          </Button>
        </div>
      </div>
      <div>
        <IconBtnTooltip
          buttonProps={{ style: { color: 'inherit' } }}
          icon={'close'}
          onClick={(event) => {
            event.stopPropagation();
            onCloseBanner();
          }}
          onFocus={event => event.stopPropagation()}
        />
      </div>
    </div>
  );
};

PaperlessBanner.propTypes = {
  show: PropTypes.bool,
  history: PropTypes.object.isRequired,
  text: PropTypes.shape({
    MyInfo: PropTypes.object.isRequired
  }).isRequired,
  onCloseBanner: PropTypes.func.isRequired,
};

export default withRouter(LanguageHOC(PaperlessBanner));