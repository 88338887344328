/*
*
* AddNonAgentAccount Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import * as validator from 'utils/helpers/form_validation';
import dayjs from 'dayjs';
import {
  FormHelperText,
} from '@mui/material';
import {
  Modal,
  DatePicker,
  BaseNumberInput,
} from '@frontend/common';
import {
  verifyNonAgentAccount,
} from '../actions';

import styles from './styles.module.scss';


export class AddNonAgentAccount extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    verifyNonAgentAccount: PropTypes.func.isRequired,
    handleAddNonAgentAccount: PropTypes.func.isRequired,
    text: PropTypes.shape({
      PayrollContribution: PropTypes.shape({
        btn_add: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_next: PropTypes.string,
        err_date_past_today: PropTypes.string,
        err_invalid_account: PropTypes.string,
        err_required: PropTypes.string,
        head_account_number: PropTypes.string,
        lbl_birth_date: PropTypes.string,
        lbl_my529_account: PropTypes.string,
        text_add_account: PropTypes.string,
        title_add_account: PropTypes.string,
        title_bene_name: PropTypes.string,
      })
    })
  };

  state = {
    accountToVerify: {
      accountNumber: '',
      beneficiaryBirthdate: ''
    },
    isAccountVerified: false,
    verifiedAccount: {},
    formErrors: {},
    apiErrors: [],
    isValidating: false,
    verificationFailed: false,
  }

  onInputChange = (name, value) => {
    this.setState({
      accountToVerify: {
        ...this.state.accountToVerify,
        [name]: value,
      },
      formErrors: {
        ...this.state.formErrors,
        [name]: '',
      },
      verificationFailed: false,
    });
  }

  onAccountNumberChange = ({ value }) => {
    // clap the account number to 9 digits max
    value = parseInt(value.toString().slice(0, 9));
    this.onInputChange('accountNumber', value);
  }

  formValidate = () => {
    const { PayrollContribution } = this.props.text;
    const { accountToVerify: { accountNumber, beneficiaryBirthdate }, apiErrors } = this.state;
    const formErrors = {};

    if (!accountNumber) {
      formErrors.accountNumber = PayrollContribution.err_required;
    }

    formErrors.beneficiaryBirthdate = validator.calendarValidator(beneficiaryBirthdate);
    if (dayjs(beneficiaryBirthdate).isAfter(dayjs(), 'day')) {
      formErrors.beneficiaryBirthdate = PayrollContribution.err_date_past_today;
    }

    if (apiErrors.length > 0) {
      apiErrors.forEach(error => formErrors[error.Field] = error.Message);
    }

    this.setState({ apiErrors: [], formErrors });
    const hasErrors = Object.keys(formErrors).some(field => formErrors[field] !== '');

    return !hasErrors;
  }

  closeForm = () => {
    this.props.onClose();
    this.setState({
      accountToVerify: {
        accountNumber: '',
        beneficiaryBirthdate: ''
      },
      isAccountVerified: false,
      verifiedAccount: {},
      formErrors: {},
      apiErrors: [],
      isValidating: false,
      verificationFailed: false,
    });
  }

  onVerifyAccount = () => {
    const {
      verifyNonAgentAccount,
    } = this.props;
    const { accountToVerify } = this.state;

    const isValid = this.formValidate();

    if (isValid) {
      this.setState({ isValidating: true });
      verifyNonAgentAccount(accountToVerify)
        .then(({ payload: { data } }) => {
          if (!data) {
            this.setState({ isValidating: false, verificationFailed: true });
          }
          else {
            this.setState({ isValidating: false, isAccountVerified: true, verifiedAccount: data });
          }
        })
        .catch(errors => {
          this.setState({
            apiErrors: errors.payload.data,
            isValidating: false
          }, () => this.formValidate());
        });
    }
  }

  renderAccountVerification = () => {
    const { text: { PayrollContribution } } = this.props;
    const { accountToVerify: { accountNumber, beneficiaryBirthdate }, formErrors, verificationFailed } = this.state;

    return (
      <React.Fragment>
        <p className={styles.verifyAccountText}>
          {PayrollContribution.text_add_account}
        </p>

        <div className={styles.verifyInputs}>
          <BaseNumberInput
            label={PayrollContribution.lbl_my529_account}
            error={Boolean(formErrors.accountNumber)}
            errorText={formErrors.accountNumber}
            variant='filled'
            value={accountNumber}
            onChange={this.onAccountNumberChange}
            inputProps={{
              allowNegative: false,
              maxLength: '20',
            }}
            style={{ width: '100%', }}
          />
          <div className={styles.emptyDiv} />
          <DatePicker
            error={Boolean(formErrors.beneficiaryBirthdate)}
            fullWidth
            helperText={formErrors.beneficiaryBirthdate}
            variant='filled'
            label={PayrollContribution.lbl_birth_date}
            maxDate={dayjs()}
            onChange={e => this.onInputChange('beneficiaryBirthdate', e ? dayjs(e).format('L') : e)}
            openTo='year'
            value={beneficiaryBirthdate || null}
          />
        </div>

        {verificationFailed && <FormHelperText error={Boolean(verificationFailed)} style={{ textAlign: 'center' }}>
          {PayrollContribution.err_invalid_account}
        </FormHelperText>}
      </React.Fragment>
    );
  }

  renderVerifiedAccount = () => {
    const { text: { PayrollContribution } } = this.props;
    const { AccountNumber, BeneficiaryName } = this.state.verifiedAccount;

    return (
      <React.Fragment>
        <div className={styles.verifiedAccountDetails}>
          <div className={styles.verifyTitle}>{PayrollContribution.head_account_number}</div>
          <div className={styles.verifyDetail}>{AccountNumber}</div>
        </div>
        <div className={styles.verifiedAccountDetails}>
          <div className={styles.verifyTitle}>{PayrollContribution.title_bene_name}</div>
          <div className={styles.verifyDetail}>{BeneficiaryName}</div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      open,
      text: { PayrollContribution }
    } = this.props;
    const { isValidating, isAccountVerified, verifiedAccount } = this.state;

    const actionButtons = this.state.isAccountVerified ?
      [
        {
          label: PayrollContribution.btn_add,
          action: () => {
            this.props.handleAddNonAgentAccount(verifiedAccount);
            this.closeForm();
          },
          buttonType: 'contained',
        },
      ] :
      [
        {
          label: PayrollContribution.btn_next,
          loading: isValidating,
          action: () => this.onVerifyAccount(),
          buttonType: 'contained',
        }
      ];

    return (
      <Modal
        title={PayrollContribution.title_add_account}
        show={open}
        onCloseModal={this.closeForm}
        modal={false}
        maxWidth='md'
        fullWidth={true}
        actionButtons={actionButtons}
        includeLeftCancelBtn
      >
        {!isAccountVerified ? this.renderAccountVerification() : this.renderVerifiedAccount()}
      </Modal>
    );
  }
}

export default connect(null, { verifyNonAgentAccount, })(LanguageHOC(AddNonAgentAccount));