/*
*
* WithdrawalDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Card,
  InfoIcon,
  currencyFormatter,
} from '@frontend/common';
import styles from './styles.module.scss';

const select = (state) => ({
  redState: state,
  withdrawalSubmitted: state.transactions.withdrawalSubmitted,
  preTransactionDetails: state.transactions.preTransactionDetails,
  selectedTransaction: state.transactions.selectedTransaction,
});

export class WithdrawalDetails extends React.Component {

  static propTypes = {
    isProportional: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    selectedTransaction: PropTypes.shape({
      Amount: PropTypes.number
    }),
    preTransactionDetails: PropTypes.shape({
      AccountType: PropTypes.string.isRequired,
      AccountNumber: PropTypes.number.isRequired,
      totalAvailableForWithdrawal: PropTypes.string.isRequired,
      totalAvailableForWithdrawalNum: PropTypes.number.isRequired,
      OptionName: PropTypes.string.isRequired,
      agentName: PropTypes.string.isRequired,
      beneficiaryName: PropTypes.string.isRequired,
      groupAccounts: PropTypes.arrayOf(PropTypes.shape({
        AccountId: PropTypes.number.isRequired,
        availableForWithdrawal: PropTypes.string.isRequired,
        availableForWithdrawalNum: PropTypes.number.isRequired,
        marketValue: PropTypes.string.isRequired,
      })),
    }).isRequired,
    withdrawalSubmitted: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Withdrawals: PropTypes.shape({
        msg_available_balance: PropTypes.func,
      })
    }).isRequired,
  };

  state = {
    isEditing: window.location.href.includes('edit'),
    pendingAmount: 0,
  };

  componentDidMount() {
    this.setState({ pendingAmount: this.props.selectedTransaction.Amount });
  }

  render() {
    const { isProportional, loading, preTransactionDetails, withdrawalSubmitted, text, } = this.props;
    const { isEditing, pendingAmount } = this.state;

    const showPendingAmount = (isEditing && !withdrawalSubmitted);

    const sourceAccountId = parseInt(this.props.match.params.accountId);
    const groupAvailableForWithdrawal = currencyFormatter(preTransactionDetails.totalAvailableForWithdrawalNum + (showPendingAmount ? pendingAmount : 0)); // using available for withdrawal
    const currentAccount = this.props.preTransactionDetails.groupAccounts.find(account => account.AccountId === sourceAccountId);
    let accountMarketValue, accountAvailableForWithdrawal;
    if (currentAccount) {
      accountMarketValue = currentAccount.marketValue;
      accountAvailableForWithdrawal = currencyFormatter(currentAccount.availableForWithdrawalNum + (showPendingAmount ? pendingAmount : 0)); // using available for withdrawal
    }
    else {
      accountMarketValue = 0;
      accountAvailableForWithdrawal = 0;
    }

    return (
      <div className={`${styles.container} hideOnPrint`}>
        {isProportional ?
          <Card
            className={styles.accountDetailsCard}
            title='Account Details'
          >
            <div className={styles.cardContentContainerProportional}>
              <div className={styles.accountOwner}>
                <div className={styles.detailsTitle}>Account Owner</div>
                {!loading && preTransactionDetails.agentName}
              </div>
              <div className={styles.beneficiary}>
                <div className={styles.detailsTitle}>Beneficiary</div>
                {!loading && preTransactionDetails.beneficiaryName}
              </div>
              <div className={styles.acctNum}>
                <div className={styles.detailsTitle}>Account Numbers</div>
                {!loading && preTransactionDetails.groupAccounts.map(account => <div key={account.AccountId}>{account.AccountNumber}</div>)}
              </div>
              <div className={styles.acctType}>
                <div className={styles.detailsTitle}>Account Type</div>
                {!loading && preTransactionDetails.AccountType}
              </div>
              <div className={styles.groupAvailableBalance}>
                <span className={styles.groupAvailableBalanceInfo}>
                  <div className={styles.detailsTitle}>Total Available For Withdrawal</div>
                  <InfoIcon message={text.Withdrawals.msg_available_balance()} />
                </span>
                {!loading && groupAvailableForWithdrawal}
              </div>
            </div>
          </Card>
          :
          <Card
            className={styles.accountDetailsCard}
            title='Account Details'
          >
            <div className={styles.cardContentContainerNonProportional}>
              <div className={styles.accountOwner}>
                <div className={styles.detailsTitle}>Account Owner</div>
                {!loading && preTransactionDetails.agentName}
              </div>
              <div className={styles.beneficiary}>
                <div className={styles.detailsTitle}>Beneficiary</div>
                {!loading && preTransactionDetails.beneficiaryName}
              </div>
              <div className={styles.acctNum}>
                <div className={styles.detailsTitle}>Account Number</div>
                {!loading && preTransactionDetails.AccountNumber}
              </div>
              <div className={styles.currentOpt}>
                <div className={styles.detailsTitle}>Current Option</div>
                {!loading && preTransactionDetails.OptionName}
              </div>
              <div className={styles.acctType}>
                <div className={styles.detailsTitle}>Account Type</div>
                {!loading && preTransactionDetails.AccountType}
              </div>
              <div className={styles.marketVal}>
                <div className={styles.detailsTitle}>Market Value</div>
                {!loading && accountMarketValue}

              </div>
              <div className={styles.availableBalance}>
                <div className={styles.availableBalanceInfo}>
                  <div className={styles.detailsTitle}>Available For Withdrawal</div>
                  <InfoIcon message={text.Withdrawals.msg_available_balance()} />
                </div>
                <div className={!accountAvailableForWithdrawal ? 'WithdrawalDetails_availableBalanceIsZero' : ''}>{!loading && accountAvailableForWithdrawal}</div>
              </div>
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(connect(select, {})(LanguageHOC(WithdrawalDetails)));