/*
 *
 * Step0Amount Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Icon,
  FormHelperText,
  Button,
} from '@mui/material';
import { withStyles, } from '@mui/styles';
import {
  CurrencyInput,
  currencyFormatter,
  Dropdown,
  IconBtnTooltip,
} from '@frontend/common';

import transactionsStyles from 'components/Features/protected/Accounts/Transactions/styles.module.scss';
import styles from '../styles.module.scss';

const muiStyles = () => ({
  root: {
    whiteSpace: 'normal',
    wordBreak: 'normal',
    overflowWrap: 'anywhere'
  }
});

Step0Amount.propTypes = {
  classes: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  selectedAccounts: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  contributionDetails: PropTypes.object.isRequired,
  contributionEnvironmentVars: PropTypes.object.isRequired,
  errorGet: PropTypes.func.isRequired,
  accountSelect: PropTypes.func.isRequired,
  amountHandle: PropTypes.func.isRequired,
  removeAccount: PropTypes.func.isRequired,
  addAccount: PropTypes.func.isRequired,
  text: PropTypes.shape({
    ContributionOneTimeStep0: PropTypes.shape({
      err_amount_min_max: PropTypes.func,
      lbl_add_account: PropTypes.string,
      lbl_amount: PropTypes.string,
      lbl_remove_account: PropTypes.string,
      lbl_selected_account: PropTypes.string,
      text_total: PropTypes.string,
    })
  })
};

export function Step0Amount({
  classes,
  isEditing,
  selectedAccounts,
  errors,
  contributionDetails,
  contributionEnvironmentVars,
  errorGet,
  accountSelect,
  amountHandle,
  removeAccount,
  addAccount,
  text: { ContributionOneTimeStep0 },
}) {
  return (
    <React.Fragment>
      {selectedAccounts.map((selectedAccount, rowIndex) => {
        return (
          <div className={styles.step0AccountsContainer} key={rowIndex}>
            <div className={styles.accountListDropdown}>
              <Dropdown
                label={ContributionOneTimeStep0.lbl_selected_account}
                value={selectedAccount.accountId}
                errorText={errorGet('account', rowIndex)}
                onChange={(value) => accountSelect(value, rowIndex)}
                options={(isEditing ? selectedAccounts : contributionDetails.canContributeAccountList).map(account => ({
                  value: account.accountId,
                  display: `${account.beneficiary.name.toUpperCase()} ...${account.accountNumber.toString().slice(account.accountNumber.toString().length - 5)}`,
                  props: {
                    disabled: (selectedAccounts.some(selAccount => selAccount.accountId === account.accountId)
                      && selectedAccounts[rowIndex].accountId !== account.accountId)
                      || account.overMaxBalance,
                  },
                }))}
                FormControlProps={{ style: { width: '100%', minWidth: '256px', textAlign: 'left' } }}
                MenuItemProps={{
                  classes: { root: classes.root }
                }}
              />
            </div>

            <div className={styles.amountInput}>
              <CurrencyInput
                className={styles.currencyInput}
                errorText={errorGet('amount', rowIndex)}
                label={ContributionOneTimeStep0.lbl_amount}
                onChange={(vals) => amountHandle(vals, rowIndex)}
                value={selectedAccount.contributionAmount}
                style={{ width: '100%' }}
              />
            </div>

            {rowIndex !== 0 &&
              <div className={styles.removeAccountIcon}>
                <IconBtnTooltip
                  icon='remove_circle'
                  onClick={() => removeAccount(rowIndex)}
                  title={ContributionOneTimeStep0.lbl_remove_account}
                  iconProps={{ color: 'secondary' }}
                />
              </div>
            }
          </div>
        );
      })}

      {contributionDetails.canContributeAccountList.length !== selectedAccounts.length && !isEditing &&
        <div className={transactionsStyles.addAccountIcon}>
          <Button
            color='secondary'
            startIcon={<Icon>add</Icon>}
            onClick={addAccount}
          >
            {ContributionOneTimeStep0.lbl_add_account}
          </Button>
        </div>
      }

      <div className={styles.totalAndErrorsContainer}>
        {errors.some(error => error.key === 'amount') &&
          <div>
            <FormHelperText
              error={true}
              style={{ margin: '0' }}
            >
              {ContributionOneTimeStep0.err_amount_min_max(currencyFormatter(contributionEnvironmentVars.MinimumOneTimeOnlineContribution), currencyFormatter(contributionEnvironmentVars.MaximumOneTimeOnlineContribution))}
            </FormHelperText>
          </div>}
        <div className={styles.totalAmountStep0}>
          <div>{ContributionOneTimeStep0.text_total}</div>
          <div>{currencyFormatter(selectedAccounts.map(account => account.contributionAmount).reduce((val1, val2) => val1 + val2, 0))}</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default (LanguageHOC(withStyles(muiStyles)(Step0Amount)));