/*
*
* ResendEmail Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { resendEmail } from 'components/AppRoot/Navigation/actions';
import FormWrapper from '../FormWrapper';
import { Button } from '@mui/material';
import { notificationShow } from '@frontend/common';

import styles from './styles.module.scss';

const select = (state) => ({
  registrationInfo: state.session.registrationInfo,
  supportNumber: state.static.environmentVars.SupportPhoneNumber,
});


export class ResendEmail extends React.Component {
  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    registrationInfo: PropTypes.object.isRequired,
    resendEmail: PropTypes.func.isRequired,
    supportNumber: PropTypes.string.isRequired,
    text: PropTypes.shape({
      Home: PropTypes.shape({
        nav_path: PropTypes.string
      }),
      ResendEmail: PropTypes.shape({
        btn_resend_email: PropTypes.string,
        head_confirm_email: PropTypes.string,
        msg_email_resent: PropTypes.string,
        text_check_inbox: PropTypes.string,
        text_support_phone: PropTypes.func,
      }),
    }).isRequired,
  };

  emailResend = () => {
    const { registrationInfo, text: { ResendEmail } } = this.props;
    if (registrationInfo.ExternalUserId && registrationInfo.UserId) {
      this.props.resendEmail(registrationInfo)
        .then(() => {
          this.props.notificationShow(ResendEmail.msg_email_resent, 'success');
        });
    }
  }

  componentDidMount() {
    const { ExternalUserId, UserId } = this.props.registrationInfo;
    if (!ExternalUserId && !UserId) {
      this.props.history.push(this.props.text.Home.nav_path);
    }
  }

  render() {
    const { supportNumber, text: { ResendEmail } } = this.props;
    return (
      <div className={styles.container}>
        <FormWrapper>
          <h1 className={styles.header}>{ResendEmail.head_confirm_email}</h1>
          <p className={styles.mainText}>{ResendEmail.text_check_inbox}</p>
          <div className={styles.button}>
            <Button
              fullWidth
              onClick={this.emailResend}
              variant='contained'
            >
              {ResendEmail.btn_resend_email}
            </Button>
          </div>
          <p className={styles.supportText}>{ResendEmail.text_support_phone(supportNumber)}</p>
        </FormWrapper>
      </div>
    );
  }
}


export default connect(select, {
  notificationShow,
  resendEmail
})(LanguageHOC(ResendEmail));

