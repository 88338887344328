import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { cloneDeep } from 'lodash';

import CircularProgress from '../CircularProgress';

import DropZone from 'react-dropzone';

import {
  Avatar,
  Button,
  TextField,
  Slide,
  Switch,
} from '@mui/material';

import { Person, } from '@mui/icons-material';

import {
  InfoIcon,
  Modal,
  notificationShow,
  LoadingOverlay,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';

import {
  changeNameVisibility,
  getGiftingList,
  getGiftImage,
  updateGift,
} from '../actions';

import styles from './styles.module.scss';

const IMAGE_SIZE_LIMIT = 8000000; // 8MBs

const select = (state) => ({
  user: state.session.userDetails,
  giftingSiteUrl: state.static.environmentVars.GiftingSiteUrl,
  beneficiaryList: state.gifting.beneficiaryList,
});

export class ManageGifting extends Component {

  static propTypes = {
    beneficiaryList: PropTypes.array.isRequired,
    changeNameVisibility: PropTypes.func.isRequired,
    getGiftingList: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    getGiftImage: PropTypes.func.isRequired,
    updateGift: PropTypes.func.isRequired,
    giftingSiteUrl: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    giftCodeId: PropTypes.number,
    text: PropTypes.shape({
      Gifting: PropTypes.shape({
        nav_path: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_done: PropTypes.string,
        btn_email: PropTypes.string,
        btn_facebook: PropTypes.string,
        btn_manage_gift: PropTypes.string,
        btn_ok: PropTypes.string,
        btn_remove: PropTypes.string,
        btn_twitter: PropTypes.string,
        lbl_account: PropTypes.string,
        lbl_account_owner: PropTypes.string,
        lbl_beneficiary: PropTypes.string,
        lbl_code: PropTypes.string,
        lbl_hidden: PropTypes.string,
        lbl_hide_last_name: PropTypes.string,
        lbl_manage_gift: PropTypes.string,
        lbl_message: PropTypes.string,
        lbl_no_history: PropTypes.string,
        lbl_history: PropTypes.string,
        lbl_page_views: PropTypes.string,
        lbl_shown: PropTypes.string,
        lbl_social: PropTypes.string,
        lbl_url: PropTypes.string,
        message: PropTypes.string,
        msg_email_subject: PropTypes.func,
        text_copy_link: PropTypes.string,
        text_default_email: PropTypes.func,
        text_enable_gifting: PropTypes.string,
        text_hide_last_name: PropTypes.string,
        msg_save: PropTypes.string,
        error_message_empty:  PropTypes.string,
        error_message_allowed_chars:  PropTypes.string,
        text_input_placeholder: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    giftUploading: false,
    imageDownloading: false,
    benesLoading: false,
    giftMessage: '',
    giftMessageError: '',
    originalGiftMessage: '',
    historyLoading: false,
    showSaveDialog: false,
    isValidateImage: false,
    validateImageMsg: '',
    beneficiary: {},
    imageChanged: false,
    progress: 0,
    hideLastName: false,
  };

  handleInput = name => e => {
    if (name === 'giftMessage') {
      this.setState({
        [name]: e.target.value
      }, () => this.validateGiftMessage()); // validate message when typing
    }
    else {
      this.setState({ [name]: e.target.value });
    }
  }

  hideLastNameChange = e => {
    const { changeNameVisibility, notificationShow, text: { Gifting } } = this.props;
    const { beneficiary } = this.state;
    const hideLastName = e.target.checked;

    changeNameVisibility(beneficiary.AccountId, hideLastName)
      .then(() => {
        this.setState({ hideLastName }, () => notificationShow(Gifting.msg_save, 'success'));
      });
  }

  renderHistory() {
    const { text: { Gifting } } = this.props;
    const { beneficiary: { Transactions } } = this.state;
    let results = [];
    if (Transactions === undefined || Transactions.length === 0) {
      results = <div className={styles.tableTitle}>{Gifting.lbl_no_history}</div>;
    }
    else {
      const trans = Transactions.map(trans => ({
        GiftId: trans.GiftId,
        GiftCodeId: trans.GiftCodeId,
        GifterId: trans.GifterId,
        PostDate: trans.PostDate,
        Name: `${trans.FirstName} ${trans.LastName}`,
        Amount: trans.Amount,
        Memo: trans.Memo,
        Status: trans.Status,
      }));
      results = (
        <>
          <div className={styles.tableTitle}>{Gifting.lbl_history}</div>
          <SmartTable
            idKey='GifterId'
            rows={trans}
            loading={this.state.historyLoading}
            columns={[
              {
                key: 'PostDate',
                title: 'Date',
                type: 'dateString',
              },
              {
                key: 'Name',
                title: 'Gifter',
                type: 'string',
              },
              {
                key: 'Amount',
                title: 'Amount',
                type: 'currency',
              },
              {
                key: 'Status',
                title: 'Status',
                type: 'string',
              },
              {
                key: 'Memo',
                title: 'Message',
                type: 'string',
                hideOn: ['phone'],
                customStyle: { width: '2fr' }
              },
            ]}
          >
            <TableToolbar />
            <TableContainer minWidth='100%'>
              <TableHeader />
              <TableRows />
            </TableContainer>
            <TablePagination />
          </SmartTable>
        </>
      );
    }
    return results;
  }

  buildURL() {
    const { text: { Gifting } } = this.props;
    const { beneficiary } = this.state;
    const url = encodeURI(`${this.props.giftingSiteUrl}/${beneficiary.GiftCode}`); //giftingSiteUrl should never include '/' at the end
    let displayURL = '';
    if (beneficiary.IsActive) {
      displayURL = (
        <React.Fragment>
          <button
            onClick={() => window.open(url, '_blank')}
            className={styles.giftingLink}
          >{url}</button>
          <i
            className='material-icons'
            style={{ cursor: 'pointer', fontSize: '18px', textIndent: '10px' }}
            onClick={() => navigator.clipboard.writeText(url)
              .then(() => {
                this.props.notificationShow(Gifting.text_copy_link, 'success');
              })}
          >file_copy</i>
          <i
            className='material-icons'
            onClick={() => window.open(url, '_blank')}
            style={{ cursor: 'pointer', fontSize: '18px', textIndent: '5px' }}
          >launch</i>
        </React.Fragment>
      );
    }
    else {
      displayURL = (<div>{Gifting.text_enable_gifting}</div>);
    }

    return displayURL;
  }

  dropFile(files) {
    const { beneficiary } = this.state;
    const file = files[0];

    if (file && (!file.type.includes('jpeg') && !file.type.includes('gif') && !file.type.includes('png'))) {
      this.setState({
        isValidateImage: true,
        validateImageMsg: 'Invalid image type. JPG/JPEG, GIF, and PNG are valid image types.'
      });
    }
    else if (file && file.size > IMAGE_SIZE_LIMIT) {
      this.setState({
        isValidateImage: true,
        validateImageMsg: 'Image cannot be larger than 8 megabytes.'
      });
    }
    else {
      const reader = new FileReader();
      reader.readAsArrayBuffer(files[0]);
      reader.onload = () => {
        const arrayBuffer = reader.result;
        this.setState({
          imageChanged: true,
          beneficiary: { ...beneficiary, Image: [...new Uint8Array(arrayBuffer)], HasImage: true },
        });
      };
    }
  }

  removeFile = () => {
    this.setState({
      beneficiary: {
        ...this.state.beneficiary,
        Image: null,
        HasImage: false,
      },
      imageChanged: true,
    });
  }

  convertByteArrayToImage(data) {
    const arrayBuffer = new Uint8Array(data);
    const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  }

  renderDropZone() {
    const { text: { Gifting } } = this.props;
    const { beneficiary, imageChanged } = this.state;
    let entireImage = '';
    if (beneficiary.Image) {
      const constructedImage = imageChanged ? this.convertByteArrayToImage(beneficiary.Image) : URL.createObjectURL(beneficiary.Image);
      entireImage = (<Avatar id='dropZone' style={{ width: '100%', height: '100%', cursor: 'pointer', }} src={constructedImage} alt='Beneficiary' />);
    }
    else {
      entireImage = (
        <Avatar
          className='material-icons'
          id='dropZone'
          alt='Beneficiary'
          style={{ width: '100%', height: '100%', }}
        >
          <Person style={{ fontSize: '300px', borderRadius: '50%', opacity: '0.50', cursor: 'pointer', border: '1x solid grey', }} />
        </Avatar>
      );
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <DropZone onDrop={incomingFile => this.dropFile(incomingFile)}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()} style={{ position: 'relative', textAlign: 'center', width: '300px', height: '300px' }}>
                <input {...getInputProps()} />
                {entireImage}
                {!beneficiary.HasImage && <div style={{ position: 'absolute', bottom: '45%', left: '30%', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                  <div><i className='material-icons' style={{ paddingRight: '10px' }}>add_circle_outline</i></div>
                  <div style={{ fontSize: '14px', fontWeight: '900' }}>ADD IMAGE</div>
                </div>}
              </div>
            </section>
          )}
        </DropZone>
        {beneficiary.HasImage &&
          <div className={styles.removeButton}>
            <Button
              variant='outlined'
              color='primary'
              onClick={this.removeFile}
            >
              {Gifting.btn_remove}
            </Button>
          </div>
        }
      </div>
    );
  }

  formChanged = () => {
    return this.state.imageChanged ||
      this.state.originalGiftMessage !== this.state.giftMessage;
  }

  shouldCancel = () => {
    const { text: { Gifting } } = this.props;
    if (this.formChanged() && this.validateGiftMessage()) {
      this.setState({ showSaveDialog: true });
    }
    else {
      // redirect to the gift dashboard
      this.props.history.push(Gifting.nav_path);
    }
  }

  showPercentageUploadProgress = (percentage) => {
    this.setState({ progress: percentage });
  }

  validateGiftMessage = () => {
    const { text: { Gifting } } = this.props;
    const { giftMessage } = this.state;
    const allowedCharsRegex = /^[a-zA-Z0-9 !?()$.,:;\-='\\\n\r]+$/g;

    let giftMessageError = ''; // clear message error
    if (!giftMessage) {
      giftMessageError = Gifting.error_message_empty;
    }
    else if (!allowedCharsRegex.test(giftMessage)) {
      giftMessageError = Gifting.error_message_allowed_chars;
    }

    this.setState({ giftMessageError });
    return !giftMessageError;
  }

  saveChanges = () => {
    if (this.formChanged() && this.validateGiftMessage()) {
      const { imageChanged, beneficiary, giftMessage } = this.state;
      const { updateGift, notificationShow, text: { Gifting } } = this.props;

      beneficiary.IsActive = beneficiary.IsActive ? 1 : 0;
      beneficiary.GiftMessage = giftMessage;
      if (!imageChanged && beneficiary.HasImage) {
        // To not update unchanged image set it to null and leave HasImage true (per API rules)
        beneficiary.Image = null;
      }

      this.setState({ giftUploading: true });
      updateGift(beneficiary, this.showPercentageUploadProgress)
        .then(() => {
          notificationShow(Gifting.msg_save, 'success');
          // redirect to the gift dashboard
          this.props.history.push(Gifting.nav_path);
        })
        .catch(() => {
          this.setState({ giftUploading: false });
        });
    }
  }

  setBeneficiary = (beneficiary) => {
    this.setState({
      giftMessage: beneficiary.GiftMessage,
      imageChanged: false,
      beneficiary
    }, () => this.validateGiftMessage()); // in case there is an empty message from DB show error
  }

  getImageSetBene = (beneficiary) => {
    const { getGiftImage } = this.props;
    // load gift image if exists, we don't include images in the gift list
    const updatedBeneficiary = cloneDeep(beneficiary);
    if (beneficiary.HasImage) {
      this.setState({ imageDownloading: true });
      getGiftImage(beneficiary.AccountId)
        .then((response) => {
          updatedBeneficiary.Image = response.payload.data;
          this.setBeneficiary(updatedBeneficiary);
        })
        .finally(() => this.setState({ imageDownloading: false }));
    }
    else {
      this.setBeneficiary(updatedBeneficiary);
    }
  }

  initializeBeneDetails = (accountId) => {
    if (this.props.beneficiaryList.length === 0) {
      this.setState({ benesLoading: true });
      this.props.getGiftingList()
        .then(() => {
          const beneficiary = this.props.beneficiaryList.find(beneficiary => beneficiary.AccountId === accountId);
          this.getImageSetBene(beneficiary);
          this.setState({
            benesLoading: false,
            originalGiftMessage: beneficiary.GiftMessage,
            hideLastName: Boolean(beneficiary.HideLastName),
          });
        });
    }
    else {
      const beneficiary = this.props.beneficiaryList.find(beneficiary => beneficiary.AccountId === accountId);
      this.setState({
        originalGiftMessage: beneficiary.GiftMessage,
        hideLastName: Boolean(beneficiary.HideLastName),
      });
      this.getImageSetBene(beneficiary);
    }
  }

  componentDidMount() {
    const accountId = parseInt(this.props.match.params.accountId);
    this.initializeBeneDetails(accountId);
  }

  componentDidUpdate(prevProps) {
    const accountId = parseInt(this.props.match.params.accountId);
    if (parseInt(prevProps.match.params.accountId) !== accountId) {
      // account id got updated
      this.initializeBeneDetails(accountId);
    }
  }

  render() {
    const {
      text: {
        Gifting
      }
    } = this.props;
    const {
      giftUploading,
      imageDownloading,
      beneficiary,
      giftMessage,
      giftMessageError,
      showSaveDialog,
      isValidateImage,
      validateImageMsg,
      progress,
      hideLastName
    } = this.state;

    const beneName = `${beneficiary.FirstName} ${beneficiary.LastName}`;
    const giftCode = beneficiary.IsActive ? beneficiary.GiftCode : 'Inactive';
    const encodedURL = encodeURI(`${this.props.giftingSiteUrl}/${giftCode}`); //giftingSiteUrl does not include '/' at the end
    const nameStatusText = hideLastName ? Gifting.lbl_hidden : Gifting.lbl_shown;

    return (
      <>
        <CircularProgress show={giftUploading} progress={progress}>
          <div className={styles.manageGifting}>
            <div className={styles.empty1} />
            <div className={styles.buttons}>
              <Button
                variant='text'
                color='primary'
                key='cancel'
                onClick={this.shouldCancel}
                style={{ marginRight: '10px' }}
              >
                {Gifting.btn_cancel}
              </Button>
              <Button
                key='done'
                onClick={this.saveChanges}
                variant='contained'
                disabled={Boolean(giftMessageError) || !this.formChanged()}
              >
                {Gifting.btn_done}
              </Button>
            </div>
            <div className={styles.empty2} />

            <div className={styles.empty3} />
            <div className={styles.picture}>
              <LoadingOverlay show={imageDownloading}>
                {this.renderDropZone()}
              </LoadingOverlay>
            </div>
            <div className={styles.customText}>
              <p className={styles.labelTILE} style={{ color: '#0072CF' }}>{Gifting.message}</p>
              <TextField
                margin='dense'
                name='GiftMessage'
                type='text'
                label={Gifting.text_input_placeholder}
                value={giftMessage}
                fullWidth
                multiline
                maxRows={10}
                inputProps={{ maxLength: 2000 }}
                onChange={this.handleInput('giftMessage')}
                error={Boolean(giftMessageError)}
                helperText={giftMessageError}
              />
              <p className={styles.messageLength}>{giftMessage.length}/2000</p>
            </div>
            <div className={styles.empty4} />

            <div className={styles.empty5} />
            <div className={styles.beneficiary}>
              <p className={styles.labelTILE}>{Gifting.lbl_beneficiary}</p>
              <p className={styles.dataTILE}>{beneName}</p>
            </div>
            <div className={styles.url}>
              <p className={styles.labelTILE}>{Gifting.lbl_url}</p>
              {this.buildURL()}
            </div>
            <div className={styles.empty6} />

            <div className={styles.empty7} />
            <div className={styles.ao}>
              <p className={styles.labelTILE}>{Gifting.lbl_account_owner}</p>
              <p className={styles.dataTILE}>{this.props.user.name}</p>
              <div className={styles.acctSettings}>
                <div className={styles.hideLastName}>
                  <p className={`${styles.labelTILE} ${styles.hideLastNameLabel}`}>{Gifting.lbl_hide_last_name}</p>
                  <p className={styles.hideLastNameInfo}><InfoIcon message={Gifting.text_hide_last_name} /></p>
                  <p className={styles.hideLastNameSwitch}>
                    <Switch
                      checked={hideLastName}
                      disabled={!beneficiary.IsActive}
                      onChange={this.hideLastNameChange}
                      value={hideLastName}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </p>
                  <p className={styles.hideLastNameStatus}>{nameStatusText}</p>
                </div>
              </div>
            </div>
            <div className={styles.pageviewscode}>
              <p className={styles.labelTILE}>{Gifting.lbl_page_views}</p>
              <p className={styles.dataTILE}>{beneficiary.PageViews || 0}</p>
              <p className={styles.labelTILE}>{Gifting.lbl_code}</p>
              <p className={styles.dataTILE}>{giftCode}</p>
            </div>
            <div className={styles.empty8} />

            <div className={styles.empty9} />
            <div className={styles.acct}>
              <p className={styles.labelTILE}>{Gifting.lbl_account}</p>
              <p className={styles.acctType}>{beneficiary.OptionName}</p>
              <p className={styles.dataTILE} style={{ color: '#3399FF', letterSpacing: '3px' }}>{beneficiary.AccountNumber}</p>
            </div>
            <div className={styles.social}>
              <p className={styles.labelTILE}>{Gifting.lbl_social}</p>
              <p className={styles.dataTILE}>
                <Button
                  variant='text'
                  onClick={() => window.open(`mailto:?subject=${Gifting.msg_email_subject(beneficiary.FirstName)}&body=${Gifting.text_default_email(beneficiary.FirstName, encodedURL)}`, '_blank')}
                  disabled={!beneficiary.IsActive}
                  style={{ color: '#0072CF', marginRight: '10px' }}
                >
                  {Gifting.btn_email}
                </Button>
                <Button
                  variant='text'
                  onClick={() => window.open(`https://facebook.com/sharer/sharer.php?u=${encodedURL}`, '_blank')}
                  disabled={!beneficiary.IsActive}
                  style={{ color: '#0072CF', marginRight: '10px' }}
                >
                  {Gifting.btn_facebook}
                </Button>
                <Button
                  variant='text'
                  onClick={() => window.open(`https://twitter.com/intent/tweet?text=${Gifting.text_default_email(beneficiary.FirstName, encodedURL)}`, '_blank')}
                  disabled={!beneficiary.IsActive}
                  style={{ color: '#0072CF' }}
                >
                  {Gifting.btn_twitter}
                </Button>
              </p>
            </div>
            <div className={styles.empty10} />

            <div className={styles.empty11} />
            <div className={styles.history}>
              <hr />
              {this.renderHistory()}
            </div>
            <div className={styles.empty12} />
          </div>
        </CircularProgress>

        <Modal
          show={showSaveDialog}
          title='Unsaved Changes'
          onCloseModal={() => this.setState({ showSaveDialog: false })}
          DialogProps={{ 'TransitionComponent': Transition }}
          actionButtons={[
            {
              action: this.saveChanges,
              label: 'Yes'
            },
            {
              action: () => this.props.history.push(Gifting.nav_path), // redirect to the gift dashboard
              label: 'No',
              buttonType: 'text',
            },
          ]}
        >
          <div>
            You have unsaved changes. Would you like to save your changes first?
          </div>
        </Modal>

        <Modal
          show={isValidateImage}
          title=''
          onCloseModal={() => this.setState({ isValidateImage: false })}
          DialogProps={{ 'TransitionComponent': Transition }}
          actionButtons={[
            {
              action: () => this.setState({ isValidateImage: false, validateImageMsg: '' }),
              label: Gifting.btn_ok
            }
          ]}
        >
          <div>{validateImageMsg}</div>
        </Modal>
      </>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction='up' ref={ref} {...props} />;
});

export default withRouter(connect(select, {
  notificationShow,
  getGiftImage,
  updateGift,
  getGiftingList,
  changeNameVisibility,
})(LanguageHOC(ManageGifting)));