/*
*
* AddressCard Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { CHANGE_ADDRESS } from '../constants';
import { GETaddress } from 'utils/helpers/api_handler';
import * as validator from 'utils/helpers/form_validation';

import {
  AddressTextFields,
  CardButtons,
  CardTitle,
  InfoIcon,
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';
import {
  Button,
  Tab,
  Tabs,
} from '@mui/material';
import { withStyles, } from '@mui/styles';

import { changeAddress, deleteAddress } from '../actions';

import styles from './styles.module.scss';

const muiStyles = {
  tab: {
    minWidth: '20px', // a number of your choice
  }
};

const select = state => ({
  ADDRESS_TYPES: state.static.environmentVars.AddressTypes,
  US_STATES: state.static.usStates
});

export class AddressCard extends React.Component {
  static propTypes = {
    addresses: PropTypes.shape({
      Physical: PropTypes.object,
      Mailing: PropTypes.object,
      Other: PropTypes.object,
    }).isRequired,
    hasTitle: PropTypes.bool,
    ADDRESS_TYPES: PropTypes.shape({
      Physical: PropTypes.string,
      Mailing: PropTypes.string,
      Other: PropTypes.string
    }).isRequired,
    changeAddress: PropTypes.func.isRequired,
    deleteAddress: PropTypes.func.isRequired,
    displayUnsavedChangesPrompt: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    text: PropTypes.shape({
      AddressCard: PropTypes.shape({
        btn_delete: PropTypes.func,
        btn_update: PropTypes.func,
        err_postal_code_length: PropTypes.string,
        err_required: PropTypes.string,
        err_smarty_streets: PropTypes.string,
        err_street1_max_length: PropTypes.string,
        head_title: PropTypes.string,
        msg_address_deleted: PropTypes.string,
        msg_address_updated: PropTypes.string,
        text_intl_address: PropTypes.string,
      }),
    }).isRequired,
    residentCheck: PropTypes.func.isRequired,
    US_STATES: PropTypes.array.isRequired,
    callbackOnAddressChange: PropTypes.func,
    classes: PropTypes.object,
  };

  static defaultProps = {
    callbackOnAddressChange: null,
    hasTitle: true,
  };

  state = {
    addressType: this.props.ADDRESS_TYPES.Mailing,
    isDeleting: false,
    isSaving: false,
    errors: {
      Mailing: {},
      Physical: {},
      Other: {},
    },
    addresses: {
      Mailing: this.props.addresses.Mailing,
      Physical: this.props.addresses.Physical,
      Other: this.props.addresses.Other,
    },
    addressSuggestions: [],
    autocompleteText: {
      Mailing: this.props.addresses.Mailing.StreetAddress1,
      Physical: this.props.addresses.Physical.StreetAddress1,
      Other: this.props.addresses.Other.StreetAddress1,
    },
  };

  areErrors(addressType, errors) {
    // we need to include inner loop for input fields
    const anyErrors = Object.values(errors[addressType]).filter(err => err && Object.values(err).filter(val => val).length !== 0);
    return anyErrors.length === 0;
  }

  errorGet = (input) => {
    const error = this.state.errors[this.state.addressType] && this.state.errors[this.state.addressType][input];
    return error ? error : '';
  }

  verifyAddress = () => {
    const { addressType, addresses, errors } = this.state;
    const { ADDRESS_TYPES } = this.props;
    const { err_postal_code_length, err_required, err_street1_max_length } = this.props.text.AddressCard;
    const postalCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{9}$)|(^\d{5} \d{4}$)/;

    // reset only this address type error
    const updatedErrors = { ...errors, [addressType]: {} };

    // verify only mailing address at this time
    if (addressType === ADDRESS_TYPES.Mailing) {
      if (!addresses[addressType].StreetAddress1) {
        updatedErrors[addressType].StreetAddress1 = err_required;
      }
      if (!addresses[addressType].State) {
        updatedErrors[addressType].State = err_required;
      }
      if (!addresses[addressType].PostalCode.match(postalCodeRegex)) {
        updatedErrors[addressType].PostalCode = err_postal_code_length;
      }
      if (addresses[addressType].StreetAddress1.length > 40) {
        updatedErrors[addressType].StreetAddress1 = err_street1_max_length;
      }
      if (addresses[addressType].StreetAddress2.length > 40) {
        updatedErrors[addressType].StreetAddress2 = err_street1_max_length;
      }
      updatedErrors[addressType].City = validator.cityValidator(addresses[addressType].City);
    }

    this.setState({ errors: updatedErrors });

    return this.areErrors(addressType, updatedErrors);
  }

  addressTabChange = (e, value) => {
    this.setState({ addressType: value });
  };

  onInputChange = (inputName, event) => {
    const { addressType } = this.state;
    this.setState({
      errors: {
        ...this.state.errors,
        [addressType]: {}
      },
      addresses: { // update only this address type and its specific field
        ...this.state.addresses,
        [addressType]: {
          ...this.state.addresses[addressType],
          [inputName]: event.target.value
        }
      },
    }, () => this.onBlur(inputName));

  }

  uppercaseInput = (inputName) => {
    const { addresses, addressType } = this.state;
    const uppercasedAddresses = { ...addresses };
    uppercasedAddresses[addressType][inputName] = uppercasedAddresses[addressType]
      && uppercasedAddresses[addressType][inputName]
      && uppercasedAddresses[addressType][inputName].toUpperCase();
    this.setState({ addresses: uppercasedAddresses });
  }

  onBlur = (inputName) => {
    if (inputName) {
      this.uppercaseInput(inputName);
      this.verifyAddress();
    }
  }

  mapApiErrorsToFormErrors = (apiErrors) => {
    const { addressType, errors } = this.state;
    const updatedErrors = { ...errors };

    if (apiErrors.length > 0) {
      // this is assuming that api Field properties would not change!
      apiErrors.forEach(apiError => updatedErrors[addressType][apiError.Field] = apiError.Message);
    }

    this.setState({ errors: updatedErrors });
  }

  addressDelete = (e) => {
    e.preventDefault();
    const { addressType, addresses } = this.state;
    this.setState({ isDeleting: true });

    this.props.deleteAddress(addresses[addressType].AddressId, addressType)
      .then(() => {
        // on success call callback if passed
        if (this.props.callbackOnAddressChange)
          this.props.callbackOnAddressChange();

        this.setState({
          isDeleting: false,
          addresses: {
            Mailing: this.props.addresses.Mailing,
            Physical: this.props.addresses.Physical,
            Other: this.props.addresses.Other,
          },
          autocompleteText: {
            Mailing: this.props.addresses.Mailing.StreetAddress1,
            Other: this.props.addresses.Other.StreetAddress1,
            Physical: this.props.addresses.Physical.StreetAddress1,
          },
        });
        this.props.notificationShow(this.props.text.AddressCard.msg_address_deleted, 'success');
      })
      .catch((response) => this.setState({ isDeleting: false }, () => this.mapApiErrorsToFormErrors(response.payload.data)));
  }

  addressUpdate = (e) => {
    e.preventDefault();
    const { addressType, addresses } = this.state;
    if (this.verifyAddress()) {
      this.setState({ isSaving: true });
      this.props.changeAddress({ ...addresses[addressType], Type: addressType })
        .then(() => {
          // on success call callback if passed
          if (this.props.callbackOnAddressChange)
            this.props.callbackOnAddressChange();

          this.setState({
            isSaving: false,
            addresses: {
              Mailing: this.props.addresses.Mailing,
              Physical: this.props.addresses.Physical,
              Other: this.props.addresses.Other,
            },
            autocompleteText: {
              Mailing: this.props.addresses.Mailing.StreetAddress1,
              Physical: this.props.addresses.Physical.StreetAddress1,
              Other: this.props.addresses.Other.StreetAddress1,
            }
          });
          this.props.notificationShow(this.props.text.AddressCard.msg_address_updated, 'success');
        })
        .then(() => this.props.residentCheck())
        .catch((response) => this.setState({ isSaving: false }, () => this.mapApiErrorsToFormErrors(response.payload.data)));
    }
  }

  updateButtonIsDisabled = () => {
    const { addressType, isDeleting, isSaving, addresses, errors } = this.state;

    return isDeleting
      || isSaving
      || isEqual(addresses[addressType], this.props.addresses[addressType])
      || !this.areErrors(addressType, errors);
  }

  deleteButtonDisabled = () => {
    const { addressType, isDeleting, isSaving, addresses } = this.state;
    return isDeleting
      || isSaving
      || Object.keys(addresses[addressType]).every(key => addresses[addressType][key] === '')
      || Object.keys(this.props.addresses[addressType]).every(key => this.props.addresses[addressType][key] === '');
  }

  buttonsCompose = () => {
    const { addressType, isDeleting, isSaving, addresses } = this.state;
    const isReadOnlyAddress = addresses[addressType].Country !== 'US' && addresses[addressType].Country !== '';

    if (!isReadOnlyAddress) {
      return [
        addressType !== this.props.ADDRESS_TYPES.Mailing &&
        <LoadingOverlay show={isDeleting} key='isDeleting'>
          <Button
            disabled={this.deleteButtonDisabled()}
            onClick={this.addressDelete}
            variant='contained'
          >
            {this.props.text.AddressCard.btn_delete(addressType)}
          </Button>
        </LoadingOverlay>,
        <LoadingOverlay show={isSaving} key='isSaving'>
          <Button
            disabled={this.updateButtonIsDisabled()}
            onClick={this.addressUpdate}
            type='submit'
            variant='contained'
          >
            {this.props.text.AddressCard.btn_update(addressType)}
          </Button>
        </LoadingOverlay>
      ];
    }
    return [];
  }

  autocompleteSuggestionsGet = event => {
    const { addressType } = this.state;

    this.setState({
      autocompleteText: {
        ...this.state.autocompleteText,
        [addressType]: event.target.value
      },
      addresses: {
        ...this.state.addresses,
        [addressType]: {
          ...this.state.addresses[addressType],
          StreetAddress1: event.target.value
        }
      }
    }, () => this.onBlur('StreetAddress1')); // TODO this call to onBlur() is a temporary workaround until the MUI Autocomplete Textfield can accept input props without erroring

    if (event.target.value.trim()) { // Only make a request if there is text. Trims out leading spaces to avoid a Smarty Streets freakout
      return GETaddress(event.target.value)
        .then(res => {
          if (res.data.suggestions !== null) {
            this.setState({
              addressSuggestions: res.data.suggestions
            });
          }
        })
        .catch(res => {
          if (res !== 'short-criteria') {
            this.props.notificationShow(this.props.text.AddressCard.err_smarty_streets, 'error');
          }
          this.setState({ addressSuggestions: [] });
        });
    }
  }

  onAutocompleteAddressSelect = (event, selection) => {
    const { addressType } = this.state;

    if (!selection) { // if user clears input
      this.setState({
        autocompleteText: {
          ...this.state[addressType],
          [addressType]: '',
        },
        addressSuggestions: [],
        addresses: {
          ...this.state.addresses,
          [addressType]: {
            ...this.state.addresses[addressType],
            StreetAddress1: '',
          }
        }
      });
    }
    else if (!Object.prototype.hasOwnProperty.call(selection, 'street_line')) { // if user sends too many requests in a short period, autocomplete stops sending suggestions back, so just set input to state
      this.setState({
        autocompleteText: {
          ...this.state.autocompleteText,
          [addressType]: selection
        },
        addresses: {
          ...this.state.addresses,
          [addressType]: {
            ...this.state.addresses[addressType],
            StreetAddress1: selection,
          }
        }
      });
    }
    else { // if all is working as expected and selection is valid SmartyStreets object
      this.setState({
        autocompleteText: {
          ...this.state.autocompleteText,
          [addressType]: selection.street_line.toUpperCase()
        },
        addresses: {
          ...this.state.addresses,
          [addressType]: {
            ...this.state.addresses[addressType],
            City: selection.city.toUpperCase(),
            PostalCode: selection.zipcode,
            State: selection.state.toUpperCase(),
            StreetAddress1: selection.street_line.toUpperCase(),
            StreetAddress2: selection.secondary.toUpperCase()
          }
        }
      });
    }
  }

  componentDidUpdate() {
    const { addresses } = this.state;
    if (!isEqual(addresses, this.props.addresses)) {
      this.props.displayUnsavedChangesPrompt(CHANGE_ADDRESS, true);
    }
    else {
      this.props.displayUnsavedChangesPrompt(CHANGE_ADDRESS, false);
    }
  }

  render() {
    const { addressType, addresses, addressSuggestions, autocompleteText } = this.state;
    const { ADDRESS_TYPES, hasTitle, text: { AddressCard: { head_title, text_intl_address } } } = this.props;
    const isReadOnlyAddress = addresses[addressType].Country !== 'US' && addresses[addressType].Country !== '';

    return (
      <React.Fragment>
        <form onSubmit={this.addressUpdate}>
          <div>
            {hasTitle &&
              <CardTitle>
                <div className={styles.titleContainer}>
                  {head_title}
                  <InfoIcon message={text_intl_address} />
                </div>
              </CardTitle>
            }
            <div>
              <Tabs
                value={addressType}
                onChange={this.addressTabChange}
                centered={true}
                className={styles.tabs}
              >
                <Tab classes={{ root: this.props.classes.tab }} label={ADDRESS_TYPES.Mailing} value={ADDRESS_TYPES.Mailing} />
                <Tab classes={{ root: this.props.classes.tab }} label={ADDRESS_TYPES.Physical} value={ADDRESS_TYPES.Physical} />
                <Tab classes={{ root: this.props.classes.tab }} label={ADDRESS_TYPES.Other} value={ADDRESS_TYPES.Other} />
              </Tabs>

              <div className={isReadOnlyAddress ? `${styles.readOnlyAddress}` : ''}>
                <AddressTextFields
                  address={addresses[addressType]}
                  addressSuggestions={addressSuggestions}
                  autocompleteSuggestionsGet={this.autocompleteSuggestionsGet}
                  autocompleteText={autocompleteText[addressType]}
                  errorGet={this.errorGet}
                  inputProps={{ onBlur: (e) => this.onBlur(e.target.name) }}
                  onAutocompleteAddressSelect={this.onAutocompleteAddressSelect}
                  onInputChange={this.onInputChange}
                  usStates={this.props.US_STATES}
                />
              </div>
            </div>
          </div>

          <CardButtons>
            {this.buttonsCompose()}
          </CardButtons>
        </form>
      </React.Fragment>
    );
  }
}

export default withStyles(muiStyles)(connect(select, { changeAddress, deleteAddress, notificationShow })(LanguageHOC(AddressCard)));
