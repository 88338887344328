/* eslint-disable newline-per-chained-call */
/*
*
* Recurring Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import { clone, isEqual, cloneDeep } from 'lodash';
import * as validator from 'utils/helpers/form_validation';
import {
  Card,
  notificationShow,
} from '@frontend/common';
import { getNextRunDate, } from 'components/Features/protected/Accounts/Transactions/actions';
import {
  MONTHLY,
  TWICE_MONTHLY,
  DISABLED_DATES,
} from '../constants';
import TransactionStepper from 'components/Features/protected/Accounts/Transactions/TransactionStepper';
import { Step0AmountAndBank, Step1Frequency, Step2Terms, Step3PrintReview } from 'components/Features/protected/Accounts/Transactions/Contributions/Recurring/StepComponents/';

import styles from './styles.module.scss';

const select = (state) => ({
  contributionEnvironmentVars: state.static.environmentVars,
  contributionDetails: state.transactions.contributionDetails,
  agentBankAccounts: state.payments.agentBankAccounts,
  nextRunDates: state.transactions.nextRunDates.monthly,
});

export class Recurring extends React.Component {
  static propTypes = {
    agentBankAccounts: PropTypes.array.isRequired,
    nextRunDates: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    isEditing: PropTypes.bool.isRequired,
    bankAccountsLoading: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    getNextRunDate: PropTypes.func.isRequired,
    contributionCreate: PropTypes.func.isRequired,
    contributionEdit: PropTypes.func.isRequired,
    toggleShowAddBank: PropTypes.func.isRequired,
    renderDuplicateConfirmModal: PropTypes.func.isRequired,
    duplicateContributionModalOpen: PropTypes.bool.isRequired,
    createdDate: PropTypes.string.isRequired,
    contributionEnvironmentVars: PropTypes.shape({
      CurrentTradeDate: PropTypes.string.isRequired,
      MaximumRecurringOnlineContribution: PropTypes.number.isRequired,
      MinimumRecurringOnlineContribution: PropTypes.number.isRequired,
      MaximumOnlineContributionAmount: PropTypes.number.isRequired,
      MaximumRecurringContributionLeadDays: PropTypes.number.isRequired,
      ValidRecurringContributionDates: PropTypes.array.isRequired,
      SupportPhoneNumber: PropTypes.string.isRequired,
      FaxNumber: PropTypes.string.isRequired,
    }),
    contributionDetails: PropTypes.shape({
      canContributeAccountList: PropTypes.array,
      contribution: PropTypes.shape({
        day1: PropTypes.string,
        day2: PropTypes.string,
        endDate: PropTypes.string,
      }),
      selectedAccountId: PropTypes.number,
      // contribution: PropTypes.bool, TODO: Update this during edit story
    }).isRequired,
    text: PropTypes.shape({
      ContributionRecurring: PropTypes.shape({
        msg_unsaved_changes: PropTypes.string,
        err_bankAccount_select: PropTypes.string,
        err_end_date_after_start: PropTypes.string,
        err_end_date_after_both: PropTypes.string,
        err_second_date_required: PropTypes.string,
        err_second_date_within_month: PropTypes.string,
        err_step_does_not_exist: PropTypes.string,
        lbl_step_labels: PropTypes.array,
        text_end_before_next_run: PropTypes.string,
        text_next_run: PropTypes.string,
      })
    }),
  }

  state = {
    contribution: {
      bankAccount: {
        BankAccountId: '',
        BankName: '',
        MaskedBankAccountNumber: '',
      },
      day1: '',
      day2: '',
      selectedAccounts: [],
      type: MONTHLY,
    },
    contributionScreenshot: {
      bankAccount: {
        BankAccountId: '',
        BankName: '',
        MaskedBankAccountNumber: '',
      },
      day1: '',
      day2: '',
      selectedAccounts: [],
      type: MONTHLY,
    },
    errors: [],
    step0ValidationHasRun: false,
    step1ValidationHasRun: false,
    termsChecked: false,
    stepHasError: false,
  };

  displayUnsavedChangesPrompt = () => {
    return !this.state.bankAccountsLoading && !isEqual(this.state.contribution, this.state.contributionScreenshot);
  }

  clearStepErrors = () => {
    this.setState({
      errors: [],
    });
  }

  accountSelect = (value, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    const selectedAccount = this.props.contributionDetails.canContributeAccountList.find(account => account.accountId === value);
    const accountUpdatedWithAmount = { ...selectedAccount, contributionAmount: selectedAccounts[rowIndex].contributionAmount };

    selectedAccounts.splice(rowIndex, 1, accountUpdatedWithAmount);
    this.contributionSet(0, 'selectedAccounts', selectedAccounts);
  }

  amountHandle = ({ floatValue = 0 }) => {
    const { selectedAccounts } = this.state.contribution;
    selectedAccounts[0].contributionAmount = floatValue;
    this.contributionSet(0, 'selectedAccounts', selectedAccounts);
  }

  bankAccountSelectHandle = (value) => {
    this.contributionSet(0, 'bankAccount', this.props.agentBankAccounts.find(bankAccount => bankAccount.BankAccountId === value));
  }

  contributionDateHandle = (date, day) => {
    const { selectedAccounts } = this.state.contribution;
    if (day === 'endDate') {
      selectedAccounts[0].endDate = date;
      this.contributionSet(1, 'selectedAccounts', selectedAccounts);
    }
    else {
      this.contributionSet(1, day, date);
      // if day1 is now showing after day2, update day2 to be 1 day past day1.
      if (day === 'day1' && dayjs(date).isSameOrAfter(this.state.contribution.day2)) {
        this.contributionSet(1, 'day2', dayjs(date).add(1, 'd'));
        this.nextRunDateGet(dayjs(date).add(1, 'd'), 'day2');
      }
    }

    this.nextRunDateGet(date, day);
  }

  contributionFormat = () => {
    const { contribution } = this.state;
    if (contribution.type === MONTHLY) {
      contribution.day2 = null;
    }
    if (this.props.isEditing) {
      return this.contributionEditHandler(contribution);
    }
    else {
      return this.contributionCreateHandler(contribution);
    }
  }

  contributionSet = (step, key, value) => {
    this.setState(({ contribution }) => {
      return { contribution: { ...contribution, [key]: value } };
    }, () => {
      if (
        (step === 0 && this.state.step0ValidationHasRun) ||
        (step === 1 && this.state.step1ValidationHasRun)
      ) {
        this.formValidate(step);
      }
    });
  }

  contributionDatesDisable = (date) => {
    return this.props.contributionEnvironmentVars.ValidRecurringContributionDates.every(validContributionDate => {
      return !dayjs(validContributionDate).isSame(dayjs(date), 'day') ||
        dayjs(this.props.contributionEnvironmentVars.CurrentTradeDate).isAfter(dayjs(date), 'day');
    });
  }

  nextMinDateGet = (startDate = this.state.contribution.day1) => {
    let momentDate = dayjs(startDate).add(1, 'd');

    if (DISABLED_DATES.includes(momentDate.date())) {
      do {
        momentDate = dayjs(momentDate).add(1, 'd');
      }
      while (DISABLED_DATES.includes(momentDate.date()));
    }

    return momentDate;
  }

  nextRunDateCompose = (start) => {
    const {
      contributionDetails: { contribution: { day1, day2, endDate } },
      nextRunDates,
      text: { ContributionRecurring },
    } = this.props;

    if (
      endDate &&
      (dayjs(day1).isSameOrAfter(endDate) || dayjs(day2).isSameOrAfter(endDate))
    ) {
      return <small>{ContributionRecurring.text_end_before_next_run}</small>;
    }

    return <small>{ContributionRecurring.text_next_run} {dayjs(start === 'start1' ? nextRunDates.day1 : nextRunDates.day2).format('L')}</small>;
  }

  nextRunDateGet = (date, day) => {
    if (dayjs(date).isValid()) {
      this.props.getNextRunDate(date, day, MONTHLY);
    }
  }

  errorGet = (key) => {
    const error = this.state.errors.find(error => error.key === key);
    return error ? error.message : '';
  }

  formValidate = (step) => {
    const { ContributionRecurring } = this.props.text;
    const { MaximumRecurringOnlineContribution, MinimumRecurringOnlineContribution, CurrentTradeDate, MaximumRecurringContributionLeadDays } = this.props.contributionEnvironmentVars;
    const { selectedAccounts, bankAccount, day1, day2, type } = this.state.contribution;
    const { contributionAmount, endDate } = selectedAccounts[0];
    const errors = [];

    const maxContributionAmount = type === TWICE_MONTHLY ? MaximumRecurringOnlineContribution / 2 : MaximumRecurringOnlineContribution;
    const amountMessage = validator.amountValidator(contributionAmount, MinimumRecurringOnlineContribution, maxContributionAmount);
    if (amountMessage) {
      errors.push({
        key: 'amount',
        message: amountMessage
      });
    }

    switch (step) {
      case 0: {
        if (!bankAccount.BankAccountId) {
          errors.push({
            key: 'bankAccount',
            message: ContributionRecurring.err_bankAccount_select,
          });
        }

        this.setState({ errors, step0ValidationHasRun: true, stepHasError: errors.length > 0 });
        break;
      }
      case 1: {
        const day1Message = validator.calendarValidator(day1, CurrentTradeDate, dayjs(CurrentTradeDate).add(MaximumRecurringContributionLeadDays, 'd'));
        if (day1Message) {
          errors.push({
            key: 'day1',
            message: day1Message,
          });
        }

        if (type === TWICE_MONTHLY) {
          if (!dayjs(day2).isValid()) { // validates that day2 is populated while editing
            errors.push({
              key: 'day2',
              message: ContributionRecurring.err_second_date_required
            });
          }
          if (
            (dayjs(day2).isSameOrBefore(day1, 'day') || // validates that day2 occurs after day1
              dayjs(day2).isSameOrAfter(dayjs(day1).add(1, 'month'), 'day')) // validates that day2 is within a month of day1
          ) {
            errors.push({
              key: 'day2',
              message: ContributionRecurring.err_second_date_within_month
            });
          }
        }

        const endDateMessage = validator.calendarValidator(endDate);
        if (endDate && endDateMessage) {
          errors.push({
            key: 'endDate',
            message: endDateMessage
          });
        }
        else if (
          ((type === TWICE_MONTHLY && (dayjs(endDate).isSameOrBefore(day1, 'day') || dayjs(endDate).isSameOrBefore(day2, 'day'))) || // validates that end date occurs after day1 and day2
            (type === MONTHLY && dayjs(endDate).isSameOrBefore(day1, 'day'))) // validates that end date occurs after day1
        ) {
          errors.push({
            key: 'endDate',
            message: type === MONTHLY ? ContributionRecurring.err_end_date_after_start : ContributionRecurring.err_end_date_after_both
          });
        }
        this.setState({ errors, step1ValidationHasRun: true, stepHasError: errors.length > 0 });
        break;
      }
      default: break;
    }

    return errors.length === 0;
  }

  contributionCreateHandler = () => {
    return this.props.contributionCreate(this.state.contribution)
      .then(() => this.setState({ contributionScreenshot: cloneDeep(this.state.contribution) }));
  }

  contributionEditHandler = () => {
    return this.props.contributionEdit(this.state.contribution)
      .then(() => this.setState({ contributionScreenshot: cloneDeep(this.state.contribution) }));
  }

  renderStepComponent = step => {
    const { agentBankAccounts, bankAccountsLoading, contributionDetails, contributionEnvironmentVars, isEditing, toggleShowAddBank, nextRunDates, createdDate, text: { ContributionRecurring } } = this.props;
    const { contribution, errors, termsChecked, } = this.state;
    let stepComponent;

    switch (step) {
      case 0: {
        stepComponent = (
          <Step0AmountAndBank
            errors={errors}
            isEditing={isEditing}
            contribution={contribution}
            agentBankAccounts={agentBankAccounts}
            bankAccountsLoading={bankAccountsLoading}
            toggleShowAddBank={toggleShowAddBank}
            contributionEnvironmentVars={contributionEnvironmentVars}
            contributionDetails={contributionDetails}
            accountSelect={this.accountSelect}
            amountHandle={this.amountHandle}
            errorGet={this.errorGet}
            bankAccountSelectHandle={this.bankAccountSelectHandle}
          />
        );
        break;
      }

      case 1: {
        stepComponent = (
          <Step1Frequency
            isEditing={isEditing}
            errors={errors}
            contribution={contribution}
            contributionEnvironmentVars={contributionEnvironmentVars}
            nextRunDates={nextRunDates}
            contributionSet={this.contributionSet}
            errorGet={this.errorGet}
            contributionDateHandle={this.contributionDateHandle}
            nextRunDateCompose={this.nextRunDateCompose}
            contributionDatesDisable={this.contributionDatesDisable}
          />
        );
        break;
      }

      case 2: {
        stepComponent = (
          <Step2Terms
            contribution={contribution}
            nextRunDates={nextRunDates}
            termsChecked={termsChecked}
            phoneEnvironmentVars={{ SupportPhoneNumber: contributionEnvironmentVars.SupportPhoneNumber, FaxNumber: contributionEnvironmentVars.FaxNumber }}
            toggleTermsChecked={() => this.setState({ termsChecked: !termsChecked })}
          />
        );
        break;
      }

      case 3: {
        stepComponent = (
          <Step3PrintReview
            contribution={contribution}
            nextRunDates={nextRunDates}
            createdDate={createdDate}
          />
        );
        break;
      }

      default:
        stepComponent = <div>{ContributionRecurring.err_step_does_not_exist}</div>;
    }
    return stepComponent;
  }

  componentDidMount() {
    const { contributionDetails: { canContributeAccountList, contribution, selectedAccountId }, contributionEnvironmentVars: { CurrentTradeDate }, isEditing } = this.props;

    if (Object.keys(canContributeAccountList).length > 0) {
      if (isEditing) {
        this.setState({ contribution, contributionScreenshot: cloneDeep(contribution) });
        this.nextRunDateGet(contribution.day1, 'day1');
        this.nextRunDateGet(contribution.day2, 'day2');
      }
      else {
        const firstNonDisabledDate = DISABLED_DATES.includes(dayjs(CurrentTradeDate).date()) ? this.nextMinDateGet(CurrentTradeDate) : dayjs(CurrentTradeDate);
        const secondNonDisabledDate = DISABLED_DATES.includes(dayjs(CurrentTradeDate).add(1, 'd').date()) ? this.nextMinDateGet(dayjs(CurrentTradeDate).add(1, 'd')) : dayjs(CurrentTradeDate).add(1, 'd'); // eslint-disable-line
        this.contributionDateHandle(firstNonDisabledDate, 'day1');
        this.contributionDateHandle(secondNonDisabledDate, 'day2');
        this.contributionSet(0, 'selectedAccounts', [clone(canContributeAccountList.find(account => account.accountId === selectedAccountId))]);
      }
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <Card>
          <TransactionStepper
            stepHasError={this.state.stepHasError}
            clearStepErrors={this.clearStepErrors}
            verifyStep={(step) => this.formValidate(step)}
            renderStepComponent={(step) => this.renderStepComponent(step)}
            handleSubmit={() => this.contributionFormat()}
            stepLabels={this.props.text.ContributionRecurring.lbl_step_labels}
            submitStep={2}
            termsChecked={this.state.termsChecked}
            renderConfirmModal={this.props.renderDuplicateConfirmModal}
          />
        </Card>

        <Prompt
          message={this.props.text.ContributionRecurring.msg_unsaved_changes}
          when={this.displayUnsavedChangesPrompt()}
        />
      </div>
    );
  }
}


export default withRouter(connect(select, {
  notificationShow,
  getNextRunDate,
})(LanguageHOC(Recurring)));
