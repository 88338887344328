import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import LogoutWarning from './LogoutWarning';
import PageNotFound from 'components/Features/public/PageNotFound';

import {
  notificationShow,
  allNotificationsHide,
} from '@frontend/common';

import {
  userLogout,
  clearStore,
  twoFaRouteChange,
} from './actions';

import { REASONS_BLOCKED } from './constants';

const select = (state) => ({
  environmentVars: state.static.environmentVars,
  isValid: state.session.isValid,
  userAccess: state.session.userAccess,
  reasonBlocked: state.session.reasonBlocked,
  isNewUser_AccessRole: state.session.isNewUser_AccessRole,
});

const windowEvents = [
  'click', // detects mouse click or finger press on button
  'keydown', // detects any key being pressed down
  'mousedown', // detects mouse click
  'mousemove', // detects any mouse movement
  'touchstart', // detects touch events for smaller screens
  'scroll' // detects scrolling on any device
];

export class ProtectedRoute extends React.Component {

  static propTypes = {
    environmentVars: PropTypes.shape({
      LogoutWarningTimeout: PropTypes.number.isRequired,
      AccountAccessNoFrontEndActivityTimeout: PropTypes.number.isRequired,
    }).isRequired,
    userLogout: PropTypes.func.isRequired,
    clearStore: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      AddBirthdate: PropTypes.shape({
        nav_path: PropTypes.string
      }),
      Login: PropTypes.shape({
        nav_path: PropTypes.string
      }),
      ProtectedRoute: PropTypes.shape({
        msg_logged_out_inactivity: PropTypes.string,
        msg_logged_out_inactivity_warning: PropTypes.func,
        title_logged_out_inactivity_warning: PropTypes.string,
        btn_close: PropTypes.string,
      }),
      Signup: PropTypes.shape({ nav_path: PropTypes.string }),
      Multifactor: PropTypes.shape({ nav_path: PropTypes.string }),
      VerifyEmail: PropTypes.shape({ nav_path: PropTypes.string }),
    }).isRequired,
    component: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    render: PropTypes.func,
    userAccess: PropTypes.string.isRequired,
    requiredAccessRights: PropTypes.array.isRequired,
    reasonBlocked: PropTypes.string,
    isNewUser_AccessRole: PropTypes.bool.isRequired,
    twoFaRouteChange: PropTypes.func.isRequired,
  };

  state = {
    inactivityWarningOn: false,
  };

  // timers
  inactivityWarningTimer = null;


  handleAccountBlocks = () => {
    const { reasonBlocked, text: { AddBirthdate, Multifactor, VerifyEmail } } = this.props;

    switch (reasonBlocked) {
      case '': return; // no blocks
      case REASONS_BLOCKED.MISSING_EMAIL_REQUIRED: // also push to verify email path
      case REASONS_BLOCKED.EMAIL_VALIDATION_REQUIRED: return <Redirect push to={VerifyEmail.nav_path} key={VerifyEmail.nav_path} />;
      case REASONS_BLOCKED.TWO_FACTOR_REGISTRATION: {
        this.props.twoFaRouteChange(window.location.pathname); // sets user's current route so they can return once 2fa is complete - important for SSup
        return <Redirect push to={Multifactor.nav_path} key={Multifactor.nav_path} />;
      }
      case REASONS_BLOCKED.NO_BIRTHDATE: return <Redirect push to={AddBirthdate.nav_path} />;
      default: return;
    }
  }

  handleRequiredAccess = () => {
    const { component, isNewUser_AccessRole, render, requiredAccessRights, text: { Signup }, userAccess } = this.props;
    const hasRequiredAccess = requiredAccessRights.includes(userAccess);
    const Component = component ? component : render; // allows render props to be passed instead of only a component

    if (hasRequiredAccess) { // loads requested route for users who have access
      return <Component {...this.props} />;
    }
    else if (isNewUser_AccessRole) { // redirects to Signup for new users
      return <Redirect push to={Signup.nav_path} />;
    }
    else { // loads PageNotFound for IP-only users
      return <PageNotFound {...this.props} />;
    }
  }

  /* inactivity */
  inactivityLogout = () => {
    const token = sessionStorage.getItem('token');
    const {
      text: { ProtectedRoute: { msg_logged_out_inactivity } },
    } = this.props;
    this.props.userLogout({ token })
      .finally(() => this.props.notificationShow(msg_logged_out_inactivity, 'error'));
    this.props.clearStore();
  }

  restartInactivityProcedure = () => {
    const { environmentVars: { AccountAccessNoFrontEndActivityTimeout } } = this.props;
    if (!this.state.inactivityWarningOn) {
      // reset all timers
      const noFrontEndActivityTimeoutInMs = AccountAccessNoFrontEndActivityTimeout * 1000;
      clearTimeout(this.inactivityWarningTimer);
      this.inactivityWarningTimer = setTimeout(() => this.setState({ inactivityWarningOn: true }), noFrontEndActivityTimeoutInMs);
    }
  }

  cancelInactivityWarning = () => {
    clearTimeout(this.inactivityWarningTimer);
    this.setState({ inactivityWarningOn: false });
  };

  componentDidMount() {
    if (this.props.isValid) {
      windowEvents.forEach(e => window.addEventListener(e, this.restartInactivityProcedure));
      this.restartInactivityProcedure();
    }

    window.onload = () => {
      this.props.allNotificationsHide();
    };
  }

  componentWillUnmount() {
    clearTimeout(this.inactivityWarningTimer);
    windowEvents.forEach(e => window.removeEventListener(e, this.restartInactivityProcedure));
  }

  render() {
    const { environmentVars: { LogoutWarningTimeout }, component, isValid, text: { ProtectedRoute, Login }, ...rest } = this.props;
    const { inactivityWarningOn } = this.state;
    const logoutWarningTimeoutInMs = LogoutWarningTimeout * 1000;

    return (
      <Route {...rest} {...component} render={() => {
        return isValid ?
          <div>

            {this.handleAccountBlocks()}

            {this.handleRequiredAccess()}

            <LogoutWarning
              logoutWarningInterval={logoutWarningTimeoutInMs}
              parseWarningMsg={ProtectedRoute.msg_logged_out_inactivity_warning}
              modalTitle={ProtectedRoute.title_logged_out_inactivity_warning}
              closeButtonLabel={ProtectedRoute.btn_close}
              onWarningClose={this.cancelInactivityWarning}
              warningOn={inactivityWarningOn}
              onTimeout={this.inactivityLogout}
              onCloseContinueWithTimeout={false}
            />
          </div>
          :
          <Redirect
            push
            to={{ pathname: Login.nav_path }}
          />;
      }}
      />
    );
  }
}

export default connect(select, {
  notificationShow,
  allNotificationsHide,
  userLogout,
  clearStore,
  twoFaRouteChange,
})(LanguageHOC(ProtectedRoute));