import { cloneDeep } from 'lodash';

import {
  USER_NAME_FORGOT,
} from './constants';

const initialState = {
  errors: [],
};

function ForgotUserNameReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {
    
    case USER_NAME_FORGOT: {
      // debugger; // eslint-disable-line
      return newState;
    }

    default:
      return state;
  }
}

export default ForgotUserNameReducer;