/*
*
* TransactionButtons Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
  Icon,
} from '@mui/material';

import {
  ContributionIcon,
  OptionChangeIcon,
  WithdrawalIcon,
} from '@frontend/common';

import AccountListDialog from './AccountListDialog';

import { MULTIFACTOR_TYPE } from 'components/AppRoot/Navigation/constants';
import { protectAction, protectWithdrawal } from 'utils/helpers/multifactor_handler';
import { get2FAPrecondition, getRSAPrecondition } from 'components/AppRoot/Navigation/actions';
import { openPreflightModal, setPreflightParams } from 'components/Features/protected/Accounts/Transactions/actions';

import * as colors from 'utils/config/_sassconfig.scss';

import transactionHubStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const select = (state) => ({
  claims: state.session.claims,
  preferredInfo: state.multifactor.preferredInfo,
  accountList: state.accounts.accountList,
});

export class TransactionButtons extends React.Component {
  static propTypes = {
    accountList: PropTypes.arrayOf(
      PropTypes.shape({
        accountId: PropTypes.number.isRequired,
        accountNumber: PropTypes.number.isRequired,
        accountTypeEnum: PropTypes.string.isRequired,
        beneficiary: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        option: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
        permissions: PropTypes.shape({
          Contribute: PropTypes.bool,
          OptionChange: PropTypes.bool,
          Transfer: PropTypes.bool,
          Withdraw: PropTypes.bool,
          WithdrawalRequest: PropTypes.bool,
        })
      })
    ).isRequired,
    claims: PropTypes.shape({
      TwoFactorAuthentication: PropTypes.string,
      RSAVerification: PropTypes.string,
    }).isRequired,
    get2FAPrecondition: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    openPreflightModal: PropTypes.func.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    setPreflightParams: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Transactions: PropTypes.object.isRequired,
    }),
  };

  state = {
    accountListOpen: false,
    accountsWithPermission: [],
    hasPrecondition: false,
    preflightAccount: {},
    buttonTransactionType: '',
    preflightTransactionType: '',
    hideButtons: {
      contribute: this.props.accountList.find(data => data.permissions.Contribute === true) === undefined,
      optionChange: this.props.accountList.find(data => data.permissions.OptionChange === true) === undefined,
      transfer: this.props.accountList.find(data => data.permissions.Transfer === true) === undefined,
      withdraw: this.props.accountList.find(data => data.permissions.Withdraw === true || data.permissions.RequestWithdraw === true) === undefined,
    }
  };

  mapPermissionByTransaction = (transactionType, permissions) => {
    const { text: { Transactions } } = this.props;
    switch (transactionType) {
      case Transactions.TRANSACTION_TYPES.WITHDRAWAL.type:
        return permissions.Withdraw;
      case Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type: // CSA
        return permissions.WithdrawalRequest;
      case Transactions.TRANSACTION_TYPES.CONTRIBUTION.type:
        return permissions.Contribute;
      case Transactions.TRANSACTION_TYPES.TRANSFER.type:
        return permissions.Transfer;
      case Transactions.TRANSACTION_TYPES.OPTION_CHANGE.type:
        return permissions.OptionChange;
      default:
        return false;
    }
  }

  isCSAWithdrawalRequest = (account, transactionType, Transactions) => transactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL.type && account.accountTypeEnum === 'D';

  handleOpen = (buttonTransactionType) => {
    const { accountList, text: { Transactions } } = this.props;
    // first filter out by their permissions
    const accountsWithPermission = accountList
      .filter((account) => {
        // when doing withdrawal switch to withdrawal request for CSA accounts
        const updatedTransactionType = this.isCSAWithdrawalRequest(account, buttonTransactionType, Transactions) ? Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type : buttonTransactionType;
        return this.mapPermissionByTransaction(updatedTransactionType, account.permissions);
      });
    this.setState({
      accountListOpen: true,
      buttonTransactionType,
      accountsWithPermission
    });
  }

  handleClose = (selectedAccountId) => {
    const { buttonTransactionType } = this.state;
    const { text: { Transactions } } = this.props;
    this.setState({ accountListOpen: false });
    // fire up the preflight here, if no account selected account id will be null
    if (selectedAccountId) {
      const selectedAccount = this.state.accountsWithPermission.find((account) => account.accountId === selectedAccountId);
      // when doing withdrawal switch to withdrawal request for CSA accounts
      const updatedTransactionType = this.isCSAWithdrawalRequest(selectedAccount, buttonTransactionType, Transactions) ? Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type : buttonTransactionType;
      this.preflightCheck(selectedAccount, updatedTransactionType);
    }
  };

  handleClickOpen = () => {
    this.setState({ accountListOpen: true });
  }

  preflightCheck = (account, transactionType) => {
    const { text: { Transactions }, preferredInfo, claims } = this.props;

    this.setState({
      preflightTransactionType: transactionType,
      preflightAccount: account,
    });

    const isWithdrawal = transactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL.type || transactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type;
    const multiStatus = isWithdrawal
      ? protectWithdrawal(transactionType, Transactions, preferredInfo, claims)
      : protectAction(claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      /* 
      need to pass if is a withdrawal or not for new accounts = (oldest AO account < 45 days old)
      if is a new account, is a withdrawal, requires 2FA and RSA
      */
      this.setState(
        { hasPrecondition: true },
        () => this.props.get2FAPrecondition(isWithdrawal).catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        { hasPrecondition: true },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      this.props.setPreflightParams(account, transactionType);
      this.setState({
        hasPrecondition: false,
        preflightAccount: {},
        buttonTransactionType: '',
        preflightTransactionType: ''
      });
      this.props.openPreflightModal();
    }
  }

  componentDidUpdate() {
    const { hasPrecondition, preflightAccount, preflightTransactionType } = this.state;
    const { text: { Transactions }, preferredInfo, claims } = this.props;
    const isWithdrawal = preflightTransactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL.type || preflightTransactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type;
    const multiStatus = isWithdrawal
      ? protectWithdrawal(preflightTransactionType, Transactions, preferredInfo, claims)
      : protectAction(claims);
    if (hasPrecondition && multiStatus === MULTIFACTOR_TYPE.AUTH) {
      this.preflightCheck(preflightAccount, preflightTransactionType);
    }
  }

  render() {
    const { accountListOpen, buttonTransactionType, accountsWithPermission, hideButtons } = this.state;
    const { text: { Transactions } } = this.props;
    return (
      <>
        <div className={transactionHubStyles.transactionButtons}>
          <div className={styles.transactionButton} hidden={hideButtons.contribute}>
            <Button
              variant='contained'
              startIcon={<ContributionIcon color='#fff' />}
              fullWidth
              size='large'
              style={{ height: '3em', whiteSpace: 'nowrap' }}
              onClick={() => this.handleOpen(Transactions.TRANSACTION_TYPES.CONTRIBUTION.type)}
            >
              {Transactions.TRANSACTION_TYPES.CONTRIBUTION.title}
            </Button>
          </div>

          <div className={styles.transactionButton} hidden={hideButtons.transfer}>
            <Button
              variant='contained'
              startIcon={<Icon>swap_horiz</Icon>}
              fullWidth
              size='large'
              style={{ height: '3em', whiteSpace: 'nowrap' }}
              onClick={() => this.handleOpen(Transactions.TRANSACTION_TYPES.TRANSFER.type)}
            >
              {Transactions.TRANSACTION_TYPES.TRANSFER.title}
            </Button>
          </div>

          <div className={styles.transactionButton} hidden={hideButtons.withdraw}>
            <Button
              variant='contained'
              startIcon={<WithdrawalIcon color='#fff' />}
              fullWidth
              size='large'
              style={{ height: '3em', whiteSpace: 'nowrap' }}
              onClick={() => this.handleOpen(Transactions.TRANSACTION_TYPES.WITHDRAWAL.type)}
            >
              {Transactions.TRANSACTION_TYPES.WITHDRAWAL.title}
            </Button>
          </div>

          <div className={styles.transactionButton} hidden={hideButtons.optionChange}>
            <Button
              variant='contained'
              startIcon={<OptionChangeIcon color={colors.primary} />}
              fullWidth
              size='large'
              style={{ height: '3em', whiteSpace: 'nowrap' }}
              onClick={() => this.handleOpen(Transactions.TRANSACTION_TYPES.OPTION_CHANGE.type)}
            >
              {Transactions.TRANSACTION_TYPES.OPTION_CHANGE.title}
            </Button>
          </div>
        </div>

        <AccountListDialog
          open={accountListOpen}
          onClose={this.handleClose}
          accounts={accountsWithPermission}
          transactionTitle={buttonTransactionType ? Transactions.transaction_title(buttonTransactionType) : ''}
          transactionSubtitle={buttonTransactionType ? Transactions.transactionhub_account_list_subtitle(buttonTransactionType) : ''}
        />
      </>
    );
  }
}


export default connect(select, {
  get2FAPrecondition,
  getRSAPrecondition,
  openPreflightModal,
  setPreflightParams,
})(LanguageHOC(TransactionButtons));
