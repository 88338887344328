/*
*
* PersistentDrawer Component
*
*/
import React, { Fragment, Children } from 'react';
import PropTypes from 'prop-types';

import {
  Divider,
  Icon,
  Drawer,
  List,
  ListItem,
  Tooltip,
} from '@mui/material';

import {
  IconBtnTooltip,
  sizify,
} from '@frontend/common';

import styles from './styles.module.scss';

export class LeftMenuItem extends React.Component {
  static displayName = 'LeftMenuItem';
  render() {
    return this.props.children;
  }
}

export class LeftMenuItems extends React.Component {
  static displayName = 'LeftMenuItems';
  render() {
    return this.props.children;
  }
}

export class Row extends React.Component {
  static displayName = 'Row';
  render() {
    return this.props.children;
  }
}

export class RightDrawer extends React.Component {
  static displayName = 'RightDrawer';
  render() {
    return this.props.children;
  }
}

export class PersistentDrawers extends React.Component {

  static propTypes = {
    drawerWidth: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number.isRequired,
    }).isRequired,
    marginTop: PropTypes.string,
    onDrawerToggle: PropTypes.func, // if parent wants to track toggle clicks
    toggleDrawerIndex: PropTypes.number, // if parent wants to trigger toggle drawer with this index
    preventOpeningDrawers: PropTypes.bool, // if parent wants to prevent any toggles but still keep track of any toggle clicks
  };

  state = {
    rowsOpen: [],
  };

  handleDrawerToggle = (drawerIndex) => {
    const { onDrawerToggle } = this.props;

    if (!this.props.preventOpeningDrawers) {
      this.toggleDrawerByIndex(drawerIndex);
    }

    onDrawerToggle && onDrawerToggle(drawerIndex);
  };

  toggleDrawerByIndex = drawerIndex => {
    const rowsOpen = this.state.rowsOpen.map(() => false); // first close all drawers
    rowsOpen[drawerIndex] = !this.state.rowsOpen[drawerIndex];
    this.setState({ rowsOpen });
  }

  isDrawerOpen = () => this.state.rowsOpen.reduce((rowOpen, isOneOpen) => rowOpen || isOneOpen, false);

  componentDidMount() {
    const rowsOpen = [];
    Children.toArray(this.props.children)
      .filter((child) => child)
      .filter((child) => child.type.displayName === 'Row')
      .map(() => rowsOpen.push(false));
    this.setState({ rowsOpen });
  }

  componentDidUpdate(prevProps) {
    const { toggleDrawerIndex } = this.props;
    if (toggleDrawerIndex && toggleDrawerIndex > -1 && prevProps.toggleDrawerIndex !== toggleDrawerIndex) {
      this.toggleDrawerByIndex(toggleDrawerIndex);
    }
  }

  render() {
    const { size, drawerWidth, disabled, marginTop } = this.props;

    let isDrawerFullWidth = false;
    let drawerWidthCalculated = drawerWidth;
    if (size.windowWidth < 650) {
      drawerWidthCalculated = '100%';
      isDrawerFullWidth = true;
    }
    const mainMarginRight = this.isDrawerOpen() ? drawerWidthCalculated : '0px';

    const rows = Children.toArray(this.props.children)
      .filter((child) => child)
      .filter((child) => child.type.displayName === 'Row');

    const leftMenuItems = rows
      .map((row) => row.props.children
        .filter((child) => child)
        .find(child => child.type.displayName === 'LeftMenuItems'));

    const drawers = rows
      .map((row) => row.props.children
        .filter((child) => child)
        .find((child) => child.type.displayName === 'RightDrawer'));

    return (
      <div>
        <main
          style={{ marginRight: `${mainMarginRight}`, transition: 'margin-right 0.2s', transitionTimingFunction: 'linear' }}
        >
          <List component='nav' aria-label='My Info'>
            {
              leftMenuItems.map((items, itemsIndex) => {
                return (
                  <Fragment key={`items_${itemsIndex}`}>
                    <ListItem
                      button
                      onClick={() => this.handleDrawerToggle(itemsIndex)}
                      className={styles.row}
                      disableGutters={isDrawerFullWidth}
                      disabled={disabled}
                    >
                      <div className={styles.forMobile}>
                        {items.props.children
                          .filter(child => child.type.displayName === 'LeftMenuItem')
                          .map((leftMenuItem, itemIndex) => {
                            const firstColStyle = itemIndex === 0 ? styles.title : '';
                            return (
                              <div key={`item_${itemIndex}`} className={`${styles.col} ${firstColStyle}`}>
                                {leftMenuItem.props.children}
                              </div>
                            );
                          })
                        }
                      </div>

                      {<div className={`${styles.col} ${styles.arrow} ${styles.end}`}>
                        <Tooltip title={this.state.rowsOpen[itemsIndex] ? 'Close' : 'Open'}><Icon>{this.state.rowsOpen[itemsIndex] ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}</Icon></Tooltip>
                      </div>}
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              })
            }
          </List>
        </main>
        <div>
          <div>
            {
              drawers.map((rightDrawer, index) => (
                <Fragment key={`row_${index}`}>
                  <Drawer
                    variant='persistent'
                    anchor='right'
                    open={this.state.rowsOpen[index]}
                    PaperProps={{
                      style: { width: drawerWidthCalculated, height: `calc(100% - ${marginTop})`, marginTop } // margin-top and height need to be recalculated at the same time
                    }}
                  >
                    <div className={styles.header}>
                      {isDrawerFullWidth &&
                        <div className={`${styles.closeButtonArrow}`}>
                          <IconBtnTooltip
                            icon='keyboard_arrow_left'
                            onClick={() => this.handleDrawerToggle(index)}
                            title='Close'
                          />
                        </div>
                      }
                      <div className={`${styles.drawerHeaderItem}`}>
                        {rightDrawer.props.title}
                      </div>
                      <div className={`${styles.closeButton}`}>
                        <IconBtnTooltip
                          onClick={() => this.handleDrawerToggle(index)}
                          icon='close'
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className={styles.drawerContent}>
                      {rightDrawer}
                    </div>
                  </Drawer>
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default sizify(PersistentDrawers);