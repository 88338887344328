/*
*
* AddMissingEmailWhileBlocked Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { camelCase } from 'lodash';
import { TextField } from '@mui/material';
import {
  Modal,
  notificationShow,
} from '@frontend/common';
import { emailValidator } from 'utils/helpers/form_validation';
import { changeEmail } from 'components/Features/protected/MyInfo/actions';
import {
  clearStore,
  userLogout
} from 'components/AppRoot/Navigation/actions';

const select = (state) => ({
  supportNumber: state.static.environmentVars.SupportPhoneNumber,
});


export class AddMissingEmailWhileBlocked extends React.Component {

  static propTypes = {
    changeEmail: PropTypes.func.isRequired,
    clearStore: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    supportNumber: PropTypes.string.isRequired,
    text: PropTypes.shape({
      AddMissingEmailWhileBlocked: PropTypes.shape({
        text_missing_email1: PropTypes.string,
        text_missing_email2: PropTypes.string,
        text_missing_email3: PropTypes.string,
      }),
      EmailCard: PropTypes.shape({
        btn_cancel_change_email: PropTypes.string,
        btn_confirm_submit_email: PropTypes.string,
        btn_resend_email: PropTypes.string,
        err_different_email: PropTypes.string,
        err_emails_must_match: PropTypes.string,
        err_required: PropTypes.string,
        err_valid_email: PropTypes.string,
        head_missing_email: PropTypes.string,
        lbl_new_email: PropTypes.string,
        lbl_new_email_confirm: PropTypes.string,
        msg_check_email_to_verify: PropTypes.func,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
        nav_path: PropTypes.string,
      }),
    }).isRequired,
    userLogout: PropTypes.func.isRequired,
  };

  state = {
    apiErrors: [],
    formErrors: [],
    isSaving: false,
    newEmail: '',
    newEmailConfirm: '',
  };
  
  emailSubmit = () => {
    const { newEmail } = this.state;

    if (this.formValidate()) {
      this.setState({ isSaving: true });
      this.props.changeEmail(newEmail)
        .then(() => {
          const token = sessionStorage.getItem('token');
          this.props.userLogout({ token })
            .finally(() => {
              this.props.clearStore();
              this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success');
              this.props.notificationShow(this.props.text.EmailCard.msg_check_email_to_verify(this.props.supportNumber), 'warning');
            });
        })
        .catch(response => {
          this.setState({
            apiErrors: response.payload.data,
            isSaving: false
          }, () => this.formValidate());
        });
    }
  }
  errorGet = name => {
    const error = this.state.formErrors.find(error => error.name === name);
    return error ? error.message : '';
  }

  formValidate = () => {
    const { apiErrors, newEmail, newEmailConfirm } = this.state;
    const { err_emails_must_match, err_required } = this.props.text.EmailCard;
    const formErrors = [];

    if (!newEmail) {
      formErrors.push({
        name: 'newEmail',
        message: err_required
      });
    }

    if (!newEmailConfirm) {
      formErrors.push({
        name: 'newEmailConfirm',
        message: err_required
      });
    }

    if (newEmail) {
      const emailError = emailValidator(newEmail);
      emailError && formErrors.push({
        name: 'newEmail',
        message: emailError
      });
    }

    if (newEmail !== newEmailConfirm) {
      formErrors.push({
        name: 'newEmailConfirm',
        message: err_emails_must_match
      });
    }

    if (apiErrors.length > 0) {
      apiErrors.forEach(error => {
        if (error.Field === 'Email') {
          formErrors.push({
            name: 'newEmail',
            message: error.Message
          });
        }
        else {
          formErrors.push({
            name: camelCase(error.Field),
            message: error.Message
          });
        }
      });
    }

    this.setState({
      apiErrors: [],
      formErrors
    });

    return formErrors.length === 0;
  }

  onInputChange = (event, name) => {
    this.setState({
      [name]: event.target.value,
      formErrors: this.state.formErrors.filter(error => error.name !== name)
    });
  }

  render() {
    const { AddMissingEmailWhileBlocked, EmailCard, } = this.props.text;
    const { newEmail, newEmailConfirm } = this.state;

    return (
      <Modal
        actionButtons={[
          {
            action: () => this.emailSubmit(),
            buttonType: 'contained',
            label: EmailCard.btn_confirm_submit_email,
            loading: this.state.isSaving,
          }
        ]}
        DialogProps={{ 'aria-labelledby': EmailCard.btn_confirm_submit_email }}
        onCloseModal={() => null}
        show={true}
        title={EmailCard.head_missing_email}
      >
        <div>
          <p>{AddMissingEmailWhileBlocked.text_missing_email1}</p>
          <p>{AddMissingEmailWhileBlocked.text_missing_email2}</p>
          <p>{AddMissingEmailWhileBlocked.text_missing_email3}</p>
          <TextField
            error={Boolean(this.errorGet('newEmail'))}
            helperText={this.errorGet('newEmail')}
            fullWidth
            label={EmailCard.lbl_new_email}
            name='newEmail'
            onChange={e => this.onInputChange(e, 'newEmail')}
            value={newEmail}
          />
          <TextField
            error={Boolean(this.errorGet('newEmailConfirm'))}
            fullWidth
            helperText={this.errorGet('newEmailConfirm')}
            id='newEmailConfirm'
            label={EmailCard.lbl_new_email_confirm}
            name='newEmailConfirm'
            onChange={e => this.onInputChange(e, 'newEmailConfirm')}
            value={newEmailConfirm}
          />
        </div>
      </Modal>
    );
  }
}


export default connect(select, {
  changeEmail,
  clearStore,
  notificationShow,
  userLogout,
})(LanguageHOC(AddMissingEmailWhileBlocked));
