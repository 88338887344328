/*
*
* Header
*
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bowser from 'bowser';

import my529LogoColors from './my529LogoColorsTransp.png';

import LanguageHOC from 'utils/translations/LanguageHOC';
// import LanguageMenu from './LanguageMenu'; // TRANSLATIONS uncomment to activate LanguageMenu
import {
  IconBtnTooltip,
} from '@frontend/common';

import {
  Badge,
  Icon,
  IconButton,
  Tooltip,
} from '@mui/material';

import {
  changeLanguage,
  setBrowserWarning,
  toggleSidenav,
  toggleNotifications,
} from 'components/AppRoot/Navigation/actions';

import { getEnvironmentVars, getStaticDocuments, getWebMessages, setLoadingPublicVars } from 'components/AppRoot/StaticResources/actions';

import styles from './styles.module.scss';

const bowser = Bowser.getParser(window.navigator.userAgent).parsedResult;

const select = (state) => ({
  environmentVars: state.static.environmentVars,
  isValid: state.session.isValid,
  accountBlocked: state.session.accountBlocked,
  language: state.session.language,
  showBrowserWarning: state.session.showBrowserWarning,
  showMobileWarning: state.session.showMobileWarning,
  usStates: state.static.usStates,
  staticDocuments: state.static.documents,
  sidenavOpen: state.session.sidenavOpen,
  notificationsOpen: state.session.notificationsOpen,
  numberOfNotViewedNotifications: state.session.numberOfNotViewedNotifications,
  isIpOnly_AccessRole: state.session.isIpOnly_AccessRole,
});

export class Header extends Component {
  static propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    environmentVars: PropTypes.object.isRequired,
    staticDocuments: PropTypes.object,
    getEnvironmentVars: PropTypes.func.isRequired,
    getStaticDocuments: PropTypes.func.isRequired,
    getWebMessages: PropTypes.func.isRequired,
    setLoadingPublicVars: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    accountBlocked: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    pageTitle: PropTypes.string,
    setBrowserWarning: PropTypes.func.isRequired,
    showBrowserWarning: PropTypes.bool.isRequired,
    showMobileWarning: PropTypes.bool.isRequired,
    sidenavOpen: PropTypes.bool.isRequired,
    notificationsOpen: PropTypes.bool.isRequired,
    numberOfNotViewedNotifications: PropTypes.number.isRequired,
    isIpOnly_AccessRole: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Header: PropTypes.shape({
        btn_main_menu: PropTypes.string,
        text_browser_warning: PropTypes.string,
        text_notifications: PropTypes.string,
      })
    }).isRequired,
    toggleSidenav: PropTypes.func.isRequired,
    toggleNotifications: PropTypes.func.isRequired,
    usStates: PropTypes.array.isRequired,
  };

  languageSet = () => {
    if (!localStorage.getItem('my529-language')) {
      this.props.changeLanguage(this.props.language);
    }
  }

  warningsCompile() {
    const { text_browser_warning } = this.props.text.Header;
    if (this.props.showBrowserWarning && bowser.browser.name === 'Internet Explorer') {
      return this.warningMessageCompose('browser', text_browser_warning);
    }
    else {
      return null;
    }
  }

  warningMessageCompose(warningType, message) {
    return (
      <div className={styles.browserWarningContainer}>
        <span>{message}</span>
        <div
          className={styles.browserWarningClose}
          onClick={() => this.props.setBrowserWarning()}
        >
          <Icon className='material-icons'>close</Icon>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const apiCalls = [];

    if (!this.props.staticDocuments.programDescription) {
      apiCalls.push(this.props.getStaticDocuments());
    }

    if (!this.props.isValid && this.props.usStates.length === 0) { // Prevents call for environmentVars if already in state. Need this to prevent a race condition where a user lands on /login from the /validate-email link, notifications are hidden
      apiCalls.push(this.props.getEnvironmentVars());
    }

    apiCalls.push(this.props.getWebMessages());

    this.props.setLoadingPublicVars(true);
    Promise.all(apiCalls)
      .then(() => this.props.setLoadingPublicVars(false))
      .catch(() => this.props.setLoadingPublicVars(false));

    this.languageSet();
  }

  render() {
    const {
      isValid,
      accountBlocked,
      pageTitle,
      sidenavOpen,
      notificationsOpen,
      numberOfNotViewedNotifications,
      isIpOnly_AccessRole,
      text,
    } = this.props;

    if (!accountBlocked && isValid) {
      return (
        <div className={`${styles.navContainer} hideOnPrint`}>
          <header className={styles.navHeader}>
            <div className={styles.left}>
              <div className={styles.darkerBackground}>
                <div className={styles.menuIcon}>
                  <IconBtnTooltip
                    buttonProps={{ style: { color: '#000' } }}
                    icon='menu'
                    onClick={() => this.props.toggleSidenav(!sidenavOpen)}
                    title={text.Header.btn_main_menu}
                  />
                </div>
                <img className={styles.logoHeader} alt='my529' src={my529LogoColors} height='50px' />
              </div>
              <div className={styles.vertLine} />
              <h1 className={styles.pageTitle}>
                {pageTitle}
              </h1>
            </div>
            {!isIpOnly_AccessRole &&
              <div className={styles.right}>
                <div className={styles.notificationIcon} id-='notificationIcon'>
                  <Tooltip title={text.Header.text_notifications}>
                    <IconButton
                      aria-label={text.Header.text_notifications}
                      onClick={() => this.props.toggleNotifications(!notificationsOpen)}
                      style={{ color: '#000' }}
                    >
                      <Badge badgeContent={numberOfNotViewedNotifications} color='error'>
                        <Icon>notifications</Icon>
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            }
          </header>
          {this.warningsCompile()}
        </div>
      );
    }
    else {
      return (
        <div className='hideOnPrint'>
          <header className={styles.publicNavHeader}>
            <h1 className={styles.pageTitle}>
              {pageTitle}
            </h1>
            {/* TRANSLATIONS uncomment to activate translation menu */}
            {/* <LanguageMenu /> */}
          </header>
          {this.warningsCompile()}
        </div>
      );
    }
  }
}

export default connect(select, {
  getEnvironmentVars,
  getStaticDocuments,
  getWebMessages,
  setLoadingPublicVars,
  changeLanguage,
  setBrowserWarning,
  toggleSidenav,
  toggleNotifications,
})(LanguageHOC(Header));