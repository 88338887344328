/* eslint-disable brace-style */
// This file does not need to be maintained. It is only for development testing purposes.
const buttons = {
  cancel: 'SPA Cancel',
  close: 'SPA Close',
  delete: 'SPA Delete',
  submit: 'SPA Submit',
  update: 'SPA Update',
};
const errors = {
  required: 'SPA Field is required.'
};
const intl_address = 'SPA Do you have an international address? If so, please submit form 500, Account Information Change';
const unsaved_changes = 'SPA Are you sure you want to leave? There are unsaved changes on this page.';

module.exports = {
  AccountDetails: {
    head_account_number: 'SPA Account Number',
    head_account_type: 'SPA Account Type',
    head_current_tax_year_contributions: 'SPA Current Tax Year to Date Contributions',
    head_earnings: 'SPA Earnings',
    head_investment_option: 'SPA Investment Option',
    head_mailing_address: 'SPA Mailing',
    head_netPrincipal: 'SPA Net Principal',
    head_other_address: 'SPA Other',
    head_physical_address: 'SPA Physical',
    head_prior_tax_year_contributions: 'SPA Prior Tax Year Contributions',
    head_remaining_option_changes: 'SPA Remaining Option Changes',
    head_title: 'SPA Account Details',
    head_title_account_details: 'SPA Details',
    head_title_activity_summary: 'SPA Activity Summary',
    head_title_beneficiary_contact: 'SPA Beneficiary Contact',
    head_total_value: 'SPA Total Value',
    nav_path(accountId) { return `/es/accounts/${accountId}`; }, // Will need some clarification on what needs to be translated
    text_none: 'SPA None',
  },
  AccountOwnerCard: {
    head_title: 'SPA Account Owner',
    lbl_first_name: 'SPA First Name',
    lbl_last_name: 'SPA Last Name',
    lbl_middle_name: 'SPA Middle Name',
    lbl_utah_resident: 'SPA Utah Resident',
    msg_residency_saved: 'SPA Utah resident status saved.',
  },
  Accounts: {
    head_title: 'SPA Accounts',
    nav_path: '/es/accounts',
    nav_title: 'SPA Accounts',
  },
  AccountsSummary: {
    head_title: 'SPA Account Summary',
    head_title_plural: 'SPA Accounts Summary'
  },
  AddressCard: {
    btn_delete(addressType) { return `${buttons.delete} ${addressType}`; },
    btn_update(addressType) { return `${buttons.update} ${addressType}`; },
    err_postal_code_length: 'SPA Postal code must be 5 or 9 digits long.',
    err_required: errors.required,
    head_title: 'SPA Addresses',
    msg_address_deleted: 'SPA Address deleted.',
    msg_address_updated: 'SPA Address updated.',
    msg_unsaved_changes: unsaved_changes,
    text_intl_address: intl_address,
  },
  BeneContactCard: {
    btn_delete: buttons.delete,
    btn_update: buttons.update,
    head_title: 'SPA Beneficiary Contact',
    msg_address_deleted: 'SPA Address deleted.',
    msg_address_updated: 'SPA Address updated.',
    msg_unsaved_changes: unsaved_changes,
    text_intl_address: intl_address,
  },
  default_title: 'SPA my529 Account Access Portal',
  EmailCard: {
    head_title: 'SPA Email',
    lbl_email: 'SPA Email',
  },
  ForgotPassword: {
    btn_cancel: buttons.cancel,
    btn_submit: buttons.submit,
    err_password: 'SPA Password is required.',
    err_username: 'SPA Username is required.',
    head_title: 'SPA Forgot Password',
    msg_success: 'SPA Request submitted. Check your email.',
    nav_path: '/es/forgot-password',
    text_instructions: 'SPA If the provided username is found, password reset instructions will be sent to the associated email address.',
    text_username: 'SPA Username',
  },
  ForgotUsername: {
    head_title: '/es/forgot-username',
    nav_path: '/es/forgot-username',
  },
  Header: {
    text_browser_warning: 'SPA You are currently using a browser that is not supported. For the best experience, please use Chrome or Firefox.',
    text_mobile_warning: 'SPA Mobile browsing of this website is currently in beta. For full functionality, please use a desktop browser.'
  },
  Login: {
    btn_login: 'SPA Log In',
    err_password: 'SPA Password is required.',
    err_username: 'SPA Username is required.',
    head_title: 'SPA Login',
    lbl_password: 'SPA Password',
    lbl_username: 'SPA Username',
    msg_you_have_logged_out: 'SPA You have logged out.',
    nav_forgot_password: 'SPA Forgot Password?',
    nav_forgot_username: 'SPA Forgot Username?',
    nav_path: '/es/login',
  },
  MyInfo: {
    head_title: 'SPA My Info',
    head_utah_resident: 'SPA Utah Resident',
    nav_path: '/es/my-info',
    nav_title: 'SPA My Info',
    text_residency_mismatch: 'SPA The selected state does not match your declared Utah residency status. Are you sure you wish to continue?',
  },
  Navigation: {
    menu_english: 'SPA English',
    menu_spanish: 'SPA Español',
    text_previous_login: 'SPA Previous Login',
    menu_log_out: 'SPA Log out',
    menu_profile: 'SPA Profile',
    text_version: 'SPA Version'
  },
  PageNotFound: {
    btn_back_to_accounts: 'SPA Back to Accounts',
    text_oops: "SPA Oops! Looks like you reached a page that doesn't exist.", // eslint-disable-line
  },
  PasswordCard: {
    btn_change_password: 'SPA Update Password',
    err_required: errors.required,
    err_password_criteria: 'SPA Password does not meet requirements.',
    err_confirm_password: 'SPA Confirmed password does not match above password.',
    msg_password_updated: 'SPA Password updated.',
    lbl_current_password: 'SPA Current Password',
    lbl_new_password: 'SPA New Password',
    lbl_confirm_password: 'SPA Confirm New Password',
    head_title: 'SPA Password',
  },
  PhoneNumbersCard: {
    btn_change_phone: 'SPA Update Phone Numbers',
    head_title: 'SPA Phone Numbers',
    lbl_cell: 'SPA Cell Phone',
    lbl_fax: 'SPA Fax',
    lbl_home: 'SPA Home Phone',
    lbl_other: 'SPA Other Phone',
    lbl_work: 'SPA Work Phone',
    msg_phone_numbers_updated: 'SPA Phone numbers updated.',
    msg_unsaved_changes: unsaved_changes,
  },
  ResetPassword: {
    err_confirm_password: 'SPA Confirm Password is required.',
    err_password_criteria: 'SPA Password does not meet criteria below.',
    err_password_match: 'SPA Passwords must match.',
    err_password_required: 'SPA Password is required.',
    head_title: 'SPA Reset Password',
    msg_password_changed: 'SPA Password changed.',
    nav_path: '/es/reset-password',
    text_new_password: 'SPA New Password',
    text_confirm_password: 'SPA Confirm new password',
    btn_submit: 'SPA Submit',

  },
  SuccessorsCard: {
    btn_add_successor(type) { return `SPA Add ${type} Successor`; },
    btn_change_successor(type) { return `SPA Change ${type} Successor`; },
    btn_close: buttons.close,
    err_age_limits(minAge, maxAge) { return `SPA Age must must be between ${minAge} and ${maxAge}.`; }, // Will require explanation
    err_required: errors.required,
    head_remove_successor(type) { return `SPA Remove ${type} Successor`; },
    head_title: 'SPA Successors',
    lbl_birth_date: 'SPA Successor Date of Birth',
    lbl_first_name: 'SPA First name',
    lbl_last_name: 'SPA Last name',
    lbl_middle_name: 'SPA Middle Name',
    lbl_ssn: 'SPA Social Security Number',
    lbl_successor: 'SPA Successor',
    menu_new_successor: 'SPA New Successor',
    msg_successor_added: 'SPA Successor added.',
    msg_successor_changed: 'SPA Successor changed.',
    msg_successor_removed: 'SPA Successor removed.',
    text_confirm_remove_successor(first, mid, last, type) { return `SPA Do you want to remove ${first} ${mid} ${last} as ${type} successor?`; },
    text_none: 'SPA None',
    text_successor_to_trust: 'SPA To designate or change the successor to a trust or institution, you must complete and submit Form 515 to my529.',
  },
  UsernameCard: {
    head_title: 'SPA Username',
    lbl_username: 'SPA Username',
  },
  ValidateEmail: {
    nav_path: '/es/validate-email',
  },
};