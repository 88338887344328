/*
*
* TransferStepOneContent Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  reduce,
  some,
} from 'lodash';

import {
  currencyFormatter,
} from '@frontend/common';

import {
  Checkbox,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  Button,
} from '@mui/material';

import DualPushButtonSwitch from './DualPushButtonSwitch';
import { TransferTypes, TransferInputTypes } from '../typeConstants';
import TargetAccount from '../TargetAccount';
import { formatAccountDisplayText } from '../formatAccountDisplayText';

import transactionsStyles from 'components/Features/protected/Accounts/Transactions/styles.module.scss';
import styles from './styles.module.scss';

export function onKeyUp(showFullBalanceDialog) {
  return (e) => {
    // enabling keyboard accessibility
    if (e.key === 'Enter' || e.key === 'Space') {
      showFullBalanceDialog();
    }
  };
}

TransferStepOneContent.propTypes = {
  sourceAccount: PropTypes.object.isRequired,
  destinationAccounts: PropTypes.array.isRequired,
  transfer: PropTypes.object.isRequired,
  onTransferChange: PropTypes.func.isRequired,
  onTargetChange: PropTypes.func.isRequired,
  addTargetAccount: PropTypes.func.isRequired,
  removeTargetAccount: PropTypes.func.isRequired,
  showFullBalanceDialog: PropTypes.func.isRequired,
  getErrorForInput: PropTypes.func.isRequired,
  text: PropTypes.shape({
    Transfers: PropTypes.shape({
      lbl_transfer_type: PropTypes.string,
      lbl_partial_transfer: PropTypes.string,
      lbl_source_account: PropTypes.string,
      lbl_leave_open: PropTypes.string,
      lbl_transfer_types: PropTypes.string,
      lbl_radio_amount: PropTypes.string,
      lbl_radio_percentage: PropTypes.string,
      lbl_total: PropTypes.string,
      lbl_add_account: PropTypes.string,
    }),
  })
};

export function TransferStepOneContent(props) {
  const {
    sourceAccount,
    destinationAccounts,
    transfer,
    onTransferChange,
    onTargetChange,
    addTargetAccount,
    removeTargetAccount,
    showFullBalanceDialog,
    getErrorForInput,
    text: { Transfers },
  } = props;

  const amountTotalError = getErrorForInput('Transfer_amountTotal');

  return (
    <div className={styles.Transfer_stepOneContainer}>
      <div key='TransferContainer-transferType' className={styles.Transfer_transferTypeContainer}>
        <div className={styles.Transfer_transferTypeToggleContainer}>
          <RadioGroup
            aria-label={Transfers.lbl_transfer_type}
            name='TransferType'
            className={styles.Transfer_transferType_radioButtonGroup}
            value={transfer.transferType}
            onChange={() => onTransferChange('transferType')}
            style={{
              display: 'flex'
            }}
          >
            <FormControlLabel
              value='partial'
              label={Transfers.lbl_partial_transfer}
              control={<Radio disableRipple />}
            />
            <FormControlLabel
              value='full'
              label={
                <span>
                  Full Balance Transfer
                  <div
                    className={styles.InfoIcon_container}
                    onClick={showFullBalanceDialog}
                    onKeyUp={onKeyUp(showFullBalanceDialog)}
                    tabIndex={0}
                    style={{
                      display: 'inline-flex'
                    }}
                  >
                    <Icon style={{ cursor: 'pointer', fontSize: '22px', color: 'var(--accent)' }}>
                      <div className={styles.InfoIcon_icon}>
                        help_outline
                      </div>
                    </Icon>
                  </div>
                </span>
              }
              control={<Radio disableRipple />}
            />
          </RadioGroup>

        </div>
      </div>
      <div className={styles.Transfer_accountsSelection}>
        <div className={styles.Transfer_sourceContainer}>
          <div className={`${styles.Transfer_sourceAccountDescription} ${getErrorForInput('Transfer_sourceContainer') ? styles.error : ''}`}>
            <div className={styles.Transfer_sourceAccountLabel}>
              {Transfers.lbl_source_account}
            </div>
            <div className={styles.Transfer_sourceAccountName}>
              {formatAccountDisplayText(sourceAccount)}
            </div>
            <div className={styles.error}>
              {getErrorForInput('Transfer_sourceContainer')}
            </div>
          </div>
          <div className={styles.Transfer_keepAccountOpenCheckbox}>
            {transfer.transferType === TransferTypes.full ?
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!transfer.CloseAccount}
                    onChange={() => onTransferChange('CloseAccount')}
                  />
                }
                label={Transfers.lbl_leave_open}
              /> : null
            }
          </div>
        </div>
        <div className={styles.Transfer_iconContainer}>
          <Icon style={{ color: 'black' }}>
            arrow_forward
          </Icon>
        </div>
        <div className={styles.Transfer_destinationColumn}>
          <DualPushButtonSwitch
            groupLabel={Transfers.lbl_transfer_types}
            onChange={() => onTransferChange('transferInputType')}
            options={[
              {
                active: transfer.transferInputType === TransferInputTypes.Amount,
                disabled: transfer.transferType === TransferTypes.full,
                label: '$',
                radioLabel: Transfers.lbl_radio_amount,
                value: TransferInputTypes.Amount,
              },
              {
                active: transfer.transferInputType === TransferInputTypes.Percentage,
                disabled: transfer.transferType === TransferTypes.full,
                label: '%',
                radioLabel: Transfers.lbl_radio_percentage,
                value: TransferInputTypes.Percentage,
              }
            ]}
          />
          {transfer.TargetAccounts.map((targetAccount, rowIndex) => {
            const accountErrorText = getErrorForInput(`Transfer_destinationContainer-${rowIndex}`);
            return (
              <TargetAccount
                key={`TargetAccount-${rowIndex}`}
                index={rowIndex}
                target={targetAccount}
                accountOptions={destinationAccounts.map(account => ({
                  ...account,
                  disabled: some(transfer.TargetAccounts, (dest, destIndex) => {
                    return destIndex !== rowIndex && dest.AccountId === account.accountId;
                  }),
                })
                )}
                canRemove={rowIndex !== 0}
                accountError={accountErrorText}
                inputError={getErrorForInput(`Transfer_inputContainer-${rowIndex}`)}
                inputFieldType={transfer.transferInputType}
                onAccountChange={onTargetChange(rowIndex)}
                onRemove={() => removeTargetAccount(rowIndex)}
              />
            );
          })}
          <div key='Transfer_amountTotal' className={`${styles.Transfer_amountTotal} ${transfer.transferInputType === TransferInputTypes.Amount ? styles.dollarTotal : styles.percentTotal}`}>
            <FormControl error={Boolean(amountTotalError)}>
              <TextField
                label={Transfers.lbl_total}
                style={{
                  cursor: 'text',
                  color: amountTotalError ? 'var(--error)' : 'var(--text)'
                }}
                name='Transfer_totalRowContainer_textField'
                value={
                  transfer.transferInputType === TransferInputTypes.Amount ?
                    currencyFormatter(reduce(transfer.TargetAccounts, (sum, account) => sum + (account[transfer.transferInputType] || 0), 0)) :
                    `${reduce(transfer.TargetAccounts, (sum, account) => sum + (account[transfer.transferInputType] || 0), 0)}%`
                }
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
              {amountTotalError && <FormHelperText>{amountTotalError}</FormHelperText>}
            </FormControl>
          </div>

          <div key='Transfer_addIconContainer' className={styles.Transfer_addIconContainer}>
            <div className={transactionsStyles.addAccountIcon}>
              <Button
                color='secondary'
                startIcon={<Icon>add</Icon>}
                onClick={addTargetAccount}
              >
                {Transfers.lbl_add_account}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LanguageHOC(TransferStepOneContent);
