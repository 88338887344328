/*
*
* OptionChangeDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@mui/material';
import {
  CardAligned,
  CardTitle,
  CardBody,
} from '@frontend/common';

import styles from './styles.module.scss';

export class AccountInfo extends React.Component {

  static propTypes = {
    AccountsInGroupNoticeShow: PropTypes.func.isRequired,
    agentName: PropTypes.string.isRequired,
    beneficiaryName: PropTypes.string.isRequired,
    accountNumber: PropTypes.number.isRequired,
    totalMarketValue: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    accountsInGroup: PropTypes.number.isRequired,
  };

  render() {
    const {
      AccountsInGroupNoticeShow,
      agentName,
      beneficiaryName,
      accountNumber,
      totalMarketValue,
      accountType,
      accountsInGroup,
    } = this.props;

    return (
      <CardAligned>
        <CardTitle>Account Details</CardTitle>
        <CardBody>
          <div className={styles.accountDetails}>
            <div className={styles.acctOwnerBeneRow}>
              <div className={styles.acctOwner}>
                <div className={styles.detailsTitle}>Account Owner</div>
                {agentName}
              </div>

              <div className={styles.ben}>
                <div className={styles.detailsTitle}>Beneficiary</div>
                {beneficiaryName}
              </div>
            </div>

            <div className={styles.acctNumbTotalValRow}>
              <div className={styles.acctNum}>
                <div className={styles.detailsTitle}>Account Number</div>
                {accountNumber}
              </div>
              <div className={styles.totalVal}>
                <div className={styles.detailsTitle}>Total Value</div>
                {totalMarketValue}
              </div>
            </div>

            <div className={styles.acctTypeAccountsInGroupRow}>
              <div className={styles.acctType}>
                <div className={styles.detailsTitle}>Account Type</div>
                {accountType}
              </div>
              {accountsInGroup > 1 && (
                <div className={styles.accountsInGroup}>
                  <div className={styles.accountsInGroupInfo}>
                    <div className={styles.detailsTitle}>Accounts in Group</div>
                    <Icon id='AccountsInGroupIcon' onClick={AccountsInGroupNoticeShow} className={styles.accountsInGroupInfoIcon}>
                        help_outline
                    </Icon>
                  </div>
                  {accountsInGroup}
                </div>
              )}

            </div>

          </div>
        </CardBody>
      </CardAligned>
    );
  }
}

export default AccountInfo;