import { GETrequest, PUTrequest } from 'utils/helpers/api_handler';
import {
  GIFTING_LIST_GET,
  GIFTING_CHANGE_NAME_VISIBILITY,
  GIFTING_CHANGE_STATUS,
  GET_GIFT_IMAGE,
  UPDATE_GIFT,
} from './constants';

export function getGiftingList() {
  const payload = GETrequest('/gift?includeImage=false');
  return {
    type: GIFTING_LIST_GET,
    payload,
  };
}

export function changeStatus(AccountId, IsActive) {
  const payload = PUTrequest('/gift/activation', { AccountId, IsActive });
  return {
    type: GIFTING_CHANGE_STATUS,
    payload,
  };
}

export function getGiftImage(AccountId) {
  const payload = GETrequest(`/gift/image/${AccountId}`, { responseType: 'blob' });
  return {
    type: GET_GIFT_IMAGE,
    payload,
  };
}

export function updateGift(gift, uploadPercentageCb = null) {
  const config = uploadPercentageCb
    ? {
      onUploadProgress: (progressEvent) => {
        uploadPercentageCb(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      }
    }
    : {};
  const payload = PUTrequest('/gift', gift, config);
  return {
    type: UPDATE_GIFT,
    payload,
  };
}

export function changeNameVisibility(AccountId, HideLastName) {
  const payload = PUTrequest('/gift/hideLastName', { AccountId, HideLastName });
  return {
    type: GIFTING_CHANGE_NAME_VISIBILITY,
    payload,
  };
}
