/*
*
* Transfers Component
*
*/
import React from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { connect } from 'react-redux';
import { find, reduce, isEqual, cloneDeep } from 'lodash';
import {
  Breadcrumbs,
  LoadingOverlay,
  ConfirmModal,
  notificationShow,
  currencyFormatter,
} from '@frontend/common';
import {
  Button,
} from '@mui/material';
import {
  getAccounts,
} from '../../actions';
import { getNotifications, } from 'components/AppRoot/Navigation/actions';
import {
  getTransferAccounts,
  createNewTransfer,
  updateTransfer,
} from '../actions';
import AccountDetailsCard from './AccountDetailsCard';
import Transfer from './Transfer';
import {
  FullBalanceDialog,
  OptionChangeDialog,
  LearnMore,
} from './Dialogs';
import { TransferInputTypes, TransferTypes } from './Transfer/typeConstants';

import styles from './styles.module.scss';

const select = (state, { match }) => ({
  accountList: state.accounts.accountList,
  destinationAccounts: state.transactions.destinationAccounts || [],
  transfer: match.params.transactionId ? state.transactions.selectedTransaction : undefined,
  groupAccountList: state.transactions.groupAccountList,
  MaximumBeneficiaryDeposit: state.static.environmentVars.MaximumBeneficiaryDeposit,
  form400: state.static.documents.form400,
  form300: state.static.documents.form300,
});

export class Transfers extends React.Component {

  static propTypes = {
    getNotifications: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    accountList: PropTypes.array.isRequired,
    groupAccountList: PropTypes.array.isRequired,
    destinationAccounts: PropTypes.array.isRequired,
    transfer: PropTypes.object,
    MaximumBeneficiaryDeposit: PropTypes.number.isRequired,
    form400: PropTypes.string.isRequired,
    form300: PropTypes.string.isRequired,
    createNewTransfer: PropTypes.func.isRequired,
    updateTransfer: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    getTransferAccounts: PropTypes.func.isRequired,
    getAccounts: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        accountId: PropTypes.string.isRequired,
        transactionId: PropTypes.string,
      }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    text: PropTypes.shape({
      AccountDetails: PropTypes.shape({
        nav_path: PropTypes.func,
        nav_title: PropTypes.string,
      }),
      Accounts: PropTypes.shape({
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
      }),
      Transfers: PropTypes.shape({
        msg_unsaved_changes: PropTypes.string,
        err_accountOwnerSingleAccount: PropTypes.string,
        err_custodialFromOtherType: PropTypes.func,
        err_custodialToOtherBeneficiary: PropTypes.func,
        err_custodialToOtherType: PropTypes.func,
        err_deferredEarningsNotConfirmed: PropTypes.string,
        err_destinationAccountMissing: PropTypes.string,
        err_fullBalanceNotAllocated: PropTypes.string,
        err_invalidAmount: PropTypes.string,
        err_invalidPercentage: PropTypes.string,
        err_maxGroupBalanceExceeded: PropTypes.func,
        err_noOptionChangesRemaining: PropTypes.func,
        err_noRemainingOptionChanges: PropTypes.string,
        err_partialBalancePercentUnderMinimum: PropTypes.string,
        err_partialBalancePercentOverMax: PropTypes.string,
        err_sourceAccountZeroBalance: PropTypes.string,
        err_termsNotChecked: PropTypes.string,
        err_totalGreaterThanAvailable: PropTypes.string,
        err_totalUnderMinimum: PropTypes.string,
        msg_transfer_created: PropTypes.string,
        msg_transfer_updated: PropTypes.string,
        nav_path_accounts: PropTypes.string,
        nav_path_edit: PropTypes.string,
        nav_path_new: PropTypes.string,
        nav_transfer_title: PropTypes.func,
        title_transfer_type: PropTypes.func,
        title_deferred_earning_confirm: PropTypes.string,
        text_deferred_earning: PropTypes.string,
      })
    })
  };

  state = {
    editing: this.props.location.pathname.includes(this.props.text.Transfers.nav_path_edit),
    isLoading: false,
    sourceAccount: this.props.accountList.find(account => account.accountId === parseInt(this.props.match.params.accountId)),
    transfer: {
      AccountId: this.props.match.params.accountId,
      Amount: 0,
      Percentage: 0,
      CloseAccount: true,
      TargetAccounts: [{ AccountId: '', Amount: 0, Percentage: 0 }],
      transferType: TransferTypes.partial,
      transferInputType: TransferInputTypes.Amount,
    },
    transferScreenshot: {
      AccountId: this.props.match.params.accountId,
      Amount: 0,
      Percentage: 0,
      CloseAccount: true,
      TargetAccounts: [{ AccountId: '', Amount: 0, Percentage: 0 }],
      transferType: TransferTypes.partial,
      transferInputType: TransferInputTypes.Amount,
    },
    runValidation: false,
    errors: [],
    isLoadingSubmit: false,
    showOptionChangeModal: false,
    showDeferredEarningsConfirmModal: false,
    hasConfirmedDeferredEarnings: false,
    showFullBalanceDialog: false,
    hasSeenFullBalanceDialog: false,
    showLearnMore: false,
    createdDate: '',
  };

  displayUnsavedChangesPrompt = () => {
    return !this.state.isLoading && !isEqual(this.state.transfer, this.state.transferScreenshot);
  }

  setupState = () => {
    if (this.state.editing) {
      const selectedTransfer = this.props.transfer;
      const transfer = {
        ...selectedTransfer,
        transferType: selectedTransfer.Percentage < 100 ? TransferTypes.partial : TransferTypes.full,
        transferInputType: selectedTransfer.Percentage > 0 ? TransferInputTypes.Percentage : TransferInputTypes.Amount,
      };
      this.setState({
        isLoading: false,
        transfer,
        transferScreenshot: cloneDeep(transfer),
        hasSeenFullBalanceDialog: selectedTransfer.Percentage === 100,
        hasConfirmedDeferredEarnings: selectedTransfer.Percentage === 100,
      });
    }
    else {
      this.setState({
        isLoading: false,
      });
    }
  };

  transfersDetailsGet = () => {
    const { accountId } = this.props.match.params;
    const { getTransferAccounts, destinationAccounts } = this.props;
    const { sourceAccount } = this.state;
    if ((this.state.editing) || !sourceAccount || destinationAccounts.length === 0) {
      this.setState({ isLoading: true });
    }
    return Promise.all([
      getTransferAccounts(accountId),
    ])
      .catch(() => null)
      .finally(() => {
        this.setupState();
      });
  };

  prepareTransferPayload = () => {
    const {
      transfer,
    } = this.state;
    const targets = transfer.TargetAccounts.filter(account => account.AccountId !== '' &&
      ((transfer.transferType === TransferTypes.partial && account[transfer.transferInputType] !== 0) || (transfer.transferType === TransferTypes.full && account.Percentage > 0))
    );
    const transferPayload = {
      AccountId: transfer.AccountId,
      TargetAccounts: targets.map(account => {
        const newAccount = {
          AccountId: account.AccountId,
        };
        if (transfer.transferType === TransferTypes.partial && transfer.transferInputType === TransferInputTypes.Amount) {
          newAccount.Amount = account.Amount;
        }
        else {
          newAccount.Percentage = account.Percentage;
        }
        return newAccount;
      }),
    };
    if (this.props.transfer !== undefined) {
      transferPayload.TradeDate = this.props.transfer.TradeDate;
      transferPayload.TransferId = this.props.transfer.TransferId;
    }

    if (transfer.transferType === TransferTypes.full) {
      transferPayload.CloseAccount = transfer.CloseAccount;
    }
    return transferPayload;
  };

  editTransfer = () => {
    return new Promise((resolve, reject) => {
      this.props.updateTransfer(this.prepareTransferPayload())
        .then(response => {
          const createdDate = response.payload.data;
          this.setState({
            isLoading: true,
            createdDate,
            transferScreenshot: cloneDeep(this.state.transfer) // to avoid exit prompt after submit
          });
          this.props.getAccounts()
            .then(() => this.setState({
              sourceAccount: this.props.accountList.find(account => account.accountId === parseInt(this.props.match.params.accountId)),
              isLoading: false,
            }));
          this.props.notificationShow(this.props.text.Transfers.msg_transfer_updated, 'success');
          this.props.getNotifications(); // refreshes app notifications
          resolve();
        })
        .catch(() => reject());
    });
  };

  createNewTransfer = () => {
    return new Promise((resolve, reject) => {
      this.props.createNewTransfer(this.prepareTransferPayload())
        .then(response => {
          const createdDate = response.payload.data;
          this.setState({
            isLoading: true,
            createdDate,
            transferScreenshot: cloneDeep(this.state.transfer) // to avoid exit prompt after submit
          });
          this.props.getAccounts()
            .then(() => this.setState({
              sourceAccount: this.props.accountList.find(account => account.accountId === parseInt(this.props.match.params.accountId)),
              isLoading: false,
            }));
          this.props.notificationShow(this.props.text.Transfers.msg_transfer_created, 'success');
          this.props.getNotifications(); // refreshes app notifications
          resolve();
        })
        .catch(() => reject());
    });
  };

  stateChangeValidationWrapper = (stateChanges) => {
    if (this.state.runValidation) {
      this.setState({
        errors: this.transferFormErrors({
          ...this.state,
          ...stateChanges
        }),
        ...stateChanges
      });
    }
    else {
      this.setState(stateChanges);
    }
  };

  onTransferChange = (key, value) => {
    const { transfer } = this.state;
    switch (key) {
      case 'transferType': {
        this.transferTypeToggle();
        break;
      }
      case 'transferInputType': {
        this.stateChangeValidationWrapper({
          transfer: {
            ...transfer,
            transferInputType: transfer.transferInputType === TransferInputTypes.Amount ? TransferInputTypes.Percentage : TransferInputTypes.Amount,
          }
        });
        break;
      }
      case 'CloseAccount': {
        this.stateChangeValidationWrapper({
          transfer: {
            ...transfer,
            CloseAccount: !transfer.CloseAccount,
          }
        });
        break;
      }
      default: {
        this.stateChangeValidationWrapper({
          transfer: {
            ...transfer,
            [key]: value,
          }
        });
      }
    }
  };

  onTargetChange = (targetAccountIndex) => {
    return (nextTarget) => {
      const { AccountId } = nextTarget;
      const { destinationAccounts, } = this.props;
      const { sourceAccount, transfer } = this.state;
      let showOptionChangeModal = false;
      if (transfer.TargetAccounts[targetAccountIndex].AccountId !== AccountId) {
        const account = find(destinationAccounts, { accountId: AccountId });
        if (account) {
          showOptionChangeModal = account.accountGroupId === sourceAccount.accountGroupId;
        }
      }
      this.stateChangeValidationWrapper({
        transfer: {
          ...transfer,
          TargetAccounts: transfer.TargetAccounts.map((account, index) => {
            if (targetAccountIndex === index) {
              return nextTarget;
            }
            return account;
          })
        },
        showOptionChangeModal,
      });
    };
  };

  addTargetAccount = () => {
    const { transfer: { TargetAccounts, ...other } } = this.state;
    this.stateChangeValidationWrapper({
      transfer: {
        ...other,
        TargetAccounts: [
          ...TargetAccounts,
          { AccountId: '', Amount: 0, Percentage: 0 }
        ]
      }
    });
  };

  removeTargetAccount = index => {
    const { transfer: { TargetAccounts, ...other } } = this.state;
    this.stateChangeValidationWrapper({
      transfer: {
        ...other,
        TargetAccounts: TargetAccounts.filter((_, rowIndex) => rowIndex !== index)
      }
    });
  };

  transferTypeToggle = () => {
    const {
      sourceAccount,
      transfer,
      hasSeenFullBalanceDialog,
      hasConfirmedDeferredEarnings,
    } = this.state;
    const newTransferType = transfer.transferType === TransferTypes.partial ? TransferTypes.full : TransferTypes.partial;
    const showFullBalanceDialog = sourceAccount.unrealizedDeferredEarnings ? hasConfirmedDeferredEarnings && !hasSeenFullBalanceDialog : !hasSeenFullBalanceDialog;
    const showDeferredEarningsConfirmModal = newTransferType === TransferTypes.full && !hasConfirmedDeferredEarnings && sourceAccount.unrealizedDeferredEarnings;

    this.stateChangeValidationWrapper({
      transfer: {
        ...transfer,
        transferType: newTransferType,
        transferInputType: transfer.transferType === TransferTypes.partial ? TransferInputTypes.Percentage : transfer.transferInputType,
        TargetAccounts: newTransferType === TransferTypes.full && !transfer.TargetAccounts.some(target => target.Percentage > 0) ?
          transfer.TargetAccounts.map((target, index) => {
            // transfer type is being changed to full, so set percentage on first destination to 100%
            if (index === 0 && transfer.transferType === TransferTypes.partial && target.Percentage !== 100) {
              return {
                ...target,
                Percentage: 100
              };
            }
            return target;
          }) : transfer.TargetAccounts,
      },
      showDeferredEarningsConfirmModal,
      showFullBalanceDialog,
    });
  };

  canContinue = (onContinue) => {
    const errors = this.transferFormErrors();
    this.setState({
      errors,
      runValidation: true,
    });
    return errors.length === 0 && ((onContinue && onContinue()) || true);
  };

  transferFormErrors = (nextState) => {
    const {
      transfer,
      sourceAccount,
    } = nextState || this.state;
    const { MaximumBeneficiaryDeposit, text: { Transfers } } = this.props;
    const errors = [];
    const totalPercentage = reduce(transfer.TargetAccounts, (sum, account) => sum + account.Percentage, 0);
    const totalAmount = reduce(transfer.TargetAccounts, (sum, account) => sum + account.Amount, 0);

    // total field
    if (transfer.transferType === TransferTypes.full) {
      // must equal 100% allocation
      if (totalPercentage !== 100) {
        errors.push({
          key: 'Transfer_amountTotal',
          message: Transfers.err_fullBalanceNotAllocated,
        });
      }
    }
    else {
      if (transfer.transferInputType === TransferInputTypes.Percentage && totalPercentage > 99) {
        errors.push({
          key: 'Transfer_amountTotal',
          message: Transfers.err_partialBalancePercentOverMax
        });
      }
      else if (transfer.transferInputType === TransferInputTypes.Percentage && !totalPercentage) {
        errors.push({
          key: 'Transfer_amountTotal',
          message: Transfers.err_partialBalancePercentUnderMinimum
        });
      }
      else if (transfer.transferInputType === TransferInputTypes.Amount) {
        if (totalAmount > sourceAccount.netAvailableNum) {
          errors.push({
            key: 'Transfer_amountTotal',
            message: Transfers.err_totalGreaterThanAvailable,
          });
        }
        if (!totalAmount) {
          errors.push({
            key: 'Transfer_amountTotal',
            message: Transfers.err_totalUnderMinimum,
          });
        }
      }
    }

    // only 1 row
    if (transfer.TargetAccounts.length === 1) {
      //  destination account isn't selected
      if (transfer.TargetAccounts[0].AccountId === '') {
        errors.push({
          key: 'Transfer_destinationContainer-0',
          message: Transfers.err_destinationAccountMissing,
        });
      }
    }

    transfer.TargetAccounts.forEach((targetAccount, index) => {
      // destination has amount/percentage filled in, but no target account selected
      if (
        (transfer.transferType === TransferTypes.full && targetAccount.Percentage > 0) ||
        (transfer.transferType === TransferTypes.partial && (
          (transfer.transferInputType === TransferInputTypes.Amount && targetAccount.Amount > 0) ||
          (transfer.transferInputType === TransferInputTypes.Percentage && targetAccount.Percentage > 0)
        ))) {
        if (targetAccount.AccountId === '') {
          errors.push({
            key: `Transfer_destinationContainer-${index}`,
            message: Transfers.err_destinationAccountMissing,
          });
        }
      }

      // destination has target selected but no amount/percentage
      if ((index !== 0 && targetAccount.AccountId !== '' && transfer.transferType === TransferTypes.partial) && (
        (transfer.transferInputType === TransferInputTypes.Amount && !targetAccount.Amount) ||
        (transfer.transferInputType === TransferInputTypes.Percentage && !targetAccount.Percentage)
      )) {
        errors.push({
          key: `Transfer_inputContainer-${index}`,
          message: transfer.transferInputType === TransferInputTypes.Amount ? Transfers.err_invalidAmount : Transfers.err_invalidPercentage,
        });
      }

      // destination account is not part of same groupId and amount puts its balance over MaximumBeneficiaryDeposit amount
      // check if a target account has been selected
      if (targetAccount.AccountId) {
        // pull more account detail from main account list
        const { accountGroupId, beneficiary } = this.props.accountList.find(account => {
          return (account.accountId === targetAccount.AccountId);
        });

        // check if target account is in different groupId than source account
        if (accountGroupId !== sourceAccount.accountGroupId) {
          // pull groupAccount totalValue balance
          const { MarketValue } = this.props.groupAccountList.find(group => {
            return (group.AccountGroupId === accountGroupId);
          });
          // get destination accounts list that go with this destination account
          const destinationAccountList = this.props.accountList.filter(account => account.accountGroupId === accountGroupId);
          // get the total destination group transfer amount and percentage.
          const totalDestinationGroupPercentage = reduce(destinationAccountList, (sum, account) => {
            const targetAccount = transfer.TargetAccounts.find(targetAccount => targetAccount.AccountId === account.accountId);
            // if targetAccount is not found in the
            return sum + (targetAccount ? targetAccount.Percentage : 0);
          }, 0);
          const totatDestinationGroupAmount = reduce(destinationAccountList, (sum, account) => {
            const targetAccount = transfer.TargetAccounts.find(targetAccount => targetAccount.AccountId === account.accountId);
            return sum + (targetAccount ? targetAccount.Amount : 0);
          }, 0);
          // based on tranfser input type, use the amount or figure out the amount using the percentage
          const transferAmount = transfer.transferInputType === TransferInputTypes.Amount ? totatDestinationGroupAmount : (totalDestinationGroupPercentage * .01 * sourceAccount.totalValueNum);
          //check if transfer amount plus current group account total balance will go over the MaximumBeneficiaryDeposit amount.
          if (transferAmount + MarketValue > MaximumBeneficiaryDeposit) {
            const allowedTransferAmount = Math.max(MaximumBeneficiaryDeposit - MarketValue, 0);
            errors.push({
              key: `Transfer_destinationContainer-${index}`,
              message: Transfers.err_maxGroupBalanceExceeded(beneficiary.name, currencyFormatter(allowedTransferAmount)),
            });
          }
        }
        // else groupId is the same as source account, now check if any optionChanges remain.
        else {
          if (sourceAccount.optionChangesRemaining === 0) {
            errors.push({
              key: `Transfer_destinationContainer-${index}`,
              message: Transfers.err_noRemainingOptionChanges,
            });
          }
        }
      }

    });
    return errors;
  };

  componentDidMount() {
    this.transfersDetailsGet();
  }

  render() {
    const { 
      match, location, destinationAccounts, form400, form300, loading,
      text: { Transfers, Accounts, AccountDetails }, 
    } = this.props;
    const {
      isLoading,
      sourceAccount,
      editing,
      transfer,
      createdDate,
    } = this.state;

    return (
      <LoadingOverlay
        show={loading || isLoading}
        width='100%'
      >
        <div className={styles.transfersContainer}>
          <div className={`${styles.pageNav} hideOnPrint`}>
            {(location.pathname.indexOf(Transfers.nav_path_new) > -1 || location.pathname.indexOf(Transfers.nav_path_edit) > -1) &&
              <Breadcrumbs
                crumbs={[
                  {
                    title: Accounts.nav_title,
                    link: Accounts.nav_path
                  },
                  {
                    title: AccountDetails.nav_title,
                    link: AccountDetails.nav_path(match.params.accountId),
                  },
                  { title: Transfers.nav_transfer_title(location.pathname.indexOf(Transfers.nav_path_new) > -1) },
                ]}
              />}
          </div>
          <div key='Transfers_detailsContainer' className={`${styles.detailsContainer} hideOnPrint`}>
            <AccountDetailsCard
              sourceAccount={sourceAccount}
            />
          </div>
          <div key='Transfers_children' className={styles.children}>
            <Transfer
              errors={this.state.errors}
              canContinue={this.canContinue}
              cardTitle={Transfers.title_transfer_type(editing)}
              transfer={transfer}
              onTransferChange={this.onTransferChange}
              onTargetChange={this.onTargetChange}
              addTargetAccount={this.addTargetAccount}
              removeTargetAccount={this.removeTargetAccount}
              showFullBalanceDialog={() => this.setState({ showFullBalanceDialog: true })}
              sourceAccount={sourceAccount}
              destinationAccounts={destinationAccounts}
              save={editing ? this.editTransfer : this.createNewTransfer}
              createdDate={createdDate}
            />
          </div>

          <div className={`${styles.learnMoreButton} hideOnPrint`}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => this.setState({ showLearnMore: true })}
            >
              Learn More
            </Button>
          </div>

          <FullBalanceDialog
            show={this.state.showFullBalanceDialog}
            onClose={() => {
              this.setState({
                showFullBalanceDialog: false,
                hasSeenFullBalanceDialog: true,
              });
            }}
          />
          <OptionChangeDialog
            show={this.state.showOptionChangeModal}
            onClose={() => {
              this.setState({ showOptionChangeModal: false });
            }}
          />
          <ConfirmModal
            show={Boolean(this.state.showDeferredEarningsConfirmModal)}
            title={Transfers.title_deferred_earning_confirm}
            body={Transfers.text_deferred_earning}
            onModalClose={() => this.setState({
              showDeferredEarningsConfirmModal: false,
              transfer: { ...transfer, transferType: TransferTypes.partial }
            })}
            onConfirm={() => this.setState({
              hasConfirmedDeferredEarnings: true,
              showDeferredEarningsConfirmModal: false,
              showFullBalanceDialog: true,
            })}
          />
          <LearnMore
            open={this.state.showLearnMore}
            onClose={() => this.setState({ showLearnMore: false })}
            form400={form400}
            form300={form300}
          />

        </div>

        <Prompt
          message={Transfers.msg_unsaved_changes}
          when={this.displayUnsavedChangesPrompt()}
        />
      </LoadingOverlay>
    );
  }
}

export default withRouter(connect(select, {
  getNotifications,
  createNewTransfer,
  updateTransfer,
  notificationShow,
  getTransferAccounts,
  getAccounts,
})(LanguageHOC(Transfers)));
