/*
*
* NewWithdrawals Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import LanguageHOC from 'utils/translations/LanguageHOC';
import parse from 'html-react-parser';
import { isEqual, cloneDeep } from 'lodash';
import * as validator from 'utils/helpers/form_validation';
import { sanitizeHTML } from 'utils/helpers/sanitation';

import {
  ONE_TIME,
  RECURRING,
  MONTHLY,
  ACH_PAYMENT,
  ACCESS_CARD_PAYMENT,
  ACCOUNT_OWNER,
  BENEFICIARY,
  CHECK_PAYMENT,
  ELECTRONICSCHOOL_PAYMENT,
  K12_ED,
  FULL_BALANCE,
  PARTIAL_BALANCE,
  SCHOOL,
  ACCOUNT_TYPE_UGMA_UTMA,
  ELECTRONIC,
  MAIL,
} from '../constants';

import {
  Card,
  ConfirmModal,
  InfoIcon,
  LoadingOverlay,
  currencyFormatter,
  notificationShow,
  properCase,
} from '@frontend/common';

import {
  createNewWithdrawal,
  updateWithdrawal,
  getSchools,
  getPreTransactionDetails,
  getScheduleRunDates,
  setWithdrawalSubmitted,
  getElectronicSchoolPaymentSchool,
} from 'components/Features/protected/Accounts/Transactions/actions';
import { getNotifications, } from 'components/AppRoot/Navigation/actions';

import {
  getBankAccounts,
  getAccessCards,
  savePOABankSelectionAgreement,
} from 'components/Features/protected/Payments/actions';

import {
  Tab,
  Tabs,
  Button,
  Step,
  Stepper,
  StepButton,
} from '@mui/material';

import Step0WithdrawalType from './StepComponents/Step0WithdrawalType';
import Step1Recipient from './StepComponents/Step1Recipient';
import Step2AmountDate from './StepComponents/Step2AmountDate';
import Step2PaymentMethod from './StepComponents/Step2PaymentMethod';
import Step3Print from './StepComponents/Step3Print';
import Step3TermsAndConditions from './StepComponents/Step3TermsAndConditions';
import SchoolSearch from '../../SchoolSearch';

import styles from './styles.module.scss';

const select = (state) => ({
  selectedTransaction: state.transactions.selectedTransaction,
  withdrawalVariables: state.static.withdrawalVariables,
  bankAccounts: state.payments.bankAccounts,
  accessCards: state.payments.accessCards,
  usStates: state.static.usStates,
  ADDRESS_TYPES: state.static.environmentVars.AddressTypes,
});

export class NewWithdrawals extends React.Component {
  static propTypes = {
    getNotifications: PropTypes.func.isRequired,
    getPreTransactionDetails: PropTypes.func.isRequired,
    setWithdrawalSubmitted: PropTypes.func.isRequired,
    getScheduleRunDates: PropTypes.func.isRequired,
    selectedTransaction: PropTypes.object,
    getSchools: PropTypes.func.isRequired,
    getElectronicSchoolPaymentSchool: PropTypes.func.isRequired,
    withdrawalVariables: PropTypes.shape({
      minWithdrawal: PropTypes.number,
      maxWithdrawal: PropTypes.number,
      maxWithdrawalRecurring: PropTypes.number,
      numOfRollingDays: PropTypes.number,
      sellDates: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ])),
      startDates: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ])),
      paymentTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })),
      withdrawalTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        messages: PropTypes.arrayOf(PropTypes.string)
      })),
      recipientTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        messages: PropTypes.arrayOf(PropTypes.string),
      })),
    }).isRequired,
    createNewWithdrawal: PropTypes.func.isRequired,
    updateWithdrawal: PropTypes.func.isRequired,
    isProportional: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    checkDisplayWarning: PropTypes.func.isRequired,
    preTransactionDetails: PropTypes.shape({
      totalMarketValueNum: PropTypes.number,
      totalAvailableBalanceNumber: PropTypes.number,
      BeneficiaryId: PropTypes.number,
      AccountId: PropTypes.number,
      AccountType: PropTypes.string,
      AllowFullWithdrawal: PropTypes.bool,
      agentAddress: PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
        name: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.shape({ Code: PropTypes.string }),
        postalCode: PropTypes.string,
      }),
      beneficiaryAddress: PropTypes.shape({
        name: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.shape({ Code: PropTypes.string }),
        postalCode: PropTypes.string,
      }),
      beneficiaryName: PropTypes.string,
      totalAvailableForWithdrawalNum: PropTypes.number,
      groupAccounts: PropTypes.arrayOf(PropTypes.shape({
        AccountId: PropTypes.number,
        availableForWithdrawalNum: PropTypes.number,
        AllowFullWithdrawal: PropTypes.bool,
      })),
      agentAddressChanged: PropTypes.bool,
      beneficiaryAddressChanged: PropTypes.bool,
      schoolAddresses: PropTypes.array,
    }),
    getBankAccounts: PropTypes.func.isRequired,
    getAccessCards: PropTypes.func.isRequired,
    savePOABankSelectionAgreement: PropTypes.func,
    bankAccounts: PropTypes.array,
    accessCards: PropTypes.array,
    text: PropTypes.shape({
      AccountDetails: PropTypes.shape({
        nav_path: PropTypes.func,
        nav_title: PropTypes.string,
      }),
      Withdrawals: PropTypes.shape({
        msg_unsaved_changes: PropTypes.string,
        errors: PropTypes.object,
        poa_bank_modal_body: PropTypes.string,
        poa_bank_modal_title: PropTypes.string,
        step_labels: PropTypes.array,
        tab_labels: PropTypes.arrayOf(PropTypes.string),
      }),
    }).isRequired,
    usStates: PropTypes.array.isRequired,
    ADDRESS_TYPES: PropTypes.shape({
      Physical: PropTypes.string,
      Mailing: PropTypes.string,
      Other: PropTypes.string
    }).isRequired,
  };

  state = {
    withdrawalScreenshot: {},
    isEditing: window.location.href.includes('edit'),
    accountId: parseInt(this.props.match.params.accountId),
    groupId: parseInt(this.props.match.params.groupId),
    initLoading: true,
    tabIndex: 0,
    stepIndex: 0,
    completed: {},
    withdrawalRequestLoading: false,
    duplicateWithdrawalModalOpen: false,
    showPrintButtonPage: false,
    duplicateWithdrawalMessage: '',
    errors: [],
    partialBalanceWithdrawalOnly: false,
    fullBalanceWithdrawalOnly: false,
    partialWithdrawalMessageOpen: false,
    schools: [],
    schoolSearchOpen: false,
    isSchoolRetained: false,
    schoolSearchLoading: false,
    smallScreen: window.innerWidth <= 1200,
    step0ValidationHasRun: false,
    step1ValidationHasRun: false,
    step2ValidationHasRun: false,
    frequencyChangeDisabled: false,
    accountIsUGMAUTMA: false,
    termsChecked: false,
    nextRunStartDates: [],
    withdrawal: {
      amount: 0,
      pendingAmount: 0,
      amountType: '',
      paymentAccount: {},
      leaveAccountOpen: false,
      frequency: ONE_TIME,
      ignoreDuplicates: false,
      paymentType: '',
      paymentDeliveryType: '',
      proportionalAmounts: [],
      recipientAddress: {
        agent: this.props.preTransactionDetails.agentAddress,
        beneficiary: this.props.preTransactionDetails.beneficiaryAddress,
        school: {
          attn: '',
          name: '',
          street1: '',
          city: '',
          state: { Code: '' },
          postalCode: '',
          schoolId: '',
          fbo: this.props.preTransactionDetails.beneficiaryName,
          studentIdNum: '',
        },
      },
      recipient: '',
      sellDate: this.props.withdrawalVariables.sellDates.length > 0 ? dayjs(this.props.withdrawalVariables.sellDates[0]) : null,
      startDate: this.props.withdrawalVariables.startDates.length > 0 ? dayjs(this.props.withdrawalVariables.startDates[0]) : null,
      endDate: null,
      withdrawalType: '',
      actualSellDate: null,
      actualStartDateDayOfMonth: null,
      actualEndDate: null,
      createdDate: null,
      nextRunDate: null,
    },
    poaBank: null,
    savingPoaBankAgreement: false,
    poaBankModalOpen: false,
    paymentAccounts: [],
    IsElectronicSchoolPaymentSchool: false,
  };

  displayUnsavedChangesPrompt = () => {
    return !this.state.loading && !isEqual(this.state.withdrawal, this.state.withdrawalScreenshot);
  }

  disableSellDates = (date) => {
    return this.props.withdrawalVariables.sellDates.every(validWithdrawalDate => !dayjs(validWithdrawalDate).isSame(date, 'day'));
  };

  disableStartDates = (date) => {
    return this.props.withdrawalVariables.startDates.every(validWithdrawalDate => !dayjs(validWithdrawalDate).isSame(date, 'day'));
  };

  diableDatesMoreThan90Days = (date) => {
    date = dayjs(date);
    const dateNow = dayjs();
    return date.diff(dateNow, 'day') > this.props.withdrawalVariables.numOfRollingDays;
  }

  amountHandle = ({ floatValue = 0 }) => {
    this.withdrawalStateSet('amount', floatValue);

    if (this.props.isProportional) {
      this.proportionalAmountsSet(floatValue);
    }
  }

  amountTypeHandle = (e) => {
    const amountType = e.target.value;
    const { accountId } = this.state;
    const { isProportional, preTransactionDetails } = this.props;

    this.withdrawalStateSet('amountType', amountType, () => {
      switch (amountType) {
        case FULL_BALANCE:
          if (isProportional) {
            this.withdrawalStateSet('amount', preTransactionDetails.totalAvailableForWithdrawalNum);
            this.proportionalAmountsSet();
          }
          else {
            // set amount from the source account only
            const individualAvailableForWithdrawal = preTransactionDetails.groupAccounts.find(account => account.AccountId === accountId).availableForWithdrawalNum;
            this.withdrawalStateSet('amount', individualAvailableForWithdrawal);
          }
          break;
        case PARTIAL_BALANCE:
          if (isProportional) {
            this.withdrawalStateSet('amount', 0);
            this.proportionalAmountsSet();
          }
          else {
            this.withdrawalStateSet('amount', 0);
          }
          break;
        default:
          this.withdrawalStateSet('amount', 0);
      }
    });
  }

  savePoaBankAgreement = () => {
    const { poaBank } = this.state;
    const {
      savePOABankSelectionAgreement,
      getBankAccounts,
    } = this.props;

    this.setState({ savingPoaBankAgreement: true });
    savePOABankSelectionAgreement(poaBank.BankAccountId)
      .then(() => {
        // reload updated bank accounts and continue with bank selection
        return getBankAccounts()
          .then(() => {
            this.withdrawalStateSet('paymentAccount', poaBank);
            this.setState({
              savingPoaBankAgreement: false,
              poaBankModalOpen: false,
              poaBank: null,
            });
          });
      })
      .catch(() => this.setState({
        savingPoaBankAgreement: false,
        poaBankModalOpen: false,
        poaBank: null,
      }));
  }

  cancelPoaBankAgreement = () => {
    this.setState({
      poaBankModalOpen: false,
      poaBank: null,
    });
  }

  paymentAccountSelectHandle = (paymentId) => {
    // Searching through all bank accounts should be fast since there are not many.
    const selectedPaymentAccount = this.state.paymentAccounts.find(acct => acct.id === paymentId);
    if (selectedPaymentAccount.DoesOwnerNeedNotification) {
      this.setState({
        poaBankModalOpen: true,
        poaBank: selectedPaymentAccount,
      });
    }
    else {
      this.withdrawalStateSet('paymentAccount', selectedPaymentAccount);
    }
  }

  errorGet = (key) => {
    const error = this.state.errors.find(error => error.key === key);
    return error ? error.message : '';
  }

  formValidate = (nextStep = () => null) => {
    const {
      isProportional,
      preTransactionDetails: { totalAvailableForWithdrawalNum, groupAccounts },
      withdrawalVariables: { minWithdrawal, maxWithdrawal, maxWithdrawalRecurring, numOfRollingDays },
      text: { Withdrawals }
    } = this.props;
    const { withdrawal, accountId } = this.state;
    const paymentDeliveryType = withdrawal.paymentDeliveryType;

    switch (this.state.stepIndex) {
      case 0: { // Withdrawal Type
        this.setState(({ withdrawal: { withdrawalType } }) => {
          const errors = [];
          if (!withdrawalType) { // validates withdrawal type (HIGHER_ED, ACCOUNT_OWNER, K12_ED) is selected
            errors.push({
              key: 'withdrawalType',
              message: Withdrawals.errors.withdrawal_type,
            });
          }

          return { errors, step0ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }
      case 1: { // Recipient
        this.setState(({ withdrawal: { recipient } }) => {
          const errors = [];
          if (!recipient) {
            errors.push({
              key: 'recipient',
              message: Withdrawals.errors.recipient_required,
            });
          }
          return { errors, step1ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }
      case 2: { // Amount, Sell Date & Pay Method
        const currAccount = groupAccounts.find(account => account.AccountId === accountId);
        const individualAvailableForWithdrawal = currAccount.availableForWithdrawalNum;

        // I removed conditional for totalMarketValue
        // there is another scheduled individual full balance withdrawal
        this.setState(({
          isEditing,
          withdrawalScreenshot,
          withdrawal: {
            amount,
            pendingAmount,
            amountType,
            frequency,
            sellDate,
            startDate,
            endDate,
            paymentAccount,
            recipient,
            recipientAddress: { school },
          } }) => {
          const errors = [];
          const formattedPostal = school.postalCode.replace(/\D/g, '');

          const startDateChanged = !dayjs(startDate).isSame(withdrawalScreenshot.startDate);

          if (amount < minWithdrawal) { // validates amount is more than min withdrawal
            if (amountType !== FULL_BALANCE) {
              errors.push({
                key: 'amount',
                message: Withdrawals.errors.min_withdrawal(currencyFormatter(minWithdrawal)),
              });
            }
          }
          else if (isEditing) {
            // when editing switch to available for withdrawal + pending amount to edit available
            if (isProportional && amount > totalAvailableForWithdrawalNum + pendingAmount) {
              errors.push({
                key: 'amount',
                message: Withdrawals.errors.exceeds_total_available,
              });
            }
            else if (!isProportional && amount > individualAvailableForWithdrawal + pendingAmount) {
              if (!(isEditing && amountType === FULL_BALANCE)) {
                errors.push({
                  key: 'amount',
                  message: Withdrawals.errors.exceeds_individual_available,
                });
              }

            }
          }
          else {
            if (isProportional && amount > totalAvailableForWithdrawalNum) { // validates amount is less than available group balance for withdrawal
              errors.push({
                key: 'amount',
                message: Withdrawals.errors.exceeds_total_available,
              });
            }
            else if (!isProportional && amount > individualAvailableForWithdrawal) { // validates amount is less than available individual balance for withdrawal
              if (!(isEditing && amountType === FULL_BALANCE))
                errors.push({
                  key: 'amount',
                  message: Withdrawals.errors.exceeds_individual_available,
                });
            }
          }

          if (school.IsElectronicSchoolPaymentSchool) {

            let finalTotalAvailableForWithdrawalNum = totalAvailableForWithdrawalNum;
            let finalIndividualAvailableForWithdrawal = individualAvailableForWithdrawal;
            if (isEditing) {
              finalTotalAvailableForWithdrawalNum = totalAvailableForWithdrawalNum + pendingAmount;
              finalIndividualAvailableForWithdrawal = individualAvailableForWithdrawal + pendingAmount;
            }

            let ElectronicSchoolAmnt = amount;
            if (paymentDeliveryType === ELECTRONIC) {
              ElectronicSchoolAmnt = amountType === FULL_BALANCE ? Number(amount - 3.50) : Number(amount + 3.50);
            }

            if (isProportional && ElectronicSchoolAmnt > finalTotalAvailableForWithdrawalNum) {
              errors.push({
                key: 'amount',
                message: Withdrawals.errors.amnt_with_fee,
              });
            }
            if (!isProportional && ElectronicSchoolAmnt > finalIndividualAvailableForWithdrawal) {
              errors.push({
                key: 'amount',
                message: Withdrawals.errors.amnt_with_fee,
              });
            }
          }

          if (!amountType) { // validates amount type (full or partial) is selected
            errors.push({
              key: 'amountType',
              message: Withdrawals.errors.amount_type,
            });
          }

          if (frequency === ONE_TIME) {
            if (amount >= maxWithdrawal) { // validates amount is less than online maximum
              errors.push({
                key: 'amount',
                message: parse(Withdrawals.errors.max_withdrawal(currencyFormatter(maxWithdrawal))),
              });
            }
            // sell date
            if (!sellDate) { // validates a sell date is selected
              errors.push({
                key: 'sellDate',
                message: Withdrawals.errors.sell_date_required,
              });
            }
            else if (this.disableSellDates(sellDate)) { // validates that keyed-in date matches a valid sell date
              errors.push({
                key: 'sellDate',
                message: Withdrawals.errors.sell_date_valid,
              });
            }
          }
          else if (frequency === RECURRING) {
            if (withdrawal.paymentType === CHECK_PAYMENT) { // check is not allowed for recurring we should show an error and stop the user
              // this is to stop user from proceeding
              errors.push({
                key: 'paymentType',
                message: Withdrawals.errors.payment_method_required,
              });
            }

            if (amount >= maxWithdrawalRecurring) { // validates amount is less than online maximum divided by 3 for 90 days period
              errors.push({
                key: 'amount',
                message: Withdrawals.errors.max_withdrawal_recurring(currencyFormatter(maxWithdrawalRecurring)),
              });
            }
            // start date
            if (!startDate) { // validates a start date is selected
              errors.push({
                key: 'startDate',
                message: Withdrawals.errors.start_date_required,
              });
            }
            else if (!dayjs(startDate).isValid()) {
              errors.push({
                key: 'startDate',
                message: Withdrawals.errors.start_date_valid,
              });
            }
            else if (
              (!isEditing && this.disableStartDates(startDate)) ||
              (isEditing && startDateChanged && this.disableStartDates(startDate))
            ) {
              errors.push({
                key: 'startDate',
                message: Withdrawals.errors.start_date_enabled(numOfRollingDays),
              });
            }
            // optional end date
            if (endDate !== null) {
              if (!dayjs(endDate).isValid()) {
                errors.push({
                  key: 'endDate',
                  message: Withdrawals.errors.end_date_required,
                });
              }
              else if (dayjs(endDate).isSameOrBefore(startDate)) {
                errors.push({
                  key: 'endDate',
                  message: Withdrawals.errors.end_date_valid,
                });
              }
            }
          }

          switch (recipient) {
            case SCHOOL: {
              if (!school.IsElectronicSchoolPaymentSchool || paymentDeliveryType === MAIL) {
                if (school.name.length < 1) {
                  errors.push({
                    key: 'school',
                    message: Withdrawals.errors.school_required,
                  });
                }
                const street1Error = validator.street1Validator(school.street1.trim());
                if (street1Error) {
                  errors.push({
                    key: 'street1',
                    message: street1Error,
                  });
                }
                const cityError = validator.cityValidator(school.city.trim());
                if (cityError) {
                  errors.push({
                    key: 'city',
                    message: cityError,
                  });
                }
                if (!school.state.Code) {
                  errors.push({
                    key: 'state',
                    message: Withdrawals.errors.state_required,
                  });
                }
                if (formattedPostal.length !== 5 && formattedPostal.length !== 9) {
                  errors.push({
                    key: 'postalCode',
                    message: Withdrawals.errors.postal_code_required,
                  });
                }
                const attnError = validator.schoolAttnValidator(school.attn);
                if (attnError) {
                  errors.push({
                    key: 'attn',
                    message: attnError,
                  });
                }
              }

              if (school.IsElectronicSchoolPaymentSchool && paymentDeliveryType === '') {
                errors.push({
                  key: 'deliveryType',
                  message: 'Delivery Type is required',
                });
              }

              let studentIdNumError = '';
              if (school.IsElectronicSchoolPaymentSchool && paymentDeliveryType === ELECTRONIC) {
                let message = '';
                const student = school.ElectronicSchoolInfo.fields.find(field => field.id === 'student_id');
                if (student.pattern_message && !school.studentIdNum.match(student.pattern, '')) { // Electronic School validation
                  message = student.pattern_message;
                }
                else if (school.studentIdNum === '') {
                  message = 'Student ID is required.';
                }

                if (message !== '') {
                  errors.push({
                    key: 'studentIdNum',
                    message,
                  });
                }
              }
              else {
                studentIdNumError = validator.alphaNumericOnlyValidator(school.studentIdNum);
                if (studentIdNumError) {
                  errors.push({
                    key: 'studentIdNum',
                    message: studentIdNumError,
                  });
                }
              }
              break;
            }


            case ACCOUNT_OWNER:
              if (withdrawal.paymentType === ACH_PAYMENT && !paymentAccount.id) {
                errors.push({
                  key: 'paymentAccount',
                  message: Withdrawals.errors.payment_method_required,
                });
              }
              if (withdrawal.paymentType === ACCESS_CARD_PAYMENT && paymentAccount.AccessCardId && amount > paymentAccount.Capacity) { // check for amount exceeding selected Access Card capacity
                errors.push({
                  key: amountType === FULL_BALANCE ? 'amountType' : 'amount', // if Full Balance is selected, show error under that type selection area instead
                  message: Withdrawals.errors.exceeds_access_card_capcity,
                });
              }
              break;
            case BENEFICIARY:
              if (withdrawal.paymentType === ACH_PAYMENT && !paymentAccount.BankAccountId) {
                errors.push({
                  key: 'paymentAccount',
                  message: Withdrawals.errors.bank_required,
                });
              }
              break;

            default:
            // do nothing
          }

          if (!withdrawal.paymentType) {
            errors.push({
              key: 'paymentType',
              message: Withdrawals.errors.payment_type_required,
            });
          }

          return { errors, step2ValidationHasRun: true };
        }, () => this.state.errors.length === 0 && nextStep());
        break;
      }
      default: break;
    }
  }

  infoMessagesCompose = (messages) => {
    return (<div>{messages.map(msg => <p key={msg}>{msg}</p>)}</div>);
  }

  leaveAccountOpenHandle = () => {
    this.withdrawalStateSet('leaveAccountOpen', !this.state.withdrawal.leaveAccountOpen);
  }

  nextStepHandle = () => {
    // make sure user can't change the frequency after step 0
    this.setState(({ smallScreen, stepIndex, completed }) => {
      const nextIndex = stepIndex + 1;
      if (!smallScreen || nextIndex === 2) {
        window.scrollTo(0, 0);
      }
      const newCompleted = completed;
      newCompleted[stepIndex] = true;
      return ({
        frequencyChangeDisabled: true,
        stepIndex: nextIndex,
        completed: newCompleted,
      });
    });
  }

  clearWithdrawalByStep = (stepIndex) => {
    let clearedWithdrawalByStep = cloneDeep(this.state.withdrawal);
    // first clear the current step's values
    switch (stepIndex) {
      case 0:
        clearedWithdrawalByStep = {
          ...clearedWithdrawalByStep,
          amount: 0,
          paymentAccount: {},
          leaveAccountOpen: false,
          paymentType: '',
          proportionalAmounts: [],
          recipient: '',
          sellDate: this.props.withdrawalVariables.sellDates.length > 0 ? dayjs(this.props.withdrawalVariables.sellDates[0]) : null,
          startDate: this.props.withdrawalVariables.startDates.length > 0 ? dayjs(this.props.withdrawalVariables.startDates[0]) : null,
          endDate: null,
          actualSellDate: null,
          actualStartDateDayOfMonth: null,
          actualEndDate: null,
          createdDate: null,
          nextRunDate: null
        };
        break;

      case 1:
        clearedWithdrawalByStep = {
          ...clearedWithdrawalByStep,
          amount: 0,
          paymentAccount: {},
          leaveAccountOpen: false,
          proportionalAmounts: [],
          sellDate: this.props.withdrawalVariables.sellDates.length > 0 ? dayjs(this.props.withdrawalVariables.sellDates[0]) : null,
          startDate: this.props.withdrawalVariables.startDates.length > 0 ? dayjs(this.props.withdrawalVariables.startDates[0]) : null,
          endDate: null,
          actualSellDate: null,
          actualStartDateDayOfMonth: null,
          actualEndDate: null,
          createdDate: null,
          nextRunDate: null
        };
        break;

      case 2:
        clearedWithdrawalByStep = {
          ...clearedWithdrawalByStep,
          amount: 0,
          paymentAccount: {},
          leaveAccountOpen: false,
          proportionalAmounts: [],
          sellDate: this.props.withdrawalVariables.sellDates.length > 0 ? dayjs(this.props.withdrawalVariables.sellDates[0]) : null,
          startDate: this.props.withdrawalVariables.startDates.length > 0 ? dayjs(this.props.withdrawalVariables.startDates[0]) : null,
          endDate: null,
          actualSellDate: null,
          actualStartDateDayOfMonth: null,
          actualEndDate: null,
          createdDate: null,
          nextRunDate: null,
        };
        break;

      default: // nothing
    }

    return clearedWithdrawalByStep;
  }

  previousStepHandle = () => {
    const { paymentType, paymentDeliveryType } = this.state.withdrawal;
    // then make sure user can change the frequency when on step 0
    this.setState(({ stepIndex, completed, isEditing }) => {
      const previousIndex = stepIndex - 1;
      const newCompleted = completed;
      newCompleted[stepIndex] = false;
      const updatedState = {
        frequencyChangeDisabled: !(previousIndex === 0),
        stepIndex: previousIndex,
        completed: newCompleted,
        paymentDeliveryType,
        paymentType,
        errors: [], // assuming there are only errors on the current step
      };
      // allow clearing values on going back when creating a new withdrawal only
      if (!isEditing) {
        updatedState.withdrawal = this.clearWithdrawalByStep(stepIndex);
      }
      return (updatedState);
    });
  }

  paymentTypeHandle = e => {
    const { accessCards, preTransactionDetails: { AccountType } } = this.props;
    const { recipient } = this.state.withdrawal;
    const paymentType = e?.target?.value || '';

    // clear payment account first
    if (e !== undefined) {
      this.withdrawalStateSet('paymentAccount', {}, () => {
        switch (recipient) {
          case ACCOUNT_OWNER: {
            const agentBankAccounts = this.getAgentBankAccounts();
            if ((agentBankAccounts.length === 0 && accessCards.length === 0) || AccountType === ACCOUNT_TYPE_UGMA_UTMA)
              this.withdrawalStateSet('paymentType', CHECK_PAYMENT);
            else {
              this.withdrawalStateSet('paymentType', paymentType);
              // if access card also set the payment account
              if (accessCards.length === 1 && paymentType === ACCESS_CARD_PAYMENT)
                this.paymentAccountSelectHandle(accessCards[0].AccessCardId);
            }
            break;
          }
          case BENEFICIARY: {
            const beneBankAccounts = this.getBeneBankAccounts();
            if (beneBankAccounts.length === 0 || AccountType === ACCOUNT_TYPE_UGMA_UTMA)
              this.withdrawalStateSet('paymentType', CHECK_PAYMENT);
            else
              this.withdrawalStateSet('paymentType', paymentType);
            break;
          }
          case SCHOOL:
            this.withdrawalStateSet('paymentType', CHECK_PAYMENT);
            break;
          case ELECTRONICSCHOOL_PAYMENT:
            this.withdrawalStateSet('paymentType', CHECK_PAYMENT);
            this.withdrawalStateSet('paymenDeliveryType', ELECTRONIC);
            break;
          default:
            this.withdrawalStateSet('paymentType', '');
        }
      });
    }
  }

  proportionalAmountsSet = (value) => {
    const { preTransactionDetails: { groupAccounts } } = this.props;
    const { withdrawal: { amount, amountType } } = this.state;

    if (amountType === FULL_BALANCE) {
      this.withdrawalStateSet('proportionalAmounts', groupAccounts.map(account => ({
        accountId: account.AccountId,
        amount: account.marketValueNumber,
        accountNumber: account.AccountNumber
      })));
    }
    else {
      this.withdrawalStateSet('proportionalAmounts', groupAccounts.map(account => ({
        accountId: account.AccountId,
        amount: (value ? value : amount) * account.percentageOfTotalValue,
        accountNumber: account.AccountNumber
      })));
    }
  }

  radioButtonLabelGenerate = label => {
    return (
      <span className={styles.radioButtonsLabel}>
        <div className={styles.radioButtonsLabelText}>{label.name}</div>
        <div className={styles.radioButtonsInfoIcon}>
          {label.id !== 'I' && <InfoIcon message={this.infoMessagesCompose(label.messages)} />}
        </div>
      </span>
    );
  }

  recipientAddressHandle = (e, fieldName) => {
    if (e !== undefined) {
      const addresses = { ...this.state.withdrawal.recipientAddress };

      let value;
      if (fieldName === 'postalCode') {
        value = e.formattedValue;
      }
      else {
        value = e.target.value;
      }

      this.withdrawalStateSet('recipientAddress', { ...addresses, school: { ...addresses.school, [fieldName]: value } });
    }
  }

  recipientHandle = (e) => {
    const recipient = e.target.value;
    this.withdrawalStateSet('recipient', recipient);
    // always reset payment type when recipient changed except when school then set to check
    if (recipient === SCHOOL) {
      this.withdrawalStateSet('paymentType', CHECK_PAYMENT);
    }
    else {
      this.withdrawalStateSet('paymentType', '');
    }
  }

  schoolSelect = (schoolId, ElectronicSchoolPaymentSchoolId) => {
    const addresses = { ...this.state.withdrawal.recipientAddress };
    const { preTransactionDetails } = this.props;
    const { schoolAddresses } = preTransactionDetails;
    const { schools } = this.state;
    const beneficiaryName = preTransactionDetails.beneficiaryName;
 
    const school = schoolId === '0'
      ? schools.find(school => school.ElectronicSchoolPaymentSchoolId === ElectronicSchoolPaymentSchoolId)
      : schools.find(school => school.schoolId === schoolId);
    
    const recipientAddress = schoolAddresses.find(address => address.schoolId === schoolId);
    const isSchoolRetained = recipientAddress !== undefined;
  
    if (school.IsElectronicSchoolPaymentSchool) {
      this.setState({ schoolSearchLoading: true });
      this.props.getElectronicSchoolPaymentSchool(school.ElectronicSchoolPaymentSchoolId)
        .then(response => {
          this.setState(
            { schoolSearchLoading: false, isSchoolRetained, schoolSearchOpen: false, IsElectronicSchoolPaymentSchool: true },
            () => {
              this.withdrawalStateSet('recipientAddress', {
                school: {
                  fbo: school.fbo,
                  name: school.name,
                  IsElectronicSchoolPaymentSchool: school.IsElectronicSchoolPaymentSchool,
                  ElectronicSchoolPaymentSchoolId: school.ElectronicSchoolPaymentSchoolId,
                  fafsaId: school.fafsaId ? school.fafsaId : undefined,
                  studentIdNum: '',
                  beneficiaryName,
                  schoolId,
                  attn: isSchoolRetained && recipientAddress.attn ? recipientAddress.attn : '',
                  city: isSchoolRetained && recipientAddress.city ? recipientAddress.city : '',
                  postalCode: isSchoolRetained && recipientAddress.postalCode ? recipientAddress.postalCode : '',
                  state: isSchoolRetained && recipientAddress.state ? recipientAddress.state : { Code: '' },
                  street1: isSchoolRetained && recipientAddress.street1 ? recipientAddress.street1 : '',
                  street2: isSchoolRetained && recipientAddress.street2 ? recipientAddress.street2 : '',
                  street3: isSchoolRetained && recipientAddress.street3 ? recipientAddress.street3 : '',
                  ElectronicSchoolInfo: response.payload.data,
                }
              });
              this.withdrawalStateSet('paymentType', ELECTRONICSCHOOL_PAYMENT);
              this.withdrawalStateSet('paymentDeliveryType', ELECTRONIC);
            }
          );
        });
    }
    else {
      this.setState(
        { isSchoolRetained, schoolSearchOpen: false, IsElectronicSchoolPaymentSchool: false },
        () => {
          this.withdrawalStateSet('recipientAddress', {
            ...addresses,
            school: {
              fbo: school.fbo,
              name: school.name,
              IsElectronicSchoolPaymentSchool: false,
              ElectronicSchoolPaymentSchoolId: '',
              fafsaId: school.fafsaId ? school.fafsaId : undefined,
              studentIdNum: '',
              beneficiaryName,
              schoolId,
              attn: isSchoolRetained && recipientAddress.attn ? recipientAddress.attn : '',
              city: isSchoolRetained && recipientAddress.city ? recipientAddress.city : '',
              postalCode: isSchoolRetained && recipientAddress.postalCode ? recipientAddress.postalCode : '',
              state: isSchoolRetained && recipientAddress.state ? recipientAddress.state : { Code: '' },
              street1: isSchoolRetained && recipientAddress.street1 ? recipientAddress.street1 : '',
              street2: isSchoolRetained && recipientAddress.street2 ? recipientAddress.street2 : '',
              street3: isSchoolRetained && recipientAddress.street3 ? recipientAddress.street3 : '',
              ElectronicSchoolInfo: {},
            }
          });
          this.withdrawalStateSet('paymentType', CHECK_PAYMENT);
          this.withdrawalStateSet('paymentDeliveryType', MAIL);
        }
      );
    }
  }

  schoolsGet() {
    if (this.state.schools.length === 0) {
      this.setState({ schoolSearchLoading: true });
      this.props.getSchools()
        .then(response => {
          const data = response.payload.data;
          let id = -1;
          if (data && data.length > 0) {
            const schools = data.map(school => {
              ++id;
              return {
                id,
                city: properCase(school.City),
                fafsaId: school.Code,
                name: properCase(school.Name),
                schoolId: school.CollegeId.toString(),
                state: school.State,
                IsElectronicSchoolPaymentSchool: school.IsElectronicSchoolPaymentSchool,
                ElectronicSchoolPaymentSchoolId: school.ElectronicSchoolPaymentSchoolId,
              };
            });
            this.setState({ schools });
          }
        })
        .finally(() => this.setState({ schoolSearchLoading: false }));
    }
  }

  continueButtonHandle = () => this.setState({ partialWithdrawalMessageOpen: false });

  dateHandle = (date, dateKey) => {
    this.withdrawalStateSet(dateKey, date);

    if (dateKey === 'startDate' || dateKey === 'sellDate')
      this.props.checkDisplayWarning(date);
  }

  stateSelectHandle = (value) => {
    const addresses = { ...this.state.withdrawal.recipientAddress };
    this.withdrawalStateSet('recipientAddress', { ...addresses, school: { ...addresses.school, state: { Code: value } } });
  }

  getAgentBankAccounts = () => {
    const { bankAccounts } = this.props;
    // get agent's bank accounts
    return bankAccounts
      .filter(bank => bank.AllowWithdrawals)
      .filter(bank => bank.BeneficiaryId === null);
  }

  getBeneBankAccounts = () => {
    const {
      bankAccounts,
      preTransactionDetails,
    } = this.props;
    // get bene's bank accounts
    return bankAccounts
      .filter(bank => bank.AllowWithdrawals)
      .filter(bank => bank.BeneficiaryId === preTransactionDetails.BeneficiaryId);
  }

  handleSchoolSearch = () => {
    this.setState({ schoolSearchOpen: true });
    this.schoolsGet();
  }

  goToPreviousPage = () => {
    this.props.history.goBack();
  }

  handleFinish = () => {
    this.props.history.goBack();
  }

  stepperButtonsCompose = () => {
    const {
      withdrawalRequestLoading,
      partialWithdrawalMessageOpen,
      stepIndex,
      termsChecked,
      withdrawal: {
        paymentType,
        recipient,
      },
      showPrintButtonPage,
    } = this.state;

    const cancelButton = (
      <Button
        variant='text'
        color='primary'
        key='cancel'
        onClick={this.goToPreviousPage}
        disabled={withdrawalRequestLoading}
        style={{ marginRight: '5px' }}
      >
        Cancel
      </Button>
    );
    const nextButton = (onClick, disabled = false) => (
      <Button
        key='nextStep'
        variant='contained'
        onClick={onClick}
        disabled={disabled}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Next
      </Button>
    );
    const backButton = (
      <Button
        key='prevStep'
        variant='text'
        color='primary'
        onClick={() => this.previousStepHandle()}
        disabled={withdrawalRequestLoading}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Back
      </Button>
    );
    const agreeButton = (
      <Button
        disabled={!termsChecked || withdrawalRequestLoading}
        key='agreeStep'
        variant='contained'
        onClick={() => this.handleWithdrawalRequest()}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        I agree
      </Button>
    );

    const finishButton = (
      <Button
        key='finishButton'
        variant='contained'
        color='primary'
        onClick={this.handleFinish}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Finish
      </Button>
    );

    const printButton = (
      <Button
        key='printButton'
        variant='text'
        color='primary'
        onClick={window.print}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        Print
      </Button>
    );

    let buttons = [];
    switch (stepIndex) {
      case 0: // Withdrawal Type
        buttons = [
          cancelButton,
          nextButton(() => this.formValidate(this.nextStepHandle))
        ];
        break;
      case 1: // Recipient
        buttons = [
          cancelButton,
          backButton,
          nextButton(() => {
            this.formValidate(this.nextStepHandle);
            this.paymentTypeHandle();
          }),
        ];
        break;
      case 2: { // Amount, Sell Date & Pay Method
        const { preTransactionDetails: { agentAddressChanged, beneficiaryAddressChanged } } = this.props;

        if (!partialWithdrawalMessageOpen) {
          let isDisabled = false;
          if (recipient === ACCOUNT_OWNER && paymentType === CHECK_PAYMENT) {
            isDisabled = agentAddressChanged;
          }
          else if (recipient === BENEFICIARY) {
            isDisabled = beneficiaryAddressChanged;
          }
          buttons = [
            cancelButton,
            backButton,
            nextButton(() => {
              this.formValidate(this.nextStepHandle);
              this.paymentTypeHandle();
            }, isDisabled)
          ];
        }
        break;
      }
      case 3: // Review, Terms & Conditions
        if (!showPrintButtonPage) {
          buttons = [
            cancelButton,
            backButton,
            <LoadingOverlay key='loading' show={withdrawalRequestLoading}>
              {agreeButton}
            </LoadingOverlay>
          ];
        }
        else {
          buttons = [
            printButton,
            finishButton
          ];
        }

        break;
      default: break;
    }
    return buttons;
  }

  handleTermsChecked = () => {
    this.setState({ termsChecked: !this.state.termsChecked });
  }

  stepperContentCompose = () => {
    const {
      stepIndex,
      withdrawal,
      termsChecked,
      partialWithdrawalMessageOpen,
      partialBalanceWithdrawalOnly,
      fullBalanceWithdrawalOnly,
      showPrintButtonPage,
      nextRunStartDates,
      isSchoolRetained,
      isEditing,
      withdrawalScreenshot,
      accountIsUGMAUTMA,
    } = this.state;

    const {
      isProportional,
      preTransactionDetails,
      withdrawalVariables,
      usStates,
    } = this.props;

    let content;
    switch (stepIndex) {
      case 0: // Withdrawal Type
        content = (
          <Step0WithdrawalType
            isEditing={isEditing}
            withdrawal={withdrawal}
            withdrawalVariables={withdrawalVariables}
            radioButtonLabelGenerate={this.radioButtonLabelGenerate}
            withdrawalTypeHandle={this.withdrawalTypeHandle}
            errors={{
              withdrawalTypeError: this.errorGet('withdrawalType'),
            }}
          />
        );
        break;
      case 1: // Recipient
        content = (
          <Step1Recipient
            isEditing={isEditing}
            recipient={withdrawal.recipient}
            frequency={withdrawal.frequency}
            withdrawalType={withdrawal.withdrawalType}
            recipientTypes={withdrawalVariables.recipientTypes}
            radioButtonLabelGenerate={this.radioButtonLabelGenerate}
            recipientHandle={this.recipientHandle}
            recipientError={this.errorGet('recipient')}
          />
        );
        break;
      case 2: // Amount, Sell Date & Pay Method
        content = (
          <>
            <Step2AmountDate
              isEditing={isEditing}
              partialWithdrawalMessageOpen={partialWithdrawalMessageOpen}
              partialBalanceWithdrawalOnly={partialBalanceWithdrawalOnly}
              fullBalanceWithdrawalOnly={fullBalanceWithdrawalOnly}
              withdrawal={withdrawal}
              withdrawalScreenshot={withdrawalScreenshot}
              isProportional={isProportional}
              preTransactionDetails={preTransactionDetails}
              withdrawalVariables={withdrawalVariables}
              continueButtonHandle={this.continueButtonHandle}
              amountHandle={this.amountHandle}
              amountTypeHandle={this.amountTypeHandle}
              dateHandle={this.dateHandle}
              radioButtonLabelGenerate={this.radioButtonLabelGenerate}
              leaveAccountOpenHandle={this.leaveAccountOpenHandle}
              errors={{
                amountError: this.errorGet('amount'),
                amountTypeError: this.errorGet('amountType'),
                withdrawalTypeError: this.errorGet('withdrawalType'),
                sellDateError: this.errorGet('sellDate'),
                startDateError: this.errorGet('startDate'),
                endDateError: this.errorGet('endDate'),
              }}
              disableStartDates={this.disableStartDates}
              disableSellDates={this.disableSellDates}
              diableDatesMoreThan90Days={this.diableDatesMoreThan90Days}
              nextRunStartDates={nextRunStartDates}
            />
            {!partialWithdrawalMessageOpen && // disable payment method selection while partial withdrawal message is open
              <Step2PaymentMethod
                isEditing={isEditing}
                accountIsUGMAUTMA={accountIsUGMAUTMA}
                withdrawal={withdrawal}
                preTransactionDetails={preTransactionDetails}
                withdrawalVariables={withdrawalVariables}
                usStates={usStates}
                agentBankAccounts={this.getAgentBankAccounts()}
                beneBankAccounts={this.getBeneBankAccounts()}
                accessCards={this.props.accessCards}
                paymentTypeHandle={this.paymentTypeHandle}
                paymentAccountSelectHandle={this.paymentAccountSelectHandle}
                recipientAddressHandle={this.recipientAddressHandle}
                stateSelectHandle={this.stateSelectHandle}
                handleSchoolSearch={this.handleSchoolSearch}
                isSchoolRetained={isSchoolRetained}
                errors={{
                  bankAccountError: this.errorGet('paymentAccount'),
                  paymentTypeError: this.errorGet('paymentType'),
                  schoolError: this.errorGet('school'),
                  street1Error: this.errorGet('street1'),
                  cityError: this.errorGet('city'),
                  stateError: this.errorGet('state'),
                  postalCodeError: this.errorGet('postalCode'),
                  attnError: this.errorGet('attn'),
                  studentIdNumError: this.errorGet('studentIdNum'),
                  deliveryType: this.errorGet('deliveryType'),
                }}
                handleElectronicSchoolDeliveryType={ElectronicSchoolDeliveryType => this.handleElectronicSchoolDeliveryType(ElectronicSchoolDeliveryType)}
                ElectronicSchoolDeliveryType={this.state.withdrawal.paymentDeliveryType}
              />}
          </>
        );
        break;
      case 3: // Review, Terms & Conditions
        content = showPrintButtonPage ? (
          <Step3Print
            withdrawal={withdrawal}
            isProportional={isProportional}
            preTransactionDetails={preTransactionDetails}
          />
        )
          :
          (
            <Step3TermsAndConditions
              termsChecked={termsChecked}
              withdrawal={withdrawal}
              preTransactionDetails={preTransactionDetails}
              withdrawalVariables={withdrawalVariables}
              handleTermsChecked={this.handleTermsChecked}
            />
          );
        break;
      default:
        content = <div>Something wrong! Missing step component.</div>;
    }
    return content;
  }

  handleWithdrawalRequest = () => {
    const {
      accountId,
      groupId,
      withdrawal: {
        amount,
        amountType,
        paymentAccount,
        ignoreDuplicates,
        leaveAccountOpen,
        frequency,
        recipientAddress,
        paymentType,
        paymentDeliveryType,
        recipient,
        sellDate,
        startDate,
        endDate,
        withdrawalType,
      },
      isEditing,
    } = this.state;

    const {
      getPreTransactionDetails,
      setWithdrawalSubmitted,
      notificationShow,
      createNewWithdrawal,
      updateWithdrawal,
      ADDRESS_TYPES,
      isProportional,
      selectedTransaction,
    } = this.props;

    const isFullBalance = amountType === FULL_BALANCE;

    let WithdrawalType = '';
    if (paymentType === ELECTRONICSCHOOL_PAYMENT) { // possible types = CHECK_PAYMENT, ELECTRONICSCHOOL_PAYMENT
      WithdrawalType = paymentDeliveryType === MAIL ? CHECK_PAYMENT : ELECTRONICSCHOOL_PAYMENT;
    }
    else { // possible types =  CHECK_PAYMENT, ACH_PAYMENT, ACCESS_CARD_PAYMENT
      WithdrawalType = paymentType;
    }

    const withdrawalBody = {
      AccountId: accountId,
      Amount: amount,
      amountType,
      AccessCardId: paymentAccount.AccessCardId || null,
      BankAccountId: paymentAccount.BankAccountId || null,
      CloseAccount: amountType === PARTIAL_BALANCE ? false : !leaveAccountOpen, // Always leave account open when partial
      ExpenseType: withdrawalType,
      FullBalance: isFullBalance,
      IgnoreDuplicates: ignoreDuplicates,
      PayeeAddress: recipient === SCHOOL ? {
        StreetAddress1: sanitizeHTML(recipientAddress.school.street1),
        City: sanitizeHTML(recipientAddress.school.city),
        State: sanitizeHTML(recipientAddress.school.state.Code),
        PostalCode: sanitizeHTML(recipientAddress.school.postalCode.replace(/ /g, '')),
        Type: ADDRESS_TYPES.Mailing, // Forced for the time being
        Country: 'US', // Forced for the time being
      } : null,
      PayeeType: recipient,
      Proportional: isProportional,
      School: {
        Department: sanitizeHTML(recipientAddress.school.attn),
        SchoolId: parseInt(sanitizeHTML(recipientAddress.school.schoolId)),
        StudentNumber: sanitizeHTML(recipientAddress.school.studentIdNum),
        SchoolName: sanitizeHTML(recipientAddress.school.name),
        ElectronicSchoolPaymentSchoolId: sanitizeHTML(recipientAddress.school.ElectronicSchoolPaymentSchoolId),
      },
      WithdrawalType,
    };

    // for recurring trade date omitted, only start date is required, end date is optional
    switch (frequency) {
      case RECURRING:
        withdrawalBody.StartDate = dayjs(startDate).format('MM/DD/YYYY');
        withdrawalBody.ScheduleType = MONTHLY;
        withdrawalBody.DayOfMonth = startDate.date(); // this should be forced to 0-28
        if (endDate)
          withdrawalBody.EndDate = dayjs(endDate).format('MM/DD/YYYY');
        break;
      case ONE_TIME:
        withdrawalBody.TradeDate = dayjs(sellDate).format('MM/DD/YYYY');
        withdrawalBody.ScheduleType = ONE_TIME;
        break;
      default: // something wrong, do nothing, no dates included should throw api error
    }

    this.setState({ withdrawalRequestLoading: true });
    this.withdrawalStateSet('leaveAccountOpen', !withdrawalBody.CloseAccount);

    if (isEditing) {
      updateWithdrawal(selectedTransaction.WithdrawalScheduleId, withdrawalBody)
        .then((response) => {
          const data = response.payload.data;
          const dupeMsgs = Array.isArray(data) ? data.filter(message => message.MessageType === 3) : [];
          if (dupeMsgs.length > 0) {
            const duplicateWithdrawalMessage = dupeMsgs.map(message => message.Message).join('<\br>');
            this.setState({
              withdrawalRequestLoading: false,
              duplicateWithdrawalModalOpen: true,
              duplicateWithdrawalMessage
            });
          }
          else {
            // successfull withdrawal
            notificationShow('Withdrawal updated.', 'success');
            this.props.getNotifications(); // refreshes app notifications
            window.scrollTo(0, 0);
            // update actual dates
            const actualStartDateDayOfMonth = data.DayOfMonth;
            const actualEndDate = data.EndDate;
            const actualSellDate = data.SellDate;
            const createdDate = data.CreatedDate;
            // tell withdrawal details component through redux that it was submitted in order to update available for withdrawal value
            setWithdrawalSubmitted(true);
            // show print and finish buttons and only after that reload group account info and (racing cond)
            this.setState({
              withdrawalRequestLoading: false,
              showPrintButtonPage: true,
              withdrawal: { ...this.state.withdrawal, actualStartDateDayOfMonth, actualEndDate, actualSellDate, createdDate },
            }, () => {
              getPreTransactionDetails(accountId, groupId);
              // now withdrawal state is set take a screenshot of it for possible unsaved changes prompt
              const withdrawalScreenshot = cloneDeep(this.state.withdrawal);
              this.setState({ withdrawalScreenshot });
            });
          }
        })
        .catch(() => this.setState({ withdrawalRequestLoading: false }));
    }
    else {
      createNewWithdrawal(withdrawalBody)
        .then((response) => {
          const data = response.payload.data;
          const dupeMsgs = Array.isArray(data) ? data.filter(message => message.MessageType === 3) : [];
          if (dupeMsgs.length > 0) {
            const duplicateWithdrawalMessage = dupeMsgs.map(message => message.Message).join('<\br>');
            this.setState({
              withdrawalRequestLoading: false,
              duplicateWithdrawalModalOpen: true,
              duplicateWithdrawalMessage
            });
          }
          else {
            // Qualtrics Intercept for full balance Withdrawal. Launches survey  
            if (isFullBalance) {
              try {
                document.cookie = 'withdrawalCloseAcct=true';
                window.QSI.API.run(); // stimulate Targeting.php
                window.QSI.API.unload();
                window.QSI.API.load();
                window.QSI.API.run(); // stimulate Targeting.php
              }
              catch (error) {
                // return null;
              }
            }

            // successfull withdrawal
            notificationShow('New Withdrawal scheduled.', 'success');
            this.props.getNotifications(); // refreshes app notifications
            window.scrollTo(0, 0);
            // update actual dates
            const actualStartDateDayOfMonth = data.DayOfMonth;
            const actualEndDate = data.EndDate;
            const actualSellDate = data.SellDate;
            const createdDate = data.CreatedDate;
            // tell widhrawal details component through redux that it was submitted in order to update available for withdrawal value
            setWithdrawalSubmitted(true);
            // show print and finish buttons and only after that reload group account info and (racing cond)
            this.setState({
              withdrawalRequestLoading: false,
              showPrintButtonPage: true,
              withdrawal: { ...this.state.withdrawal, actualStartDateDayOfMonth, actualEndDate, actualSellDate, createdDate },
            }, () => {
              getPreTransactionDetails(accountId, groupId);
              // now withdrawal state is set take a screenshot of it for possible unsaved changes prompt
              const withdrawalScreenshot = cloneDeep(this.state.withdrawal);
              this.setState({ withdrawalScreenshot });
            });
          }
        })
        .catch(() => this.setState({ withdrawalRequestLoading: false }));
    }
  }

  withdrawalStateSet = (key, value, callback = () => null) => {
    // if there is a racing condition use callback to execute after state is set
    const { step0ValidationHasRun, step1ValidationHasRun, step2ValidationHasRun, stepIndex } = this.state;

    this.setState(({ withdrawal }) => {
      return { withdrawal: { ...withdrawal, [key]: value } };
    }, () => {
      if (
        (stepIndex === 0 && step0ValidationHasRun) ||
        (stepIndex === 1 && step1ValidationHasRun) ||
        (stepIndex === 2 && step2ValidationHasRun)
      ) {
        this.formValidate();
      }
      callback();
    });
  }

  withdrawalTypeHandle = (e) => {
    const withdrawalType = e.target.value;
    this.withdrawalStateSet('withdrawalType', withdrawalType);
    if (withdrawalType === K12_ED)
      this.withdrawalStateSet('recipient', ACCOUNT_OWNER); // Recipient gets set to ACCOUNT_OWNER if "K-12 Tution" is selected
  }

  onChangeFrequencyHandler = (e, tabIndex) => {
    const { isEditing, partialBalanceWithdrawalOnly, fullBalanceWithdrawalOnly } = this.state;

    // one time vs recurring
    this.setState({ tabIndex });
    // always force user to choose withdrawal, amount, and payment type after frequency changed
    this.withdrawalStateSet('withdrawalType', '');
    this.withdrawalStateSet('paymentType', '');
    if (!(partialBalanceWithdrawalOnly || fullBalanceWithdrawalOnly) && !isEditing)
      this.withdrawalStateSet('amountType', '');

    switch (tabIndex) {
      case 0:
        this.withdrawalStateSet('frequency', ONE_TIME);
        break;
      case 1:
        this.withdrawalStateSet('frequency', RECURRING);
        break;
      default:
      // do nothing
    }
  }

  checkPartialWithdrawalAllowed(currentAccount, preTransactionDetails) {
    const {
      isEditing,
      withdrawal: { pendingAmount, },
    } = this.state;
    const {
      isProportional,
    } = this.props;

    const allowFullWithdrawalIndividual = currentAccount.AllowFullWithdrawal;
    const allowFullWithdrawalGroup = preTransactionDetails.AllowFullWithdrawal;

    let fullBalanceWithdrawalOnly = false;
    const partialBalanceWithdrawalOnly = (isProportional && !allowFullWithdrawalGroup) || (!this.props.isProportional && !allowFullWithdrawalIndividual);
    const accountIsUGMAUTMA = preTransactionDetails.AccountType === ACCOUNT_TYPE_UGMA_UTMA;

    if (this.state.showPrintButtonPage) {
      this.setState({
        partialWithdrawalMessageOpen: isEditing ? false : partialBalanceWithdrawalOnly, // lets disable partial balance only preflight when editing
        partialBalanceWithdrawalOnly,
      });
    }
    else {
      let amountType = null;
      const updatedWithdrawal = this.state.withdrawal;

      if (
        !isProportional &&
        currentAccount.availableForWithdrawalNum + pendingAmount > 0 &&
        currentAccount.availableForWithdrawalNum + pendingAmount < 1
      ) {
        // only full balance is available
        amountType = FULL_BALANCE;
        fullBalanceWithdrawalOnly = true;
      }
      else if (!isEditing) {
        amountType = partialBalanceWithdrawalOnly ? PARTIAL_BALANCE : '';
      }


      if (amountType)
        updatedWithdrawal.amountType = amountType;

      if (amountType === FULL_BALANCE) {
        updatedWithdrawal.amount = preTransactionDetails.totalAvailableForWithdrawalNum + (isEditing ? pendingAmount : 0); // this is because API would give 0 amount
      }

      this.setState({
        partialWithdrawalMessageOpen: isEditing ? false : partialBalanceWithdrawalOnly,
        partialBalanceWithdrawalOnly: isEditing ? false : partialBalanceWithdrawalOnly,
        fullBalanceWithdrawalOnly: isEditing ? false : fullBalanceWithdrawalOnly,
        withdrawal: updatedWithdrawal,
        accountIsUGMAUTMA
      });
    }
  }

  setEditWithdrawal(selectedTransaction, paymentAccounts) {
    // set values & disable features when editing
    const frequency = selectedTransaction.WithdrawalScheduleType === ONE_TIME ? ONE_TIME : RECURRING;
    // set Tabs
    const tabIndex = frequency === RECURRING ? 1 : 0;
    const paymentAccount = paymentAccounts.find(acct => acct.id === selectedTransaction.BankAccountId || acct.id === selectedTransaction.AccessCardId);

    // also CustomAmounts.length === 0 is true when proportional, using Proportional boolean property instead
    // if CustomAmounts.lengtth !=== 0 it is always === 1 and one time withdrawal (and also Proportional === false)
    let amountType;
    if (selectedTransaction.Proportional) {
      amountType = selectedTransaction.FullMarketValue ? FULL_BALANCE : PARTIAL_BALANCE;
    }
    else {
      const individualFullMarketValue = selectedTransaction.CustomAmounts.find(amount => amount.AccountId === this.state.accountId).FullMarketValue;
      amountType = individualFullMarketValue ? FULL_BALANCE : PARTIAL_BALANCE;
    }

    const withdrawalToEdit = {
      amount: selectedTransaction.Amount,
      pendingAmount: selectedTransaction.Amount,
      amountType,
      paymentAccount: paymentAccount ? paymentAccount : {},
      leaveAccountOpen: selectedTransaction.CustomAmounts.length > 0 ? !(selectedTransaction.CustomAmounts[0].FullMarketValue && selectedTransaction.CustomAmounts[0].CloseAccount) :
        !(selectedTransaction.FullMarketValue && selectedTransaction.CloseAccounts),
      frequency,
      ignoreDuplicates: false,
      paymentType: selectedTransaction.WithdrawalType,
      paymentDeliveryType: selectedTransaction.WithdrawalType === ELECTRONICSCHOOL_PAYMENT ? ELECTRONIC : MAIL,
      proportionalAmounts: [],
      recipientAddress: {
        agent: this.props.preTransactionDetails.agentAddress,
        beneficiary: this.props.preTransactionDetails.beneficiaryAddress,
        school: {
          attn: selectedTransaction.SchoolDepartment,
          name: selectedTransaction.SchoolName,
          street1: selectedTransaction.SchoolAddress ? selectedTransaction.SchoolAddress.StreetAddress1 : '',
          street2: selectedTransaction.SchoolAddress ? selectedTransaction.SchoolAddress.StreetAddress2 : '',
          street3: selectedTransaction.SchoolAddress ? selectedTransaction.SchoolAddress.StreetAddress3 : '',
          city: selectedTransaction.SchoolAddress ? selectedTransaction.SchoolAddress.City : '',
          state: { Code: selectedTransaction.SchoolAddress ? selectedTransaction.SchoolAddress.State : '' },
          postalCode: selectedTransaction.SchoolAddress ? selectedTransaction.SchoolAddress.PostalCode : '',
          schoolId: selectedTransaction.SchoolId,
          fbo: this.props.preTransactionDetails.beneficiaryName,
          studentIdNum: selectedTransaction.StudentId,
          IsElectronicSchoolPaymentSchool: selectedTransaction.IsElectronicSchoolPaymentSchool,
          ElectronicSchoolPaymentSchoolId: selectedTransaction.ElectronicSchoolPaymentSchoolId,
          ElectronicSchoolInfo: selectedTransaction.ElectronicSchoolInfo,
        },
      },
      recipient: selectedTransaction.PayeeType,
      sellDate: selectedTransaction.SellDate ? dayjs(selectedTransaction.SellDate) : null,
      startDate: selectedTransaction.StartDate ? dayjs(selectedTransaction.StartDate) : null,
      endDate: selectedTransaction.EndDate ? dayjs(selectedTransaction.EndDate) : null,
      withdrawalType: selectedTransaction.ExpenseType,
      actualSellDate: selectedTransaction.SellDate ? dayjs(selectedTransaction.SellDate) : null,
      actualStartDateDayOfMonth: selectedTransaction.DayOfMonth,
      actualEndDate: selectedTransaction.EndDate ? dayjs(selectedTransaction.EndDate) : null,
      nextRunDate: selectedTransaction.NextRunDate,
    };

    this.setState({ withdrawal: withdrawalToEdit }, () => {
      this.setState({ tabIndex });
      if (this.props.isProportional) {
        this.proportionalAmountsSet(selectedTransaction.Amount);
      }
    });
  }

  handleCombinePaymentAccounts = () => {
    const { bankAccounts, accessCards } = this.props;
    const paymentAccounts = [...bankAccounts, ...accessCards].map(account => ({ ...account, id: account.BankAccountId || account.AccessCardId })); // combine payment accounts and create id property

    this.setState({ paymentAccounts });
    return paymentAccounts;
  }

  handleElectronicSchoolDeliveryType(ElectronicSchoolDeliveryType) {
    const errors = cloneDeep(this.state.errors);
    const withdrawal = cloneDeep(this.state.withdrawal);
    let newErrors = [];
    if (ElectronicSchoolDeliveryType === MAIL) {
      newErrors = errors.filter(error => error.key !== 'studentIdNum');
      withdrawal.paymentType = ELECTRONICSCHOOL_PAYMENT;
      withdrawal.paymentDeliveryType = MAIL;
    }
    else {
      newErrors = errors.filter(error => error.key === 'amount');
      const { school } = withdrawal.recipientAddress;
      school.street1 = '';
      school.city = '';
      school.state = '';
      school.postalCode = '';
      withdrawal.recipientAddress.school = school;
      withdrawal.paymentType = ELECTRONICSCHOOL_PAYMENT;
      withdrawal.paymentDeliveryType = ELECTRONIC;
    }
    this.setState({ withdrawal, errors: newErrors });
  }

  componentDidMount() {
    const {
      accessCards,
      bankAccounts,
      preTransactionDetails,
      withdrawalVariables,
      selectedTransaction,
      setWithdrawalSubmitted,
    } = this.props;

    const { accountId } = this.state;

    // reset for not submitted yet
    setWithdrawalSubmitted(false);

    // when props get changed asynchronously but component managed to get mounted after
    if (preTransactionDetails.AccountId === accountId) {
      const currentAccount = preTransactionDetails.groupAccounts.find(account => account.AccountId === accountId);
      this.checkPartialWithdrawalAllowed(currentAccount, preTransactionDetails);
    }

    const promises = [];
    promises.push(this.props.getScheduleRunDates(withdrawalVariables.startDates, MONTHLY));
    if (bankAccounts.length === 0)
      promises.push(this.props.getBankAccounts());
    if (accessCards.length === 0)
      promises.push(this.props.getAccessCards());

    Promise.all(promises)
      .then(([nextRunStartDatesAction]) => {
        const paymentAccounts = this.handleCombinePaymentAccounts();
        this.setState({
          nextRunStartDates: nextRunStartDatesAction.payload.data,
        });
        if (this.state.isEditing) {
          if (selectedTransaction.WithdrawalType === ELECTRONICSCHOOL_PAYMENT) {
            this.props.getElectronicSchoolPaymentSchool(selectedTransaction.ElectronicSchoolPaymentSchoolId)
              .then(response => {
                selectedTransaction.ElectronicSchoolInfo = response.payload.data;
                return this.setEditWithdrawal(selectedTransaction, paymentAccounts);
              });
          }
          else {
            return this.setEditWithdrawal(selectedTransaction, paymentAccounts);
          }
        }
        return;
      })
      .then(() => {
        // now withdrawal state is set take a screenshot of it for possible unsaved changes prompt
        const withdrawalScreenshot = cloneDeep(this.state.withdrawal);
        this.setState({ withdrawalScreenshot });
      })
      .finally(() => {
        this.setState({ initLoading: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      preTransactionDetails,
      checkDisplayWarning,
    } = this.props;

    const {
      accountId,
      withdrawal: { frequency, startDate, sellDate }
    } = this.state;

    // if recipient address changed update withdrawal
    if (!isEqual(prevProps.preTransactionDetails.agentAddress, this.props.preTransactionDetails.agentAddress)) {
      this.withdrawalStateSet('recipientAddress', { ...this.state.withdrawal.recipientAddress, agent: this.props.preTransactionDetails.agentAddress });
    }

    // if bene address changed update withdrawal
    if (!isEqual(prevProps.preTransactionDetails.beneficiaryAddress, this.props.preTransactionDetails.beneficiaryAddress)) {
      this.withdrawalStateSet('recipientAddress', { ...this.state.withdrawal.recipientAddress, beneficiary: this.props.preTransactionDetails.beneficiaryAddress });
    }

    // component might be already mounted when props get changed asynchronously
    const prevPropsAccountId = prevProps.preTransactionDetails.AccountId;
    if (prevPropsAccountId > 0 && prevPropsAccountId === accountId) {
      const isRecurring = frequency === RECURRING;
      // check the tradecutoff date warning
      if (isRecurring) {
        if (startDate)
          checkDisplayWarning(startDate);
      }
      else if (sellDate) {
        checkDisplayWarning(sellDate);
      }

      const currentAccountPrev = prevProps.preTransactionDetails.groupAccounts.find(account => account.AccountId === accountId);
      const currentAccount = preTransactionDetails.groupAccounts.find(account => account.AccountId === accountId);
      if (
        (prevProps.preTransactionDetails.AllowFullWithdrawal !== preTransactionDetails.AllowFullWithdrawal ||
          currentAccountPrev.AllowFullWithdrawal !== currentAccount.AllowFullWithdrawal ||
          prevState.withdrawal.pendingAmount !== this.state.withdrawal.pendingAmount)
      ) {
        this.checkPartialWithdrawalAllowed(currentAccount, preTransactionDetails);
      }
    }
  }

  render() {
    const {
      isEditing,
      initLoading,
      stepIndex,
      tabIndex,
      completed,
      withdrawalRequestLoading,
      duplicateWithdrawalModalOpen,
      duplicateWithdrawalMessage,
      schoolSearchOpen,
      smallScreen,
      withdrawal,
      frequencyChangeDisabled,
      accountIsUGMAUTMA,
      savingPoaBankAgreement,
      poaBankModalOpen,
      poaBank,
      schoolSearchLoading,
      schools,
    } = this.state;

    const {
      loading,
      text: { Withdrawals }
    } = this.props;

    const isRecurring = withdrawal.frequency === RECURRING;
    const frequencyTabsDisabled = frequencyChangeDisabled || accountIsUGMAUTMA || isEditing || (withdrawal.amount > 0 && withdrawal.amount < 1);

    return (
      <div>
        <div>
          <div>
            {initLoading ? <LoadingOverlay show={initLoading} /> :
              <Tabs className='hideOnPrint' onChange={this.onChangeFrequencyHandler} value={tabIndex} centered>
                <Tab label={Withdrawals.tab_labels[0]} disabled={frequencyTabsDisabled} />
                <Tab label={Withdrawals.tab_labels[1]} disabled={frequencyTabsDisabled} />
              </Tabs>
            }

            <Card>
              <div className={`${styles.stepperSteps} hideOnPrint`}>
                <Stepper
                  activeStep={stepIndex}
                  orientation={smallScreen ? 'vertical' : 'horizontal'}
                >
                  <Step completed={completed[0]}>
                    <StepButton
                      onClick={() => this.setState({ stepIndex: 0 })}
                      disabled
                    >
                      {Withdrawals.step_labels[0]}
                    </StepButton>
                  </Step>
                  <Step completed={completed[1]}>
                    <StepButton onClick={() => this.setState({ stepIndex: 1 })}
                      disabled
                    >
                      {Withdrawals.step_labels[1]}
                    </StepButton>
                  </Step>
                  <Step completed={completed[2]}>
                    <StepButton onClick={() => this.setState({ stepIndex: 2 })}
                      disabled
                    >
                      {isRecurring ? Withdrawals.step_labels[2].recurring : Withdrawals.step_labels[2].onetime}
                    </StepButton>
                  </Step>
                  <Step completed={completed[3]}>
                    <StepButton onClick={() => this.setState({ stepIndex: 3 })}
                      completed={completed[3]}
                      disabled
                    >
                      {Withdrawals.step_labels[3]}
                    </StepButton>
                  </Step>
                </Stepper>
              </div>

              <div>
                {loading ? <LoadingOverlay show={loading} /> : this.stepperContentCompose()}
              </div>

              <div className={`${styles.stepperButtons} hideOnPrint`}>
                {this.stepperButtonsCompose()}
              </div>
            </Card>
          </div>
        </div>

        <SchoolSearch
          loading={schoolSearchLoading}
          onClose={() => this.setState({ schoolSearchOpen: false })}
          open={schoolSearchOpen}
          schoolSelect={this.schoolSelect}
          schools={schools}
        />

        <Prompt
          message={Withdrawals.msg_unsaved_changes}
          when={this.displayUnsavedChangesPrompt()}
        />

        <ConfirmModal
          show={poaBankModalOpen}
          title={Withdrawals.poa_bank_modal_title}
          body={(
            <div>
              {Withdrawals.poa_bank_modal_body}
              {poaBank !== null && <p>{poaBank.BankName} {poaBank.BankAccountType}, {poaBank.MaskedBankAccountNumber}</p>}
            </div>
          )}
          onModalClose={this.cancelPoaBankAgreement}
          onConfirm={this.savePoaBankAgreement}
          isLoading={savingPoaBankAgreement}
        />

        <ConfirmModal
          show={duplicateWithdrawalModalOpen}
          title='Create Duplicate Withdrawal?'
          body={duplicateWithdrawalMessage}
          onModalClose={() => this.setState({ duplicateWithdrawalModalOpen: false })}
          onConfirm={() => this.setState({ duplicateWithdrawalModalOpen: false, withdrawal: { ...withdrawal, ignoreDuplicates: true } }, () => this.handleWithdrawalRequest())}
          isLoading={withdrawalRequestLoading}
        />
      </div>
    );
  }
}

export default withRouter(connect(select, {
  getNotifications,
  getPreTransactionDetails,
  setWithdrawalSubmitted,
  getScheduleRunDates,
  getSchools,
  notificationShow,
  createNewWithdrawal,
  updateWithdrawal,
  getBankAccounts,
  getAccessCards,
  savePOABankSelectionAgreement,
  getElectronicSchoolPaymentSchool,
})(LanguageHOC(NewWithdrawals)));