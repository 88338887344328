import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep, isEmpty } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';

import {
  LoadingOverlay,
  notificationShow,
  InfoIcon,
  PhoneNumberInput
} from '@frontend/common';

import {
  twoFactorRegistration,
  twoFactorRegistrationUpdate,
  getTwoFactorToken
} from '../../actions';

import RegisterPhone from 'components/Features/protected/Multifactor/Registration/RegisterPhone';
import RegisterAuthenticator from 'components/Features/protected/Multifactor/Registration/RegisterAuthenticator';

import { userLogout, refreshClaims } from 'components/AppRoot/Navigation/actions';
import { TWO_FACTOR_STATUS, TWO_FACTOR_TYPES } from 'components/AppRoot/Navigation/constants';
import { regMethodTypes } from '../../constants.js';

import styles from './styles.module.scss';

const select = (state) => ({
  userDetails: state.session.userDetails,
  claims: state.session.claims,
  is2FARegistered: state.session.is2FARegistered,
});

export class Register extends Component {

  static propTypes = {
    hideAuthenticatorButton: PropTypes.bool, // defaults to show the authenticator option
    twoFactorRegistration: PropTypes.func.isRequired,
    twoFactorRegistrationUpdate: PropTypes.func.isRequired,
    getTwoFactorToken: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    refreshClaims: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    claims: PropTypes.object.isRequired,
    closeWindow: PropTypes.func,
    registerType: PropTypes.string,
    is2FARegistered: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_learn_more: PropTypes.string,
        btn_register: PropTypes.string,
        err_options: PropTypes.string,
        err_required: PropTypes.string,
        err_phone_number_too_short: PropTypes.string,
        register_title: PropTypes.string,
        register_phonenumber_label: PropTypes.string,
        lbl_call: PropTypes.string,
        lbl_other: PropTypes.string,
        lbl_step1: PropTypes.string,
        lbl_step2: PropTypes.string,
        lbl_text: PropTypes.string,
        msg_authenticator_download: PropTypes.string,
        msg_2FA_phone: PropTypes.string,
        msg_2FA_reg_method: PropTypes.string,
        phone_path: PropTypes.string,
        authenticator_path: PropTypes.string,
        learn_more_url: PropTypes.string,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
      }),
      Home: PropTypes.shape({
        nav_path: PropTypes.string
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
    learnMoreOpen: false,
    phone: '',
    regMethod: '',
    formErrors: {
      phone: '',
      regMethod: ''
    },
    isRegisterPhone: false,
    isRegisterAuthenticator: false
  };

  handleOptions = e => {
    this.setState({ regMethod: e.target.value });
  };

  handlePhone = (phone) => {
    this.setState({
      phone: phone.replace(/\D/g, ''),
      formErrors: { ...this.state.formErrors, phone: '' }
    });
  }

  registerPhone() {
    const { text: { Multifactor }, claims } = this.props;
    const { phone, regMethod } = cloneDeep(this.state);
    const formErrors = {};

    if (regMethod === regMethodTypes.SMS || regMethod === regMethodTypes.CALL) {
      if (phone === '') {
        formErrors.phone = Multifactor.err_required;
      }
      else if (phone.length < 10) {
        formErrors.phone = Multifactor.err_phone_number_too_short;
      }
    }

    if (regMethod === '') {
      formErrors.regMethod = Multifactor.err_options;
    }

    if (isEmpty(formErrors)) {
      const { phone, regMethod } = this.state;
      const { registerType } = this.props;

      const regParams = {
        countryCode: '+1', // only domestic
        phoneNumber: phone, // this should be 10 digit US number stripped off of all non numerics
        emailAddress: this.props.userDetails.email,
        verificationMethod: regMethod
      };

      const apiCall = claims.TwoFactorRegistration === TWO_FACTOR_STATUS.COMPLETED
        ? 'twoFactorRegistrationUpdate'
        : 'twoFactorRegistration';

      this.setState({ isLoading: true });
      this.props[apiCall](regParams)
        .then(() => {
          switch (regMethod) {
            case regMethodTypes.SMS:
            case regMethodTypes.CALL: {
              this.props.getTwoFactorToken(regMethod)
                .then(() => {
                  registerType === TWO_FACTOR_TYPES.UPDATE
                    ? this.setState({ isRegisterPhone: true })
                    : this.props.history.push(Multifactor.phone_path);
                });
              break;
            }
            case regMethodTypes.INAPPTOKEN: {
              registerType === TWO_FACTOR_TYPES.UPDATE
                ? this.setState({ isRegisterAuthenticator: true })
                : this.props.history.push(Multifactor.authenticator_path);
              break;
            }
            default: break;
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
    else {
      this.setState({ formErrors });
    }
  }

  logout = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .then(() => {
        this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success');
      });
  }

  componentDidUpdate() {
    window.onpopstate = () => {
      if (this.props.registerType === TWO_FACTOR_TYPES.UPDATE) {
        this.props.refreshClaims();
      }
    };
  }

  render() {
    const { text: { Multifactor }, registerType, closeWindow, is2FARegistered, hideAuthenticatorButton } = this.props;
    const {
      isLoading, regMethod, formErrors,
      isRegisterPhone, isRegisterAuthenticator
    } = this.state;

    let renderComponent = '';
    if (isRegisterPhone) {
      renderComponent = <RegisterPhone registerType={registerType} closeWindow={closeWindow} />;
    }
    else if (isRegisterAuthenticator) {
      renderComponent = <RegisterAuthenticator />;
    }
    else {
      renderComponent = (
        <div style={{ paddingBottom: '20px' }}>
          <LoadingOverlay show={isLoading}>
            <h3>{Multifactor.register_title}</h3>

            <div className={styles.container}>
              <div className={styles.label}>{Multifactor.lbl_step1}</div>
              <div className={styles.text}>{Multifactor.msg_2FA_reg_method}</div>
            </div>
            <div className={styles.options}>
              <RadioGroup aria-label='registration method' name='method' value={regMethod} onChange={this.handleOptions}>

                <React.Fragment>
                  <FormControlLabel value={regMethodTypes.SMS} control={<Radio />} label={Multifactor.lbl_text} />
                  <FormControlLabel value={regMethodTypes.CALL} control={<Radio />} label={Multifactor.lbl_call} />
                </React.Fragment>

                {!hideAuthenticatorButton &&
                  <div className={styles.auth_container} style={{ textAlign: 'left' }}>
                    <div className={styles.authenticator}>
                      <FormControlLabel value={regMethodTypes.INAPPTOKEN} control={<Radio />} label={Multifactor.lbl_other} style={{ marginRight: '5px' }} />
                      <InfoIcon message={Multifactor.msg_authenticator_download} />
                    </div>
                  </div>
                }

              </RadioGroup>
              <FormHelperText style={{ color: 'red' }}>{formErrors.regMethod}</FormHelperText>
            </div>

            {(regMethod === regMethodTypes.SMS || regMethod === regMethodTypes.CALL) &&
              <>
                <div className={styles.container}>
                  <div className={styles.label}>{Multifactor.lbl_step2}</div>
                  <div className={styles.text}>{Multifactor.msg_2FA_phone}</div>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <PhoneNumberInput
                    disabled={isLoading}
                    label={Multifactor.register_phonenumber_label}
                    name='2FA Phone'
                    onChange={({ formattedValue }) => this.handlePhone(formattedValue)}
                    errorText={formErrors.phone}
                    variant='outlined'
                  />
                </div>
              </>
            }


            <Button
              variant='contained'
              style={{ width: '60%' }}
              onClick={() => this.registerPhone()}
            >
              {Multifactor.btn_register}
            </Button>
            <Button
              color='primary'
              variant='text'
              style={{ width: '60%', marginTop: '10px' }}
              onClick={is2FARegistered ? closeWindow : this.logout}
            >
              {Multifactor.btn_cancel}
            </Button>
            <Button
              color='primary'
              variant='outlined'
              style={{ width: '60%', marginTop: '10px' }}
              onClick={() => window.open(Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
            >
              {Multifactor.btn_learn_more}
            </Button><br />
          </LoadingOverlay>
        </div>

      );
    }

    return (
      <div style={{ textAlign: 'center' }}>
        {renderComponent}
      </div>
    );
  }
}

export default withRouter(connect(select, {
  twoFactorRegistration,
  twoFactorRegistrationUpdate,
  getTwoFactorToken,
  refreshClaims,
  userLogout,
  notificationShow
})(LanguageHOC(Register)));