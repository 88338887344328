import {
  GETrequest,
  POSTrequest,
  PUTrequest,
} from 'utils/helpers/api_handler';
import {
  BROWSER_WARNING,
  INITIALIZE_STORE,
  LANGUAGE_CHANGE,
  RESEND_EMAIL_VALIDATION,
  USER_LOGIN,
  CHANGE_USERNAME,
  USER_REGISTRATION,
  USER_REG_INFO_SYNC,
  RESEND_REGISTRATION_EMAIL,
  VERIFY_RECAPTCHA,
  REFRESH_CLAIMS,
  RESET_CLAIMS,
  TOGGLE_SIDENAV,
  TOGGLE_NOTIFICATIONS,
  SET_NOTIFICATIONS_VIEWED,
  TOGGLE_2FA_DIALOG,
  TOGGLE_AUTHYDOWN_DIALOG,
  TOGGLE_RSA_DIALOG,
  TOGGLE_SPLASH_HAS_DISPLAYED,
  TWO_FACTOR_PRECONDITION,
  RSA_PRECONDITION,
  GET_USER_PREFERENCES,
  SAVE_USER_PREFERENCES,
  GET_NOTIFICATIONS,
  TWO_FACTOR_ROUTE_CHANGE,
  SAVE_BIRTHDATE,
  DISABLE_LEFT_MENU
} from './constants';

////////////////////////////////// LOGIN //////////////////////////////////
export function userLogin(params) {
  const response = POSTrequest('/authentication/login', params);
  return {
    type: USER_LOGIN,
    payload: response
  };
}

////////////////////////////////// LOGOUT //////////////////////////////////
export function userLogout(params) {
  const response = POSTrequest('/authentication/logout', params);

  // deletes the previously created TMX script so that a new one can be created with a correct SessionId
  const scriptToDelete = document.getElementById('tmxScript');
  scriptToDelete && document.getElementById('tmxScript').parentNode.removeChild(scriptToDelete); // if user's session times out, there may not be a scriptToDelete which causes an ugly error

  return {
    type: INITIALIZE_STORE,
    payload: response
  };
}

////////////////////////////////// CHANGE USERNAME WITH NEW SESSION //////////////////////////////////
export function changeUsername(username, password) {
  const response = PUTrequest('/agents/username', { username, password });

  return {
    type: CHANGE_USERNAME,
    payload: response
  };
}

////////////////////////////////// SESSION MGMT //////////////////////////////////
export function setBrowserWarning() {
  return {
    type: BROWSER_WARNING,
  };
}

export function clearStore() {
  return {
    type: INITIALIZE_STORE
  };
}

export function changeLanguage(language) {
  return {
    type: LANGUAGE_CHANGE,
    meta: { language }
  };
}

export function resendEmailValidation() {
  const response = PUTrequest('/agents/resendEmailValidation');
  return {
    type: RESEND_EMAIL_VALIDATION,
    payload: response,
  };
}

export function registerUser(registrationInfo) {
  const response = POSTrequest('/SimpleSignup/Register', registrationInfo);
  return {
    type: USER_REGISTRATION,
    payload: response
  };
}

export function registerUserSync(registrationInfo) {
  return {
    type: USER_REG_INFO_SYNC,
    meta: registrationInfo
  };
}

export function resendEmail(registrationInfo) {
  const response = POSTrequest('/SimpleSignup/ResendConfirmationEmail', registrationInfo);
  return {
    type: RESEND_REGISTRATION_EMAIL,
    payload: response
  };
}

export function verifyRecaptcha(recaptchaToken) {
  const response = POSTrequest('/Recaptcha', { Token: recaptchaToken });
  return {
    type: VERIFY_RECAPTCHA,
    payload: response,
    meta: { recaptchaToken }
  };
}

export function refreshClaims() { // This only refreshes claims, not the token. The token that is returned is the same as the existing session.
  const response = GETrequest('/authentication/ClaimRefresh');
  return {
    type: REFRESH_CLAIMS,
    payload: response,
  };
}

export function resetClaims() {
  const response = GETrequest('/authentication/claims');
  return {
    type: RESET_CLAIMS,
    payload: response,
  };
}

export function toggleSidenav(isOpen) {
  return {
    type: TOGGLE_SIDENAV,
    meta: { isOpen }
  };
}

export function toggleNotifications(isOpen) {
  return {
    type: TOGGLE_NOTIFICATIONS,
    meta: { isOpen }
  };
}

export function setNotificationsViewed() {
  const response = POSTrequest('/agents/notificationsviewed');
  return {
    type: SET_NOTIFICATIONS_VIEWED,
    payload: response,
  };
}

export function toggle2FADialog() {
  return {
    type: TOGGLE_2FA_DIALOG
  };
}

export function toggleAuthyDownDialog() {
  return {
    type: TOGGLE_AUTHYDOWN_DIALOG,
  };
}

export function toggleRSADialog() {
  return {
    type: TOGGLE_RSA_DIALOG,
  };
}

export function get2FAPrecondition(isWithdrawal = false) {
  const response = GETrequest('/TwoFactor/precondition');
  return {
    type: TWO_FACTOR_PRECONDITION,
    payload: response,
    meta: { isWithdrawal }
  };
}

export function getRSAPrecondition() {
  const response = GETrequest('/RSA/precondition');
  return {
    type: RSA_PRECONDITION,
    payload: response
  };
}

export function getUserPreferences() {
  const response = GETrequest('/UserRegistration/Preferences');
  return {
    type: GET_USER_PREFERENCES,
    payload: response
  };
}

export function saveUserPreferences(preferences) {
  const response = PUTrequest('/UserRegistration/Preferences', preferences);
  return {
    type: SAVE_USER_PREFERENCES,
    payload: response
  };
}

export function getNotifications() {
  const response = GETrequest('/agents/Notifications');
  return {
    type: GET_NOTIFICATIONS,
    payload: response
  };
}

export function twoFaRouteChange(route) {
  return {
    type: TWO_FACTOR_ROUTE_CHANGE,
    meta: { route }
  };
}

export function saveBirthdate(birthdate) {
  const response = PUTrequest('/agents/birthdate', birthdate);
  return {
    type: SAVE_BIRTHDATE,
    payload: response
  };
}

export function toggleSplashHasDisplayed() {
  return {
    type: TOGGLE_SPLASH_HAS_DISPLAYED,
  };
}

////////////////////////////////// NAVIGATION MENU //////////////////////////////////
export function setDisableLeftMenu(disable) {
  return {
    type: DISABLE_LEFT_MENU,
    payload: disable
  };
}