/*
*
* Accounts Actions
*
*/
import {
  DELETErequest,
  GETrequest,
  POSTrequest,
  PUTrequest
} from 'utils/helpers/api_handler';

import {
  CHANGE_BENE_ADDRESS,
  CHANGE_SUCCESSOR,
  DELETE_BENE_ADDRESS,
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUMMARY,
  GET_BALANCE_HISTORY,
  GET_SUCCESSORS,
  GET_TRANSACTION_DETAILS,
  GET_UPCOMING_TRANSACTIONS,
  GET_ALL_UPCOMING_TRANSACTIONS,
  GET_ALL_TRANSACTIONS,
  GET_TRANSACTIONS_EXPORT,
  GET_ALL_TRANSACTIONS_EXPORT,
  REMOVE_SUCCESSOR,
  ACCOUNT_INVESTMENT_PATH_GET,
  GET_PRR_DATES,
  GET_PRR,
  SUBMIT_PROMO_CODE,
} from './constants';


export function changeBeneAddress(address, beneficiaryId) {
  const payload = PUTrequest(`/agents/bene/${beneficiaryId}/Address`, address);
  return {
    type: CHANGE_BENE_ADDRESS,
    payload,
    meta: { beneficiaryId }
  };
}

export function changeSuccessor(accountId, successor) {
  const response = POSTrequest(`/accounts/${accountId}/Successor`, successor);
  return {
    type: CHANGE_SUCCESSOR,
    payload: response,
  };
}

export function deleteBeneAddress(addressId, addressType, beneficiaryId) {
  const payload = DELETErequest(`/agents/bene/${beneficiaryId}/Address/${addressId}`);
  return {
    type: DELETE_BENE_ADDRESS,
    payload,
    meta: { addressType, beneficiaryId }
  };
}

export function getAccounts() {
  const response = GETrequest('/accounts');
  return {
    type: GET_ACCOUNTS,
    payload: response
  };
}

export function getAccountsSummary() {
  return {
    type: GET_ACCOUNTS_SUMMARY,
  };
}

export function getBalanceHistory() {
  const response = GETrequest('/accounts/BalanceHistory');
  return {
    type: GET_BALANCE_HISTORY,
    payload: response
  };
}

export function getSuccessors() {
  return {
    type: GET_SUCCESSORS,
  };
}

export function getUpcomingTransactions(accountId) {
  const response = GETrequest(`/accounts/${accountId}/schedules`);
  return {
    type: GET_UPCOMING_TRANSACTIONS,
    payload: response
  };
}

export function getAllUpcomingTransactions() {
  const response = GETrequest('/accounts/schedules');
  return {
    type: GET_ALL_UPCOMING_TRANSACTIONS,
    payload: response
  };
}

export function getAllTransactions() {
  const response = GETrequest('/accounts/transactions');
  return {
    type: GET_ALL_TRANSACTIONS,
    payload: response
  };
}

export function getTransactionsExport(accountId, filterDates) {
  const response = POSTrequest(`/accounts/${accountId}/transactions/download`, filterDates, { contentType: 'text/csv' });
  return {
    type: GET_TRANSACTIONS_EXPORT,
    payload: response
  };
}

export function getAllTransactionsExport(filterDates) {
  const response = POSTrequest('/accounts/transactions/download', filterDates, { contentType: 'text/csv' });
  return {
    type: GET_ALL_TRANSACTIONS_EXPORT,
    payload: response
  };
}

export function getTransactionDetails(accountId, transactionId) {
  const response = GETrequest(`/accounts/${accountId}/transactions/${transactionId}`);
  return {
    type: GET_TRANSACTION_DETAILS,
    payload: response
  };
}

export function removeSuccessor(accountId, successorId) {
  const response = DELETErequest(`/accounts/${accountId}/successor/${successorId}`);
  return {
    type: REMOVE_SUCCESSOR,
    payload: response,
  };
}

export function accountInvestmentPathGet(accountId, includeZeroAllocations = false) {
  const payload = GETrequest(`/options/account/${accountId}?includeZeroAllocations=${includeZeroAllocations}`);
  return {
    type: ACCOUNT_INVESTMENT_PATH_GET,
    payload,
  };
}

export function getPRRDates(accountId) {
  const payload = GETrequest(`/accounts/${accountId}/PRRDates`);
  return {
    type: GET_PRR_DATES,
    payload,
  };
}

export function getPRR(accountId, startDate, endDate) {
  const body = { startDate, endDate };
  const payload = POSTrequest(`/accounts/${accountId}/PRR`, body);
  return {
    type: GET_PRR,
    payload,
  };
}

export function submitPromoCode(accountId, promoCode) {
  const payload = POSTrequest(`/accounts/${accountId}/${promoCode}`);
  return {
    type: SUBMIT_PROMO_CODE,
    payload,
  };
}


