/*
*
* TransactionButtons Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Button,
  Icon,
  Menu,
  MenuItem,
} from '@mui/material';
import { MULTIFACTOR_TYPE } from 'components/AppRoot/Navigation/constants';
import { protectAction, protectWithdrawal } from 'utils/helpers/multifactor_handler';
import { get2FAPrecondition, getRSAPrecondition } from 'components/AppRoot/Navigation/actions';
import { openPreflightModal, setPreflightParams } from 'components/Features/protected/Accounts/Transactions/actions';
import { mobileWidth } from 'utils/config/_sassconfig.scss';

const select = (state) => ({
  claims: state.session.claims,
  preferredInfo: state.multifactor.preferredInfo,
});


export class TransactionButtons extends React.Component {

  static propTypes = {
    account: PropTypes.shape({
      accountId: PropTypes.number,
      permissions: PropTypes.shape({
        Contribute: PropTypes.bool,
        OptionChange: PropTypes.bool,
        Transfer: PropTypes.bool,
        Withdraw: PropTypes.bool,
        WithdrawalRequest: PropTypes.bool,
      })
    }).isRequired,
    callToActionType: PropTypes.string,
    claims: PropTypes.shape({
      TwoFactorAuthentication: PropTypes.string,
      RSAVerification: PropTypes.string,
    }).isRequired,
    get2FAPrecondition: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    setLastClicked: PropTypes.func,
    isCallToAction: PropTypes.bool,
    openPreflightModal: PropTypes.func.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    setPreflightParams: PropTypes.func.isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number
    }).isRequired,
    text: PropTypes.shape({
      Transactions: PropTypes.shape({
        btn_transactions: PropTypes.string,
        transaction_title: PropTypes.func,
        TRANSACTION_TYPES: PropTypes.shape({
          CONTRIBUTION: PropTypes.object,
          OPTION_CHANGE: PropTypes.object,
          TRANSFER: PropTypes.object,
          WITHDRAWAL: PropTypes.object,
          WITHDRAWAL_REQUEST: PropTypes.object,
        }),
      })
    }).isRequired,
  };

  static defaultProps = {
    isCallToAction: false,
  };

  state = {
    hasPrecondition: false,
    moreMenuAnchorPoints: {},
    preflightAccount: {},
    preflightTransactionType: ''
  };

  callToActionRender = () => {
    const { account, callToActionType, text: { Transactions } } = this.props;

    switch (callToActionType) {
      case Transactions.TRANSACTION_TYPES.CONTRIBUTION.type: {
        return (
          <Button
            variant='contained'
            onClick={() => this.preflightCheck(account, Transactions.TRANSACTION_TYPES.CONTRIBUTION.type)}
          >
            {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.CONTRIBUTION.type)}
          </Button>
        );
      }

      default: return;
    }
  }

  menuClose = (accountId) => {
    this.setState({ moreMenuAnchorPoints: { ...this.state.moreMenuAnchorPoints, [accountId]: null } });
  }

  menuOpen = (event, accountId) => {
    this.setState({ moreMenuAnchorPoints: { ...this.state.moreMenuAnchorPoints, [accountId]: event.currentTarget } });
  }

  preflightCheck = (account, transactionType) => {
    const { text: { Transactions }, preferredInfo, claims, setLastClicked, } = this.props;

    setLastClicked && setLastClicked();
    this.setState({
      preflightAccount: account,
      preflightTransactionType: transactionType
    });

    const isWithdrawal = transactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL.type || transactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type;
    const multiStatus = isWithdrawal
      ? protectWithdrawal(transactionType, Transactions, preferredInfo, claims)
      : protectAction(claims);
    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      /* 
      need to pass if is a withdrawal or not for new accounts = (oldest AO account < 45 days old)
      if is a new account, is a withdrawal, requires 2FA and RSA
      */
      this.setState(
        { hasPrecondition: true },
        () => this.props.get2FAPrecondition(isWithdrawal).catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        { hasPrecondition: true },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      this.props.setPreflightParams(account, transactionType);
      this.setState({
        hasPrecondition: false,
        preflightAccount: {},
        preflightTransactionType: ''
      });
      this.props.openPreflightModal();
    }
  }

  componentDidUpdate() {
    const { hasPrecondition, preflightAccount, preflightTransactionType } = this.state;
    const { text: { Transactions }, preferredInfo, claims } = this.props;
    const isWithdrawal = preflightTransactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL.type || preflightTransactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type;
    const multiStatus = isWithdrawal
      ? protectWithdrawal(preflightTransactionType, Transactions, preferredInfo, claims)
      : protectAction(claims);
    if (hasPrecondition && multiStatus === MULTIFACTOR_TYPE.AUTH) {
      this.preflightCheck(preflightAccount, preflightTransactionType);
    }
  }

  render() {
    const { account, isCallToAction, size, text: { Transactions } } = this.props;
    const { Contribute, OptionChange, Transfer, Withdraw, WithdrawalRequest } = account.permissions;
    const hasAnyAccountPermission = Contribute || OptionChange || Transfer || Withdraw || WithdrawalRequest;

    return (
      isCallToAction ?
        this.callToActionRender()
        :
        size.windowWidth < parseInt(mobileWidth) ?
          hasAnyAccountPermission &&
            <>
              <Button
                aria-controls='transaction-menu'
                aria-haspopup='true'
                color='primary'
                onClick={event => this.menuOpen(event, account.accountId)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {Transactions.btn_transactions}
                <Icon>keyboard_arrow_down</Icon>
              </Button>
              <Menu
                anchorEl={this.state.moreMenuAnchorPoints[account.accountId]}
                id='transaction-menu'
                onClose={() => this.menuClose(account.accountId)}
                open={Boolean(this.state.moreMenuAnchorPoints[account.accountId])}
              >
                {account.permissions.Contribute &&
                  <MenuItem
                    onClick={() => {
                      this.preflightCheck(account, Transactions.TRANSACTION_TYPES.CONTRIBUTION.type);
                      this.menuClose(account.accountId);
                    }}
                  >
                    {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.CONTRIBUTION.type)}
                  </MenuItem>
                }
                {account.permissions.Transfer &&
                  <MenuItem
                    onClick={() => {
                      this.preflightCheck(account, Transactions.TRANSACTION_TYPES.TRANSFER.type);
                      this.menuClose(account.accountId);
                    }}
                  >
                    {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.TRANSFER.type)}
                  </MenuItem>
                }
                {account.permissions.WithdrawalRequest &&
                  <MenuItem
                    onClick={() => {
                      this.preflightCheck(account, Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type);
                      this.menuClose(account.accountId);
                    }}
                  >
                    {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type)}
                  </MenuItem>
                }
                {account.permissions.Withdraw &&
                  <MenuItem
                    onClick={() => {
                      this.preflightCheck(account, Transactions.TRANSACTION_TYPES.WITHDRAWAL.type);
                      this.menuClose(account.accountId);
                    }}
                  >
                    {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.WITHDRAWAL.type)}
                  </MenuItem>
                }
                {account.permissions.OptionChange &&
                  <MenuItem
                    onClick={() => {
                      this.preflightCheck(account, Transactions.TRANSACTION_TYPES.OPTION_CHANGE.type);
                      this.menuClose(account.accountId);
                    }}
                  >
                    {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.OPTION_CHANGE.type)}
                  </MenuItem>
                }
              </Menu>
            </>
          :
          hasAnyAccountPermission &&
            <>
              {account.permissions.Contribute &&
                <Button color='primary' onClick={() => this.preflightCheck(account, Transactions.TRANSACTION_TYPES.CONTRIBUTION.type)}>
                  {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.CONTRIBUTION.type)}
                </Button>}
            
              {account.permissions.Transfer &&
                <Button color='primary' onClick={() => this.preflightCheck(account, Transactions.TRANSACTION_TYPES.TRANSFER.type)}>
                  {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.TRANSFER.type)}
                </Button>}
              
              {account.permissions.WithdrawalRequest &&
                <Button color='primary' onClick={() => this.preflightCheck(account, Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type)}>
                  {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type)}
                </Button>}
              
              {account.permissions.Withdraw &&
                <Button color='primary' onClick={() => this.preflightCheck(account, Transactions.TRANSACTION_TYPES.WITHDRAWAL.type)}>
                  {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.WITHDRAWAL.type)}
                </Button>}
              
              {account.permissions.OptionChange &&
                <Button color='primary' onClick={() => this.preflightCheck(account, Transactions.TRANSACTION_TYPES.OPTION_CHANGE.type)}>
                  {Transactions.transaction_title(Transactions.TRANSACTION_TYPES.OPTION_CHANGE.type)}
                </Button>}
            </>
    );
  }
}


export default connect(select, {
  get2FAPrecondition,
  getRSAPrecondition,
  openPreflightModal,
  setPreflightParams,
})(LanguageHOC(TransactionButtons));
