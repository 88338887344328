/*
*
* Transactions Constants
*
*/
export const ACCOUNT_TRANSACTION_PREVIEW = 'app/Features/protected/Accounts/Transactions/ACCOUNT_TRANSACTION_PREVIEW';
export const CLOSE_TRANSACTION_LOADER = 'app/Features/protected/Accounts/Transactions/CLOSE_TRANSACTION_LOADER';
export const OPEN_TRANSACTION_LOADER = 'app/Features/protected/Accounts/Transactions/OPEN_TRANSACTION_LOADER';
export const SET_PREFLIGHT_PARAMS = 'app/Features/protected/Accounts/Transactions/SET_PREFLIGHT_PARAMS';
export const GET_GROUP_ACCOUNTS = 'src/components/Features/protected/Accounts/GET_GROUP_ACCOUNTS';
export const GET_TRANSFER_ACCOUNTS = 'app/Features/protected/Accounts/Transactions/GET_TRANSFER_ACCOUNTS';
export const GET_SCHEDULED_TRANSACTION = 'app/Features/protected/Accounts/Transactions/GET_SCHEDULED_TRANSACTION';
export const TRANSACTION_DELETE = 'src/components/Features/protected/Accounts/TRANSACTION_DELETE';
export const TRANSFER_CREATE = 'app/Features/protected/Accounts/Transactions/TRANSFER_CREATE';
export const TRANSFER_UPDATE = 'app/Features/protected/Accounts/Transactions/TRANSFER_UPDATE';
export const GET_PRE_TRANSACTION_DETAILS = 'app/Features/protected/Accounts/Transactions/GET_OPTION_CHANGE_DETAILS';
export const GET_CONTRIBUTION_DETAILS = 'src/components/Features/protected/Contributions/GET_CONTRIBUTION_DETAILS';
export const CONTRIBUTION_CREATE = 'src/components/Features/protected/Contributions/CONTRIBUTION_CREATE';
export const CONTRIBUTION_EDIT = 'src/components/Features/protected/Contributions/CONTRIBUTION_EDIT';
export const CONTRIBUTION_GET = 'src/components/Features/protected/Contributions/CONTRIBUTION_GET';
export const SCHEDULE_RUN_DATES_GET = 'pp/Features/protected/Accounts/Transactions/SCHEDULE_RUN_DATES_GET';
export const NEXT_RUN_DATE_GET = 'src/components/Features/protected/Contributions/NEXT_RUN_DATE_GET';
export const WITHDRAWAL_CREATE = 'app/Features/protected/Accounts/Transactions/WITHDRAWAL_CREATE';
export const WITHDRAWAL_UPDATE = 'app/Features/protected/Accounts/Transactions/WITHDRAWAL_UPDATE';
export const SCHOOLS_GET = 'app/Features/protected/Accounts/Transactions/SCHOOLS_GET';
export const SET_WITHDRAWAL_SUBMITTED = 'app/Features/protected/Accounts/Transactions/SET_WITHDRAWAL_SUBMITTED';
export const BIRTHDATE_VERIFY = 'app/Features/protected/Accounts/Transactions/BIRTHDATE_VERIFY';
export const REQUEST_WITHDRAWAL = 'app/Features/protected/Accounts/Transactions/REQUEST_WITHDRAWAL';
export const GET_ELECTRONICPAYMENT_SCHOOL = 'app/Features/protected/Accounts/Transactions/GET_ELECTRONICPAYMENT_SCHOOL';