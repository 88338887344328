/*
 *
 * ReviewDetails Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import {
  currencyFormatter,
} from '@frontend/common';
import { BIRTHDAY, } from '../../constants';
import styles from '../styles.module.scss';

ReviewDetails.propTypes = {
  contribution: PropTypes.object.isRequired,
  nextRunDates: PropTypes.object.isRequired,
  text: PropTypes.shape({
    ContrubutionOneTimeReview: PropTypes.shape({
      title_account_number: PropTypes.string,
      title_amount: PropTypes.string,
      title_bank_account: PropTypes.string,
      title_bene_name: PropTypes.string,
      title_end_date: PropTypes.string,
      title_next_run_date: PropTypes.string,
      title_total: PropTypes.string,
      text_none: PropTypes.string,
    }),
    ContributionSpecialReview: PropTypes.shape({
      text_none: PropTypes.string,
      title_account_number: PropTypes.string,
      title_amount: PropTypes.string,
      title_bank_account: PropTypes.string,
      title_bene_name: PropTypes.string,
      title_end_date: PropTypes.string,
      title_next_run_date: PropTypes.string,
      title_total: PropTypes.string,
    }),
  }).isRequired,
};

export function ReviewDetails({
  contribution: { bankAccount, occasion, selectedAccounts, },
  nextRunDates,
  text: { ContributionSpecialReview },
}) {
  const populatedAccounts = selectedAccounts.filter(account => account.accountId);
  return (
    <React.Fragment>
      <div className={styles.reviewContainer}>
        <div className={styles.reviewBeneficiaryName}>
          <div className={styles.detailsTitle}>{ContributionSpecialReview.title_bene_name}</div>
          {populatedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{account.beneficiary.name.toUpperCase()}</div>))}
        </div>

        <div className={styles.reviewAccountNumber}>
          <div className={styles.detailsTitle}>{ContributionSpecialReview.title_account_number}</div>
          {populatedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{account.accountNumber}</div>))}
        </div>

        <div className={styles.reviewBankAccount}>
          <div className={styles.detailsTitle}>{ContributionSpecialReview.title_bank_account}</div>
          <div className={styles.detail}>{bankAccount.BankName} {bankAccount.BankAccountType}, {bankAccount.MaskedBankAccountNumber}</div>
        </div>

        <div className={styles.reviewNextDate}>
          <div className={styles.detailsTitle}>{ContributionSpecialReview.title_next_run_date}</div>
          {occasion === BIRTHDAY.id ?
            populatedAccounts.map(account => <div className={styles.detail} key={account.accountId}>{dayjs(account.nextBdayRunDate).format('L')}</div>)
            :
            populatedAccounts.map(account => <div className={styles.detail} key={account.accountId}>{dayjs(nextRunDates.day1).format('L')}</div>)
          }
        </div>

        {populatedAccounts.some(account => account.endDate) && (
          <div className={styles.reviewEndDate}>
            <div className={styles.detailsTitle}>{ContributionSpecialReview.title_end_date}</div>
            {populatedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{dayjs(account.endDate).isValid() ? <div>{dayjs(account.endDate).format('L')}</div> : <div>{ContributionSpecialReview.text_none}</div>}</div>))}
          </div>
        )}

        <div className={styles.reviewAmount}>
          <div className={styles.detailsTitle}>{ContributionSpecialReview.title_amount}</div>
          {populatedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{currencyFormatter(account.contributionAmount)}</div>))}
        </div>
      </div>

      <div className={styles.totalAmountStep2}>
        <div>{ContributionSpecialReview.title_total}</div>
        <div>{currencyFormatter(populatedAccounts.map(account => account.contributionAmount).reduce((val1, val2) => val1 + val2, 0))}</div>
      </div>
    </React.Fragment>
  );
}

export default (LanguageHOC(ReviewDetails));