import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import parse from 'html-react-parser';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
  TextField,
} from '@mui/material';

import {
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';
import FormWrapper from '../FormWrapper';

import { emailValidator } from 'utils/helpers/form_validation';

import { GETRecaptchaToken } from 'utils/helpers/api_handler';
import { verifyRecaptcha } from 'components/AppRoot/Navigation/actions';
import { sendUsername } from 'components/Features/public/ForgotUsername/actions';

import styles from './styles.module.scss';

const select = (state) => ({
  recaptchaToken: state.session.userDetails.recaptchaToken,
  recaptchaVerified: state.session.userDetails.recaptchaVerified,
  supportNumber: state.static.environmentVars.SupportPhoneNumber
});

export class ForgotUsername extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    sendUsername: PropTypes.func.isRequired,
    verifyRecaptcha: PropTypes.func.isRequired,
    recaptchaToken: PropTypes.string.isRequired,
    recaptchaVerified: PropTypes.bool.isRequired,
    supportNumber: PropTypes.string.isRequired,
    text: PropTypes.shape({
      ForgotUsername: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_ok: PropTypes.string,
        btn_submit: PropTypes.string,
        err_email_required: PropTypes.string,
        err_valid_email: PropTypes.string,
        lbl_beneficiary: PropTypes.string, 
        lbl_code: PropTypes.string,
        lbl_email: PropTypes.string,
        msg_general: PropTypes.func,
        msg_recaptcha_language: PropTypes.string,
        msg_request_submitted: PropTypes.string,
        nav_title: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    loading: false,
    canContinue: false,
    isMsgSubmitted: false,
    email: '',
    formErrors: {}
  };

  handleInput = name => e => {
    const val = e.target.value;
    if (val) {
      const helperText = this.props.text.ForgotUsername.lbl_email;
      this.setState({ 
        [name]: val,
        helperText,
        formErrors: {} 
      });
    }
    else {
      this.setState({ [name]: val });
    }
  }

  sendEmail = e => {
    e.preventDefault();
    const { email } = this.state;
    const emailError = emailValidator(email);
    if (emailError) {
      const formErrors = cloneDeep(this.state.formErrors);
      formErrors.email = emailError;
      this.setState({ formErrors });
    }
    else {
      this.setState({ loading: true });
      this.props.sendUsername({ EmailAddress: email })
        .then(() => {
          this.setState({ loading: false });
          this.props.notificationShow(this.props.text.ForgotUsername.msg_request_submitted, 'success');
        });
    }
  }

  recaptchaVerify = () => {
    GETRecaptchaToken('forgotusername')
      .then(token => {
        if (token !== this.props.recaptchaToken) {
          this.props.verifyRecaptcha(token)
            .then((response) => {
              if (!this.props.recaptchaVerified) {
                const warningMessage = response.payload.data['error-codes'][0];
                this.setState({ canContinue: true });
                this.props.notificationShow(warningMessage, 'warning');
              }
            });
        }
      });
  }

  componentDidMount() {
    this.recaptchaVerify();
  }

  render() {
    const { text: { ForgotUsername } } = this.props;
    const { loading, canContinue, formErrors, email } = this.state;
    const helperText = formErrors.email ? formErrors.email : '';
    return (
      <React.Fragment>
        <FormWrapper title={ForgotUsername.nav_title}>
          <LoadingOverlay
            show={loading}
            width='100%'
          >
            <p>{ForgotUsername.msg_general(this.props.supportNumber)}</p>
            <form onSubmit={this.sendEmail}>
              <TextField
                value={email}
                label={ForgotUsername.lbl_email}
                fullWidth
                autoFocus
                disabled={loading}
                onChange={this.handleInput('email')}
                error={Boolean(formErrors.email)}
                helperText={helperText}
                variant='filled'
              />
              <div className={styles.buttons}>
                <Button
                  variant='text'
                  color='primary'
                  style={{ width: '48%' }}
                  onClick={() => this.props.history.goBack()}
                  disabled={loading}
                >
                  {ForgotUsername.btn_cancel}
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={loading || canContinue}
                  fullWidth
                >
                  {ForgotUsername.btn_submit}
                </Button>
              </div>
            </form>
          </LoadingOverlay>
        </FormWrapper>

        <div className={styles.recaptcha}> {/* This reCAPTCHA text is required since we are hiding the badge on all pages */}
          <p>{parse(ForgotUsername.msg_recaptcha_language)}</p>
        </div>
      </React.Fragment>
    );  
  }
}

export default withRouter(connect(select, {
  notificationShow,
  sendUsername,
  verifyRecaptcha
})(LanguageHOC(ForgotUsername)));