/*
*
* TransferTermsAndConditions Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Icon,
} from '@mui/material';
import {
  currencyFormatter,
} from '@frontend/common';
import { TransferInputTypes, TransferTypes } from '../typeConstants';

import styles from './styles.module.scss';

TransferFromReview.propTypes = {
  transfer: PropTypes.object.isRequired,
  destinationAccounts: PropTypes.array.isRequired,
  sourceAccount: PropTypes.object.isRequired,
  Transfers: PropTypes.shape({
    title_source_account: PropTypes.string,
    head_source_status: PropTypes.string,
    head_account_number: PropTypes.string,
    head_account_hash: PropTypes.string,
    head_bene_name: PropTypes.string,
    head_account_type: PropTypes.string,
    head_account_option: PropTypes.string,
    head_option_change: PropTypes.string,
    head_opt_change: PropTypes.string,
    text_close_status: PropTypes.func,
  })
};

export function TransferFromReview({ transfer, destinationAccounts, sourceAccount, Transfers }) {
  const compactedTargets = transfer.TargetAccounts.filter(account => account.AccountId !== '' &&
    ((transfer.transferType === TransferTypes.partial && account[transfer.transferInputType] !== 0) || (transfer.transferType === TransferTypes.full && account.Percentage > 0))
  ).map(target => ({
    ...destinationAccounts.find(account => account.accountId === target.AccountId),
    ...target,
  }));
  const optionChanges = compactedTargets.some(target => target.accountGroupId === sourceAccount.accountGroupId);
  const total = transfer.transferInputType === TransferInputTypes.Amount ?
    currencyFormatter(compactedTargets.reduce((sum, target) => sum + target.Amount, 0)) :
    `${compactedTargets.reduce((sum, target) => sum + target.Percentage, 0)}%`;

  return (
    <React.Fragment>
      <h3 style={{ textAlign: 'center' }}>{Transfers.title_source_account}</h3>
      <div className={optionChanges ? styles.TransferFrom_reviewContainer : styles.TransferFrom_reviewContainerCompact} key='review'>
        {transfer.transferType === TransferTypes.full &&
        <div className={styles.Transfer_reviewCloseAccount}>
          <div>{Transfers.head_source_status}</div>
          <div>{Transfers.text_close_status(transfer.CloseAccount)}</div>
        </div>
        }
        <div className={styles.Transfer_reviewAccountNumber}>
          <div className={`${styles.Transfer_detailsTitle} ${styles.desktop}`}>
            {Transfers.head_account_number}
          </div>
          <div className={`${styles.Transfer_detailsTitle} ${styles.mobile}`}>
            {Transfers.head_account_hash}
          </div>
          <div className={styles.Transfer_detail}>
            {sourceAccount.accountNumber}
          </div>
        </div>
        <div className={`${styles.Transfer_reviewBeneficiaryName} ${styles.desktop}`}>
          <div className={styles.Transfer_detailsTitle}>
            {Transfers.head_bene_name}
          </div>
          <div className={styles.Transfer_detail}>
            {sourceAccount.beneficiary.name}
          </div>
        </div>
        <div className={`${styles.Transfer_reviewAccountType} ${styles.desktop}`}>
          <div className={styles.Transfer_detailsTitle}>
            {Transfers.head_account_type}
          </div>
          <div className={styles.Transfer_detail}>
            {sourceAccount.accountType}
          </div>
        </div>
        <div className={`${styles.Transfer_reviewAccountOption} ${styles.desktop}`}>
          <div className={styles.Transfer_detailsTitle}>
            {Transfers.head_account_option}
          </div>
          <div className={styles.Transfer_detail}>
            {sourceAccount.option.name}
          </div>
        </div>
        {optionChanges &&
          <div className={styles.Transfer_reviewOptionChange}>
            <div className={`${styles.Transfer_detailsTitle} ${styles.desktop}`}>
              {Transfers.head_option_change}
            </div>
            <div className={`${styles.Transfer_detailsTitle} ${styles.mobile}`}>
              {Transfers.head_opt_change}
            </div>
            <div className={styles.Transfer_detail}>
              <Icon
                style={{
                  fontSize: '16px',
                }}
                title={'Yes'}
              >
                {'check'}
              </Icon>
            </div>
          </div>
        }
        <div className={styles.Transfer_reviewAmount}>
          <div className={styles.Transfer_detailsTitle}>
            {transfer.transferInputType}
          </div>
          <div className={styles.Transfer_detail}>
            {total}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
TransferToReview.propTypes = {
  transfer: PropTypes.object.isRequired,
  destinationAccounts: PropTypes.array.isRequired,
  sourceAccount: PropTypes.object.isRequired,
  Transfers: PropTypes.shape({
    lbl_total: PropTypes.string,
    head_bene_name: PropTypes.string,
    title_destination_account: PropTypes.string,
    head_account_number: PropTypes.string,
    head_account_hash: PropTypes.string,
    head_account_type: PropTypes.string,
    head_account_option: PropTypes.string,
    head_option_change: PropTypes.string,
    head_opt_change: PropTypes.string,
  })
};

export function TransferToReview({ transfer, destinationAccounts, sourceAccount, Transfers }) {
  const compactedTargets = transfer.TargetAccounts.filter(account => account.AccountId !== '' &&
    ((transfer.transferType === TransferTypes.partial && account[transfer.transferInputType] !== 0) || (transfer.transferType === TransferTypes.full && account.Percentage > 0))
  ).map(target => ({
    ...destinationAccounts.find(account => account.accountId === target.AccountId),
    ...target,
  }));
  const pluralize = compactedTargets.length > 1 ? 's' : '';
  const optionChanges = compactedTargets.some(target => target.accountGroupId === sourceAccount.accountGroupId);
  
  return (
    <React.Fragment>
      <h3 style={{ textAlign: 'center', marginTop: '30px' }}>{Transfers.title_destination_account}{pluralize}</h3>
      <div className={optionChanges ? styles.TransferTo_reviewContainer : styles.TransferTo_reviewContainerCompact} key='review'>
        <div className={styles.Transfer_reviewAccountNumber}>
          <div className={`${styles.Transfer_detailsTitle} ${styles.desktop}`}>
            {Transfers.head_account_number}
          </div>
          <div className={`${styles.Transfer_detailsTitle} ${styles.mobile}`}>
            {Transfers.head_account_hash}
          </div>
          {compactedTargets.map(target => (
            <div className={styles.Transfer_detail} key={target.AccountId}>
              {target.accountNumber}
            </div>
          ))}
        </div>
        <div className={`${styles.Transfer_reviewBeneficiaryName} ${styles.desktop}`}>
          <div className={styles.Transfer_detailsTitle}>
            {Transfers.head_bene_name}{pluralize}
          </div>
          {compactedTargets.map(target => (
            <div className={styles.Transfer_detail} key={target.AccountId}>
              {target.beneficiary.name}
            </div>
          ))}
        </div>
        <div className={`${styles.Transfer_reviewAccountType} ${styles.desktop}`}>
          <div className={styles.Transfer_detailsTitle}>
            {Transfers.head_account_type}
          </div>
          {compactedTargets.map(target => (
            <div className={styles.Transfer_detail} key={target.AccountId}>
              {target.accountType}
            </div>
          ))}
        </div>
        <div className={`${styles.Transfer_reviewAccountOption} ${styles.desktop}`}>
          <div className={styles.Transfer_detailsTitle}>
            {Transfers.head_account_option}
          </div>
          {compactedTargets.map(target => (
            <div className={styles.Transfer_detail} key={target.AccountId}>
              {target.option.Name}
            </div>
          ))}
        </div>
        {optionChanges &&
          <div className={styles.Transfer_reviewOptionChange}>
            <div className={`${styles.Transfer_detailsTitle} ${styles.desktop}`}>
              {Transfers.head_option_change}
            </div>
            <div className={`${styles.Transfer_detailsTitle} ${styles.mobile}`}>
              {Transfers.head_opt_change}
            </div>
            {compactedTargets.map(target => (
              <div className={styles.Transfer_detail} key={target.AccountId}>
                <Icon
                  style={{
                    color: target.accountGroupId !== sourceAccount.accountGroupId && 'rgba(0,0,0,0)',
                    fontSize: '16px',
                  }}
                  title={target.accountGroupId === sourceAccount.accountGroupId ? 'Yes' : 'No'}
                >
                  {target.accountGroupId === sourceAccount.accountGroupId ? 'check' : 'close'}
                </Icon>
              </div>
            ))}
          </div>
        }
        <div className={styles.Transfer_reviewAmount}>
          <div className={styles.Transfer_detailsTitle}>
            {transfer.transferInputType}{pluralize}
          </div>
          {compactedTargets.map(target => (
            <div className={styles.Transfer_detail} key={target.AccountId}>
              {transfer.transferInputType === TransferInputTypes.Amount ? currencyFormatter(target.Amount) : `${target.Percentage}%`}
            </div>
          ))}
        </div>
        {transfer.transferInputType === TransferInputTypes.Amount &&
        <div className={styles.Transfer_reviewTotalAmount}>
          <div>{Transfers.lbl_total}:</div>
          <div>{currencyFormatter(compactedTargets.reduce((sum, target) => sum + target.Amount, 0))}</div>
        </div>}
      </div>
    </React.Fragment>
  );
}

TransferTermsAndConditions.propTypes = {
  termsChecked: PropTypes.bool.isRequired,
  onTermsToggled: PropTypes.func.isRequired,
  Transfers: PropTypes.shape({
    text_terms_conditions: PropTypes.func,
    text_terms_read: PropTypes.string,
  })
};

export function TransferTermsAndConditions({ termsChecked, onTermsToggled, Transfers, }) {
  return (
    <div className={styles.Transfer_termsAndConditions}>
      {Transfers.text_terms_conditions()}
      <div className={styles.Transfer_termsCheckbox}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsChecked}
              onChange={onTermsToggled}
            />
          }
          label={Transfers.text_terms_read}
        />
      </div>
    </div>
  );
}

export default TransferTermsAndConditions;
