/*
*
* Accounts Constants
*
*/
export const CHANGE_BENE_ADDRESS = 'src/components/Features/protected/Accounts/CHANGE_BENE_ADDRESS';
export const CHANGE_SUCCESSOR = 'src/components/Features/protected/Accounts/CHANGE_SUCCESSOR';
export const DELETE_BENE_ADDRESS = 'src/components/Features/protected/Accounts/DELETE_BENE_ADDRESS';
export const GET_ACCOUNTS = 'src/components/Features/protected/Accounts/GET_ACCOUNTS';
export const GET_ACCOUNTS_SUMMARY = 'src/components/Features/protected/Accounts/GET_ACCOUNTS_SUMMARY';
export const GET_BALANCE_HISTORY = 'src/components/Features/protected/Accounts/GET_BALANCE_HISTORY';
export const GET_SUCCESSORS = 'src/components/Features/protected/Accounts/GET_SUCCESSORS';
export const GET_TRANSACTION_DETAILS = 'src/components/Features/protected/Accounts/GET_TRANSACTION_DETAILS';
export const GET_UPCOMING_TRANSACTIONS = 'src/components/Features/protected/Accounts/GET_UPCOMING_TRANSACTIONS';
export const GET_ALL_UPCOMING_TRANSACTIONS = 'src/components/Features/protected/Accounts/GET_ALL_UPCOMING_TRANSACTIONS';
export const GET_ALL_TRANSACTIONS = 'src/components/Features/protected/Accounts/GET_ALL_TRANSACTIONS';
export const GET_TRANSACTIONS_EXPORT = 'src/components/Features/protected/Accounts/GET_TRANSACTIONS_EXPORT';
export const GET_ALL_TRANSACTIONS_EXPORT = 'src/components/Features/protected/Accounts/GET_ALL_TRANSACTIONS_EXPORT';
export const REMOVE_SUCCESSOR = 'src/components/Features/protected/Accounts/REMOVE_SUCCESSOR';
export const TRANSFER_DELETE = 'src/components/Features/protected/Accounts/TRANSFER_DELETE';
export const ACCOUNT_INVESTMENT_PATH_GET = 'src/components/Features/protected/Accounts/ACCOUNT_INVESTMENT_PATH_GET';
export const GET_PRR_DATES = 'src/components/Features/protected/Accounts/GET_PRR_DATES';
export const GET_PRR = 'src/components/Features/protected/Accounts/GET_PRR';
export const SUBMIT_PROMO_CODE = 'src/components/Features/protected/Accounts/SUBMIT_PROMO_CODE';