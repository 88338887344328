/*
*
* InterestedParties Reducers
*
*/
import { cloneDeep } from 'lodash';
import {
  ADD_INTERESTED_PARTY,
  DELETE_INTERESTED_PARTY,
  EDIT_INTERESTED_PARTY,
  GET_INTERESTED_PARTIES,
  GET_IP_ACTIVATION_INFO,
  RESEND_INTERESTED_PARTY_EMAIL,
} from './constants';

const initialState = {
  activationInfo: {
    emailAddress: '',
  },
  interestedParties: [],
};

function InterestedPartiesReducer(state = initialState, action) {
  
  const newState = cloneDeep(state);

  switch (action.type) {
    case ADD_INTERESTED_PARTY:
      newState.interestedParties.push(action.payload.data);

      return newState;

    case DELETE_INTERESTED_PARTY: {
      const deletedIdx = newState.interestedParties.findIndex(ip => ip.interestedPartyId === parseInt(action.meta.ipId));
      newState.interestedParties.splice(deletedIdx, 1);

      return newState;
    }

    case EDIT_INTERESTED_PARTY: {
      const editedIdx = newState.interestedParties.findIndex(ip => ip.interestedPartyId === parseInt(action.meta.ipId));
      newState.interestedParties.splice(editedIdx, 1, action.payload.data);

      return newState;
    }
    
    case GET_INTERESTED_PARTIES:
      newState.interestedParties = action.payload.data;
      return newState;
    
    case GET_IP_ACTIVATION_INFO:
      newState.activationInfo = action.payload.data;
      return newState;

    case RESEND_INTERESTED_PARTY_EMAIL: {
      const editedIdx = newState.interestedParties.findIndex(ip => ip.interestedPartyId === parseInt(action.payload.data.InterestedPartyId));
      let editedIp = newState.interestedParties[editedIdx];
      editedIp = {
        ...editedIp,
        pin: action.payload.data.Pin,
        status: action.payload.data.Status
      };

      newState.interestedParties.splice(editedIdx, 1, editedIp);

      return newState;
    }
    
    default:
      return state;
  }

}

export default InterestedPartiesReducer;