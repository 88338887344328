import dayjs from 'dayjs';
import * as English from 'utils/translations/common.js';

/* Validations in this file (to prevent duplication)
  accountOwnerBirthdateValidator
  beneficiaryBirthdateValidator
  calendarValidator
  cityValidator
  emailValidator
  firstNameValidator
  lastNameValidator
  middleNameValidator
  phoneNumValidator
  postalCodeValidator
  socialSecurityNumValidator
  stateValidator
  street1Validator
  userNameValidator
  alphaNumericOnlyValidator
  schoolAttnValidator
*/

// TODO When these move to Common we'll need to add the translations there. This will currently hard code English in.
export function firstNameValidator(value) {
  if (!value) {
    return English.errors.required;
  }
  if (value.length > 20) {
    return English.errors.field_max_length_20;
  }
  return '';
}

export function middleNameValidator(value) {
  if (value.length > 20) {
    return English.errors.field_max_length_20;
  }
  return '';
}

export function lastNameValidator(value) {
  if (!value) {
    return English.errors.required;
  }
  if (value.length > 40) {
    return English.errors.field_max_length_40;
  }
  return '';
}

export function socialSecurityNumValidator(value) {
  if (!value) {
    return English.errors.required;
  }
  if (value.length < 9 || value.length > 9) {
    return English.errors.ssn_length;
  }
  return '';
}

export function phoneNumValidator(value) {
  if (!value) {
    return English.errors.required;
  }
  if (value.length < 10) {
    return English.errors.phone_number_too_short;
  }
  return '';
}

export function street1Validator(value) {
  const street1Regex = /^[A-Za-z0-9 .,':#/-]+$/g; // eslint-disable-line no-useless-escape
  if (!value) {
    return English.errors.required;
  }
  if (!street1Regex.test(value)) {
    return English.errors.alpha_numeric_and_allowable_characters_only(". , - ' : # /"); // eslint-disable-line
  }
  if (value.length > 40) {
    return English.errors.field_max_length_40;
  }
  return '';
}

export function cityValidator(value) {
  const cityRegex = /^[A-Za-z .,'-]+$/g; // eslint-disable-line no-useless-escape
  if (!value) {
    return English.errors.required;
  }
  if (!cityRegex.test(value)) {
    return English.errors.alphabetic_and_allowable_characters_only(". , - '"); // eslint-disable-line
  }
  return '';
}

export function stateValidator(value) {
  if (!value) {
    return English.errors.required;
  }
  return '';
}

export function postalCodeValidator(value) {
  const postalCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{9}$)|(^\d{5} \d{4}$)/;
  if (!value) {
    return English.errors.required;
  }
  if (!value.match(postalCodeRegex)) {
    return English.errors.postal_code_reqs;
  }
  return '';
}

export function accountOwnerBirthdateValidator(value, minAge, maxAge) {
  if (!value) {
    return English.errors.required;
  }

  value = dayjs(value); // converts date to momentjs just in case it doesn't come in that way

  if (!dayjs(value).isValid()) {
    return English.errors.date_invalid;
  }

  else if (dayjs(value).isBefore(dayjs().subtract(maxAge, 'year')) ||
    dayjs(value).isAfter(dayjs().subtract(minAge, 'year'))
  ) {
    return English.errors.age_limits(minAge, maxAge);
  }

  return '';
}

export function beneficiaryBirthdateValidator(value, maxAge) {
  const minAge = 0;

  if (!value) {
    return English.errors.required;
  }

  value = dayjs(value); // converts date to momentjs just in case it doesn't come in that way

  if (!dayjs(value).isValid()) {
    return English.errors.date_invalid;
  }
  else if (dayjs(value).isBefore(dayjs().subtract(maxAge, 'year')) ||
    dayjs(value).isAfter(dayjs().subtract(minAge, 'year'))
  ) {
    return English.errors.age_limits(minAge, maxAge);
  }
  return '';
}

export function beneficiaryRelationshipValidator(value) {
  if (!value) {
    return English.errors.required;
  }
  return '';
}

export function calendarValidator(value, minDate, maxDate) {
  if (!value) {
    return English.errors.required;
  }

  value = dayjs(value); // converts date to momentjs just in case it doesn't come in that way
  if (!dayjs(value).isValid()) {
    return English.errors.date_invalid;
  }
  else if (minDate && maxDate && (dayjs(value).isAfter(dayjs(maxDate), 'day') || dayjs(value).isBefore(dayjs(minDate), 'day'))) {
    return English.errors.date_limits(dayjs(minDate).format('MM/DD/YYYY'), dayjs(maxDate).format('MM/DD/YYYY'));
  }
  return '';
}

export function amountValidator(value, minAmount, maxAmount) {
  if (!value) {
    return English.errors.amount_invalid;
  }
  else if (minAmount && maxAmount && (value > maxAmount || value < minAmount)) {
    return English.errors.amount_invalid;
  }
  return '';
}

export function userNameValidator(userName) {
  if (!userName) {
    return English.errors.required;
  }
  if (userName.length > 0 && userName.length < 6) {
    return English.errors.username_min_length;
  }
  if (userName.length > 40) {
    return English.errors.field_max_length_40;
  }
  if (userName.match(/\d\d\d\d/)) { // username cannot contain more than 3 numbers in a row
    return English.errors.username_with_numbers;
  }
  if (userName.match(/^\s+|\s+$/)) {
    return English.errors.no_trailing_leading_spaces; // no leading or trailing spaces
  }
  return '';
}

export function emailValidator(email) {
  const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/; // eslint-disable-line no-useless-escape
  if (!email) {
    return English.errors.required;
  }
  if (email.match(/\s+/)) {
    return English.errors.no_spaces_anywhere; // no spaces anywhere
  }
  if (!emailRegex.test(email)) {
    return English.errors.email_invalid;
  }
  return '';
}

export function alphaNumericOnlyValidator(str) {
  const nonAlphanumericCharsRegex = /[^0-9a-zA-Z ]/g;

  if (nonAlphanumericCharsRegex.test(str)) {
    return English.errors.alpha_numeric_only;
  }
  return '';
}

export function schoolAttnValidator(str) {
  const nonAllowableCharsRegex = /[^0-9a-zA-Z ""&.,':#/-]/g;

  if (nonAllowableCharsRegex.test(str)) {
    return English.errors.alpha_numeric_and_allowable_characters_only(". , ' / \" : & # -"); // eslint-disable-line
  }
  else if (str.length > 40) {
    return English.errors.field_max_length_40;
  }
  return '';
}