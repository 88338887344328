/*
*
* Special Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import { clone, isEqual, cloneDeep } from 'lodash';
import * as validator from 'utils/helpers/form_validation';
import {
  Card,
} from '@frontend/common';
import { getNextRunDate, } from 'components/Features/protected/Accounts/Transactions/actions';
import {
  ANNUAL_EVENT,
  BIRTHDAY,
  YEARLY,
} from '../constants';
import TransactionStepper from 'components/Features/protected/Accounts/Transactions/TransactionStepper';
import { Step0AmountAndDate, Step1Bank, Step2Terms, Step3PrintReview, } from 'components/Features/protected/Accounts/Transactions/Contributions/Special/StepComponents/';

import styles from './styles.module.scss';

const select = (state) => ({
  contributionEnvironmentVars: state.static.environmentVars,
  contributionDetails: state.transactions.contributionDetails,
  agentBankAccounts: state.payments.agentBankAccounts,
  nextRunDates: state.transactions.nextRunDates.yearly,
});

export class Special extends React.Component {
  static propTypes = {
    agentBankAccounts: PropTypes.array.isRequired,
    nextRunDates: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    isEditing: PropTypes.bool.isRequired,
    bankAccountsLoading: PropTypes.bool.isRequired,
    getNextRunDate: PropTypes.func.isRequired,
    contributionCreate: PropTypes.func.isRequired,
    contributionEdit: PropTypes.func.isRequired,
    toggleShowAddBank: PropTypes.func.isRequired,
    renderDuplicateConfirmModal: PropTypes.func.isRequired,
    duplicateContributionModalOpen: PropTypes.bool.isRequired,
    createdDate: PropTypes.string.isRequired,
    contributionEnvironmentVars: PropTypes.shape({
      CurrentTradeDate: PropTypes.string.isRequired,
      MaximumOneTimeOnlineContribution: PropTypes.number.isRequired,
      MinimumOneTimeOnlineContribution: PropTypes.number.isRequired,
      MaximumOnlineContributionAmount: PropTypes.number.isRequired,
      SupportPhoneNumber: PropTypes.string.isRequired,
      FaxNumber: PropTypes.string.isRequired,
    }),
    contributionDetails: PropTypes.shape({
      canContributeAccountList: PropTypes.array,
      contribution: PropTypes.shape({
        day1: PropTypes.string,
        occasion: PropTypes.string,
      }),
      selectedAccountId: PropTypes.number,
      // contribution: PropTypes.bool, TODO: Update this during edit story
    }).isRequired,
    text: PropTypes.shape({
      ContributionSpecial: PropTypes.shape({
        err_account_select: PropTypes.string,
        err_bankAccount_select: PropTypes.string,
        err_end_date_after_start: PropTypes.string,
        err_step_does_not_exist: PropTypes.string,
        lbl_step_labels: PropTypes.array,
        msg_unsaved_changes: PropTypes.string,
      })
    }),
  }

  state = {
    contribution: {
      bankAccount: {
        BankAccountId: '',
        BankName: '',
        MaskedBankAccountNumber: '',
      },
      day1: '',
      occasion: BIRTHDAY.id,
      selectedAccounts: [],
      type: YEARLY
    },
    contributionScreenshot: {
      bankAccount: {
        BankAccountId: '',
        BankName: '',
        MaskedBankAccountNumber: '',
      },
      day1: '',
      occasion: BIRTHDAY.id,
      selectedAccounts: [],
      type: YEARLY
    },
    errors: [],
    stepIndex: 0,
    step0ValidationHasRun: false,
    step1ValidationHasRun: false,
    termsChecked: false,
    stepHasError: false,
  };

  displayUnsavedChangesPrompt = () => {
    return !this.state.bankAccountsLoading && !isEqual(this.state.contribution, this.state.contributionScreenshot);
  }

  clearStepErrors = () => {
    this.setState({
      errors: [],
    });
  }

  accountSelect = (value, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    const matchingAccount = this.props.contributionDetails.canContributeAccountList.find(agentAccount => agentAccount.accountId === value);

    const accountUpdatedWithInputData = {
      ...matchingAccount,
      contributionAmount: selectedAccounts[rowIndex].contributionAmount,
      endDate: selectedAccounts[rowIndex].endDate
    };

    selectedAccounts.splice(rowIndex, 1, accountUpdatedWithInputData);
    this.contributionSet(0, 'selectedAccounts', selectedAccounts);
    this.nextRunDateGet({}, BIRTHDAY.id);
  }

  addAccount = () => {
    const { contribution: { selectedAccounts } } = this.state;
    const newSelectedAccounts = [...selectedAccounts, { accountId: '', contributionAmount: 0, endDate: '', beneficiary: { birthday: '' } }];
    this.contributionSet(0, 'selectedAccounts', newSelectedAccounts);
  }

  removeAccount = (rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    selectedAccounts.splice(rowIndex, 1);
    this.contributionSet('selectedAccounts', selectedAccounts);
  }

  amountHandle = ({ floatValue = 0 }, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;

    selectedAccounts[rowIndex].contributionAmount = floatValue;
    this.contributionSet(0, 'selectedAccounts', selectedAccounts);
  }

  bankAccountSelectHandle = (value) => {
    this.contributionSet(1, 'bankAccount', this.props.agentBankAccounts.find(bankAccount => bankAccount.BankAccountId === value));
  }

  contributionDateHandle = (date, day, rowIndex) => {
    const { contribution: { selectedAccounts } } = this.state;
    const account = selectedAccounts[rowIndex];
    const formattedDate = date ? dayjs(date).format('L') : null; // formatted to prevent unintentially timezone conversion

    switch (day) {
      case 'endDate':
        account.endDate = formattedDate;
        this.contributionSet(0, 'selectedAccounts', selectedAccounts);
        break;
      case 'day1':
        // wait till user finishes typing the date
        this.contributionSet(0, day, formattedDate);
        if (dayjs(formattedDate).isValid()) {
          this.nextRunDateGet(formattedDate, day);
        }
        break;
      default: // do nothing
    }
  }

  contributionSet = (step, key, value) => {
    this.setState(({ contribution }) => {
      return { contribution: { ...contribution, [key]: value } };
    }, () => {
      if ((step === 0) || (step === 1)) {
        this.formValidate(step);
      }
    });
  }

  errorGet = (key, row) => {
    const error = this.state.errors.find(error => error.key === key && error.row === row);
    return error ? error.message : '';
  }

  formValidate = step => {
    const { contributionEnvironmentVars: { MaximumOneTimeOnlineContribution, MinimumOneTimeOnlineContribution, CurrentTradeDate }, nextRunDates, text: { ContributionSpecial } } = this.props;
    const { day1, occasion, selectedAccounts, bankAccount } = this.state.contribution;
    const errors = [];

    switch (step) {
      case 0: {
        selectedAccounts.forEach(({ accountId, contributionAmount, endDate, nextBdayRunDate }, index) => {

          const amountMessage = validator.amountValidator(contributionAmount, MinimumOneTimeOnlineContribution, MaximumOneTimeOnlineContribution); // validates amounts against min and max parameters
          if (accountId && amountMessage) {
            errors.push({
              key: 'amount',
              message: amountMessage,
              row: index
            });
          }
          if (!accountId && contributionAmount) { // validates all accounts that also have amounts entered have a beneficiary selected
            errors.push({
              key: 'account',
              message: ContributionSpecial.err_account_select,
              row: index
            });
          }

          if (occasion === ANNUAL_EVENT.id) { // validates day1 is selected
            const day1Message = validator.calendarValidator(day1, CurrentTradeDate, dayjs(CurrentTradeDate).add(1, 'y'));
            if (day1Message) { // validate date format
              errors.push({
                key: 'day1',
                message: day1Message,
              });
            }
          }

          const endDateMessage = validator.calendarValidator(endDate);
          if (endDate && endDateMessage) { // validate date format
            errors.push({
              key: 'endDate',
              message: endDateMessage,
              row: index
            });
          }
          else if ( // validates end date
            (occasion === ANNUAL_EVENT.id && dayjs(endDate).isSameOrBefore(nextRunDates.day1, 'day')) ||
            (occasion === BIRTHDAY.id && dayjs(endDate).isSameOrBefore(nextBdayRunDate, 'day'))
          ) {
            errors.push({
              key: 'endDate',
              message: ContributionSpecial.err_end_date_after_start,
              row: index
            });
          }

        });
        this.setState({ errors, step0ValidationHasRun: true, stepHasError: errors.length > 0, });
        break;
      }

      case 1: {
        if (!bankAccount.BankAccountId) {
          errors.push({
            key: 'bankAccount',
            message: ContributionSpecial.err_bankAccount_select,
          });
        }
        this.setState({ errors, step1ValidationHasRun: true, stepHasError: errors.length > 0, });
        break;
      }
      default: break;
    }
    return errors.length === 0;
  }

  nextRunDateGet = (date, day) => {

    if (day === BIRTHDAY.id) {
      const { selectedAccounts } = this.state.contribution;
      const selectedAccountsWithBirthdays = selectedAccounts.filter(account => account.beneficiary.birthday);
      const birthdays = selectedAccountsWithBirthdays.map(account => account.beneficiary.birthday);

      this.props.getNextRunDate(birthdays, day, YEARLY)
        .then(() => {
          this.props.nextRunDates.birthday.forEach((bday, index) => {
            selectedAccountsWithBirthdays[index].nextBdayRunDate = bday;
          });
          this.contributionSet('selectedAccounts', selectedAccounts);
        });
    }
    else {
      this.props.getNextRunDate(date, day, YEARLY);
    }
  }

  contributionCreateHandler = () => {
    return this.props.contributionCreate(this.state.contribution)
      .then(() => this.setState({ contributionScreenshot: cloneDeep(this.state.contribution) }));
  }

  contributionEditHandler = () => {
    return this.props.contributionEdit(this.state.contribution)
      .then(() => this.setState({ contributionScreenshot: cloneDeep(this.state.contribution) }));
  }

  renderStepComponent = step => {
    const { agentBankAccounts, bankAccountsLoading, contributionDetails, contributionEnvironmentVars, isEditing, toggleShowAddBank, nextRunDates, createdDate, text: { ContributionSpecial } } = this.props;
    const { contribution, errors, termsChecked, } = this.state;
    let stepComponent;

    switch (step) {
      case 0: {
        stepComponent = (
          <Step0AmountAndDate
            isEditing={isEditing}
            errors={errors}
            contribution={contribution}
            contributionDetails={contributionDetails}
            contributionEnvironmentVars={contributionEnvironmentVars}
            nextRunDates={nextRunDates}
            errorGet={this.errorGet}
            contributionSet={this.contributionSet}
            contributionDateHandle={this.contributionDateHandle}
            accountSelect={this.accountSelect}
            amountHandle={this.amountHandle}
            addAccount={this.addAccount}
            removeAccount={this.removeAccount}
          />
        );
        break;
      }

      case 1: {
        stepComponent = (
          <Step1Bank
            errors={errors}
            agentBankAccounts={agentBankAccounts}
            bankAccountsLoading={bankAccountsLoading}
            toggleShowAddBank={toggleShowAddBank}
            contribution={contribution}
            errorGet={this.errorGet}
            bankAccountSelectHandle={this.bankAccountSelectHandle}
          />
        );
        break;
      }

      case 2: {
        stepComponent = (
          <Step2Terms
            contribution={contribution}
            termsChecked={termsChecked}
            nextRunDates={nextRunDates}
            phoneEnvironmentVars={{ SupportPhoneNumber: contributionEnvironmentVars.SupportPhoneNumber, FaxNumber: contributionEnvironmentVars.FaxNumber }}
            toggleTermsChecked={() => this.setState({ termsChecked: !termsChecked })}
          />
        );
        break;
      }

      case 3: {
        stepComponent = (
          <Step3PrintReview
            contribution={contribution}
            nextRunDates={nextRunDates}
            createdDate={createdDate}
          />
        );
        break;
      }

      default:
        stepComponent = <div>{ContributionSpecial.err_step_does_not_exist}</div>;
    }
    return stepComponent;
  }

  componentDidMount() {
    const { contributionDetails: { canContributeAccountList, contribution, selectedAccountId }, isEditing } = this.props;

    if (Object.keys(canContributeAccountList).length > 0) {
      if (isEditing) {
        this.setState({ contribution, contributionScreenshot: cloneDeep(contribution) },
          () => {
            this.nextRunDateGet(contribution.day1, contribution.occasion === BIRTHDAY.id ? BIRTHDAY.id : 'day1');
          });
      }
      else {
        // Need to go around contributionSet() helper so that nextRunDateGet() has account set to state. This can be replaced with the helper on future versions of React that do not batch state.
        this.setState(
          ({ contribution }) => {
            const contributionUpdated = {
              ...contribution,
              selectedAccounts: [clone(canContributeAccountList.find(agentAccount => agentAccount.accountId === selectedAccountId))]
            };
            return { contribution: contributionUpdated, contributionScreenshot: cloneDeep(contributionUpdated) };
          },
          () => this.nextRunDateGet('', BIRTHDAY.id)
        );
      }
    }
  }

  render() {
    const { isEditing, renderDuplicateConfirmModal, text: { ContributionSpecial } } = this.props;
    const { contribution, termsChecked, stepHasError, } = this.state;

    return (
      <div className={styles.container}>
        <Card>
          <TransactionStepper
            stepHasError={stepHasError}
            clearStepErrors={this.clearStepErrors}
            verifyStep={(step) => this.formValidate(step)}
            renderStepComponent={(step) => this.renderStepComponent(step)}
            handleSubmit={() => isEditing ? this.contributionEditHandler(contribution) : this.contributionCreateHandler(contribution)}
            stepLabels={ContributionSpecial.lbl_step_labels}
            submitStep={2}
            termsChecked={termsChecked}
            renderConfirmModal={renderDuplicateConfirmModal}
          />
        </Card>

        <Prompt
          message={ContributionSpecial.msg_unsaved_changes}
          when={this.displayUnsavedChangesPrompt()}
        />
      </div>
    );
  }
}


export default withRouter(connect(select, {
  getNextRunDate,
})(LanguageHOC(Special)));
