/* eslint-disable newline-per-chained-call */
/*
*
* StaticResources Reducers
*
*/
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import {
  ENVIRONMENT_VARIABLES_GET,
  PASSWORD_REQUIREMENTS_GET,
  STATIC_DOCUMENTS_GET,
  TMX_ENVIRONMENT_VARIABLES_GET,
  SERVER_DATETIME_GET,
  WEB_MESSAGES_GET,
  SET_LOADING_PUBLIC_VARS,
  BANNER_LOCATIONS,
} from './constants';

import { default as EN } from 'utils/translations/en';
import { ACCESS_CARD_PAYMENT, ACH_PAYMENT } from 'components/Features/protected/Accounts/Transactions/Withdrawals/constants';

const initialState = {
  documents: {
    form102: { Location: '' },
    form104: { Location: '' },
    form500: { Location: '' },
  },
  environmentVars: {
    AccountAccessNoFrontEndActivityTimeout: 0,
    AddressTypes: [],
    FaxNumber: '',
    LogoutWarningTimeout: 0,
    MaximumAge: 0,
    MinimumAccountOwnerAge: 0,
    RelationshipTypes: {},
    SuccessorTypes: [],
    SupportPhoneNumber: '',
    SupportEmail: '',
    ServerDateTime: '',
    TwoFADeviceCookieExpirationInDays: 0,
    MaximumBenficiaryAccountValueForGift: 0,
  },
  passwordRequirements: [],
  serverDateTime: '',
  threatMetrixVars: {
    SessionId: '',
    ThreatMetrixUrl: '',
  },
  usStates: [],
  withdrawalVariables: {
    paymentTypes: [],
    recipientTypes: [],
    sellDates: [],
    startDates: [],
    withdrawalTypes: [],
    minWithdrawal: 0,
    maxWithdrawal: 0,
    tradeCutoff: {},
    tradeCutoffWarningTime: 0,
    numOfRollingDays: 0,
  },
  // needs to be initialized to empty strings
  webMessages: Object.values(BANNER_LOCATIONS).reduce((obj, bannerLocation) => ({ ...obj, [bannerLocation]: '' }), {}),
  loadingPublicVars: false,
};

function StaticResourcesReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {
    case ENVIRONMENT_VARIABLES_GET: {
      const data = action.payload.data;

      newState.environmentVars = data;
      newState.usStates = newState.environmentVars.USStates.map(state => ({
        Code: state.Code,
        Name: state.Name
      }));
      // add clear state item at index 0
      newState.usStates.unshift({ Code: '', Name: EN.BeneficiaryStep.lbl_clear_state });

      const messages = EN.Withdrawals.messages;
      const numberOfRollingMonths = data.DisbursementSignatureGuaranteeRollingDays && data.DisbursementSignatureGuaranteeRollingDays / 30;
      newState.withdrawalVariables = {
        ...newState.withdrawalVariables,
        ...(data.DisbursementSignatureGuaranteeRollingDays && { numOfRollingDays: data.DisbursementSignatureGuaranteeRollingDays }),
        ...(data.MinimumOnlineWithdrawalAmount && { minWithdrawal: data.MinimumOnlineWithdrawalAmount }),
        ...(data.DisbursementSignatureGuaranteeAmount && { maxWithdrawal: data.DisbursementSignatureGuaranteeAmount }),
        ...(data.DisbursementSignatureGuaranteeAmount && { maxWithdrawalRecurring: (data.DisbursementSignatureGuaranteeAmount / numberOfRollingMonths) }), // maximum divided by 3 for 90 days period
        ...(data.PaymentMethods && {
          paymentTypes:
            data.PaymentMethods.map(type => {
              // need to do a different mapping for Osprey withdrawals names
              let name;
              switch (type.Code) {
                case ACH_PAYMENT:
                  name = 'Electronic Funds Transfer';
                  break;
                case ACCESS_CARD_PAYMENT:
                  name = 'my529 Access Card ';
                  break;
                default:
                  name = type.Name;
              }
              return {
                id: type.Code,
                name,
              };
            })
        }),
        ...(data.RecipientTypes && {
          recipientTypes: data.RecipientTypes
            .filter(type => type.Code === 'P' || type.Code === 'B' || type.Code === 'I')
            .map(type => ({
              id: type.Code,
              name: type.Name,
              messages: messages.find(message => message.id === type.Code).messages
            }))
        }),
        ...(data.ValidOneTimeWithdrawalDates && { sellDates: data.ValidOneTimeWithdrawalDates.map(date => dayjs(date).format('YYYY-MM-DD')) }),
        ...(data.ValidRecurringWithdrawalDates && { startDates: data.ValidRecurringWithdrawalDates.map(date => dayjs(date).format('YYYY-MM-DD')) }),
        ...(data.TradeCutOffTime && { tradeCutoff: dayjs(data.TradeCutOffTime).format('YYYY-MM-DD HH:mm') }), // eslint-disable-line
        ...(data.TradeCutoffApproachingWarningMinutes && { tradeCutoffWarningTime: data.TradeCutoffApproachingWarningMinutes }),
        ...(data.WithdrawalTypes && {
          withdrawalTypes: data.WithdrawalTypes
            .filter(type => type.Code === 'HigherEd' || type.Code === 'K-12Tuition' || type.Code === 'NonQualified')
            .map(type => ({
              id: type.Code,
              name: type.Name,
              messages: messages.find(message => message.id === type.Code).messages
            }))
        }),
      };

      newState.serverDateTime = data.ServerDateTime;

      return newState;
    }

    case PASSWORD_REQUIREMENTS_GET: {
      newState.passwordRequirements = action.payload.data;
      return newState;
    }

    case STATIC_DOCUMENTS_GET: {
      const docs = action.payload.data;
      newState.documents = {
        onlineLPOAGuide: docs.find(doc => doc.FormType === 'OnlineLpoaAuthorizationGuide').Location,
        programDescription: docs.find(doc => doc.FormType === 'ProgramDescription').Location,
        form400: docs.find(doc => doc.FormType === 'InternalTransfer-400').Location,
        form300: docs.find(doc => doc.FormType === 'WithdrawalRequest-300').Location,
        form102: docs.find(doc => doc.FormType === 'InstitutionalAccountAgreement-102'),
        form104: docs.find(doc => doc.FormType === 'UgmaUtmaAccountAgreement-104'),
        form500: docs.find(doc => doc.FormType === 'AccountInformationChange-500'),
      };
      return newState;
    }

    case TMX_ENVIRONMENT_VARIABLES_GET: {
      newState.threatMetrixVars = action.payload.data;
      return newState;
    }

    case SERVER_DATETIME_GET: {
      newState.serverDateTime = action.payload.data;
      return newState;
    }

    case WEB_MESSAGES_GET: {
      const webMessageTypes = action.payload.data;
      // get only website message type messages
      const webMessageType = Boolean(webMessageTypes) && webMessageTypes.length > 0 && webMessageTypes.find(messageType => messageType.Name === 'WEBSITE MESSAGES');
      const webMessages = Boolean(webMessageType.WebsiteMessages) && webMessageType.WebsiteMessages.length > 0 && webMessageType.WebsiteMessages;
      // initialize all banners to empty strings each time api is called
      newState.webMessages = Object.values(BANNER_LOCATIONS).reduce((obj, bannerLocation) => ({ ...obj, [bannerLocation]: '' }), {});

      // distribute messages between banner locations (if exists will overwrite empty string)
      webMessages && webMessages.forEach(webMessage => {
        webMessage.WebsiteMessageURLs.forEach(websiteMessageURL => {
          newState.webMessages[`${websiteMessageURL.URL}/${websiteMessageURL.Page}`] = webMessage.Body;
        });
      });

      return newState;
    }

    case SET_LOADING_PUBLIC_VARS: {
      newState.loadingPublicVars = action.payload.isLoading;
      return newState;
    }

    default: {
      return state;
    }
  }

}

export default StaticResourcesReducer;