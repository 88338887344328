/*
*
* AccountsSummary Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniq, includes } from 'lodash';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import Search from 'utils/components/Search';
import { accountsByFilter } from 'utils/helpers/data_filters';
import {
  Button,
  Icon,
  MenuItem,
  Paper,
  FormControl,
  Select,
  Input,
  ListSubheader,
} from '@mui/material';
import {
  currencyFormatter,
  IconBtnTooltip,
} from '@frontend/common';

import { mobileWidth } from 'utils/config/_sassconfig.scss';

import TransactionButtons from '../TransactionButtons';

import styles from './styles.module.scss';

const select = state => ({
  accountList: state.accounts.accountList.map(account => ({
    accountId: account.accountId,
    accountGroupId: account.accountGroupId,
    beneficiaryName: account.beneficiary.name,
    totalValue: account.totalValue,
    accountNumber: account.accountNumber,
    agreementDate: account.agreementDate,
    accountType: account.accountType,
    optionName: account.option.name,
    programName: account.programName,
    csaWizardUrl: account.csaWizardUrl,
  })),
  grandTotalAllAccounts: state.accounts.grandTotalAllAccounts,
  groupedAccountsSummary: state.accounts.groupedAccountsSummary,
  lastMarketDate: state.static.environmentVars.LastMarketDate,
});

export class AccountsSummary extends React.Component {
  static propTypes = {
    accountList: PropTypes.arrayOf(
      PropTypes.shape({
        accountId: PropTypes.number,
        accountGroupId: PropTypes.number,
        beneficiaryName: PropTypes.string,
        totalValue: PropTypes.string,
        accountNumber: PropTypes.number,
        agreementDate: PropTypes.string,
        accountType: PropTypes.string,
        optionName: PropTypes.string,
        programName: PropTypes.string,
        csaWizardUrl: PropTypes.string,
      })
    ).isRequired,
    grandTotalAllAccounts: PropTypes.string.isRequired,
    groupedAccountsSummary: PropTypes.shape({
      accountId: PropTypes.number,
      accountNumber: PropTypes.number,
      agreementDate: PropTypes.string,
      accountType: PropTypes.string,
      beneficiaryId: PropTypes.string,
      beneficiaryName: PropTypes.string,
      optionName: PropTypes.string,
      totalValue: PropTypes.number,
      totalValueFormatted: PropTypes.string,
    }),
    isTileView: PropTypes.bool.isRequired,
    lastMarketDate: PropTypes.string.isRequired,
    text: PropTypes.shape({
      Accounts: PropTypes.shape({
        text_account_closed: PropTypes.string,
      }),
      AccountDetails: PropTypes.shape({
        nav_link: PropTypes.func,
        nav_path: PropTypes.func,
      }),
      AccountsSummary: PropTypes.shape({
        btn_add_account: PropTypes.string,
        btn_csa_account: PropTypes.string,
        btn_my529_account: PropTypes.string,
        head_account_type: PropTypes.string,
        head_beneficiary: PropTypes.string,
        head_date_opened: PropTypes.string,
        head_interested_party: PropTypes.string,
        head_market_date: PropTypes.string,
        head_total_accts: PropTypes.string,
        head_total_value: PropTypes.string,
        head_ytd_return: PropTypes.string,
      }),
      Signup: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
    }).isRequired,
    size: PropTypes.object.isRequired,
  };

  state = {
    filteredGroupedAccountsList: [],
    dashboardAccountList: [],
    filteredAccountList: [],
    filterList: [],
    selectedFilters: [],
    searchedAccountList: [],
    searchIsActive: false,
    showFilterList: false,
  };

  getFilteredGroupedSummaryList = (filteredAccountList) => uniq(filteredAccountList.map(account => `${account.beneficiaryName}_${account.accountType}_${account.accountGroupId}`));

  updateDataHandler = (searchedAccountList, searchIsActive) => {
    const filteredAccountList = accountsByFilter(searchedAccountList, this.state.selectedFilters);
    const filteredGroupedAccountsList = this.getFilteredGroupedSummaryList(filteredAccountList);
    this.setState({
      searchedAccountList,
      filteredAccountList,
      searchIsActive,
      filteredGroupedAccountsList,
    });
  };

  setupFilterList(accountList) {
    // get unique bene names and investment options
    const beneficiaryNames = uniq(this.props.accountList.map(account => account.beneficiaryName));
    const optionNames = uniq(this.props.accountList.map(account => account.optionName));
    const filterList = [
      { listHeader: 'Accounts', key: 'accountsHeader' },
      ...accountList.map(account => ({ // list of Account Numbers
        label: account.accountNumber,
        key: account.accountNumber,
        isActive: selectedFilters => includes(selectedFilters, account.accountNumber),
      })),
      { listHeader: 'Beneficiaries', key: 'benesHeader' },
      ...beneficiaryNames.map(name => ({ // list of Beneficiaries
        label: name,
        key: name,
        isActive: selectedFilters => includes(selectedFilters, name),
      })),
      { listHeader: 'Options', key: 'optionsHeader' },
      ...optionNames.map(name => ({ // list of options
        label: name,
        key: name,
        isActive: selectedFilters => includes(selectedFilters, name),
      })),
    ];
    return filterList;
  }

  handleShowFilterList = () => {
    this.setState({ showFilterList: !this.state.showFilterList });
  }

  handleFilterSelect = e => {
    const { dashboardAccountList, searchedAccountList, searchIsActive } = this.state;
    const selectedFilters = e.target.value;
    const accountListToUse = searchIsActive ? searchedAccountList : dashboardAccountList; // if searching is active, use searched results instead of full dashboardAccountList
    const filteredAccountList = accountsByFilter(accountListToUse, selectedFilters);
    const filteredGroupedAccountsList = this.getFilteredGroupedSummaryList(filteredAccountList);

    this.setState({
      selectedFilters,
      filteredAccountList,
      filteredGroupedAccountsList,
    });
  }

  addAccountButtonRender = () => {
    const { text: { AccountsSummary, Signup } } = this.props;
    return (
      <Button
        color='primary'
        onClick={() => this.props.history.push(Signup.nav_path)}
        variant='contained'
      >
        {AccountsSummary.btn_add_account}
      </Button>
    );
  }

  calculateTotalSum = (group) => {
    const { groupedAccountsSummary } = this.props;
    const sum = groupedAccountsSummary[group].reduce((sum, account) => sum + account.totalValue, 0);
    return currencyFormatter(sum);
  }

  menuClose = (accountId) => {
    this.setState({ moreMenuAnchorPoints: { ...this.state.moreMenuAnchorPoints, [accountId]: null } });
  }

  menuOpen = (event, accountId) => {
    this.setState({ moreMenuAnchorPoints: { ...this.state.moreMenuAnchorPoints, [accountId]: event.currentTarget } });
  }

  initialize = () => {
    // Creates menu anchor point for every account, since each menu includes a different path to transactions
    const moreMenuAnchorPoints = {};
    const groupedAccountsList = Object.keys(this.props.groupedAccountsSummary);
    groupedAccountsList.forEach(group => {
      this.props.groupedAccountsSummary[group].forEach(account => {
        moreMenuAnchorPoints[account.accountId] = null;
      });
    });
    this.setState({
      moreMenuAnchorPoints,
      filteredGroupedAccountsList: groupedAccountsList,
      dashboardAccountList: this.props.accountList,
      filteredAccountList: this.props.accountList,
      filterList: this.setupFilterList(this.props.accountList),
    });
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { groupedAccountsSummary } = this.props;
    const groupedAccountsSummaryLoaded = groupedAccountsSummary && prevProps.groupedAccountsSummary !== groupedAccountsSummary && Object.keys(groupedAccountsSummary).length > 0;
    if (groupedAccountsSummaryLoaded) {
      this.initialize();
    }
  }

  render() {
    const { grandTotalAllAccounts, groupedAccountsSummary, lastMarketDate, isTileView, size, text } = this.props;
    const { filterList, showFilterList, selectedFilters, filteredGroupedAccountsList } = this.state;

    const readyToRenderGroups = (filteredGroupedAccountsList && Object.keys(filteredGroupedAccountsList).length > 0) && (groupedAccountsSummary && Object.keys(groupedAccountsSummary).length > 0);

    return (
      <div className={styles.buttonAndAcctSummContainer}>
        <div className={styles.filterAndSearch}>
          <div className={styles.filter}>
            <IconBtnTooltip
              buttonProps={{ color: 'primary', style: { marginRight: '5px' } }}
              onClick={() => {
                this.handleShowFilterList();
              }}
              icon='filter_list'
              title={'Filter Accounts'}
            />
            <FormControl
              style={{ visibility: 'hidden', height: 0, width: 0 }}
            >
              <Select
                open={showFilterList}
                onClose={() => {
                  this.handleShowFilterList();
                }}
                value={selectedFilters}
                onChange={(event) => {
                  this.handleFilterSelect(event);
                }}
                multiple
                input={<Input />}
              >
                {filterList.map(filter => filter.listHeader
                  ? <ListSubheader key={filter.key}>{filter.listHeader}</ListSubheader>
                  :
                  <MenuItem
                    key={filter.key}
                    value={filter.label}
                    style={filter.isActive(selectedFilters) ? { color: 'var(--primary)', fontWeight: 'bold', } : {}}
                  >
                    {filter.label}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>

          <div className={styles.search}>
            <Search
              idKey={'accountId'}
              data={this.props.accountList}
              dataColumns={[
                { key: 'accountNumber', title: 'Account Number', type: 'number' },
                { key: 'beneficiaryName', title: 'Beneficiary Name', type: 'string' },
                { key: 'optionName', title: 'Option Name', type: 'string' },
              ]}
              updateData={this.updateDataHandler}
              hasSearchMenu={true}
            />
          </div>
        </div>
        <div className={isTileView ? styles.totalAmtAndAddAcctBtnTILE : styles.totalAmtAndAddAcctBtn}>
          <div>
            <p className={styles.label}>{text.AccountsSummary.head_total_accts} {lastMarketDate}</p>
            <p className={styles.data}>{grandTotalAllAccounts}</p>
          </div>
          {this.addAccountButtonRender()}
        </div>

        <div className={isTileView ? styles.accountsSummaryContainerTILE : styles.accountsSummaryContainer}>
          {readyToRenderGroups && filteredGroupedAccountsList.map(group => {
            const accountGroup = groupedAccountsSummary[group];
            return (
              <Paper key={accountGroup[0].accountId}>
                <div className={styles.beneficiaryAccountTypeContainer}>
                  {accountGroup[0].interestedPartyId ? // displays different header for interested party account
                    <div className={styles.accountTypeInterestedParty}>{text.AccountsSummary.head_account_type} {accountGroup[0].accountType} - {text.AccountsSummary.head_interested_party}</div>
                    :
                    <div className={styles.accountType}>{text.AccountsSummary.head_account_type} {accountGroup[0].accountType}</div>
                  }
                  <div className={styles.accountGroupInfoContainer}>
                    <div className={styles.beneNameAndTotalContainer}>
                      <div>
                        <p className={styles.label}>{text.AccountsSummary.head_beneficiary}</p>
                        <p className={styles.data}>{accountGroup[0].beneficiaryName}</p>
                        {accountGroup[0].beneficiaryAge && accountGroup[0].beneficiaryAge > 0 &&
                          <p className={styles.age}>Age: {accountGroup[0].beneficiaryAge}</p>
                        }
                        {accountGroup[0].ownerEntity && accountGroup[0].accountTypeEnum === 'D' && (
                          <div className={styles.scholarshipEntity}>
                            <Icon>school</Icon>
                            <p>{accountGroup[0].ownerEntity.Name}</p>
                          </div>
                        )}
                      </div>

                      <div className={styles.totalContainer}>
                        <p className={styles.label}>{text.AccountsSummary.head_total_value}</p>
                        <p className={styles.data}>{this.calculateTotalSum(group)}</p>
                        <div className={styles.marketDateContainer}>
                          <p>{text.AccountsSummary.head_market_date}</p>
                          <p>{lastMarketDate}</p>
                        </div>
                      </div>
                    </div>

                    {accountGroup.map(account => [
                      <hr className={styles.accountBorder} key='hr' />,
                      account.accountIsClosed && <div key={`${account.accountId}${account.accountIsClosed}`} className={styles.closedAccountBanner}>{text.Accounts.text_account_closed} {account.accountCloseDate}</div>,
                      <div key={`${account.accountNumber}`} className={styles.accountRow}>
                        <div className={`${isTileView ? styles.accountInfoContainerTILE : styles.accountInfoContainer} ${account.accountIsClosed ? styles.accountIsClosed : ''}`}>

                          <div className={styles.accountNumberAndOptionContainer}>
                            <div className={styles.optionName}>{account.optionName}</div>
                            <Link to={text.AccountDetails.nav_path(account.accountId)}>{account.accountNumber}</Link>
                            <div className={styles.dateOpened}>{text.AccountsSummary.head_date_opened}: {account.agreementDate}</div>
                          </div>

                          <div className={styles.transactionLinks} style={isTileView ? { justifySelf: 'center' } : { justifySelf: 'flex-end' }}>
                            <TransactionButtons
                              account={account}
                              key={isTileView}
                              size={isTileView || size.windowWidth < parseInt(mobileWidth) ? { windowWidth: 0 } : size}
                            />
                          </div>

                          <div className={styles.accountValueContainer}>
                            <div className={styles.totalValue}>{account.totalValueFormatted}</div>
                            <div className={styles.personalRateReturnContainer}>
                              <p>{text.AccountsSummary.head_ytd_return}</p>
                              <p className={!account.ytdPRR.includes('%') ? {} : account.ytdPRR.includes('-') ? styles.negativeReturn : styles.positiveReturn}>{account.ytdPRR}</p> {/* eslint-disable-line */} {/**styles negative nums in red, positive in green, and nothing for Not Available */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ])}
                  </div>
                </div>
              </Paper>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(select, {})((LanguageHOC(AccountsSummary))));