/*
*
* PageNotFound Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Icon,
  Button,
} from '@mui/material';
import styles from './styles.module.scss';

export function PageNotFound({ history, text: { Home, PageNotFound } }) {
  return (
    <div className={styles.container}>
      <div className={styles.shrug}>
        {'¯\\_(ツ)_/¯'}
      </div>
      <p className={styles.message}>
        {PageNotFound.text_oops}
      </p>
      <Button
        variant='contained'
        onClick={() => history.push(Home.nav_path)}
      >
        <Icon>keyboard_arrow_left</Icon>
        {PageNotFound.btn_back_to_accounts}
      </Button>
    </div>
  );
}

PageNotFound.propTypes = {
  history: PropTypes.object.isRequired,
  text: PropTypes.object.isRequired
};

export default withRouter(LanguageHOC(PageNotFound));