/*
*
* ViewInvestmentPath Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ViewOptionChange from 'components/Features/protected/Accounts/Transactions/OptionChanges/ViewOptionChange';

import { accountInvestmentPathGet } from 'components/Features/protected/Accounts/actions';

const select = (state) => ({
  investmentPath: state.accounts.investmentPath,
});

export class ViewInvestmentPath extends React.Component {

  static propTypes = {
    accountInvestmentPathGet: PropTypes.func.isRequired,
    investmentPath: PropTypes.object,
  };

  state = {
    loading: true,
  }

  componentDidMount() {
    const {
      match,
      accountInvestmentPathGet,
    } = this.props;

    accountInvestmentPathGet(match.params.accountId)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    const { match, investmentPath } = this.props;
    const { loading } = this.state;
    return (
      <ViewOptionChange
        loading={loading}
        accountId={loading ? null : parseInt(match.params.accountId)}
        pendingOptionChange={{ Schema: investmentPath }}
      />
    );
  }
}

export default withRouter(connect(select, {
  accountInvestmentPathGet,
})(ViewInvestmentPath));