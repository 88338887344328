/*
*
* Accounts Reducers
*
*/
import { cloneDeep, pick } from 'lodash';
import dayjs from 'dayjs';
import {
  CHANGE_BENE_ADDRESS,
  DELETE_BENE_ADDRESS,
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUMMARY,
  GET_SUCCESSORS,
  GET_TRANSACTION_DETAILS,
  GET_UPCOMING_TRANSACTIONS,
  GET_ALL_UPCOMING_TRANSACTIONS,
  GET_ALL_TRANSACTIONS,
  ACCOUNT_INVESTMENT_PATH_GET,
  GET_PRR_DATES,
  GET_BALANCE_HISTORY,
} from './constants';
import { BLANK_ADDRESS } from 'components/AppRoot/StaticResources/constants';
import {
  currencyFormatter,
} from '@frontend/common';

import { convert2DCalculatorSchemaTo3D } from '@frontend/common';

// formatter for Fund Units since it should include commas and decimal should at 5 places
const fundUnitsFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 5 });

const initialState = {
  accountList: [],
  balanceHistory: [],
  isAccountListLoaded: false,
  emptyAccount: {
    accountNumber: 0,
    accountType: '',
    beneficiary: {
      addresses: {
        Mailing: BLANK_ADDRESS,
        Physical: BLANK_ADDRESS,
        Other: BLANK_ADDRESS,
      },
      beneficiaryId: 0
    },
    netPrincipal: '',
    option: { id: 0, name: '' },
    optionChangesRemaining: '',
    priorContributions: '',
    ytdContributions: '',
    transactions: []
  },
  grandTotalAllAccounts: '0',
  groupedAccountsSummary: {},
  loadedTransactions: [],
  successors: [],
  transaction: {
    id: 0,
    accountNumber: 0,
    beneficiaryName: '',
    option: '',
    date: '',
    total: 0,
    basis: 0,
    type: '',
    source: '',
    payeeName: '',
    bankName: '',
    bankAccountNumber: '',
    bankAccountType: '',
    accessCardNumber: '',
    transactionDetails: [],
  },
  upcomingTransactions: [],
  upcomingTransactionsAll: [],
  transactionsAll: [],
  investmentPath: { TemplateName: '', FundCategories: [] },
  pRRDates: {
    minDate: null,
    maxDate: null,
  },
  uiPermissions: {
    canAddInterestedParties: false,
    canManageBankAccounts: false,
    giftingEnabled: false,
    hasCsaFamilyAccount: false, // allows user to create a new CSA family account if they already have this account type
    noBeneAccounts: false, // this can be that AO either has no accounts yet or thery were all closed and passed 13 months
  }
};

function AccountsReducer(state = initialState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {

    case GET_ACCOUNTS: {
      let uiPermissions = {
        canAddInterestedParties: false,
        canManageBankAccounts: false,
        giftingEnabled: false,
        hasCsaFamilyAccount: false,
        noBeneAccounts: false,
      };

      let grandTotalAllAccounts = 0;

      const alphabetizedAccountList = action.payload.data.sort((obj1, obj2) => { // alphabetizes account list by bene first name
        const id1 = `${obj1.Beneficiary.Name.toUpperCase()}`;
        const id2 = `${obj2.Beneficiary.Name.toUpperCase()}`;

        return id1 < id2 ? -1 : 1;
      });

      const accountList = alphabetizedAccountList.map(account => {

        uiPermissions = createUiPermissions(account, uiPermissions);

        // map through allocations and combine any parts that are below 2%
        const minPercentageToShow = 0.02; // min to show allocation in own slice is more than 2%
        const moneyAllocations = [];
        const combinedAllocations = [];
        let combinedPercentageNum = 0;
        account.MoneyAllocations.forEach((allocation, index) => {
          const allocationObject = {
            ...allocation,
            id: index,
            ActualPercentageNum: allocation.ActualPercentage,
            ActualPercentage: `${(allocation.ActualPercentage * 100).toFixed(2)}%`,
            Value: currencyFormatter(allocation.Value),
            ValueNum: allocation.Value,
            allocationDetails: [{
              id: index,
              ActualPercentage: `${(allocation.ActualPercentage * 100).toFixed(2)}%`,
              Category: allocation.Category,
              Value: currencyFormatter(allocation.Value),
            }],
          };

          if (allocation.ActualPercentage > minPercentageToShow) {
            // push this allocation object into moneyAllocations array
            moneyAllocations.push(allocationObject);
          }
          else {
            // if below 2%, put into combined array to use later
            combinedAllocations.push(allocationObject);
            combinedPercentageNum = combinedPercentageNum + allocation.ActualPercentage;
          }
        });

        // add in combined allocations that were each below 2%
        if (combinedPercentageNum > minPercentageToShow) { // make a single combined slice, if the combined totals over 2% together
          moneyAllocations.push({
            id: 'combinedAllocations',
            ActualPercentageNum: combinedPercentageNum,
            ActualPercentage: `${(combinedPercentageNum * 100).toFixed(2)}%`,
            allocationDetails: combinedAllocations,
          });
        }
        else if (combinedAllocations.length > 0) { // if combined total is still smaller than 2%, combine into the next smallest (last) allocation
          const lastAllocation = moneyAllocations[moneyAllocations.length - 1];
          lastAllocation.allocationDetails = [...lastAllocation.allocationDetails, ...combinedAllocations];
          lastAllocation.ActualPercentageNum = lastAllocation.ActualPercentageNum + combinedPercentageNum;
          lastAllocation.ActualPercentage = `${(lastAllocation.ActualPercentageNum * 100).toFixed(2)}%`;
        }

        const newAccount = {
          accountCloseDate: account.CloseDate && dayjs(account.CloseDate).format('MM/DD/YYYY'),
          accountId: account.AccountId,
          accountGroupId: account.AccountGroupId,
          accountIsClosed: account.Status === 'Closed',
          accountNumber: account.AccountNumber,
          accountType: account.Type,
          accountTypeEnum: account.TypeEnum,
          agreementDate: dayjs(account.AgreementDate).format('MM/DD/YYYY'),
          agent: {
            name: account.Agent.Name,
          },
          beneficiary: {
            addresses: {
              Physical: account.Beneficiary.PhysicalAddress ? pick(account.Beneficiary.PhysicalAddress, Object.keys(BLANK_ADDRESS)) : BLANK_ADDRESS,
              Mailing: account.Beneficiary.MailingAddress ? pick(account.Beneficiary.MailingAddress, Object.keys(BLANK_ADDRESS)) : BLANK_ADDRESS,
              Other: account.Beneficiary.OtherAddress ? pick(account.Beneficiary.OtherAddress, Object.keys(BLANK_ADDRESS)) : BLANK_ADDRESS,
            },
            name: account.Beneficiary.Name,
            fullName: `${account.Beneficiary.FirstName} ${account.Beneficiary.MiddleName} ${account.Beneficiary.LastName}`,
            agentId: account.Beneficiary.AgentId,
            beneficiaryId: account.Beneficiary.BeneficiaryId,
            birthday: account.Beneficiary.Birthdate,
            age: account.Beneficiary.BeneficiaryAge
          },
          csaWizardUrl: account.CSANewProgramAccountWizardUrl,
          earnings: currencyFormatter(account.Earnings),
          hasAvailablePromoCodes: account.HasAvailablePromoCodes,
          interestedPartyId: account.InterestedPartyId,
          // eslint-disable-next-line id-length
          fundAllocations: account.FundAllocations.sort((a, b) => b.Value - a.Value)
            .map(allocation => ({
              ...allocation,
              id: allocation.Fund.FundId,
              fundName: allocation.Fund.Name,
              ticker: allocation.Fund.StockSymbol,
              Price: currencyFormatter(allocation.Price, 4),
              priceNum: allocation.Price,
              Units: fundUnitsFormatter.format(allocation.Units),
              unitsNum: allocation.Units,
              Value: currencyFormatter(allocation.Value),
            })),
          moneyAllocations,
          netPrincipal: currencyFormatter(account.NetContributions),
          option: {
            id: account.Option.OptionId,
            name: account.Option.Name,
            isCustom: account.Option.IsCustom,
          },
          optionChangesRemaining: account.RemainingOptionChanges,
          ownerEntity: account.OwnerEntity,
          permissions: account.Permissions,
          primarySuccessor: account.PrimarySuccessor && {
            accountId: account.AccountId,
            birthDate: account.PrimarySuccessor.BirthDate ? dayjs(account.PrimarySuccessor.BirthDate) : null,
            firstName: account.PrimarySuccessor.FirstName,
            middleName: account.PrimarySuccessor.MiddleName,
            lastName: account.PrimarySuccessor.LastName,
            successorId: account.PrimarySuccessor.SuccessorId,
            successorType: 'primary',
          },
          priorContributions: currencyFormatter(account.PreviousYearContributions),
          programName: account.ProgramName,
          secondarySuccessor: account.SecondarySuccessor && {
            accountId: account.AccountId,
            birthDate: account.SecondarySuccessor.BirthDate ? dayjs(account.SecondarySuccessor.BirthDate) : null,
            firstName: account.SecondarySuccessor.FirstName,
            middleName: account.SecondarySuccessor.MiddleName,
            lastName: account.SecondarySuccessor.LastName,
            successorId: account.SecondarySuccessor.SuccessorId,
            successorType: 'secondary',
          },
          statementDeliveryMethod: account.StatementDeliveryMethod,
          status: account.Status,
          taxableEntityProgramAccountId: account.TaxableEntityProgramAccountId,
          totalValue: currencyFormatter(account.MarketValue),
          totalValueNum: account.MarketValue,
          netAvailable: currencyFormatter(account.NetAvailable),
          netAvailableNum: account.NetAvailable,
          transactions: account.Transactions.map(trans => ({
            id: trans.TransactionId,
            date: trans.PostDate,
            type: trans.Type,
            basis: trans.BasisAmount,
            amount: trans.Amount,
            taxYear: trans.TaxYear,
            milestone: trans.Milestone,
          })),
          unrealizedDeferredEarnings: account.UnrealizedDeferredEarnings,
          ytdContributions: currencyFormatter(account.YearToDateContributions),
          ytdPRR: account.YearToDatePRR,
        };

        grandTotalAllAccounts += account.MarketValue;

        return newAccount;
      });

      newState.accountList = accountList;
      newState.uiPermissions = uiPermissions;

      // add UI permission when no accounts
      newState.uiPermissions.noBeneAccounts = accountList.length === 0;

      newState.grandTotalAllAccounts = currencyFormatter(grandTotalAllAccounts);
      newState.isAccountListLoaded = true;

      return newState;
    }

    case GET_ACCOUNTS_SUMMARY: {
      // TODO: this might make sense to merge with the above get accounts but might break some stuff
      newState.groupedAccountsSummary = state.accountList.reduce((groupedAccounts, account) => { // Groups accounts by account group ID
        const id = `${account.beneficiary.name}_${account.accountType}_${account.accountGroupId}`;
        groupedAccounts[id] = groupedAccounts[id] || [];
        groupedAccounts[id].push({
          accountCloseDate: account.accountCloseDate,
          accountGroupId: account.accountGroupId,
          accountId: account.accountId,
          accountIsClosed: account.accountIsClosed,
          accountNumber: account.accountNumber,
          agreementDate: account.agreementDate,
          accountType: account.accountType,
          accountTypeEnum: account.accountTypeEnum,
          beneficiaryId: account.beneficiary.beneficiaryId,
          beneficiaryName: account.beneficiary.name,
          beneficiaryAge: account.beneficiary.age,
          interestedPartyId: account.interestedPartyId,
          optionName: account.option.name,
          ownerEntity: account.ownerEntity,
          permissions: account.permissions,
          totalValue: account.totalValueNum,
          totalValueFormatted: account.totalValue,
          ytdPRR: account.ytdPRR,
        });

        return groupedAccounts;
      }, {});

      const accountGroups = Object.keys(newState.groupedAccountsSummary);
      accountGroups.forEach(group => {
        newState.groupedAccountsSummary[group].sort((account1, account2) => {
          // Sort by Open accounts first, then show Closed accounts
          return account1.accountIsClosed < account2.accountIsClosed ? -1
            : account1.accountIsClosed > account2.accountIsClosed ? 1
              : 0;
        });
      });

      return newState;
    }

    case GET_SUCCESSORS: {
      const successorList = [];

      state.accountList.forEach(account => {
        if (account.primarySuccessor && !successorList.find(successor => successor.successorId === account.primarySuccessor.successorId)) {
          successorList.push(account.primarySuccessor);
        }
        if (account.secondarySuccessor && !successorList.find(successor => successor.successorId === account.secondarySuccessor.successorId)) {
          successorList.push(account.secondarySuccessor);
        }
      });

      newState.successors = successorList;

      return newState;
    }

    case GET_UPCOMING_TRANSACTIONS: {
      newState.upcomingTransactions = action.payload.data;

      return newState;
    }

    case GET_ALL_UPCOMING_TRANSACTIONS: {
      newState.upcomingTransactionsAll = action.payload.data;

      return newState;
    }

    case GET_ALL_TRANSACTIONS: {
      const transactionsAll = action.payload.data;

      newState.transactionsAll = transactionsAll.map(trans => ({
        id: trans.TransactionId,
        accountId: trans.AccountId,
        accountNumber: trans.AccountNumber,
        beneficiaryName: trans.BeneficiaryName,
        option: trans.Option,
        date: trans.PostDate ? trans.PostDate : '',
        type: trans.Type,
        basis: trans.BasisAmount,
        amount: trans.Amount,
        taxYear: trans.TaxYear ? trans.TaxYear.toString() : '',
        milestone: trans.Milestone,
      }));

      return newState;
    }

    case GET_TRANSACTION_DETAILS: {
      const Transaction = action.payload.data;
      const transaction = {
        accountId: Transaction.AccountId,
        accessCardNumber: Transaction.AccessCard ? Transaction.AccessCard.CardNumber : '',
        accountNumber: Transaction.AccountNumber,
        beneficiaryName: Transaction.BeneficiaryName,
        option: Transaction.Option,
        id: Transaction.TransactionId,
        date: Transaction.PostDate,
        type: Transaction.Type,
        source: Transaction.AccessCard ? Transaction.AccessCard.Title : Transaction.Source, // when access card object exists, use its title rather than the source name
        payeeName: Transaction.PayeeName,
        total: Transaction.Amount,
        basis: Transaction.BasisAmount,
        bankName: Transaction.BankAccount ? Transaction.BankAccount.BankName : '',
        bankAccountNumber: Transaction.BankAccount ? Transaction.BankAccount.MaskedBankAccountNumber : '',
        bankAccountType: Transaction.BankAccount ? Transaction.BankAccount.BankAccountType : '',
        ElectronicSchoolPaymentPaymentId: Transaction.ElectronicSchoolPaymentPaymentId,
        transactionDetails: Transaction.TransactionDetails
          .map(detail => ({
            id: detail.TransactionDetailId,
            fundName: detail.Fund.Name,
            units: fundUnitsFormatter.format(detail.Units),
            price: currencyFormatter(detail.Price, 4),
            priceNum: detail.Price,
            amount: detail.Amount,
          })),
      };
      newState.transaction = transaction;
      // remember already loaded transaction
      newState.loadedTransactions.push(transaction);

      return newState;
    }

    case CHANGE_BENE_ADDRESS: {
      const updatedAccounts = newState.accountList.filter(account => account.beneficiary.beneficiaryId === action.meta.beneficiaryId); // sometimes there are multiple accounts for single beneficiary, and addresses should be same for all beneficiary's accounts
      const updatedAddress = pick(action.payload.data, Object.keys(BLANK_ADDRESS));

      updatedAccounts.map(account => {
        const idx = newState.accountList.findIndex(acc => acc.accountId === account.accountId);
        return (
          newState.accountList[idx] = {
            ...newState.accountList[idx],
            beneficiary: {
              ...newState.accountList[idx].beneficiary,
              addresses: {
                ...newState.accountList[idx].beneficiary.addresses,
                [action.payload.data.Type]: updatedAddress
              }
            }
          }
        );
      });
      return newState;
    }

    case DELETE_BENE_ADDRESS: {
      const { addressType, beneficiaryId } = action.meta;
      const updatedAccount = newState.accountList.indexOf(newState.accountList.find(account => account.beneficiary.beneficiaryId === beneficiaryId));

      newState.accountList[updatedAccount] = {
        ...newState.accountList[updatedAccount],
        beneficiary: {
          ...newState.accountList[updatedAccount].beneficiary,
          addresses: {
            ...newState.accountList[updatedAccount].beneficiary.addresses,
            [addressType]: BLANK_ADDRESS
          }
        }
      };

      return newState;
    }

    case ACCOUNT_INVESTMENT_PATH_GET: {
      newState.investmentPath = convert2DCalculatorSchemaTo3D(action.payload.data);
      return newState;
    }

    case GET_PRR_DATES: {
      newState.pRRDates = {
        minDate: action.payload.data.StartDate,
        maxDate: action.payload.data.EndDate,
      };
      return newState;
    }

    case GET_BALANCE_HISTORY: {
      const balanceHistory = action.payload.data;
      // only persistently store balance history if account list is equal to or less than 10 accounts. this is needed to limit stored data
      newState.balanceHistory = balanceHistory.length > 0 && balanceHistory.length <= 10 ? balanceHistory : [];
      return newState;
    }

    default:
      return state;
  }
}

export function createUiPermissions(account, permissions) {
  const { canAddInterestedParties, canManageBankAccounts, giftingEnabled, hasCsaFamilyAccount } = permissions;

  if (!canManageBankAccounts) {
    permissions.canManageBankAccounts = account.Permissions.WithdrawACH;
  }

  if (!giftingEnabled && account.Permissions.GiftingEnabled) {
    permissions.giftingEnabled = true;
  }

  if (!canAddInterestedParties) {
    permissions.canAddInterestedParties = account.Status === 'Open';
  }

  if (!hasCsaFamilyAccount) {
    permissions.hasCsaFamilyAccount = account.TaxableEntityProgramAccountId !== 0 && account.TypeEnum === 'I';
  }

  return permissions;
}

export default AccountsReducer;