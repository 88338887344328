/*
*
* Transactions Reducers
*
*/
import {
  cloneDeep,
  get,
} from 'lodash';
import dayjs from 'dayjs';
import {
  GET_GROUP_ACCOUNTS,
  ACCOUNT_TRANSACTION_PREVIEW,
  CLOSE_TRANSACTION_LOADER,
  OPEN_TRANSACTION_LOADER,
  GET_PRE_TRANSACTION_DETAILS,
  SET_PREFLIGHT_PARAMS,
  GET_TRANSFER_ACCOUNTS,
  GET_SCHEDULED_TRANSACTION,
  GET_CONTRIBUTION_DETAILS,
  NEXT_RUN_DATE_GET,
  SET_WITHDRAWAL_SUBMITTED,
} from './constants';
import {
  MONTHLY,
  TWICE_MONTHLY,
} from './Contributions/constants';
import {
  currencyFormatter,
  properCase,
} from '@frontend/common';

import { convert2DCalculatorSchemaTo3D } from '@frontend/common';

const initialState = {
  groupAccountList: [],
  previewDetails: {
    canContinue: false,
    errors: []
  },
  preflightAccount: {
    accountId: 0,
    AccountGroupId: 0,
  },
  preflightLoaderOpen: false,
  preflightTransactionType: '',
  destinationAccounts: [],
  contributionDetails: {
    contribution: {},
  },
  nextRunDates: {
    monthly: {
      day1: '',
      day2: '',
    },
    yearly: {
      birthday: [],
      day1: '',
    },
  },
  selectedTransaction: {},
  preTransactionDetails: {
    AccountId: 0,
    AccountNumber: 0,
    AccountType: '',
    AgentId: 123,
    agentName: '',
    BeneficiaryId: 0,
    beneficiaryName: '',
    OptionName: '',
    OptionId: 0,
    groupAccounts: [
      {
        availableBalance: '$0',
        AccountId: 0,
        marketValue: '$0',
        availableForWithdrawalNum: 0,
        availableForWithdrawal: '$0',
        AllowFullWithdrawal: false,
      }
    ],
    OptionChangeCreate: false,
    RemainingOptionChanges: 0,
    totalMarketValue: '',
    totalMarketValueNum: 0,
    totalAvailableBalance: '',
    totalAvailableBalanceNumber: 0,
    totalAvailableForWithdrawalNum: 0,
    totalAvailableForWithdrawal: '$0',
    AllowFullWithdrawal: false,
    AllowPartialWithdrawal: false,
    agentAddress: {},
    agentAddressChanged: false,
    beneficiaryAddress: {},
    beneficiaryAddressChanged: false,
    schoolAddresses: [],
  },
  scheduleRunDates: [],
  withdrawalSubmitted: false,
};

function TransactionsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case GET_GROUP_ACCOUNTS: {
      newState.groupAccountList = action.payload.data;
      return newState;
    }

    case ACCOUNT_TRANSACTION_PREVIEW: {
      const response = action.payload.data;
      newState.previewDetails = {
        canContinue: response[action.meta.transactionType],
        errors: response.Comments,
      };
      return newState;
    }

    case OPEN_TRANSACTION_LOADER: {
      newState.preflightLoaderOpen = true;
      return newState;
    }

    case CLOSE_TRANSACTION_LOADER: {
      newState.preflightLoaderOpen = false;
      return newState;
    }

    case SET_PREFLIGHT_PARAMS: {
      // modify account fields per preflight needs
      newState.preflightAccount = {
        accountId: action.account.accountId,
        AccountGroupId: action.account.accountGroupId,
      };
      newState.preflightTransactionType = action.transactionType;
      return newState;
    }

    case GET_TRANSFER_ACCOUNTS: {
      newState.destinationAccounts = action.payload.data.map(account => ({
        accountId: account.AccountId,
        accountGroupId: account.AccountGroupId,
        accountNumber: account.AccountNumber,
        accountType: account.Type,
        option: account.Option,
        agent: {
          name: account.Agent.Name,
        },
        beneficiary: {
          name: account.Beneficiary.Name,
        },
        totalValue: currencyFormatter(account.MarketValue)
      }));
      return newState;
    }

    case GET_SCHEDULED_TRANSACTION: {
      let selectedTransaction = action.payload.data;
      switch (action.meta.type) {
        case 'Transfer In':
        case 'Transfer Out':
          selectedTransaction.Percentage = get(selectedTransaction, 'Percentage', 0);
          break;
        case 'Option Change': {
          selectedTransaction = {
            AccountId: selectedTransaction.Actions[0].AccountId,
            AccountGroupId: selectedTransaction.AccountGroupId,
            customAllocation: selectedTransaction.Actions[0].Schema ? convert2DCalculatorSchemaTo3D(selectedTransaction.Actions[0].Schema) : null,
            scheduledOptionName: selectedTransaction.Actions[0].NewOption,
            sheduledOptionId: selectedTransaction.Actions[0].NewOptionId,
          };
          break;
        }
        case 'Withdrawal': {
          selectedTransaction = {
            ...action.payload.data,
            AccountId: action.meta.AccountId,
          };
          break;
        }
        case 'Contribution':
        default:
          break;
      }
      newState.selectedTransaction = selectedTransaction;
      return newState;
    }

    case GET_PRE_TRANSACTION_DETAILS: {
      const groupAccount = action.payload.data;
      const currentAccountId = parseInt(action.meta.accountId);
      const currentAccount = groupAccount.AccountInfo.find(account => account.AccountId === currentAccountId);
      const totalAvailableForWithdrawalNum = parseFloat(groupAccount.AccountInfo.reduce((sum, account) => sum + account.AvailableForWithdrawal, 0).toFixed(2));

      newState.preTransactionDetails = {
        // selected account found from group account info
        AccountId: currentAccount.AccountId,
        AccountNumber: currentAccount.AccountNumber,
        OptionName: currentAccount.OptionName,
        OptionId: currentAccount.OptionId,

        // group account
        AgentId: groupAccount.AgentId,
        AccountType: groupAccount.AccountType,
        agentName: properCase(groupAccount.Agent.Name),
        beneficiaryName: properCase(groupAccount.Beneficiary.Name),
        BeneficiaryId: groupAccount.Beneficiary.BeneficiaryId,
        totalMarketValue: currencyFormatter(groupAccount.MarketValue),
        totalMarketValueNum: groupAccount.MarketValue,
        totalAvailableBalance: currencyFormatter(groupAccount.NetAvailable),
        totalAvailableBalanceNumber: groupAccount.NetAvailable,
        OptionChangeCreate: groupAccount.OptionChangeCreate,
        RemainingOptionChanges: groupAccount.RemainingOptionChanges,
        AllowFullWithdrawal: groupAccount.AllowFullWithdrawal,
        AllowPartialWithdrawal: groupAccount.AllowPartialWithdrawal,
        totalAvailableForWithdrawalNum,
        totalAvailableForWithdrawal: currencyFormatter(totalAvailableForWithdrawalNum),
        agentAddressChanged: groupAccount.AgentAddressChanged,
        agentAddress: {
          line1: properCase(groupAccount.Agent.Recipient.Line1),
          line2: properCase(groupAccount.Agent.Recipient.Line2, ['C/O', 'FBO', 'UGMA/UTMA']),
          name: properCase(`${groupAccount.Agent.Name}`),
          street1: properCase(groupAccount.Agent.MailingAddress.StreetAddress1, ['PO']),
          street2: properCase(groupAccount.Agent.MailingAddress.StreetAddress2, ['PO']),
          city: properCase(groupAccount.Agent.MailingAddress.City),
          state: { Code: groupAccount.Agent.MailingAddress.State },
          postalCode: groupAccount.Agent.MailingAddress.PostalCode,
        },
        beneficiaryAddressChanged: groupAccount.BeneficiaryAddressChanged,
        beneficiaryAddress: {
          name: properCase(`${groupAccount.Beneficiary.Name}`),
          street1: properCase(groupAccount.Beneficiary.MailingAddress.StreetAddress1, ['PO']),
          street2: properCase(groupAccount.Beneficiary.MailingAddress.StreetAddress2, ['PO']),
          city: properCase(groupAccount.Beneficiary.MailingAddress.City),
          state: { Code: groupAccount.Beneficiary.MailingAddress.State },
          postalCode: groupAccount.Beneficiary.MailingAddress.PostalCode,
        },
        groupAccounts: groupAccount.AccountInfo.map(account => ({
          AccountId: account.AccountId,
          AccountNumber: account.AccountNumber,
          OptionName: account.OptionName,
          marketValue: currencyFormatter(account.MarketValue),
          marketValueNumber: account.MarketValue,
          availableBalance: currencyFormatter(account.NetAvailable),
          availableBalanceNumber: account.NetAvailable,
          percentageOfTotalValue: account.AvailableForWithdrawal / totalAvailableForWithdrawalNum || 0,
          availableForWithdrawalNum: account.AvailableForWithdrawal,
          availableForWithdrawal: currencyFormatter(account.AvailableForWithdrawal),
          AllowFullWithdrawal: account.AllowFullWithdrawal,
          AllowPartialWithdrawal: account.AllowPartialWithdrawal,
        })),
        schoolAddresses: groupAccount.SchoolAddresses.map(address => ({
          attn: properCase(address.Department),
          name: properCase(address.SchoolName),
          street1: properCase(address.Address.StreetAddress1),
          city: properCase(address.Address.City),
          state: { Code: address.Address.State },
          postalCode: address.Address.PostalCode,
          schoolId: address.SchoolId.toString(),
          fbo: `${properCase(`${groupAccount.Beneficiary.Name}`)}`,
          studentIdNum: address.StudentId,
        })),
      };

      return newState;
    }

    case GET_CONTRIBUTION_DETAILS: {
      const canContributeAccountList = action.accountList.filter(account => account.permissions.Contribute);
      const maxBalance = action.MaximumBeneficiaryDeposit;
      const isEditing = action.isEditing;
      const editDetails = action.accountId === newState.selectedTransaction.AccountId ? newState.selectedTransaction : false;
      const selectedAccount = Object.keys(editDetails).length > 0 && (canContributeAccountList.find(account => account.accountId === editDetails.AccountId));

      newState.contributionDetails = {
        selectedAccountId: action.accountId,
        canContributeAccountList: canContributeAccountList.map(account => {
          // check if accountGroup is over the max balance amount. If so, it will be marked and disabled in contribution account selection dropdown
          const overMaxBalance = newState.groupAccountList.some(groupAccount => account.accountGroupId === groupAccount.AccountGroupId && groupAccount.MarketValue >= maxBalance);
          return {
            ...account,
            AccountId: account.accountId,
            contributionAmount: 0,
            endDate: '',
            overMaxBalance,
          };
        }),
        contribution: isEditing
          ? ({
            bankAccount: action.agentBankAccounts.find(bankAccount => bankAccount.BankAccountId === editDetails.BankAccountId),
            day1: editDetails.ScheduleDate1.ScheduledDate,
            day2: editDetails.ScheduleDate2 ? editDetails.ScheduleDate2.ScheduledDate : '',
            endDate: editDetails.EndDate,
            occasion: editDetails.Occasion,
            selectedAccounts: [{
              accountId: editDetails.AccountId,
              accountNumber: selectedAccount.accountNumber,
              beneficiary: {
                birthday: selectedAccount.beneficiary.birthday,
                name: selectedAccount.beneficiary.name,
              },
              contributionAmount: editDetails.Amount,
              endDate: editDetails.EndDate || '',
            }],
            transactionId: editDetails.ScheduleId,
            type: editDetails.InvestmentType === MONTHLY && editDetails.ScheduleDate2 !== null ? TWICE_MONTHLY : editDetails.InvestmentType,
          })
          : {},
      };

      return newState;
    }

    case NEXT_RUN_DATE_GET: {
      const response = action.payload.data;
      const frequency = action.meta.frequency.toLowerCase();
      const day = action.meta.day.toLowerCase();

      if (frequency === MONTHLY.toLowerCase()) {
        newState.nextRunDates.monthly[day] = dayjs(response);
      }
      else {
        if (Array.isArray(response)) {
          newState.nextRunDates.yearly[day] = response.map(date => dayjs(date));
        }
        else {
          newState.nextRunDates.yearly[day] = dayjs(response);
        }
      }
      return newState;
    }

    case SET_WITHDRAWAL_SUBMITTED: {
      newState.withdrawalSubmitted = action.payload;
      return newState;
    }

    default:
      return state;
  }
}

export default TransactionsReducer;