/*
 *
 * Step1Frequency Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import {
  DatePicker,
  currencyFormatter,
} from '@frontend/common';
import {
  MONTHLY,
  TWICE_MONTHLY,
  DISABLED_DATES
} from '../../constants';

import styles from '../styles.module.scss';

Step1Frequency.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  nextRunDates: PropTypes.object.isRequired,
  contributionDateHandle: PropTypes.func.isRequired,
  nextRunDateCompose: PropTypes.func.isRequired,
  contributionSet: PropTypes.func.isRequired,
  contribution: PropTypes.object.isRequired,
  contributionEnvironmentVars: PropTypes.object.isRequired,
  contributionDatesDisable: PropTypes.func.isRequired,
  errorGet: PropTypes.func.isRequired,
  text: PropTypes.shape({
    ContributionRecurringStep1: PropTypes.shape({
      err_amount_between: PropTypes.func,
      err_max_date: PropTypes.string,
      lbl_first_date: PropTypes.func,
      lbl_monthly: PropTypes.string,
      lbl_option_end_date: PropTypes.string,
      lbl_second_date: PropTypes.func,
      lbl_twice_monthly: PropTypes.string,
    })
  })
};

export function Step1Frequency({
  isEditing,
  contribution: { day1, day2, selectedAccounts, type },
  contributionEnvironmentVars: {
    CurrentTradeDate,
    MaximumRecurringContributionLeadDays,
    MinimumRecurringOnlineContribution,
    MaximumRecurringOnlineContribution,
  },
  contributionSet,
  errorGet,
  contributionDateHandle,
  nextRunDateCompose,
  contributionDatesDisable,
  text: { ContributionRecurringStep1 },
}) {
  return (
    <div className={styles.step1Container}>
      <RadioGroup
        className={styles.step1RadioButtons}
        onChange={(e) => contributionSet(1, 'type', e.target.value)} // eslint-disable-line
        value={type}
      >
        <FormControlLabel
          control={<Radio />}
          label={ContributionRecurringStep1.lbl_monthly}
          value={MONTHLY}
        />
        <FormControlLabel
          control={<Radio />}
          label={ContributionRecurringStep1.lbl_twice_monthly}
          value={TWICE_MONTHLY}
        />
      </RadioGroup>

      <div className={styles.errorsContainer}>
        {errorGet('amount') &&
          <FormHelperText
            error={true}
          >
            {ContributionRecurringStep1.err_amount_between(currencyFormatter(MinimumRecurringOnlineContribution), currencyFormatter(MaximumRecurringOnlineContribution), currencyFormatter(MaximumRecurringOnlineContribution / 2))}
          </FormHelperText>
        }
      </div>

      <div className={styles.step1Date1}>
        <DatePicker
          clearable={false}
          error={Boolean(errorGet('day1'))}
          helperText={errorGet('day1')}
          label={ContributionRecurringStep1.lbl_first_date(isEditing, type === MONTHLY)}
          key='datePickerDay1'
          minDate={dayjs(CurrentTradeDate)}
          maxDate={dayjs(CurrentTradeDate).add(MaximumRecurringContributionLeadDays, 'd')}
          onChange={date => contributionDateHandle(date, 'day1')}
          shouldDisableDate={contributionDatesDisable}
          value={day1}
        />
        {Boolean(!errorGet('day1')) && nextRunDateCompose('start1')}
      </div>

      {type === TWICE_MONTHLY &&
        <div className={styles.step1Date2}>
          <DatePicker
            clearable={false}
            error={Boolean(errorGet('day2'))}
            helperText={errorGet('day2')}
            label={ContributionRecurringStep1.lbl_second_date(isEditing)}
            key='datePickerDay2'
            minDate={dayjs(day1).add(1, 'd')}
            maxDate={dayjs(day1).add(1, 'M').subtract(1, 'd')} // eslint-disable-line
            onChange={date => contributionDateHandle(date, 'day2')}
            shouldDisableDate={(date) => DISABLED_DATES.includes(dayjs(date).date())}
            value={day2 ? day2 : null}
          />
          {Boolean(!errorGet('day2')) && dayjs(day2).isValid() && nextRunDateCompose('start2')}
        </div>
      }

      <div className={styles.endDate}>
        <DatePicker
          error={Boolean(errorGet('endDate'))}
          helperText={errorGet('endDate')}
          label='Optional End Date'
          key='datePickerEndDate'
          minDate={type === TWICE_MONTHLY ? dayjs(day2).add(1, 'd') : dayjs(day1).add(1, 'd')}
          slotProps={{ field: { clearable: true } }}
          onChange={date => contributionDateHandle(date, 'endDate')}
          value={selectedAccounts[0].endDate ? selectedAccounts[0].endDate : null}
          fullWidth
        />
      </div>
    </div>
  );
}

export default (LanguageHOC(Step1Frequency));