export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('aa_state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  }
  catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  /* eslint-disable */
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('aa_state', serializedState);
  }
  catch (err) {
    throw err;
    
  }
};

export const clearState = () => { // eslint-disable-line no-unused-vars
  sessionStorage.removeItem('aa_state');
};