/*
*
* InterestedPartiesActivation Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import CreateIpAccount from './CreateIpAccount';
import LoginIp from './LoginIp';
import {
  Button,
  Drawer,
} from '@mui/material';
import {
  allNotificationsHide,
  IconBtnTooltip,
  LoadingOverlay,
  My529Logo,
} from '@frontend/common';
import { getActivationInfo } from 'components/Features/protected/InterestedParties/actions.js';

import styles from './styles.module.scss';


const select = (state) => ({
  activationInfo: state.interestedParties.activationInfo,
});


export class InterestedPartiesActivation extends React.Component {

  static propTypes = {
    activationInfo: PropTypes.object.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    getActivationInfo: PropTypes.func.isRequired,
    text: PropTypes.shape({
      InterestedPartiesActivation: PropTypes.shape({
        btn_learn_more: PropTypes.string,
        text_learn_more: PropTypes.func,
      }),
      Login: PropTypes.shape({ nav_path: PropTypes.string }),
    }).isRequired,
  };

  state = {
    csaPin: new URLSearchParams(window.location.search.toLowerCase()).get('pin'),
    learnMoreOpen: false,
    loading: false,
    showCreateAccountForm: false,
    showLoginOnly: false,
  };

  getActivationInfo() {
    const guid = new URLSearchParams(window.location.search.toLowerCase()).get('request');

    if (guid) {
      this.setState({ loading: true });

      this.props.getActivationInfo(guid)
        .then(() => {
          this.setState({
            showLoginOnly: this.props.activationInfo.loginExistsAndIsRequired
          });
        })
        .catch(() => this.redirectToLogin())
        .finally(() => this.setState({ loading: false }));
    }
    else {
      this.redirectToLogin();
    }
  }

  redirectToLogin = () => {
    this.props.history.push(this.props.text.Login.nav_path);
  }

  toggleFormVisibility = () => {
    this.setState({ showCreateAccountForm: !this.state.showCreateAccountForm });
  }

  componentDidMount() {
    this.props.allNotificationsHide();
    this.getActivationInfo();
  }

  render() {
    const { csaPin, learnMoreOpen, loading, showCreateAccountForm, showLoginOnly, } = this.state;
    const { InterestedPartiesActivation } = this.props.text;

    return (
      <div className={styles.container}>
        {loading ?
          <div style={{ height: '100vh', width: '100vw' }}>
            <LoadingOverlay show={loading} indicatorHeight='15px' width='100%' />
          </div>
          :
          <div>
            <div>
              <div className={showLoginOnly ? styles.formsContainerLoginOnly : styles.formsContainer}>
                <div className={styles.logo}><My529Logo /></div>
                
                <LoginIp
                  csaPin={csaPin}
                  redirectToLogin={this.redirectToLogin}
                  showCreateAccountForm={showCreateAccountForm}
                  showLoginOnly={showLoginOnly}
                  toggleFormVisibility={this.toggleFormVisibility}
                />

                {!showLoginOnly && ( // if we detect user has an account, do not show Create form
                  <CreateIpAccount
                    csaPin={csaPin}
                    redirectToLogin={this.redirectToLogin}
                    showCreateAccountForm={showCreateAccountForm}
                    toggleFormVisibility={this.toggleFormVisibility}
                  />
                )}

              </div>
            </div>

            <div className={styles.learnMoreBtn}>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => this.setState({ learnMoreOpen: true })}
              >
                {InterestedPartiesActivation.btn_learn_more}
              </Button>
            </div>
          </div>
        }

        <Drawer anchor='bottom' onClose={() => this.setState({ learnMoreOpen: false })} open={learnMoreOpen}>
          <div className={styles.learnMoreContainer}>
            <IconBtnTooltip
              icon='close'
              onClick={() => this.setState({ learnMoreOpen: false })}
            />
            <h3>{InterestedPartiesActivation.btn_learn_more}</h3>
            <p>{InterestedPartiesActivation.text_learn_more()[0]}</p>
            {!showLoginOnly && <p>{InterestedPartiesActivation.text_learn_more()[1]}</p>} {/* do not show user line about creating account since they can only log in */}
            <p>{InterestedPartiesActivation.text_learn_more()[2]}</p>
          </div>
        </Drawer>
      </div>
    );
  }
}


export default connect(select, {
  allNotificationsHide,
  getActivationInfo,
})(LanguageHOC(InterestedPartiesActivation));
