/*
*
* SidenavMenuOption
*
*/
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Tooltip,
} from '@mui/material';

import { externalLinkClick, } from 'utils/ga/events';

import { desktopWidth } from 'utils/config/_sassconfig.scss';

export class SidenavMenuOption extends Component {

  static propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    onOptionClick: PropTypes.func,
    rootMenuOptionIconName: PropTypes.string,
    externalLink: PropTypes.string,
    sidenavOpen: PropTypes.bool,
    setNestedMenuOpen: PropTypes.func,
    onClick: PropTypes.func,
    visible: PropTypes.bool,
    toggleSidenav: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    visible: true,
    disabled: false,
  };

  state = {
    showMenuOptions: false,
  };

  menuOptionClickHandle(link, onMenuOptionClick, externalLink, name) {
    if (link) {
      this.props.history.push(link);
    }
    else if (externalLink) {
      externalLinkClick(name); // reports click event to google analytics
      window.open(externalLink, '_blank', 'noopener,noreferrer');
    }
    onMenuOptionClick && onMenuOptionClick();
    window.innerWidth < parseInt(desktopWidth) && this.props.toggleSidenav(false); // only close menu on tablet and smaller
  }

  setMenuOpen = () => {
    const showMenuOptions = !this.state.showMenuOptions;
    this.setState({ showMenuOptions });
  }

  render() {
    const { showMenuOptions } = this.state;
    const {
      sidenavOpen,
      onOptionClick,
      link,
      rootMenuOptionIconName,
      children,
      name,
      visible,
      history: {
        location
      },
      externalLink,
      onClick,
      disabled,
    } = this.props;

    const isNested = Boolean(children);
    const isSelected = link === location.pathname;

    let customOnClick = onClick; // allows passing custom onClick when declaring the component
    if (!customOnClick)
      customOnClick = isNested
        ? () => this.setMenuOpen()
        : () => this.menuOptionClickHandle(link, onOptionClick, externalLink, name);

    return (
      <List style={{ padding: '0' }}>
        <ListItem
          onClick={customOnClick}
          button
          style={visible ? {} : { display: 'none' }}
          selected={isSelected}
          disabled={disabled}
        >
          {rootMenuOptionIconName &&
            <ListItemIcon>
              <Tooltip
                title={name}
                placement='right'
                disableHoverListener={sidenavOpen}
                key={name}
              >
                <Icon>{rootMenuOptionIconName}</Icon>
              </Tooltip>
            </ListItemIcon>
          }
          {sidenavOpen && <ListItemText primary={name} />}
          {isNested && <Icon>{showMenuOptions ? 'expand_less' : 'expand_more'}</Icon>}
        </ListItem>
        {isNested &&
          <Collapse
            in={showMenuOptions}
            unmountOnExit
            onEntered={() => this.props.setNestedMenuOpen(this.state.showMenuOptions)} // before scrollbar height in measured in the parent the Collapse needs to finish animation
            onExited={() => this.props.setNestedMenuOpen(this.state.showMenuOptions)} // before scrollbar height in measured in the parent the Collapse needs to finish animation
          >
            <div style={{ marginLeft: '0.3em' }}>
              {children}
            </div>
          </Collapse>
        }
      </List>
    );
  }
}

export default withRouter(SidenavMenuOption);