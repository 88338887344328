import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import CheckAddress from './CheckAddress';

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Collapse,
  FormHelperText,
} from '@mui/material';

import { withStyles } from '@mui/styles';

import {
  Dropdown,
} from '@frontend/common';

import {
  ACH_PAYMENT,
  CHECK_PAYMENT,
  RECURRING,
  ONE_TIME,
  ACCOUNT_OWNER,
  HIGHER_ED,
  ELECTRONICSCHOOL_PAYMENT,
} from '../../constants';

import styles from './styles.module.scss';

const muiStyles = () => ({
  root: {
    whiteSpace: 'normal',
    wordBreak: 'normal',
    overflowWrap: 'anywhere'
  }
});

export class PaymentInfo extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    stepIndex: PropTypes.number.isRequired,
    withdrawalVariables: PropTypes.object.isRequired,
    paymentType: PropTypes.string.isRequired,
    paymentTypeError: PropTypes.string.isRequired,
    paymentAccount: PropTypes.object.isRequired,
    bankAccountError: PropTypes.string.isRequired,
    paymentAccounts: PropTypes.array.isRequired,
    recipientAddress: PropTypes.object.isRequired,
    recipient: PropTypes.string.isRequired,
    agentAddressChanged: PropTypes.bool.isRequired,
    beneficiaryAddressChanged: PropTypes.bool.isRequired,
    paymentTypeHandle: PropTypes.func.isRequired,
    paymentAccountSelectHandle: PropTypes.func.isRequired,
    frequency: PropTypes.string.isRequired,
    accessCards: PropTypes.array.isRequired,
    agentBankAccounts: PropTypes.array.isRequired,
    beneBankAccounts: PropTypes.array.isRequired,
    withdrawalType: PropTypes.string.isRequired,
    text: PropTypes.shape({
      Payments: PropTypes.shape({
        nav_path: PropTypes.string.isRequired,
      }).isRequired,
      Withdrawals: PropTypes.shape({
        electronic_request_text: PropTypes.string.isRequired,
        lbl_select_method: PropTypes.string.isRequired,
        no_payment_accounts_text: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  };

  componentDidMount() {
    const { withdrawalVariables, paymentTypeHandle, frequency } = this.props;

    // if only check available and one time default to a check
    if (
      withdrawalVariables.paymentTypes.length === 1 &&
      withdrawalVariables.paymentTypes[0].id === CHECK_PAYMENT
    ) {
      if (frequency === ONE_TIME) {
        paymentTypeHandle({ target: { value: CHECK_PAYMENT } }); // default to a check
      }
      else {
        paymentTypeHandle({ target: { value: '' } }); // we should stop user for recurring
      }
    }
  }

  render() {
    const {
      classes,
      isEditing,
      stepIndex,
      withdrawalVariables,
      paymentType,
      paymentTypeError,
      paymentAccount,
      bankAccountError,
      paymentAccounts,
      recipientAddress,
      recipient,
      agentAddressChanged,
      beneficiaryAddressChanged,
      paymentTypeHandle,
      paymentAccountSelectHandle,
      frequency,
      accessCards,
      agentBankAccounts,
      beneBankAccounts,
      withdrawalType,
      text: {
        Payments,
        Withdrawals: { electronic_request_text, no_payment_accounts_text, lbl_select_method, }
      }
    } = this.props;

    const isRecurring = frequency === RECURRING;
    let paymentTypes = withdrawalVariables.paymentTypes;
    paymentTypes = paymentTypes.filter(type => type.id !== ELECTRONICSCHOOL_PAYMENT);
    const isCheckOnly = paymentTypes.length === 1 && paymentTypes[0].id === CHECK_PAYMENT;
    let noBankAccounts;
    if (recipient === ACCOUNT_OWNER) {
      if (withdrawalType === HIGHER_ED)
        noBankAccounts = agentBankAccounts.length === 0 && accessCards.length === 0;
      else
        noBankAccounts = agentBankAccounts.length === 0;
    }
    else {
      noBankAccounts = beneBankAccounts.length === 0;
    }

    const onClickUrl = () => this.props.history.push(Payments.nav_path);

    const paymentAccountsFormatted = paymentAccounts.map(acct => {
      let display = acct.BankAccountId ? `${acct.BankName} ${acct.BankAccountType}, ${acct.MaskedBankAccountNumber}` : `${acct.Title}, ${acct.CardNumber}`;
      if (acct.Nickname && acct.Nickname.length > 0) {
        display = `${acct.Nickname}, ${acct.MaskedBankAccountNumber}`;
      }

      return {
        value: acct.BankAccountId || acct.AccessCardId,
        display,
      };
    });

    return (
      <div className={styles.paymentType}>
        {!isCheckOnly &&
          <FormControl error={Boolean(paymentTypeError)}>
            <RadioGroup
              onChange={paymentTypeHandle}
              value={paymentType}
            >
              {paymentTypes.map(type => {
                const isDisabled = isRecurring && type.name === CHECK_PAYMENT;
                return (
                  <FormControlLabel
                    key={type.id}
                    label={type.name}
                    value={type.id}
                    control={<Radio disableRipple />}
                    disabled={isDisabled || isEditing}
                  />
                );
              })}
            </RadioGroup>
            {paymentTypeError && !noBankAccounts && <FormHelperText>{paymentTypeError}</FormHelperText>}
          </FormControl>
        }

        <div className={styles.paymentType}>
          {paymentType === CHECK_PAYMENT && (
            <CheckAddress
              stepIndex={stepIndex}
              recipientAddress={recipientAddress}
              recipient={recipient}
              agentAddressChanged={agentAddressChanged}
              beneficiaryAddressChanged={beneficiaryAddressChanged}
            />
          )}
          {noBankAccounts && isRecurring && <div className={styles.error}>{no_payment_accounts_text(onClickUrl)}</div>}
          <Collapse in={paymentType === ACH_PAYMENT}>
            {!noBankAccounts &&
              (
                <div style={{ textAlign: 'center' }}>
                  <div className={styles.electronicRequestMessage}>
                    <strong>{electronic_request_text}</strong>
                  </div>
                  <Dropdown
                    label={lbl_select_method}
                    value={paymentAccount.BankAccountId || paymentAccount.AccessCardId || ''}
                    errorText={bankAccountError}
                    onChange={paymentAccountSelectHandle}
                    options={paymentAccountsFormatted}
                    FormControlProps={{ style: { width: '256px', textAlign: 'left' } }}
                    SelectProps={{
                      disabled: isEditing
                    }}
                    MenuItemProps={{
                      classes: { root: classes.root }
                    }}
                  />
                </div>
              )
            }
          </Collapse>
        </div>
      </div>
    );
  }

}

export default withRouter(LanguageHOC(withStyles(muiStyles)(PaymentInfo)));