import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  LoadingOverlay,
} from '@frontend/common';

import {
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Radio,
  RadioGroup,
} from '@mui/material';


import styles from './styles.module.scss';

export class RSAQuestions extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_learn_more: PropTypes.string,
        btn_submit_answer: PropTypes.string,
        err_required: PropTypes.string,
        msg_rsa_select: PropTypes.string,
        verify_identity_title: PropTypes.string,
        learn_more_url: PropTypes.string,
      }),
    }).isRequired,
    RSA: PropTypes.object.isRequired,
    handlePostRSA: PropTypes.func.isRequired,
  };

  state = {
    answers: [],
    errors: this.props.RSA.Questions.map(() =>false),
  }

  parseSelection(selection) {
    const [questionId, choiceId] = selection.split('|');
    return { questionId: parseInt(questionId), choiceId: parseInt(choiceId) };
  }

  findAnswerIndex(questionId, answers) {
    return answers.findIndex(answer => answer.questionId === questionId);
  }

  resetQuestionError = (questionId) => {
    const questionIndex = this.props.RSA.Questions.findIndex(question => question.QuestionId === questionId);
    const updatedErrors = cloneDeep(this.state.errors);
    updatedErrors[questionIndex] = false;
    this.setState({ errors: updatedErrors });
  }

  handleRadioChoices = e => {
    const value = e.target.value;
    const { questionId, choiceId } = this.parseSelection(value);
    // reset error
    this.resetQuestionError(questionId);
    // single choice 
    const updatedAnswers = cloneDeep(this.state.answers);
    const answerIndex = this.findAnswerIndex(questionId, updatedAnswers);
    if (answerIndex === -1) {
      updatedAnswers.push({ questionId, choices: [{ choiceId }] });
    } 
    else {
      updatedAnswers[answerIndex] = { questionId, choices: [{ choiceId }] };
    }

    this.setState({ answers: updatedAnswers });
  }

  handleCheckboxChoices = e => {
    const name = e.target.name;
    const { questionId, choiceId } = this.parseSelection(name);
    // reset error
    this.resetQuestionError(questionId);
    // multiple choices
    const updatedAnswers = cloneDeep(this.state.answers);
    const answerIndex = this.findAnswerIndex(questionId, updatedAnswers);
    if (answerIndex === -1) {
      updatedAnswers.push({ questionId, choices:  [{ choiceId }] });
    } 
    else {
      const answer = updatedAnswers[answerIndex];
      const choiceIndex = answer.choices.findIndex(choice => choice.choiceId === choiceId);
      if (choiceIndex === -1) {
        // checked -> add 
        answer.choices.push({ choiceId });
      }
      else {
        // unchecked -> delete
        answer.choices.splice(choiceIndex, 1);
      }
    }

    this.setState({ answers: updatedAnswers });
  }

  validateForm = (answers) => {
    const { RSA: { Questions } } = this.props;

    // reset errors
    const errors = Questions.map(() =>false);
    let hasError = false;

    // validate choices have been made
    Questions.forEach((question, questionIndex) => {  
      const answerIndex = answers.findIndex(answer => answer.questionId === question.QuestionId);
      if (answerIndex === -1) {
        errors[questionIndex] = true;
        hasError = true;
      }
      else if (answers[answerIndex].choices.length === 0) {
        errors[questionIndex] = true;
        hasError = true;
      }
    });

    if (hasError)
      this.setState({ errors });

    return hasError;
  }

  sendRSAAnswers = e => {
    e.preventDefault();
    const { answers } = this.state;
    const { RSA } = this.props;

    if (!this.validateForm(answers)) {
      // build Answer Set
      const answerSet = {};
      answerSet.questionSetId = RSA.QuestionSetId;
      answerSet.transactionId = RSA.TransactionId;
      answerSet.answers = answers;     

      this.props.handlePostRSA(answerSet);
    }
  }

  renderRSAQandA() {
    const { answers, errors } = this.state;
    const { text: { Multifactor }, loading, RSA: { Questions } } = this.props;

    const questRendered = Questions.map((question, questionIndex) => {
      if (question.AnswerType.toLowerCase() === 'single') {
        const choices = question.Choices.map(choice => {
          return (
            <FormControlLabel
              key={choice.ChoiceId}
              control={<Radio />}
              label={choice.Text}
              value={`${question.QuestionId}|${choice.ChoiceId}`}
            />
          );
        });
        return (
          <div key={question.QuestionId} className={styles.questionAndAnswer}>
            <p>{question.Text}</p>
            <div className={styles.helpText}>{question.HelpText}</div>
            <RadioGroup
              aria-label='registration method'
              name='method'
              value={answers[question.QuestionId]}
              onChange={this.handleRadioChoices}
              style={{ width: '70%', marginLeft: '15%' }}
            >
              {choices}
            </RadioGroup>
            <FormHelperText error={errors[questionIndex]} style={{ textAlign: 'center' }}>{errors[questionIndex] && Multifactor.err_required}</FormHelperText>
          </div>
        );
      }
      else {
        const choices = question.Choices.map(choice => {
          return (
            <FormControlLabel
              key={choice.ChoiceId}
              control={<Checkbox style={{ padding: '3px' }} />}
              label={choice.Text}
              onChange={this.handleCheckboxChoices}
              name={`${question.QuestionId}|${choice.ChoiceId}`}
              value={`${question.QuestionId}|${choice.ChoiceId}`}
            />
          );
        });
        return (
          <div key={question.QuestionId} className={styles.questionAndAnswer}>
            <p>{question.Text}</p>
            <div className={styles.helpText}>{question.HelpText}</div>
            <FormControl component='fieldset' style={{ width: '70%', marginLeft: '15%' }}>
              <FormGroup>
                {choices}
              </FormGroup>
              <FormHelperText error={errors[questionIndex]} style={{ textAlign: 'center' }}>{errors[questionIndex] && Multifactor.err_required}</FormHelperText>
            </FormControl>
          </div>
        );
      }
    });

    return (
      <React.Fragment>
        <form
          id='send-rsa-answers'
          onSubmit={this.sendRSAAnswers}
        >
          {questRendered}
          <div className={styles.rsaQuestionsButton}>
            <LoadingOverlay key='nextStep' show={loading}>
              <Button
                variant='contained'
                type='submit'
                style={{ width: '40%', minWidth: '200px' }}
              >
                {Multifactor.btn_submit_answer}
              </Button>
            </LoadingOverlay>
          </div>
        </form>
      </React.Fragment>
    );
  }

  render() {
    const { text: { Multifactor } } = this.props;

    return (
      <div style={{ width: '60%', minWidth: '300px', margin: 'auto' }}>
        <h3 style={{ textAlign: 'center' }}>{Multifactor.verify_identity_title}</h3>
        <p>{Multifactor.msg_rsa_select}</p>
        {this.renderRSAQandA()}
      </div>
    );
  }

}

export default LanguageHOC(RSAQuestions);