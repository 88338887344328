/*
 *
 * NewOptions Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  LoadingOverlay,
  notificationShow,
  allNotificationsHide,
  CardAligned,
  CardBody,
  populate3DCalculatorSchema,
  sizify,
  Modal
} from '@frontend/common';

import Step1OptionCategory from './StepComponents/Step1OptionCategory';
import Step2SelectPredefinedOption from './StepComponents/Step2SelectPredefinedOption';
import Step2CreateCustomizedOption from './StepComponents/Step2CreateCustomizedOption';
import Step3Submit from './StepComponents/Step3Submit';
import Step4PrintReview from './StepComponents/Step4PrintReview';
import TransactionStepper from 'components/Features/protected/Accounts/Transactions/TransactionStepper';

import { getNotifications, } from 'components/AppRoot/Navigation/actions';

import {
  postNewOptions,
  getNewAgeBasedSchema,
  getNewStaticSchema,
} from 'components/Features/protected/Accounts/Transactions/OptionChanges/actions';

import { accountInvestmentPathGet } from 'components/Features/protected/Accounts/actions';

import { NEW_OPTIONS } from './constants';

import { mobileWidth } from 'utils/config/_sassconfig.scss';

import styles from './styles.module.scss';

const select = state => ({
  getNotifications: PropTypes.func.isRequired,
  selectedTransaction: state.transactions.selectedTransaction,
  predefinedOptions: state.optionChanges.predefinedOptions,
  newAgeBasedSchema: state.optionChanges.newAgeBasedSchema,
  newStaticSchema: state.optionChanges.newStaticSchema,
  investmentPath: state.accounts.investmentPath,
});

export class NewOptions extends React.Component {
  static propTypes = {
    size: PropTypes.shape({
      windowWidth: PropTypes.number,
    }).isRequired,
    getNotifications: PropTypes.func.isRequired,
    postNewOptions: PropTypes.func.isRequired,
    predefinedOptions: PropTypes.arrayOf(PropTypes.shape({
      OptionId: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      isStatic: PropTypes.bool.isRequired,
      isEnrollmentDate: PropTypes.bool.isRequired,
    })),
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    currentOptionId: PropTypes.number.isRequired,
    currentOptionName: PropTypes.string.isRequired,
    selectedTransaction: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    newAgeBasedSchema: PropTypes.object.isRequired,
    newStaticSchema: PropTypes.object.isRequired,
    getNewAgeBasedSchema: PropTypes.func.isRequired,
    getNewStaticSchema: PropTypes.func.isRequired,
    accountInvestmentPathGet: PropTypes.func.isRequired,
    investmentPath: PropTypes.object.isRequired,
    text: PropTypes.shape({
      OptionChanges: PropTypes.shape({
        NewOptions: PropTypes.shape({
          msg_unsaved_changes: PropTypes.string,
          step_labels: PropTypes.arrayOf(PropTypes.string).isRequired,
          customized_agebased_option_selection_name: PropTypes.string.isRequired,
          customized_static_option_selection_name: PropTypes.string.isRequired,
          errors: PropTypes.shape({
            empty_category_selection: PropTypes.string.isRequired,
            empty_option_selection: PropTypes.string.isRequired,
            duplicate_option_selection: PropTypes.string.isRequired,
            agree_to_terms: PropTypes.string.isRequired,
            total_allocations_for_each_bracket_100_percent: PropTypes.string.isRequired,
            total_allocations_100_percent: PropTypes.string.isRequired,
          }).isRequired,
          success_message: PropTypes.string.isRequired,
          btn_ok: PropTypes.string.isRequired,
          title_options_on_mobile: PropTypes.string.isRequired,
          text_options_on_mobile: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }

  state = {
    loading: false,
    stepHasError: false,
    selectedOptionName: '',
    selectedCategory: '',
    currentSchema: { FundCategories: [] },
    isCustomized: false,
    stepData: [
      // 0
      {
        formInputs: { optionCategorySelection: '' },
        formErrors: { optionCategorySelection: '' }
      },
      // 1
      {
        formInputs: { selectedOptionId: -1, matrixAllocations: [] },
        formErrors: { selectedOptionId: '', calculatorErrors: {} }
      },
      // 2
      {
        formInputs: { checked: false },
        formErrors: { checked: '' }
      }
    ],
    stepDataScreenshot: [
      // 0
      {
        formInputs: { optionCategorySelection: '' },
        formErrors: { optionCategorySelection: '' }
      },
      // 1
      {
        formInputs: { selectedOptionId: -1, matrixAllocations: [] },
        formErrors: { selectedOptionId: '', calculatorErrors: {} }
      },
      // 2
      {
        formInputs: { checked: false },
        formErrors: { checked: '' }
      }
    ],
    calculatorErrors: {},
    createdDate: '',
    showModalWhenMobileAndCustomized: false,
  };

  stepLabels = this.props.text.OptionChanges.NewOptions.step_labels; // length will also decide number of steps

  displayUnsavedChangesPrompt = () => {
    // we need to only know if any form inputs changed
    const { loading, stepData, stepDataScreenshot } = this.state;
    return !loading && stepData.findIndex((stepDataItem, step) => !isEqual(stepDataItem.formInputs, stepDataScreenshot[step].formInputs)) !== -1;
  }

  clearStepErrors = () => {
    const updatedStepData = this.state.stepData;
    updatedStepData.forEach(stepData => {
      const clearedFormErrors = stepData.formErrors;
      // should update updatedStepData by reference
      Object.keys(clearedFormErrors).forEach(key => clearedFormErrors[key] = '');
    });

    this.setState({
      stepHasError: false,
      stepData: updatedStepData,
    });
  }

  getSetInvestmentPath(selectedCategory) {
    this.setState({ loading: true });
    this.props.accountInvestmentPathGet(this.props.match.params.accountId, true)
      .then(() => {
        this.setState({
          isCustomized: true,
          selectedCategory,
          currentSchema: this.props.investmentPath,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  saveCategoryNameStep0 = (selectedCategory, isInitialized = false) => {
    const {
      isEditing, selectedTransaction, newAgeBasedSchema, newStaticSchema,
      currentOptionName
    } = this.props;

    // if existing investment path is customized use it when starting new customized option change 
    const useInvestmentPathSchema = selectedCategory.includes(currentOptionName) && !isEditing;
    const useExistingCustomAllocation = selectedCategory.includes(selectedTransaction.scheduledOptionName) && isEditing;

    switch (selectedCategory) {
      case NEW_OPTIONS.CUSTOMIZED_AGEBASED: {
        if (useInvestmentPathSchema) {
          this.getSetInvestmentPath(selectedCategory);
        }
        else {
          this.setState({
            isCustomized: true,
            selectedCategory,
            currentSchema: useExistingCustomAllocation ? selectedTransaction.customAllocation : newAgeBasedSchema,
          });
        }

        break;
      }
      case NEW_OPTIONS.CUSTOMIZED_STATIC: {
        if (useInvestmentPathSchema) {
          this.getSetInvestmentPath(selectedCategory);
        }
        else {
          this.setState({
            isCustomized: true,
            selectedCategory,
            currentSchema: useExistingCustomAllocation ? selectedTransaction.customAllocation : newStaticSchema,
          });
        }

        break;
      }

      default:
        this.setState({
          isCustomized: false,
          selectedCategory
        });
    }

    // When category changed reset step 2 and 3 inputs also
    if (this.state.selectedCategory !== selectedCategory && !isInitialized) {
      const updatedStepData = this.state.stepData;
      const matrixAllocations = this.state.stepData[1].formInputs.matrixAllocations;
      updatedStepData[1] = {
        formInputs: { selectedOptionId: -1, matrixAllocations },
        formErrors: { selectedOptionId: '' }
      };
      updatedStepData[2] = {
        formInputs: { checked: false },
        formErrors: { checked: '' }
      };
      this.setState({
        stepData: updatedStepData
      });
    }
  }

  saveOptionNameStep1 = (selectedOptionId, selectedCategory) => {
    const {
      OptionChanges: {
        NewOptions: {
          customized_agebased_option_selection_name,
          customized_static_option_selection_name,
        }
      }
    } = this.props.text;

    switch (selectedCategory) {
      case NEW_OPTIONS.CUSTOMIZED_AGEBASED:
        this.setState({
          isCustomized: true,
          selectedOptionName: customized_agebased_option_selection_name,
        });
        break;
      case NEW_OPTIONS.CUSTOMIZED_STATIC:
        this.setState({
          isCustomized: true,
          selectedOptionName: customized_static_option_selection_name,
        });
        break;
      case NEW_OPTIONS.PREDEFINED_ENROLLMENTDATE:
      case NEW_OPTIONS.PREDEFINED_STATIC: {
        const selectedOptionName = this.props.predefinedOptions.find(option => option.OptionId === selectedOptionId).Name;
        this.setState({
          isCustomized: false,
          selectedOptionName,
        });
        break;
      }
      default:
      // do nothing
    }
  }

  onChangeHandle = (step, formInputs) => {
    if (step === 0) {
      const { size: { windowWidth } } = this.props;
      const isMobile = windowWidth < parseInt(mobileWidth);
      const isCustomized = formInputs.optionCategorySelection === NEW_OPTIONS.CUSTOMIZED_AGEBASED || formInputs.optionCategorySelection === NEW_OPTIONS.CUSTOMIZED_STATIC;
      // catching if user is doing customized on mobile device
      if (isMobile && isCustomized) {
        // show a popup and clear the category
        this.setState({ showModalWhenMobileAndCustomized: true });
        formInputs.optionCategorySelection = '';
      }
      else {
        // proceed as usual
        this.saveCategoryNameStep0(formInputs.optionCategorySelection);
      }
    }
    else if (step === 1) {
      this.saveOptionNameStep1(formInputs.selectedOptionId, this.state.stepData[0].formInputs.optionCategorySelection);
    }

    if (this.state.stepHasError)
      this.clearStepErrors(); // run just once when user starts typing

    const newStepData = this.state.stepData;
    newStepData[step].formErrors = {}; // always reset errors
    newStepData[step].formInputs = { ...newStepData[step].formInputs, ...formInputs }; // otherwise it would nuke other fields
    this.setState({ stepData: newStepData });
  };

  verifyStep = step => {
    const {
      OptionChanges: {
        NewOptions: {
          errors: {
            empty_category_selection,
            empty_option_selection,
            duplicate_option_selection,
            agree_to_terms,
          },
        }
      }
    } = this.props.text;

    // call only on clicking next button
    const formErrors = {}; // always reset errors
    switch (step) {
      case 0:
        if (this.state.stepData[0].formInputs.optionCategorySelection.trim() === '') {
          formErrors.optionCategorySelection = empty_category_selection;
        }
        break;

      case 1:
        // depends on the previous step 0 selection
        switch (this.state.stepData[0].formInputs.optionCategorySelection) {
          case NEW_OPTIONS.PREDEFINED_ENROLLMENTDATE:
          case NEW_OPTIONS.PREDEFINED_STATIC: {
            const selectedOptionId = this.state.stepData[1].formInputs.selectedOptionId;
            if (selectedOptionId.toString().trim() === '' || selectedOptionId === -1) {
              formErrors.selectedOptionId = empty_option_selection;
            }
            if (selectedOptionId === this.props.selectedTransaction.sheduledOptionId && this.props.isEditing) {
              formErrors.selectedOptionId = duplicate_option_selection;
            }
            break;
          }
          case NEW_OPTIONS.CUSTOMIZED_AGEBASED:
          case NEW_OPTIONS.CUSTOMIZED_STATIC: {
            const {
              hasAnyBracketTotalNot100Error,
              hasAllocationAmountError,
              hasBracketTotalSumOver100Error
            } = this.state.calculatorErrors;
            // This should forward all calculator errors
            formErrors.hasAnyBracketTotalNot100Error = hasAnyBracketTotalNot100Error;
            formErrors.hasAllocationAmountError = hasAllocationAmountError;
            formErrors.hasBracketTotalSumOver100Error = hasBracketTotalSumOver100Error;
            // show error toasts if any
            hasAnyBracketTotalNot100Error && this.props.notificationShow(hasAnyBracketTotalNot100Error, 'error');
            hasBracketTotalSumOver100Error && this.props.notificationShow(hasBracketTotalSumOver100Error, 'error');
            hasAllocationAmountError && this.props.notificationShow(hasAllocationAmountError, 'error');
            // populate the schema with values to be used in view only
            const matrixAllocations = this.state.stepData[1].formInputs.matrixAllocations;
            this.setState({ currentSchema: populate3DCalculatorSchema(matrixAllocations, this.state.currentSchema) });
            break;
          }
          default:
            formErrors.selectedOptionId = `Could not choose selection based on option category: ${this.state.stepData[0].formInputs.optionCategorySelection}`;
        }
        break;

      case 2:
        if (this.state.stepData[2].formInputs.checked === false) {
          formErrors.checked = agree_to_terms;
        }
        break;

      default:
        this.props.notificationShow(`Could not verify the step: ${step}`, 'error');
    }

    const newStepData = this.state.stepData;
    newStepData[step].formErrors = formErrors;
    this.setState({
      stepData: newStepData
    });

    const hasNoErrors = Object.values(formErrors).filter(err => err !== '').length === 0;
    this.setState({ stepHasError: !hasNoErrors });

    // return hasNoErrors && this.state.hasNoCustomAllocationOnBlurErrors && hasNoNewCustomAllocationError;
    return hasNoErrors;

  };

  onAllocationChange = (matrixAllocations, calculatorErrors) => {
    const updatedStepData = this.state.stepData;
    updatedStepData[1].formInputs = { selectedOptionId: -1, matrixAllocations };
    this.setState({ stepData: updatedStepData, calculatorErrors });
    // throw a toast on specific form errors
    const { hasAllocationAmountError, hasBracketTotalSumOver100Error } = calculatorErrors;
    if (hasAllocationAmountError || hasBracketTotalSumOver100Error) {
      hasBracketTotalSumOver100Error && this.props.notificationShow(hasBracketTotalSumOver100Error, 'error');
      hasAllocationAmountError && this.props.notificationShow(hasAllocationAmountError, 'error');
    }
    else {
      this.props.allNotificationsHide();
    }
  }

  handleSubmit = () => {
    const {
      OptionChanges: {
        NewOptions: {
          success_message,
        }
      }
    } = this.props.text;

    const accountId = this.props.match.params.accountId;
    // when submitting values below should be available
    const selectedCategory = this.state.stepData[0].formInputs.optionCategorySelection;
    const selectedOptionId = this.state.stepData[1].formInputs.selectedOptionId;
    const matrixAllocations = this.state.stepData[1].formInputs.matrixAllocations;
    this.setState({ loading: true });

    let postBody;
    switch (selectedCategory) {
      case NEW_OPTIONS.PREDEFINED_ENROLLMENTDATE:
      case NEW_OPTIONS.PREDEFINED_STATIC:
        postBody = [{
          AccountId: parseInt(accountId),
          OptionId: parseInt(selectedOptionId)
        }];
        break;
      case NEW_OPTIONS.CUSTOMIZED_AGEBASED:
      case NEW_OPTIONS.CUSTOMIZED_STATIC:
        postBody = [{
          AccountId: parseInt(accountId),
          matrixAllocations,
          CustomSchema: this.state.currentSchema,
        }];
        break;
      default:
        postBody = [];
    }

    return this.props.postNewOptions(postBody)
      .then(response => {
        const createdDate = response.payload.data; // gets created datetime from server to display on print page
        this.setState({
          createdDate,
          stepDataScreenshot: cloneDeep(this.state.stepData)
        });
        this.props.notificationShow(success_message, 'success');
        this.props.getNotifications(); // refreshes app notifications
      })
      .finally(() => this.setState({ loading: false }));
  };

  renderStepComponent = step => {
    const {
      predefinedOptions,
      currentOptionId,
    } = this.props;
    // remove current option from dropdown menu
    const availablePredefinedOptions = predefinedOptions.filter(option => option.OptionId !== currentOptionId);
    let stepComponent;
    switch (step) {
      case 0: {
        const Step1OptionCategoryOptions = Object.values(NEW_OPTIONS).map(value => ({ value, display: value }));
        stepComponent = (
          <Step1OptionCategory
            key='Step1OptionCategory'
            formInputs={this.state.stepData[0].formInputs}
            formErrors={this.state.stepData[0].formErrors}
            onChangeHandle={formInputs => this.onChangeHandle(0, formInputs)}
            dropdownOptions={Step1OptionCategoryOptions}
          />
        );
        break;
      }

      case 1: {
        // depends on the previous step 0 selection if new
        let Step2SelectOptionComponent;
        switch (this.state.stepData[0].formInputs.optionCategorySelection) {
          case NEW_OPTIONS.PREDEFINED_STATIC: {
            const staticOptions = availablePredefinedOptions
              .filter(option => option.isStatic)
              .map(option => ({ value: option.OptionId.toString(), display: option.Name }));
            Step2SelectOptionComponent = (
              <Step2SelectPredefinedOption
                key='Step2SelectPredefinedOption'
                formInputs={this.state.stepData[1].formInputs}
                formErrors={this.state.stepData[1].formErrors}
                onChangeHandle={formInputs => this.onChangeHandle(1, formInputs)}
                dropdownOptions={staticOptions}
                selectedCategory={this.state.selectedCategory}
              />
            );
            break;
          }
          case NEW_OPTIONS.PREDEFINED_ENROLLMENTDATE: {
            const enrollmentDateOptions = availablePredefinedOptions
              .filter(option => option.isEnrollmentDate)
              .map(option => ({ value: option.OptionId.toString(), display: option.Name }));
            Step2SelectOptionComponent = (
              <Step2SelectPredefinedOption
                key='Step2SelectPredefinedOption'
                formInputs={this.state.stepData[1].formInputs}
                formErrors={this.state.stepData[1].formErrors}
                onChangeHandle={formInputs => this.onChangeHandle(1, formInputs)}
                dropdownOptions={enrollmentDateOptions}
                selectedCategory={this.state.selectedCategory}
              />
            );
            break;
          }
          case NEW_OPTIONS.CUSTOMIZED_AGEBASED:
          case NEW_OPTIONS.CUSTOMIZED_STATIC:
            Step2SelectOptionComponent = (
              <Step2CreateCustomizedOption
                formInputs={this.state.stepData[1].formInputs}
                onAllocationChange={this.onAllocationChange}
                currentSchema={this.state.currentSchema}
                selectedCategory={this.state.selectedCategory}
              />
            );
            break;
          default:
            Step2SelectOptionComponent = <div>Oops! No new option selection found.</div>;
        }
        stepComponent = Step2SelectOptionComponent;
        break;
      }

      case 2: {
        stepComponent = (
          <Step3Submit
            key='step3'
            formInputs={this.state.stepData[2].formInputs}
            formErrors={this.state.stepData[2].formErrors}
            selectedOptionName={this.state.selectedOptionName}
            selectedCategory={this.state.selectedCategory}
            onChangeHandle={formInputs => this.onChangeHandle(2, formInputs)}
            currentSchema={this.state.currentSchema}
            matrixAllocations={this.state.stepData[1].formInputs.matrixAllocations}
            isCustomized={this.state.isCustomized}
          />
        );
        break;
      }

      case 3: {
        stepComponent = (
          <Step4PrintReview
            key='step4'
            pendingOptionName={this.state.selectedOptionName.replace('Option Change', '')}
            createdDate={this.state.createdDate}
            currentSchema={this.state.currentSchema}
            isCustomized={this.state.isCustomized}
          />
        );
        break;
      }

      default:
        stepComponent = <div>Step does not exist!</div>;
    }
    return stepComponent;
  };

  findCategoryByOptionId(optionId) {
    const category = this.props.predefinedOptions.find(option => option.OptionId === optionId);
    let optionCategorySelection = '';
    // this reflects how api/transactions/optionchanges/:OptionChangeId/actions/:ActionId works
    if (category === undefined) {
      // OptionId not found, it must be customized age-based (OptionId=0?)
      optionCategorySelection = NEW_OPTIONS.CUSTOMIZED_AGEBASED;
    }
    else if (category.isEnrollmentDate) {
      optionCategorySelection = NEW_OPTIONS.PREDEFINED_ENROLLMENTDATE;
    }
    else if (category.isStatic) {
      optionCategorySelection = NEW_OPTIONS.PREDEFINED_STATIC;
    }
    else {
      // OptionId=12, it must be customized static
      optionCategorySelection = NEW_OPTIONS.CUSTOMIZED_STATIC;
    }
    return optionCategorySelection;
  }

  initializeFormInputs() {
    const { selectedTransaction, isEditing, size: { windowWidth } } = this.props;
    if (isEditing) {
      // set dropdown options when editing
      const isEditingStepData = this.state.stepData;
      const optionCategorySelection = this.findCategoryByOptionId(selectedTransaction.sheduledOptionId);

      // catching if user is doing customized on mobile device
      const isMobile = windowWidth < parseInt(mobileWidth);
      const isCustomized = optionCategorySelection === NEW_OPTIONS.CUSTOMIZED_AGEBASED || optionCategorySelection === NEW_OPTIONS.CUSTOMIZED_STATIC;
      if (isMobile && isCustomized) {
        // show a popup and clear the category
        this.setState({ showModalWhenMobileAndCustomized: true });
        isEditingStepData[0].formInputs.optionCategorySelection = '';
        isEditingStepData[1].formInputs.selectedOptionId = '';
        isEditingStepData[1].formInputs.matrixAllocations = [];
      }
      else {
        isEditingStepData[0].formInputs.optionCategorySelection = optionCategorySelection;
        isEditingStepData[1].formInputs.selectedOptionId = selectedTransaction.sheduledOptionId;
        isEditingStepData[1].formInputs.matrixAllocations = selectedTransaction.matrixAllocations;
      }

      this.setState({
        stepData: isEditingStepData,
        stepDataScreenshot: cloneDeep(isEditingStepData),
      });
      this.saveCategoryNameStep0(isEditingStepData[0].formInputs.optionCategorySelection, true);
      this.saveOptionNameStep1(isEditingStepData[1].formInputs.selectedOptionId, isEditingStepData[0].formInputs.optionCategorySelection);
    }
    else {
      this.setState({ stepDataScreenshot: cloneDeep(this.state.stepData) });
    }
  }

  componentDidMount() {
    if (this.props.predefinedOptions.length > 0) {
      this.initializeFormInputs();
    }
    else {
      this.setState({ stepDataScreenshot: cloneDeep(this.state.stepData) });
    }

    // for simplicity load customized age-based or static templates if missing
    const promises = [];
    if (this.props.newAgeBasedSchema.FundCategories.length === 0) {
      promises.push(this.props.getNewAgeBasedSchema());
    }

    if (this.props.newStaticSchema.FundCategories.length === 0) {
      promises.push(this.props.getNewStaticSchema());
    }

    if (promises.length > 0) {
      this.setState({ loading: true });
      Promise.all(promises)
        .then(() => this.setState({ stepDataScreenshot: cloneDeep(this.state.stepData) }))
        .finally(() => this.setState({ loading: false }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.predefinedOptions.length !== this.props.predefinedOptions.length && this.props.predefinedOptions.length > 0) {
      this.initializeFormInputs();
    }
  }

  render() {
    const {
      OptionChanges: {
        NewOptions: {
          btn_ok, title_options_on_mobile, text_options_on_mobile
        }
      }
    } = this.props.text;

    return (
      <LoadingOverlay show={this.state.loading} width='100%'>
        <CardAligned>
          <CardBody className={styles.newOptions}>
            <TransactionStepper
              stepHasError={this.state.stepHasError}
              clearStepErrors={this.clearStepErrors}
              verifyStep={this.verifyStep}
              renderStepComponent={this.renderStepComponent}
              handleSubmit={this.handleSubmit}
              stepLabels={this.stepLabels}
              submitStep={2}
            />
          </CardBody>
        </CardAligned>

        <Prompt
          message={this.props.text.OptionChanges.NewOptions.msg_unsaved_changes}
          when={this.displayUnsavedChangesPrompt()}
        />

        <Modal
          actionButtons={[{
            label: btn_ok,
            action: () => this.setState({ showModalWhenMobileAndCustomized: false }),
          }]}
          fullScreen={false}
          onCloseModal={() => this.setState({ showModalWhenMobileAndCustomized: false })}
          show={this.state.showModalWhenMobileAndCustomized}
          title={title_options_on_mobile}
        >
          {text_options_on_mobile}
        </Modal>

      </LoadingOverlay>
    );
  }
}

export default withRouter(connect(select, {
  getNotifications,
  postNewOptions,
  notificationShow,
  allNotificationsHide,
  getNewAgeBasedSchema,
  getNewStaticSchema,
  accountInvestmentPathGet
})(sizify(LanguageHOC(NewOptions))));
