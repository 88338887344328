/*
*
* SkeletonLoader Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from '@mui/material';
import { Typography } from '@mui/material';

export class SkeletonLoader extends React.Component {

  static propTypes = {
    isLoading: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.string,
    textVariant: PropTypes.string,
    skeletonProps: PropTypes.object,
    typographyProps: PropTypes.object,
  };

  render() {
    const { children, isLoading = true, height, width, textVariant, variant, skeletonProps, typographyProps, } = this.props;

    const skeleton = (
      <Skeleton
        height={height}
        width={width}
        variant={variant || 'rect'}
        {...skeletonProps}
      />
    );

    const skeletonLoader = textVariant
      ? (
        <Typography
          variant={textVariant}
          {...typographyProps}
        >
          {skeleton}
        </Typography>
      )
      : skeleton;


    return (
      isLoading ? skeletonLoader : children || null
    );
  }
}

export default SkeletonLoader;