import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
  TextField,
} from '@mui/material';
import FormWrapper from '../FormWrapper';
import {
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';

import { GETRecaptchaToken } from 'utils/helpers/api_handler';
import { verifyRecaptcha } from 'components/AppRoot/Navigation/actions';
import { forgotPassword } from 'components/Features/public/ForgotPassword/actions';

import styles from './styles.module.scss';

const select = (state) => ({
  recaptchaToken: state.session.userDetails.recaptchaToken,
  recaptchaVerified: state.session.userDetails.recaptchaVerified,
  supportNumber: state.static.environmentVars.SupportPhoneNumber,
});

export class ForgotPassword extends Component {

  static propTypes = {
    notificationShow: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    verifyRecaptcha: PropTypes.func.isRequired,
    recaptchaToken: PropTypes.string.isRequired,
    recaptchaVerified: PropTypes.bool.isRequired,
    supportNumber: PropTypes.string.isRequired,
    text: PropTypes.shape({
      ForgotPassword: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_submit: PropTypes.string,
        err_username: PropTypes.string,
        msg_recaptcha_language: PropTypes.string,
        msg_success: PropTypes.string,
        nav_title: PropTypes.string,
        text_instructions: PropTypes.func,
        text_username: PropTypes.string,
      }),
      Login: PropTypes.shape({
        nav_path: PropTypes.string,
      })
    }).isRequired,
  };

  state = {
    loading: false,
    username: '',
    inputError: '',
    canContinue: false
  };

  formValidate = () => {
    let formIsValid = false;
    let inputError = '';

    if (this.state.username.length < 1) {
      inputError = this.props.text.ForgotPassword.err_username;
    }
    else {
      formIsValid = true;
    }

    this.setState({
      inputError,
    });

    return formIsValid;
  };

  onFormSubmit = (e) => {
    e.preventDefault();

    if (this.formValidate()) {
      this.setState({ loading: true });
      this.props.forgotPassword({ login: this.state.username })
        .then(() => {
          this.props.history.push(this.props.text.Login.nav_path);
          this.props.notificationShow(this.props.text.ForgotPassword.msg_success, 'success');
        })
        .catch(() => this.setState({ loading: false }));
    }
  };

  recaptchaVerify = () => {
    GETRecaptchaToken('forgotpassword')
      .then(token => {
        if (token !== this.props.recaptchaToken) {
          this.props.verifyRecaptcha(token)
            .then((response) => {
              if (!this.props.recaptchaVerified) {
                const warningMessage = response.payload.data['error-codes'][0];
                this.setState({ canContinue: true });
                this.props.notificationShow(warningMessage, 'warning');
              }
            });
        }
      });
  }

  componentDidMount() {
    this.recaptchaVerify();
  }

  render() {
    const { 
      btn_cancel, btn_submit, nav_title, text_instructions, 
      text_username, msg_recaptcha_language
    } = this.props.text.ForgotPassword;
    const { loading, canContinue, inputError, username } = this.state;
    return (
      <React.Fragment>
        <FormWrapper title={nav_title}>
          <form
            className='formContainer'
            onSubmit={this.onFormSubmit}
          >
            <p>{text_instructions(this.props.supportNumber)}</p>
            <TextField
              onChange={(e) => this.setState({ username: e.target.value, inputError: '' })}
              value={username}
              label={text_username}
              fullWidth
              autoFocus
              disabled={loading}
              error={Boolean(inputError)}
              helperText={inputError}
              variant='filled'
            />
            <div className={styles.buttons}>
              <Button
                variant='text'
                color='primary'
                style={{ width: '48%' }}
                onClick={() => this.props.history.goBack()}
                disabled={loading}
              >
                {btn_cancel}
              </Button>
              <LoadingOverlay show={loading} width='48%'>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={loading || canContinue}
                  fullWidth
                >
                  {btn_submit}
                </Button>
              </LoadingOverlay>
            </div>
          </form>
        </FormWrapper>

        <div className={styles.recaptcha}> {/* This reCAPTCHA text is required since we are hiding the badge on all pages */}
          <p>{parse(msg_recaptcha_language)}</p>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(select, {
  notificationShow,
  forgotPassword,
  verifyRecaptcha
})(LanguageHOC(ForgotPassword)));