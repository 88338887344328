/*
*
* Review Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { currencyFormatter } from '@frontend/common';
import styles from './styles.module.scss';


export function Review({ activeStep, beneficiaryName, withdrawalInfo, text: { WithdrawalRequests } }) {
  const IsFullBalance = withdrawalInfo.IsFullBalance === 'true';
  return (
    <div className={styles.reviewContainer}>
      <div className={styles.amount}>
        <h2>{IsFullBalance ? `${WithdrawalRequests.head_amount}*` : WithdrawalRequests.head_amount}</h2>
        <p>{currencyFormatter(withdrawalInfo.Amount)}</p>
      </div>
      <div className={styles.recipient}>
        <h2>{WithdrawalRequests.head_recipient}</h2>
        <p>{withdrawalInfo.SchoolName}</p>
        {withdrawalInfo.SchoolDepartment && <p>{withdrawalInfo.SchoolDepartment}</p>}
        <p>{withdrawalInfo.PayeeAddressLine1}</p>
        <p>{withdrawalInfo.PayeeCity}, {withdrawalInfo.PayeeState} {withdrawalInfo.PayeeZipCode}</p>
        <p>{WithdrawalRequests.lbl_fbo} {beneficiaryName}</p>
        {withdrawalInfo.StudentNumber && <p>{WithdrawalRequests.lbl_student_id}: {withdrawalInfo.StudentNumber}</p>}
      </div>
      {IsFullBalance && activeStep === 3 && <div className={styles.fullBalanceNote}>{WithdrawalRequests.text_full_balance_note}</div>}
    </div>
  );
}

Review.propTypes = {
  activeStep: PropTypes.number.isRequired,
  beneficiaryName: PropTypes.string.isRequired,
  withdrawalInfo: PropTypes.shape({
    Amount: PropTypes.string,
    IsFullBalance: PropTypes.string,
    PayeeAddressLine1: PropTypes.string,
    PayeeCity: PropTypes.string,
    PayeeState: PropTypes.string,
    PayeeZipCode: PropTypes.string,
    SchoolDepartment: PropTypes.string,
    SchoolName: PropTypes.string,
    StudentNumber: PropTypes.string,
  }).isRequired,
  text: PropTypes.shape({
    WithdrawalRequests: PropTypes.shape({
      head_amount: PropTypes.string,
      head_recipient: PropTypes.string,
      lbl_fbo: PropTypes.string,
      lbl_student_id: PropTypes.string,
      text_full_balance_note: PropTypes.string,
    }),
  }).isRequired,
};

export default LanguageHOC(Review);