import React from 'react';
import PropTypes from 'prop-types';

import {
  Dropdown,
} from '@frontend/common';


export default class Step1OptionCategory extends React.Component {
  static propTypes = {
    formInputs: PropTypes.shape({
      optionCategorySelection: PropTypes.string,
    }),
    formErrors: PropTypes.shape({
      optionCategorySelection: PropTypes.string,
    }),
    onChangeHandle: PropTypes.func.isRequired,
    dropdownOptions: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    }))
  };

  render() {
    return (
      <Dropdown
        labelId={'optionCategorySelection'}
        label={'Option Category'}
        value={this.props.formInputs.optionCategorySelection}
        errorText={this.props.formErrors.optionCategorySelection}
        onChange={(value) => this.props.onChangeHandle({ optionCategorySelection: value })}
        options={this.props.dropdownOptions}
        FormControlProps={{ style: { width: '40%', minWidth: '300px', textAlign: 'left' } }}
      />
    );
  }
}