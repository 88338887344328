/*
*
* BeneficiaryStep Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import { GETaddress } from 'utils/helpers/api_handler';
import * as validator from 'utils/helpers/form_validation';
import {
  getExistingBeneficiary,
  saveBeneficiary,
} from '../actions';
import {
  Button,
  Paper,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import {
  AddressTextFields,
  Checkbox,
  DatePicker,
  Dropdown,
  IconBtnTooltip,
  InfoIcon,
  notificationShow,
  SsnInput,
} from '@frontend/common';
import { BLANK_ADDRESS } from 'components/AppRoot/StaticResources/constants';
import { BLANK_BENE_INFO } from '../constants';

import styles from './styles.module.scss';

const errorTabStyles = {
  backgroundColor: 'rgba(255, 0, 0, 0.25)',
  color: 'rgb(255, 0, 0)',
};

const select = (state) => ({
  accountOwnerInfo: state.signup.accountOwnerInfo,
  ADDRESS_TYPES: state.static.environmentVars.AddressTypes,
  BENEFICIARY_RELATIONSHIPS: state.static.environmentVars.RelationshipTypes,
  beneficiaryInfo: state.signup.beneficiaryInfo,
  existingBenesDetails: state.signup.existingBenesDetails,
  existingBenesList: state.signup.existingBenesList,
  isAgent_AccessRole: state.session.isAgent_AccessRole,
  MaximumAge: state.static.environmentVars.MaximumAge,
  usStates: state.static.usStates,
});

export class BeneficiaryStep extends React.Component {
  static propTypes = {
    accountOwnerInfo: PropTypes.shape({
      encryptedDataExists: PropTypes.bool,
      Mailing: PropTypes.shape({
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
      }),
      OwnerBirthdate: PropTypes.string,
      OwnerFirstName: PropTypes.string,
      OwnerIsResident: PropTypes.bool,
      OwnerLastName: PropTypes.string,
      OwnerMiddleName: PropTypes.string,
      OwnerSSN1: PropTypes.string,
      OwnerSSN2: PropTypes.string,
      Physical: PropTypes.shape({
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
      })
    }),
    ADDRESS_TYPES: PropTypes.shape({
      Physical: PropTypes.string,
      Mailing: PropTypes.string,
    }).isRequired,
    /* eslint-disable id-length */
    BENEFICIARY_RELATIONSHIPS: PropTypes.shape({
      C: PropTypes.string,
      G: PropTypes.string,
      N: PropTypes.string,
      O: PropTypes.string,
      S: PropTypes.string,
      SP: PropTypes.string,
    }).isRequired,
    /* eslint-enable id-length */
    beneficiaryInfo: PropTypes.shape({
      encryptedDataExists: PropTypes.bool,
      Mailing: PropTypes.shape({
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
      }),
      BeneficiaryBirthdate: PropTypes.string,
      BeneficiaryId: PropTypes.number,
      BeneficiaryFirstName: PropTypes.string,
      BeneficiaryLastName: PropTypes.string,
      BeneficiaryMiddleName: PropTypes.string,
      BeneficiarySSN1: PropTypes.string,
      BeneficiarySSN2: PropTypes.string,
      Physical: PropTypes.shape({
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
      }),
      RelationshipToAccountOwner: PropTypes.string,
    }).isRequired,
    existingBenesDetails: PropTypes.object.isRequired,
    existingBenesList: PropTypes.array.isRequired,
    getExistingBeneficiary: PropTypes.func.isRequired,
    isAgent_AccessRole: PropTypes.bool.isRequired,
    MaximumAge: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onBackClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    saveBeneficiary: PropTypes.func.isRequired,
    setLoadingOff: PropTypes.func.isRequired,
    setLoadingOn: PropTypes.func.isRequired,
    text: PropTypes.shape({
      BeneficiaryStep: PropTypes.shape({
        btn_back: PropTypes.string,
        btn_continue: PropTypes.string,
        err_copy_ssn: PropTypes.string,
        err_phone_number_too_short: PropTypes.string,
        err_smarty_streets: PropTypes.string,
        err_ssns_must_match: PropTypes.string,
        head_beneficiary: PropTypes.string,
        lbl_birthdate: PropTypes.string,
        lbl_cancel: PropTypes.string,
        lbl_clear_state: PropTypes.string,
        lbl_existing_bene: PropTypes.string,
        lbl_firstName: PropTypes.string,
        lbl_lastName: PropTypes.string,
        lbl_mailing_is_same_as_ao: PropTypes.string,
        lbl_middleName: PropTypes.string,
        lbl_physical_is_same_as_ao: PropTypes.string,
        lbl_relationship: PropTypes.string,
        lbl_ssn_tin: PropTypes.string,
        lbl_ssn_tin_confirm: PropTypes.string,
        lbl_utah_tax_payer: PropTypes.string,
        msg_bene_info_saved: PropTypes.string,
        text_what_is_bene: PropTypes.array,
      }),
      AddressCard: PropTypes.object,
    }).isRequired,
    usStates: PropTypes.array.isRequired,
  };

  state = {
    addressSuggestions: [],
    addressType: this.props.ADDRESS_TYPES.Mailing,
    apiErrors: [],
    autocompleteText: {
      Mailing: this.props.beneficiaryInfo.Mailing.StreetAddress1,
      Physical: this.props.beneficiaryInfo.Physical.StreetAddress1,
    },
    beneficiaryInfo: this.props.beneficiaryInfo,
    editSsn: true,
    existingBeneficiaryId: this.props.beneficiaryInfo.BeneficiaryId,
    formErrors: {
      Mailing: {},
      Physical: {},
    },
  };

  addressIsDisabled = () => {
    // disabled if checkbox is checked to make address same as AO is checked
    const { addressType, beneficiaryInfo: { BeneficiaryMailingAddressSameAsOwner, BeneficiaryPhysicalAddressSameAsOwner } } = this.state;
    const { ADDRESS_TYPES } = this.props;
    return (addressType === ADDRESS_TYPES.Physical && BeneficiaryPhysicalAddressSameAsOwner) || (addressType === ADDRESS_TYPES.Mailing && BeneficiaryMailingAddressSameAsOwner);
  }

  addressTabChange = (e, value) => {
    this.setState({ addressType: value });
  };

  autocompleteAddressSelect = (event, selection) => {
    const { beneficiaryInfo, addressType, autocompleteText, formErrors } = this.state;

    if (!selection) { // if user clears input
      this.setState({
        autocompleteText: {
          ...beneficiaryInfo[addressType],
          [addressType]: '',
        },
        beneficiaryInfo: {
          ...beneficiaryInfo,
          [addressType]: {
            ...beneficiaryInfo[addressType],
            StreetAddress1: ''
          }
        },
        addressSuggestions: [],
      });
    }
    else if (!Object.prototype.hasOwnProperty.call(selection, 'street_line')) { // if user sends too many requests in a short period, autocomplete stops sending suggestions back, so just set input to state
      this.setState({
        autocompleteText: {
          ...autocompleteText,
          [addressType]: selection
        },
        beneficiaryInfo: {
          ...beneficiaryInfo,
          [addressType]: {
            ...beneficiaryInfo[addressType],
            StreetAddress1: selection,
          }
        }
      });
    }
    else { // if all is working as expected and selection is valid SmartyStreets object
      this.setState({
        apiErrors: [],
        autocompleteText: {
          ...autocompleteText,
          [addressType]: selection.street_line.toUpperCase()
        },
        beneficiaryInfo: {
          ...beneficiaryInfo,
          [addressType]: {
            ...beneficiaryInfo[addressType],
            City: selection.city.toUpperCase(),
            PostalCode: selection.zipcode.toUpperCase(),
            State: selection.state.toUpperCase(),
            StreetAddress1: selection.street_line.toUpperCase(),
            StreetAddress2: selection.secondary.toUpperCase()
          }
        },
        formErrors: {
          ...formErrors,
          [addressType]: {}
        }
      });
    }
  }

  autocompleteSuggestionsGet = (event) => {
    const { beneficiaryInfo, addressType, apiErrors, autocompleteText, formErrors } = this.state;

    this.setState({
      apiErrors: {
        ...apiErrors,
        [addressType]: []
      },
      autocompleteText: {
        ...autocompleteText,
        [addressType]: event.target.value
      },
      beneficiaryInfo: {
        ...beneficiaryInfo,
        [addressType]: {
          ...beneficiaryInfo[addressType],
          StreetAddress1: event.target.value
        }
      },
      formErrors: {
        ...formErrors,
        [addressType]: {
          ...formErrors[addressType],
          StreetAddress1: ''
        }
      }
    });

    if (event.target.value.trim()) { // Only make a request if there is text. Trims out leading spaces to avoid a Smarty Streets freakout
      return GETaddress(event.target.value)
        .then(res => {
          if (res.data.suggestions !== null) {
            this.setState({ addressSuggestions: res.data.suggestions });
          }
        })
        .catch(res => {
          if (res !== 'short-criteria') {
            this.props.notificationShow(this.props.text.AddressCard.err_smarty_streets, 'error');
          }
          this.setState({ addressSuggestions: [] });
        });
    }
  }

  copyAddress = e => {
    const { accountOwnerInfo, ADDRESS_TYPES } = this.props;
    const { addressType, autocompleteText, beneficiaryInfo, formErrors } = this.state;
    const shouldCopy = e.target.checked;

    if (addressType === ADDRESS_TYPES.Mailing) {
      this.setState({
        apiErrors: [],
        autocompleteText: {
          ...autocompleteText,
          Mailing: shouldCopy ? accountOwnerInfo.Mailing.StreetAddress1 : ''
        },
        beneficiaryInfo: {
          ...beneficiaryInfo,
          BeneficiaryMailingAddressSameAsOwner: shouldCopy,
          Mailing: shouldCopy ? accountOwnerInfo.Mailing : BLANK_ADDRESS,
        },
        formErrors: {
          ...formErrors,
          Mailing: {}, // need to clear errors in Mailing in the case of a PO box error from API
          Physical: {},
        },
      });
    }
    else {
      this.setState({
        apiErrors: [],
        autocompleteText: {
          ...autocompleteText,
          Physical: shouldCopy ? accountOwnerInfo.Physical.StreetAddress1 : ''
        },
        beneficiaryInfo: {
          ...beneficiaryInfo,
          BeneficiaryPhysicalAddressSameAsOwner: !this.state.beneficiaryInfo.BeneficiaryPhysicalAddressSameAsOwner,
          Physical: shouldCopy ? accountOwnerInfo.Physical : BLANK_ADDRESS,
        },
        formErrors: {
          ...formErrors,
          Mailing: {},
          Physical: {},
        }
      });
    }
  }

  formValidate = () => {
    const { apiErrors, beneficiaryInfo, editSsn } = this.state;
    const { ADDRESS_TYPES, MaximumAge, text: { BeneficiaryStep } } = this.props;
    const formErrors = { ...this.state.formErrors };

    // If editing, validate SSN. Encrypted SSN will not run through the validator.
    formErrors.BeneficiarySSN1 = editSsn ? validator.socialSecurityNumValidator(beneficiaryInfo.BeneficiarySSN1) : '';
    formErrors.BeneficiarySSN2 = editSsn ? validator.socialSecurityNumValidator(beneficiaryInfo.BeneficiarySSN2) : '';

    if (!formErrors.BeneficiarySSN2 && editSsn && beneficiaryInfo.BeneficiarySSN1 !== beneficiaryInfo.BeneficiarySSN2) { // checks if there is SSN2 error and gives that priority
      formErrors.BeneficiarySSN2 = BeneficiaryStep.err_ssns_must_match;
    }

    formErrors.BeneficiaryBirthdate = validator.beneficiaryBirthdateValidator(beneficiaryInfo.BeneficiaryBirthdate, MaximumAge);
    formErrors.BeneficiaryFirstName = validator.firstNameValidator(beneficiaryInfo.BeneficiaryFirstName);
    formErrors.BeneficiaryLastName = validator.lastNameValidator(beneficiaryInfo.BeneficiaryLastName);
    formErrors.Mailing.StreetAddress1 = validator.street1Validator(beneficiaryInfo.Mailing.StreetAddress1);
    beneficiaryInfo.Mailing.StreetAddress2 && (formErrors.Mailing.StreetAddress2 = validator.street1Validator(beneficiaryInfo.Mailing.StreetAddress2));
    formErrors.Mailing.City = validator.cityValidator(beneficiaryInfo.Mailing.City);
    formErrors.Mailing.State = validator.stateValidator(beneficiaryInfo.Mailing.State);
    formErrors.Mailing.PostalCode = validator.postalCodeValidator(beneficiaryInfo.Mailing.PostalCode);
    formErrors.BeneficiaryMiddleName = validator.middleNameValidator(beneficiaryInfo.BeneficiaryMiddleName);
    formErrors.RelationshipToAccountOwner = validator.beneficiaryRelationshipValidator(beneficiaryInfo.RelationshipToAccountOwner);

    if (Object.keys(beneficiaryInfo.Physical).some(field => beneficiaryInfo.Physical[field] !== '')) { // if Physical address has anything in it, validation will run
      formErrors.Physical.StreetAddress1 = validator.street1Validator(beneficiaryInfo.Physical.StreetAddress1);
      beneficiaryInfo.Physical.StreetAddress2 && (formErrors.Physical.StreetAddress2 = validator.street1Validator(beneficiaryInfo.Physical.StreetAddress2));
      formErrors.Physical.City = validator.cityValidator(beneficiaryInfo.Physical.City);
      formErrors.Physical.State = validator.stateValidator(beneficiaryInfo.Physical.State);
      formErrors.Physical.PostalCode = validator.postalCodeValidator(beneficiaryInfo.Physical.PostalCode);
    }
    else { // Clear validation errors for Physical if nothing is entered
      formErrors.Physical.StreetAddress1 = '';
      formErrors.Physical.City = '';
      formErrors.Physical.State = '';
      formErrors.Physical.PostalCode = '';
    }

    if (apiErrors.length > 0) {
      apiErrors.forEach(error => {
        if (error.Field.includes(ADDRESS_TYPES.Mailing)) {
          switch (error.Field) {
            case 'BeneficiaryMailingStreetAddress1': formErrors.Mailing.StreetAddress1 = error.Message; break;
            case 'BeneficiaryMailingStreetAddress2': formErrors.Mailing.StreetAddress2 = error.Message; break;
            case 'BeneficiaryMailingCity': formErrors.Mailing.City = error.Message; break;
            case 'BeneficiaryMailingPostalCode': formErrors.Mailing.PostalCode = error.Message; break;
            case 'BeneficiaryMailingState': formErrors.Mailing.State = error.Message; break;
            default: break;
          }
        }
        else if (error.Field.includes(ADDRESS_TYPES.Physical)) {
          switch (error.Field) {
            case 'BeneficiaryPhysicalStreetAddress1': formErrors.Physical.StreetAddress1 = error.Message; break;
            case 'BeneficiaryPhysicalStreetAddress2': formErrors.Physical.StreetAddress2 = error.Message; break;
            case 'BeneficiaryPhysicalCity': formErrors.Physical.City = error.Message; break;
            case 'BeneficiaryPhysicalPostalCode': formErrors.Physical.PostalCode = error.Message; break;
            case 'BeneficiaryPhysicalState': formErrors.Physical.State = error.Message; break;
            default: break;
          }
        }
        else {
          formErrors[error.Field] = error.Message;
        }
      });
    }

    this.setState({ formErrors });

    const hasMailingErrors = Object.keys(formErrors.Mailing).some(mailingError => formErrors.Mailing[mailingError] !== '');
    const hasPhysicalErrors = Object.keys(formErrors.Physical).some(physicalError => formErrors.Physical[physicalError] !== '');
    const formWithoutAddresses = Object.keys(formErrors).filter(field => field !== ADDRESS_TYPES.Mailing && field !== ADDRESS_TYPES.Physical);
    const hasOtherErrors = formWithoutAddresses.some(error => formErrors[error] !== '');

    return !hasMailingErrors && !hasPhysicalErrors && !hasOtherErrors;
  }

  getExistingBeneValue = () => {
    const { beneficiaryInfo: { BeneficiaryId } } = this.state;
    const isExisting = this.props.existingBenesList.some(bene => bene.BeneficiaryId === BeneficiaryId); // returns bool if any BeneficiaryId in existingBenesList matches the BeneficiaryId in state

    if (isExisting) { // Selects BeneficiaryId only if bene exists in existingBenesList. 
      return BeneficiaryId;
    }
    return 0; // If Self is selected, the API will assign a BeneficiaryId (The API considers the BeneficiaryId the same as AgentId in this case) but this account may NOT be in the existingBenesList, causing the value to be undefined. This will default it to New Beneficiary in the drop down.
  }

  inputIsDisabled = () => {
    // disabled if loading
    // disabled if relationship to AO is self
    // disabled if existing beneficiary is selected
    const { BENEFICIARY_RELATIONSHIPS, isLoading } = this.props;
    const { existingBeneficiaryId, beneficiaryInfo: { RelationshipToAccountOwner } } = this.state;
    return isLoading || BENEFICIARY_RELATIONSHIPS[RelationshipToAccountOwner] === BENEFICIARY_RELATIONSHIPS.S || existingBeneficiaryId !== 0;
  }

  onAddressChange = (name, e) => {
    const { addressType } = this.state;

    this.setState({
      beneficiaryInfo: {
        ...this.state.beneficiaryInfo,
        [addressType]: {
          ...this.state.beneficiaryInfo[addressType],
          [name]: e.target.value
        }
      },
      apiErrors: [],
      formErrors: {
        ...this.state.formErrors,
        [addressType]: {
          ...this.state.formErrors[addressType],
          [name]: ''
        }
      }
    });
  }

  onEditSsn = () => {
    this.setState({
      beneficiaryInfo: {
        ...this.state.beneficiaryInfo,
        BeneficiarySSN1: '',
        BeneficiarySSN2: ''
      },
      editSsn: true,
    });
  }

  onExistingBeneSelect = beneId => {
    const { existingBenesDetails } = this.props;
    if (!beneId) { // if beneId is 0, clear the id and existing info from state
      this.setState({
        autocompleteText: {
          Mailing: '',
          Physical: '',
        },
        beneficiaryInfo: BLANK_BENE_INFO, // sets beneInfo back to empty object
        editSsn: true,
        existingBeneficiaryId: beneId,
      });
    }
    else if (existingBenesDetails[beneId]) { // if bene object is saved in store, use that
      this.setState({
        autocompleteText: {
          Mailing: existingBenesDetails[beneId].Mailing.StreetAddress1,
          Physical: existingBenesDetails[beneId].Physical.StreetAddress1,
        },
        apiErrors: [],
        beneficiaryInfo: existingBenesDetails[beneId],
        editSsn: false,
        existingBeneficiaryId: beneId,
        formErrors: {
          Mailing: {},
          Physical: {},
        },
      });
    }
    else { // fetch bene details from API
      this.props.setLoadingOn();
      this.props.getExistingBeneficiary(beneId)
        .then(() => {
          this.setState({
            autocompleteText: {
              Mailing: this.props.existingBenesDetails[beneId].Mailing.StreetAddress1,
              Physical: this.props.existingBenesDetails[beneId].Physical.StreetAddress1,
            },
            beneficiaryInfo: this.props.existingBenesDetails[beneId],
            editSsn: false,
            existingBeneficiaryId: beneId,
          });
          this.props.setLoadingOff();
        })
        .catch(() => this.props.setLoadingOff());
    }
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    const isValid = this.formValidate();

    const { beneficiaryInfo } = this.state;
    const { msg_bene_info_saved } = this.props.text.BeneficiaryStep;

    const mappedBeneficiary = {
      ...beneficiaryInfo,
      BeneficiaryId: beneficiaryInfo.BeneficiaryId,
      BeneficiaryBirthdate: dayjs(beneficiaryInfo.BeneficiaryBirthdate).format('L'),
      BeneficiaryMailingAddressSameAsOwner: beneficiaryInfo.BeneficiaryMailingAddressSameAsOwner,
      BeneficiaryMailingCity: beneficiaryInfo.Mailing.City,
      BeneficiaryMailingPostalCode: beneficiaryInfo.Mailing.PostalCode,
      BeneficiaryMailingState: beneficiaryInfo.Mailing.State,
      BeneficiaryMailingStreetAddress1: beneficiaryInfo.Mailing.StreetAddress1,
      BeneficiaryMailingStreetAddress2: beneficiaryInfo.Mailing.StreetAddress2,
      BeneficiaryPhysicalAddressSameAsOwner: beneficiaryInfo.BeneficiaryPhysicalAddressSameAsOwner,
      BeneficiaryPhysicalCity: beneficiaryInfo.Physical.City,
      BeneficiaryPhysicalPostalCode: beneficiaryInfo.Physical.PostalCode,
      BeneficiaryPhysicalState: beneficiaryInfo.Physical.State,
      BeneficiaryPhysicalStreetAddress1: beneficiaryInfo.Physical.StreetAddress1,
      BeneficiaryPhysicalStreetAddress2: beneficiaryInfo.Physical.StreetAddress2,
      BeneficiarySSN1: beneficiaryInfo.BeneficiarySSN1,
      BeneficiarySSN2: beneficiaryInfo.BeneficiarySSN2,
    };

    if (isValid) {
      this.props.setLoadingOn();

      this.props.saveBeneficiary(mappedBeneficiary)
        .then(() => {
          this.props.setLoadingOff();
          this.props.notificationShow(msg_bene_info_saved, 'success');
          this.props.onNextClick();
        })
        .catch(errors => {
          this.props.setLoadingOff();
          this.setState({
            apiErrors: errors.payload.data.filter(error => error.Field)
          }, () => this.formValidate());
        });
    }
  }

  onInputChange = (e) => {
    this.setState({
      beneficiaryInfo: {
        ...this.state.beneficiaryInfo,
        [e.target.name]: e.target.value
      },
      apiErrors: [],
      formErrors: {
        ...this.state.formErrors,
        [e.target.name]: ''
      }
    });
  }

  onRelationshipChange = type => {
    const { accountOwnerInfo, BENEFICIARY_RELATIONSHIPS } = this.props;
    const { beneficiaryInfo } = this.state;

    if (BENEFICIARY_RELATIONSHIPS.S === BENEFICIARY_RELATIONSHIPS[type]) { // if they selected Self, copy AO info over
      this.setState({
        autocompleteText: {
          Mailing: accountOwnerInfo.Mailing.StreetAddress1,
          Physical: accountOwnerInfo.Physical.StreetAddress1,
        },
        beneficiaryInfo: {
          BeneficiaryBirthdate: accountOwnerInfo.OwnerBirthdate,
          BeneficiaryFirstName: accountOwnerInfo.OwnerFirstName,
          BeneficiaryIsResident: accountOwnerInfo.OwnerIsResident,
          BeneficiaryLastName: accountOwnerInfo.OwnerLastName,
          BeneficiaryMailingAddressSameAsOwner: true,
          BeneficiaryMiddleName: accountOwnerInfo.OwnerMiddleName,
          BeneficiaryPhysicalAddressSameAsOwner: true,
          BeneficiarySSN1: accountOwnerInfo.OwnerSSN1,
          BeneficiarySSN2: accountOwnerInfo.OwnerSSN2,
          Mailing: accountOwnerInfo.Mailing,
          Physical: accountOwnerInfo.Physical,
          RelationshipToAccountOwner: type,
          encryptedDataExists: accountOwnerInfo.encryptedDataExists,
        },
        editSsn: false,
        formErrors: {
          Mailing: {},
          Physical: {},
        },
      });
    }
    else if (BENEFICIARY_RELATIONSHIPS[beneficiaryInfo.RelationshipToAccountOwner] === BENEFICIARY_RELATIONSHIPS.S && type !== BENEFICIARY_RELATIONSHIPS.S) { // if they are switching from Self, clear form
      this.setState({
        autocompleteText: {
          Mailing: '',
          Physical: '',
        },
        beneficiaryInfo: {
          BeneficiaryBirthdate: '',
          BeneficiaryFirstName: '',
          BeneficiaryIsResident: false,
          BeneficiaryLastName: '',
          BeneficiaryMailingAddressSameAsOwner: false,
          BeneficiaryMiddleName: '',
          BeneficiaryPhysicalAddressSameAsOwner: false,
          BeneficiarySSN1: '',
          BeneficiarySSN2: '',
          Mailing: BLANK_ADDRESS,
          Physical: BLANK_ADDRESS,
          RelationshipToAccountOwner: type,
          encryptedDataExists: false,
        },
        editSsn: true,
      });
    }
    else { // if they are switching between any relationships other than Self, just update the relationship but do not clear the form
      this.setState({
        beneficiaryInfo: { ...beneficiaryInfo, RelationshipToAccountOwner: type },
      });
    }
  }

  preventCopy = (e) => {
    const formErrors = cloneDeep(this.state.formErrors);
    const confirmSsnInput = e.target;

    confirmSsnInput.onpaste = e => {
      e.preventDefault();
      formErrors.BeneficiarySSN2 = this.props.text.BeneficiaryStep.err_copy_ssn;
      this.setState({ formErrors });
    };
  }

  ssnCompose = () => { // Handles encrypted SSN inputs
    const { text: { BeneficiaryStep } } = this.props;
    const { beneficiaryInfo: { BeneficiarySSN1, BeneficiarySSN2, encryptedDataExists }, editSsn, formErrors } = this.state;
    const isDisabled = this.inputIsDisabled();

    if ((encryptedDataExists && !editSsn) || isDisabled) { // if the birthdate is encrypted and user is NOT editing, OR has selected relationship as Self
      return (
        <div className={styles.ssnContainer}>
          <div className={styles.ssnInputs}>
            <TextField
              disabled
              fullWidth
              label={BeneficiaryStep.lbl_ssn_tin}
              name='BeneficiarySSN1'
              value={BeneficiarySSN1}
              variant='filled'
            />
            {!isDisabled && ( // if user selects relationship as Self, hide ability to edit field - that must be done from AO step
              <IconBtnTooltip
                buttonProps={{ style: { marginLeft: '3px' } }}
                icon='create'
                onClick={this.onEditSsn}
              />
            )}
          </div>
          <div className={styles.ssnInputs}>
            <TextField
              disabled
              fullWidth
              label={BeneficiaryStep.lbl_ssn_tin_confirm}
              name='BeneficiarySSN2'
              value={BeneficiarySSN2}
              variant='filled'
            />
            {!isDisabled && ( // if user selects relationship as Self, hide ability to edit field - that must be done from AO step
              <IconBtnTooltip
                buttonProps={{ style: { marginLeft: '3px' } }}
                icon='create'
                onClick={this.onEditSsn}
              />
            )}
          </div>

        </div>
      );
    }
    else if (!encryptedDataExists || editSsn) { // if the user does not have an encrypted ssn, or if they do but are editing
      return (
        <div>
          <SsnInput
            disabled={isDisabled}
            error={Boolean(formErrors.BeneficiarySSN1)}
            helperText={formErrors.BeneficiarySSN1}
            fullWidth
            label={BeneficiaryStep.lbl_ssn_tin}
            onChange={({ value }) => this.onInputChange({ target: { name: 'BeneficiarySSN1', value } })}
            value={BeneficiarySSN1}
            variant='filled'
          />
          <SsnInput
            disabled={isDisabled}
            error={Boolean(formErrors.BeneficiarySSN2)}
            helperText={formErrors.BeneficiarySSN2}
            fullWidth
            label={BeneficiaryStep.lbl_ssn_tin_confirm}
            onChange={({ value }) => this.onInputChange({ target: { name: 'BeneficiarySSN2', value } })}
            onFocus={e => this.preventCopy(e)}
            value={BeneficiarySSN2}
            variant='filled'
          />

        </div>
      );
    }
  }

  utahTaxpayerToggle = e => {
    this.setState({
      beneficiaryInfo: {
        ...this.state.beneficiaryInfo,
        BeneficiaryIsResident: e.target.checked,
      }
    });
  }

  componentDidMount() {
    const { beneficiaryInfo } = this.state;

    if (beneficiaryInfo.encryptedDataExists) {
      this.setState({
        editSsn: false,
      });
    }
  }

  render() {
    const { addressType, addressSuggestions, autocompleteText, beneficiaryInfo, existingBeneficiaryId, formErrors } = this.state;
    const { ADDRESS_TYPES, BENEFICIARY_RELATIONSHIPS, existingBenesList, isAgent_AccessRole, MaximumAge, onBackClick, text: { BeneficiaryStep }, usStates } = this.props;
    const isDisabled = this.inputIsDisabled();
    const addressIsDisabled = this.addressIsDisabled();

    return (
      <Paper>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h1>{BeneficiaryStep.head_beneficiary}</h1>
            <InfoIcon message={<ul>{BeneficiaryStep.text_what_is_bene.map(line => <li key={line}>{line}</li>)}</ul>} />
          </div>
          <form onSubmit={this.onFormSubmit} className={styles.form}>
            <div className={styles.infoContainer}>
              {isAgent_AccessRole &&
                <Dropdown
                  FormControlProps={{ variant: 'filled' }}
                  label={BeneficiaryStep.lbl_existing_bene}
                  onChange={this.onExistingBeneSelect}
                  options={existingBenesList.map(bene => ({ display: bene.Name, value: bene.BeneficiaryId }))}
                  value={beneficiaryInfo.BeneficiaryId ? this.getExistingBeneValue() : 0}
                />
              }
              <Dropdown
                errorText={formErrors.RelationshipToAccountOwner}
                FormControlProps={{ variant: 'filled' }}
                label={BeneficiaryStep.lbl_relationship}
                onChange={this.onRelationshipChange}
                options={Object.keys(BENEFICIARY_RELATIONSHIPS).map(rel => ({ display: BENEFICIARY_RELATIONSHIPS[rel], value: rel }))}
                SelectProps={{ disabled: existingBeneficiaryId !== 0 }}
                value={beneficiaryInfo.RelationshipToAccountOwner}
              />
              <TextField
                autoComplete='given-name'
                disabled={isDisabled}
                error={Boolean(formErrors.BeneficiaryFirstName)}
                variant='filled'
                fullWidth
                helperText={formErrors.BeneficiaryFirstName}
                label={BeneficiaryStep.lbl_firstName}
                name='BeneficiaryFirstName'
                onChange={this.onInputChange}
                value={beneficiaryInfo.BeneficiaryFirstName}
              />
              <TextField
                autoComplete='additional-name'
                disabled={isDisabled}
                error={Boolean(formErrors.BeneficiaryMiddleName)}
                variant='filled'
                fullWidth
                helperText={formErrors.BeneficiaryMiddleName}
                label={BeneficiaryStep.lbl_middleName}
                name='BeneficiaryMiddleName'
                onChange={this.onInputChange}
                value={beneficiaryInfo.BeneficiaryMiddleName}
              />
              <TextField
                autoComplete='family-name'
                disabled={isDisabled}
                error={Boolean(formErrors.BeneficiaryLastName)}
                variant='filled'
                fullWidth
                helperText={formErrors.BeneficiaryLastName}
                label={BeneficiaryStep.lbl_lastName}
                name='BeneficiaryLastName'
                onChange={this.onInputChange}
                value={beneficiaryInfo.BeneficiaryLastName}
              />
              <DatePicker // The label formatting, value and date passed to onChange need to be specific to get the date to clear
                disabled={isDisabled}
                error={Boolean(formErrors.BeneficiaryBirthdate)}
                fullWidth
                helperText={formErrors.BeneficiaryBirthdate}
                variant='filled'
                label={BeneficiaryStep.lbl_birthdate}
                maxDate={dayjs()}
                minDate={dayjs().subtract(MaximumAge, 'year')}
                onChange={value => this.onInputChange({ target: { name: 'BeneficiaryBirthdate', value, } })}
                openTo='year'
                value={beneficiaryInfo.BeneficiaryBirthdate || ''}
              />
              {this.ssnCompose()}
            </div>
            <div className={styles.addressesContainer}>
              <Tabs
                centered
                onChange={this.addressTabChange}
                value={addressType}
              >
                <Tab
                  label={ADDRESS_TYPES.Mailing}
                  style={Object.keys(formErrors.Mailing).some(error => formErrors.Mailing[error] !== '') ? errorTabStyles : {}}
                  value={ADDRESS_TYPES.Mailing}
                />
                <Tab
                  label={ADDRESS_TYPES.Physical}
                  style={Object.keys(formErrors.Physical).some(error => formErrors.Physical[error] !== '') ? errorTabStyles : {}}
                  value={ADDRESS_TYPES.Physical}
                />
              </Tabs>
              {
                addressType === ADDRESS_TYPES.Mailing ?
                  <Checkbox
                    checked={beneficiaryInfo.BeneficiaryMailingAddressSameAsOwner}
                    className={styles.addressCheckbox}
                    color='primary'
                    disabled={isDisabled}
                    label={BeneficiaryStep.lbl_mailing_is_same_as_ao}
                    onChange={this.copyAddress}
                  />
                  :
                  <Checkbox
                    checked={beneficiaryInfo.BeneficiaryPhysicalAddressSameAsOwner}
                    className={styles.addressCheckbox}
                    color='primary'
                    disabled={isDisabled}
                    label={BeneficiaryStep.lbl_physical_is_same_as_ao}
                    onChange={this.copyAddress}
                  />
              }
              <AddressTextFields
                address={beneficiaryInfo[addressType]}
                addressSuggestions={addressSuggestions}
                autocompleteProps={{ disabled: isDisabled || addressIsDisabled }}
                autocompleteSuggestionsGet={this.autocompleteSuggestionsGet}
                autocompleteText={autocompleteText[addressType]}
                errorGet={inputName => this.state.formErrors[this.state.addressType][inputName]}
                inputProps={{ disabled: isDisabled || addressIsDisabled }} // This needs to be disabled in two places - this one disables State select
                isReadOnly={false}
                onAutocompleteAddressSelect={this.autocompleteAddressSelect}
                onInputChange={this.onAddressChange}
                stateFormControlProps={{ variant: 'filled' }}
                textFieldProps={{ disabled: isDisabled || addressIsDisabled, variant: 'filled' }} // This needs to be disabled in two places - this one disables Textfields
                usStates={usStates}
              />
            </div>
            <div className={styles.residentContainer}>
              <Checkbox
                checked={beneficiaryInfo.BeneficiaryIsResident}
                disabled={isDisabled}
                label={BeneficiaryStep.lbl_utah_tax_payer}
                onChange={this.utahTaxpayerToggle}
                color='primary'
              />
            </div>
            <div className={styles.stepperButtons}>
              <Button
                onClick={onBackClick}
                variant='text'
                color='primary'
              >
                {BeneficiaryStep.btn_back}
              </Button>
              <Button
                color='primary'
                onClick={this.onFormSubmit}
                variant='contained'
                type='submit'
              >
                {BeneficiaryStep.btn_continue}
              </Button>
            </div>
          </form>
        </div>
      </Paper>
    );
  }
}


export default connect(select, {
  getExistingBeneficiary,
  notificationShow,
  saveBeneficiary,
})(LanguageHOC(BeneficiaryStep));
