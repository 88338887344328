/*
 *
 * TransactionStepper Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Button,
} from '@mui/material';
import {
  LoadingOverlay,
} from '@frontend/common';

import { sizify } from '@frontend/common';

import styles from './styles.module.scss';
import { desktopWidth } from 'utils/config/_sassconfig.scss';

export class TransactionStepper extends React.Component {
  static propTypes = {
    verifyStep: PropTypes.func.isRequired,
    renderStepComponent: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    stepLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    submitStep: PropTypes.number.isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number.isRequired,
    }).isRequired,
    history: PropTypes.object.isRequired,
    stepHasError: PropTypes.bool.isRequired,
    clearStepErrors: PropTypes.func.isRequired,
    termsChecked: PropTypes.bool,
    renderConfirmModal: PropTypes.func, // can pass in a ConfirmModal that can use this stepper's this.handleSubmit.
    text: PropTypes.shape({
      TransactionStepper: PropTypes.shape({
        btn_agree: PropTypes.string,
        btn_back: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_finish: PropTypes.string,
        btn_next: PropTypes.string,
        btn_print: PropTypes.string,
      })
    })
  };

  state = {
    step: 0,
    completed: {},
    buttonsDisabled: false,
    submitLoading: false,
    stepsDisabled: false,
  };

  isFirstStep = () => {
    return this.state.step === 0;
  };

  isLastStep = () => {
    return this.state.step === this.props.stepLabels.length - 1;
  };

  completedSteps = () => {
    return Object.keys(this.state.completed).length;
  };

  setStep = step => () => {
    if (this.state.completed[step]) this.setState({ step });
  };

  handleBack = () => {
    this.props.clearStepErrors();
    if (!this.isFirstStep()) this.setState(({ step }) => ({ step: step - 1 }));
  };

  handleNext = () => {
    const { clearStepErrors, verifyStep } = this.props;
    clearStepErrors();
    if (verifyStep(this.state.step)) {
      if (!this.isLastStep()) {
        const newCompleted = this.state.completed;
        newCompleted[this.state.step] = true;
        this.setState(({ step }) => ({
          step: step + 1,
          completed: newCompleted
        }));
      }
    }
  };

  getCompletedStepsToThisStep(step, completed, isCompleted) {
    for (let i = 0; i <= step; i++) {
      completed[i] = isCompleted;
    }
    return completed;
  }

  handleSubmit = () => {
    const { step, completed } = this.state;
    const { clearStepErrors, verifyStep, handleSubmit } = this.props;
    clearStepErrors();
    if (verifyStep(step)) {
      this.setState({ submitLoading: true });
      handleSubmit()
        .then(() => {
          // disable all previous completed steps and buttons
          this.setState(({ step, completed }) => ({
            step: step + 1,
            buttonsDisabled: true,
            completed: this.getCompletedStepsToThisStep(step, completed, true),
            stepsDisabled: true,
            submitLoading: false,
          }));
          // after any submit scroll to top
          window.scrollTo(0, 0);
        })
        .catch(() => {
          // re-enable previous step buttons also
          this.setState({
            buttonsDisabled: false,
            completed: this.getCompletedStepsToThisStep(step, completed, true),
            submitLoading: false,
          });
        });
    }
  };

  handleFinish = () => {
    this.props.history.goBack();
  }

  renderButtons = () => {
    const { submitStep, termsChecked = true, text: { TransactionStepper } } = this.props; //terms checked left as true, unless passed in props.
    const { step, buttonsDisabled } = this.state;
    const cancelButton = (
      <Button
        variant='text'
        color='primary'
        key='cancel'
        onClick={this.props.history.goBack}
        style={{ marginRight: '5px' }}
        disabled={buttonsDisabled}
      >
        {TransactionStepper.btn_cancel}
      </Button>
    );
    const backButton = (
      <Button
        key='backButton'
        variant='text'
        color='primary'
        disabled={step === 0 || this.isLastStep() || buttonsDisabled}
        onClick={this.handleBack}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        {TransactionStepper.btn_back}
      </Button>
    );
    const nextButton = (
      <Button
        key='nextButton'
        variant='contained'
        onClick={this.handleNext}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        {TransactionStepper.btn_next}
      </Button>
    );
    const submitButton = (
      <LoadingOverlay
        key='submitButton'
        show={this.state.submitLoading}
      >
        <Button
          variant='contained'
          onClick={this.handleSubmit}
          style={{ marginLeft: '5px', marginRight: '5px' }}
          disabled={buttonsDisabled || !termsChecked}
        >
          {TransactionStepper.btn_agree}
        </Button>
      </LoadingOverlay>
    );
    const finishButton = (
      <Button
        key='finishButton'
        variant='contained'
        onClick={this.handleFinish}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        {TransactionStepper.btn_finish}
      </Button>
    );

    const printButton = (
      <Button
        key='printButton'
        variant='contained'
        color='primary'
        onClick={window.print}
        style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        {TransactionStepper.btn_print}
      </Button>
    );

    let buttons;

    if (submitStep === step) {
      buttons = [
        cancelButton,
        backButton,
        submitButton
      ];
    }
    else if (this.isLastStep()) {
      buttons = [
        printButton,
        finishButton
      ];
    }
    else if (step === 0) {
      buttons = [
        cancelButton,
        nextButton,
      ];
    }
    else {
      buttons = [
        cancelButton,
        backButton,
        nextButton,
      ];
    }

    return buttons;
  }

  render() {
    const { step, completed, stepsDisabled } = this.state;
    const { renderStepComponent, stepLabels, size, stepHasError } = this.props;
    
    return (
      <div className={styles.stepperContentContainer}>
        <div className={styles.stepperStepsContainer}>
          <div className={styles.stepperSteps}>
            <Stepper
              activeStep={step}
              orientation={size.windowWidth <= parseInt(desktopWidth) ? 'vertical' : 'horizontal'}
              className={styles.stepper}
            >
              {stepLabels.map((label, index) => (
                <Step 
                  key={label}
                  completed={completed[index]}
                >
                  <StepButton
                    onClick={this.setStep(index)}
                    disabled={!completed[index] || stepsDisabled}
                  >
                    <StepLabel
                      error={index === step && stepHasError}
                    >
                      {label}
                    </StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>

        <div className={styles.stepperContent}>
          {renderStepComponent(step)}
        </div>

        <div className={styles.stepperButtons}>
          {this.renderButtons()}
        </div>

        {this.props.renderConfirmModal && this.props.renderConfirmModal(this.handleSubmit) /* if the renderConfirmModal exists, then render it. */}
      </div>
    );
  }
}

export default withRouter(sizify(LanguageHOC(TransactionStepper)));
