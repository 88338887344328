/*
*
* UpdateBeneAddressModal Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAccounts } from 'components/Features/protected/Accounts/actions';

import {
  getPreTransactionDetails,
} from 'components/Features/protected/Accounts/Transactions/actions';

import BeneContactForm from 'components/Features/protected/Accounts/AccountDetails/BeneContact/BeneContactForm';

import {
  Modal,
  LoadingOverlay,
} from '@frontend/common';

const select = (state) => ({
  accountList: state.accounts.accountList,
});

export class UpdateBeneAddressModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    getAccounts: PropTypes.func.isRequired,
    accountList: PropTypes.array.isRequired,
    handleCancel: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    getPreTransactionDetails: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    addressType: PropTypes.string,
  };

  state = {
    loading: true,
    account: {},
  };

  callbackOnAddressChange = () => {
    if (this.props.match.params.groupId) { // this will basically check if there is a groupId in the params, which is only the case in Withdrawals.
      this.props.getPreTransactionDetails(this.props.match.params.accountId, this.props.match.params.groupId);
    }
    this.updateAccount();
  }

  accountsGet = () => {
    this.props.accountList.length === 0 && this.setState({ loading: true });
    this.props.getAccounts()
      .finally(() => this.setState({ loading: false }));
  }

  updateAccount = () => {
    const paramAccountId = parseInt(this.props.match.params.accountId);
    const account = this.props.accountList.find(account => account.accountId === paramAccountId);
    this.setState({ account });
  };

  componentDidMount() {
    this.updateAccount();
    this.setState({ loading: false });
  }

  render() {
    const { account, loading } = this.state;

    const {
      show,
      handleCancel,
      modalTitle,
      addressType,
    } = this.props;

    return (
      <div>
        <Modal
          show={show}
          title={modalTitle}
          onCloseModal={handleCancel}
          fullWidth
        >
          {loading ? <LoadingOverlay show={loading} indicatorHeight='15px' />
            :
            <BeneContactForm
              accountsGet={this.accountsGet}
              addresses={account.beneficiary.addresses}
              beneficiaryId={account.beneficiary.beneficiaryId}
              key={account.beneficiary.beneficiaryId}
              callbackOnAddressChange={this.callbackOnAddressChange}
              permissions={account.permissions}
              addressType={addressType}
            />
          }
        </Modal>
      </div>

    );
  }

}

export default withRouter(connect(select, {
  getAccounts,
  getPreTransactionDetails,
})(UpdateBeneAddressModal));