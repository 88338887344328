/*
*
* StaticResources Actions
*
*/
import {
  ENVIRONMENT_VARIABLES_GET,
  PASSWORD_REQUIREMENTS_GET,
  STATIC_DOCUMENTS_GET,
  TMX_ENVIRONMENT_VARIABLES_GET,
  SERVER_DATETIME_GET,
  WEB_MESSAGES_GET,
  SET_LOADING_PUBLIC_VARS,
} from './constants';

import { GETrequest } from 'utils/helpers/api_handler';


export function passwordRequirementsGet() {
  const response = GETrequest('/authentication/passwordrequirements');
  return {
    type: PASSWORD_REQUIREMENTS_GET,
    payload: response,
  };
}

export function getEnvironmentVars(params) {
  return {
    type: ENVIRONMENT_VARIABLES_GET,
    payload: params ? GETrequest(`/Parameters?name=${params.join('&name=')}`) : GETrequest('/Parameters'),
  };
}

export function getThreatMetrixVars() {
  return {
    type: TMX_ENVIRONMENT_VARIABLES_GET,
    payload: GETrequest('/parameters/ThreatMetrixParameters'),
  };
}

export function getStaticDocuments() {
  const payload = GETrequest('/forms');
  return {
    type: STATIC_DOCUMENTS_GET,
    payload,
  };
}

export function getServerDateTime() {
  const payload = GETrequest('/parameters/serverdatetime');
  return {
    type: SERVER_DATETIME_GET,
    payload,
  };
}

export function getWebMessages() {
  return {
    type: WEB_MESSAGES_GET,
    payload: GETrequest('/WebsiteMessage'),
  };
}

export function setLoadingPublicVars(isLoading) {
  return {
    type: SET_LOADING_PUBLIC_VARS,
    payload: { isLoading },
  };
}