import qs from 'qs';
import dayjs from 'dayjs';

const queryParser = (search) => {
  const filters = {
    accounts: [],
    documents: [],
  };
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  if (query) {
    if ('account' in query) {
      if (Array.isArray(query.account)) {
        const accountFilters = query.account.map(account => parseInt(account));
        filters.accounts.push(...accountFilters);
      }
      if (typeof query.account === 'string') {
        filters.accounts.push(parseInt(query.account));
      }
    }
    if ('document' in query) {
      if (Array.isArray(query.document)) {
        filters.documents.push(...query.document);
      }
      if (typeof query.document === 'string') {
        filters.documents.push(query.document);
      }
    }
  }
  return filters;
};


const filtersToSearchString = (filters) => {
  return qs.stringify({
    account: filters.accounts,
    document: filters.documents,
  }, { indices: false });
};


const documentsByQueryFilters = (documents, filters) => {
  let filteredDocumentList = documents;

  // filters to documents with a matching AccountNumber.
  if (filters.accounts.length > 0) {
    filteredDocumentList = filteredDocumentList.filter(document => filters.accounts.some(accountNumber => accountNumber === document.AccountNumber));
  }

  // filters to documents with a matching DocumentType.
  if (filters.documents.length > 0) {
    filteredDocumentList = filteredDocumentList.filter(document => filters.documents.some(documentType => documentType === document.DocumentType));
  }

  return filteredDocumentList;
};


const transactionsByDateFilter = (transactions, dateFilters) => {
  const { startDate, endDate } = dateFilters;

  return transactions.filter(transaction => (
    dayjs(transaction.date).isSameOrAfter(dayjs(startDate), 'day') && dayjs(transaction.date).isSameOrBefore(dayjs(endDate), 'day'))
  );
};

const accountsByFilter = (accountList, filters) => {
  let filteredAccounts = accountList;

  if (filters.length > 0) {
    filteredAccounts = filteredAccounts.filter(account => (
      filters.some(filter => filter === account.accountNumber) ||
      filters.some(filter => account.beneficiaryName ? filter === account.beneficiaryName : filter === account.beneficiary.Name) || //dashboardAccountList has different structure
      filters.some(filter => account.optionName ? filter === account.optionName : filter === account.Option.Name) //dashboardAccountList has different structure
    ));
  }

  return filteredAccounts;
};

export {
  queryParser,
  filtersToSearchString,
  documentsByQueryFilters,
  transactionsByDateFilter,
  accountsByFilter,
};