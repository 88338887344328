/*
*
* Transactions Actions
*
*/
import {
  GETrequest,
  POSTrequest,
  PUTrequest,
  DELETErequest,
} from 'utils/helpers/api_handler';
import {
  startCase
} from 'lodash';
import {
  GET_GROUP_ACCOUNTS,
  ACCOUNT_TRANSACTION_PREVIEW,
  CLOSE_TRANSACTION_LOADER,
  OPEN_TRANSACTION_LOADER,
  SET_PREFLIGHT_PARAMS,
  GET_TRANSFER_ACCOUNTS,
  GET_SCHEDULED_TRANSACTION,
  TRANSACTION_DELETE,
  TRANSFER_CREATE,
  TRANSFER_UPDATE,
  GET_PRE_TRANSACTION_DETAILS,
  GET_CONTRIBUTION_DETAILS,
  CONTRIBUTION_CREATE,
  CONTRIBUTION_EDIT,
  SCHEDULE_RUN_DATES_GET,
  NEXT_RUN_DATE_GET,
  WITHDRAWAL_CREATE,
  WITHDRAWAL_UPDATE,
  SCHOOLS_GET,
  SET_WITHDRAWAL_SUBMITTED,
  BIRTHDATE_VERIFY,
  REQUEST_WITHDRAWAL,
  GET_ELECTRONICPAYMENT_SCHOOL,
} from './constants';

export function getGroupAccounts() {
  const response = GETrequest('/accountgroups');
  return {
    type: GET_GROUP_ACCOUNTS,
    payload: response,
  };
}

export function transactionPreview(accountId, transactionType) {
  const payload = GETrequest(`/accounts/${accountId}/preview?type=${transactionType}`);
  return {
    type: ACCOUNT_TRANSACTION_PREVIEW,
    meta: { transactionType },
    payload,
  };
}

export function openPreflightModal() {
  return {
    type: OPEN_TRANSACTION_LOADER
  };
}

export function closeTransactionLoader() {
  return {
    type: CLOSE_TRANSACTION_LOADER
  };
}

export function setPreflightParams(account, transactionType) {
  return {
    type: SET_PREFLIGHT_PARAMS,
    account,
    transactionType
  };
}

export function getTransferAccounts(accountId) {
  const payload = GETrequest(`/accounts/${accountId}/transferaccounts`);
  return {
    type: GET_TRANSFER_ACCOUNTS,
    payload
  };
}

export function getScheduledTransaction(transaction) {
  //this is a generic get call that will get any full transaction details based on the URL attached to upcoming transaction object
  // for option change type it needs to include zero allocations
  const optionChangeParameter = transaction.TransactionType === 'Option Change' ? '?includeZeroAllocations=true' : '';
  const response = GETrequest(`${transaction.URL}${optionChangeParameter}`);
  return {
    type: GET_SCHEDULED_TRANSACTION,
    payload: response,
    meta: { type: transaction.TransactionType, AccountId: transaction.AccountId }
  };
}

export function deleteTransaction(transaction) {
  const response = DELETErequest(transaction.URL);
  return {
    type: TRANSACTION_DELETE,
    payload: response,
  };
}

export function createNewTransfer(payload) {
  const response = POSTrequest('/transactions/transfers', payload);
  return {
    type: TRANSFER_CREATE,
    payload: response,
  };
}

export function updateTransfer(transfer) {
  const response = PUTrequest(`/transactions/transfers/${transfer.TransferId}`, transfer);
  return {
    type: TRANSFER_UPDATE,
    payload: response,
  };
}

export function getPreTransactionDetails(accountId, accountGroupId) {
  const response = GETrequest(`/accountgroups/${accountGroupId}`);
  return {
    type: GET_PRE_TRANSACTION_DETAILS,
    meta: { accountId },
    payload: response,
  };
}

export function getContributionDetails(accountId, accountList, agentBankAccounts, isEditing, MaximumBeneficiaryDeposit) {
  return {
    type: GET_CONTRIBUTION_DETAILS,
    accountId,
    accountList,
    agentBankAccounts,
    isEditing,
    MaximumBeneficiaryDeposit,
  };
}

export function createContribution(newContribution) {
  const payload = POSTrequest('/transactions/ContributionSchedules', newContribution);
  return {
    type: CONTRIBUTION_CREATE,
    payload,
  };
}

export function editContribution(transactionId, editedContribution) {
  const payload = PUTrequest(`/transactions/ContributionSchedules/${transactionId}`, editedContribution);
  return {
    type: CONTRIBUTION_EDIT,
    payload,
  };
}

export function getScheduleRunDates(dates, frequency) {
  const datesPath = dates.map(date => `start=${date}`).join('&');
  const payload = GETrequest(`/Transactions/ScheduleRunDates?${datesPath}&frequency=${frequency}`);
  return {
    type: SCHEDULE_RUN_DATES_GET,
    payload,
    meta: dates,
  };
}

export function getNextRunDate(dates, day, frequency) {
  let datePath;

  function formatDate(date) {
    return new Date(date).toISOString().split('T')[0]; // eslint-disable-line
  }

  if (dates instanceof Array) {
    const joinedDates = dates.map(date => `start=${formatDate(date)}`).join('&'); // eslint-disable-line
    datePath = `?${joinedDates}&`;
  }
  else {
    datePath = `/${formatDate(dates)}?`;
  }

  const response = GETrequest(`/Transactions/ScheduleRunDates${datePath}frequency=${startCase(frequency)}`);

  return {
    type: NEXT_RUN_DATE_GET,
    payload: response,
    meta: { day, frequency }
  };
}

export function createNewWithdrawal(data) {
  const response = POSTrequest('/transactions/WithdrawalSchedules', data);
  return {
    type: WITHDRAWAL_CREATE,
    payload: response
  };
}

export function updateWithdrawal(scheduleId, data) {
  const response = PUTrequest(`/transactions/WithdrawalSchedules/${scheduleId}`, data);
  return {
    type: WITHDRAWAL_UPDATE,
    payload: response
  };
}

export function getSchools() {
  const response = GETrequest('/EducationalInstitutions');
  return {
    type: SCHOOLS_GET,
    payload: response,
  };
}

export function getElectronicSchoolPaymentSchool(ElectronicSchoolPaymentSchoolId) {
  const response = GETrequest(`/EducationalInstitutions/ElectronicSchoolPaymentSchool/${ElectronicSchoolPaymentSchoolId}`);
  return {
    type: GET_ELECTRONICPAYMENT_SCHOOL,
    payload: response,
  };
}

export function setWithdrawalSubmitted(withdrawalSubmitted) {
  return {
    type: SET_WITHDRAWAL_SUBMITTED,
    payload: withdrawalSubmitted,
  };
}

export function requestWithdrawal(info) {
  const response = POSTrequest('/transactions/WithdrawalSchedules/CreateWithdrawalRequest', info);
  return {
    type: REQUEST_WITHDRAWAL,
    payload: response
  };
}

export function verifyBirthdate(info) {
  const response = POSTrequest('/Authentication/BirthdayVerification', info);
  return {
    type: BIRTHDATE_VERIFY,
    payload: response
  };
}