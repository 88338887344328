/*
*
* MyInfo Reducers
*
*/
import { cloneDeep, pick } from 'lodash';

import {
  AGENT_GET,
  CHANGE_ADDRESS,
  GET_DELIVERY_METHODS,
  CHANGE_DELIVERY_METHODS,
  CHANGE_EMAIL,
  CHANGE_PHONE_NUMBERS,
  DELETE_ADDRESS,

  OFFICIAL_NOTIFICATION,
  TAXFORMS_NOTIFICATION,
  NEWSLETTER_NOTIFICATION,
  PROMO_NOTIFICATION,
  BDAY_NOTIFICATION,
  UTAH_RESIDENT_CHANGE,
} from './constants';

import { BLANK_ADDRESS } from 'components/AppRoot/StaticResources/constants';

const initialState = {
  agent: {
    AgentId: 0,
    Username: '',
    Addresses: {
      Physical: BLANK_ADDRESS,
      Mailing: BLANK_ADDRESS,
      Other: BLANK_ADDRESS
    },
    EmailAddress: '',
    FirstName: '',
    LastName: '',
    MiddleName: '',
    PhoneNumbers: {
      Cell: { Type: 'Cell', Number: '', MaskType: '' },
      Fax: { Type: 'Fax', Number: '', MaskType: '' },
      Home: { Type: 'Home', Number: '', MaskType: '' },
      Other: { Type: 'Other', Number: '', MaskType: '' },
      Work: { Type: 'Work', Number: '', MaskType: '' },
    },
    
    OfficialCommunications: 'None',
    Newsletter: 'None',
    Promotional: 'None',
    TaxForms: 'None',
    BirthdayNotifications: 'None',
  },
  DeliveryMethods: []
};

export function formatPhoneNumbers(numbers) {
  return {
    Cell: numbers.find(phone => phone.Type === 'Cell') || initialState.agent.PhoneNumbers.Cell,
    Fax: numbers.find(phone => phone.Type === 'Fax') || initialState.agent.PhoneNumbers.Fax,
    Home: numbers.find(phone => phone.Type === 'Home') || initialState.agent.PhoneNumbers.Home,
    Other: numbers.find(phone => phone.Type === 'Other') || initialState.agent.PhoneNumbers.Other,
    Work: numbers.find(phone => phone.Type === 'Work') || initialState.agent.PhoneNumbers.Work,
  };
}

function MyInfoReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case AGENT_GET: {
      const agent = action.payload.data;
      newState.agent = {
        Addresses: {
          Physical: agent.PhysicalAddress ? pick(agent.PhysicalAddress, Object.keys(BLANK_ADDRESS)) : BLANK_ADDRESS,
          Mailing: agent.MailingAddress ? pick(agent.MailingAddress, Object.keys(BLANK_ADDRESS)) : BLANK_ADDRESS,
          Other: agent.OtherAddress ? pick(agent.OtherAddress, Object.keys(BLANK_ADDRESS)) : BLANK_ADDRESS
        },
        AgentId: agent.AgentId,
        EmailAddress: agent.EmailAddress ? agent.EmailAddress : '',
        FirstName: agent.FirstName,
        LastName: agent.LastName,
        MiddleName: agent.MiddleName,
        PhoneNumbers: formatPhoneNumbers(agent.PhoneNumbers),
        Username: agent.UserName,
        
        OfficialCommunications: agent.OfficialCommunications,
        Newsletter: agent.Newsletter,
        Promotional: agent.Promotional,
        TaxForms: agent.TaxForms,
        BirthdayNotifications: agent.BirthdayNotifications,
        UtahResident: agent.UtahResident,
      };
      return newState;
    }

    case CHANGE_ADDRESS: {
      const updatedAddress = action.payload.data;

      newState.agent = {
        ...newState.agent,
        Addresses: {
          ...newState.agent.Addresses,
          [updatedAddress.Type]: pick(updatedAddress, Object.keys(BLANK_ADDRESS))
        }
      };
      return newState;
    }

    case CHANGE_EMAIL: {
      newState.agent = {
        ...newState.agent,
        EmailAddress: action.payload.data
      };
      return newState;
    }

    case CHANGE_PHONE_NUMBERS: {
      newState.agent = {
        ...newState.agent,
        PhoneNumbers: formatPhoneNumbers(action.payload.data),
      };
      return newState;
    }

    case DELETE_ADDRESS: {
      newState.agent = {
        ...newState.agent,
        Addresses: {
          ...newState.agent.Addresses,
          [action.meta.addressType]: BLANK_ADDRESS
        }
      };
      return newState;
    }

    case GET_DELIVERY_METHODS:
    case CHANGE_DELIVERY_METHODS: {
      newState.DeliveryMethods = action.payload.data.DeliveryMethods;
      return newState;
    }

    case OFFICIAL_NOTIFICATION: {
      newState.agent = {
        ...newState.agent,
        OfficialCommunications: action.payload.data
      };
      return newState;
    }

    case TAXFORMS_NOTIFICATION: {
      newState.agent = {
        ...newState.agent,
        TaxForms: action.payload.data
      };
      return newState;
    }

    case NEWSLETTER_NOTIFICATION: {
      newState.agent = {
        ...newState.agent,
        Newsletter: action.payload.data
      };
      return newState;
    }

    case PROMO_NOTIFICATION: {
      newState.agent = {
        ...newState.agent,
        Promotional: action.payload.data
      };
      return newState;
    }

    case BDAY_NOTIFICATION: {
      newState.agent = {
        ...newState.agent,
        BirthdayNotifications: action.payload.data
      };
      return newState;
    }

    case UTAH_RESIDENT_CHANGE: {
      newState.agent = {
        ...newState.agent,
        UtahResident: action.payload.data
      };
      return newState;
    }

    default:
      return state;
  }
}

export default MyInfoReducer;