/*
*
* PayrollContribution Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import parse from 'html-react-parser';
import * as validator from 'utils/helpers/form_validation';

import {
  Button,
  InputAdornment,
  TextField,
} from '@mui/material';

import {
  LoadingOverlay,
  Card,
  CurrencyInput,
  BaseNumberInput,
  currencyFormatter,
  Checkbox,
  notificationShow,
  loadingAnimationHTML,
  createPayrollPdfToNewWindow,
  Dropdown,
} from '@frontend/common';

import {
  getPayroll,
  updatePayroll,
} from 'components/Features/protected/PayrollContribution/actions';

import { getNotifications, } from 'components/AppRoot/Navigation/actions';
import { AMOUNT, PERCENTAGE } from 'components/Features/protected/PayrollContribution/constants';

import Instructions from './Instructions';
import PayrollTerms from './PayrollTerms';
import AddNonAgentAccount from './AddNonAgentAccount';
import SkeletonLoader from 'utils/components/SkeletonLoader';

import styles from './styles.module.scss';

const select = (state) => ({
  userDetails: state.session.userDetails,
  payrollContributionData: state.payrollContribution.payrollContributionData,
  usStates: state.static.usStates,
  agentMailingAddress: state.myInfo.agent.Addresses.Mailing,
  accountList: state.accounts.accountList.filter(account => account.permissions.Contribute),
});

// eslint-disable-next-line react/prop-types
function OptionButton({ label, value, active, disabled, onChange, style }) {
  return (
    <Button
      variant='contained'
      style={{
        color: active ? '#FFFFFF' : 'var(--primary)',
        backgroundColor: active ? 'var(--primary)' : '#FFFFFF',
        ...style,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        width: '100%',
      }}
      disabled={disabled}
      className={active ? 'active' : undefined}
      onClick={() => onChange(value)}
    >
      {label}
    </Button>
  );
}

export function Divider() {
  return (<hr className={styles.divider} />);
}

export class PayrollContribution extends React.Component {
  static propTypes = {
    getNotifications: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    agentMailingAddress: PropTypes.object.isRequired,
    accountList: PropTypes.array.isRequired,
    payrollContributionData: PropTypes.object.isRequired,
    usStates: PropTypes.array.isRequired,
    getPayroll: PropTypes.func.isRequired,
    updatePayroll: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    text: PropTypes.shape({
      PayrollContribution: PropTypes.shape({
        btn_add_account: PropTypes.string,
        btn_agree: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_edit: PropTypes.string,
        btn_finish: PropTypes.string,
        btn_instructions: PropTypes.string,
        btn_print: PropTypes.string,
        btn_terms: PropTypes.string,
        err_required: PropTypes.string,
        err_total_amount: PropTypes.string,
        err_total_percent: PropTypes.string,
        head_account_number: PropTypes.string,
        head_beneficiary: PropTypes.string,
        head_other_accounts: PropTypes.string,
        head_own_accounts: PropTypes.string,
        lbl_employee: PropTypes.string,
        lbl_employer: PropTypes.string,
        lbl_pay_period_contribution: PropTypes.string,
        lbl_terms_agree: PropTypes.string,
        lbl_state: PropTypes.string,
        msg_account_added: PropTypes.string,
        msg_payroll_saved: PropTypes.string,
        msg_payroll_updated: PropTypes.string,
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
        text_current_allocations: PropTypes.string,
        text_important_bank_info: PropTypes.string,
        text_total: PropTypes.string,
        title_amount: PropTypes.string,
        title_employer: PropTypes.string,
        title_payroll_account: PropTypes.string,
        title_payroll_account_type: PropTypes.string,
        title_payroll_routing: PropTypes.string,
      })
    })
  }

  state = {
    apiErrors: [],
    isNewContribution: true,
    isEditing: false,
    isLoading: false,
    submitLoading: false,
    statementLoading: false,
    termsOpen: false,
    instructionsOpen: false,
    addAccountModalOpen: false,
    contributionInputType: AMOUNT,
    payPeriodContributionAmount: '',
    termsChecked: false,
    payrollData: {
      Employer: '',
      PayrollAccountAllocations: [],
      EmployerState: ''
    },
    totalAmount: 0,
    totalPercentage: 0,
    formErrors: {},
    showReview: false,
  }

  employerHandle = event => {
    const payrollData = cloneDeep(this.state.payrollData);
    payrollData.Employer = event.target.value;
    this.setState({
      payrollData,
      formErrors: { ...this.state.formErrors, employer: '' }
    });
  }

  contributionTypeHandle = type => {
    type === AMOUNT ? this.updateAmountWithPercentages() : this.updatePercentageWithAmounts();
    this.setState({ contributionInputType: type });
  }

  payPeriodAmountHandle = floatValue => {
    this.setState({
      payPeriodContributionAmount: floatValue,
      formErrors: { ...this.state.formErrors, payPeriodContributionAmount: '' }
    }, () => this.updateAmountWithPercentages());
  }

  amountHandle = ({ floatValue = 0 }, rowIndex) => {
    const { payPeriodContributionAmount } = this.state;
    const payrollData = cloneDeep(this.state.payrollData);
    payrollData.PayrollAccountAllocations[rowIndex].Amount = floatValue;

    if (payPeriodContributionAmount) {
      payrollData.PayrollAccountAllocations[rowIndex].Percentage = (floatValue / payPeriodContributionAmount) * 100;
    }

    const totalAmount = this.getTotalAmount(payrollData);
    const totalPercentage = this.getTotalPercentage(payrollData);
    this.setState({
      payrollData,
      totalAmount,
      totalPercentage,
      formErrors: { ...this.state.formErrors, totalAmount: '', totalPercentage: '' }
    });
  }

  percentHandle = (value, rowIndex) => {
    const { payPeriodContributionAmount } = this.state;
    const payrollData = cloneDeep(this.state.payrollData);
    payrollData.PayrollAccountAllocations[rowIndex].Percentage = value;

    if (payPeriodContributionAmount) {
      payrollData.PayrollAccountAllocations[rowIndex].Amount = (value / 100) * payPeriodContributionAmount;
    }

    const totalAmount = this.getTotalAmount(payrollData);
    const totalPercentage = this.getTotalPercentage(payrollData);
    this.setState({
      payrollData,
      totalAmount,
      totalPercentage,
      formErrors: { ...this.state.formErrors, totalAmount: '', totalPercentage: '' }
    });
  }

  stateSelectHandle = (value) => {
    this.setState({
      formErrors: { ...this.state.formErrors, stateCode: '' },
      payrollData: { ...this.state.payrollData, EmployerState: value },
    });
  }

  updateAmountWithPercentages = () => {
    const { payPeriodContributionAmount } = this.state;
    const payrollData = cloneDeep(this.state.payrollData);

    if (payPeriodContributionAmount) {
      payrollData.PayrollAccountAllocations.map(payrollAccount => {
        return payrollAccount.Amount = payrollAccount.Percentage ? parseFloat(((payrollAccount.Percentage / 100) * payPeriodContributionAmount).toFixed(2)) : 0;
      });
    }
    else {
      payrollData.PayrollAccountAllocations.map(payrollAccount => {
        return payrollAccount.Amount = 0;
      });
    }
    const totalAmount = this.getTotalAmount(payrollData);
    this.setState({ payrollData, totalAmount });
  }

  updatePercentageWithAmounts = () => {
    const { payPeriodContributionAmount } = this.state;
    const payrollData = cloneDeep(this.state.payrollData);

    if (payPeriodContributionAmount) {
      payrollData.PayrollAccountAllocations.map(payrollAccount => {
        return payrollAccount.Percentage = payrollAccount.Amount ? ((payrollAccount.Amount / payPeriodContributionAmount) * 100) : 0;
      });
    }
    else {
      payrollData.PayrollAccountAllocations.map(payrollAccount => {
        return payrollAccount.Percentage = 0;
      });
    }
    const totalPercentage = this.getTotalPercentage(payrollData);
    this.setState({ payrollData, totalPercentage });
  }

  getTotalAmount = payrollData => {
    return payrollData.PayrollAccountAllocations.reduce((total, { Amount }) => total + Amount, 0);
  }

  getTotalPercentage = payrollData => {
    return payrollData.PayrollAccountAllocations.reduce((total, { Percentage = 0 }) => total + parseFloat(Percentage), 0);
  }

  handleSubmit = () => {
    const { msg_payroll_saved, msg_payroll_updated } = this.props.text.PayrollContribution;
    const notificationMessage = this.state.isEditing ? msg_payroll_updated : msg_payroll_saved;
    const isValid = this.formValidate();
    if (isValid) {
      this.setState({ submitLoading: true });
      this.props.updatePayroll(this.state.payrollData)
        .then(() => {
          // response is updated payrollContributionData so it will get updated in state
          this.setState({
            submitLoading: false,
            payrollData: this.props.payrollContributionData,
            contributionInputType: AMOUNT,
            showReview: true,
            isEditing: false,
            isNewContribution: Boolean(!this.props.payrollContributionData.EffectiveDate),
          });
          this.props.notificationShow(notificationMessage, 'success');
          this.props.getNotifications(); // refreshes app notifications
        })
        .catch(err => {
          this.setState({
            apiErrors: err.payload.data,
            submitLoading: false,
          }, () => this.formValidate());
        });
    }
  }

  formValidate = () => {
    const { PayrollContribution } = this.props.text;
    const {
      apiErrors, payrollData: { Employer, EmployerState }, totalAmount, totalPercentage,
      payPeriodContributionAmount, contributionInputType,
    } = this.state;
    const formErrors = {};

    if (!Employer) {
      formErrors.employer = PayrollContribution.err_required;
    }

    if (contributionInputType === PERCENTAGE && !payPeriodContributionAmount) {
      formErrors.payPeriodContributionAmount = PayrollContribution.err_required;
    }
    if (contributionInputType === PERCENTAGE && totalPercentage.toFixed(2) !== '100.00') {
      formErrors.totalPercentage = PayrollContribution.err_total_percent;
    }

    if (!totalAmount) {
      formErrors.totalAmount = PayrollContribution.err_total_amount;
    }

    const stateError = validator.stateValidator(EmployerState);
    if (stateError) {
      formErrors.stateCode = stateError;
    }

    if (apiErrors.length > 0) {
      apiErrors.forEach(err => {
        if (err.Field.toLowerCase() === 'employer') {
          formErrors.employer = err.Message;
          window.scrollTo(0, 0);
        }
        else {
          formErrors[err.Field] = err.Message;
        }
      });
    }

    this.setState({ apiErrors: [], formErrors });

    return Object.keys(formErrors).length === 0;
  }

  handleAddNonAgentAccount = verifiedAccount => {
    const payrollData = cloneDeep(this.state.payrollData);
    const isAlreadyAdded = payrollData.PayrollAccountAllocations.some(account => account.AccountId === verifiedAccount.AccountId);
    if (!isAlreadyAdded) {
      payrollData.PayrollAccountAllocations.push(verifiedAccount);
      this.props.notificationShow(this.props.text.PayrollContribution.msg_account_added, 'success');
    }
    this.setState({ payrollData, hasNotOwnedAccounts: true, });
  }

  viewStatementHandle() {
    const { agentMailingAddress, userDetails } = this.props;
    const { payrollData, totalAmount } = this.state;

    const data = {
      ...payrollData,
      totalAmount,
      address: agentMailingAddress,
      agentName: userDetails.name,
    };
    const pdfWindow = window.open('', '_blank'); // needs to be outside of async call to work in Safari
    pdfWindow.document.write(loadingAnimationHTML); // this will get replaced by generated pdf once the api loads all data

    createPayrollPdfToNewWindow(data, pdfWindow);
  }

  renderEmployeeSection = () => {
    const { text: { PayrollContribution }, usStates, userDetails } = this.props;
    const {
      isLoading, payrollData, formErrors, contributionInputType,
      payPeriodContributionAmount
    } = this.state;

    return (
      <div className={styles.employeeSection}>
        <div className={styles.employeeNameSection}>
          <p className={styles.topLabel}>{PayrollContribution.lbl_employee}</p>
          <p className={styles.employeeName}>{userDetails.name.toUpperCase()}</p>
        </div>

        <div className={styles.employerInput}>
          <TextField
            variant='filled'
            label={PayrollContribution.lbl_employer}
            name='employer'
            error={Boolean(formErrors.employer)}
            helperText={formErrors.employer}
            onChange={e => this.employerHandle(e)}
            value={payrollData.Employer || ''}
            style={{ width: '100%' }}
            disabled={isLoading}
            inputProps={{
              maxLength: 40
            }}
          />
        </div>

        <div className={styles.payPeriodContributionInput}>
          {contributionInputType === PERCENTAGE &&
            <CurrencyInput
              label={PayrollContribution.lbl_pay_period_contribution}
              error={Boolean(formErrors.payPeriodContributionAmount)}
              errorText={formErrors.payPeriodContributionAmount}
              variant='filled'
              onChange={({ floatValue }) => this.payPeriodAmountHandle(floatValue)}
              value={payPeriodContributionAmount || ''}
              style={{ width: '100%', }}
              inputProps={{
                maxLength: '10',
                decimalScale: 2,
                fixedDecimalScale: true,
                thousandSeparator: true,
                allowNegative: false,
              }}
            />
          }
        </div>

        <div className={styles.stateInput}>
          <Dropdown
            FormControlProps={{ 'variant': 'filled' }}
            label={PayrollContribution.lbl_state}
            options={usStates.map(state => ({ value: state.Code, display: state.Name }))}
            errorText={formErrors.stateCode}
            error={Boolean(formErrors.stateCode)}
            value={payrollData.EmployerState || ''}
            onChange={this.stateSelectHandle}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: { maxHeight: '300px' }
                }
              }
            }}
            disabled={isLoading}

          />
        </div>
      </div>
    );
  }

  renderReviewDetails = () => {
    const { text: { PayrollContribution }, userDetails } = this.props;
    const {
      payrollData: {
        Employer,
        PayrollBankAccountNumber,
        PayrollBankAccountType,
        PayrollRoutingNumber,
      },
      totalAmount,
      showReview,
    } = this.state;
    return (
      <div className={styles.reviewDetails}>
        {!showReview && <div className={styles.currentAllocationText}>{PayrollContribution.text_current_allocations}</div>}

        <div className={styles.reviewEmployee}>
          <p className={styles.topLabel}>{PayrollContribution.lbl_employee}</p>
          <p className={styles.reviewDetail}>{userDetails.name.toUpperCase()}</p>
        </div>

        <div className={styles.reviewEmployer}>
          <p className={styles.topLabel}>{PayrollContribution.title_employer}</p>
          <p className={styles.reviewDetail}>{Employer}</p>
        </div>

        <div className={styles.reviewAmount}>
          <p className={styles.topLabel}>{PayrollContribution.title_amount}</p>
          <p className={styles.reviewDetail}>{currencyFormatter(totalAmount)}</p>
        </div>

        <div className={styles.reviewRouting}>
          <p className={styles.topLabel}>{PayrollContribution.title_payroll_routing}</p>
          <p className={styles.reviewDetail}>{PayrollRoutingNumber}</p>
        </div>

        <div className={styles.reviewBankAccount}>
          <p className={styles.topLabel}>{PayrollContribution.title_payroll_account}</p>
          <p className={styles.reviewDetail}>{PayrollBankAccountNumber}</p>
        </div>

        <div className={styles.reviewBankAccountType}>
          <p className={styles.topLabel}>{PayrollContribution.title_payroll_account_type}</p>
          <p className={styles.reviewDetail}>{PayrollBankAccountType}</p>
        </div>
      </div>
    );
  }

  renderPayrollAccountList = () => {
    const { accountList, text: { PayrollContribution }, } = this.props;
    const {
      isNewContribution, isEditing, hasNotOwnedAccounts, payrollData: { PayrollAccountAllocations },
      contributionInputType, totalAmount, totalPercentage, isLoading, formErrors,
    } = this.state;

    return (
      <div className={styles.payrollAccounts}>

        <div className={styles.headers}>
          <div className={styles.accountNumberHeader}>{PayrollContribution.head_account_number}</div>
          <div className={styles.beneficiaryNameHeader}>{PayrollContribution.head_beneficiary}</div>
          <div className={styles.inputsHeader}>
            <div className={styles.typeInputButton}>
              <OptionButton
                active={contributionInputType === AMOUNT}
                disabled={isLoading}
                label='$'
                onChange={() => this.contributionTypeHandle(AMOUNT)}
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              />
            </div>
            <div className={styles.typeInputButton}>
              <OptionButton
                active={contributionInputType === PERCENTAGE}
                disabled={isLoading}
                label='%'
                onChange={() => this.contributionTypeHandle(PERCENTAGE)}
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              />
            </div>
          </div>
        </div>

        <Divider />

        {isLoading
          ? (
            accountList.map(row => (
              <div key={row.accountId} className={styles.payrollAccountRow} style={{ margin: '5px 0' }}>
                <div className={styles.accountNumber}><SkeletonLoader height={45} /></div>
                <div className={styles.beneficiaryName}><SkeletonLoader height={45} /></div>
                <div className={styles.inputs}>
                  <SkeletonLoader height={45} />
                </div>
              </div>
            ))
          ) : (
            <div>
              {hasNotOwnedAccounts && <h4 className={styles.accountListLabels}>{PayrollContribution.head_own_accounts}</h4>}
              <div className={styles.payrollAccountList}>
                {PayrollAccountAllocations.map((row, rowIndex) => (
                  row.OwnedAccount && <div key={row.AccountId} className={styles.payrollAccountRow}>
                    <div className={styles.accountNumber}>{`xxxxxx${row.AccountNumber.toString().slice(row.AccountNumber.toString().length - 3)}`}</div>
                    <div className={styles.beneficiaryName}>{row.BeneficiaryName.toUpperCase()}</div>
                    <div className={styles.inputs}>
                      {contributionInputType === AMOUNT ?
                        this.renderDollarInputs(row, rowIndex)
                        :
                        this.renderPercentageInputs(row, rowIndex)
                      }
                    </div>
                  </div>
                ))}
                {hasNotOwnedAccounts &&
                  <>
                    <Divider />
                    <h4 className={styles.accountListLabels}>{PayrollContribution.head_other_accounts}</h4>
                    {PayrollAccountAllocations.map((row, rowIndex) => (
                      !row.OwnedAccount && <div key={row.AccountId} className={styles.payrollAccountRow}>
                        <div className={styles.accountNumber}>{`xxxxxx${row.AccountNumber.toString().slice(row.AccountNumber.toString().length - 3)}`}</div>
                        <div className={styles.beneficiaryName}>{row.BeneficiaryName.toUpperCase()}</div>
                        <div className={styles.inputs}>
                          {contributionInputType === AMOUNT ?
                            this.renderDollarInputs(row, rowIndex)
                            :
                            this.renderPercentageInputs(row, rowIndex)
                          }
                        </div>
                      </div>
                    ))}
                  </>}
              </div>
            </div>
          )}

        <Divider />

        <div className={styles.totalRow}>
          <div className={styles.differentAccountButton}>
            {(isEditing || isNewContribution) && !isLoading &&
              <Button
                color='primary'
                onClick={() => this.setState({ addAccountModalOpen: true })}
              >
                {PayrollContribution.btn_add_account}
              </Button>
            }
          </div>
          <div className={styles.totalText}>{PayrollContribution.text_total}</div>
          <div className={styles.totalAmount}>
            {contributionInputType === AMOUNT ?
              <CurrencyInput
                variant='filled'
                disabled={true}
                error={Boolean(formErrors.totalAmount)}
                errorText={formErrors.totalAmount}
                value={currencyFormatter(totalAmount)}
                style={{ width: '100%', }}
                InputProps={{
                  startAdornment: <InputAdornment position='start' style={{ marginTop: 0 }}>$</InputAdornment>,
                  inputProps: {
                    style: { padding: '10px 12px 10px 0', },
                    decimalScale: 2,
                    fixedDecimalScale: true,
                    thousandSeparator: true,
                    allowNegative: false,
                  }
                }}
              /> :
              <BaseNumberInput
                variant='filled'
                disabled={true}
                error={Boolean(formErrors.totalPercentage)}
                errorText={formErrors.totalPercentage}
                value={totalPercentage || ''}
                inputProps={{
                  suffix: '%',
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  allowNegative: false,
                  maxLength: '7',
                  style: { padding: '10px 12px' }
                }}
                style={{ width: '100%', }}
              />
            }
          </div>
        </div>
      </div>
    );
  }

  renderButtons = () => {
    const { btn_agree, btn_cancel, btn_finish, btn_edit, btn_print, } = this.props.text.PayrollContribution;
    const {
      isNewContribution, isEditing, showReview, isLoading, termsChecked,
      submitLoading, statementLoading,
    } = this.state;

    const cancelButton = (
      <Button
        key='cancel'
        style={{ marginRight: '6px' }}
        variant='text'
        color='primary'
        onClick={this.props.history.goBack}
      >
        {btn_cancel}
      </Button>
    );
    const agreeButton = (
      <LoadingOverlay key='agree' show={submitLoading}>
        <Button
          disabled={!termsChecked || isLoading}
          variant='contained'
          onClick={this.handleSubmit}
        >
          {btn_agree}
        </Button>
      </LoadingOverlay>
    );
    const editButton = (
      <Button
        key='edit'
        variant='contained'
        onClick={() => this.setState({ isEditing: true })}
      >
        {btn_edit}
      </Button>
    );
    const printButton = (
      <LoadingOverlay key='print' show={statementLoading}>
        <Button
          variant='contained'
          style={{ marginRight: '6px' }}
          onClick={() => this.viewStatementHandle()}
        >
          {btn_print}
        </Button>
      </LoadingOverlay>
    );
    const finishButton = (
      <Button
        key='finish'
        variant='contained'
        onClick={this.props.history.goBack}
      >
        {btn_finish}
      </Button>
    );

    let buttons = [];
    if (showReview) {
      buttons = [
        printButton,
        finishButton,
      ];
    }
    else if (!isEditing && !isNewContribution) {
      buttons = [
        cancelButton,
        printButton,
        editButton,
      ];
    }
    else if (isEditing || isNewContribution) {
      buttons = [
        cancelButton,
        agreeButton,
      ];
    }
    return buttons;
  }

  renderDollarInputs = (row, rowIndex) => {
    const { isEditing, isNewContribution } = this.state;

    return (
      <CurrencyInput
        variant='filled'
        disabled={!isEditing && !isNewContribution}
        onChange={(vals) => this.amountHandle(vals, rowIndex)}
        value={row.Amount || ''}
        style={{ width: '100%', }}
        InputProps={{
          startAdornment: <InputAdornment position='start' style={{ marginTop: 0 }}>$</InputAdornment>,
          inputProps: {
            style: { padding: '10px 12px 10px 0', marginTop: 0 },
            margin: 'dense',
            maxLength: '10',
            decimalScale: 2,
            fixedDecimalScale: true,
            thousandSeparator: true,
            allowNegative: false,
          }
        }}
      />
    );
  }

  renderPercentageInputs = (row, rowIndex) => {
    const { isEditing, isNewContribution } = this.state;

    return (
      <BaseNumberInput
        variant='filled'
        disabled={!isEditing && !isNewContribution}
        value={row.Percentage || ''}
        onChange={({ value, }) => this.percentHandle(value, rowIndex)}
        InputProps={{
          endAdornment: <InputAdornment position='end' style={{ marginTop: 0 }}>%</InputAdornment>,
          inputProps: {
            decimalScale: 2,
            fixedDecimalScale: true,
            allowNegative: false,
            maxLength: '6',
            style: { padding: '10px 12px' },
          }
        }}
        style={{ width: '100%', }}
      />
    );
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    if (Object.keys(this.props.payrollContributionData).length === 0) {
      this.props.getPayroll()
        .then(() => {
          const payrollData = this.props.payrollContributionData;
          const totalAmount = payrollData.PayrollAccountAllocations.reduce((total, { Amount }) => total + Amount, 0);
          const totalPercentage = payrollData.PayrollAccountAllocations.reduce((total, { Percentage = 0 }) => total + Percentage, 0);
          const hasNotOwnedAccounts = payrollData.PayrollAccountAllocations.some(account => !account.OwnedAccount);
          this.setState({
            payrollData,
            totalAmount,
            totalPercentage,
            isLoading: false,
            payPeriodContributionAmount: totalAmount,
            isNewContribution: Boolean(!payrollData.EffectiveDate),
            hasNotOwnedAccounts,
          }, () => this.updatePercentageWithAmounts());
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
    else {
      const payrollData = this.props.payrollContributionData;
      const totalAmount = payrollData.PayrollAccountAllocations.reduce((total, { Amount }) => total + Amount, 0);
      const totalPercentage = payrollData.PayrollAccountAllocations.reduce((total, { Percentage = 0 }) => total + Percentage, 0);
      const hasNotOwnedAccounts = payrollData.PayrollAccountAllocations.some(account => !account.OwnedAccount);
      this.setState({
        payrollData,
        totalAmount,
        totalPercentage,
        isLoading: false,
        payPeriodContributionAmount: totalAmount,
        isNewContribution: Boolean(!payrollData.EffectiveDate),
        hasNotOwnedAccounts,
      }, () => this.updatePercentageWithAmounts());
    }
  }

  render() {
    const { PayrollContribution } = this.props.text;
    const {
      isEditing, showReview, termsOpen, instructionsOpen, isNewContribution,
      addAccountModalOpen, termsChecked,
    } = this.state;

    return (
      <div className={styles.container}>
        <>
          <Card>
            {isEditing || isNewContribution ? this.renderEmployeeSection() : this.renderReviewDetails()}
            {!showReview && this.renderPayrollAccountList()}

            {isEditing || isNewContribution ? (
              <div className={styles.checkbox}>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() => this.setState({ termsOpen: true })}
                >
                  {PayrollContribution.btn_terms}
                </Button>
                <Checkbox
                  checked={termsChecked}
                  label={PayrollContribution.lbl_terms_agree}
                  onChange={() => this.setState({ termsChecked: !termsChecked })}
                  color='primary'
                  style={{ marginRight: 0 }}
                />
              </div>
            ) : (
              <p className={styles.importantText}>{parse(PayrollContribution.text_important_bank_info)}</p>
            )}

            <div className={styles.buttonsContainer}>
              {this.renderButtons()}
            </div>
          </Card>
          <div className={styles.instructionsButton}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => this.setState({ instructionsOpen: true })}
            >
              {PayrollContribution.btn_instructions}
            </Button>
          </div>
        </>

        <AddNonAgentAccount
          open={addAccountModalOpen}
          onClose={() => this.setState({ addAccountModalOpen: false })}
          handleAddNonAgentAccount={this.handleAddNonAgentAccount}
        />

        <PayrollTerms
          onClose={() => this.setState({ termsOpen: false })}
          open={termsOpen}
        />

        <Instructions
          onClose={() => this.setState({ instructionsOpen: false })}
          open={instructionsOpen}
        />
      </div>
    );
  }
}


export default withRouter(connect(select, {
  getNotifications,
  getPayroll,
  updatePayroll,
  notificationShow,
})(LanguageHOC(PayrollContribution)));
