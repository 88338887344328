import { notificationShow } from '@frontend/common';
import {
  clearStore,
  toggleAuthyDownDialog,
  toggle2FADialog,
  toggleRSADialog
} from 'components/AppRoot/Navigation/actions';
import { REASONS_BLOCKED } from 'components/AppRoot/Navigation/constants';
import { persistClientErrors } from './error_handler';
import English from 'utils/translations/en.js';

/*
*
* Middleware
*
*/
export const apiMessages = store => next => action => { // eslint-disable-line
  if (action.error) return; // ignore extra actions with error set as true

  const { payload } = action;

  if (payload) {
    const errorMessages = [];
    const inputMessages = [];
    const infoMessages = [];
    const warningMessages = [];

    // Handler for API error codes
    if (payload.status >= 299 && payload.status < 400) { // Redirection
      errorMessages.push('A temporary redirect was issued by the server.');
      window.location.href = English.Home.nav_path;
    }
    else if (payload.status === 400) { // payload.data may be an array of messages.
      if (payload.data && payload.data.length > 0) {
        payload.data.forEach(msg => {
          switch (msg.MessageType) {
            case 0: infoMessages.push(msg.Message); break;
            case 1: warningMessages.push(msg.Message); break;
            case 2: {
              if (!msg.Field) {
                errorMessages.push(msg.Message);
                break;
              }
              else if (msg.Field !== '') {
                inputMessages.push(msg.Message);
                break;
              }
              break;
            }
            default: break;
          }
        });
      }
      else {
        errorMessages.push('A bad request or invalid credentials were provided. No messages were provided. Status: 400.');
      }
    }
    else if (payload.status === 401) { // Likely invalid token
      persistClientErrors(action); // currently returning a 401, but leaving this in hopes the back end can turn on logging for unauthenticated user for a short time
      errorMessages.push('Your session has timed out. Please log in again.');
      store.dispatch(clearStore());
    }
    else if (payload.status === 403) { // Likely user doesn't have needed Claim/Permission
      errorMessages.push('Access denied.');
    }
    else if (payload.status === 404) {
      errorMessages.push('Not found.');
    }
    else if (payload.status > 404 && payload.status < 409) { // race condition
      errorMessages.push('You are attempting an action that is not allowed. Contact my529 if you continue to experience this issue.');
    }
    else if (payload.status === 409) {
      errorMessages.push('You are attempting to modify a record that is in the process of being modified.');
    }
    else if (payload.status === 412) {
      const reasonBlocked = payload.data.Precondition;
      switch (reasonBlocked) {
        case REASONS_BLOCKED.TWO_FACTOR_AUTHENTICATION: {
          store.dispatch(toggle2FADialog());
          return Promise.reject(action);
        }
        case REASONS_BLOCKED.RSA_VERIFICATION: {
          store.dispatch(toggleRSADialog());
          return Promise.reject(action);
        }
        default: return;
      }
    }
    else if (payload.status === 460) { // Authy is down
      store.dispatch(toggleAuthyDownDialog());
      return Promise.reject(action);
    }
    else if (payload.status > 460 && payload.status < 600) { // send the GUID to the API so it can persist the GUID into the WebErrorLog table
      errorMessages.push('There was an issue communicating with the server. Please try again later, or contact my529 if you continue to experience this issue.');
    }

    if (errorMessages.length > 0) {
      errorMessages.forEach(error => store.dispatch(notificationShow(error, 'error')));
      const updatedAction = action;
      updatedAction.type = `${action.type}_ERRORS`;
      next(updatedAction);
      action.payload.status !== 400 && action.payload.status !== 401 && persistClientErrors(action); // Don't log common 400s, and 401 is already being logged above so that we access their user info before store gets cleared
      return Promise.reject(action);
    }

    if (inputMessages.length > 0) {
      const updatedAction = action;
      updatedAction.type = `${action.type}_ERRORS`;
      next(updatedAction);
      return Promise.reject(action);
    }

    if (warningMessages.length > 0) {
      warningMessages.forEach(warning => store.dispatch(notificationShow(warning, 'warning')));
    }

    if (infoMessages.length > 0) {
      infoMessages.forEach(info => store.dispatch(notificationShow(info, 'warning')));
    }

    return next(action);
  }

  // Non-async actions
  else {
    return next(action);
  }
};
