/*
*
* ValidateEmail Actions
*
*/
import { VALIDATE_EMAIL } from './constants';
import { POSTrequest } from 'utils/helpers/api_handler';

export function validateEmail(guid) {
  const response = POSTrequest('/authentication/ValidateEmail', { id: guid });

  return {
    type: VALIDATE_EMAIL,
    payload: response
  };
}