import { cloneDeep } from 'lodash';
import { properCase } from '@frontend/common';
import {
  BROWSER_WARNING,
  USER_LOGIN,
  LANGUAGE_CHANGE,
  USER_REGISTRATION,
  USER_REG_INFO_SYNC,
  VERIFY_RECAPTCHA,
  REFRESH_CLAIMS,
  RESET_CLAIMS,
  TOGGLE_SIDENAV,
  TOGGLE_NOTIFICATIONS,
  SET_NOTIFICATIONS_VIEWED,
  TOGGLE_2FA_DIALOG,
  TOGGLE_AUTHYDOWN_DIALOG,
  TOGGLE_RSA_DIALOG,
  TOGGLE_SPLASH_HAS_DISPLAYED,
  TWO_FACTOR_PRECONDITION,
  RSA_PRECONDITION,
  GET_USER_PREFERENCES,
  SAVE_USER_PREFERENCES,
  GET_NOTIFICATIONS,
  CHANGE_USERNAME,
  TWO_FACTOR_ROUTE_CHANGE,
  REASONS_BLOCKED, // constants
  DISABLE_LEFT_MENU,
  TWO_FACTOR_STATUS,
} from './constants';

import { ACCESS_ROLE } from './RequiredAccess';

const browserLanguage = (window.navigator.userLanguage || window.navigator.language).split('-')[0];
const initialState = {
  accountBlocked: false,
  claims: {},
  isAgent_AccessRole: false,
  isIpOnly_AccessRole: false,
  isNewUser_AccessRole: false,
  isValid: false,
  language: localStorage.getItem('my529-language') ? localStorage.getItem('my529-language') : browserLanguage,
  showBrowserWarning: true,
  showMobileWarning: true,
  splashHasDisplayed: false,
  token: '',
  userDetails: {
    email: '',
    name: '',
    previousLogin: '',
    recaptchaToken: '',
    recaptchaVerified: false,
  },
  registrationInfo: {
    EmailAddress: '',
    ExternalUserId: '',
    UserId: '',
  },
  userAccess: '',
  sidenavOpen: false,
  notificationsOpen: false,
  notifications: [],
  numberOfNotViewedNotifications: 0,
  is2FADialogOpen: false,
  isAuthyDownDialogOpen: false,
  isRSADialogOpen: false,
  is2FAPrecondition: false,
  isRSAPrecondition: false,
  is2FARegistered: false,
  is2FABypassed: true,
  isWithdrawal: false,
  userPreferences: [],
  prev2faRoute: '',
  reasonBlocked: '',
  disableLeftNavigationMenu: false,
};

export default function NavigationReducer(state = initialState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case BROWSER_WARNING:
      newState.showBrowserWarning = !state.showBrowserWarning;
      return newState;

    case RESET_CLAIMS:
    case REFRESH_CLAIMS:
    case USER_LOGIN:
    case CHANGE_USERNAME: {
      const rawClaims = action.payload.data.Claims ? action.payload.data.Claims : action.payload.data;
      const claims = constructClaims(rawClaims);
      const mappedClaims = mapClaims(claims);
      if (action.payload.data.Token) {
        sessionStorage.setItem('token', action.payload.data.Token);
      }
      newState.accountBlocked = claims.AccountBlocked === 'True';
      newState.reasonBlocked = newState.accountBlocked ? claims.AccountBlock : '';
      newState.is2FADialogOpen = newState.reasonBlocked === REASONS_BLOCKED.TWO_FACTOR_AUTHENTICATION;
      newState.is2FARegistered = claims.TwoFactorRegistration === TWO_FACTOR_STATUS.COMPLETED;
      newState.is2FABypassed = claims.TwoFactorAuthentication === TWO_FACTOR_STATUS.BYPASSED;
      newState.claims = claims;
      newState.userDetails = mappedClaims.userDetails;
      newState.isValid = true;
      newState.isAgent_AccessRole = claims.AgentId !== '';
      newState.isIpOnly_AccessRole = claims.AgentId === '' && claims.InterestedPartyId !== '';
      newState.isNewUser_AccessRole = !newState.isIpOnly_AccessRole && !newState.isAgent_AccessRole;
      newState.userAccess = userAccessDetermine(newState);

      return newState;
    }

    case LANGUAGE_CHANGE:
      localStorage.setItem('my529-language', action.meta.language);
      newState.language = localStorage.getItem('my529-language');
      return newState;

    case USER_REGISTRATION: {
      const data = action.payload.data;

      newState.registrationInfo = {
        EmailAddress: data.EmailAddress,
        ExternalUserId: data.ExternalUserId,
        UserId: data.UserId
      };
      return newState;
    }

    case USER_REG_INFO_SYNC: {
      newState.registrationInfo = action.meta;
      return newState;
    }

    case VERIFY_RECAPTCHA: {
      newState.userDetails = {
        ...state.userDetails,
        recaptchaToken: action.meta.recaptchaToken,
        recaptchaVerified: action.payload.data.success,
      };
      return newState;
    }

    case TOGGLE_SIDENAV: {
      newState.sidenavOpen = action.meta.isOpen;
      return newState;
    }

    case TOGGLE_NOTIFICATIONS: {
      newState.notificationsOpen = action.meta.isOpen;
      return newState;
    }

    case TOGGLE_2FA_DIALOG: {
      newState.is2FADialogOpen = !newState.is2FADialogOpen;
      return newState;
    }

    case TOGGLE_AUTHYDOWN_DIALOG: {
      newState.isAuthyDownDialogOpen = !newState.isAuthyDownDialogOpen;
      return newState;
    }

    case TOGGLE_RSA_DIALOG: {
      newState.isRSADialogOpen = !newState.isRSADialogOpen;
      return newState;
    }

    case TWO_FACTOR_PRECONDITION: {
      newState.isWithdrawal = action.meta.isWithdrawal;
      return newState;
    }

    case RSA_PRECONDITION: {
      return newState;
    }
    
    case GET_USER_PREFERENCES: {
      newState.userPreferences = action.payload.data.map(pref => ({
        ...pref,
        Value: pref.Value === 'true',
      }));

      return newState;
    }
    
    case SAVE_USER_PREFERENCES: {
      const userPreference = {
        ...action.payload.data,
        Value: action.payload.data.Value === 'true',
      };
      const preferenceIndex = newState.userPreferences.findIndex(preference => preference.Identifier === userPreference.Identifier);
      
      newState.userPreferences[preferenceIndex] = userPreference; // replace preference with update
      return newState;
    }

    case GET_NOTIFICATIONS: 
    case SET_NOTIFICATIONS_VIEWED: {
      newState.notifications = action.payload.data;
      newState.numberOfNotViewedNotifications = action.payload.data.filter(notification => !notification.ViewedDate).length;
      return newState;
    }

    case TWO_FACTOR_ROUTE_CHANGE: {
      if (!action.meta.route.includes('/multifactor')) { // TODO will need to pull in translated routes to reducers
        newState.prev2faRoute = action.meta.route;
      }

      return newState;
    }

    case DISABLE_LEFT_MENU: {
      newState.disableLeftNavigationMenu = action.payload;
      return newState;
    }

    case TOGGLE_SPLASH_HAS_DISPLAYED: {
      newState.splashHasDisplayed = true;
      return newState;
    }
    
    default: 
      return state;
  }
}

/****************** HELPER METHODS ******************/
export function mapClaims(claims) {
  return {
    userDetails: {
      agentId: claims.AgentId,
      email: claims.EmailAddress,
      name: properCase(claims.Name || ''),
      previousLogin: claims.LastLogin,
    }
  };
}

export function constructClaims(claims) {
  return claims.reduce((result, claim) => ({ ...result, [claim.m_type]: claim.m_value }), {});
}

export function userAccessDetermine(newState) {
  if (newState.isAgent_AccessRole) {
    return ACCESS_ROLE.IS_AGENT;
  }
  else if (newState.isIpOnly_AccessRole) {
    return ACCESS_ROLE.IS_IP_ONLY;
  }
  return ACCESS_ROLE.IS_NEW_USER;
}
