/*
*
* ValidateEmail Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  allNotificationsHide,
  LoadingOverlay,
  notificationShow
} from '@frontend/common';
import { validateEmail } from './actions';

import styles from './styles.module.scss';

export class ValidateEmail extends React.Component {
  static propTypes = {
    allNotificationsHide: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Login: PropTypes.shape({ nav_path: PropTypes.string }),
      ValidateEmail: PropTypes.shape({
        msg_email_validated: PropTypes.string,
        nav_path: PropTypes.string,
      }),
    }).isRequired,
    validateEmail: PropTypes.func.isRequired,
  };

  state = {
    showLoader: false,
  };

  componentDidMount() {
    const { ValidateEmail, Login } = this.props.text;
    const queryParams = new URLSearchParams(window.location.search.toLowerCase()).get('request');
    if (!queryParams) {
      this.props.history.push(Login.nav_path);
    }
    else {
      setTimeout(() => {
        this.setState({ showLoader: true });
      }, 700); // Included this pause because the call can be so quick it's not worth showing the loader, esp if it makes user think they missed something

      this.props.validateEmail(queryParams)
        .then(() => {
          this.props.notificationShow(ValidateEmail.msg_email_validated, 'success');
          this.props.history.push(Login.nav_path);
        })
        .catch(() => {
          this.props.history.push(Login.nav_path);
        });
    }

    this.props.allNotificationsHide();
  }
  render() {
    return (
      this.state.showLoader &&
        <div className={styles.container}>
          <div className={styles.loadingContainer}>
            <h1 className={styles.message} key='1'>Validating email...</h1>,
            <LoadingOverlay key='2' show={this.state.showLoader} indicatorHeight='15px' width='100%' height='100%' />
          </div>
        </div>
    );

  }
}


export default withRouter(connect(null, {
  allNotificationsHide,
  notificationShow,
  validateEmail,
})(LanguageHOC(ValidateEmail)));
