/*
*
* Transactions Component
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  getAccounts,
} from 'components/Features/protected/Accounts/actions';
import {
  getGroupAccounts,
} from 'components/Features/protected/Accounts/Transactions/actions';
import {
  getEnvironmentVars,
  getServerDateTime
} from 'components/AppRoot/StaticResources/actions';

import OptionChanges from 'components/Features/protected/Accounts/Transactions/OptionChanges';
import Transfers from 'components/Features/protected/Accounts/Transactions/Transfers';
import Contributions from 'components/Features/protected/Accounts/Transactions/Contributions';
import Withdrawals from 'components/Features/protected/Accounts/Transactions/Withdrawals';
import WithdrawalRequests from 'components/Features/protected/Accounts/Transactions/WithdrawalRequests';
import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import PageNotFound from 'components/Features/public/PageNotFound';

const select = (state) => ({
  accountList: state.accounts.accountList,
  environmentVars: state.static.environmentVars,
  groupAccountList: state.transactions.groupAccountList,
  preTransactionDetails: state.transactions.preTransactionDetails,
  serverDateTime: state.static.serverDateTime,
  webMessages: state.static.webMessages,
});

export class Transactions extends React.Component {
  static propTypes = {
    webMessages: PropTypes.object.isRequired,
    accountList: PropTypes.array.isRequired,
    environmentVars: PropTypes.shape({
      OneTimeContributionTaxYearMessageDisplayDate: PropTypes.string,
      TransactionTaxYearMessageDisplayDate: PropTypes.string,
    }).isRequired,
    groupAccountList: PropTypes.array.isRequired,
    getAccounts: PropTypes.func.isRequired,
    getGroupAccounts: PropTypes.func.isRequired,
    getEnvironmentVars: PropTypes.func.isRequired,
    getServerDateTime: PropTypes.func.isRequired,
    preTransactionDetails: PropTypes.shape({
      AgentId: PropTypes.number,
    }).isRequired,
    serverDateTime: PropTypes.string.isRequired,
    text: PropTypes.shape({
      Accounts: PropTypes.shape({
        accounts_path: PropTypes.string,
      }),
      Transactions: PropTypes.shape({
        edit_path: PropTypes.string,
        nav_path: PropTypes.func,
        new_path: PropTypes.string,
        TRANSACTION_TYPES: PropTypes.shape({
          OPTION_CHANGE: PropTypes.object,
          TRANSFER: PropTypes.object,
          CONTRIBUTION: PropTypes.object,
          WITHDRAWAL: PropTypes.object,
          WITHDRAWAL_REQUEST: PropTypes.object,
        }),
        transactions_path: PropTypes.string,
        withdrawal_path: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    Promise.all([
      this.props.accountList.length === 0 && this.props.getAccounts(),
      this.props.groupAccountList.length === 0 && this.props.getGroupAccounts(),
      this.props.getEnvironmentVars(),
      this.props.getServerDateTime(),
    ])
      .then(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      accountList,
      match: { params },
      preTransactionDetails,
      webMessages,
      text: { Accounts, Transactions }
    } = this.props;
    const { loading } = this.state;
    
    const account = accountList.find(account => account.accountId === parseInt(params.accountId)); // retrieves account by accountId param
    const permissions = account ? account.permissions : {}; // sets permissions to empty object in case user enters invalid accountId into URL

    return (
      <div>
        {this.props.accountList.length > 0 &&
          <Switch>
            {permissions.WithdrawalRequest && ([
              <Route
                path={Transactions.nav_path(':accountId', Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type, 'new', '', ':groupId')}
                key={`${preTransactionDetails.AgentId}`}
                render={props => (
                  <>
                    <Banner show={Boolean(webMessages[BANNER_LOCATIONS.WITHDRAWALS_NEW])} body={webMessages[BANNER_LOCATIONS.WITHDRAWALS_NEW]} />
                    <WithdrawalRequests {...props} />
                  </>
                )}
              />
            ])}
            {permissions.Withdraw && ([
              <Route
                path={`/${Accounts.accounts_path}/:accountId/${Transactions.transactions_path}/${Transactions.withdrawal_path}/groups/:groupId/${Transactions.new_path}`}
                key='newWithdrawal'
                render={props => (
                  <>
                    <Banner show={Boolean(webMessages[BANNER_LOCATIONS.WITHDRAWALS_NEW])} body={webMessages[BANNER_LOCATIONS.WITHDRAWALS_NEW]} />
                    <Withdrawals {...props} />
                  </>
                )}
              />,
              <Route
                path={`/${Accounts.accounts_path}/:accountId/${Transactions.transactions_path}/${Transactions.withdrawal_path}/groups/:groupId/${Transactions.edit_path}`}
                key='editWithdrawal'
                render={props => (
                  <>
                    <Banner show={Boolean(webMessages[BANNER_LOCATIONS.WITHDRAWALS_EDIT])} body={webMessages[BANNER_LOCATIONS.WITHDRAWALS_EDIT]} />
                    <Withdrawals {...props} />
                  </>
                )}
              />
            ])}
            {permissions.OptionChange && ([
              <Route
                path={Transactions.nav_path(':accountId', Transactions.TRANSACTION_TYPES.OPTION_CHANGE.type, 'new')}
                key='newOptionChange'
                render={props => (
                  <>
                    <Banner show={Boolean(webMessages[BANNER_LOCATIONS.OPTION_CHANGES_NEW])} body={webMessages[BANNER_LOCATIONS.OPTION_CHANGES_NEW]} />
                    <OptionChanges {...props} />
                  </>
                )}
              />,
              <Route
                path={Transactions.nav_path(':accountId', Transactions.TRANSACTION_TYPES.OPTION_CHANGE.type, 'edit')}
                key='editOptionChange'
                render={props => (
                  <>
                    <Banner show={Boolean(webMessages[BANNER_LOCATIONS.OPTION_CHANGES_EDIT])} body={webMessages[BANNER_LOCATIONS.OPTION_CHANGES_EDIT]} />
                    <OptionChanges {...props} />
                  </>
                )}
              />
            ])}
            {permissions.Transfer && ([
              <Route
                path={Transactions.nav_path(':accountId', Transactions.TRANSACTION_TYPES.TRANSFER.type, 'new')}
                key='newTransfer'
                render={props => (
                  <>
                    <Banner show={Boolean(webMessages[BANNER_LOCATIONS.TRANSFERS_NEW])} body={webMessages[BANNER_LOCATIONS.TRANSFERS_NEW]} />
                    <Transfers loading={loading} {...props} />
                  </>
                )}
              />,
              <Route
                path={Transactions.nav_path(':accountId', Transactions.TRANSACTION_TYPES.TRANSFER.type, 'edit', ':transactionId')}
                key='editTransfer'
                render={props => (
                  <>
                    <Banner show={Boolean(webMessages[BANNER_LOCATIONS.TRANSFERS_EDIT])} body={webMessages[BANNER_LOCATIONS.TRANSFERS_EDIT]} />
                    <Transfers loading={loading} {...props} />
                  </>
                )}
              />
            ])}
            {permissions.Contribute && ([
              <Route
                path={Transactions.nav_path(':accountId', Transactions.TRANSACTION_TYPES.CONTRIBUTION.type, 'new')}
                key='newContribution'
                render={props => (
                  <>
                    <Banner show={Boolean(webMessages[BANNER_LOCATIONS.CONTRIBUTIONS_NEW])} body={webMessages[BANNER_LOCATIONS.CONTRIBUTIONS_NEW]} />
                    <Contributions loading={loading} {...props}
                      key={`groupAccountList_${this.props.groupAccountList.length}`}
                    />
                  </>
                )}
              />,
              <Route
                path={Transactions.nav_path(':accountId', Transactions.TRANSACTION_TYPES.CONTRIBUTION.type, 'edit', ':transactionId')}
                key='editContribution'
                render={props => (
                  <>
                    <Banner show={Boolean(webMessages[BANNER_LOCATIONS.CONTRIBUTIONS_EDIT])} body={webMessages[BANNER_LOCATIONS.CONTRIBUTIONS_EDIT]} />
                    <Contributions loading={loading} {...props} />
                  </>
                )}
              />
            ])}
            <Route component={PageNotFound} key='PageNotFound' />,
          </Switch>
        }
      </div>
    );
  }
}

export default withRouter(connect(select, {
  getAccounts,
  getGroupAccounts,
  getEnvironmentVars,
  getServerDateTime,
})(LanguageHOC(Transactions)));