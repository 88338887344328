/*
 *
 * ReviewDetails Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import {
  currencyFormatter,
} from '@frontend/common';
import { MONTHLY } from '../../constants';

import styles from '../styles.module.scss';

ReviewDetails.propTypes = {
  contribution: PropTypes.object.isRequired,
  nextRunDates: PropTypes.object.isRequired,
  text: PropTypes.shape({
    ContributionRecurringReview: PropTypes.shape({
      text_scheduled_days: PropTypes.func,
      title_account_number: PropTypes.string,
      title_amount: PropTypes.string,
      title_bank_account: PropTypes.string,
      title_bene_name: PropTypes.string,
      title_end_date: PropTypes.string,
      title_next_date: PropTypes.string,
      title_scheduled_day: PropTypes.func,
    })
  })
};

export function ReviewDetails({
  contribution: { bankAccount, day1, day2, selectedAccounts, type },
  nextRunDates,
  text: { ContributionRecurringReview },
}) {
  let nextRunDate = '';
  if (type === MONTHLY) {
    nextRunDate = dayjs(nextRunDates.day1);
  }
  else {
    nextRunDate = dayjs(nextRunDates.day1).isBefore(dayjs(nextRunDates.day2), 'day') ? dayjs(nextRunDates.day1) : dayjs(nextRunDates.day2);
  }

  return (
    <div className={styles.reviewContainer}>
      <span className={styles.reviewInfoAccountNum}>
        <div className={styles.detailsTitle}>{ContributionRecurringReview.title_account_number}</div>
        {selectedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{account.accountNumber}</div>))}
      </span>

      <span className={styles.reviewInfoBen}>
        <div className={styles.detailsTitle}>{ContributionRecurringReview.title_bene_name}</div>
        {selectedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{account.beneficiary.name.toUpperCase()}</div>))}
      </span>

      <span className={styles.reviewInfoSched}>
        <div className={styles.detailsTitle}>{ContributionRecurringReview.title_scheduled_day(type === MONTHLY)}</div>
        <div>{ContributionRecurringReview.text_scheduled_days(type === MONTHLY, dayjs(day1).format('Do'), dayjs(day2).format('Do'))}</div>
      </span>

      <span className={styles.reviewInfoNextDate}>
        <div className={styles.detailsTitle}>{ContributionRecurringReview.title_next_date}</div>
        <div>{nextRunDate.format('L')}</div>
      </span>

      {selectedAccounts[0].endDate && (
        <div className={styles.reviewInfoEndDate}>
          <div className={styles.detailsTitle}>{ContributionRecurringReview.title_end_date}</div>
          <div>{dayjs(selectedAccounts[0].endDate).format('L')}</div>
        </div>
      )}

      <span className={styles.reviewInfoAmount}>
        <div className={styles.detailsTitle}>{ContributionRecurringReview.title_amount}</div>
        {selectedAccounts.map(account => (<div className={styles.detail} key={account.accountId}>{currencyFormatter(account.contributionAmount)}</div>))}
      </span>

      <span className={styles.reviewInfoBankAccount}>
        <div className={styles.detailsTitle}>{ContributionRecurringReview.title_bank_account}</div>
        <div>{bankAccount.BankName} {bankAccount.BankAccountType}, {bankAccount.MaskedBankAccountNumber}</div>
      </span>
    </div>
  );
}

export default (LanguageHOC(ReviewDetails));