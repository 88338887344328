import React from 'react';
import PropTypes from 'prop-types';

import {
  Dropdown,
} from '@frontend/common';

import styles from '../styles.module.scss';

export default class Step2SelectPredefinedOption extends React.Component {
  static propTypes = {
    formInputs: PropTypes.shape({
      selectedOptionId: PropTypes.number,
    }),
    formErrors: PropTypes.shape({
      selectedOptionId: PropTypes.string,
    }),
    onChangeHandle: PropTypes.func.isRequired,
    dropdownOptions: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })),
    selectedCategory: PropTypes.string.isRequired,
  };

  onChangeHandle = (value) => {
    this.props.onChangeHandle({ selectedOptionId: parseInt(value) });
  }

  render() {
    const {
      selectedCategory,
      formInputs,
      formErrors,
      dropdownOptions,
    } = this.props;

    return (
      <div style={{ textAlign: 'center' }}>
        <div className={styles.selectedOption}>Selected Category</div>
        <div className={styles.selectedOptionValue}>{selectedCategory}</div>
        <div>
          <Dropdown
            labelId={'selectedOptionId'}
            label={'Select Option'}
            value={formInputs.selectedOptionId}
            errorText={formErrors.selectedOptionId}
            onChange={this.onChangeHandle}
            options={dropdownOptions}
            FormControlProps={{ style: { width: '40%', minWidth: '300px', textAlign: 'left' } }}
          />
        </div>
      </div>
    );
  }
}

