/*
*
* BeneContactForm Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { GETaddress } from 'utils/helpers/api_handler';
import * as validator from 'utils/helpers/form_validation';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  AddressTextFields,
  CardBody,
  CardButtons,
  CardTitle,
  InfoIcon,
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';
import {
  Button,
  Tab,
  Tabs,
} from '@mui/material';
import {
  changeBeneAddress,
  deleteBeneAddress
} from '../../../actions';
import styles from './styles.module.scss';

const select = state => ({
  ADDRESS_TYPES: state.static.environmentVars.AddressTypes,
  language: state.static.language,
  usStates: state.static.usStates
});

export class BeneContactForm extends React.Component {

  static propTypes = {
    accountsGet: PropTypes.func.isRequired,
    addresses: PropTypes.shape({
      Mailing: PropTypes.shape({
        City: PropTypes.string,
        Country: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
      }),
      Physical: PropTypes.shape({
        City: PropTypes.string,
        Country: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
      }),
      Other: PropTypes.shape({
        City: PropTypes.string,
        Country: PropTypes.string,
        PostalCode: PropTypes.string,
        State: PropTypes.string,
        StreetAddress1: PropTypes.string,
        StreetAddress2: PropTypes.string,
      })
    }).isRequired,
    ADDRESS_TYPES: PropTypes.shape({
      Physical: PropTypes.string,
      Mailing: PropTypes.string,
      Other: PropTypes.string
    }).isRequired,
    addressType: PropTypes.string,
    beneficiaryId: PropTypes.number.isRequired,
    changeBeneAddress: PropTypes.func.isRequired,
    deleteBeneAddress: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    permissions: PropTypes.shape({
      EditAccount: PropTypes.bool
    }).isRequired,
    text: PropTypes.shape({
      BeneContactForm: PropTypes.shape({
        btn_change: PropTypes.string,
        btn_delete: PropTypes.string,
        err_field_required: PropTypes.string,
        err_postal_code: PropTypes.string,
        err_smarty_streets: PropTypes.string,
        err_street1_max_length: PropTypes.string,
        head_title: PropTypes.string,
        head_title_beneficiary_contact: PropTypes.string,
        msg_address_changed: PropTypes.string,
        msg_address_deleted: PropTypes.string,
        msg_unsaved_changes: PropTypes.string,
        text_intl_address: PropTypes.string,
      }),
      AddressCard: PropTypes.object,
      Login: PropTypes.shape({ nav_path: PropTypes.string }),
    }).isRequired,
    usStates: PropTypes.array.isRequired,
    callbackOnAddressChange: PropTypes.func,
  };

  static defaultProps = {
    callbackOnAddressChange: null,
    addressType: null,
  };

  state = {
    addressSuggestions: [],
    addressType: this.props.addressType || this.props.ADDRESS_TYPES.Mailing,
    apiErrors: {
      Mailing: [],
      Physical: [],
      Other: [],
    },
    autocompleteText: {
      Mailing: this.props.addresses.Mailing.StreetAddress1,
      Physical: this.props.addresses.Physical.StreetAddress1,
      Other: this.props.addresses.Other.StreetAddress1,
    },
    errors: {
      Mailing: [],
      Physical: [],
      Other: [],
    },
    isDeleting: false,
    isSaving: false,
    Mailing: this.props.addresses.Mailing,
    Other: this.props.addresses.Other,
    Physical: this.props.addresses.Physical,
  };

  addressDelete = (e) => {
    e.preventDefault();
    const { addressType } = this.state;

    this.setState({ isDeleting: true });

    this.props.deleteBeneAddress(this.state[addressType].AddressId, addressType, this.props.beneficiaryId)
      .then(() => {
        // on success call callback if passed
        if (this.props.callbackOnAddressChange)
          this.props.callbackOnAddressChange();

        this.setState({ isDeleting: false, });
        this.props.notificationShow(this.props.text.BeneContactForm.msg_address_deleted, 'success');

        this.props.accountsGet();
      })
      .catch(() => this.setState({ isDeleting: false }));
  }

  addressTabChange = (e, value) => {
    this.setState({ addressType: value });
  };

  addressChange = (e) => {
    e.preventDefault();
    const { addressType } = this.state;
    const addressIsValid = this.addressValidate();
    this.uppercaseAddress();

    if (addressIsValid) {
      this.setState({ isSaving: true });
      this.props.changeBeneAddress({ ...this.state[addressType], Type: addressType }, this.props.beneficiaryId)
        .then(() => {
          // on success call callback if passed
          if (this.props.callbackOnAddressChange)
            this.props.callbackOnAddressChange();
          this.setState({ isSaving: false, });
          this.props.notificationShow(this.props.text.BeneContactForm.msg_address_changed, 'success');

          this.props.accountsGet();
        })
        .catch(response => {
          this.setState({
            apiErrors: {
              ...this.state.apiErrors,
              [addressType]: response.payload.data
            },
            isSaving: false
          }, () => this.addressValidate()); // set errors from api to state and then validate form
        });
    }
  }

  addressValidate = () => {
    const errors = {
      Mailing: [],
      Other: [],
      Physical: [],
    };
    const { addressType, apiErrors, Mailing } = this.state;
    const { err_field_required, err_postal_code, err_street1_max_length } = this.props.text.BeneContactForm;
    const postalCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{9}$)|(^\d{5} \d{4}$)/;

    if (addressType === this.props.ADDRESS_TYPES.Mailing) {
      if (!Mailing.StreetAddress1) {
        errors.Mailing.push({
          input: 'StreetAddress1',
          message: err_field_required,
        });
      }
      if (!Mailing.State) {
        errors.Mailing.push({
          input: 'State',
          message: err_field_required,
        });
      }
      if (!Mailing.PostalCode.match(postalCodeRegex)) {
        errors.Mailing.push({
          input: 'PostalCode',
          message: err_postal_code,
        });
      }
      if (Mailing.StreetAddress1.length > 40) {
        errors.Mailing.push({
          input: 'StreetAddress1',
          message: err_street1_max_length
        });
      }
      if (Mailing.StreetAddress2.length > 40) {
        errors.Mailing.push({
          input: 'StreetAddress2',
          message: err_street1_max_length
        });
      }
    }
    const cityError = validator.cityValidator(this.state[addressType].City);
    if (cityError) {
      errors[addressType].push({
        input: 'City',
        message: cityError,
      });
    }
    if (apiErrors[addressType].length > 0) {
      apiErrors[addressType].forEach(error => {
        errors[addressType].push({
          input: error.Field,
          message: error.Message
        });
      });
    }

    this.setState({
      errors: {
        Mailing: errors.Mailing,
        Physical: errors.Physical,
        Other: errors.Other,
      }
    });

    return errors[addressType].length === 0;
  }

  autocompleteSuggestionsGet = event => {
    const { addressType } = this.state;

    this.setState({
      apiErrors: {
        ...this.state.apiErrors,
        [addressType]: []
      },
      autocompleteText: {
        ...this.state.autocompleteText,
        [addressType]: event.target.value
      },
      [addressType]: {
        ...this.state[addressType],
        StreetAddress1: event.target.value
      }
    }, () => this.addressValidate());

    // returning promises so it can be used in testing instead of setTimeout
    if (event.target.value.trim()) { // Only make a request if there is text. Trims out leading spaces to avoid a Smarty Streets freakout
      return GETaddress(event.target.value)
        .then(res => {
          if (res.data.suggestions !== null) {
            this.setState({
              addressSuggestions: res.data.suggestions
            });
          }
        })
        .catch(res => {
          if (res !== 'short-criteria') {
            this.props.notificationShow(this.props.text.AddressCard.err_smarty_streets, 'error');
          }
          this.setState({ addressSuggestions: [] });
        });
    }

    return;
  }

  buttonsCompose = () => {
    const { addressType, isDeleting, isSaving } = this.state;
    const isReadOnlyAddress = !this.props.permissions.EditAccount || (this.state[addressType].Country !== 'US' && this.state[addressType].Country !== '');

    if (!isReadOnlyAddress) {
      return [
        addressType !== this.props.ADDRESS_TYPES.Mailing &&
        <LoadingOverlay show={isDeleting} key='deleteButton'>
          <Button
            disabled={this.deleteButtonDisabled()}
            onClick={this.addressDelete}
            variant='contained'
          >
            {`${this.props.text.BeneContactForm.btn_delete} ${addressType}`}
          </Button>
        </LoadingOverlay>,
        <LoadingOverlay show={isSaving} key='changeButton'>
          <Button
            disabled={this.changeButtonIsDisabled()}
            onClick={this.addressChange}
            type='submit'
            variant='contained'
          >
            {`${this.props.text.BeneContactForm.btn_change} ${addressType}`}
          </Button>
        </LoadingOverlay>
      ];
    }
    return [];
  }

  changeButtonIsDisabled = () => {
    const { addressType, isSaving } = this.state;
    return isSaving || isEqual(this.state[addressType], this.props.addresses[addressType]) || this.state.errors[addressType].length > 0;
  }

  deleteButtonDisabled = () => {
    const { addressType, isDeleting, isSaving } = this.state;
    return isSaving
      || isDeleting
      || Object.keys(this.state[addressType]).every(key => this.state[addressType][key] === '')
      || Object.keys(this.props.addresses[addressType]).every(key => this.props.addresses[addressType][key] === '');
  }

  errorGet = (input) => {
    const errorsForAddressType = this.state.errors[this.state.addressType];
    const error = errorsForAddressType.find(error => error.input === input);
    return error ? error.message : '';
  }

  onAutocompleteAddressSelect = (event, selection) => {
    const { addressType, errors } = this.state;

    if (!selection) { // if user clears input
      this.setState({
        autocompleteText: {
          ...this.state[addressType],
          [addressType]: '',
        },
        addressSuggestions: [],
        [addressType]: {
          ...this.state[addressType],
          StreetAddress1: ''
        }
      }, () => this.addressValidate());
    }
    else if (!Object.prototype.hasOwnProperty.call(selection, 'street_line')) { // if user sends too many requests in a short period, autocomplete stops sending suggestions back, so just set input to state
      this.setState({
        autocompleteText: {
          ...this.state.autocompleteText,
          [addressType]: selection
        },
        [addressType]: {
          ...this.state[addressType],
          StreetAddress1: selection,
        }
      });
    }
    else { // if all is working as expected and selection is valid SmartyStreets object
      this.setState({
        autocompleteText: {
          ...this.state.autocompleteText,
          [addressType]: selection.street_line.toUpperCase()
        },
        errors: { ...errors, [addressType]: [] },
        [addressType]: {
          ...this.state[addressType],
          City: selection.city.toUpperCase(),
          PostalCode: selection.zipcode.toUpperCase(),
          State: selection.state.toUpperCase(),
          StreetAddress1: selection.street_line.toUpperCase(),
          StreetAddress2: selection.secondary.toUpperCase()
        }
      });
    }
  }

  onInputChange = (name, event) => {
    const { addressType } = this.state;
    this.setState({
      apiErrors: {
        ...this.state.apiErrors,
        [addressType]: []
      },
      [addressType]: {
        ...this.state[addressType],
        [name]: event.target.value
      }
    }, () => this.addressValidate());
  }

  uppercaseAddress = () => {
    const addressToUppercase = this.state[this.state.addressType];

    // eslint-disable-next-line no-unused-vars
    for (const field in addressToUppercase) {
      addressToUppercase[field] = typeof addressToUppercase[field] === 'string' ? addressToUppercase[field].toUpperCase() : addressToUppercase[field];
    }

    this.setState({ [this.state.addressType]: addressToUppercase });
  }

  componentDidUpdate(prevProps) {
    const { addresses } = this.props;

    if (!isEqual(addresses, prevProps.addresses)) {
      this.setState({
        Mailing: addresses.Mailing,
        Other: addresses.Other,
        Physical: addresses.Physical,
        autocompleteText: {
          Mailing: addresses.Mailing?.StreetAddress1,
          Physical: addresses.Physical?.StreetAddress1,
          Other: addresses.Other?.StreetAddress1,
        }
      });
    }
  }

  render() {
    const { addressSuggestions, addressType, autocompleteText } = this.state;
    const { ADDRESS_TYPES, permissions, text: { BeneContactForm } } = this.props;
    const isReadOnlyAddress = !permissions.EditAccount || (this.state[addressType].Country !== 'US' && this.state[addressType].Country !== '');

    return (
      <React.Fragment>
        <form className={styles.benContactForm} onSubmit={this.addressChange}>
          <CardTitle>
            <div className={styles.titleContainer}>
              {BeneContactForm.head_title_beneficiary_contact}
              <InfoIcon message={BeneContactForm.text_intl_address} />
            </div>
          </CardTitle>
          <CardBody>
            <div className={styles.form}>
              <Tabs
                centered
                className={styles.tabs}
                onChange={this.addressTabChange}
                value={addressType}
              >
                <Tab label={ADDRESS_TYPES.Mailing} value={ADDRESS_TYPES.Mailing} />
                <Tab label={ADDRESS_TYPES.Physical} value={ADDRESS_TYPES.Physical} />
                <Tab label={ADDRESS_TYPES.Other} value={ADDRESS_TYPES.Other} />
              </Tabs>
              <div className={isReadOnlyAddress ? styles.readOnlyAddress : ''}>
                <AddressTextFields
                  address={this.state[addressType]}
                  addressSuggestions={addressSuggestions}
                  autocompleteSuggestionsGet={this.autocompleteSuggestionsGet}
                  autocompleteText={autocompleteText[addressType]}
                  errorGet={this.errorGet}
                  inputProps={{ onBlur: e => { this.addressValidate(e); this.uppercaseAddress(); } }} // eslint-disable-line
                  isReadOnly={isReadOnlyAddress}
                  onAutocompleteAddressSelect={this.onAutocompleteAddressSelect}
                  onInputChange={this.onInputChange}
                  usStates={this.props.usStates}
                />
              </div>
            </div>
          </CardBody>
          <CardButtons>
            {this.buttonsCompose()}
          </CardButtons>
        </form>
      </React.Fragment>
    );
  }
}

export default connect(select, {
  changeBeneAddress,
  deleteBeneAddress,
  notificationShow,
})(LanguageHOC(BeneContactForm));
