/*
*
* UpcomingSchedules Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  withRouter
} from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  getAllUpcomingTransactions,
  getAccounts,
} from 'components/Features/protected/Accounts/actions';
import {
  deleteTransaction,
  getScheduledTransaction,
} from 'components/Features/protected/Accounts/Transactions/actions';
import {
  getOptionChangeById
} from 'components/Features/protected/Accounts/Transactions/OptionChanges/actions';
import { get2FAPrecondition, getRSAPrecondition } from 'components/AppRoot/Navigation/actions';
import { getPreferredMethod } from 'components/Features/protected/Multifactor/actions';
import { protectAction, protectWithdrawal } from 'utils/helpers/multifactor_handler';
import { MULTIFACTOR_TYPE } from 'components/AppRoot/Navigation/constants';
import {
  CardAligned,
  CardBody,
  CardTitle,
  notificationShow,
  ConfirmModal,
  LoadingOverlay,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
} from '@frontend/common';

import ViewPendingOptionChange from 'components/Features/protected/Accounts/UpcomingTransactions/ViewPendingOptionChange';

import transactionHubStyles from '../styles.module.scss';
import styles from './styles.module.scss';

export const transactionTypes = { // used for API routing below
  transfers: 'transfers',
  optionChanges: 'option-changes',
  withdrawals: 'withdrawals',
  contributions: 'contributions'
};

const select = (state) => ({
  upcomingTransactionsAll: state.accounts.upcomingTransactionsAll.map((transaction, index) => ({ ...transaction, id: index })),
  selectedTransaction: state.transactions.selectedTransaction,
  optionChangeById: state.optionChanges.optionChangeById,
  claims: state.session.claims,
  preferredInfo: state.multifactor.preferredInfo,
});

export class UpcomingSchedules extends React.Component {

  static propTypes = {
    getAccounts: PropTypes.func.isRequired,
    getAllUpcomingTransactions: PropTypes.func.isRequired,
    getScheduledTransaction: PropTypes.func.isRequired,
    getOptionChangeById: PropTypes.func.isRequired,
    deleteTransaction: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    get2FAPrecondition: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    getPreferredMethod: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    text: PropTypes.shape({
      UpcomingTransactions: PropTypes.shape({
        head_title: PropTypes.string,
        menu_cancel: PropTypes.string,
        menu_edit: PropTypes.string,
        menu_view: PropTypes.string,
        msg_error_retrieving_transactions: PropTypes.string,
        text_acct_number: PropTypes.string,
        text_bene_name: PropTypes.string,
        text_option: PropTypes.string,
        text_amount: PropTypes.string,
        text_end_date: PropTypes.string,
        text_frequency: PropTypes.string,
        text_no_upcoming_transactions: PropTypes.string,
        text_scheduled_date: PropTypes.string,
        text_status: PropTypes.string,
        text_transaction_type: PropTypes.string,
        text_date: PropTypes.string,
      }),
      AccountDetails: PropTypes.shape({ nav_path: PropTypes.func }),
      Transactions: PropTypes.shape({
        edit_path: PropTypes.string,
        nav_path: PropTypes.func,
        transactions_path: PropTypes.string,
        withdrawal_path: PropTypes.string,
        cancel_transaction_modal_message: PropTypes.func,
        TRANSACTION_TYPES: PropTypes.shape({
          CONTRIBUTION: PropTypes.object,
          OPTION_CHANGE: PropTypes.object,
          TRANSFER: PropTypes.object,
          WITHDRAWAL: PropTypes.object,
          WITHDRAWAL_REQUEST: PropTypes.object,
        }),
      }),
    }).isRequired,
    upcomingTransactionsAll: PropTypes.array.isRequired,
    selectedTransaction: PropTypes.object.isRequired,
    optionChangeById: PropTypes.object.isRequired,
  };

  state = {
    loading: false,
    showCancelConfirmModal: false,
    cancelTransactionLoading: false,
    selectedTransaction: {},
    loadingSelectedTransaction: false,
    showPendingOptionChange: false,
    selectedPendingOptionChange: {},
    hasPrecondition: false,
    rememberedFunction: '',
    txn: {}
  };

  upcomingTransactionsGet = () => {
    this.setState({ loading: true });
    return this.props.getAllUpcomingTransactions()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  editTransaction = transaction => {
    const { text: { Transactions }, preferredInfo, claims } = this.props;
    const isWithdrawal = transaction.TransactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL.type || transaction.TransactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type;
    const multiStatus = isWithdrawal
      ? protectWithdrawal(transaction.TransactionType, Transactions, preferredInfo, claims)
      : protectAction(claims);

    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      /* 
      need to pass if is a withdrawal or not for new accounts = (oldest AO account < 45 days old)
      if is a new account, is a withdrawal, requires 2FA and RSA
      */
      this.setState(
        {
          hasPrecondition: true,
          txn: transaction,
          rememberedFunction: 'editTransaction'
        },
        () => this.props.get2FAPrecondition(isWithdrawal).catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        {
          hasPrecondition: true,
          txn: transaction,
          rememberedFunction: 'editTransaction'
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      const { text } = this.props;
      this.setState({ hasPrecondition: false, loadingSelectedTransaction: true, rememberedFunction: '' });
      this.props.getScheduledTransaction(transaction)
        .then(() => {
          this.setState({ loadingSelectedTransaction: false });
          const { AccountId, AccountGroupId } = this.props.selectedTransaction;
          switch (this.transactionType(transaction.TransactionType)) {
            case transactionTypes.transfers: {
              return this.props.history.push(`/accounts/${AccountId}/transactions/${transaction.ScheduleId}/transfers/edit`);
            }
            case transactionTypes.optionchanges: {
              return this.props.history.push(`${text.AccountDetails.nav_path(AccountId)}/${text.Transactions.transactions_path}/option-changes/${text.Transactions.edit_path}`);
            }
            case transactionTypes.withdrawals: {
              return this.props.history.push(`${text.AccountDetails.nav_path(AccountId)}/${text.Transactions.transactions_path}/${text.Transactions.withdrawal_path}/groups/${AccountGroupId}/${text.Transactions.edit_path}`);
            }
            case transactionTypes.contributions: {
              return this.props.history.push(text.Transactions.nav_path(AccountId, transaction.TransactionType, 'edit', transaction.ScheduleId));
            }
            default: return;
          }
        });
    }
  }

  viewTransaction = (transaction) => {
    // when pending only option changes are viewed
    const { ScheduleId, ActionId, TransactionType } = transaction;
    const { optionChangeById } = this.props;
    if (this.transactionType(TransactionType) === transactionTypes.optionchanges) {
      if (!optionChangeById || ScheduleId !== optionChangeById.OptionChangeId || ActionId !== optionChangeById.ActionId) {
        this.setState({ loadingSelectedTransaction: true });
        this.props.getOptionChangeById(ScheduleId, ActionId)
          .then(() => {
            this.togglePendingOptionChangeDrawer();
            this.setState({
              selectedPendingOptionChange: this.props.optionChangeById,
              loadingSelectedTransaction: false,
            });
          });
      }
      else {
        this.togglePendingOptionChangeDrawer();
        this.setState({
          selectedPendingOptionChange: optionChangeById,
          loadingSelectedTransaction: false,
        });
      }
    }
  }

  transactionType = (type) => {
    switch (type) {
      case 'Transfer Out':
      case 'Transfer In': return transactionTypes.transfers;
      case 'Option Change': return transactionTypes.optionchanges;
      case 'Withdrawal': return transactionTypes.withdrawals;
      case 'Contribution': return transactionTypes.contributions;
      default: return;
    }
  }

  upcomingTransactionCancelHandler = () => {
    const txn = this.state.selectedTransaction;
    this.setState({ cancelTransactionLoading: true });
    this.props.deleteTransaction(txn)
      .then(() => {
        this.props.notificationShow(`${txn.TransactionType} cancelled.`, 'success');
        this.setState({
          cancelTransactionLoading: false,
          showCancelConfirmModal: false,
          selectedTransaction: {},
        });
        this.upcomingTransactionsGet();
        this.props.getAccounts();
      })
      .catch(() => {
        this.setState({ cancelTransactionLoading: false, showCancelConfirmModal: false, });
        this.upcomingTransactionsGet();
      });
  };

  togglePendingOptionChangeDrawer = () => {
    this.setState({ showPendingOptionChange: !this.state.showPendingOptionChange });
  }

  protectDeleteAction(transaction) {
    const { text: { Transactions }, preferredInfo, claims } = this.props;
    const isWithdrawal = transaction.TransactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL.type || transaction.TransactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type;
    const multiStatus = isWithdrawal
      ? protectWithdrawal(transaction.TransactionType, Transactions, preferredInfo, claims)
      : protectAction(claims);

    if (multiStatus === MULTIFACTOR_TYPE.TWO_FACTOR) {
      /* 
      need to pass if is a withdrawal or not for new accounts = (oldest AO account < 45 days old)
      if is a new account, is a withdrawal, requires 2FA and RSA
      */
      this.setState(
        {
          hasPrecondition: true,
          txn: transaction,
          rememberedFunction: 'protectDeleteAction'
        },
        () => this.props.get2FAPrecondition(isWithdrawal).catch(() => null)
      );
    }
    else if (multiStatus === MULTIFACTOR_TYPE.RSA)
      this.setState(
        {
          hasPrecondition: true,
          txn: transaction,
          rememberedFunction: 'protectDeleteAction'
        },
        () => this.props.getRSAPrecondition().catch(() => null)
      );
    else {
      this.setState({
        hasPrecondition: false,
        showCancelConfirmModal: true,
        selectedTransaction: transaction,
        rememberedFunction: ''
      });
    }
  }

  componentDidMount() {
    this.upcomingTransactionsGet();
    this.props.getPreferredMethod();
  }

  componentDidUpdate() {
    const { text: { Transactions }, preferredInfo, claims } = this.props;
    const isWithdrawal = this.state.txn.TransactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL.type || this.state.txn.TransactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type;
    const multiStatus = isWithdrawal
      ? protectWithdrawal(this.state.txn.TransactionType, Transactions, preferredInfo, claims)
      : protectAction(claims);

    if (this.state.hasPrecondition && multiStatus === MULTIFACTOR_TYPE.AUTH) {
      const rememberedFunction = this.state.rememberedFunction;
      this[rememberedFunction](this.state.txn);
    }
  }

  render() {
    const { text: { UpcomingTransactions, Transactions } } = this.props;
    const {
      showCancelConfirmModal,
      selectedTransaction,
      cancelTransactionLoading,
      showPendingOptionChange,
      selectedPendingOptionChange,
      loadingSelectedTransaction,
      loading,
    } = this.state;

    return (
      <React.Fragment>
        <CardAligned
          className={transactionHubStyles.upcomingTransactionsCard}
          formProps={{ style: { display: 'block' } }}
        >
          <CardTitle>{UpcomingTransactions.head_title}</CardTitle>
          <CardBody>
            <LoadingOverlay show={loadingSelectedTransaction} width='100%'>
              <div className={styles.tableContainer}>
                <SmartTable
                  rows={this.props.upcomingTransactionsAll}
                  emptyMessage={UpcomingTransactions.text_no_upcoming_transactions}
                  idKey='id'
                  loading={loading}
                  actions={[
                    {
                      displayName: UpcomingTransactions.menu_edit,
                      type: 'menu',
                      onSelect: row => this.setState({
                        selectedTransaction: row,
                      }, this.editTransaction(row)),
                      showIf: row => row.CanEdit,
                    },
                    {
                      displayName: UpcomingTransactions.menu_view,
                      type: 'menu',
                      onSelect: row => this.setState({
                        selectedTransaction: row,
                      }, this.viewTransaction(row)),
                      showIf: row => !row.CanEdit && row.TransactionType === 'Option Change',
                    },
                    {
                      displayName: UpcomingTransactions.menu_cancel,
                      type: 'menu',
                      onSelect: row => this.protectDeleteAction(row),
                      showIf: row => row.CanDelete,
                    }
                  ]}
                  columns={[
                    {
                      key: 'AccountNumber',
                      title: UpcomingTransactions.text_acct_number,
                      type: 'number',
                    },
                    {
                      key: 'BeneficiaryName',
                      title: UpcomingTransactions.text_bene_name,
                      type: 'string',
                      hideOn: ['phone'],
                    },
                    {
                      key: 'Option',
                      title: UpcomingTransactions.text_option,
                      type: 'string',
                      hideOn: ['phone'],
                    },
                    {
                      key: 'Frequency',
                      title: UpcomingTransactions.text_frequency,
                      type: 'string',
                      hideOn: ['phone'],
                    },
                    {
                      key: 'TransactionType',
                      title: UpcomingTransactions.text_transaction_type,
                      type: 'string',
                      hideOn: ['phone'],
                    },
                    {
                      key: 'Amount',
                      title: UpcomingTransactions.text_amount,
                      type: 'currency',
                    },
                    {
                      key: 'Status',
                      title: UpcomingTransactions.text_status,
                      type: 'string',
                      hideOn: ['phone'],
                    },
                    {
                      key: 'ScheduledDate',
                      title: UpcomingTransactions.text_scheduled_date,
                      type: 'dateString',
                      hideOn: ['phone'],
                    },
                    {
                      key: 'EndDate',
                      title: UpcomingTransactions.text_end_date,
                      type: 'dateString',
                      hideOn: ['phone'],
                    },
                  ]}
                >
                  <TableContainer minWidth='100%'>
                    <TableHeader />
                    <TableRows />
                  </TableContainer>
                  <TablePagination />
                </SmartTable>
              </div>
            </LoadingOverlay>
          </CardBody>
        </CardAligned>

        <ConfirmModal
          show={Boolean(showCancelConfirmModal)}
          title={`Cancel ${selectedTransaction.TransactionType}`}
          body={Transactions.cancel_transaction_modal_message(selectedTransaction.TransactionType, selectedTransaction.Frequency)}
          onModalClose={() => this.setState({ showCancelConfirmModal: false })}
          onConfirm={() => this.upcomingTransactionCancelHandler(selectedTransaction)}
          loading={cancelTransactionLoading}
        />

        <ViewPendingOptionChange
          open={showPendingOptionChange}
          onClose={this.togglePendingOptionChangeDrawer}
          pendingOptionChange={selectedPendingOptionChange}
          loading={loadingSelectedTransaction}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(connect(select, {
  getAccounts,
  getAllUpcomingTransactions,
  getScheduledTransaction,
  deleteTransaction,
  getOptionChangeById,
  notificationShow,
  getPreferredMethod,
  get2FAPrecondition,
  getRSAPrecondition,
})(LanguageHOC(UpcomingSchedules)));
