import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import { Modal } from '@frontend/common';

import { mobileWidth } from 'utils/config/_sassconfig.scss';
import styles from './styles.module.scss';

export class AccountListDialog extends React.Component {
  static propTypes = {
    accounts: PropTypes.arrayOf(
      PropTypes.shape({
        accountId: PropTypes.number.isRequired,
        accountNumber: PropTypes.number.isRequired,
        beneficiary: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
        option: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
    onClose: PropTypes.func.isRequired,
    transactionTitle: PropTypes.string.isRequired,
    transactionSubtitle: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
  }

  state = {
    isOnMobile: window.innerWidth <= parseInt(mobileWidth.replace('px', '')),
  }

  handleClose = () => {
    this.props.onClose(null); // when no account selected passing null to the parent
  };

  handleListItemClick = (selectedAccountId) => {
    this.props.onClose(selectedAccountId);
  };

  render() {
    const { isOnMobile } = this.state;
    const { open, transactionTitle, transactionSubtitle, accounts } = this.props;

    return (
      <Modal
        onCloseModal={this.handleClose}
        show={open}
        fullWidth
        fullScreen={isOnMobile}
        title={transactionTitle}
      >
        <List className={styles.list}>
          <ListItem className={styles.subTitle}>{transactionSubtitle}</ListItem>
          {accounts.map((account) => [
            <ListItem button onClick={() => this.handleListItemClick(account.accountId)} key={`listItem_${account.accountId}`}>
              <ListItemText>
                <div className={styles.threeItemRow}>
                  <div className={styles.threeItemCell}>{account.beneficiary.name}</div>
                  <div className={`${styles.threeItemCell} ${styles.cellValues}`}>{account.accountNumber}</div>
                  <div className={`${styles.threeItemCell} ${styles.cellValues}`}>{account.option.name}</div>
                </div>
              </ListItemText>
            </ListItem>,
            <Divider key={`divider_${account.accountId}`} />
          ])}
        </List>
      </Modal>);
  }

}

export default LanguageHOC(AccountListDialog);