import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import parse from 'html-react-parser';
import {
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
} from '@mui/material';
import { IconBtnTooltip } from '@frontend/common';

import styles from './styles.module.scss';

export function Instructions({ open, onClose, text: { PayrollInstructions } }) {

  Instructions.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    text: PropTypes.shape({
      PayrollInstructions: PropTypes.shape({
        head_instructions: PropTypes.string,
        title_how: PropTypes.string,
        text_how1: PropTypes.string,
        text_how2: PropTypes.array,
        text_how3: PropTypes.string,
        text_how4: PropTypes.string,
        text_how5: PropTypes.string,
        title_provide_to_employer: PropTypes.string,
        text_provide1: PropTypes.string,
        text_provide2: PropTypes.string,
      })
    })
  };

  const renderPayroll = () => (
    <div className={styles.instructionsContainer}>
      <div className={styles.closeIcon}>
        <IconBtnTooltip
          icon='close'
          onClick={onClose}
        />
      </div>
      <Divider />
      <h3 className={styles.instructionsHeader}>{PayrollInstructions.head_instructions}</h3>
      <Accordion>
        <AccordionSummary
          expandIcon={<i className='material-icons' style={{ cursor: 'pointer' }}>keyboard_arrow_down</i>}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>{PayrollInstructions.title_how}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div>{PayrollInstructions.text_how1}</div>
            <ol style={{ marginTop: '5px' }}>
              {PayrollInstructions.text_how2.map(line => <li key={line}>{line}</li>)}
            </ol>
            <p>{parse(PayrollInstructions.text_how3)}</p>

            <strong>{PayrollInstructions.text_how4}</strong>
            <div>{PayrollInstructions.text_how5}</div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<i className='material-icons' style={{ cursor: 'pointer' }}>keyboard_arrow_down</i>}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>{PayrollInstructions.title_provide_to_employer}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p style={{ marginTop: 0 }}>{PayrollInstructions.text_provide1}</p>
            <div>{PayrollInstructions.text_provide2}</div>
          </div>
        </AccordionDetails>
      </Accordion>

    </div>
  );

  return (
    <Drawer anchor='bottom' open={open} onClose={onClose}>
      {renderPayroll()}
    </Drawer>
  );
}

export default LanguageHOC(Instructions);