/*
*
* Documents Constants
*
*/
export const DOCUMENTS_GET = 'src/components/Features/protected/Documents/DOCUMENTS_GET';
export const ACCOUNT_DOCUMENT_DOWNLOAD_DATA = 'src/components/Features/protected/Documents/ACCOUNT_DOCUMENT_DOWNLOAD_DATA';
export const COUNTRIES_LIST_GET = 'src/components/Features/protected/Documents/COUNTRIES_LIST_GET,';
export const ACCOUNT_CERTIFICATE_NAME = 'src/components/Features/protected/Documents/ACCOUNT_CERTIFICATE_NAME';

export const PDF_FILE = 'PDF';
export const HTML_FILE = 'HTML';