/*
*
* MyInfo Constants
*
*/
export const AGENT_GET = 'src/components/Features/protected/MyInfo/AGENT_GET';
export const CHANGE_ADDRESS = 'src/components/Features/protected/MyInfo/CHANGE_ADDRESS';
export const GET_DELIVERY_METHODS = 'src/components/Features/protected/MyInfo/GET_DELIVERY_METHODS';
export const CHANGE_DELIVERY_METHODS = 'src/components/Features/protected/MyInfo/CHANGE_DELIVERY_METHODS';
export const CHANGE_EMAIL = 'src/components/Features/protected/MyInfo/CHANGE_EMAIL';
export const CHANGE_PHONE_NUMBERS = 'src/components/Features/protected/MyInfo/CHANGE_PHONE_NUMBERS';
export const DELETE_ADDRESS = 'src/components/Features/protected/MyInfo/DELETE_ADDRESS';
export const PASSWORD_CHANGE = 'src/components/Features/protected/MyInfo/PASSWORD_CHANGE';

export const OFFICIAL_NOTIFICATION = 'src/components/Features/protected/MyInfo/OFFICIAL_NOTIFICATION';
export const TAXFORMS_NOTIFICATION = 'src/components/Features/protected/MyInfo/TAXFORMS_NOTIFICATION';
export const NEWSLETTER_NOTIFICATION = 'src/components/Features/protected/MyInfo/NEWSLETTER_NOTIFICATION';
export const PROMO_NOTIFICATION = 'src/components/Features/protected/MyInfo/PROMO_NOTIFICATION';
export const BDAY_NOTIFICATION = 'src/components/Features/protected/MyInfo/BDAY_NOTIFICATION';
export const UTAH_RESIDENT_CHANGE = 'src/components/Features/protected/MyInfo/UTAH_RESIDENT_CHANGE';

export const ONLINE_ONLY = 'Online';
export const MAIL = 'Mail';
export const OPT_OUT = 'OptOut';
export const MAIL_ONLINE = 'Mail + Online';
export const BLOCKED = 'Blocked';

export const notificationTypes = {
  OfficialCommunications: {
    title: 'OfficialCommunications',
    options: [
      { display: 'Online Only', value: ONLINE_ONLY },
      { display: 'Mail + Online', value: MAIL },
    ]
  },
  TaxForms: {
    title: 'TaxForms',
    options: [ 
      { display: 'Online Only', value: ONLINE_ONLY },
      { display: 'Mail + Online', value: MAIL },
    ],
  },
  Newsletter: {
    title: 'Newsletter',
    options: [ 
      { display: 'Online Only', value: ONLINE_ONLY },
      { display: 'Mail + Online', value: MAIL },
      { display: 'Opt Out', value: OPT_OUT },
    ],
  },
  Promotional: {
    title: 'Promotional',
    options: [
      { display: 'Online Only', value: ONLINE_ONLY },
      { display: 'Opt Out', value: OPT_OUT },
    ],
  },
  BirthdayNotifications: {
    title: 'BirthdayNotifications',
    options: [
      { display: 'Online Only', value: ONLINE_ONLY },
      { display: 'Opt Out', value: OPT_OUT },
    ] 
  },
  UTRESIDENT: {
    title: 'UTRESIDENT',
  },
};