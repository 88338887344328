/*
*
* LanguageHOC Higher Order Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { default as English } from 'utils/translations/en.js';
import { default as Spanish } from 'utils/translations/es.js';

const select = state => ({
  language: state.session.language
});

export default function(ComposedComponent) {

  class Language extends React.Component {

    static propTypes = {
      language: PropTypes.string.isRequired
    };

    render() {
      const translatedText = this.props.language === 'es' ? Spanish : English; // eslint-disable-line
      return <ComposedComponent {...this.props} text={English} />; // TRANSLATIONS change text={translatedText} to activate Spanish translation (and remove comment above)
    }
  }

  return connect(select)(Language);
}