/*
*
* EditIp Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { editIp } from '../actions';
import {
  TextField,
} from '@mui/material';
import {
  Modal,
  notificationShow,
} from '@frontend/common';


export class EditIp extends React.Component {

  static propTypes = {
    editIp: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    renderAccountSelection: PropTypes.func.isRequired,
    selectableAccounts: PropTypes.array.isRequired,
    selectedIp: PropTypes.object.isRequired,
    text: PropTypes.shape({
      InterestedParties: PropTypes.shape({
        btn_close: PropTypes.string,
        btn_edit_ip: PropTypes.string,
        btn_save: PropTypes.string,
        msg_ip_edited: PropTypes.string,
      }),
    }).isRequired,
    validateSelectedAccounts: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
  };

  closeModal = () => {
    this.setState({ loading: false });
    this.props.onModalClose();
  }

  editIp = () => {
    const { selectableAccounts, selectedIp: { interestedPartyId } } = this.props;
    const isValid = this.props.validateSelectedAccounts();

    if (isValid) {
      this.setState({ loading: true });
      const accountIds = selectableAccounts.filter(acct => acct.selected).map(acct => acct.accountId);

      this.props.editIp({ interestedPartyId, accountIds })
        .then(() => {
          this.closeModal();
          this.props.notificationShow(this.props.text.InterestedParties.msg_ip_edited, 'success');
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  render() {
    const { open, selectedIp, renderAccountSelection, text: { InterestedParties } } = this.props;
    const { loading } = this.state;

    return (
      <Modal
        actionButtons={[
          {
            action: this.editIp,
            buttonType: 'contained',
            disabled: loading,
            label: InterestedParties.btn_save,
            loading,
          },
        ]}
        onCloseModal={this.closeModal}
        show={open}
        title={InterestedParties.btn_edit_ip}
        includeLeftCancelBtn
      >
        <TextField disabled fullWidth helperText='Email Address' value={selectedIp.emailAddress1} />
        {renderAccountSelection()}
      </Modal>
    );
  }
}


export default connect(null, {
  editIp,
  notificationShow,
})(LanguageHOC(EditIp));
