/*
*
* Documents Reducers
*
*/
import { cloneDeep, } from 'lodash';
import {
  DOCUMENTS_GET,
  COUNTRIES_LIST_GET,
} from './constants';

const initialState = {
  documents: [],
  countries: []
};

function DocumentsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case DOCUMENTS_GET: {
      newState.documents = action.payload.data;
      return newState;
    }

    case COUNTRIES_LIST_GET: {
      newState.countries = action.payload.data;
      return newState;
    }

    default:
      return state;
  }

}

export default DocumentsReducer;