/*
*
* OptionChanges Reducers
*
*/
import {
  GET_PREDEFINED_OPTIONS,
  GET_NEW_AGE_BASED_SCHEMA,
  GET_NEW_STATIC_SCHEMA,
  GET_OPTION_CHANGE_BY_ID,
} from './constants';

import {
  cloneDeep,
} from 'lodash';

import { convert2DCalculatorSchemaTo3D } from '@frontend/common';

const initialState = {
  predefinedOptions: [],
  newAgeBasedSchema: {
    TemplateName: '',
    FundCategories: [],
  },
  newStaticSchema: {
    TemplateName: '',
    FundCategories: [],
  },
  template: {
    TemplateName: '',
    FundCategories: [],
  },
  optionChangeById: {},
};


function OptionChangesReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case GET_PREDEFINED_OPTIONS: {
      newState.predefinedOptions = action.payload.data.map(option => ({
        OptionId: option.OptionId,
        Name: option.Name,
        isCustom: option.IsCustom,
        isStatic: option.OptionType === 'S' && !option.IsCustom,
        isEnrollmentDate: option.OptionType === 'T' && !option.IsCustom,
        OptionOnlineDescription: option.UIOption.OptionOnlineDescription,
        OptionOnlineLink: option.UIOption.OptionOnlineLink,
      }));
      return newState;
    }

    case GET_OPTION_CHANGE_BY_ID: {
      const data = {
        ...action.payload.data,
        ...action.payload.data.Actions[0],
      };
      data.Schema = data.NewOption.includes('Custom') ? convert2DCalculatorSchemaTo3D(data.Schema) : null;

      newState.optionChangeById = data;
      return newState;
    }

    case GET_NEW_AGE_BASED_SCHEMA: {
      newState.newAgeBasedSchema = convert2DCalculatorSchemaTo3D(action.payload.data);
      return newState;
    }

    case GET_NEW_STATIC_SCHEMA: {
      newState.newStaticSchema = convert2DCalculatorSchemaTo3D(action.payload.data);
      return newState;
    }

    default:
      return state;
  }
}

export default OptionChangesReducer;