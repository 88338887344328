/*
*
* PreflightLoader Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  closeTransactionLoader,
  openPreflightModal,
  transactionPreview
} from '../actions';

import {
  Modal,
  LoadingOverlay,
} from '@frontend/common';
import { Icon } from '@mui/material';

import styles from './styles.module.scss';

const select = (state) => ({
  preflightLoaderOpen: state.transactions.preflightLoaderOpen,
  previewDetails: state.transactions.previewDetails,
  preflightAccount: state.transactions.preflightAccount,
  preflightTransactionType: state.transactions.preflightTransactionType,
});

export class PreflightLoader extends React.Component {
  static propTypes = {
    closeTransactionLoader: PropTypes.func.isRequired,
    openPreflightModal: PropTypes.func.isRequired,
    preflightAccount: PropTypes.shape({
      accountId: PropTypes.number.isRequired,
      AccountGroupId: PropTypes.number.isRequired,
    }).isRequired,
    preflightLoaderOpen: PropTypes.bool.isRequired,
    preflightTransactionType: PropTypes.string.isRequired,
    previewDetails: PropTypes.shape({
      canContinue: PropTypes.bool.isRequired,
      errors: PropTypes.arrayOf(PropTypes.string),
    }),
    transactionPreview: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Transactions: PropTypes.shape({
        btn_cancel: PropTypes.string,
        nav_path: PropTypes.func,
        preflight_in_progress_message: PropTypes.string,
        preflight_title_suffix: PropTypes.func,
        transaction_title: PropTypes.func,
      })
    }).isRequired,
  };

  state = {
    preflightInProgress: true,
  };

  componentDidMount() {
    const {
      preflightAccount,
      preflightTransactionType,
      transactionPreview,
      preflightLoaderOpen,
      text,
      closeTransactionLoader,
      history
    } = this.props;
    if (preflightLoaderOpen) {
      transactionPreview(preflightAccount.accountId, preflightTransactionType)
        .then(() => {
          const { previewDetails } = this.props;
          if (previewDetails.canContinue && previewDetails.errors.length === 0) {
            // preflight success
            closeTransactionLoader();
            history.push(text.Transactions.nav_path(preflightAccount.accountId, preflightTransactionType, 'new', ':transactionId', preflightAccount.AccountGroupId));
          }
          else {
            this.setState({
              preflightInProgress: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            preflightInProgress: false,
          });
        });
    }
  }

  render() {
    const { previewDetails, preflightLoaderOpen, closeTransactionLoader, preflightTransactionType, text } = this.props;
    const { preflightInProgress } = this.state;

    const preflightTitle = text.Transactions.transaction_title(preflightTransactionType);
    const preflightTitleSuffix = text.Transactions.preflight_title_suffix(preflightTransactionType);

    return (
      <Modal
        show={preflightLoaderOpen}
        title=''
        onCloseModal={() => closeTransactionLoader()}
        actionButtons={[
          {
            label: text.Transactions.btn_cancel,
            action: () => closeTransactionLoader(),
            disabled: preflightInProgress,
            loading: preflightInProgress,
          },
        ]}
        fullScreen={false}
      >
        <div className={styles.preparingModal}>
          {preflightInProgress && [
            <div
              key='message'
              className={styles.preparingMessage}
            >
              {text.Transactions.preflight_in_progress_message}
            </div>,
            <LoadingOverlay
              key='indicator'
              show={true}
              width='100%'
            />
          ]}
          {!preflightInProgress && !previewDetails.canContinue && previewDetails.errors.length > 0 && (
            <div>
              <div style={{ marginBottom: '10px' }}><strong>{preflightTitle}</strong>{preflightTitleSuffix}</div>
              {previewDetails.errors.map((error, index) => (
                <div
                  key={index}
                  className={styles.preparingError}
                >
                  <Icon>close</Icon>
                  <span>{error}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}


export default withRouter(connect(select, {
  closeTransactionLoader,
  openPreflightModal,
  transactionPreview,
})(LanguageHOC(PreflightLoader)));
