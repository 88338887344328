export const ANNUAL_EVENT = {
  display: 'Annual Event',
  id: 'Other'
};
export const BIRTHDAY = {
  display: 'Birthday',
  id: 'Birthday'
};
export const DISABLED_DATES = [29, 30, 31];
export const MONTHLY = 'Monthly';
export const ONE_TIME = 'One-Time';
export const TWICE_MONTHLY = 'Twice Monthly';
export const YEARLY = 'Yearly';
export const SPECIAL = 'Special';
export const NON_SPECIAL = 'Non-Special';