/*
*
* AoContactInput Component
*
*/
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  TextField,
} from '@mui/material';

import {
  PhoneNumberInput,
  Dropdown,
} from '@frontend/common';

import { RELATIONSHIPS } from '../../constants';

import styles from '../styles.module.scss';

export class AoContactInput extends Component {
  static propTypes = {
    applicationInfoErrors: PropTypes.object.isRequired,
    applicationInfo: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    onAoContactInput: PropTypes.func.isRequired,
    text: PropTypes.shape({
      LpoaAccount: PropTypes.object.isRequired,
    }).isRequired,
  }

  render() {
    const {
      applicationInfoErrors,
      applicationInfo,
      title,
      subtitle,
      onAoContactInput,
      text: { LpoaAccount: { ao_contact } },
    } = this.props;

    return (
      <Fragment>
        <div className={styles.sectionTitle}>{title}</div>
        {subtitle ? <div className={styles.sectionSubTitle}>{subtitle}</div> : <div className={styles.sectionSubTitle}><div className={styles.emptySubtitle} /></div>}
        <TextField
          autoComplete='off'
          error={Boolean(applicationInfoErrors.ao.contact.email)}
          variant='filled'
          fullWidth
          helperText={applicationInfoErrors.ao.contact.email}
          label={ao_contact.email_label}
          onChange={(e) => onAoContactInput(e.target.value, 'email')}
          value={applicationInfo.ao.contact.email}
        />
        <PhoneNumberInput
          autoComplete='off'
          label={ao_contact.home_phone_label}
          variant='filled'
          onChange={({ formattedValue }) => onAoContactInput(formattedValue, 'homePhone')}
          value={applicationInfo.ao.contact.homePhone}
          fullWidth
          errorText={applicationInfoErrors.ao.contact.homePhone}
        />
        <PhoneNumberInput
          autoComplete='off'
          label={ao_contact.work_phone_label}
          variant='filled'
          onChange={({ formattedValue }) => onAoContactInput(formattedValue, 'workPhone')}
          value={applicationInfo.ao.contact.workPhone}
          fullWidth
          errorText={applicationInfoErrors.ao.contact.workPhone}
        />
        <PhoneNumberInput
          autoComplete='off'
          label={ao_contact.other_phone_label}
          variant='filled'
          onChange={({ formattedValue }) => onAoContactInput(formattedValue, 'otherPhone')}
          value={applicationInfo.ao.contact.otherPhone}
          fullWidth
          errorText={applicationInfoErrors.ao.contact.otherPhone}
        />
        <Dropdown
          FormControlProps={{ variant: 'filled' }}
          label={ao_contact.relationship_label}
          onChange={value => onAoContactInput(value, 'relationship')}
          options={RELATIONSHIPS}
          value={applicationInfo.ao.contact.relationship}
        />
      </Fragment>
    );
  }
}


export default LanguageHOC(AoContactInput);
