import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Drawer,
  Divider,
} from '@mui/material';
import { IconBtnTooltip } from '@frontend/common';

import styles from './styles.module.scss';


LearnMore.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  form400: PropTypes.string.isRequired,
  form300: PropTypes.string.isRequired,
  text: PropTypes.shape({
    Transfers: PropTypes.shape({ 
      text_learn_more: PropTypes.func,
    })
  }),
};

export function LearnMore({ open, onClose, form400, form300, text: { Transfers }, }) {


  const renderTerms = () => (
    <div className={styles.termsContainer}>
      <div className={styles.closeIcon}>
        <IconBtnTooltip
          icon='close'
          onClick={onClose}
        />
      </div>
      <Divider />
      <div className={styles.terms}>
        {Transfers.text_learn_more(form400, form300)}
      </div>
    </div>
  );

  return (
    <Drawer anchor='bottom' open={open} onClose={onClose}>
      {renderTerms()}
    </Drawer>
  );
}

export default LanguageHOC(LearnMore);