/*
*
* Step1Recipient Component
* Recipient
*
*/

import React from 'react';
import PropTypes from 'prop-types';

import {
  ACCOUNT_OWNER,
  BENEFICIARY,
  K12_ED,
  HIGHER_ED,
  SCHOOL,
  RECURRING,
} from '../../../constants';

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';

Step1Recipient.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  recipient: PropTypes.string.isRequired,
  frequency: PropTypes.string.isRequired,
  withdrawalType: PropTypes.string.isRequired,
  recipientTypes: PropTypes.array.isRequired,
  radioButtonLabelGenerate: PropTypes.func.isRequired,
  recipientHandle: PropTypes.func.isRequired,
  recipientError: PropTypes.string.isRequired,
};

export function Step1Recipient(props) {
  const {
    isEditing,
    recipient,
    frequency,
    withdrawalType,
    recipientTypes,
    radioButtonLabelGenerate,
    recipientHandle,
    recipientError,
  } = props;

  const accountOwner = recipientTypes.find(type => type.id === ACCOUNT_OWNER);
  const beneficiary = recipientTypes.find(type => type.id === BENEFICIARY);
  const school = recipientTypes.find(type => type.id === SCHOOL);
  const isRecurring = frequency === RECURRING;

  if (withdrawalType === K12_ED) {
    return radioButtonLabelGenerate(accountOwner);
  }
  else if (withdrawalType === HIGHER_ED) {
    return (
      <FormControl
        error={Boolean(recipientError)}
      >
        <RadioGroup
          value={recipient}
          onChange={recipientHandle}
        >
          <FormControlLabel
            label={radioButtonLabelGenerate(accountOwner)}
            value={ACCOUNT_OWNER}
            control={<Radio disableRipple />}
            disabled={isEditing}
          />
          <FormControlLabel
            label={radioButtonLabelGenerate(beneficiary)}
            value={BENEFICIARY}
            control={<Radio disableRipple />}
            disabled={isEditing}
          />
          {!isRecurring &&
            <FormControlLabel
              label={radioButtonLabelGenerate(school)}
              value={SCHOOL}
              control={<Radio disableRipple />}
              disabled={isEditing}
            />
          }
        </RadioGroup>
        {recipientError &&
          <FormHelperText>{recipientError}</FormHelperText>
        }
      </FormControl>
    );
  }
  else {
    return (
      <FormControl error={Boolean(recipientError)}>
        <RadioGroup
          value={recipient}
          onChange={recipientHandle}
        >
          <FormControlLabel
            label={radioButtonLabelGenerate(accountOwner)}
            value={ACCOUNT_OWNER}
            control={<Radio disableRipple />}
            disabled={isEditing}
          />
          <FormControlLabel
            label={radioButtonLabelGenerate(beneficiary)}
            value={BENEFICIARY}
            control={<Radio disableRipple />}
            disabled={isEditing}
          />
        </RadioGroup>
        {recipientError && <FormHelperText>{recipientError}</FormHelperText>}
      </FormControl>
    );
  }
}

export default Step1Recipient;