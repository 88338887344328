/*
*
* AccountsInGroup Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
} from '@frontend/common';
import LanguageHOC from 'utils/translations/LanguageHOC';

AccountsInGroup.propTypes = {
  groupAccounts: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProportionalSelect: PropTypes.func.isRequired,
  proportionalDisabled: PropTypes.bool.isRequired,
  text: PropTypes.shape({
    Withdrawals: PropTypes.shape({
      withdraw_funds_modal: PropTypes.shape({
        btn_all_accts_label_long: PropTypes.string,
        btn_all_accts_label_short: PropTypes.string,
        btn_one_acct_label: PropTypes.string,
        listItem1: PropTypes.string,
        listItem2: PropTypes.string,
        modal_title: PropTypes.string,
        p1: PropTypes.string,
        p_all_accts_disabled: PropTypes.string,
        table_column1_title: PropTypes.string,
        table_column2_title: PropTypes.string,
        table_column3_title: PropTypes.string,
        title: PropTypes.string,
      })
    })
  }).isRequired
};

export function AccountsInGroup({
  groupAccounts,
  loading,
  onClose,
  onProportionalSelect,
  open,
  proportionalDisabled,
  text
}) {
  const tableData = groupAccounts.map(account => ({
    accountId: account.AccountId,
    accountNumber: account.AccountNumber,
    availableBalanceNumber: account.availableForWithdrawalNum,
    optionDescription: account.OptionName,
  }));

  const { Withdrawals: { withdraw_funds_modal } } = text;

  return (
    <Modal
      actionButtons={[
        {
          label: withdraw_funds_modal.btn_one_acct_label,
          action: onClose,
        },
        {
          label: window.innerWidth <= 1200 ? withdraw_funds_modal.btn_all_accts_label_short : withdraw_funds_modal.btn_all_accts_label_long,
          action: onProportionalSelect,
          disabled: proportionalDisabled,
        }
      ]}
      show={open && !loading}
      title={withdraw_funds_modal.modal_title}
      onCloseModal={onClose}
    >
      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{withdraw_funds_modal.title}</p>
      <p>{withdraw_funds_modal.p1}</p>
      <ol>
        <li>{withdraw_funds_modal.listItem1}</li>
        <li>{withdraw_funds_modal.listItem2}</li>
      </ol>
      {proportionalDisabled && <p>{withdraw_funds_modal.p_all_accts_disabled}</p>}
      <div style={{ minHeight: '175px' }}>
        <SmartTable
          idKey='accountId'
          rows={tableData}
          columns={[
            {
              key: 'accountNumber',
              title: withdraw_funds_modal.table_column1_title,
              type: 'number',
            },
            {
              key: 'optionDescription',
              title: withdraw_funds_modal.table_column2_title,
              type: 'string',
              hideOn: ['phone'],
              customStyle: { width: '1.5fr' }
            },
            {
              key: 'availableBalanceNumber',
              title: withdraw_funds_modal.table_column3_title,
              type: 'currency',
              hideOn: ['phone'],
            },
          ]}
        >
          <TableContainer minWidth='100%'>
            <TableHeader />
            <TableRows />
          </TableContainer>
          <TablePagination />
        </SmartTable>
      </div>
    </Modal>
  );
}

export default LanguageHOC(AccountsInGroup);