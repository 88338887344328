import { cloneDeep } from 'lodash';

import {
  AGENT_SEARCH,
  AGENT_UPDATE,
  REGISTRATION_TYPE,
} from './constants';

const initialState = {
  agent: {},
  updatedAgent: {},
  registrationType: '',
};

function SetupUserReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case AGENT_SEARCH: {
      const { data } = action.payload;
      newState.agent = data === '' ? {} : data;
      return newState;
    }

    case AGENT_UPDATE: {
      const { data } = action.payload;
      newState.updatedAgent = data === '' ? {} : data;
      return newState;
    }

    case REGISTRATION_TYPE: {
      newState.registrationType = action.payload;
      return newState;
    }

    default:
      return state;
  }
}

export default SetupUserReducer;