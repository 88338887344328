import React from 'react';
import PropTypes from 'prop-types';

import {
  ACCOUNT_OWNER,
  BENEFICIARY,
  SCHOOL,
} from '../../constants';

import styles from './styles.module.scss';

BankInfo.propTypes = {
  recipientAddress: PropTypes.object.isRequired,
  paymentAccount: PropTypes.object.isRequired,
  recipient: PropTypes.string.isRequired,
};

export function BankInfo(props) {
  const {
    recipient,
    paymentAccount,
    recipientAddress,
  } = props;

  let recipientName;
  switch (recipient) {
    case ACCOUNT_OWNER:
      recipientName = recipientAddress.agent.name;
      break;
    case BENEFICIARY:
      recipientName = recipientAddress.beneficiary.name;
      break;
    case SCHOOL:
      recipientName = recipientAddress.school.name;
      break;
    default:
      recipientName = 'Recipient not found.';
  }
  const paymentAccountDisplay = paymentAccount.BankAccountId
    ? `${paymentAccount.BankName} ${paymentAccount.BankAccountType}, ${paymentAccount.MaskedBankAccountNumber}`
    : `${paymentAccount.Title}, ${paymentAccount.CardNumber}`;

  return (
    <div className={styles.checkAddressContainer}>
      <p>{recipientName}</p>
      <p>{paymentAccountDisplay}</p>
    </div>
  );
}

export default BankInfo;
