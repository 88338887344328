/*
*
* OptionChanges Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import OptionChangeDetails from './OptionChangeDetails';
import AccountsInGroupNotice from './AccountsInGroupNotice';
import NewOptions from './NewOptions';
import AccountInfo from './AccountInfo';

import {
  Breadcrumbs,
  LoadingOverlay,
} from '@frontend/common';

import { connect } from 'react-redux';

import {
  getPreTransactionDetails,
} from 'components/Features/protected/Accounts/Transactions/actions';

import {
  getPredefinedOptions,
} from './actions';

import styles from './styles.module.scss';

const select = (state) => ({
  preTransactionDetails: state.transactions.preTransactionDetails,
  preflightAccount: state.transactions.preflightAccount,
  predefinedOptions: state.optionChanges.predefinedOptions,
  selectedTransaction: state.transactions.selectedTransaction,
});

export class OptionChanges extends React.Component {
  static propTypes = {
    getPredefinedOptions: PropTypes.func.isRequired,
    predefinedOptions: PropTypes.arrayOf(PropTypes.shape({
      OptionId: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      isStatic: PropTypes.bool.isRequired,
      isEnrollmentDate: PropTypes.bool.isRequired,
    })),
    preTransactionDetails: PropTypes.shape({
      AccountType: PropTypes.string,
      AccountId: PropTypes.number,
      AccountNumber: PropTypes.number,
      accountType: PropTypes.string,
      agentName: PropTypes.string,
      beneficiaryName: PropTypes.string,
      OptionName: PropTypes.string,
      groupAccounts: PropTypes.arrayOf(PropTypes.shape({
        AccountId: PropTypes.number,
        AccountNumber: PropTypes.number,
        OptionName: PropTypes.string,
        marketValue: PropTypes.string,
      })),
      OptionChangeCreate: PropTypes.bool,
      OptionId: PropTypes.number,
      RemainingOptionChanges: PropTypes.number,
      totalMarketValue: PropTypes.string,
    }).isRequired,
    getPreTransactionDetails: PropTypes.func.isRequired,
    preflightAccount: PropTypes.shape({
      AccountGroupId: PropTypes.number.isRequired,
    }).isRequired,
    text: PropTypes.shape({
      Transactions: PropTypes.shape({
        nav_path: PropTypes.func,
      }).isRequired,
      Accounts: PropTypes.shape({
        accounts_path: PropTypes.string,
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
      }),
      AccountDetails: PropTypes.shape({
        nav_path: PropTypes.func,
        nav_title: PropTypes.string,
      }),
    }).isRequired,
    selectedTransaction: PropTypes.object.isRequired,
  };

  state = {
    accountId: this.props.match.params.accountId,
    loading: false,
    openModal: false,
    isEditing: window.location.href.search('edit') > -1,
  };

  toggleAccountsInGroupNotice = () => {
    this.setState({ openModal: !this.state.openModal });
  }

  componentDidMount() {
    const {
      predefinedOptions,
      getPredefinedOptions,
      getPreTransactionDetails,
    } = this.props;
    const { accountId } = this.state;
    let accountGroupId;

    // selectively get resources for this option change transaction
    this.setState({ loading: true });
    if (!this.state.isEditing) {
      const { preflightAccount } = this.props;
      accountGroupId = preflightAccount.AccountGroupId;
    }
    else {
      // get sheduled transaction details and predefined option
      const { selectedTransaction } = this.props;
      accountGroupId = selectedTransaction.AccountGroupId;
    }
 
    getPreTransactionDetails(accountId, accountGroupId)
      .then(() => {
        const preTransactionDetails = this.props.preTransactionDetails;
        if (preTransactionDetails.groupAccounts.length > 1)
          this.toggleAccountsInGroupNotice();
      })
      .then(() => {
        if (predefinedOptions.length === 0) {
          return getPredefinedOptions();
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { 
      preTransactionDetails,
      text: { Accounts, AccountDetails } 
    } = this.props;
    const { loading, openModal, isEditing, accountId } = this.state;
    
    return (
      <div className={styles.container}>
        <LoadingOverlay show={loading} key='optionChange' textAlign='center'>
          <div className={styles.optionChanges}>
            <div className={styles.pageNav}>
              <Breadcrumbs
                crumbs={[
                  {
                    title: Accounts.nav_title,
                    link: `/${Accounts.accounts_path}`, // TBD
                  },
                  {
                    title: AccountDetails.nav_title,
                    link: AccountDetails.nav_path(accountId),
                  },
                  {
                    title: 'Change Investment Option',
                    link: '#',
                  },
                ]}
              />
            </div>
            <div className={styles.accountInfo}>
              <AccountInfo
                AccountsInGroupNoticeShow={this.toggleAccountsInGroupNotice}
                agentName={preTransactionDetails.agentName}
                beneficiaryName={preTransactionDetails.beneficiaryName}
                accountNumber={preTransactionDetails.AccountNumber}
                totalMarketValue={preTransactionDetails.totalMarketValue}
                accountType={preTransactionDetails.AccountType}
                accountsInGroup={preTransactionDetails.groupAccounts.length}
              />
            </div>
            <div className={styles.optionChangeDetails}>
              <OptionChangeDetails
                currentOptionName={preTransactionDetails.OptionName}
                remainingOptionChanges={preTransactionDetails.RemainingOptionChanges}
              />
            </div>
            <div className={styles.newOptionChanges}>
              <NewOptions
                currentOptionName={preTransactionDetails.OptionName}
                currentOptionId={preTransactionDetails.OptionId}
                isEditing={isEditing}
                parentLoading={loading}
              />
            </div>
            <AccountsInGroupNotice
              groupAccounts={preTransactionDetails.groupAccounts}
              open={openModal}
              onClose={this.toggleAccountsInGroupNotice}
            />
          </div>
        </LoadingOverlay>
      </div>
    );
  }

}

export default connect(select, {
  getPreTransactionDetails,
  getPredefinedOptions,
})(LanguageHOC(OptionChanges));
