/*
*
* TransactionHistory Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import * as validator from 'utils/helpers/form_validation';
import {
  Button,
} from '@mui/material';
import {
  CardAligned,
  CardBody,
  CardTitle,
  LoadingOverlay,
  DatePicker,
  getMSTfromUTC,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
} from '@frontend/common';
import {
  getTransactionDetails,
  getAllTransactionsExport,
  getAllTransactions,
} from 'components/Features/protected/Accounts/actions';
import { transactionsByDateFilter } from 'utils/helpers/data_filters';

import TransactionDetails from './TransactionDetails';

import transactionHubStyles from '../styles.module.scss';
import styles from './styles.module.scss';

const select = (state) => ({
  transaction: state.accounts.transaction,
  transactionsAll: state.accounts.transactionsAll,
  loadedTransactions: state.accounts.loadedTransactions,
  hasScholarshipAccount: state.accounts.accountList.some(account => account.accountType === 'Scholarship'),
});

export class TransactionHistory extends React.Component {
  static propTypes = {
    hasScholarshipAccount: PropTypes.bool.isRequired,
    getAllTransactions: PropTypes.func.isRequired,
    transactionsAll: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        accountId: PropTypes.number.isRequired,
        accountNumber: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        basis: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        taxYear: PropTypes.string,
        milestone: PropTypes.string,
      })
    ).isRequired,
    transaction: PropTypes.shape({
      id: PropTypes.number.isRequired,
      date: PropTypes.string,
      type: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      payeeName: PropTypes.string,
      total: PropTypes.number.isRequired,
      basis: PropTypes.number.isRequired,
      bankName: PropTypes.string.isRequired,
      bankAccountNumber: PropTypes.string.isRequired,
      bankAccountType: PropTypes.string.isRequired,
      transactionDetails: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          fundName: PropTypes.string,
          units: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
          amount: PropTypes.number.isRequired,
        })
      )
    }).isRequired,
    loadedTransactions: PropTypes.array.isRequired,
    getTransactionDetails: PropTypes.func.isRequired,
    getAllTransactionsExport: PropTypes.func.isRequired,
    text: PropTypes.shape({
      TransactionHistory: PropTypes.shape({
        btn_export: PropTypes.string,
        btn_open: PropTypes.string,
        err_date_past_today: PropTypes.string,
        err_from_after_to: PropTypes.string,
        err_to_before_from: PropTypes.string,
        head_title: PropTypes.string,
        lbl_from_date: PropTypes.string,
        lbl_to_date: PropTypes.string,
        text_acct_number: PropTypes.string,
        text_bene_name: PropTypes.string,
        text_option: PropTypes.string,
        text_amount: PropTypes.string,
        text_basis: PropTypes.string,
        text_date: PropTypes.string,
        text_no_transactions: PropTypes.string,
        text_tax_year: PropTypes.string,
        text_transaction_type: PropTypes.string,
        text_view_details: PropTypes.string,
        text_milestone: PropTypes.string,
      })
    }).isRequired,
  };

  state = {
    loading: false,
    transactionsAll: [],
    showTransaction: false,
    loadingTransaction: false,
    selectedTransaction: null,
    filterDates: {
      startDate: dayjs().subtract(6, 'month'),
      endDate: getMSTfromUTC(),
    },
    formErrors: {},
    isExporting: false,
  };

  toggleDetailsDrawer = () => {
    this.setState({ showTransaction: !this.state.showTransaction });
  }

  transactionDetailsShow = (accountId, transactionId) => {
    // first check if transaction was already loaded if not call api
    const foundTransaction = this.props.loadedTransactions.find(transaction => transaction.id === transactionId);
    if (foundTransaction) {
      this.setState({ selectedTransaction: foundTransaction });
      this.toggleDetailsDrawer();
    }
    else {
      this.setState({ loadingTransaction: true });
      this.props.getTransactionDetails(accountId, transactionId)
        .then(() => {
          this.setState({
            loadingTransaction: false,
            selectedTransaction: this.props.transaction
          });
          this.toggleDetailsDrawer();
        })
        .catch(() => null);
    }
  }

  onInputChange = (name, value) => {
    this.setState({
      filterDates: {
        ...this.state.filterDates,
        [name]: value,
      },
    }, () => this.filterTransactions(name));
  }

  filterTransactions = (fieldName) => {
    const { transactionsAll } = this.props;
    const { filterDates } = this.state;

    if (this.formValidate(fieldName)) {
      let filteredTransactions = transactionsAll; // default to props transactions, if dates are removed
      if (filterDates.startDate && filterDates.endDate) { // if both dates are provided, then get filtered transactions
        filteredTransactions = transactionsByDateFilter(transactionsAll, filterDates);
      }
      this.setState({ transactionsAll: filteredTransactions });
    }
  }

  formValidate = (fieldName) => {
    const { TransactionHistory } = this.props.text;
    const { startDate, endDate } = this.state.filterDates;
    const formErrors = {};

    // startDate validation
    formErrors.startDate = validator.calendarValidator(startDate);
    if (dayjs(startDate).isAfter(dayjs(), 'day')) {
      formErrors.startDate = TransactionHistory.err_date_past_today;
    }
    if (dayjs(startDate).isAfter(dayjs(endDate), 'day') && fieldName === 'startDate') {
      formErrors.startDate = TransactionHistory.err_from_after_to;
    }

    // endDate validation
    formErrors.endDate = validator.calendarValidator(endDate);
    if (dayjs(endDate).isBefore(dayjs(startDate), 'day') && fieldName === 'endDate') {
      formErrors.endDate = TransactionHistory.err_to_before_from;
    }

    // if both dates are blank, empty out errors
    if (!startDate && !endDate) {
      formErrors.startDate = '';
      formErrors.endDate = '';
    }

    this.setState({ formErrors });
    const hasErrors = Object.keys(formErrors).some(field => formErrors[field] !== '');

    return !hasErrors;
  }

  handleExportTransactions = () => {
    const { getAllTransactionsExport, transactionsAll } = this.props;
    const { startDate, endDate } = this.state.filterDates;

    let canRunExport = true; // default to true for when no dates have been populated
    if (startDate || endDate) { // if either date is populated, run validation 
      canRunExport = this.formValidate('startDate'); // default fieldName to startDate for validator
    }

    if (canRunExport) {
      const filterDates = {
        startDate: startDate ? dayjs(startDate).format('MM-DD-YYYY') : transactionsAll[transactionsAll.length - 1].date, // if no dates, default startDate to last in array as that is the earliest date,
        endDate: endDate ? dayjs(endDate).format('MM-DD-YYYY') : transactionsAll[0].date, // if no dates, default endDate to first in array as that is the latest date,
      };
      this.setState({ isExporting: true });
      getAllTransactionsExport(filterDates)
        .then(({ payload }) => {
          const file = new Blob([payload.data], { type: 'text/csv;encoding:utf-8' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.setAttribute('download', 'My529TransactionHistory.csv'); // sets filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.setState({ isExporting: false });
        })
        .catch(() => this.setState({ isExporting: false, }));
    }
  }

  allTransactionsGet = () => {
    this.setState({ loading: true });
    return this.props.getAllTransactions()
      .then(() => {
        this.setState({
          loading: false,
          transactionsAll: this.props.transactionsAll
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  renderDateFilter = () => {
    const { TransactionHistory } = this.props.text;
    const { formErrors, filterDates: { startDate, endDate, }, isExporting, transactionsAll, loading, } = this.state;
    const noTransactions = transactionsAll.length === 0;

    return (
      <div className={styles.dateFilterContainer}>
        <div className={styles.dateFilters}>
          <div className={styles.startDate}>
            <DatePicker
              error={Boolean(formErrors.startDate)}
              fullWidth
              helperText={formErrors.startDate}
              variant='filled'
              label={TransactionHistory.lbl_from_date}
              maxDate={dayjs()}
              onChange={e => this.onInputChange('startDate', e ? e.toDate() : e)}
              value={startDate || null}
              disabled={loading}
            />
          </div>
          <div className={styles.endDate}>
            <DatePicker
              error={Boolean(formErrors.endDate)}
              fullWidth
              helperText={formErrors.endDate}
              variant='filled'
              label={TransactionHistory.lbl_to_date}
              maxDate={dayjs()}
              onChange={e => this.onInputChange('endDate', e ? e.toDate() : e)}
              value={endDate || null}
              disabled={loading}
            />
          </div>
        </div>
        <div className={styles.exportButton}>
          <LoadingOverlay show={isExporting}>
            <Button
              disabled={isExporting || noTransactions || loading}
              onClick={this.handleExportTransactions}
              variant='contained'
            >
              {TransactionHistory.btn_export}
            </Button>
          </LoadingOverlay>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // initialize selectedTransaction even if empty  
    this.setState({
      selectedTransaction: this.props.transaction,
    });
    this.allTransactionsGet()
      .then(() => {
        // make sure the data filtering happens after the data is available
        this.filterTransactions('startDate');
      });
  }

  render() {
    const {
      hasScholarshipAccount,
      text: { TransactionHistory }
    } = this.props;

    const {
      showTransaction,
      loadingTransaction,
      selectedTransaction,
      transactionsAll,
      loading
    } = this.state;
    const columns = [
      {
        key: 'accountNumber',
        title: TransactionHistory.text_acct_number,
        type: 'number',
      },
      {
        key: 'beneficiaryName',
        title: TransactionHistory.text_bene_name,
        type: 'string',
        hideOn: ['phone'],
      },
      {
        key: 'option',
        title: TransactionHistory.text_option,
        type: 'string',
        hideOn: ['phone'],
      },
      {
        key: 'type',
        title: TransactionHistory.text_transaction_type,
        type: 'string',
        hideOn: ['phone'],
      },
      {
        key: 'basis',
        title: TransactionHistory.text_basis,
        type: 'currency',
        hideOn: ['phone'],
      },
      {
        key: 'amount',
        title: TransactionHistory.text_amount,
        type: 'currency',
      },
      {
        key: 'date',
        title: TransactionHistory.text_date,
        type: 'dateString',
        hideOn: ['phone'],
      },
      {
        key: 'taxYear',
        title: TransactionHistory.text_tax_year,
        type: 'string',
        hideOn: ['phone'],
      },
    ];
    if (hasScholarshipAccount) { // add milestone column, if account type is scholarship
      columns.push({
        key: 'milestone',
        title: TransactionHistory.text_milestone,
        type: 'string',
        hideOn: ['phone', 'tablet'],
      });
    }

    return (

      <CardAligned
        className={transactionHubStyles.transactionsCard}
        formProps={{ style: { display: 'block' } }}
      >
        <CardTitle>{TransactionHistory.head_title}</CardTitle>
        <CardBody>
          <LoadingOverlay show={loadingTransaction} width='100%'>
            {this.renderDateFilter()}
            <div className={styles.tableContainer}>
              <SmartTable
                idKey={'id'}
                rows={transactionsAll}
                emptyMessage={TransactionHistory.text_no_transactions}
                loading={loading}
                actions={[
                  {
                    type: 'icon',
                    icon: 'arrow_left',
                    onSelect: row => this.transactionDetailsShow(row.accountId, row.id),
                    iconTitle: TransactionHistory.btn_open,
                  },
                ]}
                columns={columns}
              >
                <TableToolbar />
                <TableContainer minWidth='100%'>
                  <TableHeader />
                  <TableRows
                    onRowClick={row => this.transactionDetailsShow(row.accountId, row.id)}
                  />
                </TableContainer>
                <TablePagination />
              </SmartTable>

            </div >

            <TransactionDetails
              open={showTransaction}
              onClose={this.toggleDetailsDrawer}
              transaction={selectedTransaction}
            />
          </LoadingOverlay>
        </CardBody>
      </CardAligned>
    );
  }
}

export default withRouter(connect(select, {
  getTransactionDetails,
  getAllTransactionsExport,
  getAllTransactions,
})(LanguageHOC(TransactionHistory)));
