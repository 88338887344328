/*
*
* NotificationsDrawer Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Divider,
  Icon,
  SwipeableDrawer,
} from '@mui/material';
import { IconBtnTooltip, LoadingOverlay } from '@frontend/common';
import {
  toggleNotifications,
  setNotificationsViewed,
} from 'components/AppRoot/Navigation/actions';
import { NOTIFICATION_TYPES, } from 'components/AppRoot/Navigation/constants';
import styles from './styles.module.scss';

const select = (state) => ({
  notificationsOpen: state.session.notificationsOpen,
  notifications: state.session.notifications,
});

export class NotificationsDrawer extends React.Component {

  static propTypes = {
    notificationsOpen: PropTypes.bool.isRequired,
    notifications: PropTypes.array.isRequired,
    toggleNotifications: PropTypes.func.isRequired,
    setNotificationsViewed: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Notifications: PropTypes.shape({
        text_no_notifications: PropTypes.string,
      }),
    }),
  };

  state = {
    loading: false
  }

  getIconName = notificationType => {
    switch (notificationType) {
      case NOTIFICATION_TYPES.CONTRIBUTION: return 'attach_money';
      case NOTIFICATION_TYPES.GIFTING: return 'card_giftcard';
      case NOTIFICATION_TYPES.ACCOUNT: return 'account_circle';
      case NOTIFICATION_TYPES.BANK_ACCOUNT: return 'account_balance';
      case NOTIFICATION_TYPES.DOCUMENTS: return 'description';
      case NOTIFICATION_TYPES.INTERESTED_PARTY: return 'supervised_user_circle';

      default: return '';
    }
  }

  redirectAndToggle = (url) => {
    // trying avoid calling async api on component unmount
    // refresh notifications
    this.setState({ loading: true });
    this.props.setNotificationsViewed()
      .then(() => {
        this.setState({ loading: false });
        return this.props.toggleNotifications(false);
      })
      .then(() => {
        // test if absolute path
        if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
          window.open(url, '_blank', 'noopener');
          return null;
        }
        else {
          return this.props.history.push(url);
        }
      });
  }

  toggleNotifications = (isOpen) => {
    // trying avoid calling async api on component unmount
    if (!isOpen) {
      // refresh notifications
      this.setState({ loading: true });
      this.props.setNotificationsViewed()
        .then(() => {
          this.setState({ loading: false });
          this.props.toggleNotifications(isOpen);
        });
    }
    else
      this.props.toggleNotifications(isOpen);
  }

  render() {
    const { loading } = this.state;
    const {
      notifications,
      notificationsOpen,
      text: { Notifications },
    } = this.props;

    return (
      <SwipeableDrawer
        anchor='right'
        open={notificationsOpen}
        onClose={() => this.toggleNotifications(false)}
        onOpen={() => this.toggleNotifications(true)}
      >
        <LoadingOverlay show={loading}>
          <div>
            <div className={styles.notificationsTitle}>
              <div>Notifications</div>
              <div className={styles.closeIcon}>
                <IconBtnTooltip
                  icon='close'
                  onClick={() => this.toggleNotifications(false)}
                />
              </div>
            </div>
            <Divider />
            <div>
              {notifications.length > 0 ?
                notifications.map(notification => {
                  const icon = this.getIconName(notification.Type);
                  return [
                    <div
                      key={notification.Title}
                      className={`${styles.notificationContainer} ${!notification.ViewedDate ? styles.notificationContainerNotViewed : ''}`}
                      onClick={() => this.redirectAndToggle(notification.URL)}
                    >
                      <div className={styles.notificationsText}>
                        <div className={styles.notificationHeader}>
                          <div className={styles.iconContainer}>
                            <Icon fontSize='inherit'>{icon}</Icon>
                          </div>
                          <span>
                            <span className={styles.notificationType}>{notification.Type}</span> . <span className={styles.fromTime}>{dayjs(notification.CreatedDate).fromNow()}</span>
                          </span>
                        </div>
                        <div className={styles.notificationMessage}>{notification.Title}</div>
                        <div className={styles.notificationStatus}>{notification.Status}</div>
                      </div>
                    </div>,
                    <Divider key='divider' />
                  ];
                }) : <div className={styles.noNotifications}>{Notifications.text_no_notifications}</div>
              }
            </div>
          </div>
        </LoadingOverlay>
      </SwipeableDrawer>
    );
  }
}

export default withRouter(connect(select, {
  toggleNotifications,
  setNotificationsViewed,
})(LanguageHOC(NotificationsDrawer)));