/*
*
* GiactRejection Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  FormHelperText,
} from '@mui/material';
import {
  Modal,
} from '@frontend/common';

import styles from './styles.module.scss';

GiactRejectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseGiactRejectionModal: PropTypes.func.isRequired,
  text: PropTypes.shape({
    GiactRejectionModal: PropTypes.shape({
      btn_close: PropTypes.string,
      head_title: PropTypes.string,
      text_not_authenticated: PropTypes.string,
      text_rejection_language_1: PropTypes.string,
      text_rejection_language_2: PropTypes.string,
      text_rejection_language_3: PropTypes.string,
      text_rejection_language_4: PropTypes.func,
      text_giact_address_1: PropTypes.string,
      text_giact_address_2: PropTypes.string,
      text_giact_address_3: PropTypes.string,
      text_giact_address_4: PropTypes.string,
      text_rejection_language_5: PropTypes.string,
      text_rejection_language_6: PropTypes.string,
      text_rejection_language_7: PropTypes.string,
      text_rejection_language_8: PropTypes.string,
    })
  })
};

export function GiactRejectionModal({ open, handleCloseGiactRejectionModal, text: { GiactRejectionModal }, }) {
  
  return (
    <Modal
      title={GiactRejectionModal.head_title}
      show={open}
      onCloseModal={() => handleCloseGiactRejectionModal()}
      modal={false}
      maxWidth='md'
      fullWidth
    >
      <div className={styles.giactText}>
        <FormHelperText error={true} style={{ fontSize: 'large' }}>
          {GiactRejectionModal.text_not_authenticated}
        </FormHelperText>
        <ul>
          <li>{GiactRejectionModal.text_rejection_language_1}</li>
          <li>{GiactRejectionModal.text_rejection_language_2}</li>
        </ul>
        <h3>{GiactRejectionModal.text_rejection_language_3}</h3>
        {GiactRejectionModal.text_rejection_language_4()}
        <ul style={{ listStyleType: 'none' }} >
          <li>{GiactRejectionModal.text_giact_address_1}</li>
          <li>{GiactRejectionModal.text_giact_address_2}</li>
          <li>{GiactRejectionModal.text_giact_address_3}</li>
          <li>{GiactRejectionModal.text_giact_address_4}</li>
        </ul>
        <p>{GiactRejectionModal.text_rejection_language_5}<a href='https://www.giact.com/consumer-inquiries/' target='_blank' rel='noopener noreferrer'>https://www.giact.com/consumer-inquiries/</a></p>
        <h3>{GiactRejectionModal.text_rejection_language_6}</h3>
        <p>{GiactRejectionModal.text_rejection_language_7}<a href='mailto:info@my529.org' target='_blank' rel='noopener noreferrer'>info@my529.org</a>{GiactRejectionModal.text_rejection_language_8}</p>
      </div>
    </Modal>
  );
}

export default LanguageHOC(GiactRejectionModal);