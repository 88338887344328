/*
*
* TermsConditionsStep Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';

import parse from 'html-react-parser';
import {
  Button,
  Paper
} from '@mui/material';
import {
  Checkbox,
  IconBtnTooltip,
  notificationShow,
} from '@frontend/common';

import { createAccount } from '../actions';
import { getAccounts, getAccountsSummary } from 'components/Features/protected/Accounts/actions';
import { setDisableLeftMenu } from 'components/AppRoot/Navigation/actions';
import { getNotifications, } from 'components/AppRoot/Navigation/actions';

import styles from './styles.module.scss';

const select = (state) => ({
  faxNumber: state.static.environmentVars.FaxNumber,
  phoneNumber: state.static.environmentVars.SupportPhoneNumber,
  programDescription: state.static.documents.programDescription,
});


export class TermsConditionsStep extends React.Component {
  static propTypes = {
    getNotifications: PropTypes.func.isRequired,
    getAccounts: PropTypes.func.isRequired,
    getAccountsSummary: PropTypes.func.isRequired,
    setDisableLeftMenu: PropTypes.func.isRequired,
    createAccount: PropTypes.func.isRequired,
    faxNumber: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onBackClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    programDescription: PropTypes.string.isRequired,
    setLoadingOff: PropTypes.func.isRequired,
    setLoadingOn: PropTypes.func.isRequired,
    text: PropTypes.shape({
      TermsConditionsStep: PropTypes.shape({
        btn_back: PropTypes.string,
        btn_i_agree: PropTypes.string,
        head_terms: PropTypes.string,
        msg_account_created: PropTypes.string,
        text_terms_intro: PropTypes.string,
        text_read_understand_agree: PropTypes.func,
        text_terms1: PropTypes.array,
        text_terms2: PropTypes.array,
        text_terms3: PropTypes.func,
        text_terms4: PropTypes.string,
      })
    }).isRequired,
  };

  state = {
    termsChecked: false,
  };

  onAccountCreate = () => {
    this.props.setLoadingOn();
    this.props.setDisableLeftMenu(true);

    this.props.createAccount()
      .then(() => this.props.getAccounts()) // updates accounts list
      .then(() => this.props.getAccountsSummary()) // builds account summary object from freshly fetched account list
      .then(() => {
        this.props.setLoadingOff();
        this.props.setDisableLeftMenu(false);
        this.props.notificationShow(this.props.text.TermsConditionsStep.msg_account_created, 'success');
        this.props.getNotifications(); // refreshes app notifications
        this.props.onNextClick();

        try {
          document.cookie = 'openAcct=true'; // Qualtrics Intercept for account creation. Launches survey
          window.QSI.API.run(); // stimulate Targeting.php
          window.QSI.API.unload();
          window.QSI.API.load();
          window.QSI.API.run(); // stimulate Targeting.php
        }
        catch (error) { 
          // return null;
        }
        
      })
      .catch(() => {
        this.props.setLoadingOff();
        this.props.setDisableLeftMenu(false);
      });
  }

  render() {
    const { isLoading, faxNumber, onBackClick, phoneNumber, programDescription, text: { TermsConditionsStep } } = this.props;
    return (
      <Paper>
        <div className={styles.container}>
          <div className={styles.printButton}>
            <IconBtnTooltip
              icon='print'
              onClick={() => window.print()}
            />
          </div>
          <h1>{TermsConditionsStep.head_terms}</h1>
          <strong>{TermsConditionsStep.text_terms_intro}</strong>
          <ol>
            {TermsConditionsStep.text_terms1.map(line => <li key={line}>{line}</li>)}

            <ol type='a'>
              {TermsConditionsStep.text_terms2.map(line => <li key={line}>{line}</li>)}
            </ol>

            <li>{TermsConditionsStep.text_terms3(faxNumber, phoneNumber)}</li>
            <li>{TermsConditionsStep.text_terms4}</li>
          </ol>

          <Checkbox
            checked={this.state.termsChecked}
            color='primary'
            disabled={isLoading}
            label={<div>{parse(TermsConditionsStep.text_read_understand_agree(programDescription))}</div>}
            onChange={e => this.setState({ termsChecked: e.target.checked })}
          />

        </div>

        <div className={styles.stepperButtons}>
          <Button
            onClick={onBackClick}
            variant='text'
            color='primary'
          >
            {TermsConditionsStep.btn_back}
          </Button>
          <Button
            color='primary'
            disabled={!this.state.termsChecked || isLoading}
            onClick={this.onAccountCreate}
            variant='contained'
            type='submit'
          >
            {TermsConditionsStep.btn_i_agree}
          </Button>
        </div>
      </Paper>
    );
  }
}


export default connect(select, {
  getNotifications,
  createAccount,
  notificationShow,
  getAccounts,
  getAccountsSummary,
  setDisableLeftMenu,
})(LanguageHOC(TermsConditionsStep));
