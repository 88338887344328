import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Button,
  TextField,
  Collapse,
  FormHelperText,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@mui/material';

import {
  Dropdown,
  ZipInput,
  IconBtnTooltip,
  InfoIcon,
} from '@frontend/common';

import {
  MAIL,
  ELECTRONIC,
} from 'components/Features/protected/Accounts/Transactions/Withdrawals/constants';

import styles from './styles.module.scss';

export class School extends Component {

  static propTypes = {
    recipientAddress: PropTypes.object.isRequired,
    usStates: PropTypes.array.isRequired,
    recipientAddressHandle: PropTypes.func.isRequired,
    isEditing: PropTypes.bool,
    errors: PropTypes.shape({
      schoolError: PropTypes.string,
      street1Error: PropTypes.string,
      cityError: PropTypes.string,
      stateError: PropTypes.string,
      postalCodeError: PropTypes.string,
      attnError: PropTypes.string,
      studentIdNumError: PropTypes.string,
      deliveryType: PropTypes.string,
    }).isRequired,
    stateSelectHandle: PropTypes.func.isRequired,
    handleSchoolSearch: PropTypes.func.isRequired,
    isSchoolRetained: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Withdrawals: PropTypes.object.isRequired,
    }).isRequired,
    handleElectronicSchoolDeliveryType: PropTypes.func,
    ElectronicSchoolDeliveryType: PropTypes.string,
  };

  renderElectronicSchoolOption() {
    const {
      recipientAddress,
      isEditing,
      errors: {
        schoolError,
        deliveryType,
      },
      text: {
        Withdrawals: {
          check_mailing_text,
          ElectronicSchoolPayment_fee_msg,
          ElectronicSchoolPayment_electronic_info,
          ElectronicSchoolPayment_mail_info,
          ElectronicSchoolPayment_mail_check,
          retained_school_address_txt,
        }
      },
      handleSchoolSearch,
      isSchoolRetained,
    } = this.props;

    const { ElectronicSchoolDeliveryType } = this.props;
    const displayBene = ElectronicSchoolDeliveryType ? this.renderBeneficiary() : '';
    let displaySchoolAddress = '';
    let displayMailCheckNotification = '';
    if (ElectronicSchoolDeliveryType === MAIL) {
      displaySchoolAddress = this.renderAddress();
      displayMailCheckNotification = <div style={{ paddingBottom: '10px' }}><strong>{check_mailing_text}</strong></div>;
    }
    return (
      <>
        <div>
          <TextField
            disabled={true}
            error={Boolean(schoolError)}
            helperText={schoolError}
            style={{ width: '300px' }}
            label={'Name of School'}
            onChange={() => null}
            value={recipientAddress.school.name}
            InputProps={{ 
              endAdornment: (
                <InputAdornment position='end'>
                  {!isEditing && 
                  <IconBtnTooltip
                    icon='search'
                    onClick={handleSchoolSearch} // eslint-disable-line brace-style
                    title={'Search'}
                  />}
                </InputAdornment>
              )
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          {recipientAddress.school.IsElectronicSchoolPaymentSchool &&
          <FormControl>
            <RadioGroup
              value={ElectronicSchoolDeliveryType}
              onChange={e => this.props.handleElectronicSchoolDeliveryType(e.target.value)}
              name='deliveryType'
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel value={ELECTRONIC} control={<Radio disabled={isEditing} />} label={ElectronicSchoolPayment_fee_msg} style={{ marginRight: '0px' }} />
                <InfoIcon message={ElectronicSchoolPayment_electronic_info.map(msg => <p key={msg}>{msg}</p>)} />
              </div>
              <div className={styles.checkMailingMessage}>
                <div><FormControlLabel value={MAIL} control={<Radio disabled={isEditing} />} label={ElectronicSchoolPayment_mail_check} style={{ marginRight: '2px' }} /></div>
                <div><InfoIcon message={ElectronicSchoolPayment_mail_info.map(msg => <p key={msg}>{msg}</p>)} /></div>
              </div>
              <div style={{ textAlign: 'center', color: 'red', fontSize: '12px' }}>{deliveryType}</div>
            </RadioGroup>
          </FormControl>}
        </div>
        <div>
          {isSchoolRetained && ElectronicSchoolDeliveryType === MAIL && <div className={styles.retainedSchoolAddress}>{retained_school_address_txt}</div>}
          {displayMailCheckNotification}
          {displaySchoolAddress}
          {displayBene}
        </div>
      </>
    );
  }

  renderRegularOption() {
    const {
      recipientAddress,
      isEditing,
      errors: {
        schoolError,
      },
      handleSchoolSearch,
      isSchoolRetained,
      text: {
        Withdrawals: {
          check_mailing_text,
          retained_school_address_txt,
          ElectronicSchoolPayment_mail_info,
        }
      },
    } = this.props;

    return (
      <div className={styles.schoolAddressContainer} key={recipientAddress.school.schoolId}>
        <div className={styles.searchSchools}>
          {recipientAddress.school.name === ''
            ?
            <FormControl error={Boolean(schoolError)}>
              <Button
                onClick={handleSchoolSearch} // eslint-disable-line brace-style
                variant='contained'
                disabled={isEditing}
              >
                Search Eligible Schools
              </Button>
              {schoolError && <FormHelperText style={{ textAlign: 'center' }}>{schoolError}</FormHelperText>}
            </FormControl>
            : 
            <TextField
              disabled={true}
              error={Boolean(schoolError)}
              helperText={schoolError}
              style={{ width: '300px' }}
              label={'Name of School'}
              onChange={() => null}
              value={recipientAddress.school.name}
              InputProps={{ endAdornment: (
                <InputAdornment position='end'>
                  {!isEditing &&
                  <IconBtnTooltip
                    icon='search'
                    onClick={handleSchoolSearch} // eslint-disable-line brace-style
                    title={'Search'}
                  />}
                </InputAdornment>)
              }}
            />
          }
        </div>
  
        <Collapse in={recipientAddress.school.schoolId !== ''}>
          <div className={styles.schoolAddressContainer}>
            <div className={styles.checkMailingMessage}>
              <div><strong>{check_mailing_text}</strong></div>
              <div><InfoIcon message={ElectronicSchoolPayment_mail_info.map(msg => <p key={msg}>{msg}</p>)} /></div>
            </div>
            {isSchoolRetained && <div className={styles.retainedSchoolAddress}>{retained_school_address_txt}</div>}
          </div>
  
          {this.renderAddress()}
          {this.renderBeneficiary()}
        </Collapse>
      </div>
    );
  }

  renderAddress() {
    const {
      recipientAddress,
      usStates,
      recipientAddressHandle,
      errors: {
        street1Error,
        cityError,
        stateError,
        postalCodeError,
        attnError,
      },
      stateSelectHandle,
    } = this.props;

    return (
      <>
        <div>
          <TextField
            value={recipientAddress.school.name}
            disabled={true}
            label='Name of School'
            onChange={(e) => recipientAddressHandle(e, 'name')} // eslint-disable-line brace-style
            fullWidth
          />
        </div>
        <div>
          <TextField
            error={Boolean(attnError)}
            helperText={attnError}
            defaultValue={recipientAddress.school.attn}
            label='Attention/Department'
            onChange={(e) => recipientAddressHandle(e, 'attn')} // eslint-disable-line brace-style
            fullWidth
          />
        </div>
        <div>
          <TextField
            error={Boolean(street1Error)}
            helperText={street1Error}
            defaultValue={recipientAddress.school.street1}
            label='Street Address'
            onChange={(e) => { e.persist(); recipientAddressHandle(e, 'street1'); }} // eslint-disable-line brace-style
            fullWidth
            inputProps={{
              maxLength: 40
            }}
          />
        </div>
        <div>
          <TextField
            error={Boolean(cityError)}
            helperText={cityError}
            defaultValue={recipientAddress.school.city}
            label='City'
            onChange={(e) => { e.persist(); recipientAddressHandle(e, 'city'); }} // eslint-disable-line brace-style
            fullWidth
            inputProps={{
              maxLength: 25
            }}
          />
        </div>
        <div>
          <Dropdown
            label='State'
            options={usStates.map(state => ({ value: state.Code, display: state.Name }))}
            errorText={stateError}
            value={recipientAddress.school.state.Code || ''}
            onChange={stateSelectHandle}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: { maxHeight: '300px' }
                }
              }
            }}
          />
        </div>
        <div>
          <ZipInput
            errorText={postalCodeError}
            fullWidth
            onChange={(e) => recipientAddressHandle(e, 'postalCode')}
            label='Postal Code'
            value={recipientAddress.school.postalCode}
          />
        </div>
      </>
    );
  }

  renderBeneficiary() {
    const { recipientAddress, errors: { studentIdNumError } } = this.props;
    const bene = recipientAddress.school.beneficiaryName
      ? recipientAddress.school.beneficiaryName
      : recipientAddress.school.fbo;
    return (
      <>
        <div>
          <TextField
            value={bene}
            disabled={true}
            label='Beneficiary'
            name='FBO'
            fullWidth
          />
        </div>
        <div>
          <TextField
            error={Boolean(studentIdNumError)}
            helperText={studentIdNumError}
            label='Student ID Number'
            inputProps={{
              maxLength: 15
            }}
            onChange={(e) => { e.persist(); this.props.recipientAddressHandle(e, 'studentIdNum'); }} // eslint-disable-line brace-style
            value={recipientAddress.school.studentIdNum}
            fullWidth
          />
        </div>
      </>
    );
  }
  
  render() {
    const renderOption = this.props.recipientAddress.school.IsElectronicSchoolPaymentSchool
      ? this.renderElectronicSchoolOption()
      : this.renderRegularOption();

    return renderOption;
  }
}

export default LanguageHOC(School);