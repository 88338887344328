/*
*
* Step2Recipient Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import School from 'components/Features/protected/Accounts/Transactions/Withdrawals/NewWithdrawals/StepComponents/School';
import { getSchools } from 'components/Features/protected/Accounts/Transactions/actions';
import SchoolSearch from '../../SchoolSearch';
import { Button } from '@mui/material';
import * as validator from 'utils/helpers/form_validation';
import { sanitizeHTML } from 'utils/helpers/sanitation';

import { properCase } from '@frontend/common';

import styles from './styles.module.scss';

const select = (state) => ({
  preTransactionDetails: state.transactions.preTransactionDetails,
  usStates: state.static.usStates,
});

const BLANK_SCHOOL = {
  attn: '',
  city: '',
  fbo: '',
  postalCode: '',
  schoolId: '',
  state: { Code: '' },
  street1: '',
  street2: '',
  street3: '',
  studentIdNum: '',
};

export class Step2Recipient extends React.Component {
  static propTypes = {
    beneficiaryName: PropTypes.string.isRequired,
    getSchools: PropTypes.func.isRequired,
    nextClickHandle: PropTypes.func.isRequired,
    preTransactionDetails: PropTypes.shape({
      beneficiaryName: PropTypes.string,
      schoolAddresses: PropTypes.array,
    }).isRequired,
    renderBackButton: PropTypes.func.isRequired,
    renderCancelButton: PropTypes.func.isRequired,
    setWithdrawalInfo: PropTypes.func.isRequired,
    text: PropTypes.shape({
      WithdrawalRequests: PropTypes.shape({
        btn_next: PropTypes.string,
        err_field_required: PropTypes.string,
        err_school_required: PropTypes.string,
      }),
    }),
    usStates: PropTypes.array.isRequired,
    withdrawalInfo: PropTypes.shape({
      PayeeAddressLine1: PropTypes.string,
      PayeeAddressLine2: PropTypes.string,
      PayeeCity: PropTypes.string,
      PayeeState: PropTypes.string,
      PayeeZipCode: PropTypes.string,
      SchoolDepartment: PropTypes.string,
      SchoolId: PropTypes.string,
      SchoolName: PropTypes.string,
      StudentNumber: PropTypes.string,
    }).isRequired,
  };

  state = {
    formErrors: {
      schoolError: '',
      street1Error: '',
      cityError: '',
      stateError: '',
      postalCodeError: '',
      attnError: '',
      studentIdNumError: '',
    },
    loading: false,
    school: {
      attn: this.props.withdrawalInfo.SchoolDepartment,
      city: this.props.withdrawalInfo.PayeeCity,
      fbo: this.props.beneficiaryName,
      name: this.props.withdrawalInfo.SchoolName,
      postalCode: this.props.withdrawalInfo.PayeeZipCode,
      schoolId: this.props.withdrawalInfo.SchoolId,
      state: { Code: this.props.withdrawalInfo.PayeeState },
      street1: this.props.withdrawalInfo.PayeeAddressLine1,
      street2: this.props.withdrawalInfo.PayeeAddressLine2,
      studentIdNum: this.props.withdrawalInfo.StudentNumber,
      IsElectronicSchoolPaymentSchool: false,
      ElectronicSchoolPaymentSchoolId: '',
    },
    schools: [],
    schoolSearchOpen: false,
  };

  addressHandle = (e, fieldName) => {
    if (e !== undefined) {
      let formErrors = {
        ...this.state.formErrors,
        [`${fieldName}Error`]: '',
      };
      let value;
      if (fieldName === 'postalCode') {
        value = e.value;
        formErrors = {
          ...this.state.formErrors,
          postalCodeError: '',
        };
      }
      else {
        value = e.target.value;
      }

      this.setState({
        formErrors,
        school: {
          ...this.state.school,
          [fieldName]: value
        }
      });
    }
  }

  formValidate = () => {
    const formErrors = {};
    const { school } = this.state;
    const { err_school_required, } = this.props.text.WithdrawalRequests;

    formErrors.cityError = validator.cityValidator(school.city.trim());
    formErrors.postalCodeError = validator.postalCodeValidator(school.postalCode.trim());
    formErrors.schoolError = school.name.length < 1 ? err_school_required : '';
    formErrors.stateError = validator.stateValidator(school.state.Code);
    formErrors.street1Error = validator.street1Validator(school.street1.trim());
    formErrors.attnError = validator.schoolAttnValidator(school.attn || '');
    formErrors.studentIdNumError = validator.alphaNumericOnlyValidator(school.studentIdNum);

    this.setState({ formErrors });

    return Object.keys(formErrors).length === 0 || Object.values(formErrors).every(err => err === '');
  }

  nextHandle = e => {
    e.preventDefault();
    if (this.formValidate()) {
      const { school } = this.state;

      this.props.setWithdrawalInfo({
        PayeeAddressLine1: sanitizeHTML(school.street1),
        PayeeAddressLine2: sanitizeHTML(school.street2),
        PayeeCity: sanitizeHTML(school.city),
        PayeeState: sanitizeHTML(school.state.Code),
        PayeeZipCode: sanitizeHTML(school.postalCode),
        SchoolDepartment: sanitizeHTML(school.attn),
        SchoolId: sanitizeHTML(school.schoolId),
        SchoolName: sanitizeHTML(school.name),
        StudentNumber: school.studentIdNum ? sanitizeHTML(school.studentIdNum.trim()) : '', // remove trailing spaces for student id
      });
      this.props.nextClickHandle();
    }

  }

  schoolSearchHandle = () => {
    this.setState({ loading: true, schoolSearchOpen: true });
    this.props.getSchools()
      .then(response => {
        const data = response.payload.data;
        let id = -1;
        if (data && data.length > 0) {
          const schools = data.map(school => {
            ++id;
            return {
              id,
              attn: school.SchoolDepartment,
              fbo: school.beneficiaryName,
              postalCode: '',
              street1: '',
              street2: '',
              city: '',
              fafsaId: school.Code,
              name: properCase(school.Name),
              schoolId: school.CollegeId.toString(),
              state: '',
              IsElectronicSchoolPaymentSchool: false,
            };
          });
          this.setState({ schools });
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  schoolSelect = schoolId => {
    const { preTransactionDetails: { beneficiaryName, schoolAddresses } } = this.props;
    const { schools } = this.state;
    if (schools?.find(address => address.schoolId === schoolId)) {
      const school = schools.find(school => school.schoolId === schoolId);
      school.fbo = beneficiaryName;
      school.beneficiaryName = beneficiaryName;
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          schoolError: '',
        },
        school,
      });
    }
    else {
      const school = schoolAddresses.find(school => school.schoolId === schoolId);
      this.setState({
        formErrors: {
          schoolError: '',
        },
        school: {
          ...BLANK_SCHOOL,
          fbo: beneficiaryName,
          beneficiaryName,
          name: school.name,
          schoolId,
          IsElectronicSchoolPaymentSchool: false,
        }
      });
    }

    this.setState({ schoolSearchOpen: false }, () => {
      const { school } = this.state;
      this.props.setWithdrawalInfo({
        PayeeAddressLine1: sanitizeHTML(school.street1),
        PayeeAddressLine2: sanitizeHTML(school.street2),
        PayeeCity: sanitizeHTML(school.city),
        PayeeState: sanitizeHTML(school.state.Code),
        PayeeZipCode: sanitizeHTML(school.postalCode),
        SchoolDepartment: sanitizeHTML(school.attn),
        SchoolId: sanitizeHTML(school.schoolId),
        SchoolName: sanitizeHTML(school.name),
        StudentNumber: school.studentIdNum ? sanitizeHTML(school.studentIdNum.trim()) : '', // remove trailing spaces for student id
      });
    });
  }

  stateSelectHandle = value => {
    this.setState({
      formErrors: {
        ...this.state.formErrors,
        stateError: '',
      },
      school: {
        ...this.state.school,
        state: { Code: value }
      }
    }, () => {
      const { school } = this.state;
      this.props.setWithdrawalInfo({
        PayeeAddressLine1: sanitizeHTML(school.street1),
        PayeeAddressLine2: sanitizeHTML(school.street2),
        PayeeCity: sanitizeHTML(school.city),
        PayeeState: sanitizeHTML(school.state.Code),
        PayeeZipCode: sanitizeHTML(school.postalCode),
        SchoolDepartment: sanitizeHTML(school.attn),
        SchoolId: sanitizeHTML(school.schoolId),
        SchoolName: sanitizeHTML(school.name),
        StudentNumber: school.studentIdNum ? sanitizeHTML(school.studentIdNum.trim()) : '', // remove trailing spaces for student id
      });
    });
  }

  render() {
    const { text: { WithdrawalRequests }, usStates } = this.props;
    const { formErrors, loading, school, schools, schoolSearchOpen } = this.state;

    return (
      <form className={styles.container} onSubmit={this.nextHandle}>
        <School
          errors={formErrors}
          handleSchoolSearch={this.schoolSearchHandle}
          isSchoolRetained={false}
          recipientAddress={{ school }}
          recipientAddressHandle={this.addressHandle}
          stateSelectHandle={this.stateSelectHandle}
          usStates={usStates}
        />

        <SchoolSearch
          loading={loading}
          onClose={() => this.setState({ schoolSearchOpen: false })}
          open={schoolSearchOpen}
          schoolSelect={this.schoolSelect}
          schools={schools}
        />

        <div className={styles.stepperButtons}>
          {this.props.renderCancelButton()}

          {this.props.renderBackButton()}

          <Button
            color='primary'
            onClick={this.nextHandle}
            variant='contained'
            fullWidth
            type='submit'
          >
            {WithdrawalRequests.btn_next}
          </Button>
        </div>
      </form>
    );
  }
}


export default connect(select, {
  getSchools,
})(LanguageHOC(Step2Recipient));
