import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { setRegisteredDevice } from 'utils/helpers/registered_device_handler';

import {
  Button,
  FormControlLabel,
  FormHelperText,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Checkbox
} from '@mui/material';

import {
  My529Logo,
  LoadingOverlay,
  notificationShow,
} from '@frontend/common';

import {
  deviceTokenCreate,
  getPreferredMethod,
  savePreferredMethod,
  getTwoFactorToken,
  twoFactorTokenVerification,
} from '../actions';

import {
  toggle2FADialog,
  getRSAPrecondition,
  resetClaims,
  refreshClaims,
  userLogout
} from 'components/AppRoot/Navigation/actions';

import { regMethodTypes } from '../constants';

import styles from './styles.module.scss';

const select = (state) => ({
  preferredInfo: state.multifactor.preferredInfo,
  VerificationInfo: state.multifactor.VerificationInfo,
  is2FADialogOpen: state.session.is2FADialogOpen,
  claims: state.session.claims,
  isWithdrawal: state.session.isWithdrawal,
  deviceToken: state.multifactor.deviceToken,
  ServerDateTime: state.static.environmentVars.ServerDateTime,
  TwoFADeviceCookieExpirationInDays: state.static.environmentVars.TwoFADeviceCookieExpirationInDays,
});

const displayTypes = {
  PHONE_AUTH: 'PHONE_AUTH',
  ONE_TOUCH: 'ONE_TOUCH'
};

export class Authentication extends Component {

  static propTypes = {
    deviceTokenCreate: PropTypes.func.isRequired,
    deviceToken: PropTypes.string.isRequired,
    claims: PropTypes.object.isRequired,
    getPreferredMethod: PropTypes.func.isRequired,
    savePreferredMethod: PropTypes.func.isRequired,
    getTwoFactorToken: PropTypes.func.isRequired,
    twoFactorTokenVerification: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    toggle2FADialog: PropTypes.func.isRequired,
    getRSAPrecondition: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    resetClaims: PropTypes.func.isRequired,
    refreshClaims: PropTypes.func.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    VerificationInfo: PropTypes.object.isRequired,
    is2FADialogOpen: PropTypes.bool.isRequired,
    isWithdrawal: PropTypes.bool.isRequired,
    ServerDateTime: PropTypes.string.isRequired,
    TwoFADeviceCookieExpirationInDays: PropTypes.number.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_call_again: PropTypes.string,
        btn_calling: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_enter_token: PropTypes.string,
        btn_request_code: PropTypes.string,
        btn_resend_code: PropTypes.string,
        btn_sec_code: PropTypes.string,
        btn_send_newpush: PropTypes.string,
        btn_sending: PropTypes.string,
        btn_submit_code: PropTypes.string,
        btn_submit_token: PropTypes.string,
        btn_use_phone: PropTypes.string,
        btn_use_token: PropTypes.string,
        err_code_required: PropTypes.string,
        err_invalid_token: PropTypes.string,
        err_token_required: PropTypes.string,
        err_options: PropTypes.string,
        lbl_call: PropTypes.string,
        lbl_remember_device: PropTypes.string,
        lbl_text: PropTypes.string,
        lbl_token: PropTypes.string,
        msg_attempts_remaining: PropTypes.string,
        msg_token_attempts_remaining: PropTypes.string,
        msg_enter_authenticator: PropTypes.string,
        msg_security_code: PropTypes.func,
        msg_send_security_code: PropTypes.func,
        msg_we_called: PropTypes.func,
        preferred_response_path: PropTypes.string,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
      }),
      Home: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
    isDialogOpen: false,
    isWorking: false,
    preferredMethod: '',
    regMethod: '',
    regMethodError: '',
    attemptWarning: '',
    isRegisterDevice: false,

    token: '',
    hasToken: false,
    tokenError: '',
    authType: 0, // 0 = Reg/Auth, 1 = Protected Action
  };

  submitPhoneMethod() {
    const { text: { Multifactor } } = this.props;
    const { regMethod } = this.state;
    if (regMethod) {
      this.setState({ isLoading: true });
      this.props.getTwoFactorToken(regMethod)
        .then(() => {
          this.setState({ isLoading: false, preferredMethod: displayTypes.PHONE_AUTH });
        });
      this.setState({ preferredMethod: displayTypes.PHONE_AUTH });
    }
    else {
      this.setState({ regMethodError: Multifactor.err_options });
    }
  }

  renderPhoneMethod() {
    const { preferredInfo, text: { Multifactor } } = this.props;
    const { regMethod, regMethodError, authType, isLoading } = this.state;
    const lastFour = preferredInfo.PhoneNumber
      ? preferredInfo.PhoneNumber.substr(preferredInfo.PhoneNumber.length - 4, preferredInfo.PhoneNumber.length)
      : '';
    return (
      <div>
        <h3>{Multifactor.msg_send_security_code(lastFour)}</h3>
        <div style={{ width: '50%', margin: 'auto' }}>
          <RadioGroup aria-label='registration method' name='method' value={regMethod} onChange={this.handleOptions}>
            <FormControlLabel value={regMethodTypes.SMS} control={<Radio />} label={Multifactor.lbl_text} />
            <FormControlLabel value={regMethodTypes.CALL} control={<Radio />} label={Multifactor.lbl_call} />
          </RadioGroup>
          <FormHelperText style={{ color: 'red', width: '70%', textAlign: 'center' }}>{regMethodError}</FormHelperText>
        </div>
        <Button
          variant='contained'
          style={{ width: '80%', marginTop: '15px' }}
          onClick={() => this.submitPhoneMethod()}
          disabled={isLoading}
        >
          {Multifactor.btn_request_code}
        </Button>

        {preferredInfo.VerifiedAppCode &&
          <Button
            color='primary'
            variant='text'
            style={{ width: '80%', marginTop: '10px' }}
            onClick={() => this.setState({ preferredMethod: regMethodTypes.INAPPTOKEN })}
          >
            {Multifactor.btn_use_token}
          </Button>
        }

        <Button
          color='primary'
          variant='text'
          style={{ width: '80%', marginTop: '10px' }}
          onClick={() => authType === 0 ? this.logout() : this.props.toggle2FADialog()}
        >
          {Multifactor.btn_cancel}
        </Button>
      </div>
    );
  }

  submitPhoneCode(e) {
    e.preventDefault();
    const { token, regMethod } = this.state;
    const { text: { Multifactor } } = this.props;
    if (token) {
      this.setState({ isLoading: true, isWorking: true });
      Promise.all([
        this.props.twoFactorTokenVerification(token, regMethod),
        this.props.getPreferredMethod()
      ])
        .then(() => {
          const { VerificationInfo } = this.props;
          if (VerificationInfo.Verified) {
            const params = {
              AlwaysRequired: this.props.preferredInfo.AlwaysRequired,
              PreferredMethod: regMethod // submits selected method based on if text or phone is choosen
            };
            this.props.savePreferredMethod(params)
              .then(() => {
                this.registerDevice();
                this.props.resetClaims()
                  .then(() => {
                    this.displayRSAModal();
                  });
              });
          }
          else {
            if (VerificationInfo.AttemptsRemaining === 0) {
              this.logout();
            }
            else if (VerificationInfo.AttemptsRemaining === 1) {
              this.setState({
                isLoading: false,
                isWorking: false,
                attemptWarning: Multifactor.msg_attempts_remaining
              });
            }
            else {
              this.setState({
                isLoading: false,
                isWorking: false,
                tokenError: Multifactor.err_invalid_token
              });
            }
          }
        })
        .catch(() => null);
    }
    else {
      this.setState({
        hasToken: true,
        tokenError: Multifactor.err_code_required
      });
    }
  }

  reSubmitPhoneCode() {
    const { regMethod } = this.state;
    this.setState({ isWorking: true, attemptWarning: '' });
    setTimeout(() => {
      this.setState({ isWorking: false });
    }, 5000);
    this.props.getTwoFactorToken(regMethod)
      .catch(() => null);
  }

  renderPhoneAuthenticate() {
    const { preferredInfo, text: { Multifactor } } = this.props;
    const {
      token, hasToken, tokenError, attemptWarning,
      regMethod, isWorking, isLoading, authType, isRegisterDevice } = this.state;
    const lastFour = preferredInfo.PhoneNumber
      ? preferredInfo.PhoneNumber.substr(preferredInfo.PhoneNumber.length - 4, preferredInfo.PhoneNumber.length)
      : '';
    const sendConfirmMsg = regMethod === regMethodTypes.CALL ? Multifactor.msg_we_called(lastFour) : Multifactor.msg_security_code(lastFour);
    const secondaryBtn = regMethod === regMethodTypes.CALL
      ? isWorking ? Multifactor.btn_calling : Multifactor.btn_call_again
      : isWorking ? Multifactor.btn_sending : Multifactor.btn_resend_code;
    return (
      <div>
        <h2>{Multifactor.btn_sec_code}</h2>
        <span>{sendConfirmMsg}</span>
        <form
          id='phone-form'
          onSubmit={e => this.submitPhoneCode(e)}
        >
          {!preferredInfo.AlwaysRequired && authType === 0 &&
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRegisterDevice}
                    onChange={() => this.setState({ isRegisterDevice: !isRegisterDevice })}
                    name='authy-register-device'
                    color='primary'
                  />
                }
                label={Multifactor.lbl_remember_device}
              />
            </div>
          }
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <TextField
              error={hasToken || Boolean(tokenError)}
              helperText={tokenError}
              label={Multifactor.btn_sec_code}
              onChange={this.handleInput('token')}
              value={token}
              variant='filled'
              autoFocus={true}
            />
          </div>
          <div className={styles.attemptWarning}>{attemptWarning}</div>
          <Button
            id='submit-phone'
            variant='contained'
            style={{ width: '90%', marginTop: '15px' }}
            type='submit'
            disabled={isLoading || isWorking}
          >
            {Multifactor.btn_submit_code}
          </Button>
        </form>
        <Button
          color='primary'
          variant='text'
          style={{ width: '90%', marginTop: '15px' }}
          onClick={() => this.reSubmitPhoneCode()}
          disabled={isWorking}
        >
          {secondaryBtn}
        </Button>
        <Button
          color='primary'
          variant='text'
          style={{ width: '90%', marginTop: '15px' }}
          onClick={() => authType === 0 ? this.logout() : this.props.toggle2FADialog()}
        >
          {Multifactor.btn_cancel}
        </Button>
      </div>
    );
  }

  submitAuthenticator(e) {
    e.preventDefault();
    const { text: { Multifactor } } = this.props;
    const { token } = this.state;
    if (token) {
      this.setState({ isLoading: true });
      Promise.all([
        this.props.twoFactorTokenVerification(token, regMethodTypes.INAPPTOKEN),
        this.props.getPreferredMethod()
      ])
        .then(() => {
          const { VerificationInfo } = this.props;
          if (VerificationInfo.Verified) {
            const params = {
              AlwaysRequired: this.props.preferredInfo.AlwaysRequired,
              PreferredMethod: regMethodTypes.INAPPTOKEN
            };
            this.props.savePreferredMethod(params)
              .then(() => {
                this.registerDevice();
                this.props.resetClaims()
                  .then(() => {
                    this.displayRSAModal();
                  });
              });
          }
          else {
            if (VerificationInfo.AttemptsRemaining === 0) {
              this.logout();
            }
            else if (VerificationInfo.AttemptsRemaining === 1) {
              this.setState({
                isLoading: false,
                isWorking: false,
                attemptWarning: Multifactor.msg_token_attempts_remaining
              });
            }
            else {
              this.setState({
                isLoading: false,
                isWorking: false,
                tokenError: Multifactor.err_invalid_token,
              });
            }
          }
        })
        .catch(() => null);
    }
    else {
      this.setState({
        tokenError: Multifactor.err_token_required,
        hasToken: true
      });
    }
  }

  renderAuthenticator() {
    const { text: { Multifactor }, preferredInfo } = this.props;
    const {
      token, hasToken, attemptWarning,
      tokenError, authType, isRegisterDevice, isLoading
    } = this.state;

    const isDomestic = preferredInfo.CountryCode === '+1';

    return (
      <div>
        <h3>{Multifactor.msg_enter_authenticator}</h3>
        <form
          id='authenticator-form'
          onSubmit={e => this.submitAuthenticator(e)}
        >
          {!preferredInfo.AlwaysRequired && authType === 0 && authType === 0 &&
            <div style={{ marginTop: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRegisterDevice}
                    onChange={() => this.setState({ isRegisterDevice: !isRegisterDevice })}
                    name='authy-register-device'
                    color='primary'
                  />
                }
                label={Multifactor.lbl_remember_device}
              />
            </div>
          }
          <div style={{ marginTop: '10px' }}>
            <TextField
              error={hasToken || Boolean(tokenError)}
              helperText={tokenError}
              label={Multifactor.lbl_token}
              onChange={this.handleInput('token')}
              value={token}
              variant='filled'
              autoFocus={true}
            />
            <div className={styles.attemptWarning}>{attemptWarning}</div>
          </div>
          <Button
            id='submit-authenticator'
            variant='contained'
            style={{ width: '60%', marginTop: '30px' }}
            type='submit'
            disabled={isLoading}
          >
            {Multifactor.btn_submit_token}
          </Button>
        </form>
        {preferredInfo.PhoneNumber && isDomestic && // show only for US phone numbers
          <Button
            color='primary'
            variant='text'
            style={{ width: '60%', marginTop: '15px' }}
            onClick={() => this.setState({ preferredMethod: regMethodTypes.SMS })}
          >
            {Multifactor.btn_use_phone}
          </Button>
        }

        <Button
          color='primary'
          variant='text'
          style={{ width: '60%', marginTop: '15px' }}
          onClick={() => authType === 0 ? this.logout() : this.props.toggle2FADialog()}
        >
          {Multifactor.btn_cancel}
        </Button>
      </div>
    );
  }

  displayRSAModal() {
    if (this.props.isWithdrawal) {
      this.props.getRSAPrecondition().catch(() => null);
    }
  }

  registerDevice() {
    const { ServerDateTime, TwoFADeviceCookieExpirationInDays } = this.props;
    if (this.state.isRegisterDevice) {
      this.props.deviceTokenCreate()
        .then(() => {
          setRegisteredDevice(this.props.deviceToken, ServerDateTime, TwoFADeviceCookieExpirationInDays);
        });
    }
  }

  logout() {
    const token = sessionStorage.getItem('token');
    this.setState({ preferredMethod: 'NADA' }, () => {
      this.props.toggle2FADialog();
      this.props.userLogout({ token });
      setTimeout(() => this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success'), 500);
    });
  }

  handleOptions = e => {
    this.setState({ regMethod: e.target.value });
  }

  handleInput = name => e => {
    this.setState({ [name]: e.target.value, hasToken: false, tokenError: '' });
  }

  renderMethod() {
    const method = this.state.preferredMethod;
    switch (method) {
      case regMethodTypes.SMS:
      case regMethodTypes.CALL: { return this.renderPhoneMethod(); }
      case regMethodTypes.INAPPTOKEN: { return this.renderAuthenticator(); }
      case displayTypes.PHONE_AUTH: { return this.renderPhoneAuthenticate(); }
      default: break;
    }
  }

  componentDidMount() {
    const authType = this.props.claims.AccountBlocked === 'True' ? 0 : 1;
    if (this.props.is2FADialogOpen) {
      this.setState({ isLoading: true, authType });
      this.props.getPreferredMethod()
        .then(() => {
          const preferredMethod = this.props.preferredInfo.PreferredMethod;
          if (preferredMethod === null) {
            this.props.refreshClaims();
          }
          else {
            this.setState({ isLoading: false, preferredMethod, regMethod: preferredMethod });
          }
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.preferredMethod !== this.state.preferredMethod) {
      this.renderMethod();
    }
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div className={styles.container}>
        <Paper>
          <div className={styles.body}>
            <LoadingOverlay show={isLoading}>
              <div className={styles.logo}><My529Logo /></div>
              {this.renderMethod()}
            </LoadingOverlay>
          </div>
        </Paper>
      </div>
    );
  }
}

export default connect(select, {
  deviceTokenCreate,
  setRegisteredDevice,
  getPreferredMethod,
  savePreferredMethod,
  getTwoFactorToken,
  twoFactorTokenVerification,
  resetClaims,
  refreshClaims,
  userLogout,
  toggle2FADialog,
  getRSAPrecondition,
  notificationShow
})(LanguageHOC(Authentication));