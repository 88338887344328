/*
*
* TransactionDetails Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
} from '@mui/material';
import {
  CardTitle,
  IconBtnTooltip,
} from '@frontend/common';
import LanguageHOC from 'utils/translations/LanguageHOC';

import styles from './styles.module.scss';
import ViewOptionChange from 'components/Features/protected/Accounts/Transactions/OptionChanges/ViewOptionChange';

export class ViewPendingOptionChange extends React.Component {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    pendingOptionChange: PropTypes.object.isRequired,
    text: PropTypes.shape({
      UpcomingTransactions: PropTypes.shape({
        drawer_title_view_pending_option: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
  };


  render() {
    const {
      open,
      onClose,
      pendingOptionChange,
      text,
      loading,
    } = this.props;
    
    return (
      <Drawer
        anchor='right'
        open={open}
        onClose={onClose}
      >
        <div
          tabIndex={0}
          style={{ width: 'auto' }}
          className={styles.Transaction_container}
        >
          <div className={styles.transactionNavigation}>
            <div>
              <IconBtnTooltip
                icon='arrow_left'
                onClick={onClose}
                title='Close'
              />
            </div>
            <div className={styles.transactionTitle}>
              <CardTitle>
                {text.UpcomingTransactions.drawer_title_view_pending_option}
              </CardTitle>
            </div>
            <div>
              <IconBtnTooltip
                icon='close'
                onClick={onClose}
              />
            </div>
          </div>
          <div className={styles.transactionTableWrapper}>
            <ViewOptionChange
              loading={loading}
              pendingOptionChange={pendingOptionChange}
            />
          </div>

        </div>
      </Drawer >
    );
  }
}

export default LanguageHOC(ViewPendingOptionChange);