/*
*
* Step4Print Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import Review from '../Review';
import { Button } from '@mui/material';

import styles from './styles.module.scss';


export function Step4Print({ activeStep, beneficiaryName, history, text: { WithdrawalRequests }, withdrawalInfo }) {
  return (
    <div className={styles.container}>
      <h1>{WithdrawalRequests.head_confirmation}</h1>
      <p>{WithdrawalRequests.text_submitted}</p>

      <Review
        activeStep={activeStep}
        beneficiaryName={beneficiaryName}
        withdrawalInfo={withdrawalInfo}
      />

      <div className={styles.timestamp}>
        <p className={styles.createdDate}>{dayjs(withdrawalInfo.CreatedDate).format('MM/DD/YYYY h:mm A')} {WithdrawalRequests.text_timezone}</p> {/* eslint-disable-line */}
        <p className='hideOnPrint'>{WithdrawalRequests.text_print_copy}</p>
      </div>


      <div className={`${styles.stepperButtons} hideOnPrint`}>
        <Button
          color='primary'
          onClick={() => window.print()}
          variant='text'
          fullWidth
        >
          {WithdrawalRequests.btn_print}
        </Button>

        <Button
          onClick={() => history.goBack()}
          variant='contained'
          color='primary'
          fullWidth
        >
          {WithdrawalRequests.btn_finish}
        </Button>
      </div>

    </div>
  );
}

Step4Print.propTypes = {
  activeStep: PropTypes.number.isRequired,
  beneficiaryName: PropTypes.string.isRequired,
  withdrawalInfo: PropTypes.object.isRequired,
  text: PropTypes.shape({
    WithdrawalRequests: PropTypes.shape({
      btn_finish: PropTypes.string,
      btn_print: PropTypes.string,
      head_confirmation: PropTypes.string,
      text_print_copy: PropTypes.string,
      text_submitted: PropTypes.string,
      text_timezone: PropTypes.string,
    })
  }).isRequired,
};

export default withRouter(LanguageHOC(Step4Print));