/* eslint-disable newline-per-chained-call */
/*
*
* Withdrawals Component
*
*/
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import AccountsInGroup from './AccountsInGroup';
import NewWithdrawals from './NewWithdrawals';
import WithdrawalDetails from './WithdrawalDetails';
import {
  getPreTransactionDetails,
} from 'components/Features/protected/Accounts/Transactions/actions';
import {
  getServerDateTime,
} from 'components/AppRoot/StaticResources/actions';
import {
  Breadcrumbs,
  notificationShow,
  LoadingOverlay,
} from '@frontend/common';
import LanguageHOC from 'utils/translations/LanguageHOC';

import styles from './styles.module.scss';

const select = (state) => ({
  accountList: state.accounts.accountList,
  preTransactionDetails: state.transactions.preTransactionDetails,
  withdrawalVariables: state.static.withdrawalVariables,
  serverDateTime: state.static.serverDateTime,
  selectedTransaction: state.transactions.selectedTransaction,
});

export class Withdrawals extends React.Component {

  static propTypes = {
    accountList: PropTypes.array.isRequired,
    getPreTransactionDetails: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    preTransactionDetails: PropTypes.object.isRequired,
    withdrawalVariables: PropTypes.shape({
      sellDates: PropTypes.array,
      tradeCutoff: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
      ]),
      tradeCutoffWarningTime: PropTypes.number,
    }).isRequired,
    getServerDateTime: PropTypes.func.isRequired,
    serverDateTime: PropTypes.string.isRequired,
    text: PropTypes.shape({
      Transactions: PropTypes.shape({
        nav_path: PropTypes.func,
      }),
      Accounts: PropTypes.shape({
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
      }),
      AccountDetails: PropTypes.shape({
        nav_path: PropTypes.func,
        nav_title: PropTypes.string,
      }),
      Withdrawals: PropTypes.shape({
        title_edit_withdrawal: PropTypes.string,
        title_new_withdrawal: PropTypes.string,
      })
    }).isRequired,
    selectedTransaction: PropTypes.object,
  };

  state = {
    accountId: this.props.match.params.accountId,
    accountsInGroupModalOpen: false,
    displayWarning: false,
    isProportional: false,
    loading: false,
    isEditing: window.location.href.includes('edit'),
  };

  checkDisplayWarning = (date) => {
    const { tradeCutoff, tradeCutoffWarningTime } = this.props.withdrawalVariables;

    // show warning only when user selects sell or start date to be on the same day and a half an hour before trade cutoff time
    const dateNow = dayjs(this.props.serverDateTime); // using the local(true) makes this now adjust the time based on any timezone
    const tradeCutoffMinusWarningTime = dayjs(tradeCutoff).subtract(dayjs(tradeCutoffWarningTime), 'minute');
    const isDateOnTheSameDayAsTradeCutoff = dayjs(tradeCutoff).isSame(dayjs(date), 'day');

    if (isDateOnTheSameDayAsTradeCutoff && dateNow.isBetween(tradeCutoffMinusWarningTime, tradeCutoff)) {
      this.setState({ displayWarning: true });
    }
    else {
      this.setState({ displayWarning: false });
    }
  }

  componentDidMount() {
    const {
      match: { params },
      getPreTransactionDetails,
      selectedTransaction,
    } = this.props;

    const promises = [];

    // get current server MST datetime to use for displaying cutoff warning banner
    promises.push(this.props.getServerDateTime());

    // at this time selected transaction should be in redux
    if (this.state.isEditing)
      this.setState({ isProportional: selectedTransaction.Proportional });

    // This needs to be called each time withdrawal transactions starts to get updated info (for example scheduled transaction).
    promises.push(getPreTransactionDetails(params.accountId, params.groupId)
      .then(() => {
        if (this.props.preTransactionDetails.groupAccounts.length > 1) {
          this.setState({ accountsInGroupModalOpen: true });
        }
      }));

    this.setState({ loading: true });
    Promise.all(promises).finally(() => this.setState({ loading: false }));
  }

  render() {
    const { isEditing, displayWarning, isProportional, loading, accountsInGroupModalOpen, accountId } = this.state;
    const { preTransactionDetails, withdrawalVariables, text } = this.props;
    // To do proportional we need at least 2 accounts and all non zero
    const proportionalDisabled = preTransactionDetails.groupAccounts.length < 2 || preTransactionDetails.groupAccounts.filter(account => account.availableForWithdrawalNum === 0).length > 0;

    return (
      <LoadingOverlay
        show={loading}
        width='100%'
      >
        <div className={styles.container}>
          <div className={styles.pageNav}>
            <Breadcrumbs
              crumbs={[
                {
                  title: text.Accounts.nav_title,
                  link: text.Accounts.nav_path
                },
                {
                  title: text.AccountDetails.nav_title,
                  link: text.AccountDetails.nav_path(accountId),
                },
                {
                  title: isEditing ? text.Withdrawals.title_edit_withdrawal : text.Withdrawals.title_new_withdrawal,
                },
              ]}
            />
          </div>

          {(displayWarning && !loading) ?
            <div className={`${styles.tradeCutoffWarning} hideOnPrint`}>
              {text.Withdrawals.cutoff_warning(dayjs(withdrawalVariables.tradeCutoff).format('h:mm A'))} {/* eslint-disable-line */}
            </div>
            :
            null
          }

          <div className={styles.withdrawalDetails}>
            <WithdrawalDetails
              isProportional={isProportional}
              loading={loading}
              key={`${preTransactionDetails.AccountId}_WithdrawalDetails`}
            />
          </div>

          <div className={styles.newWithdrawals}>
            <NewWithdrawals
              isProportional={isProportional}
              loading={loading}
              key={`${preTransactionDetails.AccountId}_NewWithdrawals_${isProportional}`}
              checkDisplayWarning={this.checkDisplayWarning}
              preTransactionDetails={preTransactionDetails}
            />
          </div>

          {!isEditing &&
            <AccountsInGroup
              key={`${preTransactionDetails.AccountId}_AccountsInGroup`}
              loading={loading}
              open={accountsInGroupModalOpen}
              onClose={() => this.setState({ accountsInGroupModalOpen: false })}
              onProportionalSelect={() => this.setState({ accountsInGroupModalOpen: false, isProportional: true })}
              groupAccounts={preTransactionDetails.groupAccounts}
              proportionalDisabled={proportionalDisabled}
            />
          }
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(connect(select, {
  getPreTransactionDetails,
  notificationShow,
  getServerDateTime,
})(LanguageHOC(Withdrawals)));