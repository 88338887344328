
import axios from 'axios';
const frontendConfig = window.frontendConfig;

function configureRequest(apiPath) {
  const token = sessionStorage.getItem('token');
  axios.defaults.headers.common.Authorization = token || 'no~auth';
  axios.defaults.headers.common['Accept-Language'] = 'en'; // TRANSLATIONS remove line to activate API calls with language header
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  return frontendConfig.api + apiPath;
}

export function GETrequest(apiPath, config = {}) {
  const endPoint = configureRequest(apiPath);
  return axios.get(endPoint, config)
    .then(res => res)
    .catch(err => err.response);
}

export function POSTrequest(apiPath, data, config = {}) {
  const endPoint = configureRequest(apiPath);
  return axios.post(endPoint, data, config)
    .then(res => res)
    .catch(err => err.response);
}

export function PUTrequest(apiPath, data, config = {}) {
  const endPoint = configureRequest(apiPath);
  return axios.put(endPoint, data, config)
    .then(res => res)
    .catch(err => err.response);
}

export function DELETErequest(apiPath, config = {}) { 
  const endPoint = configureRequest(apiPath);
  return axios.delete(endPoint, config)
    .then(res => res)
    .catch(err => err.response);
}

export function GETaddress(searchCriteria) {
  if (searchCriteria && searchCriteria.length > 2) {
    const endPoint = `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=3795161896457999660&search=${searchCriteria}`;
    return axios.get(endPoint)
      .then(res => res)
      .catch(err => Promise.reject(err));
  }
  else {
    return Promise.reject('short-criteria');
  }
}

export function GETRecaptchaToken(action) { // Gets a new recaptcha token from Google. "action" is a string that should describe the page for analytics.
  const RECAPTCHA_KEY = window.location.host.includes('qareg') ? process.env.REACT_APP_QAREG_RECAPTCHA_KEY : process.env.REACT_APP_PROD_RECAPTCHA_KEY;
  return new Promise(resolve => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(RECAPTCHA_KEY, { action })
        .then(res => resolve(res));
    });
  });
}