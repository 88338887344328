/*
 *
 * Step2Terms Component
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Checkbox,
} from '@frontend/common';
import ReviewDetails from './ReviewDetails';
import TermsConditions from 'components/Features/protected/Accounts/Transactions/Contributions/TermsCondition';
import { SPECIAL } from '../../constants';
import styles from '../styles.module.scss';

Step2Terms.propTypes = {
  contribution: PropTypes.object.isRequired,
  nextRunDates: PropTypes.object.isRequired,
  termsChecked: PropTypes.bool.isRequired,
  toggleTermsChecked: PropTypes.func.isRequired,
  phoneEnvironmentVars: PropTypes.object.isRequired,
  text: PropTypes.shape({
    ContributionSpecialStep2: PropTypes.shape({
      lbl_read_terms: PropTypes.string,
    })
  })
};

export function Step2Terms({
  contribution,
  nextRunDates,
  termsChecked,
  toggleTermsChecked,
  phoneEnvironmentVars,
  text,
}) {
  return (
    <React.Fragment>
      <ReviewDetails 
        contribution={contribution}
        nextRunDates={nextRunDates}
      />

      <div className={styles.termsContainer}>
        <div className={styles.terms}>
          <TermsConditions 
            phoneEnvironmentVars={phoneEnvironmentVars}
            contributionType={SPECIAL}
          />
        </div>
        <div className={styles.checkbox}>
          <Checkbox
            checked={termsChecked}
            label={text.ContributionSpecialStep2.lbl_read_terms}
            onChange={toggleTermsChecked}
            color='primary'
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default (LanguageHOC(Step2Terms));