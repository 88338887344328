/*
*
* AccountsInGroupNotice Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';

import {
  Modal,

  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
} from '@frontend/common';

export class AccountsInGroupNotice extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    groupAccounts: PropTypes.arrayOf(PropTypes.shape({
      AccountNumber: PropTypes.number,
      OptionName: PropTypes.string,
    })),
  };

  render() {
    const {
      groupAccounts,
      open,
      onClose,
    } = this.props;

    return (
      <div>
        <Modal
          actionButtons={[
            {
              label: 'Ok',
              action: onClose
            }
          ]}
          onCloseModal={onClose}
          show={open}
          title='Accounts In Group'
        >
          <div style={{ minHeight: '400px' }}>
            <p>This account is 1 of {groupAccounts.length} accounts for the same account owner/beneficiary combination. This option change will count as one of the two investment option changes allowed per beneficiary for this calendar year.</p>
            <p>If you want to change the investment option for another account for the same beneficiary without incurring a second option change, you must make the change on the same day before the close of trading on the New York Stock Exchange, normally 2 p.m. MT. You also must make any modifications or cancellations before the close of trading.</p>
            <p>If you make an investment option request after the close of trading, or on a weekend or holiday, any modifications or cancellations must be received by 2 p.m. MT, the following business day.</p>
            <SmartTable
              idKey='AccountNumber'
              rows={groupAccounts}
              columns={[
                {
                  key: 'AccountNumber',
                  title: 'Account Number',
                  type: 'number',
                },
                {
                  key: 'OptionName',
                  title: 'Current Option',
                  type: 'string',
                  hideOn: ['phone']
                },
              ]}
            >
              <TableContainer minWidth='100%'>
                <TableHeader />
                <TableRows />
              </TableContainer>
              <TablePagination />
            </SmartTable>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AccountsInGroupNotice;
