/*
*
* CertificateEdit Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  TextField,
} from '@mui/material';
import {
  Modal,
} from '@frontend/common';

import styles from './styles.module.scss';


export class CertificateEdit extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    saveNameAndOpenDoc: PropTypes.func.isRequired,
    certificateCustomName: PropTypes.string.isRequired,
    text: PropTypes.shape({
      Documents: PropTypes.shape({
        btn_cancel: PropTypes.string,
        btn_save: PropTypes.string,
        err_required: PropTypes.string,
        lbl_from_name: PropTypes.string,
        text_edit_certificate: PropTypes.string,
        title_edit_certificate: PropTypes.string,
      })
    })
  };

  state = {
    certificateFromName: this.props.certificateCustomName,
    formErrors: {},
    isSaving: false,
  }

  onInputChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
      formErrors: {
        ...this.state.formErrors,
        [name]: '',
      },
      verificationFailed: false,
    });
  }

  formValidate = () => {
    const { Documents } = this.props.text;
    const { certificateFromName } = this.state;
    const formErrors = {};

    if (!certificateFromName) {
      formErrors.certificateFromName = Documents.err_required;
    }

    this.setState({ formErrors });
    const hasErrors = Object.keys(formErrors).some(field => formErrors[field] !== '');

    return !hasErrors;
  }

  closeForm = () => {
    this.props.onClose();
  }

  onEditCertificate = () => {
    const { saveNameAndOpenDoc } = this.props;
    const { certificateFromName } = this.state;
    const isValid = this.formValidate();

    if (isValid) {
      this.setState({ isSaving: true });
      /* 
        The & sign causes issues when submitting edits since the new From Name
        is sent in the API URL. This ensures that the & sign will save and appear
        correctly and safely.
      */
      const ampEncodedCertificateFromName = certificateFromName.replace(/&/g, '%26');
      saveNameAndOpenDoc(ampEncodedCertificateFromName);
    }
  }

  render() {
    const { 
      open, 
      text: { Documents }
    } = this.props;
    const { isSaving, formErrors, certificateFromName } = this.state;
    
    return (
      <Modal
        title={Documents.title_edit_certificate}
        show={open}
        onCloseModal={this.closeForm}
        modal={false}
        maxWidth='md'
        fullWidth={true}
        includeLeftCancelBtn
        actionButtons={[
          {
            label: Documents.btn_save,
            loading: isSaving,
            action: () => this.onEditCertificate(),
            buttonType: 'contained',
          }
        ]} 
      >
        <React.Fragment>
          <p className={styles.editCertificateText}> 
            {Documents.text_edit_certificate}
          </p>
          
          <div className={styles.fromNameInput}>
            <TextField
              variant='filled'
              error={Boolean(formErrors.certificateFromName)}
              helperText={formErrors.certificateFromName}
              disabled={isSaving}
              fullWidth
              label={Documents.lbl_from_name}
              name='certificateFromName'
              onChange={e => this.onInputChange(e, 'certificateFromName')}
              value={certificateFromName}
              inputProps={{
                maxLength: 40
              }}
            />
          </div>
        </React.Fragment>
      </Modal>
    );
  }
}

export default connect(null, {})(LanguageHOC(CertificateEdit));