import { POSTrequest } from 'utils/helpers/api_handler';
import {
  USER_NAME_FORGOT,
} from './constants';


export function sendUsername(EmailAddress) {
  const response = POSTrequest('/authentication/forgotusername', EmailAddress);
  return {
    type: USER_NAME_FORGOT,
    payload: response
  };
}