import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@frontend/common';
import { startTimer, stopTimer, getRemainingTimeInMs } from 'utils/helpers/timer_real_time';

export default class LogoutWarning extends Component {
  static propTypes = {
    logoutWarningInterval: PropTypes.number.isRequired,
    parseWarningMsg: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    closeButtonLabel: PropTypes.string.isRequired,
    onWarningClose: PropTypes.func.isRequired,
    warningOn: PropTypes.bool.isRequired,
    onTimeout: PropTypes.func.isRequired,
    onCloseContinueWithTimeout: PropTypes.bool.isRequired,
  };

  state = {
    remainingTimeInMs: 0,
  }

  updateRemainingTime = () => {
    this.setState({ remainingTimeInMs: getRemainingTimeInMs() });
  }

  startWarning = () => {
    // 1. show warning
    this.setState({ remainingTimeInMs: this.props.logoutWarningInterval });

    // 2. re-start countdown
    startTimer(
      this.updateRemainingTime,
      this.props.onTimeout,
      this.props.logoutWarningInterval
    );
  }

  stopWarning = () => {
    !this.props.onCloseContinueWithTimeout && stopTimer();
    this.props.onWarningClose();
    this.setState({ remainingTimeInMs: 0 });
  };

  parseCountdown = () => {
    const secsAll = this.state.remainingTimeInMs / 1000;
    const remainingMins = Math.floor(secsAll / 60);
    const remainingSecs = Math.round(secsAll - (remainingMins * 60));
    
    const minsFormatted = (remainingMins < 10 ? '0' : '') + remainingMins;
    const secsFormatted = (remainingSecs < 10 ? '0' : '') + remainingSecs;
    return `${minsFormatted}:${secsFormatted}`;
  };

  componentDidUpdate(prevProps) {
    if (this.props.warningOn !== prevProps.warningOn) {
      if (this.props.warningOn)
        this.startWarning();
      else
        this.stopWarning();
    }
  }

  componentWillUnmount() {
    stopTimer();
  }

  render() {
    const { remainingTimeInMs } = this.state;
    const { parseWarningMsg, modalTitle, closeButtonLabel, warningOn } = this.props;

    return (
      <Modal
        actionButtons={[
          {
            action: this.stopWarning,
            label: closeButtonLabel
          }
        ]}
        onCloseModal={this.stopWarning}
        show={warningOn}
        title={modalTitle}
      >
        <div>
          {parseWarningMsg(this.parseCountdown(remainingTimeInMs))}
        </div>
      </Modal>
    );
  }
}