/*
*
* SecondTermsAndConditions Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from '@mui/material';

import styles from './styles.module.scss';


export class SecondTermsAndConditions extends React.Component {

  static propTypes = {
    scrollToTop: PropTypes.func.isRequired,
    renderNavigationButton: PropTypes.func.isRequired,
    onBackHandler: PropTypes.func.isRequired,
    onSubmitHandler: PropTypes.func.isRequired,
    showRejectModalHandler: PropTypes.func.isRequired,
    text: PropTypes.object.isRequired,
    rejectLoading: PropTypes.bool.isRequired,
    approveAccountLoading: PropTypes.bool.isRequired,
  };

  state = {
    termsChecked: false,
    termsCheckedError: '',
  }

  onTermsCheckboxClicked = () => {
    this.setState({
      termsCheckedError: '',
      termsChecked: !this.state.termsChecked,
    });
  }

  onSubmitHandler = () => {
    if (this.state.termsChecked)
      this.props.onSubmitHandler();
    else
      this.setState({ termsCheckedError: 'Please agree to terms.' });
  }

  componentDidMount() {
    this.props.scrollToTop();
  }

  render() {
    const { termsChecked, termsCheckedError } = this.state;

    const {
      text: {
        LpoaAccount:
        {
          second_terms_and_conditions,
          btn_back,
          btn_reject,
          btn_submit,
          btn_print,
        }
      },
      renderNavigationButton,
      onBackHandler,
      showRejectModalHandler,
      rejectLoading,
      approveAccountLoading,
    } = this.props;

    return (
      <div className={styles.container}>
        <h2>{second_terms_and_conditions.title}</h2>
        <div className={styles.content}>
          {second_terms_and_conditions.content()}
        </div>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              inputProps={{ 'aria-label': second_terms_and_conditions.accept_terms_checkbox_label }}
              onClick={this.onTermsCheckboxClicked}
              checked={termsChecked}
            />
          }
          label={second_terms_and_conditions.accept_terms_checkbox_label}
        />
        {termsCheckedError && <FormHelperText error={Boolean(termsCheckedError)} style={{ textAlign: 'center' }}>{termsCheckedError}</FormHelperText>}
        <div className={styles.navButtons}>
          {renderNavigationButton(btn_back, onBackHandler, 'text', approveAccountLoading || rejectLoading)}
          {renderNavigationButton(btn_reject, showRejectModalHandler, 'text', approveAccountLoading || rejectLoading, rejectLoading)}
          {renderNavigationButton(btn_print, () => window.print(), 'text', approveAccountLoading || rejectLoading)}
          {renderNavigationButton(btn_submit, this.onSubmitHandler, 'contained', approveAccountLoading || rejectLoading, approveAccountLoading)}
        </div>
      </div >
    );
  }

}

export default LanguageHOC(SecondTermsAndConditions);