/*
*
* DeleteIp Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { deleteIp } from '../actions';
import {
  ConfirmModal,
  notificationShow,
} from '@frontend/common';


export class DeleteIp extends React.Component {
  static propTypes = {
    deleteIp: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedIp: PropTypes.object.isRequired,
    text: PropTypes.shape({
      InterestedParties: PropTypes.shape({
        head_delete_ip: PropTypes.string,
        msg_ip_deleted: PropTypes.string,
        text_confirm_delete_ip: PropTypes.func,
      }),
    }).isRequired,
  };

  state = {
    loading: false,
  };

  closeModal = () => {
    this.setState({ loading: false });
    this.props.onModalClose();
  }

  deleteIp = () => {
    const { selectedIp: { interestedPartyId }, text: { InterestedParties } } = this.props;
    this.setState({ loading: true });

    this.props.deleteIp(interestedPartyId)
      .then(() => {
        this.closeModal();
        this.props.notificationShow(InterestedParties.msg_ip_deleted, 'success');
      })
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    const { open, selectedIp, text: { InterestedParties } } = this.props;
    return (
      <ConfirmModal
        body={InterestedParties.text_confirm_delete_ip(selectedIp.emailAddress1)}
        isLoading={this.state.loading}
        onConfirm={this.deleteIp}
        onModalClose={this.closeModal}
        show={open}
        title={InterestedParties.head_delete_ip}
      />
    );
  }
}


export default connect(null, {
  deleteIp,
  notificationShow,
})(LanguageHOC(DeleteIp));
