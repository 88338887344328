/*
*
* Step0WithdrawalType Component
* Withdrawal Type
*
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  RECURRING,
  NONQUALIFIED,
} from '../../../constants';

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';

import styles from './styles.module.scss';

export class Step0WithdrawalType extends Component {
  static propTypes = {
    isEditing: PropTypes.bool.isRequired,
    withdrawal: PropTypes.shape({
      frequency: PropTypes.string,
      withdrawalType: PropTypes.string.isRequired,
    }),
    withdrawalVariables: PropTypes.object.isRequired,
    radioButtonLabelGenerate: PropTypes.func.isRequired,
    withdrawalTypeHandle: PropTypes.func.isRequired,
    errors: PropTypes.shape({
      withdrawalTypeError: PropTypes.string.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    const { withdrawalTypeHandle, withdrawal: { withdrawalType } } = this.props;
    withdrawalTypeHandle({ target: { value: withdrawalType } });
  }

  render() {
    const {
      isEditing,
      withdrawal: {
        frequency,
        withdrawalType,
      },
      withdrawalVariables,
      radioButtonLabelGenerate,
      withdrawalTypeHandle,
      errors: { withdrawalTypeError, },
    } = this.props;

    const isRecurring = frequency === RECURRING;
    const availableWithdrawalTypes = isRecurring ? withdrawalVariables.withdrawalTypes.filter(type => type.name !== NONQUALIFIED) : withdrawalVariables.withdrawalTypes;

    return (
      <div className={styles.withdrawalTypeContainer}>
        <FormControl error={Boolean(withdrawalTypeError)}>
          <RadioGroup
            onChange={withdrawalTypeHandle}
            value={withdrawalType}
          >
            {availableWithdrawalTypes.map(type => (
              <FormControlLabel
                key={type.id}
                label={radioButtonLabelGenerate(type)}
                value={type.id}
                control={<Radio disableRipple />}
                disabled={isEditing}
              />
            ))}
          </RadioGroup>
          {withdrawalTypeError && <FormHelperText>{withdrawalTypeError}</FormHelperText>}
        </FormControl>
      </div>
    );
  }
}

export default LanguageHOC(Step0WithdrawalType);