/*
*
* AddBirthdate Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import dayjs from 'dayjs';
import * as validator from 'utils/helpers/form_validation';
import {
  LoadingOverlay,
  notificationShow,
  DatePicker
} from '@frontend/common';
import { Button } from '@mui/material';
import {
  refreshClaims,
  saveBirthdate
} from 'components/AppRoot/Navigation/actions';
import { userLogout } from 'components/AppRoot/Navigation/actions';

import styles from './styles.module.scss';

const select = (state) => ({
  agentName: state.session.userDetails.name,
  MaximumAge: state.static.environmentVars.MaximumAge,
  MinimumAccountOwnerAge: state.static.environmentVars.MinimumAccountOwnerAge,
  supportNumber: state.static.environmentVars.SupportPhoneNumber,
});


export class AddBirthdate extends React.Component {

  static propTypes = {
    agentName: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    MaximumAge: PropTypes.number.isRequired,
    MinimumAccountOwnerAge: PropTypes.number.isRequired,
    notificationShow: PropTypes.func.isRequired,
    refreshClaims: PropTypes.func.isRequired,
    saveBirthdate: PropTypes.func.isRequired,
    supportNumber: PropTypes.string.isRequired,
    text: PropTypes.shape({
      AddBirthdate: PropTypes.shape({
        btn_logout: PropTypes.string,
        head_incomplete_records: PropTypes.string,
        lbl_birthdate: PropTypes.string,
        lbl_submit: PropTypes.string,
        msg_birthdate_updated: PropTypes.string,
        text_incomplete_records: PropTypes.string,
        text_support: PropTypes.func,
      }),
      Home: PropTypes.shape({
        nav_path: PropTypes.string,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
      })
    }).isRequired,
    userLogout: PropTypes.func.isRequired,
  };

  state = {
    birthdate: '',
    error: '',
    loading: false,
  };

  birthdateSave = e => {
    e.preventDefault();

    if (this.validateBirthdate()) {
      this.setState({ loading: true });

      this.props.saveBirthdate({ birthdate: this.state.birthdate.format('MM/DD/YYYY') })
        .then(() => {
          this.props.refreshClaims()
            .then(() => {
              this.props.notificationShow(this.props.text.AddBirthdate.msg_birthdate_updated, 'success');
              this.props.history.push(this.props.text.Home.nav_path);
            });
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  logout = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .then(() => this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success'));
  }

  setBirthdate = e => {
    this.setState({ birthdate: e });
  }

  validateBirthdate = () => {
    const error = validator.accountOwnerBirthdateValidator(this.state.birthdate, this.props.MinimumAccountOwnerAge, this.props.MaximumAge);
    this.setState({ error });
    return error === ''; // returns true if bdate is valid
  }

  render() {
    const { birthdate, error, loading } = this.state;
    const { agentName, MaximumAge, MinimumAccountOwnerAge, supportNumber, text, } = this.props;

    return (
      <div className={styles.container}>
        <h1>{text.AddBirthdate.head_incomplete_records}</h1>
        <p>{text.AddBirthdate.text_incomplete_records}</p>
        <p><strong>{agentName}</strong></p>
        <form onSubmit={this.birthdateSave} className={styles.formContainer}>
          <DatePicker
            disabled={loading}
            error={Boolean(error)}
            helperText={error}
            variant='filled'
            label={text.AddBirthdate.lbl_birthdate}
            maxDate={dayjs().subtract(MinimumAccountOwnerAge, 'year')}
            minDate={dayjs().subtract(MaximumAge, 'year')}
            onChange={this.setBirthdate}
            openTo='year'
            value={birthdate || null}
          />

          <LoadingOverlay show={loading} width='100%'>
            <div className={styles.buttons}>
              <Button
                color='primary'
                disabled={loading}
                onClick={this.logout}
                variant='text'
              >
                {text.AddBirthdate.btn_logout}
              </Button>
              <Button
                color='primary'
                disabled={loading}
                onClick={this.birthdateSave}
                variant='contained'
              >
                {text.AddBirthdate.lbl_submit}
              </Button>
            </div>
          </LoadingOverlay>

        </form>
        <p className={styles.support}>{text.AddBirthdate.text_support(supportNumber)}</p>
      </div>
    );
  }
}


export default withRouter(connect(select, {
  notificationShow,
  refreshClaims,
  saveBirthdate,
  userLogout,
})(LanguageHOC(AddBirthdate)));
