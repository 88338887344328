/*
*
* Payments Actions
*
*/
import {
  DELETErequest,
  GETrequest,
  POSTrequest,
  PUTrequest,
} from 'utils/helpers/api_handler';

import {
  CHECK_ROUTING_NUMBER,
  GET_BANK_ACCOUNTS,
  GET_AGENT_BANK_ACCOUNTS,
  GET_GROUPED_BANK_ACCOUNTS,
  GET_ALLOWED_BANK_HOLDERS,
  DELETE_BANK_ACCOUNT,
  RENEW_BANK_ACCOUNT,
  CREATE_AGENT_BANK_ACCOUNT,
  CREATE_BENEFICIARY_BANK_ACCOUNT,
  POA_APPROVAL,
  START_MIRCO_DEPOSITS,
  CONFIRM_MIRCO_DEPOSITS,
  SAVE_POA_BANK_SELECTION,
  GET_ACCESS_CARDS,
  UPDATE_NICKNAME,
  GET_ACCESS_CARD_URL,
} from './constants';


export function checkRoutingNumber(routingNumber) {
  const payload = GETrequest(`/BankAccount/CheckRoutingNumber?routingNumber=${routingNumber}`);
  return {
    type: CHECK_ROUTING_NUMBER,
    payload,
  };
}

export function getBankAccounts() {
  const payload = GETrequest('/BankAccount');
  return {
    type: GET_BANK_ACCOUNTS,
    payload,
  };
}

export function getAgentBankAccounts() {
  const payload = GETrequest('/BankAccount/Agent');
  return {
    type: GET_AGENT_BANK_ACCOUNTS,
    payload,
  };
}

export function getGroupedBankAccounts() {
  return {
    type: GET_GROUPED_BANK_ACCOUNTS,
  };
}

export function getAllowedBankHolders(userDetails, accountList) { //agentOnly is optional argument, used for transaction needs
  return {
    type: GET_ALLOWED_BANK_HOLDERS,
    userDetails,
    accountList,
  };
}

export function createAgentBankAccount(bankAccount) {
  const payload = POSTrequest('/BankAccount/Agent', bankAccount);
  return {
    type: CREATE_AGENT_BANK_ACCOUNT,
    payload,
  };
}

export function createBeneficiaryBankAccount(bankAccount, beneficiaryId) {
  const payload = POSTrequest(`/BankAccount/Beneficiary/${beneficiaryId}`, bankAccount);
  return {
    type: CREATE_BENEFICIARY_BANK_ACCOUNT,
    payload,
  };
}

export function deleteBankAccount(bankAccountId) {
  const payload = DELETErequest(`/BankAccount/${bankAccountId}`);
  return {
    type: DELETE_BANK_ACCOUNT,
    payload,
  };
}

export function renewBankAccount(bankAccountId) {
  const payload = POSTrequest(`/BankAccount/Renew/${bankAccountId}`);
  return {
    type: RENEW_BANK_ACCOUNT,
    payload,
  };
}

export function poaApproval(approval, bankAccountId) {
  //approval can be 'Approve' or 'Unapprove'
  const payload = POSTrequest(`/BankAccount/POA/${approval}/${bankAccountId}`);
  return {
    type: POA_APPROVAL,
    payload,
  };
}

export function startMicroDeposits(bankAccountId) {
  const payload = POSTrequest(`/BankAccount/StartMicroDeposits/${bankAccountId}`);
  return {
    type: START_MIRCO_DEPOSITS,
    payload,
  };
}

export function confirmMicroDeposits(bankAccountId, depositAmounts) {
  const payload = POSTrequest(`/BankAccount/ConfirmMicroDeposits/${bankAccountId}`, depositAmounts);
  return {
    type: CONFIRM_MIRCO_DEPOSITS,
    payload,
  };
}

export function savePOABankSelectionAgreement(bankAccountId) {
  const payload = POSTrequest(`/BankAccount/POA/OwnerNotified/${bankAccountId}`);
  return {
    type: SAVE_POA_BANK_SELECTION,
    payload,
  };
}

export function getAccessCards() {
  const payload = GETrequest('/AccessCards');
  return {
    type: GET_ACCESS_CARDS,
    payload,
  };
}

export function editNickName(BankAccountId, nickName) {
  const response = PUTrequest(`/BankAccount/Nickname/${BankAccountId}?nickname=${nickName}`);
  return {
    type: UPDATE_NICKNAME,
    payload: response,
    meta: { BankAccountId, nickName }
  };
}

export function getAccessCardURL() {
  const payload = GETrequest('/AccessCards/RegistrationURL');
  return {
    type: GET_ACCESS_CARD_URL,
    payload,
  };
}