/*
*
* ViewOptionChange Component
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  CalculatorForm,
  Breadcrumbs,
} from '@frontend/common';

import './styles.css';

const select = (state) => ({
  ServerDateTime: state.static.ServerDateTime,
});

export class ViewOptionChange extends React.Component {

  static propTypes = {
    text: PropTypes.shape({
      Accounts: PropTypes.shape({
        accounts_path: PropTypes.string,
        nav_path: PropTypes.string,
        nav_title: PropTypes.string,
      }),
      AccountDetails: PropTypes.shape({
        nav_path: PropTypes.func,
        nav_title: PropTypes.string,
      })
    }).isRequired,
    accountId: PropTypes.number, // breadcrumbs will not show if falsy
    pendingOptionChange: PropTypes.object.isRequired,
    ServerDateTime: PropTypes.string,
    loading: PropTypes.bool.isRequired,
  };

  render() {
    const {
      text,
      accountId,
      pendingOptionChange,
      ServerDateTime,
      loading,
    } = this.props;

    const breadcrumbs = (
      <Breadcrumbs
        crumbs={[
          {
            title: text.Accounts.nav_title,
            link: `/${text.Accounts.accounts_path}`, // TBD
          },
          {
            title: text.AccountDetails.nav_title,
            link: text.AccountDetails.nav_path(accountId),
          },
          {
            title: 'Investment Path',
            link: '#',
          },
        ]}
      />
    );
    const optionChangeName = pendingOptionChange.Schema ? pendingOptionChange.Schema.TemplateName || pendingOptionChange.NewOption : pendingOptionChange.NewOption;
    
    return (
      <div className='AccountOption_page'>
        <div className='AccountOption_container'>
          {accountId && breadcrumbs}
          <div>
            {accountId // if accountId, InvestmentPath is being displayed, otherwise it is a pending option change
              ? (
                <div className='AccountOption_name'>
                  {optionChangeName}
                </div>
              ) : ( 
                <>
                  <div className='AccountOption_name'>
                    <strong>Current Option: </strong>
                    <span>{pendingOptionChange.CurrentOption}</span>
                  </div>
                  <div className='AccountOption_name'>
                    <strong>New Option: </strong>
                    <span>{optionChangeName}</span>
                  </div>
                </>)}
            {pendingOptionChange.Schema &&
            <CalculatorForm
              loading={loading}
              stickyHeaderTopOffset={60}
              schema={pendingOptionChange.Schema}
              isViewing
              hideTemplateName={true}
              currentDate={ServerDateTime}
            />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(select, {}, null)(LanguageHOC(ViewOptionChange));
