/*
*
* TermsConditions Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { SPECIAL, NON_SPECIAL } from '../constants';
import styles from './styles.module.scss';

export class TermsConditions extends React.Component {
  static propTypes = {
    phoneEnvironmentVars: PropTypes.shape({
      SupportPhoneNumber: PropTypes.string.isRequired,
      FaxNumber: PropTypes.string.isRequired,
    }),
    contributionType: PropTypes.string,
    text: PropTypes.shape({
      ContributionTermsConditions: PropTypes.shape({
        head_title: PropTypes.string,
        head_review_terms: PropTypes.string,
        text_about_special_occasion: PropTypes.string,
        text_about_special: PropTypes.array,
        text_bad_accounts_warning: PropTypes.string,
        text_bad_accounts: PropTypes.array,
        text_check: PropTypes.string,
        text_check_requirements: PropTypes.array,
        text_notes: PropTypes.string,
        text_notes_list1: PropTypes.array,
        text_notes_list2: PropTypes.string,
        text_notes_list3: PropTypes.string,
        text_notes_list4_sublists_special: PropTypes.func,
        text_notes_list4_sublists_non_special: PropTypes.func,
        text_notes_list5: PropTypes.string,
        text_authorize: PropTypes.string,
      })
    })
  };

  static defaultProps = {
    contributionType: NON_SPECIAL,
  };

  render() {
    const { text: { ContributionTermsConditions }, phoneEnvironmentVars, contributionType } = this.props;
    const isSpecialContribution = contributionType === SPECIAL;
    const text_notes_list4_sublists = isSpecialContribution ? ContributionTermsConditions.text_notes_list4_sublists_special : ContributionTermsConditions.text_notes_list4_sublists_non_special;

    return (
      <div className={styles.termsConditions_container}>
        <h1 className={styles.termsConditions_modalHeader}>{ContributionTermsConditions.head_title}</h1>
        <h2 className={styles.termsConditions_modalSubheader}>{ContributionTermsConditions.head_review_terms}</h2>
        {isSpecialContribution && (
          <div>
            <p>{ContributionTermsConditions.text_about_special_occasion}</p>
            <ul>
              {ContributionTermsConditions.text_about_special.map(line => <li key={line}>{line}</li>)}
            </ul>
          </div>
        )}
        <p>{ContributionTermsConditions.text_bad_accounts_warning}</p>
        <ul>
          {ContributionTermsConditions.text_bad_accounts.map(line => <li key={line}>{line}</li>)}
        </ul>
        <p>{ContributionTermsConditions.text_check}</p>
        <ul>
          {ContributionTermsConditions.text_check_requirements.map(line => <li key={line}>{line}</li>)}
        </ul>
        <p>{ContributionTermsConditions.text_notes}</p>
        <ul>
          {ContributionTermsConditions.text_notes_list1.map(line => <li key={line}>{line}</li>)}
          <li><strong>{ContributionTermsConditions.text_notes_list2}</strong>{ContributionTermsConditions.text_notes_list3}</li>
          <ol>
            {text_notes_list4_sublists(phoneEnvironmentVars, isSpecialContribution).map(line => {
              if (Array.isArray(line)) {
                return (
                  <ol key='subNotes' type='i'>
                    {line.map(subLine => <li key={subLine}>{subLine}</li>)}
                  </ol>
                );
              } 
              else {
                return <li key={line}>{line}</li>;
              }
            })}
          </ol>
          <li>{ContributionTermsConditions.text_notes_list5}</li>
        </ul>
        <i>{ContributionTermsConditions.text_authorize}</i>
      </div>
    );
  }
}

export default (LanguageHOC(TermsConditions));
