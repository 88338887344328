import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { cloneDeep } from 'lodash';

import {
  ACCOUNT_OWNER,
  ACCOUNT_TYPE_UGMA_UTMA,
  BENEFICIARY,
  SCHOOL,
  RECURRING,
  ACCESS_CARD_PAYMENT,
  ACH_PAYMENT,
  HIGHER_ED,
  CHECK_PAYMENT,
} from '../../../constants';

import CheckAddress from '../CheckAddress';
import PaymentInfo from '../PaymentInfo';
import School from '../School';

import styles from '../styles.module.scss';

export class Step2PaymentMethod extends Component {
  static propTypes = {
    isEditing: PropTypes.bool.isRequired,
    accountIsUGMAUTMA: PropTypes.bool.isRequired,
    withdrawal: PropTypes.shape({
      paymentAccount: PropTypes.object.isRequired,
      paymentType: PropTypes.string.isRequired,
      recipient: PropTypes.string.isRequired,
      recipientAddress: PropTypes.object.isRequired,
      frequency: PropTypes.string.isRequired,
      withdrawalType: PropTypes.string.isRequired,
    }).isRequired,
    preTransactionDetails: PropTypes.shape({
      AccountType: PropTypes.string.isRequired,
      agentAddressChanged: PropTypes.bool.isRequired,
      beneficiaryAddressChanged: PropTypes.bool.isRequired,
    }).isRequired,
    withdrawalVariables: PropTypes.object.isRequired,
    usStates: PropTypes.array.isRequired,
    accessCards: PropTypes.array.isRequired,
    agentBankAccounts: PropTypes.array.isRequired,
    beneBankAccounts: PropTypes.array.isRequired,
    errors: PropTypes.shape({
      bankAccountError: PropTypes.string.isRequired,
      paymentTypeError: PropTypes.string.isRequired,
      schoolError: PropTypes.string.isRequired,
      street1Error: PropTypes.string.isRequired,
      cityError: PropTypes.string.isRequired,
      stateError: PropTypes.string.isRequired,
      postalCodeError: PropTypes.string.isRequired,
      attnError: PropTypes.string.isRequired,
      studentIdNumError: PropTypes.string.isRequired,
      deliveryType: PropTypes.string.isRequired,
    }).isRequired,
    paymentTypeHandle: PropTypes.func.isRequired,
    paymentAccountSelectHandle: PropTypes.func.isRequired,
    recipientAddressHandle: PropTypes.func.isRequired,
    stateSelectHandle: PropTypes.func.isRequired,
    handleSchoolSearch: PropTypes.func.isRequired,
    isSchoolRetained: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Payments: PropTypes.shape({
        nav_path: PropTypes.string
      }).isRequired,
      Withdrawals: PropTypes.shape({
        no_payment_accounts_text: PropTypes.func,
        ugmautma_check_only_txt: PropTypes.string,
      }).isRequired,
    }).isRequired,
    handleElectronicSchoolDeliveryType: PropTypes.func.isRequired,
    ElectronicSchoolDeliveryType: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const {
      accountIsUGMAUTMA,
      agentBankAccounts,
      beneBankAccounts,
      accessCards,
      paymentTypeHandle,
      withdrawal: {
        recipient,
        frequency,
      }
    } = this.props;

    // default to check
    if (frequency !== RECURRING) {
      if (
        (recipient === ACCOUNT_OWNER && agentBankAccounts.length === 0 && accessCards.length === 0) ||
        (recipient === BENEFICIARY && beneBankAccounts.length === 0) ||
        accountIsUGMAUTMA
      ) {
        paymentTypeHandle({ target: { value: CHECK_PAYMENT } });
      }
    }
  }

  render() {
    const {
      isEditing,
      withdrawal: {
        paymentAccount,
        paymentType,
        recipient,
        recipientAddress,
        frequency,
        withdrawalType
      },
      preTransactionDetails: {
        AccountType,
        agentAddressChanged,
        beneficiaryAddressChanged,
      },
      withdrawalVariables,
      usStates,
      accessCards,
      agentBankAccounts,
      beneBankAccounts,
      paymentTypeHandle,
      paymentAccountSelectHandle,
      recipientAddressHandle,
      stateSelectHandle,
      handleSchoolSearch,
      errors: {
        bankAccountError,
        paymentTypeError,
        schoolError,
        street1Error,
        cityError,
        stateError,
        postalCodeError,
        attnError,
        studentIdNumError,
        deliveryType,
      },
      isSchoolRetained,
      text: {
        Payments: {
          nav_path
        },
        Withdrawals: {
          no_payment_accounts_text,
          ugmautma_check_only_txt,
        }
      },
    } = this.props;
    const stepIndex = 2;
    const isRecurring = frequency === RECURRING;
    const isUgmaUtmaAccount = AccountType === ACCOUNT_TYPE_UGMA_UTMA;

    const onClickUrl = () => this.props.history.push(nav_path);

    let component;
    switch (recipient) {
      case ACCOUNT_OWNER:
        if (
          (agentBankAccounts.length === 0 && accessCards.length === 0) || isUgmaUtmaAccount
        )
          if (isRecurring) {
            component = <div className={styles.error}>{no_payment_accounts_text(onClickUrl)}</div>;
          }
          else
            component = (
              <div>
                {isUgmaUtmaAccount && <div className={styles.ugmautma}>{ugmautma_check_only_txt}</div>}
                <CheckAddress
                  stepIndex={stepIndex}
                  recipientAddress={recipientAddress}
                  recipient={recipient}
                  agentAddressChanged={agentAddressChanged}
                  beneficiaryAddressChanged={beneficiaryAddressChanged}
                />
              </div>
            );
        else {
          // hide access card or bank accounts radio buttons if not available, when access card also hide if not Higher Ed
          const customWithdrawalVariables = cloneDeep(withdrawalVariables);
          if (accessCards.length === 0 || withdrawalType !== HIGHER_ED) {
            customWithdrawalVariables.paymentTypes = customWithdrawalVariables.paymentTypes.filter(type => type.id !== ACCESS_CARD_PAYMENT);
          }

          if (agentBankAccounts.length === 0) {
            customWithdrawalVariables.paymentTypes = customWithdrawalVariables.paymentTypes.filter(type => type.id !== ACH_PAYMENT);
          }

          if (frequency === RECURRING) {
            // remove check from payment methods if there
            customWithdrawalVariables.paymentTypes = customWithdrawalVariables.paymentTypes.filter(type => type.id !== CHECK_PAYMENT);
          }

          component = (
            <PaymentInfo
              isEditing={isEditing}
              stepIndex={stepIndex}
              withdrawalVariables={customWithdrawalVariables}
              paymentType={paymentType}
              paymentTypeError={paymentTypeError}
              paymentAccount={paymentAccount}
              bankAccountError={bankAccountError}
              paymentAccounts={agentBankAccounts.map(account => ({ ...account, id: account.BankAccountId || account.AccessCardId }))} // combine payment accounts and create id property}
              recipientAddress={recipientAddress}
              recipient={recipient}
              agentAddressChanged={agentAddressChanged}
              beneficiaryAddressChanged={beneficiaryAddressChanged}
              paymentTypeHandle={paymentTypeHandle}
              paymentAccountSelectHandle={paymentAccountSelectHandle}
              frequency={frequency}
              accessCards={accessCards}
              agentBankAccounts={agentBankAccounts}
              beneBankAccounts={beneBankAccounts}
              withdrawalType={withdrawalType}
            />
          );
        }

        break;
      case BENEFICIARY:
        if (
          beneBankAccounts.length === 0 || isUgmaUtmaAccount
        )
          if (isRecurring) {
            component = <div className={styles.error}>{no_payment_accounts_text(onClickUrl)}</div>;
          }
          else {
            component = (
              <div>
                {isUgmaUtmaAccount && <div className={styles.ugmautma}>{ugmautma_check_only_txt}</div>}
                <CheckAddress
                  stepIndex={stepIndex}
                  recipientAddress={recipientAddress}
                  recipient={recipient}
                  agentAddressChanged={agentAddressChanged}
                  beneficiaryAddressChanged={beneficiaryAddressChanged}
                />
              </div>
            );
          }

        else {
          // always hide access cards or hide bank accounts radio buttons if not available
          const customWithdrawalVariables = cloneDeep(withdrawalVariables);
          customWithdrawalVariables.paymentTypes = customWithdrawalVariables.paymentTypes.filter(type => type.id !== ACCESS_CARD_PAYMENT);
          if (beneBankAccounts.length === 0) {
            customWithdrawalVariables.paymentTypes = customWithdrawalVariables.paymentTypes.filter(type => type.id !== ACH_PAYMENT);
          }

          if (frequency === RECURRING) {
            // remove check from payment methods if there
            customWithdrawalVariables.paymentTypes = customWithdrawalVariables.paymentTypes.filter(type => type.id !== CHECK_PAYMENT);
          }

          component = (
            <PaymentInfo
              isEditing={isEditing}
              stepIndex={stepIndex}
              withdrawalVariables={customWithdrawalVariables}
              paymentType={paymentType}
              paymentTypeError={paymentTypeError}
              paymentAccount={paymentAccount}
              bankAccountError={bankAccountError}
              paymentAccounts={beneBankAccounts}
              recipientAddress={recipientAddress}
              recipient={recipient}
              agentAddressChanged={agentAddressChanged}
              beneficiaryAddressChanged={beneficiaryAddressChanged}
              paymentTypeHandle={paymentTypeHandle}
              paymentAccountSelectHandle={paymentAccountSelectHandle}
              frequency={frequency}
              accessCards={accessCards}
              agentBankAccounts={agentBankAccounts}
              beneBankAccounts={beneBankAccounts}
              withdrawalType={withdrawalType}
            />
          );
        }

        break;
      case SCHOOL:
        component = (
          <School
            recipientAddress={recipientAddress}
            usStates={usStates}
            recipientAddressHandle={recipientAddressHandle}
            isEditing={isEditing}
            stateSelectHandle={stateSelectHandle}
            handleSchoolSearch={handleSchoolSearch}
            isSchoolRetained={isSchoolRetained}
            errors={{
              schoolError,
              street1Error,
              cityError,
              stateError,
              postalCodeError,
              attnError,
              studentIdNumError,
              deliveryType,
            }}
            handleElectronicSchoolDeliveryType={this.props.handleElectronicSchoolDeliveryType}
            ElectronicSchoolDeliveryType={this.props.ElectronicSchoolDeliveryType}
          />
        );
        break;
      default:
        component = <div>Recipient not found!</div>;
    }

    return component;
  }


}

export default withRouter(LanguageHOC(Step2PaymentMethod));