/*
*
* Welcome Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
} from '@mui/material';
import { My529Logo } from '@frontend/common';
import reader from './reader.jpg';
import { tabletWidth } from 'utils/config/_sassconfig.scss';

import styles from './styles.module.scss';


export function Welcome({ activeStep, closeModal, isAgent_AccessRole, isNewUser_AccessRole, isIpOnly_AccessRole, open, text, text: { SignupWelcome } }) {

  const fullScreen = window.innerWidth < parseInt(tabletWidth);
  const showNewWelcome = (isNewUser_AccessRole && activeStep === 0) || (isIpOnly_AccessRole && activeStep === 0) || (isAgent_AccessRole && activeStep === 1);

  const stepsToComplete = [
    text.AccountOwnerStep.head_account_owner,
    text.BeneficiaryStep.head_beneficiary,
    text.InvestmentOptionStep.head_investment_option,
    text.ReviewStep.head_review,
  ];

  return (
    <Dialog
      aria-labelledby={SignupWelcome.head_welcome}
      fullScreen={fullScreen}
      maxWidth='xs'
      onClose={closeModal}
      open={open}
      scroll={fullScreen ? 'body' : 'paper'}
    >
      <DialogTitle style={{ padding: '0px', height: '250px' }}>
        <div className={styles.imgContainer}>
          <img src={reader} alt={SignupWelcome.text_child_reading} className={styles.picture} />
          <My529Logo color='white' className={styles.logo} />
        </div>
      </DialogTitle>

      <DialogContent className={styles.contentContainer}>
        <div>
          <div className={styles.welcomeContainer}>
            <h1>{showNewWelcome ? SignupWelcome.head_welcome : SignupWelcome.head_welcome_back},</h1>
            <p>{showNewWelcome ? SignupWelcome.text_new_welcome : SignupWelcome.text_welcome_back}</p>
          </div>

          <div className={styles.stepsContainer}>
            <p>{SignupWelcome.text_steps_remaining}</p>
            {stepsToComplete.map((step, idx) => (
              <div className={styles.progressContainer} key={step}>
                {activeStep > idx ? <Icon>done</Icon> : <Icon />}
                <p>{step}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            style={{ color: 'white', borderColor: 'white', width: '100%' }}
            onClick={() => closeModal()}
            variant='outlined'
          >
            {showNewWelcome ? SignupWelcome.btn_get_started : SignupWelcome.btn_continue}
          </Button>
        </div>

      </DialogContent>
    </Dialog>
  );
}

Welcome.propTypes = {
  activeStep: PropTypes.number, // left off the ".isRequired" b/c sometimes this is null and it will throw a console error
  closeModal: PropTypes.func.isRequired,
  isAgent_AccessRole: PropTypes.bool.isRequired,
  isNewUser_AccessRole: PropTypes.bool.isRequired,
  isIpOnly_AccessRole: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.shape({
    AccountOwnerStep: PropTypes.shape({ head_account_owner: PropTypes.string }),
    BeneficiaryStep: PropTypes.shape({ head_beneficiary: PropTypes.string }),
    InvestmentOptionStep: PropTypes.shape({ head_investment_option: PropTypes.string }),
    ReviewStep: PropTypes.shape({ head_review: PropTypes.string }),
    SignupWelcome: PropTypes.shape({
      btn_continue: PropTypes.string,
      btn_get_started: PropTypes.string,
      head_welcome: PropTypes.string,
      head_welcome_back: PropTypes.string,
      text_child_reading: PropTypes.string,
      text_new_welcome: PropTypes.string,
      text_steps_remaining: PropTypes.string,
      text_steps_to_complete: PropTypes.string,
      text_welcome_back: PropTypes.string,
    }),
  }).isRequired,
};

export default LanguageHOC(Welcome);