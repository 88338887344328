/*
*
* Payments Reducers
*
*/
import { cloneDeep } from 'lodash';
import { properCase } from '@frontend/common';
import {
  CHECK_ROUTING_NUMBER,
  GET_BANK_ACCOUNTS,
  GET_AGENT_BANK_ACCOUNTS,
  GET_GROUPED_BANK_ACCOUNTS,
  GET_ALLOWED_BANK_HOLDERS,
  GET_ACCESS_CARDS,
  UPDATE_NICKNAME,
} from './constants';

const initialState = {
  routingNumberBank: '',
  bankAccounts: [],
  agentBankAccounts: [],
  groupedBankAccounts: {},
  allowedBankHolders: [],
  accessCards: [],
};

function PaymentsReducer(state = initialState, action) {

  const newState = cloneDeep(state);

  switch (action.type) {

    case CHECK_ROUTING_NUMBER: {
      newState.routingNumberBank = action.payload.data.BankName;
      return newState;
    }

    case GET_BANK_ACCOUNTS: {
      newState.bankAccounts = action.payload.data.map(bankAccount => ({
        ...bankAccount,
        BankName: bankAccount.BankName.toUpperCase(),
      }));
      return newState;
    }

    case GET_AGENT_BANK_ACCOUNTS: {
      newState.agentBankAccounts = action.payload.data.map(bankAccount => ({
        $id: bankAccount.$id,
        BankAccountId: bankAccount.BankAccountId,
        BankName: bankAccount.BankName.toUpperCase(),
        MaskedBankAccountNumber: bankAccount.MaskedBankAccountNumber,
        RoutingNumber: bankAccount.RoutingNumber,
        BankAccountType: bankAccount.BankAccountType,
        AllowWithdrawals: bankAccount.AllowWithdrawals,
        IsPendingWithdrawalVerification: bankAccount.IsPendingWithdrawalVerification,
        VerificationAttemptsRemaining: bankAccount.VerificationAttemptsRemaining,
        HasExceededFullVerificationAttempts: bankAccount.HasExceededFullVerificationAttempts,
        BeneficiaryId: bankAccount.BeneficiaryId,
        BeneficiaryName: bankAccount.BeneficiaryName,
        IsEligibleForPoa: bankAccount.IsEligibleForPoa,
        IsPoaApproved: bankAccount.IsPoaApproved,
        ExpirationDate: bankAccount.ExpirationDate,
        ExpirationWarningDate: bankAccount.ExpirationWarningDate,
        HasPendingACHTransactions: bankAccount.HasPendingACHTransactions,
        GiactResult: bankAccount.GiactResult,
        Nickname: bankAccount.Nickname,
      }));
      return newState;
    }

    case GET_GROUPED_BANK_ACCOUNTS: {
      // group bank accounts by owner and beneficiaries
      const groupedBankAccounts = state.bankAccounts.reduce((groupedBankAccounts, bankAccount) => {
        const nameId = bankAccount.BeneficiaryName ? `${properCase(bankAccount.BeneficiaryName)}'s Bank Accounts - Withdrawals Only` : 'My Bank Accounts';
        groupedBankAccounts[nameId] = groupedBankAccounts[nameId] || [];
        groupedBankAccounts[nameId].push(bankAccount);

        return groupedBankAccounts;
      }, {});

      newState.groupedBankAccounts = groupedBankAccounts;
      return newState;
    }

    case GET_ALLOWED_BANK_HOLDERS: {
      const allowedBankHolders = [
        {
          id: action.userDetails.agentId,
          name: action.userDetails.name.toUpperCase(),
          isBeneficiary: false,
        },
      ];

      action.accountList.forEach(account => {
        const canWithdrawACH = account.permissions.WithdrawACH;
        const allowedBankHolder = {
          id: account.beneficiary.beneficiaryId,
          name: account.beneficiary.fullName.toUpperCase(),
          isBeneficiary: true,
        };
        const beneIsNotAgent = action.userDetails.agentId !== account.beneficiary.beneficiaryId;
        if (canWithdrawACH && beneIsNotAgent && !allowedBankHolders.some(bankHolder => bankHolder.id === allowedBankHolder.id)) {
          allowedBankHolders.push(allowedBankHolder);
        }
      });

      newState.allowedBankHolders = allowedBankHolders;
      return newState;
    }

    case GET_ACCESS_CARDS: {
      newState.accessCards = action.payload.data;
      return newState;
    }

    case UPDATE_NICKNAME: {
      Object.keys(newState.groupedBankAccounts).forEach(accountOwner => {
        const bankAccountGroup = newState.groupedBankAccounts[accountOwner];
        bankAccountGroup.forEach(account => {
          if (account.BankAccountId === action.meta.BankAccountId) {
            account.Nickname = action.meta.nickName;
          }
        });
      });
      return newState;
    }

    default:
      return state;
  }

}

export default PaymentsReducer;