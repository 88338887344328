/*
*
* ValidationStart Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Modal,
} from '@frontend/common';


ValidationStartModal.propTypes = {
  open: PropTypes.bool.isRequired,
  validationStarted: PropTypes.bool.isRequired,
  selectedBankAccount: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  startMicroDeposits: PropTypes.func.isRequired,
  handleGetBankAccounts: PropTypes.func.isRequired,
  handleButtonIsLoading: PropTypes.func.isRequired,
  handleShowValidationStart: PropTypes.func.isRequired,
  handleValidationStarted: PropTypes.func.isRequired,
  text: PropTypes.shape({
    ValidationStart: PropTypes.shape({
      btn_cancel: PropTypes.string,
      btn_begin: PropTypes.string,
      btn_done: PropTypes.string,
      head_validate: PropTypes.func,
      text_complete_to_verify: PropTypes.string,
      text_step_1: PropTypes.string,
      text_step_2: PropTypes.string,
      text_step_3: PropTypes.string,
      text_step_4: PropTypes.string,
      text_step_5: PropTypes.string,
      text_step_done: PropTypes.string,
    })
  })
};

export function ValidationStartModal({ 
  open, 
  validationStarted, 
  selectedBankAccount, 
  isLoading, 
  startMicroDeposits,
  handleGetBankAccounts,
  handleButtonIsLoading, 
  handleShowValidationStart, 
  handleValidationStarted, 
  text : {
    ValidationStart,
  }
}) {
  const actionButtons = !validationStarted ?
    [
      {
        label: ValidationStart.btn_begin,
        loading: isLoading,
        action: () => {
          handleButtonIsLoading();
          startMicroDeposits(selectedBankAccount.BankAccountId)
            .then(() => {
              handleGetBankAccounts();
              handleValidationStarted();
              handleButtonIsLoading();
            })
            .catch(() => {
              handleButtonIsLoading();
            });
        },
        buttonType: 'contained',
      }
    ] :
    [
      {
        label: ValidationStart.btn_done,
        action: () => handleShowValidationStart(),
        buttonType: 'contained',
      },
    ];
    
  return (
    <Modal
      title={ValidationStart.head_validate(selectedBankAccount.MaskedBankAccountNumber)}
      show={open}
      onCloseModal={() => handleShowValidationStart()}
      modal={false}
      maxWidth='md'
      fullWidth
      actionButtons={actionButtons}
      includeLeftCancelBtn={!validationStarted}
    >
      {!validationStarted ?
        (<div>
          <p style={{ fontSize: 'large' }}> 
            {ValidationStart.text_complete_to_verify}
          </p> 
          <ul>
            <li>{ValidationStart.text_step_1}</li>
            <li>{ValidationStart.text_step_2}</li>
            <ul>
              <li>{ValidationStart.text_step_3}</li> 
              <li>{ValidationStart.text_step_4}</li>
            </ul>
          </ul>
          <p style={{ fontWeight: 'bold' }}>{ValidationStart.text_step_5}</p>
        </div>) :
        (
          <div>
            <p>{ValidationStart.text_step_done}</p>
          </div>
        )
      }
    </Modal>
  );
}

export default LanguageHOC(ValidationStartModal);