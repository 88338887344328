/*
*
* Required Access
* Use this file to store access rights for all protected routes.
* Simple Signup user should not have access to any route other than /signup (and be able to log out)
*
*/

export const ACCESS_ROLE = {
  IS_AGENT: 'IS_AGENT', // Agent but can also have Interested Party accounts
  IS_IP_ONLY: 'IS_IP_ONLY', // Interested Party only
  IS_NEW_USER: 'IS_NEW_USER', // Is a new user with no accounts
};

export const ROUTE_ACCESS = {
  AccountBlocks: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY,
    ACCESS_ROLE.IS_NEW_USER
  ],
  AccountDetails: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY,
  ],
  Accounts: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY,
  ],
  TransactionsHub: [
    ACCESS_ROLE.IS_AGENT,
  ],
  AddBirthdate: [
    ACCESS_ROLE.IS_AGENT, // only agents should be affected by this
  ],
  Payments: [
    ACCESS_ROLE.IS_AGENT,
  ],
  Documents: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY,
  ],
  Gifting: [
    ACCESS_ROLE.IS_AGENT,
  ],
  Home: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY,
  ],
  InterestedParties: [
    ACCESS_ROLE.IS_AGENT,
  ],
  Lpoa: [
    ACCESS_ROLE.IS_AGENT,
  ],
  Multifactor: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY,
  ],
  MyInfo: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY,
  ],
  PageNotFound: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY,
    ACCESS_ROLE.IS_NEW_USER,
  ],
  PayrollContribution: [
    ACCESS_ROLE.IS_AGENT
  ],
  Signup: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY,
    ACCESS_ROLE.IS_NEW_USER,
  ],
  Transactions: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY, // allows CSA IPs to request withdrawals
  ],
  VerifyEmail: [
    ACCESS_ROLE.IS_AGENT,
    ACCESS_ROLE.IS_IP_ONLY,
    ACCESS_ROLE.IS_NEW_USER,
  ],
};
