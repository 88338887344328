import dayjs from 'dayjs';
import English from 'utils/translations/en.js';

export function greetingGenerator() {
  const { Home } = English;
  const hour = dayjs().format('HH'); // military hour of the day

  if (hour >= 5 && hour < 12) { // between 5am and 12pm
    return Home.head_greeting_morning;
  }
  else if (hour >= 12 && hour < 17) { // between 12pm and 5pm
    return Home.head_greeting_afternoon;
  }
  else if (hour >= 17 || hour < 5) { // between 5pm and 5am, or is required here since hours start over at 24
    return Home.head_greeting_evening;
  }
}