import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {
  CalculatorForm,
} from '@frontend/common';

import styles from '../styles.module.scss';

export default class Step4PrintReview extends React.Component {
  static propTypes = {
    pendingOptionName: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
    currentSchema: PropTypes.object.isRequired,
    isCustomized: PropTypes.bool,
  }

  render() {
    const { currentSchema, isCustomized, createdDate } = this.props;
    return (
      <div className={styles.printContainer}>
        <div className={styles.pendingOption}>Pending Option Change</div>
        <div>{this.props.pendingOptionName}</div>
        <div className={styles.currentTime}>{dayjs(createdDate)
          .format('MM/DD/YYYY h:mm A')} MT</div>
        {isCustomized &&
          <CalculatorForm
            stickyHeaderTopOffset={60}
            hideTemplateName={true}
            isViewing={true}
            hidePrintBtn={true}
            schema={currentSchema}
          />}
        <div className={`${styles.print} hideOnPrint`}>
          Please print a copy of this page for your records.
        </div>
      </div>
    );
  }
}
