/*
*
* AccountEdit Component
*
*/
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import dayjs from 'dayjs';
import LanguageHOC from 'utils/translations/LanguageHOC';
import * as validator from 'utils/helpers/form_validation';

import { PERSON_TYPES, ADDRESS_TYPES } from '../constants';

import FundAllocationsAndFees from './FundAllocationsAndFees';
import PersonInput from './PersonInput';
import AddressInput from './AddressInput';
import AoContactInput from './AoContactInput';
import AccountSummary from './AccountSummary';

import {
  getLpoaAccount,
  saveToReduxLpoaAccount,
  validateLpoaAccount,
} from '../actions';

import {
  LoadingOverlay,
  notificationShow,
  allNotificationsHide,
} from '@frontend/common';

import styles from './styles.module.scss';


const select = (state) => ({
  applicationInfo: state.lpoaAccount.applicationInfo,
  documents: state.static.documents ? state.static.documents : {},
});

export class AccountEdit extends Component {
  static propTypes = {
    applicationInfo: PropTypes.object.isRequired,
    documents: PropTypes.object,
    showRejectModalHandler: PropTypes.func.isRequired,
    rejectLoading: PropTypes.bool.isRequired,
    getLpoaAccount: PropTypes.func.isRequired,
    onNextHandler: PropTypes.func.isRequired,
    renderNavigationButton: PropTypes.func.isRequired,
    saveToReduxLpoaAccount: PropTypes.func.isRequired,
    validateLpoaAccount: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    text: PropTypes.shape({
      LpoaAccount: PropTypes.object.isRequired,
    }).isRequired,
  };

  apiEditableKeyNames = [
    'OwnerFirstName',
    'OwnerMiddleName',
    'OwnerLastName',
    'OwnerBirthdate',
    'OwnerSSN',
    'OwnerIsResident',
    'OwnerMailingAddress1',
    'OwnerMailingAddress2',
    'OwnerMailingCity',
    'OwnerMailingState',
    'OwnerMailingZipCode',
    'OwnerPhysicalAddress1',
    'OwnerPhysicalAddress2',
    'OwnerPhysicalCity',
    'OwnerPhysicalState',
    'OwnerPhysicalZipCode',
    'OwnerEmail',
    'OwnerHomePhone',
    'OwnerWorkPhone',
    'OwnerOtherPhone',
    'Relationship',
    'BeneficiaryFirstName',
    'BeneficiaryMiddleName',
    'BeneficiaryLastName',
    'BeneficiaryBirthdate',
    'BeneficiarySSN',
    'BeneficiaryIsResident',
    'BeneficiaryMailingAddress1',
    'BeneficiaryMailingAddress2',
    'BeneficiaryMailingCity',
    'BeneficiaryMailingState',
    'BeneficiaryMailingZipCode',
    'BeneficiaryPhysicalAddress1',
    'BeneficiaryPhysicalAddress2',
    'BeneficiaryPhysicalCity',
    'BeneficiaryPhysicalState',
    'BeneficiaryPhysicalZipCode',
    'SuccessorFirstName',
    'SuccessorMiddleName',
    'SuccessorLastName',
    'SuccessorBirthdate',
    'SuccessorSSN',
    'SuccessorUtahResident',
    'SuccessorMailingAddress1',
    'SuccessorMailingAddress2',
    'SuccessorMailingCity',
    'SuccessorMailingState',
    'SuccessorMailingZipCode',
    'SuccessorPhysicalAddress1',
    'SuccessorPhysicalAddress2',
    'SuccessorPhysicalCity',
    'SuccessorPhysicalState',
    'SuccessorPhysicalZipCode',
    'SSuccessorFirstName',
    'SSuccessorMiddleName',
    'SSuccessorLastName',
    'SSuccessorBirthDate',
    'SSuccessorSSN',
    'SSuccessorUtahResident',
    'SSuccessorMailingAddress1',
    'SSuccessorMailingAddress2',
    'SSuccessorMailingCity',
    'SSuccessorMailingState',
    'SSuccessorMailingZipCode',
    'SSuccessorPhysicalAddress1',
    'SSuccessorPhysicalAddress2',
    'SSuccessorPhysicalCity',
    'SSuccessorPhysicalState',
    'SSuccessorPhysicalZipCode',
    'PoaLevel',
    'CanViewFedTaxDocs',
    'CanViewStateTaxDocs',
  ];

  clearedApplicationInfoErrors = {
    [PERSON_TYPES.AO]: {
      firstName: '',
      middleName: '',
      lastName: '',
      birthDate: '',
      ssn: '',
      isUtahPayer: '',
      [ADDRESS_TYPES.MAILING]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      contact: {
        email: '',
        homePhone: '',
        workPhone: '',
        otherPhone: '',
        relationship: '',
      },
    },
    [PERSON_TYPES.BENE]: {
      firstName: '',
      middleName: '',
      lastName: '',
      birthDate: '',
      ssn: '',
      isUtahPayer: '',
      [ADDRESS_TYPES.MAILING]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    [PERSON_TYPES.SUCCESSOR_PRIMARY]: {
      firstName: '',
      middleName: '',
      lastName: '',
      birthDate: '',
      ssn: '',
      isUtahPayer: '',
      [ADDRESS_TYPES.MAILING]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    [PERSON_TYPES.SUCCESSOR_SECONDARY]: {
      firstName: '',
      middleName: '',
      lastName: '',
      birthDate: '',
      ssn: '',
      isUtahPayer: '',
      [ADDRESS_TYPES.MAILING]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      [ADDRESS_TYPES.PHYSICAL]: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
      },
    },
    accountSummary: {
      poaLevel: '',
      fedTaxDocs: '',
      stateTaxDocs: '',
    },
  };

  state = {
    applicationInfo: this.props.applicationInfo,
    accountLoading: false,
    validationLoading: false,
    applicationInfoErrors: cloneDeep(this.clearedApplicationInfoErrors),
    applicationInfoErrorsCleared: true,
    isAgeBased: false,
    selectedAgeBand: 0,
  }

  findErrorMessageByKey = (errors, key) => {
    const foundError = errors.find(err => err.Field === key);
    return foundError ? foundError.Message : '';
  }

  mapApiErrors(apiErrors) {
    const applicationInfoErrors = {
      [PERSON_TYPES.AO]: {
        firstName: this.findErrorMessageByKey(apiErrors, 'OwnerFirstName'),
        middleName: this.findErrorMessageByKey(apiErrors, 'OwnerMiddleName'),
        lastName: this.findErrorMessageByKey(apiErrors, 'OwnerLastName'),
        birthDate: this.findErrorMessageByKey(apiErrors, 'OwnerBirthdate'),
        ssn: this.findErrorMessageByKey(apiErrors, 'OwnerSSN'),
        isUtahPayer: this.findErrorMessageByKey(apiErrors, 'OwnerIsResident'),
        [ADDRESS_TYPES.MAILING]: {
          street1: this.findErrorMessageByKey(apiErrors, 'OwnerMailingAddress1'),
          street2: this.findErrorMessageByKey(apiErrors, 'OwnerMailingAddress2'),
          city: this.findErrorMessageByKey(apiErrors, 'OwnerMailingCity'),
          state: this.findErrorMessageByKey(apiErrors, 'OwnerMailingState'),
          zipCode: this.findErrorMessageByKey(apiErrors, 'OwnerMailingZipCode'),
        },
        [ADDRESS_TYPES.PHYSICAL]: {
          street1: this.findErrorMessageByKey(apiErrors, 'OwnerPhysicalAddress1'),
          street2: this.findErrorMessageByKey(apiErrors, 'OwnerPhysicalAddress2'),
          city: this.findErrorMessageByKey(apiErrors, 'OwnerPhysicalCity'),
          state: this.findErrorMessageByKey(apiErrors, 'OwnerPhysicalState'),
          zipCode: this.findErrorMessageByKey(apiErrors, 'OwnerPhysicalZipCode'),
        },
        contact: {
          email: this.findErrorMessageByKey(apiErrors, 'OwnerEmail'),
          homePhone: this.findErrorMessageByKey(apiErrors, 'OwnerHomePhone'),
          workPhone: this.findErrorMessageByKey(apiErrors, 'OwnerWorkPhone'),
          otherPhone: this.findErrorMessageByKey(apiErrors, 'OwnerOtherPhone'),
          relationship: this.findErrorMessageByKey(apiErrors, 'Relationship'),
        },
      },
      [PERSON_TYPES.BENE]: {
        firstName: this.findErrorMessageByKey(apiErrors, 'BeneficiaryFirstName'),
        middleName: this.findErrorMessageByKey(apiErrors, 'BeneficiaryMiddleName'),
        lastName: this.findErrorMessageByKey(apiErrors, 'BeneficiaryLastName'),
        birthDate: this.findErrorMessageByKey(apiErrors, 'BeneficiaryBirthdate'),
        ssn: this.findErrorMessageByKey(apiErrors, 'BeneficiarySSN'),
        isUtahPayer: this.findErrorMessageByKey(apiErrors, 'BeneficiaryIsResident'),
        [ADDRESS_TYPES.MAILING]: {
          street1: this.findErrorMessageByKey(apiErrors, 'BeneficiaryMailingAddress1'),
          street2: this.findErrorMessageByKey(apiErrors, 'BeneficiaryMailingAddress2'),
          city: this.findErrorMessageByKey(apiErrors, 'BeneficiaryMailingCity'),
          state: this.findErrorMessageByKey(apiErrors, 'BeneficiaryMailingState'),
          zipCode: this.findErrorMessageByKey(apiErrors, 'BeneficiaryMailingZipCode'),
        },
        [ADDRESS_TYPES.PHYSICAL]: {
          street1: this.findErrorMessageByKey(apiErrors, 'BeneficiaryPhysicalAddress1'),
          street2: this.findErrorMessageByKey(apiErrors, 'BeneficiaryPhysicalAddress2'),
          city: this.findErrorMessageByKey(apiErrors, 'BeneficiaryPhysicalCity'),
          state: this.findErrorMessageByKey(apiErrors, 'BeneficiaryPhysicalState'),
          zipCode: this.findErrorMessageByKey(apiErrors, 'BeneficiaryPhysicalZipCode'),
        },
      },
      [PERSON_TYPES.SUCCESSOR_PRIMARY]: {
        firstName: this.findErrorMessageByKey(apiErrors, 'SuccessorFirstName'),
        middleName: this.findErrorMessageByKey(apiErrors, 'SuccessorMiddleName'),
        lastName: this.findErrorMessageByKey(apiErrors, 'SuccessorLastName'),
        birthDate: this.findErrorMessageByKey(apiErrors, 'SuccessorBirthdate'),
        ssn: this.findErrorMessageByKey(apiErrors, 'SuccessorSSN'),
        isUtahPayer: this.findErrorMessageByKey(apiErrors, 'SuccessorUtahResident'),
        [ADDRESS_TYPES.MAILING]: {
          street1: this.findErrorMessageByKey(apiErrors, 'SuccessorMailingAddress1'),
          street2: this.findErrorMessageByKey(apiErrors, 'SuccessorMailingAddress2'),
          city: this.findErrorMessageByKey(apiErrors, 'SuccessorMailingCity'),
          state: this.findErrorMessageByKey(apiErrors, 'SuccessorMailingState'),
          zipCode: this.findErrorMessageByKey(apiErrors, 'SuccessorMailingZipCode'),
        },
        [ADDRESS_TYPES.PHYSICAL]: {
          street1: this.findErrorMessageByKey(apiErrors, 'SuccessorPhysicalAddress1'),
          street2: this.findErrorMessageByKey(apiErrors, 'SuccessorPhysicalAddress2'),
          city: this.findErrorMessageByKey(apiErrors, 'SuccessorPhysicalCity'),
          state: this.findErrorMessageByKey(apiErrors, 'SuccessorPhysicalState'),
          zipCode: this.findErrorMessageByKey(apiErrors, 'SuccessorPhysicalZipCode'),
        },
      },
      [PERSON_TYPES.SUCCESSOR_SECONDARY]: {
        firstName: this.findErrorMessageByKey(apiErrors, 'SSuccessorFirstName'),
        middleName: this.findErrorMessageByKey(apiErrors, 'SSuccessorMiddleName'),
        lastName: this.findErrorMessageByKey(apiErrors, 'SSuccessorLastName'),
        birthDate: this.findErrorMessageByKey(apiErrors, 'SSuccessorBirthDate'),
        ssn: this.findErrorMessageByKey(apiErrors, 'SSuccessorSSN'),
        isUtahPayer: this.findErrorMessageByKey(apiErrors, 'SSuccessorUtahResident'),
        [ADDRESS_TYPES.MAILING]: {
          street1: this.findErrorMessageByKey(apiErrors, 'SSuccessorMailingAddress1'),
          street2: this.findErrorMessageByKey(apiErrors, 'SSuccessorMailingAddress2'),
          city: this.findErrorMessageByKey(apiErrors, 'SSuccessorMailingCity'),
          state: this.findErrorMessageByKey(apiErrors, 'SSuccessorMailingState'),
          zipCode: this.findErrorMessageByKey(apiErrors, 'SSuccessorMailingZipCode'),
        },
        [ADDRESS_TYPES.PHYSICAL]: {
          street1: this.findErrorMessageByKey(apiErrors, 'SSuccessorPhysicalAddress1'),
          street2: this.findErrorMessageByKey(apiErrors, 'SSuccessorPhysicalAddress2'),
          city: this.findErrorMessageByKey(apiErrors, 'SSuccessorPhysicalCity'),
          state: this.findErrorMessageByKey(apiErrors, 'SSuccessorPhysicalState'),
          zipCode: this.findErrorMessageByKey(apiErrors, 'SSuccessorPhysicalZipCode'),
        },
      },
      accountSummary: {
        poaLevel: this.findErrorMessageByKey(apiErrors, 'PoaLevel'),
        fedTaxDocs: this.findErrorMessageByKey(apiErrors, 'CanViewFedTaxDocs'),
        stateTaxDocs: this.findErrorMessageByKey(apiErrors, 'CanViewStateTaxDocs'),
      },
    };

    return applicationInfoErrors;
  }

  clearToastErrorsOnce = () => {
    if (!this.state.applicationInfoErrorsCleared) {
      this.props.allNotificationsHide();
      this.setState({
        applicationInfoErrorsCleared: true,
      });
    }
  }

  copyAddress = (personType, addressType) => {
    this.clearToastErrorsOnce();
    const updatedApplicationInfoErrors = cloneDeep(this.state.applicationInfoErrors);

    const updatedApplicationInfo = cloneDeep(this.state.applicationInfo);
    const personToCopyTo = personType === PERSON_TYPES.AO ? PERSON_TYPES.BENE : PERSON_TYPES.AO;
    updatedApplicationInfo[personToCopyTo][addressType] = cloneDeep(updatedApplicationInfo[personType][addressType]);
    updatedApplicationInfoErrors[personToCopyTo][addressType].street1 = '';
    updatedApplicationInfoErrors[personToCopyTo][addressType].street2 = '';
    updatedApplicationInfoErrors[personToCopyTo][addressType].city = '';
    updatedApplicationInfoErrors[personToCopyTo][addressType].state = '';
    updatedApplicationInfoErrors[personToCopyTo][addressType].zipCode = '';

    this.setState({
      applicationInfoErrors: updatedApplicationInfoErrors,
      applicationInfo: updatedApplicationInfo,
    });
  }

  onPersonInput = (value, personType, keyName) => {
    this.clearToastErrorsOnce();
    const updatedApplicationInfoErrors = cloneDeep(this.state.applicationInfoErrors);
    updatedApplicationInfoErrors[personType][keyName] = '';

    const updatedApplicationInfo = cloneDeep(this.state.applicationInfo);
    updatedApplicationInfo[personType][keyName] = value;

    // update bene age and band dropdown if bene's dob changed
    if (personType === PERSON_TYPES.BENE && keyName === 'birthDate') {
      const beneAge = dayjs().diff(dayjs(value), 'year');
      updatedApplicationInfo.beneAge = beneAge;
      this.setAgeBased(updatedApplicationInfo);
    }

    this.setState({
      applicationInfoErrors: updatedApplicationInfoErrors,
      applicationInfo: updatedApplicationInfo,
    });
  }

  onAddressInput = (value, personType, addressType, keyName) => {
    this.clearToastErrorsOnce();
    const updatedApplicationInfoErrors = cloneDeep(this.state.applicationInfoErrors);
    updatedApplicationInfoErrors[personType][addressType][keyName] = '';

    const updatedApplicationInfo = cloneDeep(this.state.applicationInfo);
    updatedApplicationInfo[personType][addressType][keyName] = value;

    if (keyName === 'city') {
      updatedApplicationInfoErrors[personType][addressType].city = validator.cityValidator(updatedApplicationInfo[personType][addressType].city);
    }

    this.setState({
      applicationInfoErrors: updatedApplicationInfoErrors,
      applicationInfo: updatedApplicationInfo,
    });
  }

  onAccountSummaryInput = (value, keyName) => {
    this.clearToastErrorsOnce();
    const updatedApplicationInfoErrors = cloneDeep(this.state.applicationInfoErrors);
    updatedApplicationInfoErrors.accountSummary[keyName] = '';

    const updatedApplicationInfo = cloneDeep(this.state.applicationInfo);
    updatedApplicationInfo.accountSummary[keyName] = value;

    this.setState({
      applicationInfoErrors: updatedApplicationInfoErrors,
      applicationInfo: updatedApplicationInfo,
    });
  }

  onAoContactInput = (value, keyName) => {
    this.clearToastErrorsOnce();
    const updatedApplicationInfoErrors = cloneDeep(this.state.applicationInfoErrors);
    updatedApplicationInfoErrors[PERSON_TYPES.AO].contact[keyName] = '';

    const updatedApplicationInfo = cloneDeep(this.state.applicationInfo);
    updatedApplicationInfo[PERSON_TYPES.AO].contact[keyName] = value;

    this.setState({
      applicationInfoErrors: updatedApplicationInfoErrors,
      applicationInfo: updatedApplicationInfo,
    });
  }

  onAgeBandSelection = (index) => {
    this.setState({ selectedAgeBand: index });
  }

  setAgeBased = (applicationInfo) => {
    const isAgeBased = applicationInfo.ageBands.length > 1;
    const beneAge = applicationInfo.beneAge;
    const beneficiaryAgeBandIndex = applicationInfo.ageBands.findIndex(ageBand => (ageBand.MinimumAge <= beneAge || ageBand.MinimumAge === null) && (ageBand.MaximumAge >= beneAge || ageBand.MaximumAge === null));
    this.setState({
      isAgeBased,
      selectedAgeBand: beneficiaryAgeBandIndex > -1 ? beneficiaryAgeBandIndex : 0,
    });
  }

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onNextHandler = (e) => {
    e.preventDefault();

    const {
      validateLpoaAccount,
      saveToReduxLpoaAccount,
      onNextHandler,
      notificationShow,
      text: {
        LpoaAccount: {
          account_edit,
        }
      }
    } = this.props;

    this.setState({
      validationLoading: true,
      applicationErrorsCleared: false,
    });
    validateLpoaAccount(this.state.applicationInfo, this.state.guid)
      .then(() => {
        // validation successful
        saveToReduxLpoaAccount(this.state.applicationInfo);
        onNextHandler();
      })
      .catch(response => {
        const apiErrors = response.payload.data;
        const updatedApplicationErrors = this.mapApiErrors(apiErrors);

        this.setState({
          validationLoading: false,
          applicationInfoErrorsCleared: false,
          applicationInfoErrors: updatedApplicationErrors,
        });

        // if there are no matched errors (for example non-editable ones) it needs to be shown as toast errors
        apiErrors
          .filter(error => error.Field !== null && this.apiEditableKeyNames.indexOf(error.Field) === -1)
          .forEach((error) => {
            notificationShow(error.Message, 'error');
          });

        // show a warning toast that there are inline errors
        if (!isEqual(updatedApplicationErrors, this.clearedApplicationInfoErrors)) {
          notificationShow(account_edit.error_lpoa_account_validate, 'error');
        }
      });
  }

  componentDidMount() {
    this.props.allNotificationsHide();
    const guid = new URLSearchParams(window.location.search.toLowerCase()).get('key');
    this.setState({ guid });

    if (this.props.applicationInfo.lpoaFirmName === '' || guid.toUpperCase() !== this.props.applicationInfo.guid.toUpperCase()) {
      this.setState({ accountLoading: true });
      this.props.getLpoaAccount(guid)
        .then(() => {
          this.setState({
            accountLoading: false,
            applicationInfo: this.props.applicationInfo,
          });
          this.setAgeBased(this.props.applicationInfo);
        })
        .finally(() => {
          this.setState({
            accountLoading: false,
          });
        });
    }
    else {
      this.setAgeBased(this.props.applicationInfo);
    }
  }

  render() {
    const {
      accountLoading,
      validationLoading,
      applicationInfo,
      applicationInfoErrors,
      isAgeBased,
      selectedAgeBand,
    } = this.state;

    const {
      text: {
        LpoaAccount: {
          btn_reject,
          btn_next,
          btn_print,
          account_edit,
        },
      },
      renderNavigationButton,
      rejectLoading,
      showRejectModalHandler,
      documents,
    } = this.props;

    return (
      <Fragment>
        <div className={styles.container}>
          <div className={styles.title}>{account_edit.lpoa_review_title}</div>
          <LoadingOverlay show={accountLoading} width='100%'>
            <form autoComplete='off' onSubmit={this.onNextHandler}>
              <div className={styles.sectionsContainer}>
                <div className={styles.section}>
                  <div className={styles.sectionItem}>
                    <PersonInput
                      applicationInfoErrors={applicationInfoErrors}
                      applicationInfo={applicationInfo}
                      personType={PERSON_TYPES.AO}
                      title={account_edit.account_owner}
                      subtitle=''
                      onPersonInput={this.onPersonInput}
                    />
                  </div>
                  <div className={styles.sectionItem}>
                    <AddressInput
                      applicationInfoErrors={applicationInfoErrors}
                      applicationInfo={applicationInfo}
                      title={account_edit.mailing_adddress}
                      subtitle={account_edit.account_owner}
                      personType={PERSON_TYPES.AO}
                      addressType={ADDRESS_TYPES.MAILING}
                      onAddressInput={this.onAddressInput}
                      renderNavigationButton={renderNavigationButton}
                      copyAddress={this.copyAddress}
                    />
                  </div>
                  <div className={styles.sectionItem}>
                    <AddressInput
                      applicationInfoErrors={applicationInfoErrors}
                      applicationInfo={applicationInfo}
                      title={account_edit.physical_address}
                      subtitle={account_edit.account_owner}
                      personType={PERSON_TYPES.AO}
                      addressType={ADDRESS_TYPES.PHYSICAL}
                      onAddressInput={this.onAddressInput}
                      renderNavigationButton={renderNavigationButton}
                      copyAddress={this.copyAddress}
                    />
                  </div>
                  <div className={styles.sectionItem}>
                    <AoContactInput
                      applicationInfoErrors={applicationInfoErrors}
                      applicationInfo={applicationInfo}
                      title={account_edit.contact}
                      subtitle={account_edit.account_owner}
                      onAoContactInput={this.onAoContactInput}
                    />
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionItem}>
                    <PersonInput
                      applicationInfoErrors={applicationInfoErrors}
                      applicationInfo={applicationInfo}
                      personType={PERSON_TYPES.BENE}
                      title={account_edit.beneficiary}
                      subtitle=''
                      onPersonInput={this.onPersonInput}
                    />
                  </div>
                  <div className={styles.sectionItem}>
                    <AddressInput
                      applicationInfoErrors={applicationInfoErrors}
                      applicationInfo={applicationInfo}
                      title={account_edit.mailing_adddress}
                      subtitle={account_edit.beneficiary}
                      personType={PERSON_TYPES.BENE}
                      addressType={ADDRESS_TYPES.MAILING}
                      onAddressInput={this.onAddressInput}
                      renderNavigationButton={renderNavigationButton}
                      copyAddress={this.copyAddress}
                    />
                  </div>
                  <div className={styles.sectionItem}>
                    <AddressInput
                      applicationInfoErrors={applicationInfoErrors}
                      applicationInfo={applicationInfo}
                      title={account_edit.physical_address}
                      subtitle={account_edit.beneficiary}
                      personType={PERSON_TYPES.BENE}
                      addressType={ADDRESS_TYPES.PHYSICAL}
                      onAddressInput={this.onAddressInput}
                      renderNavigationButton={renderNavigationButton}
                      copyAddress={this.copyAddress}
                    />
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionItem}>
                    <PersonInput
                      applicationInfoErrors={applicationInfoErrors}
                      applicationInfo={applicationInfo}
                      personType={PERSON_TYPES.SUCCESSOR_PRIMARY}
                      title={account_edit.succesor}
                      subtitle={account_edit.primary}
                      onPersonInput={this.onPersonInput}
                    />
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionItem}>
                    <PersonInput
                      applicationInfoErrors={applicationInfoErrors}
                      applicationInfo={applicationInfo}
                      personType={PERSON_TYPES.SUCCESSOR_SECONDARY}
                      title={account_edit.succesor}
                      subtitle={account_edit.secondary}
                      onPersonInput={this.onPersonInput}
                    />
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionItem}>
                    <AccountSummary
                      applicationInfo={applicationInfo}
                      applicationInfoErrors={applicationInfoErrors}
                      title={account_edit.account_summary}
                      onAccountSummaryInput={this.onAccountSummaryInput}
                      renderNavigationButton={renderNavigationButton}
                    />
                  </div>
                </div>

                <div className={styles.section}>
                  <div className={styles.sectionItem}>
                    <FundAllocationsAndFees
                      applicationInfo={applicationInfo}
                      programDescriptionLink={documents.programDescription}
                      title1={account_edit.fund_allocations_title1}
                      title2={account_edit.fund_allocations_title2}
                      isAgeBased={isAgeBased}
                      selectedAgeBand={selectedAgeBand}
                      onAgeBandSelection={this.onAgeBandSelection}
                    />
                  </div>
                </div>
              </div>

              <div className={`${styles.aboveButtonsMessage} hideOnPrint`}>
                {account_edit.warning_text_above_buttons}
              </div>

              <div className={`${styles.navButtons} hideOnPrint`}>
                {renderNavigationButton(btn_reject, showRejectModalHandler, 'text', rejectLoading || accountLoading || validationLoading, rejectLoading)}
                {renderNavigationButton(btn_print, () => window.print(), 'text', rejectLoading || accountLoading || validationLoading, validationLoading)}
                {renderNavigationButton(btn_next, this.onNextHandler, 'contained', rejectLoading || accountLoading || validationLoading, validationLoading)}
              </div>
            </form>
          </LoadingOverlay>
        </div>
      </Fragment>
    );
  }
}

export default connect(select, {
  getLpoaAccount,
  saveToReduxLpoaAccount,
  validateLpoaAccount,
  notificationShow,
  allNotificationsHide,
})(LanguageHOC(AccountEdit));
