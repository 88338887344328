import { POSTrequest } from './api_handler';
import { store } from 'src/index';

export function persistClientErrors(error) {

  const state = store.getState();

  const minimizedState = {
    session: state.session,
    url: window.location.href,
    browser: {
      vendor: window.navigator.vendor,
      version: window.navigator.appVersion,
    }
  };

  const params = {
    StackTrace: JSON.stringify(error),
    StateData: JSON.stringify(minimizedState),
  };

  if (state.session.token || error.type) {
    POSTrequest('/error', params)
      .catch(() => {
        console.warn('Client Error:', params); //eslint-disable-line no-console
      });
  }
}