import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Modal,
} from '@frontend/common';

import { thirdParyLinkClick, } from 'utils/ga/events';

import styles from './styles.module.scss';

const onThirdPartyURLClickHandler = (onClose, eventLabel, thirdPartyURL) => {
  thirdParyLinkClick(eventLabel);
  onClose();
  window.open(thirdPartyURL, '_blank', 'noopener,noreferrer');
};

export function ThirdPartyWarningModal({
  open,
  onClose,
  thirdPartyURL,
  eventLabel,
  imageSrc,
  warningLanguage,
  footer,
  text: { Sidenav },
  isLoading,
  maxWidth
}) {
  return (
    <div>
      <Modal
        actionButtons={[
          {
            action: () => onThirdPartyURLClickHandler(onClose, eventLabel, thirdPartyURL),
            label: Sidenav.resources.btn_proceed,
            buttonType: 'contained'
          },
        ]}
        onCloseModal={onClose}
        show={open}
        title={Sidenav.resources.title_third_party_warning}
        maxWidth={maxWidth ? maxWidth : 'md'}
        includeLeftCancelBtn
        isLoading={isLoading}
      >
        {warningLanguage}
        {imageSrc && <div className={styles.image}><img src={imageSrc} alt='Screenshot' /></div>}
        {footer}
      </Modal>
    </div>
  );
}

ThirdPartyWarningModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  eventLabel: PropTypes.string.isRequired,
  thirdPartyURL: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  warningLanguage: PropTypes.string.isRequired,
  footer: PropTypes.string,
  text: PropTypes.shape({
    Sidenav: PropTypes.shape({
      btn_cancel: PropTypes.string,
      resources: PropTypes.shape({
        btn_proceed: PropTypes.string,
        title_third_party_warning: PropTypes.string,
        txt_third_party_warning_access_cards: PropTypes.string,
      }),
      text_timezone: PropTypes.string,
    }),
  }).isRequired,
  isLoading: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default LanguageHOC(ThirdPartyWarningModal);
