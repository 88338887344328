/*
*
* Lpoa Constants
*
*/
export const CHECK_AUTH_CODE = 'src/components/Features/protected/Lpoa/CHECK_AUTH_CODE';
export const GET_LPOA = 'src/components/Features/protected/Lpoa/GET_LPOA';
export const POST_LPOA = 'src/components/Features/protected/Lpoa/POST_LPOA';
export const DELETE_LPOA = 'src/components/Features/protected/Lpoa/DELETE_LPOA';

export const INVALID_AUTH_CODE = 'Code is invalid';
