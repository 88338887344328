/*
*
* AppTour Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getUserPreferences,
  saveUserPreferences,
} from 'components/AppRoot/Navigation/actions.js';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  MobileStepper,
  Slide,
} from '@mui/material';
import { withStyles, } from '@mui/styles';
import { Checkbox, sizify } from '@frontend/common';
import welcome from './WelcomeScreen.svg';
import { tabletWidth } from 'utils/config/_sassconfig.scss';
import { appTourStepper } from 'utils/ga/events';

import { mobileWidth } from 'utils/config/_sassconfig.scss';

import styles from './styles.module.scss';

const muiStyles = () => ({
  backdrop: {
    backgroundColor: 'transparent',
  },
  desktopPaperProps: {
    minHeight: '375px',
    minWidth: '625px',
  },
  dialog: {
    zIndex: '9999 !important',
  },
  iconButtonRoot: {
    color: '#000',
  },
  mobilePaperProps: {
    bottom: '0px',
    paddingBottom: '32px',
    margin: '0px',
    position: 'absolute',
    width: '100%',
  },
  stepperRoot: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-around',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction='up' ref={ref} {...props} />;
});

const select = state => ({
  tourPreference: state.session.userPreferences.length > 0 ? state.session.userPreferences.find(pref => pref.Identifier === 'ShowAATour') : {},
  claims: state.session.claims,
  isAccountListLoaded: state.accounts.isAccountListLoaded,
});

export class AppTour extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    getUserPreferences: PropTypes.func.isRequired,
    saveUserPreferences: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    text: PropTypes.shape({
      AppTour: PropTypes.shape({
        btn_back: PropTypes.string,
        btn_finish: PropTypes.string,
        btn_next: PropTypes.string,
        btn_skip: PropTypes.string,
        head_action_btn: PropTypes.string,
        head_more_info: PropTypes.string,
        head_nav: PropTypes.string,
        head_notifications: PropTypes.string,
        head_table_menu: PropTypes.string,
        head_table_search: PropTypes.string,
        head_transactions: PropTypes.string,
        head_welcome: PropTypes.string,
        text_dont_show: PropTypes.string,
        text_more_info: PropTypes.string,
        text_nav: PropTypes.string,
        text_notifications: PropTypes.string,
        text_table_menu: PropTypes.string,
        text_table_search: PropTypes.string,
        text_transactions1: PropTypes.string,
        text_transactions2: PropTypes.string,
        text_transactions3: PropTypes.string,
        text_transactions4: PropTypes.string,
        text_transactions: PropTypes.string,
        text_welcome2: PropTypes.string,
        text_welcome: PropTypes.string,
      }),
    }).isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number,
    }).isRequired,
    tourPreference: PropTypes.shape({ Value: PropTypes.bool }).isRequired,
    isAccountListLoaded: PropTypes.bool.isRequired,
    splashHasDisplayed: PropTypes.bool.isRequired,
  };

  state = {
    activeStep: 0,
    isSmallScreen: window.innerWidth <= parseInt(tabletWidth),
    loaded: false,
    neverShowAgain: false,
    showTour: false,
    steps: [
      this.props.text.AppTour.head_welcome,
      this.props.text.AppTour.head_nav,
      this.props.text.AppTour.head_notifications,
      this.props.text.AppTour.head_transactions,
      this.props.text.AppTour.head_table_search,
      this.props.text.AppTour.head_table_menu,
      this.props.text.AppTour.head_more_info,
    ],
  };

  backHandle = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  closeModalHandle = () => {
    this.setState({ showTour: false });

    if (this.state.activeStep === this.state.steps.length - 1) { // if user skips on last step
      appTourStepper('finish'); // Google Analytics url for goal tracking
    }

    if (this.state.neverShowAgain) {
      const updatedPreference = {
        ...this.props.tourPreference,
        Value: false,
      };

      this.props.saveUserPreferences(updatedPreference);
    }
  }

  dialogContentRender = () => {
    const { activeStep, isSmallScreen } = this.state;
    const { AppTour } = this.props.text;

    switch (activeStep) {
      case 0:
        return (
          <div className={styles.iconWithText}>
            <img src={welcome} className={styles.welcomeImage} alt={AppTour.head_welcome} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p>{AppTour.text_welcome}</p>
              <p>{AppTour.text_welcome2}</p>
            </div>
          </div>
        );
      case 1:
        return (
          <div className={styles.iconWithText}>
            {!isSmallScreen && <Icon>menu</Icon>}
            <p>{AppTour.text_nav}</p>
          </div>
        );
      case 2:
        return (
          <div className={styles.iconWithText}>
            {!isSmallScreen && <Icon>notifications</Icon>}
            <p>{AppTour.text_notifications}</p>
          </div>
        );
      case 3:
        return (
          <div>
            <div>{AppTour.text_transactions}</div>
            <div className={styles.iconWithText}>
              <div className={styles.transactionsIcon1}><Button color='primary'>{AppTour.text_transactions1}</Button></div>
              <p>{AppTour.text_transactions2}</p>
            </div>

            <div className={styles.iconWithText}>
              <div className={styles.transactionsIcon2}>
                <Button color='primary' style={{ display: 'flex', alignItems: 'center' }}>
                  {AppTour.head_transactions}
                  <Icon>keyboard_arrow_down</Icon>
                </Button>
              </div>
              <p>{AppTour.text_transactions3}</p>
            </div>
          </div>
        );
      case 4:
        return (
          <div className={styles.iconWithText}>
            <Icon>search</Icon>
            <p>{AppTour.text_table_search}</p>
          </div>
        );
      case 5:
        return (
          <div className={styles.iconWithText}>
            <Icon>more_vert</Icon>
            <p>{AppTour.text_table_menu}</p>
          </div>
        );
      case 6:
        return (
          <div className={styles.iconWithText}>
            <Icon>info</Icon>
            <p>{AppTour.text_more_info}</p>
          </div>
        );
      default: return;
    }
  }

  neverShowAgainHandle = () => {
    this.setState({ neverShowAgain: !this.state.neverShowAgain });
  }

  nextHandle = () => {
    if (this.state.activeStep === this.state.steps.length - 1) {
      appTourStepper('finish'); // Google Analytics url for goal tracking
      this.closeModalHandle();
    }
    else {
      appTourStepper(this.state.activeStep + 1); // Google Analytics url for goal tracking
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  }

  componentDidMount() {
    const { claims, tourPreference, size: { windowWidth } } = this.props;
    const isMobile = windowWidth < parseInt(mobileWidth);

    if (claims.AccountBlocked !== 'True' && Object.keys(tourPreference).length === 0) { // does not pop up tour if user is 2fa blocked at login
      this.props.getUserPreferences()
        .then(() => {
          // don't show a tour if mobile
          if (isMobile) {
            this.setState({ showTour: false });
          }
          else {
            this.setState({ showTour: this.props.tourPreference.Value });
          }
        });
    }
  }

  render() {
    const { activeStep, isSmallScreen, neverShowAgain, showTour, steps } = this.state;
    const { classes, text: { AppTour } } = this.props;

    return (
      <>
        {showTour && (
          <>
            <div className={styles.overlay}>
              {/* circle around navigation icon */}
              <div className={activeStep === 1 ? styles.navCircleLoaded : styles.navCircleHidden}><Icon>menu</Icon></div>

              {/* circle around notifications icon */}
              <div className={activeStep === 2 ? styles.notifCircleLoaded : styles.notifCircleHidden}><Icon>notifications</Icon></div>
            </div>

            <Dialog
              aria-labelledby={steps[activeStep]}
              BackdropProps={{ classes: { root: classes.backdrop } }}
              classes={{ root: classes.dialog }}
              open={true}
              PaperProps={{ classes: { root: isSmallScreen ? classes.mobilePaperProps : classes.desktopPaperProps } }}
              TransitionComponent={Transition}
              transitionDuration={{ enter: 500, exit: 500 }}
            >
              <DialogTitle className={styles.title}>{steps[activeStep]}</DialogTitle>

              <DialogContent className={styles.content}>
                {this.dialogContentRender()}
              </DialogContent>

              <DialogActions className={styles.bottomNav}>
                <div className={styles.stepperNav}>
                  <MobileStepper
                    activeStep={activeStep}
                    backButton={
                      <Button
                        color='primary'
                        variant='text'
                        onClick={this.backHandle}
                        disabled={activeStep === 0}
                      >
                        {activeStep !== 0 && <Icon>keyboard_arrow_left</Icon>}
                        {activeStep !== 0 && AppTour.btn_back}
                      </Button>
                    }
                    classes={{ root: classes.stepperRoot }}
                    nextButton={
                      <Button
                        color='primary'
                        onClick={this.nextHandle}
                      >
                        {activeStep === steps.length - 1 ? AppTour.btn_finish : AppTour.btn_next}
                        <Icon>keyboard_arrow_right</Icon>
                      </Button>
                    }
                    position='static'
                    steps={steps.length}
                    variant='dots'
                  />
                </div>

                <div className={styles.skipNav}>
                  <Checkbox
                    checked={neverShowAgain}
                    color='primary'
                    label={AppTour.text_dont_show}
                    onChange={this.neverShowAgainHandle}
                  />

                  <Button
                    color='primary'
                    onClick={this.closeModalHandle}
                    variant='text'
                  >
                    {AppTour.btn_skip}
                  </Button>
                </div>

              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    );
  }
}


export default withRouter(withStyles(muiStyles)(connect(select, {
  getUserPreferences,
  saveUserPreferences,
})(sizify(LanguageHOC(AppTour)))));