import { TWO_FACTOR_STATUS, RSA_STATUS } from 'components/AppRoot/Navigation/constants';
import { MULTIFACTOR_TYPE } from 'components/AppRoot/Navigation/constants';

// protected action
export const protectAction = claims => {
  
  if (claims.TwoFactorAuthentication === TWO_FACTOR_STATUS.COMPLETED
  || claims.TwoFactorAuthentication === TWO_FACTOR_STATUS.BYPASSED) {
    return MULTIFACTOR_TYPE.AUTH;
  }
  else {
    if (claims.TwoFactorRegistration === TWO_FACTOR_STATUS.COMPLETED) { // REGISTRATION CHECK
      if (claims.TwoFactorAuthentication === TWO_FACTOR_STATUS.UNAVAILABLE) {
        if (claims.RSAVerification === RSA_STATUS.BYPASSED
        || claims.RSAVerification === RSA_STATUS.COMPLETED) {
          return MULTIFACTOR_TYPE.AUTH;
        }
        else {
          return MULTIFACTOR_TYPE.RSA;
        }
      }
      else {
        return MULTIFACTOR_TYPE.TWO_FACTOR;
      }
    }
    else {
      if (claims.RSAVerification === RSA_STATUS.BYPASSED
      || claims.RSAVerification === RSA_STATUS.COMPLETED) {
        return MULTIFACTOR_TYPE.AUTH;
      }
      else {
        return MULTIFACTOR_TYPE.RSA;
      }
    }
  }
  
};

// Protect Withdrawals
export const protectWithdrawal = (transactionType, Transactions, preferredInfo, claims) => {
  
  if (transactionType !== ''
  && (transactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL.type
  || transactionType === Transactions.TRANSACTION_TYPES.WITHDRAWAL_REQUEST.type)) {
    
    if (preferredInfo.IsRegistered
    && claims.TwoFactorAuthentication === TWO_FACTOR_STATUS.PENDING) {
      return MULTIFACTOR_TYPE.TWO_FACTOR;
    }

    if (preferredInfo.IsRegistered
    && claims.TwoFactorAuthentication === TWO_FACTOR_STATUS.COMPLETED
    && claims.RSAVerification === RSA_STATUS.PENDING) {
      return MULTIFACTOR_TYPE.RSA;
    }

    if (claims.RSAVerification === RSA_STATUS.COMPLETED) {
      return MULTIFACTOR_TYPE.AUTH;
    }
    else { 
      if (preferredInfo.IsRegistered
      && (claims.TwoFactorAuthentication === TWO_FACTOR_STATUS.BYPASSED
      || claims.TwoFactorAuthentication === TWO_FACTOR_STATUS.UNAVAILABLE)) {
        if (claims.RSAVerification === RSA_STATUS.BYPASSED) {
          return MULTIFACTOR_TYPE.AUTH;
        }
        else {
          return MULTIFACTOR_TYPE.RSA;
        }
      }
      else if (!preferredInfo.IsRegistered
      && claims.RSAVerification === RSA_STATUS.PENDING) {
        return MULTIFACTOR_TYPE.RSA;
      }
      else {
        return MULTIFACTOR_TYPE.AUTH;
      }
    }

  }   

};