/*
*
* Google Analytics Events
*
*/

import { sendPageView, sendClickEvent, } from './helpers';

export function appTourStepper(step) {
  sendPageView(`/app-tour/step_${step}`);
}

export function signupStepper(step) {
  sendPageView(`/add-account/step_${step}`);
}

export function thirdParyLinkClick(eventLabel) {
  sendClickEvent('Third Party Link Clicks', eventLabel);
}

export function externalLinkClick(eventLabel) {
  sendClickEvent('External Link Clicks', eventLabel);
}