/*
*
* LoginIp Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Button,
  TextField
} from '@mui/material';
import {
  notificationShow,
  StyledLink,
  PasswordInput,
} from '@frontend/common';
import { activateIpAccount } from 'components/Features/protected/InterestedParties/actions.js';

import styles from './styles.module.scss';
import parentStyles from '../styles.module.scss';

export class LoginIp extends React.Component {

  static propTypes = {
    activateIpAccount: PropTypes.func.isRequired,
    csaPin: PropTypes.string, // cannot be required because the API returns it as null
    notificationShow: PropTypes.func.isRequired,
    redirectToLogin: PropTypes.func.isRequired,
    showCreateAccountForm: PropTypes.bool.isRequired,
    showLoginOnly: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      ForgotPassword: PropTypes.shape({ nav_path: PropTypes.string }),
      ForgotUsername: PropTypes.shape({ nav_path: PropTypes.string }),
      InterestedPartiesActivation: PropTypes.shape({
        err_required_field: PropTypes.string,
        head_already_have_account: PropTypes.string,
        head_login: PropTypes.string,
        lbl_password1: PropTypes.string,
        lbl_pin: PropTypes.string,
        lbl_username: PropTypes.string,
        msg_ip_activated: PropTypes.string,
      }),
      Login: PropTypes.shape({
        btn_login: PropTypes.string,
        nav_forgot_password: PropTypes.string,
        nav_forgot_username: PropTypes.string,
      })
    }).isRequired,
    toggleFormVisibility: PropTypes.func.isRequired,
  };

  state = {
    apiErrors: [],
    formErrors: {},
    loading: false,
    password1: '',
    pin: '',
    userName: '',
  };

  onInputChange = e => {
    this.setState({
      formErrors: {
        ...this.state.formErrors,
        [e.target.name]: '',
      },
      [e.target.name]: e.target.value,
    });
  }

  ipLogin = (e) => {
    e.preventDefault();
    
    const isValid = this.validateForm();
    const { password1, pin, userName } = this.state;
    
    if (isValid) {
      const guid = new URLSearchParams(window.location.search.toLowerCase()).get('request');

      const ipLoginInfo = {
        password1,
        pin,
        userName,
      };

      this.props.activateIpAccount(guid, ipLoginInfo)
        .then(() => {
          this.setState({ loading: false });
          this.props.redirectToLogin();
          this.props.notificationShow(this.props.text.InterestedPartiesActivation.msg_ip_activated, 'success');
        })
        .catch(res => {
          this.setState({
            apiErrors: res.payload.data,
            loading: false,
          }, () => this.validateForm());
        });
    }
  }

  validateForm = () => {
    const { apiErrors, password1, pin, userName } = this.state;
    const { InterestedPartiesActivation } = this.props.text;
    const formErrors = {};

    formErrors.userName = userName ? '' : InterestedPartiesActivation.err_required_field;
    formErrors.pin = pin ? '' : InterestedPartiesActivation.err_required_field;
    formErrors.password1 = password1 ? '' : InterestedPartiesActivation.err_required_field;

    if (apiErrors.length > 0) {
      apiErrors.map(err => formErrors[err.Field] = err.Message);
    }

    this.setState({
      apiErrors: [],
      formErrors
    });

    return Object.keys(formErrors).every(error => formErrors[error] === ''); // returns true if no errors
  }

  componentDidMount() {
    if (this.props.csaPin) {
      this.setState({ pin: this.props.csaPin });
    }
  }

  render() {
    const { formErrors, loading, password1, pin, userName, } = this.state;
    const { csaPin, showCreateAccountForm, showLoginOnly, text: { ForgotPassword, ForgotUsername, Login, InterestedPartiesActivation } } = this.props;

    return (
      <React.Fragment>
        <form
          className={parentStyles.loginForm}
          onSubmit={e => !showCreateAccountForm ? e.preventDefault() : this.LoginIp(e)} // prevents form from submitting before its visible
        >
          <h2>{showLoginOnly ? InterestedPartiesActivation.head_login : InterestedPartiesActivation.head_already_have_account}</h2>
          <div className={showCreateAccountForm ? parentStyles.hideFormContainer : parentStyles.showFormContainer}>
            <TextField
              autoComplete='username'
              disabled={showCreateAccountForm}
              error={Boolean(formErrors.userName)}
              fullWidth
              helperText={formErrors.userName}
              label={InterestedPartiesActivation.lbl_username}
              name='userName'
              onChange={this.onInputChange}
              value={userName}
              variant='filled'
            />

            <PasswordInput
              autoComplete='current-password'
              disabled={showCreateAccountForm}
              fullWidth
              errorText={formErrors.password1}
              label={InterestedPartiesActivation.lbl_password1}
              name='password1'
              onChange={this.onInputChange}
              value={password1}
              variant='filled'
            />

            <TextField
              disabled={showCreateAccountForm || Boolean(csaPin)}
              error={Boolean(formErrors.pin)}
              fullWidth
              helperText={formErrors.pin}
              label={InterestedPartiesActivation.lbl_pin}
              name='pin'
              onChange={this.onInputChange}
              value={csaPin ? csaPin : pin}
              variant='filled'
            />

            <div className={styles.linkContainer}>
              <StyledLink to={loading ? '' : ForgotPassword.nav_path}><small>{Login.nav_forgot_password}</small></StyledLink>
              <StyledLink to={loading ? '' : ForgotUsername.nav_path}><small>{Login.nav_forgot_username}</small></StyledLink>
            </div>
          </div>

          <div className={parentStyles.button}>
            <Button
              disabled={loading}
              fullWidth
              onClick={(e) => showCreateAccountForm ? this.props.toggleFormVisibility() : this.ipLogin(e)}
              type='submit'
              variant='contained'
            >
              {Login.btn_login}
            </Button>
          </div>

        </form>
      </React.Fragment>
    );
  }
}


export default connect(null, {
  activateIpAccount,
  notificationShow,
})(LanguageHOC(LoginIp));
