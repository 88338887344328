/*
*
* ReviewStep Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Button,
  Paper,
} from '@mui/material';
import { savePromoCode } from '../actions';
import Summary from '../Summary';

import styles from './styles.module.scss';

const select = (state) => ({
  PromotionalCode: state.signup.PromotionalCode,
});

export class ReviewStep extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    onBackClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    onStepClick: PropTypes.func.isRequired,
    PromotionalCode: PropTypes.string.isRequired,
    savePromoCode: PropTypes.func.isRequired,
    setLoadingOff: PropTypes.func.isRequired,
    setLoadingOn: PropTypes.func.isRequired,
    text: PropTypes.shape({
      ReviewStep: PropTypes.shape({
        btn_back: PropTypes.string,
        btn_continue: PropTypes.string,
        head_review: PropTypes.string,
        text_review: PropTypes.array,
      })
    }).isRequired,
  };

  state = {
    PromotionalCode: this.props.PromotionalCode,
    promoError: '',
  };

  onPromoCodeChange = e => {
    this.setState({
      PromotionalCode: e.target.value,
      promoError: '',
    });
  }

  saveAndContinue = () => {
    const { onNextClick, savePromoCode, setLoadingOff, setLoadingOn } = this.props;

    setLoadingOn();

    savePromoCode(this.state.PromotionalCode)
      .then(() => {
        onNextClick();
        setLoadingOff();
      })
      .catch(error => {
        this.setState({ promoError: error.payload.data[0].Message });
        setLoadingOff();
      });
  }

  render() {
    const { isLoading, onBackClick, onStepClick, text: { ReviewStep } } = this.props;

    return (
      <Paper>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h1>{ReviewStep.head_review}</h1>
            {ReviewStep.text_review.map(line => <p key={line}>{line}</p>)}
          </div>

          <Summary
            isDisabled={isLoading}
            onPromoCodeChange={this.onPromoCodeChange}
            onStepClick={onStepClick}
            promoError={this.state.promoError}
            PromotionalCode={this.state.PromotionalCode}
          />

          <div className={styles.stepperButtons}>
            <Button
              onClick={onBackClick}
              variant='text'
              color='primary'
            >
              {ReviewStep.btn_back}
            </Button>
            <Button
              color='primary'
              onClick={this.saveAndContinue}
              variant='contained'
            >
              {ReviewStep.btn_continue}
            </Button>
          </div>
        </div>
      </Paper>
    );
  }
}


export default connect(select, {
  savePromoCode,
})(LanguageHOC(ReviewStep));
