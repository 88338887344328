import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Drawer,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  Typography,
} from '@mui/material';
import { IconBtnTooltip } from '@frontend/common';

import styles from './styles.module.scss';

export function LearnMoreDrawer({ open, onClose, text: { AccessCardsLearnMore }, }) {

  const learnMore = () => (
    <div className={styles.learnMoreContainer}>
      <div className={styles.closeIcon}>
        <IconBtnTooltip
          icon='close'
          onClick={onClose}
        />
      </div>
      <hr className={styles.divider} />
      <h3 className={styles.learnMoreInfoHeader}>{AccessCardsLearnMore.title_learn_more}</h3>
      {AccessCardsLearnMore.text_faq.map((qa, qaIndex) => {
        return (
          <Accordion key={qaIndex}>
            <AccordionSummary
              expandIcon={<Icon>keyboard_arrow_down</Icon>}
              aria-controls='learn-more'
              id='learn-more'
            >
              <Typography>{qa.question}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column' }}>
              {Array.isArray(qa.answer)
                ? qa.answer.map((answer, index) => (
                  Array.isArray(answer)
                    ? qaIndex !== 18
                      ? <ul key={index}>{answer.map((subAnswer, index) => <li key={index}>{subAnswer}</li>)}</ul>
                      : <ol key={index} type='a' style={{ marginTop: 0 }}>{answer.map((subAnswer, index) => <li key={index}>{subAnswer}</li>)}</ol>
                    : <p key={index}>{answer}</p>
                ))
                : <Typography>{qa.answer}</Typography>}
            </AccordionDetails>
          </Accordion>
        );
      })}
      <p className={styles.learnMoreInfoText_footer}>{AccessCardsLearnMore.learn_more_footer}</p>
    </div>
  );

  return (
    <Drawer anchor='bottom' open={open} onClose={onClose}>
      {learnMore()}
    </Drawer>
  );
}

LearnMoreDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.shape({
    AccessCardsLearnMore: PropTypes.shape({
      title_learn_more: PropTypes.string,
      text_faq: PropTypes.array,
      learn_more_footer: PropTypes.string,
    }),
  }),
};

export default LanguageHOC(LearnMoreDrawer);