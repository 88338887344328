/*
*
* Step1Amount Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { verifyBirthdate } from '../../actions';
import {
  currencyFormatter,
  CurrencyInput,
  DatePicker,
  LoadingOverlay,
} from '@frontend/common';
import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';

import styles from './styles.module.scss';

const select = (state) => ({
  environmentVars: state.static.environmentVars,
  preTransactionDetails: state.transactions.preTransactionDetails,
});


export class Step1Amount extends React.Component {

  static propTypes = {
    environmentVars: PropTypes.shape({
      DisbursementSignatureGuaranteeAmount: PropTypes.number,
      MaximumAge: PropTypes.number,
      MinimumOnlineWithdrawalAmount: PropTypes.number,
    }).isRequired,
    nextClickHandle: PropTypes.func.isRequired,
    preTransactionDetails: PropTypes.shape({
      AccountId: PropTypes.number,
      AgentId: PropTypes.number,
      AllowFullWithdrawal: PropTypes.bool,
      BeneficiaryId: PropTypes.number,
      totalAvailableForWithdrawal: PropTypes.string,
      totalAvailableForWithdrawalNum: PropTypes.number,
      totalMarketValueNum: PropTypes.number,
      totalAvailableBalanceNumber: PropTypes.number,
    }).isRequired,
    renderCancelButton: PropTypes.func.isRequired,
    setWithdrawalInfo: PropTypes.func.isRequired,
    text: PropTypes.shape({
      WithdrawalRequests: PropTypes.shape({
        btn_next: PropTypes.string,
        err_max_amount: PropTypes.func.isRequired,
        err_min_amount: PropTypes.func.isRequired,
        err_required_field: PropTypes.string,
        err_selection_required: PropTypes.string,
        head_amount: PropTypes.string,
        lbl_birthdate: PropTypes.string,
        lbl_full_balance: PropTypes.string,
        lbl_partial_balance: PropTypes.string,
        text_3rd_party: PropTypes.string,
        text_full_balance_info: PropTypes.string,
      }),
      Withdrawals: PropTypes.shape({
        errors: PropTypes.shape({
          max_withdrawal: PropTypes.func,
        }),
        step0_amount_date: PropTypes.object,
      }),
    }).isRequired,
    verifyBirthdate: PropTypes.func.isRequired,
    withdrawalInfo: PropTypes.shape({
      Amount: PropTypes.string,
      BeneficiaryBirthdate: PropTypes.string,
      IsFullBalance: PropTypes.string,
    }).isRequired,
  };

  state = {
    Amount: this.props.withdrawalInfo.Amount,
    BeneficiaryBirthdate: this.props.withdrawalInfo.BeneficiaryBirthdate,
    IsFullBalance: this.props.withdrawalInfo.IsFullBalance,
    formErrors: {},
    isLoading: false,
    partialWithdrawalWarningShow: false,
  };

  amountHandle = value => {
    this.setState({
      Amount: value,
      formErrors: {
        ...this.state.formErrors,
        Amount: '',
      }
    }, () => {
      const { Amount, IsFullBalance, BeneficiaryBirthdate } = this.state;
      this.props.setWithdrawalInfo({
        Amount,
        BeneficiaryBirthdate: dayjs(BeneficiaryBirthdate).format('MM/DD/YYYY'),
        IsFullBalance,
      });
    });
  }

  amountTypeSet = e => {
    const IsFullBalance = e.target.value === 'true';

    this.setState({
      Amount: IsFullBalance ? this.props.preTransactionDetails.totalAvailableForWithdrawalNum : '',
      IsFullBalance: e.target.value,
      formErrors: {
        ...this.state.formErrors,
        IsFullBalance: '',
      }
    }, () => {
      const { Amount, IsFullBalance, BeneficiaryBirthdate } = this.state;
      this.props.setWithdrawalInfo({
        Amount,
        BeneficiaryBirthdate: dayjs(BeneficiaryBirthdate).format('MM/DD/YYYY'),
        IsFullBalance,
      });
    });
  }

  birthdateHandle = e => {
    this.setState({
      BeneficiaryBirthdate: e.target.value,
      formErrors: {
        ...this.state.formErrors,
        BeneficiaryBirthdate: '',
      }
    }, () => {
      const { Amount, IsFullBalance, BeneficiaryBirthdate } = this.state;
      this.props.setWithdrawalInfo({
        Amount,
        BeneficiaryBirthdate: dayjs(BeneficiaryBirthdate).format('MM/DD/YYYY'),
        IsFullBalance,
      });
    });
  }

  birthdateVerify = () => {
    const { AccountId, AgentId, BeneficiaryId } = this.props.preTransactionDetails;

    const authInfo = {
      AccountId,
      AgentId,
      BeneficiaryBirthdate: dayjs(this.state.BeneficiaryBirthdate).format('MM/DD/YYYY'),
      BeneficiaryId,
    };

    this.setState({ isLoading: true });

    this.props.verifyBirthdate(authInfo)
      .then(() => this.nextHandle())
      .catch(err => {
        this.setState({
          formErrors: {
            ...this.state.formErrors,
            BeneficiaryBirthdate: parse(err.payload.data[0].Message),
          },
          isLoading: false,
        });
      });
  }

  formValidate = e => {
    e.preventDefault();

    const { Amount, BeneficiaryBirthdate, IsFullBalance } = this.state;
    const { environmentVars: { DisbursementSignatureGuaranteeAmount, MinimumOnlineWithdrawalAmount }, preTransactionDetails: { totalAvailableForWithdrawal, totalAvailableForWithdrawalNum }, text: { WithdrawalRequests, Withdrawals } } = this.props;
    const formErrors = {};

    if (IsFullBalance === '') { // neither full balance or partial was selected
      formErrors.IsFullBalance = WithdrawalRequests.err_selection_required;
    }
    if (IsFullBalance === 'false') { // partial withdrawal errors
      if (!Amount) { // no amount has been entered
        formErrors.Amount = WithdrawalRequests.err_required_field;
      }
      if (parseInt(Amount) < MinimumOnlineWithdrawalAmount) { // amount is less than minimum
        formErrors.Amount = WithdrawalRequests.err_min_amount(currencyFormatter(MinimumOnlineWithdrawalAmount));
      }
      if (parseInt(Amount) > totalAvailableForWithdrawalNum) { // amount is greater than total available
        formErrors.Amount = WithdrawalRequests.err_max_amount(totalAvailableForWithdrawal);
      }
      if (parseInt(Amount) >= DisbursementSignatureGuaranteeAmount) { // amount is equal to or greater than $75k
        formErrors.Amount = Withdrawals.errors.max_withdrawal(DisbursementSignatureGuaranteeAmount);
      }
    }
    if (!BeneficiaryBirthdate) { // no birthdate was entered
      formErrors.BeneficiaryBirthdate = WithdrawalRequests.err_required_field;
    }
    if (Object.keys(formErrors).length === 0 || Object.values(formErrors).every(err => err === '')) { // if form is valid, check bday against API
      this.birthdateVerify();
    }

    this.setState({ formErrors });
  }

  nextHandle = () => {
    const { Amount, IsFullBalance, BeneficiaryBirthdate } = this.state;

    this.props.setWithdrawalInfo({
      Amount,
      BeneficiaryBirthdate: dayjs(BeneficiaryBirthdate).format('MM/DD/YYYY'),
      IsFullBalance,
    });
    this.props.nextClickHandle();
  }

  componentDidMount() {
    const { preTransactionDetails: { AllowFullWithdrawal }, withdrawalInfo: { Amount, BeneficiaryBirthdate, IsFullBalance } } = this.props;
    this.setState({
      Amount,
      BeneficiaryBirthdate,
      IsFullBalance: AllowFullWithdrawal ? IsFullBalance : 'false',
      partialWithdrawalWarningShow: !AllowFullWithdrawal,
    });
  }

  render() {
    const { Amount, BeneficiaryBirthdate, formErrors, IsFullBalance, isLoading, partialWithdrawalWarningShow } = this.state;
    const { environmentVars: { DisbursementSignatureGuaranteeAmount, MaximumAge }, preTransactionDetails, text: { WithdrawalRequests, Withdrawals } } = this.props;
    const availableBalanceIsMoreThanMax = preTransactionDetails.totalAvailableForWithdrawalNum > DisbursementSignatureGuaranteeAmount;
    const areThereUncollectedFunds = preTransactionDetails.totalMarketValueNum > preTransactionDetails.totalAvailableBalanceNumber;

    return (
      <form className={styles.step1Container} type='submit' onSubmit={this.formValidate}>
        {partialWithdrawalWarningShow ?
          <div className={styles.partialBalanceMessage}>
            <strong>{Withdrawals.step0_amount_date.partial_balance.title}</strong>
            {availableBalanceIsMoreThanMax ?
              <p>{Withdrawals.step0_amount_date.partial_balance.more_than_max_p_long}</p>
              :
              <p>{Withdrawals.step0_amount_date.partial_balance.more_than_max_p_short}</p>
            }
            <ul>
              <li>{Withdrawals.step0_amount_date.partial_balance.listItem1}</li>
              {areThereUncollectedFunds && <li>{Withdrawals.step0_amount_date.partial_balance.uncollected_funds_text}</li>}
              {availableBalanceIsMoreThanMax && <li>{parse(Withdrawals.step0_amount_date.partial_balance.html_listItem2_more_than_max(currencyFormatter(DisbursementSignatureGuaranteeAmount)))}</li>}
            </ul>
            <Button
              onClick={() => this.setState({ partialWithdrawalWarningShow: false })}
              style={{ marginTop: '12px' }}
              variant='text'
              color='primary'
            >
              {Withdrawals.step0_amount_date.continue_btn_label}
            </Button>
          </div>
          :
          <React.Fragment>
            <FormControl error={Boolean(formErrors.IsFullBalance)}>
              <RadioGroup aria-label={WithdrawalRequests.head_amount} value={IsFullBalance} onChange={this.amountTypeSet} className={styles.radioContainer}>
                {preTransactionDetails.AllowFullWithdrawal && (
                  <>
                    <FormControlLabel
                      value='true'
                      control={<Radio color='primary' />}
                      label={WithdrawalRequests.lbl_full_balance}
                    />
                    <FormControlLabel
                      value='false'
                      control={<Radio color='primary' />}
                      label={WithdrawalRequests.lbl_partial_balance}
                    />
                    {formErrors.IsFullBalance && <FormHelperText>{formErrors.IsFullBalance}</FormHelperText>}
                  </>
                )}
              </RadioGroup>
            </FormControl>

            <Collapse in={IsFullBalance === 'false'} className={styles.amountAccordion}>
              <CurrencyInput
                error={Boolean(formErrors.Amount)}
                fullWidth
                helperText={formErrors.Amount}
                label={WithdrawalRequests.head_amount}
                onChange={({ value }) => this.setState({ Amount: value, formErrors: { ...this.state.formErrors, Amount: '' } })}
                style={{ alignSelf: 'start', maxWidth: '300px' }}
                value={Amount}
                variant='filled'
              />
            </Collapse>

            <DatePicker
              error={Boolean(formErrors.BeneficiaryBirthdate)}
              fullWidth
              helperText={formErrors.BeneficiaryBirthdate}
              variant='filled'
              label={WithdrawalRequests.lbl_birthdate}
              maxDate={dayjs().subtract(18, 'year')} // CSA benes have to be at least 18
              minDate={dayjs().subtract(MaximumAge, 'year')}
              onChange={e => this.birthdateHandle({ target: { name: 'BeneficiaryBirthdate', value: e ? e.toDate() : e } })}
              openTo='year'
              sx={{ marginTop: '15px', maxWidth: '300px' }}
              value={BeneficiaryBirthdate}
            />

            <div className={styles.stepperButtons}>
              {this.props.renderCancelButton()}
              <LoadingOverlay show={isLoading}>
                <Button
                  color='primary'
                  onClick={this.formValidate}
                  variant='contained'
                  fullWidth
                  type='submit'
                >
                  {WithdrawalRequests.btn_next}
                </Button>
              </LoadingOverlay>
            </div>
          </React.Fragment>
        }
      </form>
    );
  }
}


export default connect(select, {
  verifyBirthdate,
})(LanguageHOC(Step1Amount));
