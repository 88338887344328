/*
*
* Signup Constants
*
*/
import { BLANK_ADDRESS } from 'components/AppRoot/StaticResources/constants';

export const CREATE_ACCOUNT = 'src/components/Features/protected/Signup/CREATE_ACCOUNT';
export const DELETE_APPLICATION = 'src/components/Features/protected/Signup/DELETE_APPLICATION';
export const GET_EXISTING_BENEFICIARIES = 'src/components/Features/protected/Signup/GET_EXISTING_BENEFICIARIES';
export const GET_EXISTING_BENEFICIARY = 'src/components/Features/protected/Signup/GET_EXISTING_BENEFICIARY';
export const GET_SIGNUP_APPLICATION = 'src/components/Features/protected/Signup/GET_SIGNUP_APPLICATION';
export const RESET_ACCOUNT_APPLICATION = 'src/components/Features/protected/Signup/RESET_ACCOUNT_APPLICATION';
export const SAVE_ACCOUNT_OWNER = 'src/components/Features/protected/Signup/SAVE_ACCOUNT_OWNER';
export const SAVE_BENEFICIARY = 'src/components/Features/protected/Signup/SAVE_BENEFICIARY';
export const SAVE_CUSTOMIZED_OPTION = 'src/components/Features/protected/Signup/SAVE_CUSTOMIZED_OPTION';
export const SAVE_PREDEFINED_INVESTMENT_OPTION = 'src/components/Features/protected/Signup/SAVE_PREDEFINED_INVESTMENT_OPTION';
export const SAVE_PROMO_CODE = 'src/components/Features/protected/Signup/SAVE_PROMO_CODE';

export const BLANK_BENE_INFO = {
  BeneficiaryBirthdate: '',
  BeneficiaryEmail: '',
  BeneficiaryFirstName: '',
  BeneficiaryId: 0,
  BeneficiaryIsResident: false,
  BeneficiaryLastName: '',
  BeneficiaryMailingAddressSameAsOwner: false,
  BeneficiaryMiddleName: '',
  BeneficiaryPhysicalAddressSameAsOwner: false,
  BeneficiarySSN1: '',
  BeneficiarySSN2: '',
  Mailing: BLANK_ADDRESS,
  Physical: BLANK_ADDRESS,
  RelationshipToAccountOwner: '',
  encryptedDataExists: false,
};