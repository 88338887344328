/*
*
* AccountDetailsCard Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Card,
  InfoIcon,
} from '@frontend/common';

import styles from './styles.module.scss';

AccountDetailsCard.propTypes = {
  sourceAccount: PropTypes.object,
  text: PropTypes.shape({
    Transfers: PropTypes.shape({
      head_account_number: PropTypes.string,
      head_account_owner: PropTypes.string,
      head_account_type: PropTypes.string,
      head_available_balance: PropTypes.string,
      head_bene: PropTypes.string,
      head_total_value: PropTypes.string,
      msg_balance_info: PropTypes.func,
    })
  })
};

AccountDetailsCard.defaultProps = {
  sourceAccount: {
    agent: {
      name: ''
    },
    beneficiary: {
      name: ''
    },
    accountNumber: '',
    totalValue: '',
    accountType: '',
    // netAvailable: 0, this field does not seem to be available in the data
    
  }
};

export function AccountDetailsCard({ sourceAccount, text: { Transfers } }) {
  return (
    <div className={styles.container}>
      <Card
        className={styles.accountDetailsCard}
        title='Account Details'
      >
        <div className={styles.accountDetails}>
          <div className={styles.accountDetailsAO}>
            <div className={styles.detailsTitle}>{Transfers.head_account_owner}</div>
            {sourceAccount.agent.name}
          </div>
          <div className={styles.accountDetailsBen}>
            <div className={styles.detailsTitle}>{Transfers.head_bene}</div>
            {sourceAccount.beneficiary.name}
          </div>
          <div className={styles.accountDetailsAcctNum}>
            <div className={styles.detailsTitle}>{Transfers.head_account_number}</div>
            {sourceAccount.accountNumber}
          </div>
          <div className={styles.accountDetailsTotalVal}>
            <div className={styles.detailsTitle}>{Transfers.head_total_value}</div>
            {sourceAccount.totalValue}
          </div>
          <div className={styles.accountDetailsAcctType}>
            <div className={styles.detailsTitle}>{Transfers.head_account_type}</div>
            {sourceAccount.accountType}
          </div>
          <div className={styles.accountDetailsAvailableBalance}>
            <div className={styles.detailsTitle}>
              {Transfers.head_available_balance}
              <InfoIcon
                message={Transfers.msg_balance_info()}
              />
            </div>
            {sourceAccount.netAvailable}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default LanguageHOC(AccountDetailsCard);
