/*
*
* StaticResources Constants
*
*/
export const ENVIRONMENT_VARIABLES_GET = 'src/components/AppRoot/StaticResources/ENVIRONMENT_VARIABLES_GET';
export const PASSWORD_REQUIREMENTS_GET = 'src/components/AppRoot/StaticResources/PASSWORD_REQUIREMENTS_GET';
export const STATIC_DOCUMENTS_GET = 'src/components/AppRoot/StaticResources/STATIC_DOCUMENTS_GET';
export const TMX_ENVIRONMENT_VARIABLES_GET = 'src/components/AppRoot/StaticResources/TMX_ENVIRONMENT_VARIABLES_GET';
export const SERVER_DATETIME_GET = 'src/components/AppRoot/StaticResources/SERVER_DATETIME_GET';
export const WEB_MESSAGES_GET = 'src/components/AppRoot/StaticResources/WEB_MESSAGES_GET';
export const SET_LOADING_PUBLIC_VARS = 'src/components/AppRoot/StaticResources/SET_LOADING_PUBLIC_VARS';
export const BLANK_ADDRESS = {
  AddressId: '',
  City: '',
  Country: '',
  CountryName: '',
  PostalCode: '',
  State: '',
  StreetAddress1: '',
  StreetAddress2: '',
  StreetAddress3: '',
};
// hardcoded banners - the value has to match exactly `${URL}/${Page}` from WebsiteMessage api 
// add/remove when needed
export const BANNER_LOCATIONS = {
  LOGIN: '/login/Login Page',
  HOME: '/home/Home Page',
  CONTRIBUTIONS_NEW: '/accounts/{accountid}/transactions/contributions/new/Contribution (New)',
  CONTRIBUTIONS_EDIT: '/accounts/{accountid}/transactions/contributions/edit/Contribution (Edit)',
  OPTION_CHANGES_NEW: '/accounts/{accountid}/transactions/option-changes/new/Option Change (New)',
  OPTION_CHANGES_EDIT: '/accounts/{accountid}/transactions/option-changes/edit/Option Change (Edit)',
  TRANSFERS_NEW: '/accounts/{accountid}/transactions/transfers/new/Transfer (New)',
  TRANSFERS_EDIT: '/accounts/{accountid}/transactions/transfers/edit/Transfer (Edit)',
  WITHDRAWALS_NEW: '/accounts/{accountid}/transactions/withdrawals/groups/{accountgroupid}/new/Withdrawal (New)',
  WITHDRAWALS_EDIT: '/accounts/{accountid}/transactions/withdrawals/groups/{accountgroupid}/edit/Withdrawal (Edit)',
  ADD_ACCOUNT_STEP3: '/add-account/Simple Sign-up Page - Step 3',
  DOCUMENTS: '/documents/Documents',
};
