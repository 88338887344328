import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  changeStatementDeliveryMethods,
} from '../actions';
import { getNotifications, } from 'components/AppRoot/Navigation/actions';

import {
  notificationShow,
  CardButtons,
  LoadingOverlay,
} from '@frontend/common';

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import {
  CHANGE_DELIVERY_METHODS,
  ONLINE_ONLY,
  MAIL_ONLINE,
  MAIL,
} from '../constants';

import styles from './styles.module.scss';

const select = (state) => ({
  DeliveryMethods: state.myInfo.DeliveryMethods,
});

export class DeliveryMethodsComp extends React.Component {
  static propTypes = {
    changeStatementDeliveryMethods: PropTypes.func.isRequired,
    displayUnsavedChangesPrompt: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    DeliveryMethods: PropTypes.arrayOf(
      PropTypes.shape({
        AccountId: PropTypes.number,
        DeliveryMethod: PropTypes.string,
        Name: PropTypes.string,
      })
    ).isRequired,
    text: PropTypes.shape({
      Preferences: PropTypes.shape({
        btn_change_delivery_prefs: PropTypes.string,
        btn_close: PropTypes.string,
        btn_view_change_statement_delivery: PropTypes.string,
        head_account: PropTypes.string,
        head_beneficiary: PropTypes.string,
        head_delivery_method: PropTypes.string,
        head_title_change_statement_delivery_prefs: PropTypes.string,
        msg_statement_delivery_changed: PropTypes.string,
      })
    }).isRequired,
  };

  state = {
    disableChangeButton: true,
    isSaving: false,
    DeliveryMethods: this.props.DeliveryMethods,
  };

  onStatementDeliveryPrefChange = (event, accountId) => {
    const modifiedDeliveryMethods = this.state.DeliveryMethods.map(account => {
      if (account.AccountId === accountId) {
        return {
          ...account,
          DeliveryMethod: event.target.value
        };
      }
      else
        return account;
    });

    let disableChangeButton = true;
    if (!isEqual(modifiedDeliveryMethods, this.props.DeliveryMethods))
      disableChangeButton = false;

    this.setState({
      disableChangeButton,
      DeliveryMethods: modifiedDeliveryMethods,
    });
  }

  statementDeliveryPrefChange = () => {
    const preferences = {
      DeliveryMethods: this.state.DeliveryMethods,
    };

    this.setState({ isSaving: true });
    this.props.changeStatementDeliveryMethods(preferences)
      .then(() => {
        this.setState({
          isSaving: false,
          DeliveryMethods: this.props.DeliveryMethods,
          statementDeliveryMethodPrefsModalOpen: false,
          disableChangeButton: true,
        });
        this.props.notificationShow(this.props.text.Preferences.msg_statement_delivery_changed, 'success');
        this.props.getNotifications(); // refreshes app notifications to remove the Change Delivery Method notification
      })
      .catch(() => this.setState({
        isSaving: false,
      }));
  }

  renderTableDataRows = () => {
    const { DeliveryMethods } = this.state;
    return DeliveryMethods.map((method) => (
      <tr key={method.AccountId}>
        <td>{method.AccountNumber}<br />{method.BeneficiaryName}</td>
        <td>
          <RadioGroup
            aria-label={this.props.text.Preferences.head_title_change_statement_delivery_prefs}
            name='statementDeliveryPreference'
            value={method.DeliveryMethod}
            onChange={(event) => this.onStatementDeliveryPrefChange(event, method.AccountId)}
          >
            <FormControlLabel value={ONLINE_ONLY} label={ONLINE_ONLY} control={<Radio />} />
            <FormControlLabel value={MAIL} label={MAIL_ONLINE} control={<Radio />} />
          </RadioGroup>
        </td>
      </tr>
    ));
  }

  componentDidUpdate() {
    const { DeliveryMethods } = this.state;
    if (Boolean(DeliveryMethods) && !isEqual(DeliveryMethods, this.props.DeliveryMethods)) {
      this.props.displayUnsavedChangesPrompt(CHANGE_DELIVERY_METHODS, true);
    }
    else {
      this.props.displayUnsavedChangesPrompt(CHANGE_DELIVERY_METHODS, false);
    }
  }

  render() {
    const { isSaving, disableChangeButton } = this.state;
    const { text: { Preferences } } = this.props;

    return (
      <div className={styles.preferencesContainer}>
        <table>
          <tbody>
            <tr>
              <th>{Preferences.head_account} {Preferences.head_beneficiary}</th>
              <th>{Preferences.head_delivery_method}</th>
            </tr>
            {this.renderTableDataRows()}
          </tbody>
        </table>

        <CardButtons>
          <LoadingOverlay show={isSaving} key='changeButton'>
            <Button
              disabled={isSaving || disableChangeButton}
              key='updateStatementDeliveryPrefs'
              onClick={this.statementDeliveryPrefChange}
              type='submit'
              variant='contained'
            >
              {Preferences.btn_change_delivery_prefs}
            </Button>
          </LoadingOverlay>
        </CardButtons>
      </div>
    );
  }
}


export default connect(select, {
  changeStatementDeliveryMethods,
  notificationShow,
  getNotifications,
})(LanguageHOC(DeliveryMethodsComp));
