/*
*
* OptionChanges Actions
*
*/
import {
  GETrequest,
  POSTrequest,
} from 'utils/helpers/api_handler';

import {
  GET_PREDEFINED_OPTIONS,
  POST_NEW_OPTION,
  GET_NEW_AGE_BASED_SCHEMA,
  GET_NEW_STATIC_SCHEMA,
  GET_OPTION_CHANGE_BY_ID,
} from './constants';

import { convert3DCalculatorSchemaTo2D } from '@frontend/common';

export function getPredefinedOptions() {
  const payload = GETrequest('/options');
  return {
    type: GET_PREDEFINED_OPTIONS,
    payload,
  };
}

export function postNewOptions(data) {
  const updatedData = data.map(optionChange => {
    const { matrixAllocations, CustomSchema } = optionChange;
    const isCustomizedOptionChange = CustomSchema && CustomSchema.FundCategories && CustomSchema.FundCategories.length > 0;
    const updatedCustomSchema = isCustomizedOptionChange ? convert3DCalculatorSchemaTo2D(matrixAllocations, CustomSchema) : CustomSchema;
    delete optionChange.matrixAllocations;
    return { ...optionChange, CustomSchema: updatedCustomSchema };
  });

  const response = POSTrequest('/transactions/optionchanges', updatedData);
  return {
    type: POST_NEW_OPTION,
    payload: response
  };
}

export function getOptionChangeById(optionChangeId, actionId) {
  const payload = GETrequest(`/transactions/optionchanges/${optionChangeId}/actions/${actionId}`);
  return {
    type: GET_OPTION_CHANGE_BY_ID,
    payload
  };
}

export function getNewAgeBasedSchema() {
  const response = GETrequest('/options/new?typeCode=a');
  return {
    type: GET_NEW_AGE_BASED_SCHEMA,
    payload: response
  };
}

export function getNewStaticSchema() {
  const response = GETrequest('/options/new?typeCode=s');
  return {
    type: GET_NEW_STATIC_SCHEMA,
    payload: response
  };
}