import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  Drawer,
} from '@mui/material';
import { IconBtnTooltip } from '@frontend/common';

import styles from './styles.module.scss';


LearnMore.propTypes = {
  environmentVars: PropTypes.shape({
    DisbursementSignatureGuaranteeAmount: PropTypes.number,
    DisbursementSignatureGuaranteeRollingDays: PropTypes.number,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.shape({
    BankAccountsLearnMore: PropTypes.shape({ 
      head_title: PropTypes.string, 
      text_info_1: PropTypes.string,
      text_info_2: PropTypes.string,
      text_info_3: PropTypes.string,
      text_info_4: PropTypes.array,
      text_info_5: PropTypes.string,
      text_info_6: PropTypes.func,
      text_info_7: PropTypes.string,
      text_info_8: PropTypes.string,
    })
  }).isRequired,
};

export function LearnMore({ environmentVars, open, onClose, text: { BankAccountsLearnMore }, }) {


  const learnMoreInfo = () => (
    <div className={styles.learnMoreInfoContainer}>
      <div className={styles.closeIcon}>
        <IconBtnTooltip
          icon='close'
          onClick={onClose}
        />
      </div>
      <hr className={styles.divider} />
      <h3 className={styles.learnMoreInfoHeader}>{BankAccountsLearnMore.head_title}</h3>
      <div className={styles.learnMoreInfo}>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <p>{BankAccountsLearnMore.text_info_1}</p>
        <p>{BankAccountsLearnMore.text_info_2}</p> {/* TODO: 'bank acounts' has a hover explaination */}
        <p>{BankAccountsLearnMore.text_info_3}</p> {/* TODO: link for form 300. Should it come from backend? */}
        <ul>
          {BankAccountsLearnMore.text_info_4.map(line => <li key={line}>{line}</li>)}
        </ul>

        <p>{BankAccountsLearnMore.text_info_5}</p>
        <ul>
          {BankAccountsLearnMore.text_info_6(environmentVars.DisbursementSignatureGuaranteeAmount, environmentVars.DisbursementSignatureGuaranteeRollingDays).map(line => <li key={line}>{line}</li>)}
        </ul>

        <p>{BankAccountsLearnMore.text_info_7}</p>
        <p>{BankAccountsLearnMore.text_info_8}</p>
      </div>
    </div>
  );

  return (
    <Drawer anchor='bottom' open={open} onClose={onClose}>
      {learnMoreInfo()}
    </Drawer>
  );
}

export default LanguageHOC(LearnMore);