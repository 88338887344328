/*
*
* PhoneNumbersCard Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep, isEqual } from 'lodash';
import LanguageHOC from 'utils/translations/LanguageHOC';
import {
  CHANGE_PHONE_NUMBERS,
} from '../constants';

import {
  CardButtons,
  notificationShow,
  PhoneNumberInput,
  CardTitle,
} from '@frontend/common';

import {
  Button,
} from '@mui/material';

import { changePhoneNumbers } from '../actions';

import styles from './styles.module.scss';

const OTHER = 'OTHER';

export class PhoneNumbersCard extends React.Component {
  static propTypes = {
    changePhoneNumbers: PropTypes.func.isRequired,
    displayUnsavedChangesPrompt: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    phoneNumbers: PropTypes.shape({
      Cell: PropTypes.object,
      Fax: PropTypes.object,
      Home: PropTypes.object,
      Other: PropTypes.object,
      Work: PropTypes.object,
    }),
    hasTitle: PropTypes.bool,
    text: PropTypes.shape({
      PhoneNumbersCard: PropTypes.shape({
        btn_change_phone: PropTypes.string,
        head_title: PropTypes.string,
        lbl_cell: PropTypes.string,
        lbl_fax: PropTypes.string,
        lbl_home: PropTypes.string,
        lbl_other: PropTypes.string,
        lbl_work: PropTypes.string,
        msg_phone_numbers_updated: PropTypes.string,
        msg_phone_number_too_short: PropTypes.string,
        msg_warning_changing_phone: PropTypes.string,
      })
    }).isRequired,
  };

  static defaultProps = {
    hasTitle: true,
  };

  state = {
    isSaving: false,
    phoneNumbers: this.props.phoneNumbers,
    phoneErrors: {},
  };

  mapApiErrorsToFormErrors = apiErrors => {
    // we don't have any inline error handling so if any api errors with a field just show toasts
    if (apiErrors && apiErrors.length > 0) {
      apiErrors.forEach((apiError) => apiError.Field && this.props.notificationShow(apiError.Message, 'error'));
    }
  };

  getPhoneNumberError = (phoneNumber) => {
    const phoneMatch = phoneNumber.Number && phoneNumber.Number.match(/\d/g);

    if (phoneMatch && phoneMatch.length !== 10) {
      return this.props.text.PhoneNumbersCard.msg_phone_number_too_short;
    }
    return '';
  }

  verifyPhoneNumbers = () => {
    // validates only on submit and blur
    const phoneErrors = {};
    const { phoneNumbers } = this.state;

    phoneErrors.Cell = this.getPhoneNumberError(phoneNumbers.Cell);
    phoneErrors.Home = this.getPhoneNumberError(phoneNumbers.Home);
    phoneErrors.Work = this.getPhoneNumberError(phoneNumbers.Work);
    phoneErrors.Other = this.getPhoneNumberError(phoneNumbers.Other);
    phoneErrors.Fax = this.getPhoneNumberError(phoneNumbers.Fax);

    this.setState({ phoneErrors });

    return Object.values(phoneErrors).filter(err => err !== '').length === 0;
  }

  phoneNumbersChange = (e) => {
    e.preventDefault();

    if (this.verifyPhoneNumbers()) {
      this.setState({ isSaving: true });
      const formattedPhoneNumbers = Object.keys(this.state.phoneNumbers).map(num => ({ Number: this.state.phoneNumbers[num].Number, Type: this.state.phoneNumbers[num].Type }));

      this.props.changePhoneNumbers(formattedPhoneNumbers)
        .then(() => {
          this.setState({
            isSaving: false,
            phoneNumbers: this.props.phoneNumbers
          });
          this.props.notificationShow(this.props.text.PhoneNumbersCard.msg_phone_numbers_updated, 'success');
        })
        .catch(response => this.setState({
          isSaving: false
        }, () => this.mapApiErrorsToFormErrors(response.payload.data)));
    }
  }

  updateButtonIsDisabled = () => {
    const { isSaving, phoneNumbers, phoneErrors } = this.state;
    const strippedNumbersOnState = Object.keys(phoneNumbers).map(num => phoneNumbers[num].Number.replace(/\D/g, ''));
    const strippedNumbersOnProps = Object.values(this.props.phoneNumbers).map(num => num.Number.replace(/\D/g, ''));
    const errorsNotCleared = Object.values(phoneErrors).filter(err => err !== '').length !== 0;
    return isSaving
      || isEqual(strippedNumbersOnState, strippedNumbersOnProps)
      || errorsNotCleared;
  }

  onInputChange = (name, value) => {
    // update specific phone number
    const phoneNumbers = cloneDeep(this.state.phoneNumbers);
    
    phoneNumbers[name] = {
      ...phoneNumbers[name],
      MaskType: 'US',
      Number: value,
    };

    // clear specific input error when user starts typing
    const phoneErrors = { ...this.state.phoneErrors };
    phoneErrors[name] = '';
    this.setState({ phoneNumbers, phoneErrors });
  }

  componentDidUpdate() {
    const strippedNumbersOnState = Object.keys(this.state.phoneNumbers).map(num => this.state.phoneNumbers[num].Number.replace(/\D/g, ''));
    const strippedNumbersOnProps = Object.values(this.props.phoneNumbers).map(num => num.Number.replace(/\D/g, ''));
    if (!isEqual(strippedNumbersOnState, strippedNumbersOnProps)) {
      this.props.displayUnsavedChangesPrompt(CHANGE_PHONE_NUMBERS, true);
    }
    else {
      this.props.displayUnsavedChangesPrompt(CHANGE_PHONE_NUMBERS, false);
    }
  }

  render() {
    const { phoneNumbers: { Cell, Home, Fax, Work, Other }, phoneErrors } = this.state;

    const { text: { PhoneNumbersCard }, hasTitle } = this.props;

    return (
      <React.Fragment>
        <form onSubmit={this.phoneNumbersChange}>
          <div>
            {hasTitle && <CardTitle>{PhoneNumbersCard.head_title}</CardTitle>}
            <div>
              <div className={styles.warning}>{PhoneNumbersCard.msg_warning_changing_phone}</div>
              <PhoneNumberInput
                label={PhoneNumbersCard.lbl_cell}
                name='Cell'
                onChange={({ formattedValue }) => this.onInputChange('Cell', formattedValue)}
                onBlur={this.verifyPhoneNumbers}
                value={Cell.Number}
                fullWidth
                errorText={phoneErrors.Cell}
                isintl={Cell.MaskType === OTHER ? 'true' : 'false'}
              />

              <PhoneNumberInput
                label={PhoneNumbersCard.lbl_home}
                name='Home'
                onChange={({ formattedValue }) => this.onInputChange('Home', formattedValue)}
                onBlur={this.verifyPhoneNumbers}
                value={Home.Number}
                fullWidth
                errorText={phoneErrors.Home}
                isintl={Home.MaskType === OTHER ? 'true' : 'false'}
              />

              <PhoneNumberInput
                label={PhoneNumbersCard.lbl_work}
                name='Work'
                onChange={({ formattedValue }) => this.onInputChange('Work', formattedValue)}
                onBlur={this.verifyPhoneNumbers}
                value={Work.Number}
                fullWidth
                errorText={phoneErrors.Work}
                isintl={Work.MaskType === OTHER ? 'true' : 'false'}
              />
              <PhoneNumberInput
                label={PhoneNumbersCard.lbl_other}
                name='Other'
                onChange={({ formattedValue }) => this.onInputChange('Other', formattedValue)}
                onBlur={this.verifyPhoneNumbers}
                value={Other.Number}
                fullWidth
                errorText={phoneErrors.Other}
                isintl={Other.MaskType === OTHER ? 'true' : 'false'}
              />
              <PhoneNumberInput
                label={PhoneNumbersCard.lbl_fax}
                name='Fax'
                onChange={({ formattedValue }) => this.onInputChange('Fax', formattedValue)}
                onBlur={this.verifyPhoneNumbers}
                value={Fax.Number}
                fullWidth
                errorText={phoneErrors.Fax}
                isintl={Fax.MaskType === OTHER ? 'true' : 'false'}
              />
            </div>
          </div>
          <CardButtons>
            <Button
              disabled={this.updateButtonIsDisabled()}
              key='updatePhoneNumbersButton'
              onClick={this.phoneNumbersChange}
              type='submit'
              variant='contained'
            >
              {PhoneNumbersCard.btn_change_phone}
            </Button>
          </CardButtons>
        </form>
      </React.Fragment>
    );
  }
}

export default connect(null, { changePhoneNumbers, notificationShow })(LanguageHOC(PhoneNumbersCard));
