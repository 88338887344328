/*
*
* MyInfo Actions
*
*/
import { DELETErequest, GETrequest, POSTrequest, PUTrequest } from 'utils/helpers/api_handler';
import {
  AGENT_GET,
  CHANGE_ADDRESS,
  GET_DELIVERY_METHODS,
  CHANGE_DELIVERY_METHODS,
  CHANGE_EMAIL,
  CHANGE_PHONE_NUMBERS,
  DELETE_ADDRESS,
  PASSWORD_CHANGE,

  OFFICIAL_NOTIFICATION,
  TAXFORMS_NOTIFICATION,
  NEWSLETTER_NOTIFICATION,
  PROMO_NOTIFICATION,
  BDAY_NOTIFICATION,
  UTAH_RESIDENT_CHANGE,
} from './constants';

export function agentGet() {
  const payload = GETrequest('/agents');

  return {
    type: AGENT_GET,
    payload,
  };
}

export function changeAddress(address) {
  const payload = PUTrequest('/agents/Address', address);

  return {
    type: CHANGE_ADDRESS,
    payload
  };
}

export function changeEmail(email) {
  const payload = PUTrequest('/agents/email', {
    email
  });

  return {
    type: CHANGE_EMAIL,
    payload,
  };
}

export function changePhoneNumbers(phoneNumbers) {
  const payload = PUTrequest('/agents/phones', phoneNumbers);

  return {
    type: CHANGE_PHONE_NUMBERS,
    payload
  };
}

export function deleteAddress(addressId, addressType) {
  const payload = DELETErequest(`/agents/Address/${addressId}`);

  return {
    type: DELETE_ADDRESS,
    payload,
    meta: { addressType }
  };
}

export function passwordChange(oldpassword, newpassword1, newpassword2) {
  const payload = POSTrequest('/authentication/changepassword', {
    oldpassword,
    newpassword1,
    newpassword2
  });

  return {
    type: PASSWORD_CHANGE,
    payload,
  };
}

export function getStatementDeliveryMethods() {
  const payload = GETrequest('/agents/StatementDelivery');

  return {
    type: GET_DELIVERY_METHODS,
    payload,
  };
}

export function changeStatementDeliveryMethods(deliveryMethods) {
  const payload = PUTrequest('/agents/StatementDelivery', deliveryMethods);

  return {
    type: CHANGE_DELIVERY_METHODS,
    payload,
  };
}

export function officialNotificationUpdate(Value) {
  const payload = PUTrequest('/agents/OfficialCommunications', { Value });
  return {
    type: OFFICIAL_NOTIFICATION,
    payload,
  };
}

export function taxFormsNotificationUpdate(Value) {
  const payload = PUTrequest('/agents/TaxForms', { Value });

  return {
    type: TAXFORMS_NOTIFICATION,
    payload,
  };
}

export function newsletterNotificationUpdate(Value) {
  const payload = PUTrequest('/agents/Newsletter', { Value });

  return {
    type: NEWSLETTER_NOTIFICATION,
    payload,
  };
}

export function promoNotificationUpdate(Value) {
  const payload = PUTrequest('/agents/Promotional', { Value });

  return {
    type: PROMO_NOTIFICATION,
    payload,
  };
}

export function bdayNotificationUpdate(Value) {
  const payload = PUTrequest('/agents/BirthdayNotifications', { Value });

  return {
    type: BDAY_NOTIFICATION,
    payload,
  };
}

export function utahResidentSave(utahResident) {
  const payload = PUTrequest('/agents/UtahResident', { IsOn: utahResident });
  return {
    type: UTAH_RESIDENT_CHANGE,
    payload,
  };
}
