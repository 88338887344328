/*
*
* Documents Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';

import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import {
  Paper,
} from '@mui/material';

import {
  LoadingOverlay,
  notificationShow,
  InfoIcon,
  createStatementPdfToNewWindow,
  createCertificatePdfToNewWindow,
  loadingAnimationHTML,
  loadingErrorHTML,
  SmartTable,
  TableContainer,
  TableHeader,
  TablePagination,
  TableRows,
  TableToolbar,
  TAXFORM1099_COPY_PATHS,
  TAXFORMTC675_PATHS,
  TCFORM_ACCOUNT_TYPE_CODES,
  get1099QPdfBytes,
  getTC675HPdfBytes,
  mapPdfDataFrom1099QData,
  mapPdfDataFromTC675HData
} from '@frontend/common';

import {
  documentsGet,
  downloadAccountDocumentData,
  updateAccountCertificateName,
  getCountries
} from 'components/Features/protected/Documents/actions';

import { getNotifications, } from 'components/AppRoot/Navigation/actions';

import CertificateEditModal from './CertificateEditModal';

import styles from './styles.module.scss';

const blankSelectedCertificate = {
  CertificateCustomName: '',
  Url: '',
  Format: '',
  AccountId: '',
};

const select = (state) => ({
  documents: state.documents.documents,
  DocumentTypes: state.static.environmentVars.DocumentTypes,
  webMessages: state.static.webMessages,
  countries: state.documents.countries,
});

export class Documents extends React.Component {

  static propTypes = {
    webMessages: PropTypes.object.isRequired,
    documents: PropTypes.array.isRequired,
    DocumentTypes: PropTypes.shape({
      AccountCertificate: PropTypes.string,
      QuarterlyStatement: PropTypes.string,
      FederalTaxForm: PropTypes.string,
      UtahStateTaxForm: PropTypes.string,
    }).isRequired,
    documentsGet: PropTypes.func.isRequired,
    downloadAccountDocumentData: PropTypes.func.isRequired,
    updateAccountCertificateName: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    text: PropTypes.shape({
      Documents: PropTypes.shape({
        btn_open_pdf: PropTypes.string,
        msg_certificate_updated: PropTypes.string,
        msg_interested_party: PropTypes.string,
        nav_path: PropTypes.string,
        text_no_documents: PropTypes.string,
        title_account_number: PropTypes.string,
        title_account_type: PropTypes.string,
        title_bene_name: PropTypes.string,
        title_description: PropTypes.string,
        title_document_type: PropTypes.string,
        title_download: PropTypes.string,
        title_edit_certificate: PropTypes.string,
        title_quarter: PropTypes.string,
        title_view_additional: PropTypes.string,
        title_view_document: PropTypes.string,
        title_year: PropTypes.string,
      }),
    }),
    getCountries: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
  };

  state = {
    isLoading: false,
    isDownloading: false,
    openCertificateEdit: false,
    selectedCertificate: blankSelectedCertificate,
    hasInterestedPartyAccounts: false,
    containerEl: document.createElement('div'),
  }

  closeEditCertificateModalHandler = () => {
    this.setState({ openCertificateEdit: false, selectedCertificate: blankSelectedCertificate });
  }

  getDocuments = () => {
    this.setState({ isLoading: true });
    this.props.documentsGet()
      .then(() => {
        const hasInterestedPartyAccounts = this.props.documents.some(document => document.IsInterestedPartyDoc);
        this.setState({
          isLoading: false,
          hasInterestedPartyAccounts,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  certificateEditHandler(url, documentType, certificateFromName, accountId) {
    if (certificateFromName && accountId) {
      this.props.updateAccountCertificateName(certificateFromName, accountId)
        .then(() => {
          this.getDocuments(this.state.selectedCertificate.AccountNumber);
          this.props.notificationShow('Account Certificate Updated.', 'success');
          this.closeEditCertificateModalHandler();
          this.openPdfStatement(url, documentType);
        });
    }
  }

  openPdfStatement = (url, documentType, pdf1099Path) => {
    const { DocumentTypes, text, downloadAccountDocumentData, countries } = this.props;
    // const isTaxDoc = documentType === DocumentTypes.FederalTaxForm || documentType === DocumentTypes.UtahStateTaxForm;
    const statementWindow = window.open('', '_blank'); // needs to be outside of async call to work in Safari
    statementWindow.document.write(loadingAnimationHTML); // this will get replaced by generated pdf once the api loads all data
    let apiCall;
    switch (documentType) {
      // generate statement
      case DocumentTypes.QuarterlyStatement:
        apiCall = downloadAccountDocumentData(url)
          .then(response => createStatementPdfToNewWindow(response.payload.data, statementWindow, text.Documents.title_view_document));
        break;

      // generate account certificate
      case DocumentTypes.AccountCertificate:
        apiCall = downloadAccountDocumentData(url)
          .then(response => createCertificatePdfToNewWindow(response.payload.data, statementWindow));
        break;

      // load tax docs
      case DocumentTypes.FederalTaxForm:
        apiCall = downloadAccountDocumentData(url)
          .then(response => {
            const pdfData = mapPdfDataFrom1099QData(response.payload.data, countries);
            try {
              return get1099QPdfBytes(pdfData, pdf1099Path)
                .then(pdfBytes => {
                  const file = new Blob([pdfBytes], { type: 'application/pdf' });
                  const fileURL = URL.createObjectURL(file);
                  statementWindow.location = fileURL; // appeases Safari where window.open is outside of async call
                });
            }
            catch (err) {
              return err;
            }
          });
        break;

      case DocumentTypes.UtahStateTaxForm:
        apiCall = downloadAccountDocumentData(url)
          .then(response => {
            try {
              const data = response.payload.data;
              const pdfData = mapPdfDataFromTC675HData(data, this.props.countries);
              let pdfTC675HPath; // this path needs to be decided after data are loaded
              switch (data.AccountType) {
                case TCFORM_ACCOUNT_TYPE_CODES.INDIVIDUAL:
                  pdfTC675HPath = TAXFORMTC675_PATHS.INDIVIDUAL_INSTRUCTIONS;
                  break;
                case TCFORM_ACCOUNT_TYPE_CODES.INSTITUTIONAL:
                  pdfTC675HPath = TAXFORMTC675_PATHS.INSTITUTIONAL_INSTRUCTIONS;
                  break;
                case TCFORM_ACCOUNT_TYPE_CODES.UGMAUTMA:
                  pdfTC675HPath = TAXFORMTC675_PATHS.UGMAUTMA_INSTRUCTIONS;
                  break;
                default: // do nothing
              }

              return getTC675HPdfBytes(pdfData, pdfTC675HPath)
                .then(pdfBytes => {
                  const file = new Blob([pdfBytes], { type: 'application/pdf' });
                  const fileURL = URL.createObjectURL(file);
                  statementWindow.location = fileURL; // appeases Safari where window.open is outside of async call
                });
            }
            catch (err) {
              return err;
            }
          });
        break;

      default:
        this.setState({ downloadLoading: false });
        this.props.notificationShow('Document type does not exist.', 'error');
    }

    // shared promise catch and finally
    apiCall && apiCall
      .catch(() => {
        // show error in a new tab by replacing its html content
        statementWindow.document.close(); // clear first
        statementWindow.document.write(loadingErrorHTML);
      })
      .finally(() => {
        this.setState({ downloadLoading: false });
      });
  }

  componentDidMount() {
    if (this.props.countries.length === 0) {
      this.props.getCountries();
    }

    if (this.props.documents.length === 0) {
      this.getDocuments();
    }
    else {
      const hasInterestedPartyAccounts = this.props.documents.some(document => document.IsInterestedPartyDoc);
      this.setState({
        hasInterestedPartyAccounts,
      });
    }
  }

  render() {
    const { documents, DocumentTypes, webMessages, text: { Documents } } = this.props;
    const { hasInterestedPartyAccounts, isDownloading, isLoading, openCertificateEdit, selectedCertificate } = this.state;

    const columns = [
      {
        key: 'DocumentType',
        title: Documents.title_document_type,
        type: 'string',
      },
      {
        key: 'BeneficiaryName',
        title: Documents.title_bene_name,
        type: 'string',
      },
      {
        key: 'AccountNumber',
        title: Documents.title_account_number,
        type: 'number',
        hideOn: ['phone', 'tablet'],
      },
      {
        key: 'AccountType',
        title: Documents.title_account_type,
        type: 'string',
        hideOn: ['phone', 'tablet'],
      },
      {
        key: 'Year',
        title: Documents.title_year,
        type: 'number',
        customStyle: { width: '50px' }
      },
      {
        key: 'Quarter',
        title: Documents.title_quarter,
        type: 'number',
        customStyle: { width: '55px' },
      },
      {
        key: 'Description',
        title: Documents.title_description,
        type: 'string',
        hideOn: ['phone'],
        canFilter: false,
      },
    ];
    // only if the account owner has IP accounts, then add in the interested party chip identifier
    if (hasInterestedPartyAccounts) {
      columns.push({
        key: 'IsInterestedPartyDoc',
        title: '',
        type: 'string',
        canFilter: false,
        customStyle: { width: '35px', padding: '5px' },
        format: IsInterestedPartyDoc => IsInterestedPartyDoc ? <InfoIcon message={this.props.text.Documents.msg_interested_party} /> : null,
      });
    }

    return (
      <div className={styles.documentsContainer}>
        <Banner show={Boolean(webMessages[BANNER_LOCATIONS.DOCUMENTS])} body={webMessages[BANNER_LOCATIONS.DOCUMENTS]} />
        <LoadingOverlay
          show={isDownloading}
          indicatorHeight='15px'
          width='100%'
        >
          <Paper elevation={1}>
            <SmartTable
              idKey='Id'
              rows={documents}
              emptyMessage={Documents.text_no_documents}
              loading={isLoading}
              columns={columns}
              actions={[
                {
                  displayName: Documents.title_download,
                  type: 'icon',
                  icon: 'picture_as_pdf',
                  iconTitle: Documents.btn_open_pdf,
                  onSelect: row => this.openPdfStatement(row.Url, row.DocumentType, TAXFORM1099_COPY_PATHS.COPY_B_MAIL_INSTRUCTIONS),
                },
                {
                  displayName: Documents.title_view_additional,
                  type: 'menu',
                  onSelect: row => this.openPdfStatement(row.Supplement.Url, row.DocumentType),
                  showIf: row => row.Supplement,
                },
                {
                  displayName: Documents.title_edit_certificate,
                  type: 'menu',
                  onSelect: row => this.setState({ openCertificateEdit: true, selectedCertificate: row }),
                  showIf: row => row.DocumentType === DocumentTypes.AccountCertificate,
                },
              ]}
            >
              <TableToolbar canFilter />
              <TableContainer minWidth='100%'>
                <TableHeader />
                <TableRows />
              </TableContainer>
              <TablePagination />
            </SmartTable>
          </Paper>
        </LoadingOverlay>

        <CertificateEditModal
          key={selectedCertificate.Id}
          open={openCertificateEdit}
          certificateCustomName={selectedCertificate.CertificateCustomName}
          onClose={this.closeEditCertificateModalHandler}
          saveNameAndOpenDoc={(certificateFromName) => this.certificateEditHandler(
            selectedCertificate.Url,
            selectedCertificate.DocumentType,
            certificateFromName,
            selectedCertificate.AccountId
          )}
        />
      </div >
    );
  }
}


export default withRouter(connect(select, {
  documentsGet,
  downloadAccountDocumentData,
  updateAccountCertificateName,
  notificationShow,
  getNotifications,
  getCountries,
})(LanguageHOC(Documents)));
