/*
*
* AddressInput Component
*
*/
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  TextField,
} from '@mui/material';

import {
  StateDropdown,
  ZipInput,
} from '@frontend/common';

import { PERSON_TYPES } from '../../constants';

import styles from '../styles.module.scss';

const select = (state) => ({
  US_STATES: state.static.usStates,
});

export class AddressInput extends Component {
  static propTypes = {
    US_STATES: PropTypes.array.isRequired,
    applicationInfoErrors: PropTypes.object.isRequired,
    applicationInfo: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    personType: PropTypes.string.isRequired,
    addressType: PropTypes.string.isRequired,
    onAddressInput: PropTypes.func.isRequired,
    renderNavigationButton: PropTypes.func.isRequired,
    copyAddress: PropTypes.func.isRequired,
  };

  render() {
    const {
      US_STATES,
      applicationInfoErrors,
      applicationInfo,
      title,
      subtitle,
      personType,
      addressType,
      onAddressInput,
      renderNavigationButton,
      copyAddress,
    } = this.props;

    const copyButtonLabel = personType === PERSON_TYPES.AO ? 'copy >> Beneficiary' : 'copy >> Account Owner';

    return (
      <Fragment>
        <div className={styles.sectionTitle}>{title}</div>
        {subtitle ? <div className={styles.sectionSubTitle}>{subtitle}</div> : <div className={styles.sectionSubTitle}><div className={styles.emptySubtitle} /></div>}
        <TextField
          autoComplete='off'
          error={Boolean(applicationInfoErrors[personType][addressType].street1)}
          variant='filled'
          fullWidth
          helperText={applicationInfoErrors[personType][addressType].street1}
          label={'Street Address 1'}
          onChange={(e) => onAddressInput(e.target.value, personType, addressType, 'street1')}
          value={applicationInfo[personType][addressType].street1}
        />
        <TextField
          autoComplete='off'
          error={Boolean(applicationInfoErrors[personType][addressType].street2)}
          variant='filled'
          fullWidth
          helperText={applicationInfoErrors[personType][addressType].street2}
          label={'Street Address 2'}
          onChange={(e) => onAddressInput(e.target.value, personType, addressType, 'street2')}
          value={applicationInfo[personType][addressType].street2}
        />
        <TextField
          autoComplete='off'
          error={Boolean(applicationInfoErrors[personType][addressType].city)}
          variant='filled'
          fullWidth
          helperText={applicationInfoErrors[personType][addressType].city}
          label={'City'}
          onChange={(e) => onAddressInput(e.target.value, personType, addressType, 'city')}
          value={applicationInfo[personType][addressType].city}
        />
        <StateDropdown
          autoComplete='off'
          errorText={applicationInfoErrors[personType][addressType].state}
          fullWidth
          FormControlProps={{ variant: 'filled' }}
          key='State'
          onChange={value => onAddressInput(value, personType, addressType, 'state')}
          states={US_STATES}
          value={applicationInfo[personType][addressType].state}
        />
        <ZipInput
          autoComplete='off'
          errorText={applicationInfoErrors[personType][addressType].zipCode}
          variant='filled'
          onChange={value => onAddressInput(value.formattedValue.trim(), personType, addressType, 'zipCode')}
          label='Postal Code'
          value={applicationInfo[personType][addressType].zipCode}
        />
        <div className={styles.copyAddress}>
          {renderNavigationButton(copyButtonLabel, () => copyAddress(personType, addressType), 'text')}
        </div>


      </Fragment>
    );
  }
}

export default connect(select, {})(LanguageHOC(AddressInput));