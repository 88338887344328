/*
*
* FirstTermsAndConditions Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from '@mui/material';

import styles from './styles.module.scss';

export class FirstTermsAndConditions extends React.Component {
  static propTypes = {
    scrollToTop: PropTypes.func.isRequired,
    renderNavigationButton: PropTypes.func.isRequired,
    onBackHandler: PropTypes.func.isRequired,
    onNextHandler: PropTypes.func.isRequired,
    showRejectModalHandler: PropTypes.func.isRequired,
    text: PropTypes.object.isRequired,
    rejectLoading: PropTypes.bool.isRequired,
  };

  state = {
    termsChecked: false,
    termsCheckedError: '',
  }

  onTermsCheckboxClicked = () => {
    this.setState({
      termsCheckedError: '',
      termsChecked: !this.state.termsChecked,
    });
  }

  onNextHandler = () => {
    if (this.state.termsChecked)
      this.props.onNextHandler();
    else
      this.setState({ termsCheckedError: 'Please agree to terms.' });
  }

  componentDidMount() {
    this.props.scrollToTop();
  }

  render() {
    const { termsChecked, termsCheckedError } = this.state;

    const {
      text: { 
        LpoaAccount: {
          first_terms_and_conditions,
          btn_back,
          btn_reject,
          btn_next,
          btn_print,
        } 
      },
      renderNavigationButton,
      onBackHandler,
      showRejectModalHandler,
      rejectLoading,
    } = this.props;

    return (
      <div className={styles.container}>
        <h2>{first_terms_and_conditions.title}</h2>
        <div className={styles.content}>
          {first_terms_and_conditions.content()}
        </div>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              inputProps={{ 'aria-label': 'Accept Terms' }}
              onClick={this.onTermsCheckboxClicked}
              checked={termsChecked}
            />
          }
          label={'Accept Terms'}
        />
        {termsCheckedError && <FormHelperText error={Boolean(termsCheckedError)} style={{ textAlign: 'center' }}>{termsCheckedError}</FormHelperText>}
        <div className={styles.navButtons}>
          {renderNavigationButton(btn_back, onBackHandler, 'text', rejectLoading)}
          {renderNavigationButton(btn_reject, showRejectModalHandler, 'text', rejectLoading, rejectLoading)}
          {renderNavigationButton(btn_print, () => window.print(), 'text', rejectLoading)}
          {renderNavigationButton(btn_next, this.onNextHandler, 'contained', rejectLoading)}
        </div>
      </div>
    );
  }
}

export default LanguageHOC(FirstTermsAndConditions);