/*
*
* Accounts Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route,
  Switch,
  withRouter
} from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { getAccounts, getAccountsSummary } from './actions';
import AccountDetails from 'components/Features/protected/Accounts/AccountDetails';
import AccountsSummary from 'components/Features/protected/Accounts/AccountsSummary';
import {
  Tabs,
  Tab,
} from '@mui/material';
import {
  LoadingOverlay,
  sizify,
} from '@frontend/common';

import { mobileWidth } from 'utils/config/_sassconfig.scss';
import styles from './styles.module.scss';

const select = (state) => ({
  accountList: state.accounts.accountList.map(account => ({
    accountId: account.accountId,
    accountGroupId: account.accountGroupId,
    beneficiaryName: account.beneficiary.name,
    totalValue: account.totalValue,
    accountNumber: account.accountNumber,
    accountType: account.accountType,
    optionName: account.option.name,
    programName: account.programName,
    csaWizardUrl: account.csaWizardUrl,
  })),
});

export class Accounts extends React.Component {

  static propTypes = {
    accountList: PropTypes.arrayOf(
      PropTypes.shape({
        accountId: PropTypes.number,
        accountGroupId: PropTypes.number,
        beneficiaryName: PropTypes.string,
        totalValue: PropTypes.string,
        accountNumber: PropTypes.number,
        accountType: PropTypes.string,
        optionName: PropTypes.string,
        programName: PropTypes.string,
        csaWizardUrl: PropTypes.string,
      })
    ).isRequired,
    getAccounts: PropTypes.func.isRequired,
    getAccountsSummary: PropTypes.func.isRequired,
    size: PropTypes.shape({
      windowWidth: PropTypes.number,
    }).isRequired,
    text: PropTypes.shape({
      Accounts: PropTypes.shape({
        head_title: PropTypes.string,
        nav_path: PropTypes.string,
      }),
      AccountDetails: PropTypes.shape({ nav_path: PropTypes.func }),
    }).isRequired,
  };

  state = {
    isTileView: false,
    loading: false,
    accountList: [],
  };

  componentDidMount() {
    if (this.props.accountList.length === 0) {
      this.setState({ loading: true });
      this.props.getAccounts()
        .then(() => this.props.getAccountsSummary())
        .finally(() => this.setState({
          loading: false,
          isTileView: this.props.size.windowWidth < parseInt(mobileWidth),
        }));
    }
    else {
      this.props.getAccountsSummary();
      this.setState({
        isTileView: this.props.size.windowWidth < parseInt(mobileWidth),
      });
    }
  }

  componentDidUpdate({ size }) {
    const { size: { windowWidth } } = this.props;
    if (size.windowWidth !== windowWidth) {
      this.setState({
        isTileView: windowWidth < parseInt(mobileWidth),
      });
    }
  }

  render() {
    const { accountList, history, size, text } = this.props;
    const { isTileView } = this.state;

    return (
      <LoadingOverlay show={this.state.loading} width='100%'>
        <div className={styles.accountsContainer}>
          <Route
            path={text.Accounts.nav_path}
            render={({ location }) => {

              return (
                <div className={styles.tabsContainer}>
                  <Tabs
                    className={styles.accountSelector}
                    indicatorColor='primary'
                    textColor='primary'
                    value={location.pathname}
                    variant='scrollable'
                  >
                    <Tab
                      label={
                        <div className={location.pathname === text.Accounts.nav_path ? styles.selected : styles.accountLinks}>
                          <div>{text.Accounts.head_title}</div>
                        </div>
                      }
                      to={text.Accounts.nav_path}
                      value={text.Accounts.nav_path}
                      onClick={() => history.push(text.Accounts.nav_path)}
                    />
                    {accountList.length > 0 && accountList.map(account => (
                      <Tab
                        data-auto-id={account.accountId}
                        key={account.accountId}
                        label={
                          <div className={location.pathname === text.AccountDetails.nav_path(account.accountId) ? styles.selected : styles.accountLinks}>
                            <div>{account.beneficiaryName}</div>
                            <div>{`(...${account.accountNumber.toString().substring(4)})`}</div>
                          </div>
                        }
                        to={text.AccountDetails.nav_path(account.accountId)} // <Link /> component defined above will push to this route, which gets defined in Switch below
                        value={text.AccountDetails.nav_path(account.accountId)}
                        onClick={() => history.push(text.AccountDetails.nav_path(account.accountId))}
                      />
                    ))}
                  </Tabs>

                  <Switch> {/* Dynamically creates a route for each tab (aka account) */}
                    <Route
                      exact
                      path={text.Accounts.nav_path}
                      render={props => (
                        <AccountsSummary
                          isTileView={isTileView}
                          size={size}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={text.AccountDetails.nav_path(':accountId')}
                      render={props => <AccountDetails accountList={accountList} accountsGet={this.accountsGet} {...props} />}
                    />
                  </Switch>
                </div>
              );
            }}
          />
        </div>
      </LoadingOverlay>
    );
  }
}

export default withRouter(connect(select, { getAccounts, getAccountsSummary })(sizify(LanguageHOC(Accounts))));
