import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import QRCode from 'react-qr-code';

import {
  Button,
  TextField
} from '@mui/material';

import {
  notificationShow,
  LoadingOverlay,
} from '@frontend/common';

import {
  getInitialTwoFactorQRCode,
  getTwoFactorQRCode,
  twoFactorTokenVerification,
  getPreferredMethod,
  savePreferredMethod
} from '../../actions';

import { userLogout } from 'components/AppRoot/Navigation/actions';
import { regMethodTypes } from '../../constants.js';
import { TWO_FACTOR_TYPES } from 'components/AppRoot/Navigation/constants';

import styles from './styles.module.scss';

const select = (state) => ({
  QRCodeInfo: state.multifactor.QRCodeInfo,
  VerificationInfo: state.multifactor.VerificationInfo,
  preferredInfo: state.multifactor.preferredInfo,
  is2FARegistered: state.session.is2FARegistered,
});

export class RegisterAuthenticator extends Component {

  static propTypes = {
    hideAnotherMethodButton: PropTypes.bool, // defaults to always show
    getInitialTwoFactorQRCode: PropTypes.func.isRequired,
    getTwoFactorQRCode: PropTypes.func.isRequired,
    twoFactorTokenVerification: PropTypes.func.isRequired,
    getPreferredMethod: PropTypes.func.isRequired,
    savePreferredMethod: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    QRCodeInfo: PropTypes.object.isRequired,
    VerificationInfo: PropTypes.object.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    registerType: PropTypes.string,
    closeWindow: PropTypes.func,
    is2FARegistered: PropTypes.bool.isRequired,
    text: PropTypes.shape({
      Multifactor: PropTypes.shape({
        btn_another_method: PropTypes.string,
        btn_cancel: PropTypes.string,
        btn_learn_more: PropTypes.string,
        btn_submit_token: PropTypes.string,
        confirmation_path: PropTypes.string,
        lbl_invalid_code: PropTypes.string,
        err_invalid_token: PropTypes.string,
        err_token_required: PropTypes.string,
        lbl_my529_token: PropTypes.string,
        lbl_step1: PropTypes.string,
        lbl_step2: PropTypes.string,
        lbl_step3: PropTypes.string,
        msg_token_attempts_remaining: PropTypes.string,
        msg_download_authenticator: PropTypes.string,
        msg_get_codes: PropTypes.string,
        msg_scan_qrcode: PropTypes.string,
        msg_enter_seccode: PropTypes.string,
        register_path: PropTypes.string,
        learn_more_url: PropTypes.string,
      }),
      Login: PropTypes.shape({
        msg_you_have_logged_out: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    isLoading: false,
    hasSecurityCode: false,
    securityCode: '',
    securityCodeError: '',
    attemptWarning: '',
  };

  logout = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .then(() => {
        this.props.notificationShow(this.props.text.Login.msg_you_have_logged_out, 'success');
      });
  }

  handleInput = name => e => {
    if (this.state.securityCode) {
      this.setState({
        securityCode: false,
        securityCodeError: '',
        [name]: e.target.value
      });
    }
    else {
      this.setState({ [name]: e.target.value });
    }
  }

  verifyCode = (e) => {
    e.preventDefault();
    const { text: { Multifactor } } = this.props;
    const { securityCode } = this.state;
    if (securityCode) {
      this.setState({ isLoading: true });
      Promise.all([
        this.props.twoFactorTokenVerification(securityCode, regMethodTypes.INAPPTOKEN),
        this.props.getPreferredMethod()
      ]).then(() => {
        const { VerificationInfo, preferredInfo } = this.props;
        if (VerificationInfo.Verified) {
          const params = {
            AlwaysRequired: preferredInfo.AlwaysRequired,
            PreferredMethod: regMethodTypes.INAPPTOKEN
          };
          this.props.savePreferredMethod(params)
            .then(() => {
              this.props.history.push(Multifactor.confirmation_path);
            });
        }
        else {
          if (VerificationInfo.AttemptsRemaining === 0) {
            this.logout();
          }
          else if (VerificationInfo.AttemptsRemaining === 1) {
            this.setState({
              isLoading: false,
              attemptWarning: Multifactor.msg_token_attempts_remaining
            });
          }
          else {
            this.setState({
              isLoading: false,
              securityCodeError: Multifactor.err_invalid_token
            });
          }
        }
      });
    }
    else {
      this.setState({
        hasSecurityCode: true,
        securityCodeError: Multifactor.err_token_required
      });
    }
  }

  componentDidMount() {
    const { getInitialTwoFactorQRCode, getTwoFactorQRCode, registerType } = this.props;
    // If initial 2FA registration, the endpoint does not need to validate token. Update is done on MyInfo page and requires token validation.
    const getQRCode = registerType === TWO_FACTOR_TYPES.UPDATE ? getTwoFactorQRCode : getInitialTwoFactorQRCode;

    this.setState({ isLoading: true });
    getQRCode()
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { QRCodeInfo, text: { Multifactor }, closeWindow, is2FARegistered, hideAnotherMethodButton } = this.props;
    const {
      isLoading, hasSecurityCode, securityCode,
      securityCodeError, attemptWarning,
    } = this.state;
    return (
      <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
        <LoadingOverlay show={isLoading}>
          <h2>{Multifactor.msg_get_codes}</h2>
          <form
            id='authy-form'
            onSubmit={e => this.verifyCode(e)}
          >
            <div className={styles.container}>
              <div className={styles.step1}>{Multifactor.lbl_step1}</div>
              <div className={styles.step1text}>{Multifactor.msg_download_authenticator}</div>
              <div className={styles.step2}>{Multifactor.lbl_step2}</div>
              <div className={styles.step2text} style={{ width: '70%' }}>{Multifactor.msg_scan_qrcode}</div>
              <div className={styles.qrcode}>
                {QRCodeInfo.ImageUrl && <QRCode value={QRCodeInfo.ImageUrl} />}
                <br /><br />
                {QRCodeInfo.Code && QRCodeInfo.Code}
              </div>
              <div className={styles.step3}>{Multifactor.lbl_step3}</div>
              <div className={styles.step3text}>{Multifactor.msg_enter_seccode}</div>
              <div className={styles.my529token}>
                <TextField
                  error={hasSecurityCode || Boolean(securityCodeError) || Boolean(attemptWarning)}
                  helperText={securityCodeError}
                  label={Multifactor.lbl_my529_token}
                  onChange={this.handleInput('securityCode')}
                  value={securityCode}
                  variant='filled'
                  autoFocus={true}
                />
              </div>
            </div>
            <Button
              variant='contained'
              style={{ width: '60%', marginTop: '20px' }}
              type='submit'
            >
              {Multifactor.btn_submit_token}
            </Button>
            <div>
              {!hideAnotherMethodButton &&
                <Button
                  color='primary'
                  disabled={isLoading}
                  style={{ width: '60%', marginTop: '20px' }}
                  onClick={() => this.props.history.push(Multifactor.register_path)}
                >
                  {Multifactor.btn_another_method}
                </Button>
              }
            </div>
            <div>
              <Button
                color='primary'
                variant='text'
                disabled={isLoading}
                style={{ width: '60%', marginTop: '20px' }}
                onClick={is2FARegistered ? closeWindow : this.logout}
              >
                {Multifactor.btn_cancel}
              </Button>
            </div>
          </form>
          <div className={styles.attemptWarning}>{attemptWarning}</div>

          {closeWindow && !is2FARegistered &&
            <Button
              color='primary'
              variant='outlined'
              style={{ width: '60%', marginTop: '10px' }}
              onClick={is2FARegistered ? closeWindow : this.logout}
            >
              {Multifactor.btn_cancel}
            </Button>
          }

          <Button
            color='primary'
            variant='outlined'
            style={{ width: '60%', marginTop: '10px' }}
            onClick={() => window.open(Multifactor.learn_more_url, '_blank', 'noopener noreferrer')}
          >
            {Multifactor.btn_learn_more}
          </Button>
        </LoadingOverlay>
      </div>
    );
  }
}

export default withRouter(connect(select, {
  getInitialTwoFactorQRCode,
  getTwoFactorQRCode,
  twoFactorTokenVerification,
  getPreferredMethod,
  savePreferredMethod,
  userLogout,
  notificationShow
})(LanguageHOC(RegisterAuthenticator)));