/*
*
* Signup Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { getPredefinedOptions } from 'components/Features/protected/Accounts/Transactions/OptionChanges/actions.js';
import AccountOwnerStep from './AccountOwnerStep';
import BeneficiaryStep from './BeneficiaryStep';
import ConfirmationStep from './ConfirmationStep';
import InvestmentOptionStep from './InvestmentOptionStep';
import ReviewStep from './ReviewStep';
import TermsConditionsStep from './TermsConditionsStep';
import Welcome from './Welcome';

import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import {
  Button,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from '@mui/material';

import {
  LoadingOverlay,
  Modal,
  notificationShow,
} from '@frontend/common';

import {
  deleteApplication,
  getExistingBeneficiaries,
  getSignupApplication
} from './actions';
import { getNotifications, } from 'components/AppRoot/Navigation/actions';

import { tabletWidth } from 'utils/config/_sassconfig.scss';

import { signupStepper } from 'utils/ga/events';

import styles from './styles.module.scss';

const select = (state) => ({
  accountOwnerInfo: state.signup.accountOwnerInfo,
  activeStep: state.signup.activeStep,
  beneficiaryInfo: state.signup.beneficiaryInfo,
  existingBenesList: state.signup.existingBenesList,
  isAgent_AccessRole: state.session.isAgent_AccessRole,
  isIpOnly_AccessRole: state.session.isIpOnly_AccessRole,
  isNewUser_AccessRole: state.session.isNewUser_AccessRole,
  splashHasDisplayed: state.session.splashHasDisplayed,
  predefinedOptions: state.optionChanges.predefinedOptions,
  webMessages: state.static.webMessages,
});

export class Signup extends React.Component {
  static propTypes = {
    getNotifications: PropTypes.func.isRequired,
    webMessages: PropTypes.object.isRequired,
    accountOwnerInfo: PropTypes.shape({
      encryptedDataExists: PropTypes.bool,
      Mailing: PropTypes.shape({
        StreetAddress1: PropTypes.string
      }),
      OwnerFirstName: PropTypes.string
    }).isRequired,
    activeStep: PropTypes.number,
    beneficiaryInfo: PropTypes.shape({
      BeneficiarySSN1: PropTypes.string,
      encryptedDataExists: PropTypes.bool,
    }).isRequired,
    deleteApplication: PropTypes.func.isRequired,
    existingBenesList: PropTypes.array.isRequired,
    getExistingBeneficiaries: PropTypes.func.isRequired,
    getPredefinedOptions: PropTypes.func.isRequired,
    getSignupApplication: PropTypes.func.isRequired,
    isAgent_AccessRole: PropTypes.bool.isRequired,
    isIpOnly_AccessRole: PropTypes.bool.isRequired,
    isNewUser_AccessRole: PropTypes.bool.isRequired,
    splashHasDisplayed: PropTypes.bool.isRequired,
    notificationShow: PropTypes.func.isRequired,
    predefinedOptions: PropTypes.array.isRequired,
    text: PropTypes.shape({
      Signup: PropTypes.shape({
        btn_close: PropTypes.string,
        btn_delete_application: PropTypes.string,
        head_delete_account_application: PropTypes.string,
        lbl_ao_step: PropTypes.string,
        lbl_bene_step: PropTypes.string,
        lbl_confirmation_step: PropTypes.string,
        lbl_investment_step: PropTypes.string,
        lbl_review_step: PropTypes.string,
        lbl_terms_step: PropTypes.string,
        msg_application_deleted: PropTypes.string,
        text_delete_application_data: PropTypes.string,
      }),
    }).isRequired,
  };

  state = {
    activeStep: this.props.activeStep,
    deleteApplicationModalOpen: false,
    isLoading: false,
    steps: [
      this.props.text.Signup.lbl_ao_step,
      this.props.text.Signup.lbl_bene_step,
      this.props.text.Signup.lbl_investment_step,
      this.props.text.Signup.lbl_review_step,
      this.props.text.Signup.lbl_terms_step,
      this.props.text.Signup.lbl_confirmation_step,
    ],
    welcomeModalOpen: false,
  };

  closeDeleteModal = () => {
    this.setState({ deleteApplicationModalOpen: false });
  }

  deleteApplicationButtonCompose = () => {
    const { accountOwnerInfo, beneficiaryInfo, isAgent_AccessRole, text: { Signup } } = this.props;

    const btn = (
      <div className={styles.deleteButton}>
        <Button onClick={() => this.setState({ deleteApplicationModalOpen: true })} color='primary'>
          {Signup.btn_delete_application}
        </Button>
      </div>
    );

    if (!isAgent_AccessRole && accountOwnerInfo.encryptedDataExists && this.state.activeStep !== 5) { // user has no accounts and has saved at least AO info, and it's not the confirmation step
      return btn;
    }
    else if (isAgent_AccessRole && beneficiaryInfo.encryptedDataExists && this.state.activeStep !== 5) { // user has accounts and has saved at least bene info
      return btn;
    }
  }

  onBackClick = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
    window.scrollTo(0, 0);
  }

  onDeleteApplication = () => {
    this.setLoadingOn();

    this.props.deleteApplication()
      .then(() => {
        this.closeDeleteModal();
        this.props.notificationShow(this.props.text.Signup.msg_application_deleted, 'success');
        this.props.getNotifications(); // refreshes app notifications
        this.setState({
          activeStep: this.props.activeStep,
          isLoading: false,
        });
        window.scrollTo(0, 0);
      })
      .catch(() => this.setLoadingOff());
  }

  onNextClick = () => {
    const activeStep = this.state.activeStep + 1;

    this.setState({ activeStep });
    signupStepper(activeStep); // Google Analytics url for goal tracking
    window.scrollTo(0, 0);
  }

  onStepClick = (index) => {
    this.setState({ activeStep: index });
  }

  setLoadingOff = () => {
    this.setState({ isLoading: false });
  }

  setLoadingOn = () => {
    this.setState({ isLoading: true });
  }

  stepperContentGet = () => {
    const { accountOwnerInfo, beneficiaryInfo } = this.props;

    switch (this.state.activeStep) {
      case 0: return (
        <AccountOwnerStep
          getSignupApplication={() => this.props.getSignupApplication()}
          isLoading={this.state.isLoading}
          key={accountOwnerInfo.Mailing.StreetAddress1}
          onBackClick={this.onBackClick}
          onNextClick={this.onNextClick}
          setLoadingOff={this.setLoadingOff}
          setLoadingOn={this.setLoadingOn}
          {...this.props}
        />
      );
      case 1: return (
        <BeneficiaryStep
          isLoading={this.state.isLoading}
          key={beneficiaryInfo.BeneficiarySSN1}
          onNextClick={this.onNextClick}
          onBackClick={this.onBackClick}
          setLoadingOff={this.setLoadingOff}
          setLoadingOn={this.setLoadingOn}
          {...this.props}
        />
      );
      case 2: return (
        <InvestmentOptionStep
          isLoading={this.state.isLoading}
          key={beneficiaryInfo.BeneficiarySSN1}
          onNextClick={this.onNextClick}
          onBackClick={this.onBackClick}
          setLoadingOff={this.setLoadingOff}
          setLoadingOn={this.setLoadingOn}
          welcomeModalOpen={this.state.welcomeModalOpen}
          {...this.props}
        />
      );
      case 3: return (
        <ReviewStep
          isLoading={this.state.isLoading}
          onNextClick={this.onNextClick}
          onBackClick={this.onBackClick}
          onStepClick={this.onStepClick}
          setLoadingOff={this.setLoadingOff}
          setLoadingOn={this.setLoadingOn}
          {...this.props}
        />
      );
      case 4: return (
        <TermsConditionsStep
          isLoading={this.state.isLoading}
          onNextClick={this.onNextClick}
          onBackClick={this.onBackClick}
          setLoadingOff={this.setLoadingOff}
          setLoadingOn={this.setLoadingOn}
        />
      );
      case 5: return (
        <ConfirmationStep
          onNextClick={this.onNextClick}
          setLoadingOff={this.setLoadingOff}
          setLoadingOn={this.setLoadingOn}
          onStepClick={this.onStepClick}
        />
      );
      default: break;
    }
  }

  componentDidMount() {
    const { accountOwnerInfo, existingBenesList, isAgent_AccessRole, predefinedOptions } = this.props;
    const apiCalls = [];

    // gets investment option info - needed for review and option selection
    if (predefinedOptions.length === 0) {
      apiCalls.push(this.props.getPredefinedOptions());
    }

    // gets the partially saved application the first time it loads only
    if (!accountOwnerInfo.OwnerFirstName) {
      apiCalls.push(this.props.getSignupApplication());
    }

    // gets grouped accounts for existing beneficiaries
    if (isAgent_AccessRole && existingBenesList.length === 0) {
      apiCalls.push(this.props.getExistingBeneficiaries());
    }

    if (apiCalls.length > 0) {
      this.setLoadingOn();

      Promise.all(apiCalls)
        .then(() => {
          this.setState({
            activeStep: this.props.activeStep,
            welcomeModalOpen: true,
          });
          this.setLoadingOff();
        })
        .catch(() => this.setLoadingOff());
    }
  }

  render() {
    const { activeStep, isLoading, steps, welcomeModalOpen, } = this.state;
    const {
      isAgent_AccessRole,
      isIpOnly_AccessRole,
      isNewUser_AccessRole,
      text: { Signup },
      webMessages,
      splashHasDisplayed,
    } = this.props;

    return (
      <>
        <div className={styles.container}>
          {activeStep === 2 && <Banner show={Boolean(webMessages[BANNER_LOCATIONS.ADD_ACCOUNT_STEP3])} body={webMessages[BANNER_LOCATIONS.ADD_ACCOUNT_STEP3]} />}

          <Stepper
            activeStep={activeStep}
            alternativeLabel={window.innerWidth > parseInt(tabletWidth)} // Only uses alternativeLabel for desktop and tablet
            orientation={window.innerWidth < parseInt(tabletWidth) ? 'vertical' : 'horizontal'} // Vertical orientation for mobile
          >
            {steps.map((label, index) => (
              activeStep === 5 ? // On confirmation step, disables all steps from being clicklable
                <Step key={label} disabled={activeStep === 5}>
                  <StepButton onClick={() => this.onStepClick(index)}>
                    <StepLabel>{label}</StepLabel>
                  </StepButton>
                </Step>
                : // On any step except the last, user can click back to previous step
                <Step key={label}>
                  <StepButton onClick={() => this.onStepClick(index)}>
                    <StepLabel>{label}</StepLabel>
                  </StepButton>
                </Step>
            ))}
          </Stepper>
          <LoadingOverlay show={isLoading} width='100%'>
            <div className={styles.stepperContentContainer}>
              {this.stepperContentGet()}
            </div>
          </LoadingOverlay>

          {this.deleteApplicationButtonCompose()}
        </div>

        <Modal
          actionButtons={[
            {
              action: this.onDeleteApplication,
              disabled: isLoading,
              label: Signup.btn_delete_application,
              loading: isLoading,
            }, {
              action: this.closeDeleteModal,
              label: Signup.btn_close
            }
          ]}
          onCloseModal={() => this.setState({ deleteApplicationModalOpen: false })}
          show={this.state.deleteApplicationModalOpen}
          title={Signup.head_delete_account_application}
        >
          {Signup.text_delete_application_data}
        </Modal>

        <Welcome
          activeStep={activeStep}
          closeModal={() => this.setState({ welcomeModalOpen: false })}
          isAgent_AccessRole={isAgent_AccessRole}
          isIpOnly_AccessRole={isIpOnly_AccessRole}
          isNewUser_AccessRole={isNewUser_AccessRole}
          open={welcomeModalOpen && splashHasDisplayed}
        />

      </>
    );
  }
}


export default withRouter(connect(select, {
  getNotifications,
  deleteApplication,
  getExistingBeneficiaries,
  getSignupApplication,
  getPredefinedOptions,
  notificationShow,
})(LanguageHOC(Signup)));
