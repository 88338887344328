import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';

import {
  Drawer,
} from '@mui/material';

import {
  IconBtnTooltip,
  InfoIcon,
} from '@frontend/common';

import styles from './styles.module.scss';

export function LearnMoreDrawer({ open, onClose, text: { Lpoa } }) {

  return (
    <Drawer anchor='bottom' open={open} onClose={onClose}>
      <div className={`${styles.learnMoreInfoContainer} hideOnPrint`}>
        <div className={styles.closeIcon}>
          <IconBtnTooltip icon='close' onClick={onClose} />
        </div>
        <h3 className={styles.learnMoreInfoHeader}>{Lpoa.learn_more_title}</h3>
        <div className={styles.learnMoreInfo}>
          <div className={styles.levelSelectionContainer}>
            <div style={{ overflowX: 'auto' }} > {/* make sure the table scrolls horizontally in mobile */}
              <table>
                <thead>
                  <tr>
                    <td rowSpan={2}>{Lpoa.edit_level_table.column_titles[0]}</td>
                    <td rowSpan={2}>{Lpoa.edit_level_table.column_titles[1]}</td>
                    <td colSpan={4}>{Lpoa.edit_level_table.span_column_3_4_5_6_title}</td>
                  </tr>
                  <tr>
                    <td>{Lpoa.edit_level_table.column_titles[2]}</td>
                    <td>{Lpoa.edit_level_table.column_titles[3]}</td>
                    <td>{Lpoa.edit_level_table.column_titles[4]}</td>
                    <td>{Lpoa.edit_level_table.column_titles[5]}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {Lpoa.edit_level_table.levels[0]}
                    </td>
                    <td>{Lpoa.edit_level_table.online_phone_text}</td>
                    <td>{Lpoa.text_no}</td>
                    <td>{Lpoa.text_no}</td>
                    <td>{Lpoa.text_no}</td>
                    <td>{Lpoa.text_no}</td>
                  </tr>
                  <tr>
                    <td>
                      {Lpoa.edit_level_table.levels[1]}
                    </td>
                    <td>{Lpoa.edit_level_table.online_phone_text}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>{Lpoa.text_no}</td>
                  </tr>
                  <tr>
                    <td>
                      {Lpoa.edit_level_table.levels[2]}
                    </td>
                    <td>{Lpoa.edit_level_table.online_phone_text}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>{Lpoa.text_yes}</td>
                    <td>
                      {Lpoa.text_yes}
                      <InfoIcon message={Lpoa.level_3_info_bubble} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>



        </div>
      </div>
    </Drawer>
  );
}

LearnMoreDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.shape({
    Lpoa: PropTypes.object.isRequired,
  }).isRequired,
};

export default LanguageHOC(LearnMoreDrawer);