/*
*
* AccountSummary Component
*
*/
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import LanguageHOC from 'utils/translations/LanguageHOC';

import LearnMore from './LearnMore';

import {
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import {
  Dropdown,
  InfoIcon,
} from '@frontend/common';

import styles from '../styles.module.scss';

export class AccountSummary extends Component {
  static propTypes = {
    applicationInfoErrors: PropTypes.object.isRequired,
    applicationInfo: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    onAccountSummaryInput: PropTypes.func.isRequired,
    renderNavigationButton: PropTypes.func.isRequired,
    text: PropTypes.shape({
      LpoaAccount: PropTypes.object.isRequired,
    }).isRequired,
  };

  state = {
    showLpoaLevelLearnMore: false,
  }

  render() {
    const { showLpoaLevelLearnMore } = this.state;

    const {
      applicationInfo,
      applicationInfoErrors,
      title,
      onAccountSummaryInput,
      renderNavigationButton,
      text: {
        LpoaAccount: { account_summary, btn_learn_more },
      },
    } = this.props;

    return (
      <Fragment>
        <div className={styles.sectionTitle}>{title}</div>
        <div className={styles.sectionSubTitle}><div className={styles.emptySubtitle} /></div>

        <TextField
          disabled={true}
          variant='filled'
          fullWidth
          label={account_summary.application_date_label}
          value={dayjs(applicationInfo.accountSummary.applicationDate).format('MM/DD/YYYY')} // eslint-disable-line
        />

        <TextField
          disabled={true}
          variant='filled'
          fullWidth
          label={account_summary.type_label}
          value={applicationInfo.accountSummary.type}
        />

        <TextField
          disabled={true}
          variant='filled'
          fullWidth
          label={account_summary.option_label}
          value={applicationInfo.accountSummary.option}
        />

        <div className={styles.detailsRow}>
          <Dropdown
            label={account_summary.poa_level_label}
            FormControlProps={{ variant: 'filled' }}
            onChange={value => onAccountSummaryInput(value, 'poaLevel')}
            options={[
              {
                display: account_summary.poa_levels[0],
                value: 1,
              },
              {
                display: account_summary.poa_levels[1],
                value: 2,
              },
              {
                display: account_summary.poa_levels[2],
                value: 3,
              },
            ]}
            errorText={applicationInfoErrors.accountSummary.poaLevel}
            value={applicationInfo.accountSummary.poaLevel}
          />
          <div className={styles.learnMoreButton}>
            {renderNavigationButton(btn_learn_more, () => this.setState({ showLpoaLevelLearnMore: true }), 'text')}
          </div>
        </div>

        <div className={styles.detailsRow}>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                inputProps={{ 'aria-label': account_summary.fed_tax_docs_label }}
                onClick={() => onAccountSummaryInput(!applicationInfo.accountSummary.fedTaxDocs, 'fedTaxDocs')}
                checked={applicationInfo.accountSummary.fedTaxDocs}
              />
            }
            label={account_summary.fed_tax_docs_label}
          />
          <div className={styles.infoColumn}>
            <InfoIcon message={applicationInfo.accountSummary.fedTaxDocs ? account_summary.fed_tax_docs_info_icon_checked : account_summary.fed_tax_docs_info_icon_unchecked} />
          </div>
        </div>

        <div className={styles.detailsRow}>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                inputProps={{ 'aria-label': account_summary.state_tax_docs_label }}
                onClick={() => onAccountSummaryInput(!applicationInfo.accountSummary.stateTaxDocs, 'stateTaxDocs')}
                checked={applicationInfo.accountSummary.stateTaxDocs}
              />
            }
            label={account_summary.state_tax_docs_label}
          />
          <div className={styles.infoColumn}>
            <InfoIcon message={applicationInfo.accountSummary.stateTaxDocs ? account_summary.state_tax_docs_info_icon_checked : account_summary.state_tax_docs_info_icon_unchecked} />
          </div>
        </div>

        <TextField
          disabled={true}
          variant='filled'
          fullWidth
          label={account_summary.status_label}
          value={applicationInfo.accountSummary.status}
        />

        {applicationInfo.accountSummary.promoCode &&
          <TextField
            disabled={true}
            variant='filled'
            fullWidth
            label={account_summary.promo_code_label}
            value={applicationInfo.accountSummary.promoCode}
          />
        }

        <LearnMore
          onClose={() => this.setState({ showLpoaLevelLearnMore: false })}
          open={showLpoaLevelLearnMore}
        />
      </Fragment>
    );
  }
}


export default LanguageHOC(AccountSummary);
