/*
*
* AllocationChart Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import * as d3 from 'd3';
import * as material_ui_theme from 'utils/config/material_ui_theme';
import { lighten } from '@mui/material/styles';

import { Tooltip } from '@mui/material';

import styles from './styles.module.scss';

const agentActiveFill = material_ui_theme.default.palette.secondary.main;
const agentFill = lighten(material_ui_theme.default.palette.secondary.main, 0.65);
const interestedPartyActiveFill = material_ui_theme.default.palette.primary.main;
const interestedPartyFill = lighten(material_ui_theme.default.palette.primary.main, 0.65);
const innerRadius = 60;
const outerRadius = 100;
const activeOuterRadius = 110;
const connectorInnerRadius = activeOuterRadius + 25;
const connectorOuterRadius = connectorInnerRadius + 5;

export class AllocationChart extends React.Component {

  static propTypes = {
    allocations: PropTypes.array.isRequired,
    isInterestedParty: PropTypes.bool.isRequired,
    activeIndex: PropTypes.number.isRequired,
    pieSelection: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { allocations, activeIndex, isInterestedParty } = nextProps;
    if (!allocations) return {};

    const prevActiveIndex = prevState.slices.findIndex(slice => slice.isActive);
    const activeFill = isInterestedParty ? interestedPartyActiveFill : agentActiveFill;
    const fill = isInterestedParty ? interestedPartyFill : agentFill;

    const pie = d3.pie()
      .value(data => data.ActualPercentageNum);

    const arcs = pie(allocations);

    const connectorArcGenerator = d3.arc();
    
    const slices = arcs.map((arc, i) => {
      const isActive = activeIndex === i;
      const isPreviousActive = prevActiveIndex === i;
      const connectorStartAngle = (arc.startAngle + arc.endAngle) / 2;

      const arcGenerator = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .padAngle(arc.value > 0.05 ? 0.02 : 0.005);

      const activeArcGenerator = d3.arc()
        .innerRadius(innerRadius)
        .outerRadius(activeOuterRadius)
        .padAngle(arc.value > 0.05 ? 0.02 : 0.005);
      
      const pieConnectorPath = connectorArcGenerator({
        innerRadius: activeOuterRadius,
        outerRadius: connectorInnerRadius + 5,
        startAngle: connectorStartAngle - .02,
        endAngle: connectorStartAngle + .02,
      });
      const connectorPath = connectorArcGenerator({
        innerRadius: connectorInnerRadius,
        outerRadius: connectorOuterRadius,
        startAngle: connectorStartAngle,
        endAngle: Math.PI,
      });
      const dataConnectorPath = connectorArcGenerator({
        innerRadius: connectorInnerRadius,
        outerRadius: connectorOuterRadius + 30,
        startAngle: Math.PI - .02,
        endAngle: Math.PI + .02,
      });

      const arcCentroid = arcGenerator.centroid(arc);


      return {
        ...arc,
        isActive,
        isPreviousActive,
        startAngle: arc.startAngle, // used for main donut section
        slicePath: isActive ? activeArcGenerator(arc) : arcGenerator(arc), // used for main donut sections
        fill: isActive ? activeFill : fill, // will be shaded lighter, if active selection
        activeFill, // activeFill will always be used for connectorPaths from selected slice to data
        textTransform: `translate(${arcCentroid})`, // finds the center point of each pie slice to put the text in
        pieConnectorPath, // connects outerPath to connectorPath
        connectorPath, // a curved path for only the active selection that points down to the data
        dataConnectorPath, // connects connectorPath down to data below chart
      };
    });


    if (prevActiveIndex !== activeIndex) {
      // new selection transitions, only run on new selection
      const sliceElements = d3.select('.slices');
      
      // main pie slice, only does a color transition
      sliceElements
        .selectAll('.slicePath')
        .data(slices)
        .attr('d', slice => slice.slicePath)
        .transition()
        .duration(1000)
        .attrTween('d', slice => {
          const activeInterpolate = d3.interpolate(outerRadius, activeOuterRadius);
          const prevActiveInterpolate = d3.interpolate(activeOuterRadius, outerRadius);
          return tween => {
            if (slice.isActive) {
              const activeArcGenerator = d3.arc()
                .innerRadius(innerRadius)
                .outerRadius(activeInterpolate(tween)) // causes the outerRadius to increase to larger size on selection
                .padAngle(slice.value > 0.05 ? 0.02 : 0.005);
              return activeArcGenerator(slice);
            }
            else if (slice.isPreviousActive) {
              const prevActiveArcGenerator = d3.arc()
                .innerRadius(innerRadius)
                .outerRadius(prevActiveInterpolate(tween)) // causes the outerRadius to decreases to normal size on new selection
                .padAngle(slice.value > 0.05 ? 0.02 : 0.005);
              return prevActiveArcGenerator(slice);
            }
            else {
              return slice.slicePath;
            }
          };
        })
        .attrTween('fill', slice => {
          if (slice.isActive) {
            return d3.interpolateRgb(fill, activeFill);
          }
          else if (slice.isPreviousActive) {
            return d3.interpolateRgb(activeFill, fill);
          }
          else {
            return d3.interpolateRgb(fill, fill);
          }
        });
      
      // outerpath to connector path connection new selection transition
      sliceElements
        .selectAll('.pieConnectorPath')
        .data(slices)
        .transition()
        .duration(1000)
        .attrTween('d', slice => {
          if (slice.isActive) {
            const connectorStartAngle = (slice.startAngle + slice.endAngle) / 2;
            const interpolate = d3.interpolate(outerRadius, connectorInnerRadius + 5);
            return tween => {
              return connectorArcGenerator({
                innerRadius: outerRadius,
                outerRadius: interpolate(tween), // makes the outerRadius grow larger from donut center over the duration time.
                startAngle: connectorStartAngle - .04,
                endAngle: connectorStartAngle,
              });
            };
          }
        });
  
      // connector path from pie to data new selection transition
      sliceElements
        .selectAll('.connectorPath')
        .data(slices)
        .transition()
        .duration(1500)
        .attrTween('d', slice => {
          if (slice.isActive) {
            const connectorStartAngle = (slice.startAngle + slice.endAngle) / 2;
            const interpolate = d3.interpolate(connectorStartAngle, Math.PI);
            return tween => {
              const endAngle = interpolate(tween);
              return connectorArcGenerator({
                innerRadius: connectorInnerRadius,
                outerRadius: connectorOuterRadius,
                startAngle: connectorStartAngle,
                endAngle,
              });
            };
          }
        });
  
      // connector path going down to data.
      sliceElements
        .selectAll('.dataConnectorPath')
        .data(slices)
        .transition()
        .duration(1500)
        .attrTween('d', slice => {
          if (slice.isActive) {
            const interpolate = d3.interpolate(connectorInnerRadius + 30, connectorInnerRadius);
            return tween => {
              return connectorArcGenerator({
                innerRadius: interpolate(tween), // decreases the innerRadius, causing the path to go up from the data to the connectorPath near the pie chart
                outerRadius: connectorOuterRadius + 30,
                startAngle: Math.PI - .02,
                endAngle: Math.PI + .02,
              });
            };
          }
        });
    }

    return { slices, };
  }

  state = {
    slices: [], // array of svg path commands, each representing a fund category
  };

  componentDidMount() {
    const { isInterestedParty } = this.props;
    const { slices } = this.state;
    const activeFill = isInterestedParty ? interestedPartyActiveFill : agentActiveFill;
    const fill = isInterestedParty ? interestedPartyFill : agentFill;

    // main select of svg g with class of .slices.
    const sliceElements = d3.select('.slices');

    // selects the pie slice sections, adds transition
    sliceElements
      .selectAll('.slicePath')
      .data(slices)
      .attr('fill', fill)
      .transition()
      .duration(2000)
      .attrTween('d', slice => {
        const interpolateAngle = d3.interpolate(slice.startAngle, slice.endAngle);
        const interpolateRadius = d3.interpolate(outerRadius, activeOuterRadius);
        // chart loading transitions
        const arcGenerator = d3.arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .padAngle(slice.value > 0.05 ? 0.02 : 0.005);
        return tween => {
          slice.endAngle = interpolateAngle(tween); 
          const transitionArcGenerator = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(interpolateRadius(tween)) // causes the outerRadius to increase to larger size on selection
            .padAngle(slice.value > 0.05 ? 0.02 : 0.005);
          return slice.isActive ? transitionArcGenerator(slice) : arcGenerator(slice);
        };
      })
      .attrTween('fill', slice => {
        if (slice.isActive) {
          return d3.interpolateRgb(fill, activeFill);
        }
      });

    // creates genertic arcGenerator, this allows us to add in unique arguments for all the connectorPaths going from the donut to the data table below the chart.
    const connectorArcGenerator = d3.arc();

    // selects the pieConnectorPath (little section from outerPath to connectorPath), adds transition
    sliceElements
      .selectAll('.pieConnectorPath')
      .data(slices)
      .transition()
      .duration(1000)
      .attrTween('d', slice => {
        if (slice.isActive) {
          const connectorStartAngle = (slice.startAngle + slice.endAngle) / 2;
          const interpolate = d3.interpolate(outerRadius, connectorInnerRadius + 5);
          return tween => {
            return connectorArcGenerator({
              innerRadius: outerRadius,
              outerRadius: interpolate(tween), // makes the outerRadius grow larger from donut center over the duration time.
              startAngle: connectorStartAngle - .02,
              endAngle: connectorStartAngle + .02,
            });
          };
        }
      });

    // selects the connectorPath, adds transition
    sliceElements
      .selectAll('.connectorPath')
      .data(slices)
      .transition()
      .duration(2000)
      .attrTween('d', slice => {
        if (slice.isActive) {
          const connectorStartAngle = (slice.startAngle + slice.endAngle) / 2;
          const interpolate = d3.interpolate(connectorStartAngle, Math.PI);
          return tween => {
            const endAngle = interpolate(tween); // makes the slice connectorPath grow longer
            return connectorArcGenerator({
              innerRadius: connectorInnerRadius,
              outerRadius: connectorOuterRadius,
              startAngle: connectorStartAngle,
              endAngle,
            });
          };
        }
      });

    // selects the pieConnectorPath (little section from connectorPath down to the data table), adds transition
    sliceElements
      .selectAll('.dataConnectorPath')
      .data(slices)
      .attr('d', null)
      .transition()
      .duration(2000)
      .attrTween('d', slice => {
        if (slice.isActive) {
          const interpolate = d3.interpolate(connectorInnerRadius + 30, connectorInnerRadius);
          return tween => {
            return connectorArcGenerator({
              innerRadius: interpolate(tween), // decreases the innerRadius, causing the path to go up from the data to the connectorPath near the pie chart
              outerRadius: connectorOuterRadius + 30,
              startAngle: Math.PI - .02,
              endAngle: Math.PI + .02,
            });
          };
        }
      });
  }


  render() {
    const { pieSelection, } = this.props;

    return (
      <svg className={styles.chartContainer} >
        <g className={`${styles.allocationChart} slices`} >
          {this.state.slices.map((slice, index) => (
            <g key={index} onClick={() => pieSelection(slice, index)}>

              {/* donut slice part */}
              <Tooltip title={slice.value < 0.06 ? slice.data.ActualPercentage : ''} placement='bottom'>
                <path className='slicePath' />
              </Tooltip>

              {/* ///// connector path to data below chart ///// */}
              {/* Connector from pie slice to curved connector */}
              <path 
                className='pieConnectorPath'
                fill={slice.activeFill}
                style={{ visibility: slice.isActive ? 'visible' : 'hidden' }}
              />
              {/* Curved connector line */}
              <path 
                className='connectorPath'
                fill={slice.activeFill}
                style={{ visibility: slice.isActive ? 'visible' : 'hidden' }}
              />
              {/* connector from curved connector towards data below chart */}
              <path 
                className='dataConnectorPath'
                fill={slice.activeFill}
                style={{ visibility: slice.isActive ? 'visible' : 'hidden' }}
              />
              <g className='insideTextLabels'>
                {slice.value >= 0.06 && // label in donut slice will only show if greater than or equal to 5.5%
                <text transform={slice.textTransform}>
                  <tspan 
                    y='+0.2em' 
                    x='-1.6em' 
                    fontWeight='bold' 
                    fontSize='10' 
                    fill='#FFF'
                  >
                    {slice.data.ActualPercentage}
                  </tspan>
                </text>}
              </g>
            </g>
          ))}
        </g>
      </svg>
    );
  }
}


export default (LanguageHOC(AllocationChart));
