/*
*
* InterestedParties Actions
*
*/
import {
  ADD_INTERESTED_PARTY,
  CREATE_INTERESTED_PARTY,
  DELETE_INTERESTED_PARTY,
  EDIT_INTERESTED_PARTY,
  GET_INTERESTED_PARTIES,
  GET_IP_ACTIVATION_INFO,
  RESEND_INTERESTED_PARTY_EMAIL,
} from './constants';

import {
  DELETErequest,
  GETrequest,
  POSTrequest,
  PUTrequest,
} from 'utils/helpers/api_handler';

export function activateIpAccount(guid, ipInfo) {
  const response = POSTrequest(`/ip/activate/${guid}`, ipInfo);

  return {
    type: CREATE_INTERESTED_PARTY,
    payload: response,
  };
}

export function addIp(ipInfo) {
  const response = POSTrequest('/ip', ipInfo);

  return {
    type: ADD_INTERESTED_PARTY,
    payload: response,
  };
}

export function deleteIp(ipId) {
  const response = DELETErequest(`/ip/${ipId}`);
  
  return {
    type: DELETE_INTERESTED_PARTY,
    payload: response,
    meta: { ipId }
  };
}

export function editIp(ipInfo) {
  const response = PUTrequest('/ip', ipInfo);

  return {
    type: EDIT_INTERESTED_PARTY,
    payload: response,
    meta: { ipId: ipInfo.interestedPartyId }
  };
}

export function getActivationInfo(guid) {
  const payload = GETrequest(`/ip/ActivationInfo/${guid}`);
  return {
    type: GET_IP_ACTIVATION_INFO,
    payload
  };
}

export function getInterestedParties() {
  const response = GETrequest('/ip');
  
  return {
    type: GET_INTERESTED_PARTIES,
    payload: response,
  };
}

export function resendIpEmail(ipId) {
  const response = POSTrequest(`/ip/ResendActivation/${ipId}`);

  return {
    type: RESEND_INTERESTED_PARTY_EMAIL,
    payload: response,
  };
}