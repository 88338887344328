/*
*
* Step3Terms Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import LanguageHOC from 'utils/translations/LanguageHOC';
import { Checkbox } from '@frontend/common';
import {
  Button,
  FormHelperText,
} from '@mui/material';
import { LoadingOverlay } from '@frontend/common';
import Review from '../Review';

import styles from './styles.module.scss';

export class Step3Terms extends React.Component {
  static propTypes = {
    activeStep: PropTypes.number.isRequired,
    beneficiaryName: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    nextClickHandle: PropTypes.func.isRequired,
    renderBackButton: PropTypes.func.isRequired,
    renderCancelButton: PropTypes.func.isRequired,
    text: PropTypes.shape({
      WithdrawalRequests: PropTypes.shape({
        btn_next: PropTypes.string,
        head_terms: PropTypes.string,
        lbl_agree_terms: PropTypes.string,
        text_terms_p1: PropTypes.array,
        text_terms_p2: PropTypes.array,
        text_terms_p3: PropTypes.array,
        text_terms_p4: PropTypes.array,
      }),
    }).isRequired,
    withdrawalInfo: PropTypes.shape({
      Amount: PropTypes.string,
      PayeeAddressLine1: PropTypes.string,
      PayeeCity: PropTypes.string,
      PayeeState: PropTypes.string,
      PayeeZipCode: PropTypes.string,
      SchoolDepartment: PropTypes.string,
      SchoolName: PropTypes.string,
      StudentNumber: PropTypes.string,
    }).isRequired,
  };

  state = {
    termsChecked: false,
  };

  formValidate = () => {
    let termsError = '';

    if (!this.state.termsChecked) {
      termsError = 'You must agree to the terms.';
    }

    this.setState({ termsError });

    return !termsError;
  }

  nextHandle = e => {
    e.preventDefault();

    if (this.formValidate()) {
      this.props.nextClickHandle();
    }
  }

  render() {
    const { activeStep, beneficiaryName, loading, text: { WithdrawalRequests }, withdrawalInfo } = this.props;

    return (
      <div className={styles.container}>
        <LoadingOverlay show={loading}>

          <Review
            activeStep={activeStep}
            beneficiaryName={beneficiaryName}
            withdrawalInfo={withdrawalInfo}
          />

          <div className={styles.termsContainer}>
            <h1>{WithdrawalRequests.head_terms}</h1>
            <h2>{WithdrawalRequests.text_terms_p1[0]}</h2>
            <p>{WithdrawalRequests.text_terms_p1[1]}</p>
            <p>{WithdrawalRequests.text_terms_p1[2]}</p>

            <h2>{WithdrawalRequests.text_terms_p2[0]}</h2>
            <p>{WithdrawalRequests.text_terms_p2[1]}</p>
            <p>{WithdrawalRequests.text_terms_p2[2]}</p>

            <h2>{WithdrawalRequests.text_terms_p3[0]}</h2>
            <p>{WithdrawalRequests.text_terms_p3[1]}</p>
            <p>{WithdrawalRequests.text_terms_p3[2]}</p>

            <h2>{WithdrawalRequests.text_terms_p4[0]}</h2>
            <p>{WithdrawalRequests.text_terms_p4[1]}</p>
            <p>{WithdrawalRequests.text_terms_p4[2]}</p>
            <p>{WithdrawalRequests.text_terms_p4[3]}</p>
          </div>

          <form onSubmit={this.nextHandle} className={styles.form}>
            <Checkbox
              checked={this.state.termsChecked}
              color='primary'
              label={<div>{WithdrawalRequests.lbl_agree_terms}</div>}
              onChange={e => this.setState({ termsChecked: e.target.checked, termsError: '', })}
            />
            {this.state.termsError && <FormHelperText style={{ color: 'var(--error)' }}>{this.state.termsError}</FormHelperText>}

            <div className={styles.stepperButtons}>
              {this.props.renderCancelButton()}

              {this.props.renderBackButton()}
              <Button
                color='primary'
                onClick={this.nextHandle}
                variant='contained'
                fullWidth
                type='submit'
              >
                {WithdrawalRequests.btn_next}
              </Button>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    );
  }
}


export default LanguageHOC(Step3Terms);
