/*
*
* Lpoa Actions
*
*/
import {
  DELETErequest,
  GETrequest,
  POSTrequest,
} from 'utils/helpers/api_handler';

import {
  CHECK_AUTH_CODE,
  GET_LPOA,
  POST_LPOA,
  DELETE_LPOA,
} from './constants';


export function checkAuthCode(authCode) {
  const response = GETrequest(`/lpoa/CheckAuthCode?code=${authCode}`);
  // if success returns LPOA name otherwise text "Code is invalid"
  return {
    type: CHECK_AUTH_CODE,
    payload: response,
  };
}

export function getLpoa(includeUnassigned = true) {
  const response = GETrequest(`/lpoa?includeUnassigned=${includeUnassigned}`);
  return {
    type: GET_LPOA,
    payload: response,
  };
}

export function postLpoa(body) {
  // this will be used for both add and update lpoa
  const response = POSTrequest('/lpoa', body);
  return {
    type: POST_LPOA,
    payload: response,
  };
}

export function deleteLpoa(beneIds) {
  const config = { data: { BeneficiaryIds: beneIds } }; // this is the required config syntaxt for passing json body
  const response = DELETErequest('/lpoa', config);
  return {
    type: DELETE_LPOA,
    payload: response,
  };
}

